import { React, useState, useContext, useEffect } from 'react'
import Loading from "react-fullscreen-loading";
import HeaderSection from "../banner/HeaderSection";
import * as  CONST from "../../../component/config/Constant"
import "../../../assets/css/form.css";
import axios from 'axios';
import { AuthContext } from '../../../component/context/AuthContext';
import { toast } from "react-toastify";
import deleteImg from "../../../assets/img/icon/delete.svg";
import EditableRow from '../../../component/social/EditableRow';
import ReadOnlyRow from '../../../component/social/ReadOnlyRow';
import Swal from 'sweetalert2';


function Social() {
    const [authUserData, setAuthUserData] = useContext(AuthContext);
	const [editSocialId, setEditSocialId] = useState(null);
	const [loading, setloading] = useState(false);
    const [inputFields, setInputFields] = useState([{
        social_name:'',
        social_link:'',
        validate: {}
    } ]);

    const [socialDetails, setSocialDetails] = useState([{
        social_name:'',
        social_link:'',
    } ]);

    const [editFormData, setEditFormData] = useState([{
        social_name:'',
        social_link:'',
    } ]);

	let isToken = '';
	if (authUserData) {
		if (authUserData[0]) {
			isToken = authUserData[0].token ? authUserData[0].token : "";
		}
	}

    useEffect(() => {
        //fetchSocialData();
    }, []);

    async function fetchSocialData(){
        setloading(true);
        //GET SOCIAL DATA
        await axios
        .get(CONST.GET_SOCIAL_DATA,
        {
            headers: {
                'Authorization': `Bearer ${isToken}`
            },    
            
        })

        .then((response) => {
           
            const status = response.data.status;
            
            if (status === 'success') {
                
                let newData = response.data.data;
                if(newData.length > 0 ){
                 
                  //setInputFields(newData)
                  setSocialDetails(newData)
                }else{
                    setSocialDetails([{
                        social_name:'',
                        social_link:'',
                    } ]);
                   
                }
                
                
            }else{
                toast.error(response.data.message);
            }
        })
        .catch((error) => {
            console.log(error);
        });
        setloading(false);
    }

    const valdations = async () =>{
        let valid = true;
        
        Object.entries(inputFields).map(([key, value]) => {
            let socialError, linkError = '';
            if(value.social_name == ''){
                socialError = 'Social name field is required' 
              valid = false;
            }
            validateChange(key, 'social_name' ,socialError );

            if(value.social_link == ''){
                linkError = 'Social link field is required' 
                valid = false;
            }else{
                // if (value.social_link.match(/[0-9]/)) {
                //     linkError = 'Invalid social link format' 
                //   valid = false;
                // }
                const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');   
                if (value.social_name != 'Skype') {
                    if(regex.test(value.social_link) == false){
                        linkError = 'Invalid social link format' 
                        valid = false;
                    }
                }
            }
            validateChange(key, 'social_link' ,linkError );
        });
        return valid ;
    }
    const handleSubmit = async (e) => {
       
        let isValid = await valdations();
        if(isValid === true){
            
            setloading(true);
            axios
                .post(CONST.ADD_SOCIAL, 
                    {
                       "sociallink": inputFields 
                    } ,
                    {
                    headers: {
                        'Authorization': `Bearer ${isToken}`,
                        'Content-Type': 'application/json'   
                    }
                })
                .then((response) => {
                    
                    if (response) {

                        if (response.data.status == 'error') {
                            toast.error(response.data.message);
                        } else {
                            toast.success(response.data.message);
                            fetchSocialData()

                            setInputFields(
                                [{
                                    social_name:'',
                                    social_link:'',
                                    validate: {}
                                }]  
                            );
                        }
                    }
                })
                .catch((error) => {
                    setloading(false);
                    console.log(error);
                    toast.error("Something went wrong. Please try again." + error);
                });
            setloading(false);
        }else{
            
        }
    }

    const addSocialRow = () => {
        
        setInputFields([...inputFields, {
            social_name:'',
            social_link:'',
            validate: {}
        } ])
    }

    const removeInputFields = (index)=>{
        const rows = [...inputFields];
        rows.splice(index, 1);
        setInputFields(rows);
        
    }

    const handleChange = (index, evnt)=>{
        const { name, value } = evnt.target;
        const list = [...inputFields];
        list[index][name] = value;
        setInputFields(list);
    }

    const validateChange = (index, fieldName ,error)=>{
        const list = [...inputFields];
        
       // let valid = 0;
        if(error != ''){
            list[index]['validate']['validate'] = 1;
            list[index]['validate'][fieldName] = error;    
        }
        
        
        setInputFields(list);
    }

    const validateEditFields = (editFormData,index) =>{
        const newFormData = [...socialDetails];
        let formData = editFormData;
       
        if(formData.social_name == ''){
            formData.error_social_name = 'Social media name field is required';
        }else if(!/^[a-zA-Z\s]+$/.test(formData.degree) ){
			formData.error_social_name = "Social media is not valid";
		}else{
            formData.error_social_name = ""; 
        }

        const regex = new RegExp('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'); 
        if(formData.social_link == ''){
            formData.error_social_link = 'Social link field is required';
        }else if(regex.test(formData.social_link) == false ){
			formData.error_social_link = "Social link is not valid";
		}else{
            formData.error_social_link = ""; 
        }


        setEditFormData(formData);
        newFormData[index]	= formData;
	 	setSocialDetails(newFormData);

        if(formData.error_social_name == "" &&
        formData.error_social_link == ""
        ){
                return true;
        }else{
            return false;
        }
    }

    const handleSocialEditFormSubmit = (event, social) => { 
		event.preventDefault();
        //setEditSocialId(social.id);
		const index = socialDetails.findIndex((social) => social.id === editSocialId );
	    const isEditDataValid = validateEditFields(editFormData,index);
        
		if (isEditDataValid) {
			axios.put(CONST.EDIT_SOCIAL_DETAILS,
					{
						'id'	   	 : editSocialId,
						'social_name': editFormData.social_name,
						'social_link': editFormData.social_link,
						
					},{
						headers: {
							'Authorization': `Basic ${isToken}`,
						}
					}
				)
				.then((response) => {
					const status = response.data.status;
					setloading(false);
					
					if (status === 'success') {
						toast.success(response.data.message);
						fetchSocialData();
						setEditSocialId(null);
					} else {
						toast.error(response.data.message);
					}
				})
				.catch((error) => {
					setloading(false);
     				toast.error("Something went wrong. Please try again.");
				});
		}
	}

    const handleEditFormChange = (fieldValue, fieldName) => {
		const newFormData = { ...editFormData };
		newFormData[fieldName] = fieldValue; //event.target.value;
		setEditFormData(newFormData);
        
	}

    const handleEditSocial = (event, social) =>{
		event.preventDefault();
		setEditSocialId(social.id);
		const formValues = { 
			'id': social.id,
			'social_name' : social.social_name,
			'social_link'  : social.social_link
		}
		setEditFormData(formValues);
	}

    const handleCancelClick = (event, guardian) => {
		setEditSocialId(null);
	}

    function deleteSocial(id) {
        Swal.fire({
			title: 'Are you sure?',
			text: "Do you want to delete social media details?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then((result) => {
			if (result.isConfirmed) {
				//setLoading(true);
				const newFormData = [...socialDetails];
				const index = socialDetails.findIndex((social) => social.id === editSocialId );
                if (id > 0) {
                    axios
                        .delete(CONST.DELETE_SOCIAL_DETAILS, {
                            headers: {
                                'Authorization': `Bearer ${isToken}`
                            },
                            data: {
                                soc_id: id
                            }
                        })
                        .then((response) => {

                            if (response) {
                                if (response.data.status == 'error') {
                                    toast.error(response.data.message);
                                } else {
                                    fetchSocialData();
                                    toast.success(response.data.message);
									// newFormData.splice(index,1);
									// setSocialDetails(newFormData);
                                
                                }
						    }
                        })
                        .catch((error) => {
                            setloading(false);
                            toast.error("Something went wrong. Please try again." + error);
                        });
                        setloading(false);
                }
			}
		})
    }

    return (
        <>
			<Loading
				loading={loading}
				background="rgba(0, 0, 0, 0.5)"
				loaderColor="#fff"
			/>
			<HeaderSection
				title=""
				heading="Social Links"
				addButton={addSocialRow}
				addLink="#"
				addLinkName="Add More"
				linktype=""
			/>
            <div className="page-body">
                <div className="container-xl">
                    <div className="row row-cards">
                        <div className="col-12">
                            <div className="card">
                                <div className="card" key="gcard">

                                    <div className="card-body">
                                        <div className="col-12">
                                            <div className="card">
                                                <div className="table-responsive" key="div_0">
                                                    <form onSubmit={handleSocialEditFormSubmit}>
                                                    <table className="table table-vcenter card-table table-striped" key="table_0" id="custTable">
                                                        <thead>
                                                            <tr key="tr_head">
                                                            <th>Social Name</th>
                                                            <th>Social Link</th>
                                                            <th className="w-1">Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                        {
                                                            
                                                            socialDetails.map((data, i)=>{
                                                            //alert(editSocialId+' data.id : ' + data.id);
                                                            
                                                            return(
                                                                <>
                                                                { (editSocialId != null && editSocialId == data.id) 
                                                                ?
                                                                ( 
                                                                <><EditableRow data={data} editFormData={editFormData}
                                                                    handleEditFormChange={handleEditFormChange} handleCancelClick={handleCancelClick} />
                                                                    
                                                                </>
                                                                )
                                                                :
                                                                (
                                                                <> 
                                                                <ReadOnlyRow data={data} handleEditSocial={handleEditSocial} deleteSocial={deleteSocial} deleteImg={deleteImg} />
                                                                </>
                                                                )
                                                                
                                                                
                                                                }
                                                                </>
                                                        
                                                                )
                                                            })
                                                          
                                                        }
                                                        </tbody>
                                                    </table>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-body">
                                {
                                     
                                inputFields.map((data, i)=>{
                                
                                const {social_name, social_link, validate}= data;
                               
                                
                                return(
                                <div className="row" key={`row_${i}`}  >
                                    <div className="col-md-6">
                                        <div className="form-group mb-3 ">
                                            <label className="form-label required">
                                                Social Media Name
                                            </label>
                                        <div>

                                            <select
                                            type="text"
                                            name="social_name"
                                            id={ `social_name_${i}`}
                                            value={social_name}
                                            className={`form-select form-control ${validate.validate && validate.social_name
                                                ? "is-invalid "
                                                : ""
                                                }`}
                                            aria-describedby="emailHelp"
                                            placeholder="Enter social media name"
                                            onChange={(evnt)=>handleChange(i, evnt)}
                                            >
                                                <option value="">Select Social Media</option>
                                                <option value="LinkedIn">LinkedIn</option>
                                                <option value="Skype">Skype</option>
                                                <option value="Facebook">Facebook</option>
                                                <option value="Instagram">Instagram</option>
                                            </select>
                                        </div>
                                        <div
                                            className={`invalid-feedback text-start ${validate.validate && validate.social_name
                                            ? "d-block"
                                            : "d-none"
                                            }`}
                                        >
                                           {validate.validate && validate.social_name
                                        ? validate.social_name
                                        : ""}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mb-3 ">
                                    <label className="form-label required">
                                        Social Link
                                    </label>
                                    <div>
                                        <input
                                        type="text"
                                        name="social_link"
                                        id={ `social_link_${i}`}
                                        className={`form-control ${validate.validate && validate.social_link
                                            ? "is-invalid "
                                            : ""
                                            }`}
                                        aria-describedby="emailHelp"
                                        placeholder="Enter social link"
                                        onChange={(evnt)=>handleChange(i, evnt)}
                                        value={social_link}
                                        />
                                    </div>            
                                    <div
                                        className={`invalid-feedback text-start ${validate.validate && validate.social_link
                                        ? "d-block"
                                        : "d-none"
                                        }`}
                                    >
                                        {validate.validate && validate.social_link
                                        ? validate.social_link
                                        : ""}
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-md-6">
                            {(inputFields.length!==1 && i>0)? 
                            <button className="mr-3 btn btn-danger mt-4" onClick={() => removeInputFields(i)} style={{margin: "1px 10px 30px 1px"}}>Remove</button>:''
                            }
                            </div>
                            <hr></hr>
                        </div>
                        
                        )
                    })
                    }

                    <div className="col-md-12">
                        <div className="form-footer">
                            <button
                            type="button"
                            className="mr-3 btn btn-primary"
                            onClick={handleSubmit}
                            >
                            Submit
                            </button>
                                &nbsp;&nbsp;
                                <button
                                type="reset"
                                className="btn btn-outline-primary"
                                
                                >
                                Reset
                            </button>
                        </div>
                    </div>
                       
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </div >
    </>
    )
}

export default Social