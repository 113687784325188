import React from "react";
import DatePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';

const EditableRow = ({data, editFormData, handleEditFormChange, handleCancelClick }) => {
 //alert('edit socialFormData'+JSON.stringify(editFormData) );
  return (
    <tr key=''>
    <td> 
        {data.social_name ? data.social_name : ''}    
    </td>
    <td>
        <input
        type="text"
        name="social_link"
        id="social_link"
        className={`form-control`}
        placeholder="Enter Social Link"
        value= {editFormData.social_link}
        onChange={(e) => handleEditFormChange(e.target.value,'social_link')}
         />  
        <span className={`invalid-feedback text-start ${editFormData.error_social_link ? "d-block" : "d-none"}`}>{editFormData.error_social_link !== "" ? editFormData.error_social_link : ''}
        </span>     
    </td>
    
    <td>
      <div className="inline-btn-grp">
        <button className="btn btn-primary" type="submit">Save</button>&nbsp;
        <button className="btn btn-danger" type="button" onClick={handleCancelClick}>Cancel</button>
      </div>
    </td>
    </tr>
  )
}

export default EditableRow