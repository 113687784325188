import React from "react";

const ReadOnlyRow = ({data, handleEditSocial, deleteSocial, deleteImg}) => {
    //alert('READ Social'+JSON.stringify(data))
    
    return (
        <>
            {
                (data.id)
                ?
                (
                <tr key={`tr_view`}>
                    <td>{data.social_name ? data.social_name : ''}</td>
                    <td className="text-muted">{data.social_link ? data.social_link : '' }</td>
                    <td>
                    <img src="/static/media/edit.8b4759ee999d507ec6d9bc66c8538edc.svg" alt="editEducation" className="cursor" width="32" onClick={(event)=> handleEditSocial(event, data)} />
                    <img src={deleteImg} alt='deleteImg' width={32} className='cursor' onClick={() => deleteSocial(data.id) } />
                    </td>
                </tr>
                )
                :
                (
                    <tr>
                        <td colSpan='6'>Record not found!</td>
                    </tr>
                )
            }
        </>
    )
}

export default ReadOnlyRow