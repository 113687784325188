import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Form from "../../component/utilities/Forms";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "react-fullscreen-loading";
import * as CONST from "../../component/config/Constant";

export default function ForgetPassword() {
  const [email, setEmail] = useState("");
  const [validate, setValidate] = useState({});
  const [loading, setloaing] = useState(false);

  /*  useEffect(() => {
    
   }, []); */

  const validateForgotPassword = async () => {
    let isValid = true;

    let validator = Form.validator({
      email: {
        value: email,
        isRequired: true,
        isEmail: true,
        label: "email",
      },
    });

    if (validator !== null) {
      setValidate({
        validate: validator.errors,
      });

      isValid = false;
    } else {
      setValidate({
        validate: "",
      });
    }
    return isValid;
  };

  const submitForm = async (e) => {
    e.preventDefault();
    const validate = await validateForgotPassword();
    if (validate) {
      setloaing(true);
      await axios
        .post(CONST.FORGOT_PASSWORD, {
          email: email,
          login_url: CONST.APP_LINK
        })
        .then((response) => {
          console.log(response);
          const status = (response.data.status);
          setloaing(false);
          if (status === "success") {
            toast.success("Password Recovery email has been sent. Please check the email.");
          } else {
            toast.error(response.data.message);
          }
          setloaing(false);
        })
        .catch((error) => {
          setloaing(false);
          toast.error("There was an error.");
        });
    } else {
    }
  };
  /* const togglePassword = (e) => {
        if (showPassword) {
          setShowPassword(false);
        } else {
          setShowPassword(true);
        }
      }; */
  return (
    <>
      <Loading
        loading={loading}
        background="rgba(0, 0, 0, 0.5)"
        loaderColor="#fff"
      />{" "}
      <section className="page page-center login_forgot background_white">
      <img src="/eportal/assets/img/hero/hero-1/shape-1.svg" alt="" className="shape shape-1"></img>
        <div className="container container-normal py-4">
          <div className="row align-items-center g-4 d-flex justify-content-center min_height">
            
            <div className="col-lg-5">
                            
                  <div className="card-body">
                    <h3 className="h3 mb-4">Password Recovery</h3>{" "}
                    <p className="pb-2">
                      Have you forgotten your password? Simply enter the email
                      associated with your account in the form below and we'll
                      send you the instructions to reset your password.
                    </p>
                    <form
                      className="auth-form"
                      method="POST"
                      onSubmit={submitForm}
                      autoComplete={"off"}
                    >
                      <div className="mb-3">
                        <label className="form-label"> Email address </label>{" "}
                        <input
                          type="email"
                          className={`form-control ${validate.validate && validate.validate.email
                            ? "is-invalid "
                            : ""
                            }`}
                          id="email"
                          name="email"
                          value={email}
                          placeholder="your@email.com"
                          autoComplete="false"
                          autoFill="false"
                          onChange={(e) => setEmail(e.target.value)}
                        />{" "}
                        <div
                          className={`invalid-feedback text-start mb-2 ${validate.validate && validate.validate.email
                            ? "d-block"
                            : "d-none"
                            }`}
                        >
                          {" "}
                          {validate.validate && validate.validate.email
                            ? validate.validate.email[0]
                            : ""}{" "}
                        </div>{" "}
                      </div>{" "}
                      <div className="form-footer">
                        <button type="submit" className="btn btn-primary w-100">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path
                              stroke="none"
                              d="M0 0h24v24H0z"
                              fill="none"
                            ></path>
                            <rect
                              x="3"
                              y="5"
                              width="18"
                              height="14"
                              rx="2"
                            ></rect>
                            <polyline points="3 7 12 13 21 7"></polyline>
                          </svg>&nbsp;Send me new password
                        </button>{" "}
                      </div>{" "}
                    </form>{" "}
                    <div className="text-muted mt-3">
                      Forget it, <Link to="/login"><strong>send me back</strong></Link> to the
                      sign in screen.
                    </div>
                  </div>{" "}
                
              
            </div>{" "}
            <div className="col-lg-6 offset-lg-1 ">
            <img className="img-fluid" src={process.env.PUBLIC_URL + "/assets/img/login/login.png"} alt="banner-image"/>
          </div>
          </div>{" "}
        </div>{" "}
      </section>
      {" "}
    </>
  );
}
