import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate, Navigate, useParams } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import * as CONST from "../config/Constant";
import { toast } from "react-toastify";
import AuthMenu from "./AuthMenu";
import WithoutAuthMenu from "./WithoutAuthMenu";
import axios from "axios";
function Header() {
  const navigate = useNavigate();
  const [isSticky, setSticky] = useState(false);
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  let isToken = "";
  let profile_photo_path = "";
  let first_name = "";
  let first_name_short = "";
  let last_name_short = "";
  let last_name = "";
  let department_id = '';
  if (authUserData) {
    if (authUserData[0]) {
      first_name_short = authUserData[0].first_name
        ? authUserData[0].first_name.substring(0, 1)
        : "";
      last_name_short = authUserData[0].last_name
        ? authUserData[0].last_name.substring(0, 1)
        : "";
      first_name = authUserData[0].first_name ? authUserData[0].first_name : "";
      last_name = authUserData[0].last_name ? authUserData[0] : "";
      profile_photo_path = authUserData[0].profile_photo_path
        ? authUserData[0].profile_photo_path
        : "";
      isToken = authUserData[0].token ? authUserData[0].token : "";
      department_id = authUserData[0].department_id ? authUserData[0].department_id : "";
    }
  }

  const profileImage = CONST.HOST + profile_photo_path;
  console.log(profileImage);
  const handleScroll = () => {
    const windowScrollTop = window.scrollY;
    if (windowScrollTop > 10) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };
  useEffect(() => {

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };

  }, []);

  const handleLogout = () => {
    navigate("/");
    const UserData = [
      {
        first_name: "",
        last_name: "",
        email: "",
        status: "",
        token: "",
        role_id: "",
        profile_photo_path: "",
      },
    ];
    setAuthUserData(UserData);
    localStorage.removeItem("eToken");
    toast.success("You have logged out successfully.");
  };
  const pathName = window.location.pathname;
  const adminPath = "admin";
  const isAdminPanel = pathName.includes(adminPath);

  return (
    <>
      <header className="header header-4">
        <div className="navbar-area">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <nav className="navbar navbar-expand-lg">
                  <Link className="navbar-brand" to="/">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/eluminous-pvt-ltd.png"
                      }
                      alt="logo"
                    />
                  </Link>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarSupportedContent4"
                    aria-controls="navbarSupportedContent4"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                    <span className="toggler-icon"></span>
                  </button>
                  <div
                    className="collapse navbar-collapse sub-menu-bar"
                    id="navbarSupportedContent4"
                  >
                    <ul id="nav4" className="navbar-nav ml-auto">
                      {isToken && isToken ? (
                        <>
                          <li className="nav-item">
                            <Link
                              className="page-scroll"
                              aria-current="page"
                              to={CONST.ADMIN_PRE + "dashboard"}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-home-2 mr-1"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="currentColor"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                ></path>
                                <path d="M5 12l-2 0l9 -9l9 9l-2 0"></path>
                                <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"></path>
                                <path d="M10 12h4v4h-4z"></path>
                              </svg>
                              Dashboard
                            </Link>
                          </li>
                          {isAdminPanel && isAdminPanel ? (
                            <>
                              <AuthMenu />
                            </>
                          ) : (
                            <>
                              <WithoutAuthMenu />
                            </>
                          )}
                          <li className="nav-item dropdown nav-item last_profile d-flex flex-wrap">
                            <span
                              className="rounded-circle pr-2"
                              style={{
                                backgroundImage: `url("${
                                  CONST.HOST + profile_photo_path
                                }")`,
                              }}
                            ></span>
                            <Link
                              className="page-scroll "
                              href="#0"
                              data-toggle="collapse"
                              data-target="#sub-nav2"
                              aria-controls="sub-nav2"
                              aria-expanded="false"
                              aria-label="Toggle navigation"
                            >
                              <span className="pl-1 pr-1">{first_name}</span>
                              <div className="sub-nav-toggler">
                                <span>
                                  <i className="lni lni-chevron-down"></i>{" "}
                                </span>
                              </div>
                            </Link>
                            <ul className="sub-menu collapse" id="sub-nav2">
                              <li>
                                <Link to={CONST.ADMIN_PRE + "my-profile"}>
                                  {" "}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="icon icon-tabler icon-tabler-caret-right-filled mr-1"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    stroke-width="2"
                                    stroke="currentColor"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    ></path>
                                    <path
                                      d="M9 6c0 -.852 .986 -1.297 1.623 -.783l.084 .076l6 6a1 1 0 0 1 .083 1.32l-.083 .094l-6 6l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002l-.059 -.002l-.058 -.005l-.06 -.009l-.052 -.01l-.108 -.032l-.067 -.027l-.132 -.07l-.09 -.065l-.081 -.073l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057l-.002 -12.059z"
                                      stroke-width="0"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                  My Account
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="page-scroll"
                                  aria-current="page"
                                  to={CONST.ADMIN_PRE + "leaves"}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="icon icon-tabler icon-tabler-caret-right-filled mr-1"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    stroke-width="2"
                                    stroke="currentColor"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    ></path>
                                    <path
                                      d="M9 6c0 -.852 .986 -1.297 1.623 -.783l.084 .076l6 6a1 1 0 0 1 .083 1.32l-.083 .094l-6 6l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002l-.059 -.002l-.058 -.005l-.06 -.009l-.052 -.01l-.108 -.032l-.067 -.027l-.132 -.07l-.09 -.065l-.081 -.073l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057l-.002 -12.059z"
                                      stroke-width="0"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                  My Leaves
                                </Link>
                              </li>
                              <li>
                                <Link
                                  className="page-scroll"
                                  aria-current="page"
                                  to={CONST.ADMIN_PRE + "comp-off"}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="icon icon-tabler icon-tabler-caret-right-filled mr-1"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    stroke-width="2"
                                    stroke="currentColor"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    ></path>
                                    <path
                                      d="M9 6c0 -.852 .986 -1.297 1.623 -.783l.084 .076l6 6a1 1 0 0 1 .083 1.32l-.083 .094l-6 6l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002l-.059 -.002l-.058 -.005l-.06 -.009l-.052 -.01l-.108 -.032l-.067 -.027l-.132 -.07l-.09 -.065l-.081 -.073l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057l-.002 -12.059z"
                                      stroke-width="0"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                  My Comp-Off
                                </Link>
                              </li>
                              <li>
                                <Link to={CONST.ADMIN_PRE + "my-exemption"}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="icon icon-tabler icon-tabler-caret-right-filled mr-1"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    stroke-width="2"
                                    stroke="currentColor"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    ></path>
                                    <path
                                      d="M9 6c0 -.852 .986 -1.297 1.623 -.783l.084 .076l6 6a1 1 0 0 1 .083 1.32l-.083 .094l-6 6l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002l-.059 -.002l-.058 -.005l-.06 -.009l-.052 -.01l-.108 -.032l-.067 -.027l-.132 -.07l-.09 -.065l-.081 -.073l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057l-.002 -12.059z"
                                      stroke-width="0"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                  My Tax Exemptions
                                </Link>
                              </li>
                              <li>
                                <Link to={CONST.ADMIN_PRE + "my-roles"}>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="icon icon-tabler icon-tabler-caret-right-filled mr-1"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    stroke-width="2"
                                    stroke="currentColor"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    ></path>
                                    <path
                                      d="M9 6c0 -.852 .986 -1.297 1.623 -.783l.084 .076l6 6a1 1 0 0 1 .083 1.32l-.083 .094l-6 6l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002l-.059 -.002l-.058 -.005l-.06 -.009l-.052 -.01l-.108 -.032l-.067 -.027l-.132 -.07l-.09 -.065l-.081 -.073l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057l-.002 -12.059z"
                                      stroke-width="0"
                                      fill="currentColor"
                                    ></path>
                                  </svg>{" "}
                                  My Roles & responsibility
                                </Link>
                              </li>
                              <li>
                                <Link to={CONST.ADMIN_PRE + "my-assets"}>
                                  {" "}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="icon icon-tabler icon-tabler-caret-right-filled mr-1"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    stroke-width="2"
                                    stroke="currentColor"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    ></path>
                                    <path
                                      d="M9 6c0 -.852 .986 -1.297 1.623 -.783l.084 .076l6 6a1 1 0 0 1 .083 1.32l-.083 .094l-6 6l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002l-.059 -.002l-.058 -.005l-.06 -.009l-.052 -.01l-.108 -.032l-.067 -.027l-.132 -.07l-.09 -.065l-.081 -.073l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057l-.002 -12.059z"
                                      stroke-width="0"
                                      fill="currentColor"
                                    ></path>
                                  </svg>
                                  My Assets
                                </Link>
                              </li>

                              <li>
                                <button
                                  className="nav-button button "
                                  onClick={handleLogout}
                                >
                                  <i className="lni lni-lock"></i> &nbsp;Logout
                                </button>
                              </li>
                            </ul>
                          </li>
                        </>
                      ) : (
                        <>
                          <WithoutAuthMenu />
                        </>
                      )}
                    </ul>
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
export default Header;
