import React from "react";
import { Link } from "react-router-dom";

function HeaderSection(props) {
  return (
    <>
      <div className="page-header d-print-none">
        <div className="container-xl">
          <div className="row g-2 align-items-center">
            <div className="col">
              <div className="page-pretitle">{props.title}</div>
              <h2 className="page-title">{props.heading}</h2>
            </div>
            {
              (props.addLink && (
                <>
                  <div className="col-12 col-md-auto ms-auto d-print-none">
                    <div className="btn-list">
                      <Link
                        to={props.addLink ? props.addLink : "#"}
                        className="btn btn-primary"
                        onClick={props.addButton}
                      >
                        {props.linktype === "view" ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-eye"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <circle cx="12" cy="12" r="2"></circle>
                            <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path>
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-plus"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            strokeWidth="2"
                            stroke="currentColor"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <line x1="12" y1="5" x2="12" y2="19"></line>
                            <line x1="5" y1="12" x2="19" y2="12"></line>
                          </svg>
                        )}
                        &nbsp;{props.addLinkName}
                      </Link>
                    </div>
                  </div>
                </>
              ))
            }

          </div>
        </div>
      </div>
    </>
  );
}

export default HeaderSection;
