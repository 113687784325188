import { React, useState, useContext, useEffect } from 'react'
import Loading from "react-fullscreen-loading";
import HeaderSection from "../banner/HeaderSection";
import * as  CONST from "../../../component/config/Constant"
import Form from "../../../component/utilities/Forms";
import "../../../assets/css/form.css";
import axios from 'axios';
import { AuthContext } from '../../../component/context/AuthContext';
import { toast } from "react-toastify";
import DivSpinner from "../../../component/utilities/Spinner";

import DatePicker from 'react-datetime';
import moment from 'moment';
import 'react-datetime/css/react-datetime.css';

function Add() {
	const departmentTeamList = [
		{
			"id": 1,
			"fk_department_id": 1,
			"name": "Developer"
		},
		{
			"id": 2,
			"fk_department_id": 1,
			"name": "Designer"
		},
		{
			"id": 3,
			"fk_department_id": 1,
			"name": "Mobile"
		},
		{
			"id": 4,
			"fk_department_id": 1,
			"name": "QA"
		}
	]
	const [authUserData, setAuthUserData] = useContext(AuthContext);
	const [validate, setValidate] = useState({});
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [address, setAddress] = useState("");
	const [department, setDepartment] = useState("");
	const [teamdepartmentid, setTeamDepartmentId] = useState("");
	const [teamdepartmentList, setTeamDepartmentList] = useState("");
	const [departmentList, setDepartmentList] = useState("");
	const [designation, setDesignation] = useState("");
	const [designationList, setDesignationList] = useState("");
	const [status, setStatus] = useState("");
	const [informToAll, setInformToAll] = useState(false);
	const [sendInduction, setSendInduction] = useState(false);
	const [loading, setLoading] = useState(false);
	const [checked, setChecked] = useState([]);
	const [dateOfBirth, setDateOfBirth] = useState(new Date());
	const [dateOfJoining, setDateOfJoining] = useState(new Date());

	let isToken = '';
	if (authUserData) {
		if (authUserData[0]) {
			isToken = authUserData[0].token ? authUserData[0].token : "";
		}
	}
	const validateEmployee = async () => {
		let isValid = true;

		let validator = Form.validator({
			firstName: {
				value: firstName,
				isRequired: true,
				isEmail: false,
				label: "first name",
			},
			lastName: {
				value: lastName,
				isRequired: true,
				isEmail: false,
				label: "last name",
			},
			email: {
				value: email,
				isRequired: true,
				isEmail: true,
				label: "email",
			},
			phoneNumber: {
				value: phoneNumber,
				isRequired: true,
				isEmail: false,
				label: "phone number",
			},
			department: {
				value: department,
				isRequired: true,
				isEmail: false,
				label: "department",
			},
			// address: {
			// 	value: address,
			// 	isRequired: true,
			// 	isEmail: false,
			// 	label: "address",
			// },
			status: {
				value: status,
				isRequired: true,
				isEmail: false,
				label: "status",
			},
			// dateOfBirth: {
			// 	value: dateOfBirth,
			// 	isRequired: true,
			// 	isEmail: false,
			// 	label: "Date of birth",
			// },
			// dateOfJoining: {
			// 	value: dateOfJoining,
			// 	isRequired: true,
			// 	isEmail: false,
			// 	label: "Date of Joining",
			// },

		});

		if (validator !== null) {
			setValidate({
				validate: validator.errors,
			});
			isValid = false;
		} else {
			setValidate({
				validate: "",
			});
			isValid = true;
		}
		return isValid;
	};
	const HandleInfomChecked = async () => {
		setInformToAll(!informToAll);
	}
	const handleInductionChecked = async () => {
		setSendInduction(!sendInduction);
	}
	const handleFrmSubmit = async (e) => {
		e.preventDefault();
		const validate = await validateEmployee();
		if (validate) {
			setLoading(true);
			await axios
				.post(CONST.EMP_CREATE, {
					first_name: firstName,
					last_name: lastName,
					email: email,
					// address: address,
					department: department,
					designation: designation,
					status: status,
					phone_number: phoneNumber,
					informToAll: informToAll,
					sentEmailtoDept: checked,
					sendInduction: sendInduction,
					login_url: CONST.APP_LINK,
					department_team_id:teamdepartmentid
					// date_of_birth: moment(dateOfBirth).format('YYYY-MM-DD'),
					// date_of_joining: moment(dateOfJoining).format('YYYY-MM-DD'),
				}, {
					headers: {
						'Authorization': `Basic ${isToken}`
					}
				})
				.then((response) => {

					const status = response.data.status;
					setLoading(false);
					if (status === 'error') {
						const errorMsg = response.data.errors;
						let isArray = Array.isArray(errorMsg);
						if (isArray) {
							errorMsg.map(element => {
								toast.error(element.msg);
							})
						}
						else {
							toast.error(errorMsg);
						}
						console.log(errorMsg);
					} else {
						toast.success("Employee account has been created successfully.");
						setFirstName("");
						setLastName("");
						setEmail("");
						setPhoneNumber("");
						setAddress("");
						setDepartment("");
						setDepartmentList("");
						setDesignation("");
						setDesignationList("");
						setTeamDepartmentId("")
						setStatus("");
						setInformToAll(false);
						setSendInduction(false);
						setChecked([]);
					}
				})
				.catch((error) => {
					toast.error("Something went wrong. Please try again.");
				});
			setLoading(false);
		}
	}
	const handleReset = async () => {
		setFirstName("");
		setLastName("");
		setEmail("");
		setPhoneNumber("");
		setAddress("");
		setDepartment("");
		setDesignation("");
		setDesignationList("");
		setTeamDepartmentId("")
		setStatus("");
		setInformToAll(false);
		setSendInduction(false);
		setLoading(false);
		setChecked([]);
	}
	const fetchDepartment = async () => {
		await axios
			.get(CONST.GET_DEPARTMENT, {
				headers: {
					'Authorization': `Basic ${isToken}`
				}
			})
			.then((response) => {
				const status = response.data.status;
				if (status === 'success') {
					setDepartmentList(response.data.data);
				}
			})
			.catch((error) => {
				alert(error);
			});
	}
	const fetchTeamDepartment = async () => {
		await axios
			.get(CONST.GET_DEPARTMENT_TEAM_LIST, {
				headers: {
					'Authorization': `Basic ${isToken}`
				}
			})
			.then((response) => {
				const status = response.data.status;
				if (status === 'success') {
					setTeamDepartmentList(response.data.data);
					// setTeamDepartmentList(departmentTeamList);
				}
			})
			.catch((error) => {
				alert(error);
			});
	}
	useEffect(() => {
		fetchDepartment();
		fetchTeamDepartment()
	}, [])
	console.log(departmentList);

	const handleDesignation = async (did) => {
		setLoading(true);
		setDepartment(did);
		if (did) {
			await axios
				.get(CONST.GET_DESIG_BY_ID + '/' + did, {
					headers: {
						'Authorization': `Basic ${isToken}`
					}
				})
				.then((response) => {
					const status = response.data.status;
					if (status === 'success') {
						setDesignationList(response.data.data);
					}
				})
				.catch((error) => {
					alert(error);
				});
		} else {
			setDesignationList("");
		}
		setLoading(false);
	}
	const handleDeptCheck = async (event) => {
		var updatedList = [...checked];
		if (event.target.checked) {
			updatedList = [...checked, event.target.value];
		} else {
			updatedList.splice(checked.indexOf(event.target.value), 1);
		}
		setChecked(updatedList);
	}
	console.log(checked);
	return (
		<>
			<Loading
				loading={loading}
				background="rgba(0, 0, 0, 0.5)"
				loaderColor="#fff"
			/>

			<HeaderSection
				title="Add"
				heading="Employee"
				addButton=""
				addLink={CONST.ADMIN_PRE + 'employee'}
				addLinkName="Employee List"
				linktype="view"
			/>

			<div className="page-body">
				<div className="container-xl">
					<div className="row row-cards">
						<div className="col-12">
							<div className="card">

								<div className="card-body">
									<form
										method="POST"
										onSubmit={handleFrmSubmit}
										autoComplete={"off"}
									>
										<div className="row">
											<label className="form-label"><h5>Personal Information</h5></label>
											<hr />
											<div className="col-md-4">
												<div className="form-group mb-3 ">
													<label className="form-label required">
														First Name
													</label>
													<div>
														<input
															type="text"
															name="firstName"
															id="firstName"
															className={`form-control ${validate.validate && validate.validate.firstName
																? "is-invalid "
																: ""
																}`}
															aria-describedby="emailHelp"
															placeholder="Enter first name"
															onChange={(e) => setFirstName(e.target.value)}
															value={firstName}
														/>
													</div>
													<div
														className={`invalid-feedback text-start ${validate.validate && validate.validate.firstName
															? "d-block"
															: "d-none"
															}`}
													>
														{validate.validate && validate.validate.firstName
															? validate.validate.firstName[0]
															: ""}{" "}
													</div>{" "}
												</div>
											</div>
											<div className="col-md-4">
												<div className="form-group mb-3 ">
													<label className="form-label required">
														Last Name
													</label>
													<div>
														<input
															type="text"
															name="lastName"
															id="lastName"
															className={`form-control ${validate.validate && validate.validate.lastName
																? "is-invalid "
																: ""
																}`}
															aria-describedby="emailHelp"
															placeholder="Enter last name"
															onChange={(e) => setLastName(e.target.value)}
															value={lastName}
														/>
													</div>
													<div
														className={`invalid-feedback text-start ${validate.validate && validate.validate.lastName
															? "d-block"
															: "d-none"
															}`}
													>
														{validate.validate && validate.validate.lastName
															? validate.validate.lastName[0]
															: ""}{" "}
													</div>{" "}
												</div>
											</div>
											<div className="col-md-4">
												<div className="form-group mb-3 ">
													<label className="form-label required">Email</label>
													<div>
														<input
															type="email"
															className={`form-control ${validate.validate && validate.validate.email
																? "is-invalid "
																: ""
																}`}
															id="email"
															name="email"
															value={email}
															placeholder="your@email.com"
															onChange={(e) => setEmail(e.target.value)}
														/>
														<div
															className={`invalid-feedback text-start mb-2 ${validate.validate && validate.validate.email
																? "d-block"
																: "d-none"
																}`}
														>
															{validate.validate && validate.validate.email
																? validate.validate.email[0]
																: ""}
														</div>
													</div>
												</div>
											</div>
											<div className="col-md-4">
												<div className="form-group mb-3 ">
													<label className="form-label required">
														Phone Number
													</label>
													<div>
														<input
															international
															defaultCountry="US"
															type="text"
															name="phoneNumber"
															id="phoneNumber"
															className={`form-control ${validate.validate && validate.validate.phoneNumber
																? "is-invalid "
																: ""
																}`}
															aria-describedby="emailHelp"
															placeholder="Enter phone number"
															onChange={(e) => setPhoneNumber(e.target.value)}
															value={phoneNumber}
														/>
													</div>
													<div
														className={`invalid-feedback text-start 
                            ${validate.validate && validate.validate.phoneNumber
																? "d-block"
																: "d-none"
															}
                           `}
													>
														{validate.validate && validate.validate.phoneNumber
															? validate.validate.phoneNumber[0]
															: ""}{" "}
													</div>{" "}
													<div
														className="invalid-feedback text-start" >
													</div>
												</div>
											</div>
											{/* <div className="col-md-4">
												<div className="form-group mb-3 ">
													<label className="form-label required">
														Date of Birth
													</label>
													<div>
														<DatePicker
															name="dateOfBirth"
															id='dateOfBirth'
															value={dateOfBirth}
															selected={dateOfBirth}
															timeFormat=""
															dateFormat="DD-MM-YYYY"
															className={`${validate.validate && validate.validate.dateOfBirth
																? "is-invalid "
																: ""
																}`}
															onChange={(date) => setDateOfBirth(date)}
														/>
													</div>
													<div
														className={`invalid-feedback text-start ${validate.validate && validate.validate.dateOfBirth
															? "d-block"
															: "d-none"
															}`}
													>
														{validate.validate && validate.validate.dateOfBirth
															? validate.validate.dateOfBirth[0]
															: ""}{" "}
													</div>{" "}
												</div>
											</div> */}
											{/* <div className="col-md-4">
												<div className="form-group mb-3 ">
													<label className="form-label required">Address</label>
													<div>
														<input
															type="text"
															className={`form-control ${validate.validate && validate.validate.address
																? "is-invalid "
																: ""
																}`}
															id="address"
															name="address"
															value={address}
															placeholder="Enter address"
															onChange={(e) => setAddress(e.target.value)}
														/>
														<div
															className={`invalid-feedback text-start mb-2 ${validate.validate && validate.validate.address
																? "d-block"
																: "d-none"
																}`}
														>
															{validate.validate && validate.validate.address
																? validate.validate.address[0]
																: ""}
														</div>
													</div>
												</div>
											</div> */}
											<div className="col-md-4">
												<div className="mb-3">
													<label className="form-label required">Department</label>
													{
														(departmentList) ? (
															<>



																<select
																	className={`form-select form-control ${validate.validate && validate.validate.department
																		? "is-invalid "
																		: ""
																		}`}
																	name="department"
																	id="department"
																	placeholder="Select department"
																	onChange={(e) => handleDesignation(e.target.value)}

																	value={department}
																>
																	<option value="">Select</option>

																	{
																		departmentList && departmentList.map((element, index) => {
																			return (
																				<option
																					value={element.id}
																					key={index}
																				>{element.name}</option>

																			);
																		})
																	}
																</select>
															</>
														) :
															(
																<>
																	<DivSpinner size="sm" color="primary" />
																</>
															)
													}
													<div
														className={`invalid-feedback text-start ${validate.validate && validate.validate.department
															? "d-block"
															: "d-none"
															}`}
													>
														{validate.validate && validate.validate.department
															? validate.validate.department[0]
															: ""}{" "}
													</div>{" "}
												</div>
											</div>
											{
												designationList &&
												(
													<div className="col-md-4">
														<div className="mb-3">
															<label className="form-label required">Designation</label>
															<select
																className={`form-select form-control ${validate.validate && validate.validate.designation
																	? "is-invalid "
																	: ""
																	}`}
																name="designation"
																id="designation"
																placeholder="Select designation"
																onChange={(e) => setDesignation(e.target.value)}
																value={designation}
															>
																<option value="">Select</option>
																{
																	designationList && designationList.map((element, index) => {
																		return (
																			<option
																				value={element.id}
																				key={index}
																			>{element.name}</option>

																		);
																	})
																}
															</select>
															<div
																className={`invalid-feedback text-start ${validate.validate && validate.validate.designation
																	? "d-block"
																	: "d-none"
																	}`}
															>
																{validate.validate && validate.validate.designation
																	? validate.validate.designation[0]
																	: ""}{" "}
															</div>{" "}
														</div>
													</div>
												)
											}
											{/* <div className="col-md-4">
												<div className="form-group mb-3 ">
													<label className="form-label required">
														Date of Joining
													</label>
													<div>
														<DatePicker
															name="dateOfJoining"
															id='dateOfJoining'
															value={dateOfJoining}
															selected={dateOfJoining}
															timeFormat=""
															dateFormat="DD-MM-YYYY"
															className={`${validate.validate && validate.validate.dateOfJoining
																? "is-invalid "
																: ""
																}`}
															onChange={(date) => setDateOfJoining(date)}
														/>
													</div>
													<div
														className={`invalid-feedback text-start ${validate.validate && validate.validate.dateOfJoining
															? "d-block"
															: "d-none"
															}`}
													>
														{validate.validate && validate.validate.dateOfJoining
															? validate.validate.dateOfJoining[0]
															: ""}{" "}
													</div>{" "}
												</div>
											</div> */}
											{
												department == '1' &&
												(
													<div className="col-md-4">
														<div className="mb-3">
															<label className="form-label required">Department Team</label>
															<select
																className={`form-select form-control ${validate.validate && validate.validate.designation
																	? "is-invalid "
																	: ""
																	}`}
																name="teamdepartmentid"
																id="teamdepartmentid"
																placeholder="Select designation"
																onChange={(e) => setTeamDepartmentId(e.target.value)}
																value={teamdepartmentid}
															>
																<option value="">Select</option>
																{
																	teamdepartmentList && teamdepartmentList.map((element, index) => {
																		return (
																			<option
																				value={element.id}
																				key={index}
																			>{element.name}</option>

																		);
																	})
																}
															</select>
															<div
																className={`invalid-feedback text-start ${validate.validate && validate.validate.teamdepartmentid
																	? "d-block"
																	: "d-none"
																	}`}
															>
																{validate.validate && validate.validate.teamdepartmentid
																	? validate.validate.teamdepartmentid[0]
																	: ""}{" "}
															</div>{" "}
														</div>
													</div>
												)
											}
											
											<div className="col-md-4">
												<div className="mb-3">
													<label className="form-label required">Status</label>
													<select
														className={`form-select form-control ${validate.validate && validate.validate.status
															? "is-invalid "
															: ""
															}`}
														name="status"
														id="status"
														placeholder="Select status"
														onChange={(e) => setStatus(e.target.value)}
														value={status}
													>
														<option value="">Select</option>
														<option value="1">Active</option>
														<option value="0">Deactive</option>
													</select>
													<div
														className={`invalid-feedback text-start ${validate.validate && validate.validate.status
															? "d-block"
															: "d-none"
															}`}
													>
														{validate.validate && validate.validate.status
															? validate.validate.status[0]
															: ""}{" "}
													</div>{" "}
												</div>
											</div>
											<div className='col-md-12'>
												<label className="form-label"><h5>Welcome to eLuminous</h5></label>
												<hr />
											</div>
											<div className="col-md-4">
												<div className="mb-3">
													<input
														type="checkbox"
														className="checkbox"
														name="informToAll"
														id="informToAll"
														placeholder="Select status"
														onChange={(e) => HandleInfomChecked(e.target.value)}
														value={informToAll}
													/>
													<label className="pl-2">Inform to departments?</label>

												</div>
											</div>
											{
												(informToAll) && (
													<>
														<div className='col-md-12 none pl-5' id="informToAllSection">

															<label className="form-label"><h6>Select Departments to which you want to send welcome email</h6></label>
															<div className="row p-0">
																{
																	departmentList && departmentList.map((element, index) => {
																		return (
																			<>
																				<div className="col-xl-3 col-md-4">
																					<div className="mb-3">
																						<input
																							type="checkbox"
																							className="checkbox"
																							name=""
																							id=""
																							placeholder="Select status"
																							onChange={handleDeptCheck}
																							value={element.id}
																						/>
																						<label className="pl-2">{element.name}</label>
																					</div>
																				</div>
																			</>
																		);
																	})
																}


															</div>
														</div>
													</>
												)
											}
											<div className="col-md-12">
												<div className="mb-3">
													<input
														type="checkbox"
														className="checkbox"
														name="sendInduction"
														id="sendInduction"
														onChange={(e) => handleInductionChecked(e.target.value)}
														value={sendInduction}
													/>
													<label className="pl-2">Send Induction?</label>

												</div>
											</div>
											<div className="col-md-12">
												<div className="form-footer">
													<button
														type="submit"
														className="mr-3 btn btn-primary"
													>
														Submit
													</button>
													&nbsp;&nbsp;
													<button
														type="reset"
														className="btn btn-outline-primary"
														onClick={handleReset}
													>
														Reset
													</button>
												</div>
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div >
		</>
	)
}

export default Add