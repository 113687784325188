import React from "react";
import Spinner from "react-bootstrap/Spinner";

function DivSpinner(props) {
    return (
        <>
            <Spinner
                animation="border"
                size={props.size}
                variant={props.color}
                as="div"
            />{" "}
            <span >Loading...</span>
        </>
    );
}

export default DivSpinner;
