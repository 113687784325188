import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "../../../assets/css/dashboard.css";
import DashboardHeader from "../banner/DashboardHeader";
import * as CONST from "../../../component/config/Constant";
import { AuthContext } from "../../../component/context/AuthContext";
import DivSpinner from "../../../component/utilities/Spinner";
import { CircularProgressbar } from "react-circular-progressbar";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ReactReadMoreReadLess from "react-read-more-read-less";
import DataTable from "react-data-table-component";
import moment from "moment";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import Moment from "moment";
function Dashboard() {
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  const [deptStats, setDeptStats] = useState("");
  const [testimonialData, setTestimonialData] = useState("");
  const [dashboadDetails, setDashboadDetails] = useState("");
  const [upcomingLeave, setUpcomingLeave] = useState("");
  const [upcomingEvent, setUpcomingEvent] = useState("");
  const [leaveRemainingCount, setLeaveRemainingCount] = useState("0");
  const [eventRemainingCount, setEventRemainingCount] = useState("0");
  const [headUserLists, setHeadUserLists] = useState("");
  const [profilePercent, setProfilePercent] = useState("0");
  const currentDate = new Date();
  const [myLeaveLisiting, setMyLeaveLisiting] = useState("");
  const [myCompoffLisiting, setMyCompoffLisiting] = useState("");
  const [empCompOffLisiting, setEmpCompOffLisiting] = useState("");
  const [upcomingStats, setUpcomingStats] = useState("");
  const [expandedRowId, setExpandedRowId] = useState(null);


  const profileList = [
    {
      id: "1",
      name: "Personal Information",
      link: "my-profile",
    },
    {
      id: "2",
      name: "Change Password",
      link: "my-profile",
    },
    {
      id: "3",
      name: "Guardian Information",
      link: "my-profile",
    },
    {
      id: "4",
      name: "Education Information",
      link: "my-profile",
    },
    {
      id: "5",
      name: "Document Information",
      link: "my-profile",
    },
    {
      id: "6",
      name: "Experience Information",
      link: "my-profile",
    },
    {
      id: "7",
      name: "Social Links",
      link: "my-profile",
    },
    {
      id: "8",
      name: "My Roles & responsiblity",
      link: "my-roles",
    },
  ];

  const [dataSort, setDataSort] = useState({
    order: "desc",
    order_by: "id",
  });

  const [searchParam, setSearchParam] = useState({
    srchName: "",
    srchStatus: "",
    srchLeaveDate: "",
    srchLeaveType: "",
  });


  const toggleText = (rowId) => {
    setExpandedRowId((prevRowId) => (prevRowId === rowId ? null : rowId));
  };


  let totalCount = 0;
  let dptPercentage = 0;
  let isToken = "";
  let authUserName = "";
  let is_head = 0;
  let is_admin = 0;
  let photo_path = "";
  let userId = 0;

  if (authUserData) {
    console.log("ggggg", authUserData);
    if (authUserData[0]) {
      isToken = authUserData[0].token ? authUserData[0].token : "";
      authUserName = authUserData[0].first_name
        ? authUserData[0].first_name
        : "";
      authUserName += authUserData[0].last_name
        ? " " + authUserData[0].last_name
        : "";
      photo_path = authUserData[0].profile_photo_path
        ? authUserData[0].profile_photo_path
        : "";
      is_head = authUserData[0].is_head ? authUserData[0].is_head : 0;
      is_admin = authUserData[0].is_admin ? authUserData[0].is_admin : 0;
      userId = authUserData[0].user_id ? authUserData[0].user_id : 0;
    }
  }
  const profileImage = CONST.HOST + photo_path;
  async function fetchDepartmentCounts() {
    await axios
      .get(CONST.CNT_DEPARTMENT, {
        headers: {
          Authorization: `Basic ${isToken}`,
        },
      })
      .then((response) => {
        const status = response.data.status;
        //console.log(status);
        if (status === "success") {
          setDeptStats(response.data.data);
        }
      })
      .catch((error) => {
        alert(error);
      });
  }
  async function fetchTestimonialsData() {
    const response = await axios
      .post(
        CONST.ADMIN_TESTIMONIAL_LIST,
        {
          limit: 5,
          page_number: 1,
          search_input: "",
          sort: "",
        },
        {
          headers: {
            Authorization: `Basic ${isToken}`,
          },
        }
      )
      .then((response) => {
        const status = response.data.status;
        if (status == "success") {
          console.log("Itssssssss", response.data);
          setTestimonialData(response.data.data);
        }
      })
      .catch((error) => { });
  }
  /* GET_HEAD_USER */
  async function fetchheadUserLists() {
    const response = await axios
      .get(CONST.GET_HEAD_USER, {
        headers: {
          Authorization: `Basic ${isToken}`,
        },
      })
      .then((response) => {
        const status = response.data.status;
        if (status == "success") {
          setHeadUserLists(response.data.data);
        }
      })
      .catch((error) => { });
  }

  async function fetchDashboardDetails() {
    const response = await axios
      .post(
        CONST.DASHBOARD_DATA,
        {
          is_head: is_head,
          is_admin: is_admin,
        },
        {
          headers: {
            Authorization: `Basic ${isToken}`,
          },
        }
      )
      .then((response) => {
        const status = response.data.status;
        //console.log('dashboard data' + JSON.stringify(response.data.data[0]));
        if (status === "success") {
          console.log('kkkkkkkkk', response.data.data[0])
          var dashBoard = response.data.data[0]
          setDashboadDetails(response.data.data[0]);

          //console.log('leave from date :' + response.data.data[0].upcomingLeavesCount.leave_from_date)
          if (response.data.data[0].upcomingLeavesCount.leave_from_date) {
            const leaveDate = new Date(
              response.data.data[0].upcomingLeavesCount.leave_from_date
            );
            const timeDifference = Math.abs(leaveDate - currentDate);
            const daysRemaining = Math.ceil(
              timeDifference / (1000 * 60 * 60 * 24)
            );
            //console.log('daysRemaining >', daysRemaining);
            setLeaveRemainingCount(daysRemaining);
          }

          if (response.data.data[0].upcomingEventsCount[0].event_date) {
            const eventDate = new Date(
              response.data.data[0].upcomingEventsCount[0].event_date
            );
            const eventTimeDifference = Math.abs(eventDate - currentDate);
            const eventDaysRemaining = Math.ceil(
              eventTimeDifference / (1000 * 60 * 60 * 24)
            );

            setEventRemainingCount(eventDaysRemaining);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function fetchMyLeavesDetails() {
    const response = await axios
      .post(CONST.GET_USER_LEAVES, {
        "id": userId,
        "limit": 5,
        "year": moment().format('YYYY'),
        "page_number": 1,
      },
        {
          headers: {
            Authorization: `Basic ${isToken}`,
          },
        }
      )
      .then((response) => {
        const status = response.data.status;
        //console.log('dashboard data' + JSON.stringify(response.data.data[0]));

        if (status === "success") {
          setMyLeaveLisiting(response.data.data);
        } else {
          setMyLeaveLisiting("");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function fetchMyCompOffDetails() {
    const response = await axios
      .post(
        CONST.COMP_OFF_GET_MY,
        {
          id: userId,
          limit: 5,
          year: "2023",
          page_number: 1,

          month: "",
          sort: dataSort,
          search_input: searchParam,
        },
        {
          headers: {
            Authorization: `Basic ${isToken}`,
          },
        }
      )
      .then((response) => {
        const status = response.data.status;

        if (status === "success") {
          setMyCompoffLisiting(response.data.data);
        } else {
          setMyCompoffLisiting("");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function fetchEmployeeCompOffDetails() {
    const response = await axios
      .post(
        CONST.COMP_OFF_GET_TOCC,
        {
          id: userId,
          limit: 5,
          year: "2023",
          page_number: 1,
          month: "",
          sort: dataSort,
          search_input: searchParam,
          is_admin: is_admin,
          is_head: is_head,
        },
        {
          headers: {
            Authorization: `Basic ${isToken}`,
          },
        }
      )
      .then((response) => {
        const status = response.data.status;
        console.log("dashboard data" + JSON.stringify(response.data.data[0]));
        if (status === "success") {
          setEmpCompOffLisiting(response.data.data);
        } else {
          setEmpCompOffLisiting("");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function fetchFeildsInfo() {
    await axios({
      method: "Post",
      url: CONST.USER_PROFILE_INFO,

      headers: {
        Authorization: `Bearer ${isToken}`,
      },
    })
      .then((response) => {
        const status = response.data.status;
        // console.log('dashboard data' + JSON.stringify(response.data.data[0]));
        if (status === "success") {
          var data = response.data.data;
          setProfilePercent(data);
          console.log(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function fetchUpcomingDetails() {
    await axios
      .get(CONST.UPCOMING_INFO, {
        headers: {
          Authorization: `Basic ${isToken}`,
        },
      })
      .then((response) => {
        const status = response.data.status;
        //console.log(status);
        if (status === "success") {
          setUpcomingStats(response.data.data);
          console.log("UpcomingStats: " + JSON.stringify(upcomingStats));
          //alert('UpcomingStats: '+JSON.stringify(upcomingStats[0].todayBirthdayUsers));
        }
      })
      .catch((error) => {
        alert(error);
      });
  }

  const formatTime = (date) => {
    return date.slice(0, -3);
  };

  useEffect(() => {
    fetchDepartmentCounts();
    fetchTestimonialsData();
    fetchDashboardDetails();
    fetchheadUserLists();
    fetchFeildsInfo();
    fetchMyLeavesDetails();
    fetchMyCompOffDetails();
    fetchEmployeeCompOffDetails();
    fetchUpcomingDetails();
  }, []);

  return (
    <>
      <div className="page-wrapper">
        <DashboardHeader userName={authUserName} />
        <div className="page-body">
          <section className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className="row row-cards">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-body card-body-scrollable card-body-scrollable-shadow">
                        <div className="divide-y">
                          {upcomingStats
                            ? upcomingStats[0].todayBirthdayUsers.map(
                              (Stats, index) => {
                                return (
                                  <>
                                    <div>
                                      <div class="row">
                                        <div class="col-auto">
                                          {Stats.profile_photo_path ? (
                                            <div class="d-flex py-1 align-items-center">
                                              <span class="avatar    ">
                                                <img
                                                  src={
                                                    CONST.HOST +
                                                    Stats.profile_photo_path
                                                  }
                                                  alt=""
                                                  width="40"
                                                  class="rounded-circle"
                                                />
                                              </span>
                                            </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                        <div class="col">
                                          <div class="text-truncate">
                                            It's{" "}
                                            <strong>
                                              {" "}
                                              {Stats.first_name
                                                ? Stats.first_name
                                                : ""}{" "}
                                              {Stats.last_name
                                                ? Stats.last_name
                                                : ""}
                                            </strong>
                                            's birthday. Wish well!
                                          </div>
                                          <div class="text-secondary">
                                            today
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              }
                            )
                            : ""}

                          {upcomingStats
                            ? upcomingStats[0].newJoineeUsers.map(
                              (Stats, index) => {
                                //console.log('stats-----'+JSON.stringify(Stats));
                                return (
                                  <>
                                    <div>
                                      <div class="row">
                                        {Stats.profile_photo_path ? (
                                          <div class="col-auto">
                                            <div class="d-flex py-1 align-items-center">
                                              <span class="avatar    ">
                                                <img
                                                  src={
                                                    CONST.HOST +
                                                    Stats.profile_photo_path
                                                  }
                                                  alt=""
                                                  width="40"
                                                  class="rounded-circle"
                                                />
                                              </span>
                                            </div>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                        <div class="col">
                                          <div class="text-truncate">
                                            New joinee{" "}
                                            <strong>
                                              {" "}
                                              {Stats.first_name
                                                ? Stats.first_name
                                                : ""}{" "}
                                              {Stats.last_name
                                                ? Stats.last_name
                                                : ""}{" "}
                                            </strong>
                                            from{" "}
                                            <strong>
                                              {Stats.department.name
                                                ? Stats.department.name
                                                : ""}{" "}
                                            </strong>
                                          </div>
                                          <div class="text-secondary">
                                            {Stats.date_of_joining
                                              ? Stats.date_of_joining
                                              : ""}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              }
                            )
                            : ""}

                          {upcomingStats
                            ? upcomingStats[0].upcomingEventDetails.map(
                              (event, index) => {
                                return (
                                  <>
                                    <div>
                                      <div class="row">
                                        <div class="col-auto">
                                          <div class="d-flex py-1 align-items-center">
                                            <span class="avatar    ">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="icon icon-tabler icon-tabler-calendar-event"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                stroke-width="2"
                                                stroke="currentColor"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              >
                                                <path
                                                  stroke="none"
                                                  d="M0 0h24v24H0z"
                                                  fill="none"
                                                ></path>
                                                <path d="M4 5m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"></path>
                                                <path d="M16 3l0 4"></path>
                                                <path d="M8 3l0 4"></path>
                                                <path d="M4 11l16 0"></path>
                                                <path d="M8 15h2v2h-2z"></path>
                                              </svg>
                                            </span>
                                          </div>
                                        </div>
                                        <div class="col">
                                          <div class="text-truncate">
                                            Upcoming event{" "}
                                            <strong>
                                              {event.title ? event.title : ""}
                                            </strong>
                                          </div>
                                          <div class="text-secondary">
                                            {event.event_date
                                              ? event.event_date
                                              : ""}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              }
                            )
                            : ""}

                          {upcomingStats
                            ? upcomingStats[0].holidayDetails.map(
                              (Stats, index) => {
                                return (
                                  <>
                                    <div>
                                      <div class="row">
                                        <div class="col-auto">
                                          <div class="d-flex py-1 align-items-center">
                                            <span class="avatar    ">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="icon icon-tabler icon-tabler-christmas-tree"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                stroke-width="2"
                                                stroke="currentColor"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              >
                                                <path
                                                  stroke="none"
                                                  d="M0 0h24v24H0z"
                                                  fill="none"
                                                ></path>
                                                <path d="M12 3l4 4l-2 1l4 4l-3 1l4 4h-14l4 -4l-3 -1l4 -4l-2 -1z"></path>
                                                <path d="M14 17v3a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-3"></path>
                                              </svg>
                                            </span>
                                          </div>
                                        </div>
                                        <div class="col">
                                          <div class="text-truncate">
                                            It's{" "}
                                            <strong>
                                              {Stats.holiday_name
                                                ? Stats.holiday_name
                                                : ""}{" "}
                                            </strong>{" "}
                                            Holiday !!
                                          </div>
                                          <div class="text-secondary">
                                            {Stats.holiday_date
                                              ? Stats.holiday_date
                                              : ""}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              }
                            )
                            : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Department Heads</h3>
                      </div>
                      {headUserLists &&
                        (headUserLists ? (
                          <>
                            {headUserLists.map((headUser, index) => {
                              let userName = headUser.name.split(" ");

                              return (
                                <>
                                  <div class="row row-cards">
                                    <div class="col-12">
                                      <div class="card">
                                        <div class="card-body card-body-scrollable card-body-scrollable-shadow">
                                          <div class="divide-y">
                                            <div>
                                              <div class="row">
                                                <div className="d-flex align-items-center">
                                                  {headUser.profile_photo_path ? (
                                                    <span className="avatar me-2 mr-2">
                                                      <img
                                                        src={
                                                          CONST.HOST +
                                                          headUser.profile_photo_path
                                                        }
                                                        alt="emp-img"
                                                        width="40"
                                                        className="rounded-circle"
                                                      />
                                                    </span>
                                                  ) : (
                                                    <span className="avatar rounded-circle me-2 mr-2">
                                                      {headUser.name ? (
                                                        <>
                                                          {" "}
                                                          {userName[0] &&
                                                            userName[0].substring(
                                                              0,
                                                              1
                                                            )}
                                                          {userName[1] &&
                                                            userName[1].substring(
                                                              0,
                                                              1
                                                            )}
                                                        </>
                                                      ) : (
                                                        headUser.name &&
                                                        headUser.name.substring(
                                                          0,
                                                          2
                                                        )
                                                      )}
                                                    </span>
                                                  )}
                                                  <div className="flex-fill pt-1">
                                                    <div
                                                      className="font-weight-medium"
                                                      style={{ color: "#000" }}
                                                    >
                                                      {headUser.name},{" "}
                                                      <span className="text-muted">
                                                        <small>
                                                          {headUser.department}
                                                        </small>
                                                      </span>
                                                      ,{" "}
                                                      <span className="text-muted">
                                                        <small>
                                                          {headUser.designation}
                                                        </small>
                                                      </span>{" "}
                                                    </div>
                                                    {headUser.phone_number && (
                                                      <>
                                                        <div className="text-muted pt-1 ">
                                                          <span className="text-muted">
                                                            <small>
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                class="icon icon-tabler icon-tabler-phone-call"
                                                                width="16"
                                                                height="16"
                                                                viewBox="0 0 24 24"
                                                                stroke-width="2"
                                                                stroke="currentColor"
                                                                fill="none"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                              >
                                                                <path
                                                                  stroke="none"
                                                                  d="M0 0h24v24H0z"
                                                                  fill="none"
                                                                ></path>
                                                                <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"></path>
                                                                <path d="M15 7a2 2 0 0 1 2 2"></path>
                                                                <path d="M15 3a6 6 0 0 1 6 6"></path>
                                                              </svg>
                                                              <Link to="tel:{headUser.phone_number}">
                                                                {
                                                                  headUser.phone_number
                                                                }
                                                              </Link>
                                                            </small>
                                                          </span>
                                                        </div>
                                                      </>
                                                    )}
                                                    {headUser.email && (
                                                      <>
                                                        <div className="text-muted pt-1">
                                                          <span className="text-muted">
                                                            <small>
                                                              <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                class="text-dark icon icon-tabler icon-tabler-mail-filled"
                                                                width="16"
                                                                height="16"
                                                                viewBox="0 0 24 24"
                                                                stroke-width="2"
                                                                stroke="currentColor"
                                                                fill="none"
                                                                stroke-linecap="round"
                                                                stroke-linejoin="round"
                                                              >
                                                                <path
                                                                  stroke="none"
                                                                  d="M0 0h24v24H0z"
                                                                  fill="none"
                                                                ></path>
                                                                <path
                                                                  d="M22 7.535v9.465a3 3 0 0 1 -2.824 2.995l-.176 .005h-14a3 3 0 0 1 -2.995 -2.824l-.005 -.176v-9.465l9.445 6.297l.116 .066a1 1 0 0 0 .878 0l.116 -.066l9.445 -6.297z"
                                                                  stroke-width="0"
                                                                  fill="currentColor"
                                                                ></path>
                                                                <path
                                                                  d="M19 4c1.08 0 2.027 .57 2.555 1.427l-9.555 6.37l-9.555 -6.37a2.999 2.999 0 0 1 2.354 -1.42l.201 -.007h14z"
                                                                  stroke-width="0"
                                                                  fill="currentColor"
                                                                ></path>
                                                              </svg>
                                                              <Link to="mailto:{headUser.email}">
                                                                {" "}
                                                                {headUser.email}
                                                              </Link>
                                                            </small>
                                                          </span>
                                                        </div>
                                                      </>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}
                          </>
                        ) : (
                          <DivSpinner size="sm" color="primary" />
                        ))}

                      {/*  {
                      deptStats ? (
                        <>
                          <table className="table card-table table-vcenter">
                            <thead>
                              <tr>
                                <th className="w-50">Name</th>
                                <th colSpan={2}>Count</th>
                                <th >Total</th>

                              </tr>
                            </thead>
                            <tbody>
                              {

                                deptStats.map((dept, index) => {
                                  totalCount = totalCount + dept.total;
                                  dptPercentage = ((dept.active * 100) / totalCount);
                                 
                                  return (
                                    <>
                                      <tr>
                                        <td>{dept.name}</td>
                                        <td>{dept.active}</td>
                                        <td className="w-25">
                                          <div className="progress progress-xs">
                                            <div className="progress-bar bg-primary" style={{ width: `${dptPercentage}%` }} ></div>
                                          </div>

                                        </td>
                                        <td>{dept.active + dept.inactive}</td>
                                      </tr>
                                    </>
                                  )
                                })
                              }
                            </tbody>
                          </table>
                        </>
                      ) : (
                        <>
                          <div className='p-3 text-center'>
                            <DivSpinner size="sm" color="primary" />

                          </div>
                        </>
                      )
                    } */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-8">
                <div className="row pb-3 dashboard-dept">
                  <div className="col-md-4 col-xl-4">
                    <div className="card card-sm">
                      <div className="card-body">
                        <div className="row align-items-center">
                          <div className="col-auto">
                            <span className="bg-green-lt avatar">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-door-exit"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="currentColor"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                ></path>
                                <path d="M13 12v.01"></path>
                                <path d="M3 21h18"></path>
                                <path d="M5 21v-16a2 2 0 0 1 2 -2h7.5m2.5 10.5v7.5"></path>
                                <path d="M14 7h7m-3 -3l3 3l-3 3"></path>
                              </svg>
                            </span>
                          </div>
                          <div className="col">
                            <div className="font-weight-medium">
                              <strong> My Total Leaves</strong>
                            </div>
                            <div className="text-muted">
                              {dashboadDetails.allocatedLeaves
                                ? dashboadDetails.allocatedLeaves
                                : 0}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-xl-4">
                    <div className="card card-sm">
                      <div className="card-body">
                        <div className="row align-items-center">
                          <div className="col-auto">
                            <span className="bg-red-lt avatar">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-calendar-minus"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="currentColor"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                ></path>
                                <path d="M12.5 21h-6.5a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v8"></path>
                                <path d="M16 3v4"></path>
                                <path d="M8 3v4"></path>
                                <path d="M4 11h16"></path>
                                <path d="M16 19h6"></path>
                              </svg>
                            </span>
                          </div>
                          <div className="col">
                            <div className="font-weight-medium">
                              <strong>My Leaves Used</strong>
                            </div>
                            <div className="text-muted">
                              {dashboadDetails.leavesUsedCount
                                ? dashboadDetails.leavesUsedCount
                                : 0}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-xl-4">
                    <div className="card card-sm">
                      <div className="card-body">
                        <div className="row align-items-center">
                          <div className="col-auto">
                            <span className="bg-green-lt avatar">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-calendar-time"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="currentColor"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                ></path>
                                <path d="M11.795 21h-6.795a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v4"></path>
                                <path d="M18 18m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"></path>
                                <path d="M15 3v4"></path>
                                <path d="M7 3v4"></path>
                                <path d="M3 11h16"></path>
                                <path d="M18 16.496v1.504l1 1"></path>
                              </svg>
                            </span>
                          </div>
                          <div className="col">
                            <div className="font-weight-medium">
                              <strong>My Pending Leaves</strong>
                            </div>
                            <div className="text-muted">
                              {dashboadDetails.pendingLeaves
                                ? dashboadDetails.pendingLeaves
                                : 0}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-xl-4 pt-1">
                    <div className="card card-sm">
                      <div className="card-body">
                        <div className="row align-items-center">
                          <div className="col-auto">
                            <span className="bg-green-lt avatar">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-door-exit"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="currentColor"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                ></path>
                                <path d="M13 12v.01"></path>
                                <path d="M3 21h18"></path>
                                <path d="M5 21v-16a2 2 0 0 1 2 -2h7.5m2.5 10.5v7.5"></path>
                                <path d="M14 7h7m-3 -3l3 3l-3 3"></path>
                              </svg>
                            </span>
                          </div>
                          <div className="col">
                            <div className="font-weight-medium">
                              <strong>My Total Comp-Off with Leaves</strong>
                            </div>
                            <div className="text-muted">
                              {dashboadDetails.pendingLeaves
                                ? dashboadDetails.pendingLeaves
                                : 0}{" "}
                              +
                              {dashboadDetails.totalApprovedCompoffCount
                                ? dashboadDetails.totalApprovedCompoffCount
                                : 0}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-xl-4 pt-1">
                    <div className="card card-sm">
                      <div className="card-body">
                        <div className="row align-items-center">
                          <div className="col-auto">
                            <span className="bg-green-lt avatar">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-calendar-plus"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="currentColor"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                ></path>
                                <path d="M12.5 21h-6.5a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v5"></path>
                                <path d="M16 3v4"></path>
                                <path d="M8 3v4"></path>
                                <path d="M4 11h16"></path>
                                <path d="M16 19h6"></path>
                                <path d="M19 16v6"></path>
                              </svg>
                            </span>
                          </div>
                          <div className="col">
                            <div className="font-weight-medium">
                              <strong>My Approved Comp-Off</strong>
                            </div>
                            <div className="text-muted">
                              {dashboadDetails.totalApprovedCompoffCount
                                ? dashboadDetails.totalApprovedCompoffCount
                                : 0}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4 col-xl-4 pt-1">
                    <div className="card card-sm">
                      <div className="card-body">
                        <div className="row align-items-center">
                          <div className="col-auto">
                            <span className="bg-red-lt avatar">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-calendar-minus"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="currentColor"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                ></path>
                                <path d="M12.5 21h-6.5a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v8"></path>
                                <path d="M16 3v4"></path>
                                <path d="M8 3v4"></path>
                                <path d="M4 11h16"></path>
                                <path d="M16 19h6"></path>
                              </svg>
                            </span>
                          </div>
                          <div className="col">
                            <div className="font-weight-medium">
                              <strong>My Rejected Comp-Off</strong>
                            </div>
                            <div className="text-muted">
                              {dashboadDetails.totalRejectedCompoffCount
                                ? dashboadDetails.totalRejectedCompoffCount
                                : 0}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {
                  // dashboadDetails
                }

                {/* here the profile html */}
                <div className="col-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Profile</h3>
                    </div>
                    <div className="row">
                      {" "}
                      {/* Add a row container */}
                      <div className="col-5 p-5">
                        <CircularProgressbarWithChildren value={profilePercent}>
                          {/* Put any JSX content in here that you'd like. It'll be vertically and horizonally centered. */}
                          <img
                            style={{ width: 60, marginTop: -5 }}
                            src={profileImage}
                            alt="profile"
                          />
                          <div style={{ fontSize: 25, marginTop: 4 }}>
                            <strong>{profilePercent}%</strong>
                          </div>
                        </CircularProgressbarWithChildren>
                      </div>
                      <div className="col-5">
                        <table className="table card-table table-vcenter">
                          <thead>
                            <tr>
                              <th className="w-50">Remaining Fields</th>
                              <th>Link</th>
                            </tr>
                          </thead>
                          <tbody>
                            {profileList.map((dept, index) => {
                              return (
                                <>
                                  <tr>
                                    <td>{dept.name}</td>
                                    <td className="w-25">
                                      <Link
                                        className="page-scroll"
                                        aria-current="page"
                                        to={CONST.ADMIN_PRE + dept.link}
                                      >
                                        Link
                                      </Link>
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                {/* here the end  */}

                <div className="row row-cards dashboard-upcoming-event pb-3">
                  <div className="col-md-4 col-lg-4"></div>
                </div>
              </div>
            </div>
            <div className="row pt-1">
              <div className="card">
                <Tabs>
                  <TabList>
                    {(() => {
                      if (is_admin == "1" || is_head == "1") {
                        return (
                          <>
                            <Tab>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="cursor mr-2 icon icon-tabler icon-tabler-door-exit"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="currentColor"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                ></path>
                                <path d="M13 12v.01"></path>
                                <path d="M3 21h18"></path>
                                <path d="M5 21v-16a2 2 0 0 1 2 -2h7.5m2.5 10.5v7.5"></path>
                                <path d="M14 7h7m-3 -3l3 3l-3 3"></path>
                              </svg>
                              Employee Leaves
                            </Tab>
                            <Tab>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="icon icon-tabler icon-tabler-align-box-bottom-left"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="2"
                                stroke="currentColor"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                ></path>
                                <path d="M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z"></path>
                                <path d="M7 15v2"></path>
                                <path d="M10 11v6"></path>
                                <path d="M13 13v4"></path>
                              </svg>
                              Employee Comp-Off
                            </Tab>
                          </>
                        );
                      }
                    })()}

                    <Tab>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="cursor mr-2 icon icon-tabler icon-tabler-door-exit"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M13 12v.01"></path>
                        <path d="M3 21h18"></path>
                        <path d="M5 21v-16a2 2 0 0 1 2 -2h7.5m2.5 10.5v7.5"></path>
                        <path d="M14 7h7m-3 -3l3 3l-3 3"></path>
                      </svg>
                      My Leaves
                    </Tab>
                    <Tab>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-align-box-bottom-left"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"
                        ></path>
                        <path d="M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z"></path>
                        <path d="M7 15v2"></path>
                        <path d="M10 11v6"></path>
                        <path d="M13 13v4"></path>
                      </svg>
                      My Comp-Off
                    </Tab>
                  </TabList>

                  {(() => {
                    if (is_admin == "1" || is_head == "1") {
                      return (
                        <>
                          <TabPanel>
                            <h4>Employee Leaves</h4>
                            <div className="card-table table-responsive ">
                              <table className="table table-striped table-bordered table-sm">
                                <thead>
                                  <tr>
                                    <th scope="col">User</th>
                                    <th scope="col"> Comment</th>
                                    <th scope="col">Leave Dates</th>
                                    <th scope="col">Applied Dates</th>
                                    <th scope="col">Status</th>
                                  </tr>
                                </thead>
                                <tbody className="table-tbody">
                                  {dashboadDetails.leavedTableListing &&
                                    dashboadDetails != "" ? (
                                    dashboadDetails.leavedTableListing.map(
                                      (leave, i) => (
                                        <>
                                          <tr id={leave.id}>
                                            <td className="w-1">
                                              <div className="d-flex py-1 align-items-center">
                                                {leave.user
                                                  .profile_photo_path ? (
                                                  <span className="avatar me-2 mr-2">
                                                    <img
                                                      src={
                                                        CONST.HOST +
                                                        leave.user
                                                          .profile_photo_path
                                                      }
                                                      alt="emp-img"
                                                      width="40"
                                                      className="rounded-circle"
                                                    />
                                                  </span>
                                                ) : (
                                                  <span className="avatar rounded-circle me-2 mr-2">
                                                    {leave.user.first_name &&
                                                      leave.user.last_name ? (
                                                      <>
                                                        {" "}
                                                        {leave.user
                                                          .first_name &&
                                                          leave.user.first_name.substring(
                                                            0,
                                                            1
                                                          )}
                                                        {leave.user.last_name &&
                                                          leave.user.last_name.substring(
                                                            0,
                                                            1
                                                          )}
                                                      </>
                                                    ) : (
                                                      leave.user.name &&
                                                      leave.user.name.substring(
                                                        0,
                                                        2
                                                      )
                                                    )}
                                                  </span>
                                                )}
                                                <div className="flex-fill">
                                                  <div className="font-weight-medium">
                                                    {leave.user.first_name}{" "}
                                                    {leave.user.last_name}
                                                  </div>
                                                  <div className="text-muted">
                                                    <span className="text-muted">
                                                      <small>
                                                        {
                                                          leave.user.department
                                                            .name
                                                        }
                                                      </small>
                                                    </span>
                                                  </div>
                                                </div>
                                              </div>
                                            </td>
                                            <td className="w-1">
                                              {leave.reason.length > 50 &&
                                                expandedRowId !== leave.id ? (
                                                <span
                                                  dangerouslySetInnerHTML={{
                                                    __html:
                                                      leave.reason.slice(
                                                        0,
                                                        50
                                                      ) + "...",
                                                  }}
                                                ></span>
                                              ) :
                                                (
                                                  <span
                                                    dangerouslySetInnerHTML={{
                                                      __html: leave.reason,
                                                    }}
                                                  ></span>
                                                )}
                                              {leave.reason.length > 50 && (
                                                <button
                                                  onClick={() =>
                                                    toggleText(leave.id)
                                                  }
                                                >
                                                  {expandedRowId === leave.id
                                                    ? "Read Less"
                                                    : "Read More"}
                                                </button>
                                              )}
                                            </td>
                                            <td className="text-nowrap text-muted">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="icon"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                strokeWidth="2"
                                                stroke="currentColor"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              >
                                                <path
                                                  stroke="none"
                                                  d="M0 0h24v24H0z"
                                                  fill="none"
                                                ></path>
                                                <rect
                                                  x="4"
                                                  y="5"
                                                  width="16"
                                                  height="16"
                                                  rx="2"
                                                ></rect>
                                                <line
                                                  x1="16"
                                                  y1="3"
                                                  x2="16"
                                                  y2="7"
                                                ></line>
                                                <line
                                                  x1="8"
                                                  y1="3"
                                                  x2="8"
                                                  y2="7"
                                                ></line>
                                                <line
                                                  x1="4"
                                                  y1="11"
                                                  x2="20"
                                                  y2="11"
                                                ></line>
                                                <line
                                                  x1="11"
                                                  y1="15"
                                                  x2="12"
                                                  y2="15"
                                                ></line>
                                                <line
                                                  x1="12"
                                                  y1="15"
                                                  x2="12"
                                                  y2="18"
                                                ></line>
                                              </svg>
                                              {Moment(
                                                leave.leave_from_date
                                              ).format("DD, MMM. YYYY ")}
                                            </td>
                                            <td className="text-nowrap text-muted">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="icon"
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                strokeWidth="2"
                                                stroke="currentColor"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              >
                                                <path
                                                  stroke="none"
                                                  d="M0 0h24v24H0z"
                                                  fill="none"
                                                ></path>
                                                <rect
                                                  x="4"
                                                  y="5"
                                                  width="16"
                                                  height="16"
                                                  rx="2"
                                                ></rect>
                                                <line
                                                  x1="16"
                                                  y1="3"
                                                  x2="16"
                                                  y2="7"
                                                ></line>
                                                <line
                                                  x1="8"
                                                  y1="3"
                                                  x2="8"
                                                  y2="7"
                                                ></line>
                                                <line
                                                  x1="4"
                                                  y1="11"
                                                  x2="20"
                                                  y2="11"
                                                ></line>
                                                <line
                                                  x1="11"
                                                  y1="15"
                                                  x2="12"
                                                  y2="15"
                                                ></line>
                                                <line
                                                  x1="12"
                                                  y1="15"
                                                  x2="12"
                                                  y2="18"
                                                ></line>
                                              </svg>
                                              {Moment(leave.created_at).format(
                                                "DD, MMM. YYYY "
                                              )}
                                            </td>
                                            <td className="w-1">
                                              {leave.status == 1
                                                ? "Approved"
                                                : leave.status == 2
                                                  ? "Rejected"
                                                  : leave.status == 3
                                                    ? "Cancelled"
                                                    : "Pending"}
                                            </td>
                                          </tr>
                                        </>
                                      )
                                    )
                                  ) : (
                                    <>
                                      <div className="p-3 text-center">
                                        {/* <DivSpinner size="sm" color="primary" /> */}
                                        <div>No Leaves Found!</div>
                                      </div>
                                    </>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </TabPanel>

                          <TabPanel>
                            <h4>Employee Comp-Off</h4>
                            <div className="card-table table-responsive ">
                              <table className="table table-striped table-bordered table-sm">
                                <thead>
                                  <tr>
                                    <th scope="col">User</th>
                                    <th scope="col"> Reason</th>
                                    <th scope="col">Compoff Dates</th>
                                    <th scope="col">Applied Dates</th>
                                    <th scope="col">Status</th>
                                  </tr>
                                </thead>
                                <tbody className="table-tbody">
                                  {empCompOffLisiting &&
                                    empCompOffLisiting != "" ? (
                                    empCompOffLisiting.map((leave, i) => (
                                      <>
                                        <tr id={leave.id}>
                                          <td className="w-1">
                                            <div className="d-flex py-1 align-items-center">
                                              {leave.compoffAddedBy
                                                .profile_photo_path ? (
                                                <span className="avatar me-2 mr-2">
                                                  <img
                                                    src={
                                                      CONST.HOST +
                                                      leave.compoffAddedBy
                                                        .profile_photo_path
                                                    }
                                                    alt="emp-img"
                                                    width="40"
                                                    className="rounded-circle"
                                                  />
                                                </span>
                                              ) : (
                                                <span className="avatar rounded-circle me-2 mr-2">
                                                  {leave.compoffAddedBy
                                                    .first_name &&
                                                    leave.compoffAddedBy
                                                      .last_name ? (
                                                    <>
                                                      {" "}
                                                      {leave.compoffAddedBy
                                                        .first_name &&
                                                        leave.compoffAddedBy.first_name.substring(
                                                          0,
                                                          1
                                                        )}
                                                      {leave.compoffAddedBy
                                                        .last_name &&
                                                        leave.compoffAddedBy.last_name.substring(
                                                          0,
                                                          1
                                                        )}
                                                    </>
                                                  ) : (
                                                    leave.compoffAddedBy.name &&
                                                    leave.compoffAddedBy.name.substring(
                                                      0,
                                                      2
                                                    )
                                                  )}
                                                </span>
                                              )}
                                              <div className="flex-fill">
                                                <div className="font-weight-medium">
                                                  {
                                                    leave.compoffAddedBy
                                                      .first_name
                                                  }{" "}
                                                  {
                                                    leave.compoffAddedBy
                                                      .last_name
                                                  }
                                                </div>
                                                <div className="text-muted">
                                                  <span className="text-muted">
                                                    <small>
                                                      {
                                                        leave.compoffAddedBy
                                                          .department.name
                                                      }
                                                    </small>
                                                  </span>
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                          {/* <td className="w-1">
                                            <span
                                              dangerouslySetInnerHTML={{
                                                __html: leave.reason,
                                              }}
                                            ></span>{" "}
                                          </td> */}
                                          <td className="w-1">
                                            {leave.reason.length > 50 &&
                                              expandedRowId !== leave.id ? (
                                              <span
                                                dangerouslySetInnerHTML={{
                                                  __html:
                                                    leave.reason.slice(
                                                      0,
                                                      50
                                                    ) + "...",
                                                }}
                                              ></span>
                                            ) :
                                              (
                                                <span
                                                  dangerouslySetInnerHTML={{
                                                    __html: leave.reason,
                                                  }}
                                                ></span>
                                              )}
                                            {leave.reason.length > 50 && (
                                              <button
                                                onClick={() =>
                                                  toggleText(leave.id)
                                                }
                                              >
                                                {expandedRowId === leave.id
                                                  ? "Read Less"
                                                  : "Read More"}
                                              </button>
                                            )}
                                          </td>
                                          <td className="text-nowrap text-muted">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              className="icon"
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              strokeWidth="2"
                                              stroke="currentColor"
                                              fill="none"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            >
                                              <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                                fill="none"
                                              ></path>
                                              <rect
                                                x="4"
                                                y="5"
                                                width="16"
                                                height="16"
                                                rx="2"
                                              ></rect>
                                              <line
                                                x1="16"
                                                y1="3"
                                                x2="16"
                                                y2="7"
                                              ></line>
                                              <line
                                                x1="8"
                                                y1="3"
                                                x2="8"
                                                y2="7"
                                              ></line>
                                              <line
                                                x1="4"
                                                y1="11"
                                                x2="20"
                                                y2="11"
                                              ></line>
                                              <line
                                                x1="11"
                                                y1="15"
                                                x2="12"
                                                y2="15"
                                              ></line>
                                              <line
                                                x1="12"
                                                y1="15"
                                                x2="12"
                                                y2="18"
                                              ></line>
                                            </svg>
                                            {Moment(
                                              leave.leave_from_date
                                            ).format("DD, MMM. YYYY ")}
                                          </td>
                                          <td className="text-nowrap text-muted">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              className="icon"
                                              width="24"
                                              height="24"
                                              viewBox="0 0 24 24"
                                              strokeWidth="2"
                                              stroke="currentColor"
                                              fill="none"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            >
                                              <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                                fill="none"
                                              ></path>
                                              <rect
                                                x="4"
                                                y="5"
                                                width="16"
                                                height="16"
                                                rx="2"
                                              ></rect>
                                              <line
                                                x1="16"
                                                y1="3"
                                                x2="16"
                                                y2="7"
                                              ></line>
                                              <line
                                                x1="8"
                                                y1="3"
                                                x2="8"
                                                y2="7"
                                              ></line>
                                              <line
                                                x1="4"
                                                y1="11"
                                                x2="20"
                                                y2="11"
                                              ></line>
                                              <line
                                                x1="11"
                                                y1="15"
                                                x2="12"
                                                y2="15"
                                              ></line>
                                              <line
                                                x1="12"
                                                y1="15"
                                                x2="12"
                                                y2="18"
                                              ></line>
                                            </svg>
                                            {Moment(
                                              leave.created_at
                                            ).format("DD, MMM. YYYY ")}
                                          </td>
                                          <td className="w-1">
                                            {leave.status == 1
                                              ? "Approved"
                                              : leave.status == 2
                                                ? "Rejected"
                                                : leave.status == 3
                                                  ? "Cancelled"
                                                  : "Pending"}
                                          </td>
                                        </tr>
                                      </>
                                    ))
                                  ) : (
                                    <>
                                      <div className="p-3 text-center">
                                        {/* <DivSpinner size="sm" color="primary" /> */}
                                        <div>No Comp-Off Found!</div>
                                      </div>
                                    </>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </TabPanel>
                        </>
                      );
                    }
                  })()}

                  <TabPanel>
                    <h4>My Leaves</h4>
                    <div className="card-table table-responsive ">
                      <table className="table table-striped table-bordered table-sm">
                        <thead>
                          <tr>
                            <th scope="col">User</th>
                            <th scope="col"> Reason</th>
                            <th scope="col">Leave Dates</th>
                            <th scope="col">Applied Dates</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>
                        <tbody className="table-tbody">
                          {myLeaveLisiting && myLeaveLisiting != "" ? (
                            myLeaveLisiting.map((leave, i) => (
                              <>
                                <tr id={leave.id}>
                                  <td className="w-1">
                                    <div className="d-flex py-1 align-items-center">
                                      {leave.user.profile_photo_path ? (
                                        <span className="avatar me-2 mr-2">
                                          <img
                                            src={
                                              CONST.HOST +
                                              leave.user.profile_photo_path
                                            }
                                            alt="emp-img"
                                            width="40"
                                            className="rounded-circle"
                                          />
                                        </span>
                                      ) : (
                                        <span className="avatar rounded-circle me-2 mr-2">
                                          {leave.user.first_name &&
                                            leave.user.last_name ? (
                                            <>
                                              {" "}
                                              {leave.user.first_name &&
                                                leave.user.first_name.substring(
                                                  0,
                                                  1
                                                )}
                                              {leave.user.last_name &&
                                                leave.user.last_name.substring(
                                                  0,
                                                  1
                                                )}
                                            </>
                                          ) : (
                                            leave.user.name &&
                                            leave.user.name.substring(0, 2)
                                          )}
                                        </span>
                                      )}
                                      <div className="flex-fill">
                                        <div className="font-weight-medium">
                                          {leave.user.first_name}{" "}
                                          {leave.user.last_name}
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  {/* <td className="w-1">
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: leave.reason,
                                      }}
                                    ></span>{" "}
                                  </td> */}
                                  <td className="w-1">
                                    {leave.reason.length > 50 &&
                                      expandedRowId !== leave.id ? (
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            leave.reason.slice(
                                              0,
                                              50
                                            ) + "...",
                                        }}
                                      ></span>
                                    ) :
                                      (
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: leave.reason,
                                          }}
                                        ></span>
                                      )}
                                    {leave.reason.length > 50 && (
                                      <button
                                        onClick={() =>
                                          toggleText(leave.id)
                                        }
                                      >
                                        {expandedRowId === leave.id
                                          ? "Read Less"
                                          : "Read More"}
                                      </button>
                                    )}
                                  </td>
                                  <td className="text-nowrap text-muted">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="icon"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      strokeWidth="2"
                                      stroke="currentColor"
                                      fill="none"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    >
                                      <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                      ></path>
                                      <rect
                                        x="4"
                                        y="5"
                                        width="16"
                                        height="16"
                                        rx="2"
                                      ></rect>
                                      <line
                                        x1="16"
                                        y1="3"
                                        x2="16"
                                        y2="7"
                                      ></line>
                                      <line x1="8" y1="3" x2="8" y2="7"></line>
                                      <line
                                        x1="4"
                                        y1="11"
                                        x2="20"
                                        y2="11"
                                      ></line>
                                      <line
                                        x1="11"
                                        y1="15"
                                        x2="12"
                                        y2="15"
                                      ></line>
                                      <line
                                        x1="12"
                                        y1="15"
                                        x2="12"
                                        y2="18"
                                      ></line>
                                    </svg>
                                    {Moment(leave.leave_from_date).format(
                                      "DD, MMM. YYYY "
                                    )}
                                  </td>
                                  <td className="text-nowrap text-muted">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="icon"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      strokeWidth="2"
                                      stroke="currentColor"
                                      fill="none"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    >
                                      <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                      ></path>
                                      <rect
                                        x="4"
                                        y="5"
                                        width="16"
                                        height="16"
                                        rx="2"
                                      ></rect>
                                      <line
                                        x1="16"
                                        y1="3"
                                        x2="16"
                                        y2="7"
                                      ></line>
                                      <line x1="8" y1="3" x2="8" y2="7"></line>
                                      <line
                                        x1="4"
                                        y1="11"
                                        x2="20"
                                        y2="11"
                                      ></line>
                                      <line
                                        x1="11"
                                        y1="15"
                                        x2="12"
                                        y2="15"
                                      ></line>
                                      <line
                                        x1="12"
                                        y1="15"
                                        x2="12"
                                        y2="18"
                                      ></line>
                                    </svg>
                                    {Moment(leave.leave_applied_date).format(
                                      "DD, MMM. YYYY "
                                    )}
                                  </td>
                                  <td className="w-1">
                                    {leave.status == 1
                                      ? "Approved"
                                      : leave.status == 2
                                        ? "Rejected"
                                        : leave.status == 3
                                          ? "Cancelled"
                                          : "Pending"}
                                  </td>
                                </tr>
                              </>
                            ))
                          ) : (
                            <>
                              <div className="p-3 text-center">
                                {/* <DivSpinner size="sm" color="primary" /> */}
                                <div>No Comp-Off Found!</div>
                              </div>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <h4>My Comp-Off</h4>
                    <div className="card-table table-responsive ">
                      <table className="table table-striped table-bordered table-sm">
                        <thead>
                          <tr>
                            <th scope="col">User</th>
                            <th scope="col"> Reason</th>
                            <th scope="col">Compoff Dates</th>
                            <th scope="col">Applied Dates</th>
                            <th scope="col">Status</th>
                          </tr>
                        </thead>
                        <tbody className="table-tbody">
                          {myCompoffLisiting && myCompoffLisiting != "" ? (
                            myCompoffLisiting.map((leave, i) => (
                              <>
                                <tr id={leave.id}>
                                  <td className="w-1">
                                    <div className="d-flex py-1 align-items-center">
                                      {leave.e_compoff.compoffAddedBy
                                        .profile_photo_path ? (
                                        <span className="avatar me-2 mr-2">
                                          <img
                                            src={
                                              CONST.HOST +
                                              leave.e_compoff.compoffAddedBy
                                                .profile_photo_path
                                            }
                                            alt="emp-img"
                                            width="40"
                                            className="rounded-circle"
                                          />
                                        </span>
                                      ) : (
                                        <span className="avatar rounded-circle me-2 mr-2">
                                          {leave.e_compoff.compoffAddedBy
                                            .first_name &&
                                            leave.e_compoff.compoffAddedBy
                                              .last_name ? (
                                            <>
                                              {" "}
                                              {leave.e_compoff.compoffAddedBy
                                                .first_name &&
                                                leave.e_compoff.compoffAddedBy.first_name.substring(
                                                  0,
                                                  1
                                                )}
                                              {leave.e_compoff.compoffAddedBy
                                                .last_name &&
                                                leave.e_compoff.compoffAddedBy.last_name.substring(
                                                  0,
                                                  1
                                                )}
                                            </>
                                          ) : (
                                            leave.e_compoff.compoffAddedBy
                                              .name &&
                                            leave.e_compoff.compoffAddedBy.name.substring(
                                              0,
                                              2
                                            )
                                          )}
                                        </span>
                                      )}
                                      <div className="flex-fill">
                                        <div className="font-weight-medium">
                                          {
                                            leave.e_compoff.compoffAddedBy
                                              .first_name
                                          }{" "}
                                          {
                                            leave.e_compoff.compoffAddedBy
                                              .last_name
                                          }
                                        </div>
                                      </div>
                                    </div>
                                  </td>
                                  {/* <td className="w-1">
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: leave.e_compoff.reason,
                                      }}
                                    ></span>{" "}
                                  </td> */}
                                  <td className="w-1">
                                    {leave.e_compoff.reason.length > 50 &&
                                      expandedRowId !== leave.id ? (
                                      <span
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            leave.e_compoff.reason.slice(
                                              0,
                                              50
                                            ) + "...",
                                        }}
                                      ></span>
                                    ) :
                                      (
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: leave.e_compoff.reason,
                                          }}
                                        ></span>
                                      )}
                                    {leave.e_compoff.reason.length > 50 && (
                                      <button
                                        onClick={() =>
                                          toggleText(leave.id)
                                        }
                                      >
                                        {expandedRowId === leave.id
                                          ? "Read Less"
                                          : "Read More"}
                                      </button>
                                    )}
                                  </td>
                                  <td className="text-nowrap text-muted">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="icon"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      strokeWidth="2"
                                      stroke="currentColor"
                                      fill="none"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    >
                                      <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                      ></path>
                                      <rect
                                        x="4"
                                        y="5"
                                        width="16"
                                        height="16"
                                        rx="2"
                                      ></rect>
                                      <line
                                        x1="16"
                                        y1="3"
                                        x2="16"
                                        y2="7"
                                      ></line>
                                      <line x1="8" y1="3" x2="8" y2="7"></line>
                                      <line
                                        x1="4"
                                        y1="11"
                                        x2="20"
                                        y2="11"
                                      ></line>
                                      <line
                                        x1="11"
                                        y1="15"
                                        x2="12"
                                        y2="15"
                                      ></line>
                                      <line
                                        x1="12"
                                        y1="15"
                                        x2="12"
                                        y2="18"
                                      ></line>
                                    </svg>
                                    {Moment(leave.compoff_date).format(
                                      "DD, MMM. YYYY "
                                    )}
                                  </td>
                                  <td className="text-nowrap text-muted">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="icon"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 24 24"
                                      strokeWidth="2"
                                      stroke="currentColor"
                                      fill="none"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    >
                                      <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                      ></path>
                                      <rect
                                        x="4"
                                        y="5"
                                        width="16"
                                        height="16"
                                        rx="2"
                                      ></rect>
                                      <line
                                        x1="16"
                                        y1="3"
                                        x2="16"
                                        y2="7"
                                      ></line>
                                      <line x1="8" y1="3" x2="8" y2="7"></line>
                                      <line
                                        x1="4"
                                        y1="11"
                                        x2="20"
                                        y2="11"
                                      ></line>
                                      <line
                                        x1="11"
                                        y1="15"
                                        x2="12"
                                        y2="15"
                                      ></line>
                                      <line
                                        x1="12"
                                        y1="15"
                                        x2="12"
                                        y2="18"
                                      ></line>
                                    </svg>
                                    {Moment(leave.compoff_date).format(
                                      "DD, MMM. YYYY "
                                    )}
                                  </td>
                                  <td className="w-1">
                                    {leave.e_compoff.status == 1
                                      ? "Approved"
                                      : leave.e_compoff.status == 2
                                        ? "Rejected"
                                        : leave.e_compoff.status == 3
                                          ? "Cancelled"
                                          : "Pending"}
                                  </td>
                                </tr>
                              </>
                            ))
                          ) : (
                            <>
                              <div className="p-3 text-center">
                                {/* <DivSpinner size="sm" color="primary" /> */}
                                <div>No Comp-Off Found!</div>
                              </div>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
            </div>
            <div className="col-12">
              <div className="col-12 pt-3">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">Latest Testimonials</h3>
                  </div>
                  <div className="card-table table-responsive">
                    {testimonialData ? (
                      <table className="table table-vcenter">
                        <thead>
                          <tr>
                            <th>User</th>
                            <th>Testimonial</th>
                            <th>Added By</th>
                            <th>Date</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {testimonialData.map((testimonial, index) => {
                            console.log(
                              "dash-testimonials-" +
                              JSON.stringify(testimonial)
                            );
                            return (
                              <tr>
                                <td className="w-1">
                                  <div className="d-flex py-1 align-items-center">
                                    <span className="avatar ">
                                      {testimonial.user.length > 0 &&
                                        testimonial.user
                                          .profile_photo_path ? (
                                        <>
                                          <img
                                            src={
                                              CONST.HOST +
                                              testimonial.user
                                                .profile_photo_path
                                            }
                                            alt=""
                                            width="40"
                                            className="rounded-circle"
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <span>
                                            <span
                                              className="badge bg-success me-1"
                                              data-toggle="tooltip"
                                              data-placement="top"
                                              title="Active"
                                            >
                                              {testimonial.user
                                                .first_name &&
                                                testimonial.user.last_name ? (
                                                <>
                                                  {" "}
                                                  {testimonial.user
                                                    .first_name &&
                                                    testimonial.user.first_name.substring(
                                                      0,
                                                      1
                                                    )}
                                                  {testimonial.user
                                                    .last_name &&
                                                    testimonial.user.last_name.substring(
                                                      0,
                                                      1
                                                    )}
                                                </>
                                              ) : (
                                                testimonial.name &&
                                                testimonial.name.substring(
                                                  0,
                                                  2
                                                )
                                              )}
                                            </span>
                                          </span>
                                        </>
                                      )}
                                    </span>
                                    <div className="flex-fill pl-2">
                                      <div className="font-weight-medium">
                                        {testimonial.user && (
                                          <>
                                            {testimonial.user.first_name}{" "}
                                            {testimonial.user.last_name}
                                          </>
                                        )}
                                      </div>
                                      <div className="text-muted">
                                        <span className="text-muted">
                                          <small>
                                            {testimonial.user &&
                                              testimonial.user.department
                                                .name}
                                          </small>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </td>
                                <td className=" ">
                                  <div className="">
                                    {testimonial.description}
                                  </div>
                                </td>

                                <td className="td-truncate">
                                  <div className="d-flex py-1 align-items-center">
                                    <span className="avatar ">
                                      {testimonial.added_by_admin &&
                                        testimonial.added_by_admin
                                          .profile_photo_path ? (
                                        <>
                                          <img
                                            src={
                                              CONST.HOST +
                                              testimonial.added_by_admin
                                                .profile_photo_path
                                            }
                                            alt=""
                                            width="40"
                                            className="rounded-circle"
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <span>
                                            <span
                                              className="badge bg-success me-1"
                                              data-toggle="tooltip"
                                              data-placement="top"
                                              title="Active"
                                            >
                                              {testimonial.user
                                                .first_name &&
                                                testimonial.user.last_name ? (
                                                <>
                                                  {" "}
                                                  {testimonial.user
                                                    .first_name &&
                                                    testimonial.user.first_name.substring(
                                                      0,
                                                      1
                                                    )}
                                                  {testimonial.user
                                                    .last_name &&
                                                    testimonial.user.last_name.substring(
                                                      0,
                                                      1
                                                    )}
                                                </>
                                              ) : (
                                                testimonial.user.name &&
                                                testimonial.user.name.substring(
                                                  0,
                                                  2
                                                )
                                              )}
                                            </span>
                                          </span>
                                        </>
                                      )}
                                    </span>
                                    {testimonial.added_by_admin ? (
                                      <div className="flex-fill pl-2">
                                        <div className="font-weight-medium">
                                          {" "}
                                          {
                                            testimonial.added_by_admin
                                              .first_name
                                          }{" "}
                                          {
                                            testimonial.added_by_admin
                                              .last_name
                                          }
                                        </div>
                                        <div className="text-muted">
                                          <span className="text-muted">
                                            <small>
                                              {
                                                testimonial.added_by_admin
                                                  .department.name
                                              }
                                            </small>
                                          </span>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </td>
                                <td className="text-nowrap text-muted">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="icon"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    strokeWidth="2"
                                    stroke="currentColor"
                                    fill="none"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  >
                                    <path
                                      stroke="none"
                                      d="M0 0h24v24H0z"
                                      fill="none"
                                    ></path>
                                    <rect
                                      x="4"
                                      y="5"
                                      width="16"
                                      height="16"
                                      rx="2"
                                    ></rect>
                                    <line
                                      x1="16"
                                      y1="3"
                                      x2="16"
                                      y2="7"
                                    ></line>
                                    <line
                                      x1="8"
                                      y1="3"
                                      x2="8"
                                      y2="7"
                                    ></line>
                                    <line
                                      x1="4"
                                      y1="11"
                                      x2="20"
                                      y2="11"
                                    ></line>
                                    <line
                                      x1="11"
                                      y1="15"
                                      x2="12"
                                      y2="15"
                                    ></line>
                                    <line
                                      x1="12"
                                      y1="15"
                                      x2="12"
                                      y2="18"
                                    ></line>
                                  </svg>{" "}
                                  {Moment(testimonial.created_at).format(
                                    "DD, MMM. YYYY "
                                  )}
                                </td>
                                <td>
                                  {testimonial.status == 1 && (
                                    <>
                                      <span>
                                        <span className="badge bg-success me-1 pr-1"></span>
                                        &nbsp;Active
                                      </span>
                                    </>
                                  )}
                                  {testimonial.status == 0 && (
                                    <>
                                      <span>
                                        <span className="badge bg-danger me-1"></span>{" "}
                                        &nbsp;Inactive
                                      </span>
                                    </>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    ) : (
                      <>
                        <div className="p-3 text-center">
                          {/* <DivSpinner size="sm" color="primary" /> */}
                          <div>No data found!</div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default Dashboard;
