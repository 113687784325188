import { React, useState, useContext, useEffect } from 'react'
import Loading from "react-fullscreen-loading";
import HeaderSection from "../banner/HeaderSection";
import * as  CONST from "../../../component/config/Constant"
import Form from "../../../component/utilities/Forms";
import "../../../assets/css/form.css";
import axios from 'axios';
import { AuthContext } from '../../../component/context/AuthContext';
import { toast } from "react-toastify";
import 'react-datetime/css/react-datetime.css';

function MyTestimonial() {
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  const [validate, setValidate] = useState({});
  const [textTestimonials, setTextTestimonials] = useState("");
  const [editTestiId, setEditTestiId] = useState("");
  const [loading, setLoading] = useState(false);
  let isToken = '';
  let userId = '';
  if (authUserData) {
    if (authUserData[0]) {
      isToken = authUserData[0].token ? authUserData[0].token : "";
      userId = authUserData[0].user_id ? authUserData[0].user_id : 0;
    }
  }
  useEffect(() => {
    fetchTestimonials();
  }, []);

  const fetchTestimonials = async () => {
    await axios
      .post(CONST.ADMIN_TESTIMONIAL_LIST, {
        userId: userId,
        "limit": 1,
        "page_number": 1,
        "search_input": "",
        "sort": {
          order: 'desc',
          order_by: 'id'
        }
      }, {
        headers: {
          'Authorization': `Basic ${isToken}`
        }
      })
      .then((response) => {
        const status = response.data.status;
        if (status === 'success') {
          setLoading(true);
          if (response.data.data[0]) {
            setTextTestimonials(response.data.data[0].description);
            setEditTestiId(response.data.data[0].id);
          }
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  }
  const validTestimonials = async () => {
    let isValid = true;

    let validator = Form.validator({
      textTestimonials: {
        value: textTestimonials,
        isRequired: true,
        isEmail: false,
        label: "testimonials",
      },
    });

    if (validator !== null) {
      setValidate({
        validate: validator.errors,
      });
      isValid = false;
    } else {
      setValidate({
        validate: "",
      });
      isValid = true;
    }
    return isValid;
  }
  const handleForm = async (e) => {
    e.preventDefault();
    const validate = await validTestimonials();
    if (validate) {
      await axios
        .put(CONST.ADMIN_TESTIMONIALS_UPDATE, {
          description: textTestimonials,
          user_id: userId,
          testimonial_id: editTestiId,
          added_by: userId,
        },
          {
            headers: {
              'Authorization': `Basic ${isToken}`
            }
          })
        .then((response) => {
          const status = response.data.status;
          setLoading(false);
          if (status === 'error') {
            const errorMsg = response.data.message;
            toast.error(errorMsg);

          } else {
            const errorMsg = response.data.message;
            toast.success(errorMsg);

          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error("Something went wrong. Please try again.");
        });
    }
  }
  return (
    <>
      <Loading
        loading={loading}
        background="rgba(0, 0, 0, 0.5)"
        loaderColor="#fff"
      />
      <HeaderSection
        title=""
        heading="Update Testimonial"
        addButton=""
        addLink=""
        addLinkName=""
        linktype=""
      />

      <div className="page-body">
        <div className="container-xl">
          <div className="row row-cards">
            <div className="col-12">
              <div className="card">

                <div className="card-body">
                  <form
                    method="POST"
                    onSubmit={handleForm}
                    autoComplete={"off"}
                    encType="multipart/form-data"
                  >
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group mb-3 ">
                          <label className="form-label required">
                            Testimonial
                          </label>
                          <div>
                            <textarea
                              name="textTestimonials"
                              id="textTestimonials"
                              className={`form-control ${validate.validate && validate.validate.textTestimonials
                                ? "is-invalid "
                                : ""
                                }`}
                              onChange={(e) => setTextTestimonials(e.target.value)}
                              style={{ height: '100px !important;' }}
                              value={textTestimonials ? textTestimonials : ""}
                            >{textTestimonials ? textTestimonials : ""}</textarea>
                          </div>
                          <div
                            className={`invalid-feedback text-start ${validate.validate && validate.validate.textTestimonials
                              ? "d-block"
                              : "d-none"
                              }`}
                          >
                            {validate.validate && validate.validate.textTestimonials
                              ? validate.validate.textTestimonials[0]
                              : ""}{" "}
                          </div>{" "}
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-footer">
                          <button
                            type="submit"
                            className="mr-3 btn btn-primary"
                          >
                            Submit
                          </button>
                          &nbsp;&nbsp;
                          <button
                            type="reset"
                            className="btn btn-outline-primary"

                          >
                            Reset
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}

export default MyTestimonial