import React from 'react'

const ReadOnlyRow = ({ gItem, handleEditClick, deleteHandler, deleteImg }) => {
    return (
        <>
            {
                (gItem.id)
                    ?
                    (
                        <tr key={`tr_view`}>
                            <td className="text-muted">{gItem.relation}</td>
                            <td className="text-muted">
                                {gItem.gName}
                            </td>
                            <td className="text-muted">{gItem.email}</td>
                            <td className="text-muted">{gItem.gPhoneNumber}</td>
                            <td className="text-muted">
                                {gItem.gAddress}
                            </td>
                            <td className="text-muted">

                                <img src="/static/media/edit.8b4759ee999d507ec6d9bc66c8538edc.svg" alt="editUser" className="cursor" width="32" onClick={(event) => handleEditClick(event, gItem)} />

                                <img src={deleteImg} alt='deleteImg' width={32} className='cursor' onClick={() => deleteHandler(gItem.id)} />
                            </td>
                        </tr>
                    )
                    :
                    (
                        <tr>
                            <td colSpan='6'>Guardian not found!</td>
                        </tr>
                    )
            }

        </>
    )
}
export default ReadOnlyRow