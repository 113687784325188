import { React, useState, useEffect, useContext } from 'react'
import "../../../assets/css/table.css";
import Loading from "react-fullscreen-loading";
import { Link } from 'react-router-dom';
import HeaderSection from "../banner/HeaderSection";
import DivLoader from "../../../component/utilities/Spinner";
import DataTable from "react-data-table-component";
import axios from "axios";
import { toast } from "react-toastify";
import * as  CONST from "../../../component/config/Constant"
import Form from "../../../component/utilities/Forms";
import editImg from "../../../assets/img/icon/edit.svg";
import deleteImg from "../../../assets/img/icon/delete.svg";
import { Modal, Button } from 'react-bootstrap';
import { AuthContext } from '../../../component/context/AuthContext';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ReactSwitch from 'react-switch';
import * as SwitchProps from '../../../component/utilities/ReactSwitchReactSwitchProps';
function PolicyTypeList() {
    const [authUserData, setAuthUserData] = useContext(AuthContext);
    const [fullLoading, setFullLoading] = useState(false);
    const [validate, setValidate] = useState({});
    const [processing, setProcessing] = useState(false);
    const [policyTypeData, setPolicyTypeData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activePage, setActivePage] = useState(1);
  
    const MySwal = withReactContent(Swal)
    const [listLimit, setListLimit] = useState(50);

    const [action, setAction] = useState('');

    /** For Modalpopup */
    const [show, setShow] = useState(false);

    /* START DATATABLE */
    const [totalRecords, settotalRecords] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [maxPageLimit, setMaxPageLimit] = useState(10);
    const [minPageLimit, setMinPageLimit] = useState(0);

    //Search
    const [searchPolicyTypeName, setSearchPolicyTypeName] = useState('');
    const [searchPolicyStatus, setSearchPolicyStatus] = useState('');
    const [searchPolicyCode, setSearchPolicyCode] = useState('');
    const [dataSort, setPolicyTypeDataSort] = useState({
        order: 'desc',
        order_by: 'id'
    });
    const pageNumberLimit = { perPage };

    //For Form
    const [policyName, setPolicyName] = useState('');
    const [status, setStatus] = useState(false);
    const [policyCode, setPolicyCode] = useState('');
    const [policyTypeId, setPolicyTypeId] = useState(0);
    
    let isToken = '';
    if (authUserData) {
        if (authUserData[0]) {
            isToken = authUserData[0].token ? authUserData[0].token : "";
        }
    }

    const [searchParam, setSearchParam] = useState(
        {
            "srchPolicyTypeName": searchPolicyTypeName,
            "srchPolicyTypeCode": searchPolicyCode,
            "srchPolicyTypeStatus": searchPolicyStatus
        }
    );

    useEffect(() => {
        fetchPolicyData(searchParam, dataSort, activePage, perPage);
        //fetchPolicyType();
    }, [searchParam, dataSort, activePage, listLimit]);
  

    function handleShow(action, policyTypeId) {
        const policy_id = policyTypeId ? policyTypeId : 0;
        //setpolicyTypeId(policy_id);
        setPolicyName("");
        setPolicyTypeId("");
        setStatus("");
        setPolicyCode("");
        if (policy_id !== 0) {
            fetchPolicyById(policy_id);
        }
        setAction(action)
        setShow(true);
    }


    async function policySearch() {
        setSearchParam({
          "srchPolicyTypeName": searchPolicyTypeName,
          "srchPolicyTypeCode": searchPolicyCode,
          "srchPolicyTypeStatus": searchPolicyStatus
        })

        setPolicyTypeDataSort({
          order: "desc",
          order_by: "id"
		});

		setActivePage("1");
    }

    function clearSearch() {
        setSearchPolicyTypeName('');
        setSearchPolicyStatus('');
        setSearchPolicyCode('');
        setSearchParam({
            "srchPolicyTypeName": '',
            "srchPolicyTypeCode": '',
            "srchPolicyTypeStatus": ''
        });
        
        setPolicyTypeDataSort({
            order   : "desc",
            order_by: "id"
		    });

		    setActivePage("1");
    }

    const handleClose = () => {
        setShow(false);
        //setpolicyTypeId(0);
        setPolicyTypeId("");
        setPolicyName("");
        //setPolicyType("");
        setStatus("");
        setPolicyCode("");
    }

    function deleteHandler(policy_type_id) {
        Swal.fire({
          title: 'Are you sure?',
          text: "Do you want to delete this policy type?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.isConfirmed) {
            setLoading(true);
              if (policy_type_id > 0) {
                  setLoading(true);
                  axios
                      .delete(CONST.DELETE_POLICY_TYPE, {
                          headers: {
                              'Authorization': `Bearer ${isToken}`
                          },
                          data: {
                            policy_type_id: policy_type_id
                          }
                      })
                      .then((response) => {

                          if (response) {
                              if (response.data.status == 'error') {
                                  toast.error(response.data.message);
                              } else {
                                  toast.success(response.data.message);
                                  fetchPolicyData(searchParam, dataSort, activePage, perPage);
                              }
                          }
                      })
                      .catch((error) => {
                          setLoading(false);
                          toast.error("Something went wrong. Please try again." + error);
                      });
                  setLoading(false);
            }
          }
        })
    }

    //fetch all policy data by id
    async function fetchPolicyById(policyTypeId) {
        setLoading(true);
        await axios
            .get(CONST.GET_POLICY_TYPE_BY_ID + `${policyTypeId}`, {
                headers: {
                    'Authorization': `Bearer ${isToken}`
                }
            })
            .then((response) => {
                const status = response.data.status;
                console.log("get policy by id >>");
                console.log(response.data);
                if (status === 'success') {
                    let policyData = response.data.data[0] ? response.data.data[0] : [];

                    setPolicyName(policyData['name'] ? policyData['name'] : '' );
                    setPolicyTypeId(policyData['id'] ? policyData['id'] : '');
                    setStatus(policyData['is_active'] ? policyData['is_active'] : '');
                    setPolicyCode(policyData['code'] ? policyData['code'] : '');
                }
            })
            .catch((error) => {
                alert(error);
            });
        setLoading(false);
    }

    //fetch all policy details
    async function fetchPolicyData(paramSearch, paramSort, paramPage, paramLimit) {
        setLoading(true);
        let searchParams = JSON.stringify({
            'limit': paramLimit,
            'search_input': paramSearch,
            'page_number': paramPage,
            'sort': paramSort
        })
        await axios
            .post(CONST.GET_POLICY_TYPE_LIST, {
                'limit': paramLimit,
                'search_input': paramSearch,
                'page_number': paramPage,
                'sort': paramSort
            }, {
                headers: {
                    'Authorization': `Bearer ${isToken}`
                }
            })

            .then((response) => {
                const status = response.data.status;
                console.log("response >>>");
                console.log(response.data.data);
                if (status === 'success') {
                    setPolicyTypeData(response.data.data);
                    setTotalRows(response.data.count);
                    settotalRecords(response.data.count);
                }
                else{
                    toast.error(response.data.message);
                }
            })
            .catch((error) => {
            });
        setLoading(false);
    }

    //fetch all Department details
    async function fetchPolicyType() {
        setLoading(true);
        await axios
            .post(CONST.GET_POLICY_TYPE_LIST, {
                search_input: '',
                page_number: ''
            }, {
                headers: {
                    'Authorization': `Bearer ${isToken}`
                }
            })

            .then((response) => {
                const status = response.data.status;
                if (status === 'success') {
                    setPolicyTypeData(response.data.data);
                }
            })
            .catch((error) => {
                alert(error);
            });
        setLoading(false);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let isValid = true;

        let validator = Form.validator({
            policyName: {
                value: policyName,
                isRequired: true,
                label: "Policy Name",
            },
            policyCode: {
                value: policyCode,
                isRequired: true,
                label: "Policy Code",
            },
        });

        if (validator !== null) {
            setValidate({
                validate: validator.errors,
            });
            isValid = false;
        }

        if (isValid) {
            console.log("isValid >> " +isValid);
            console.log("name : " +  policyName);
            console.log("policyTypeId : " + policyTypeId);
            console.log("status : " + status);
            console.log("code : " + policyCode);

            let apiUrl = '';
            let sucessMessage = '';
            let strMethod = '';
            if (policyTypeId == 0) {
                apiUrl        = CONST.ADD_POLICY_TYPE;
                strMethod     = 'post';
                sucessMessage = 'Policy type added succesfully';
            } else {
                apiUrl        = CONST.EDIT_POLICY_TYPE;
                strMethod     = 'put';
                sucessMessage = 'Policy type updated succesfully';
            }

            console.log("apiUrl >> " + apiUrl);
            console.log("strMethod >>> " + strMethod);
            console.log("sucessMessage >>> " +sucessMessage);

            await axios({
                method: strMethod,
                url: apiUrl,
                data: {
                    name            : policyName,
                    policy_type_id  : policyTypeId,
                    is_active       : (status == '1' || status == '' ) ? '1' : '0',
                    code            : policyCode
                },
                headers: {
                    'Authorization': `Bearer ${isToken}`,
                }
            })
            .then((response) => {
                if (response) {
                    if (response.data.status == 'error') {
                        toast.error("Something went wrong. Please try again.");
                    } else {
                        toast.success(sucessMessage);
                        fetchPolicyData(searchParam, dataSort, activePage, perPage);
                        handleClose();
                    }
                }
            })
            .catch((error) => {
                setLoading(false);
                toast.error("Something went wrong. Please try again.");
            });
        } 
    }
    //this is remaining 
    const handleChangeStatus = (index, status_value, policy_id) => {
        Swal.fire({
        title: 'Are you sure?',
        text: "Do you want to change the status?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, change it!'
      }).then((result) => {
			    if (result.isConfirmed) {
            setLoading(true);
            axios.patch(CONST.UPDATE_POLICY_TYPE_STATUS,
              {
                'policy_type_id': policy_id,
                'status': (status_value == '0') ? '1' : '0',
              },
              {
                headers: {
                  'Authorization': `Basic ${isToken}`
                }
              }
				  )
					.then((response) => {
						const status = response.data.status;
						if (status == 'success') {
							toast.success(response.data.message);
						} else {
							toast.error(response.data.message);
						}
					})
					.catch((error) => {
						toast.error("There was an error.");
					});
				setLoading(false);
                console.log("initial policyTypeData");
                console.log(policyTypeData);
				const newData = [...policyTypeData];
    		    newData[index].is_active = (status_value == 0) ? 1 : 0;
				setPolicyTypeData(newData);
			}
		})
    }

    const columns = [
        {
            name: 'Sr. No',
            selector: (row, index) => index + 1,
            width: "10%",
        },
        {
            name: 'Policy Name',
            selector: (row) => row.name,
            width: "25%",
        },
        {
            name: 'Code',
            selector: (row) => row.code,
            width: "25%",
        },
        {
            name: 'Status',
            selector: (row) => 'Active',
            width: "15%",
            selector: (row, index) => <ReactSwitch
                onColor={SwitchProps.onColor}
                onHandleColor={SwitchProps.onHandleColor}
                handleDiameter={SwitchProps.handleDiameter}
                uncheckedIcon={SwitchProps.uncheckedIcon}
                checkedIcon={SwitchProps.checkedIcon}
                boxShadow={SwitchProps.boxShadow}
                activeBoxShadow={SwitchProps.activeBoxShadow}
                height={SwitchProps.height}
                width={SwitchProps.width}
                className={SwitchProps.className}
                checked={(row.is_active == 1) ? true : false}
                onChange={() => { handleChangeStatus(index, row.is_active, row.id) }}
            />,
        },
        {
            name: 'Action',
            width: "20%",
            wrap: true,
            sortable: false,
            cell: (row) => <Action row={row} />,
        },

    ]

    const Action = ({ row }) => (
        <>
            <Link to={'#'} data-id={row.id} onClick={() => handleShow('Edit', row.id)}>
                <img src={editImg} alt='editPolicy' width={32} className='cursor' />
            </Link>
            <Link onClick={() => deleteHandler(row.id) }>
                <img src={deleteImg} alt='deleteImg' width={32} className='cursor' />
            </Link>
        </>
    )

    const handleSort = async (column, sortDirection) => {
        setActivePage(1);
        setSearchParam({
            search: 'no',
            name: '',
            status: '',
        });

        setPolicyTypeDataSort({
            order: sortDirection,
            order_by: column.sortField
        })
        /*  setLoading(false); */

    };
    /*  PAGINATION FUNCTIONS  */
    const handlePageChange = async (page) => {
        setActivePage(parseInt(page));
    };

    const BootyPagination = ({
        rowsPerPage,
        rowCount,
        onChangePage,
        onChangeRowsPerPage, // available but not used here
        currentPage,
    }) => {

        function toPages(pages) {
			const results = [];
			for (let i = 1; i <= pages; i++) {
				results.push(i);
			}
			return results;
		}
		function getNumberOfPages(rowCount, rowsPerPage) {
			return Math.ceil(rowCount / rowsPerPage);
		}


    const handleBackButtonClick = async () => {
        if ((currentPage - 1) % pageNumberLimit === 0) {
            setMaxPageLimit(maxPageLimit - pageNumberLimit);
            setMinPageLimit(minPageLimit - pageNumberLimit);
        }
        onChangePage(currentPage - 1);
    };

    const handleNextButtonClick = async () => {
        if (currentPage + 1 > maxPageLimit) {
            setMaxPageLimit(maxPageLimit + pageNumberLimit);
            setMinPageLimit(minPageLimit + pageNumberLimit);
        }

        onChangePage(currentPage + 1);
    };

      const handlePageNumber = (e) => {
          onChangePage(Number(e.target.value));
      };

      let paginationText = "Page:  " + currentPage + ", Showing ";

      paginationText =
          paginationText +
          ((currentPage - 1) * perPage + 1) +
          " to " +
          perPage * currentPage;
      paginationText = paginationText + " of " + rowCount + " entries;";

      const pages = getNumberOfPages(rowCount, rowsPerPage);
      const pageItems = toPages(pages);
      const nextDisabled = currentPage === pageItems.length;
      const previosDisabled = currentPage === 1;

      const pageNumbers = pageItems.map((page) => {
          if (page <= maxPageLimit && page > minPageLimit) {
              return (
                  <li
                      key={page}
                      id={page}
                      className={`page-item ${currentPage === page ? "active" : ""}`}
                    >
                      <button
                          className={`page-link ${currentPage === page ? "active" : ""}`}
                          onClick={handlePageNumber}
                          value={page}
                      >
                          {page}
                      </button>
                  </li>
              );
          } else {
              return null;
          }
      });
      let pageIncrementEllipses = null;
      if (pageItems.length > maxPageLimit) {
          pageIncrementEllipses = (
              <li className="page-item" onClick={handleNextButtonClick}>
                  &hellip;
              </li>
          );
      }
      let pageDecremenEllipses = null;
      if (minPageLimit >= 1) {
          pageDecremenEllipses = (
              <li className="page-item" onClick={handleBackButtonClick}>
                  &hellip;
              </li>
          );
      }
      return (
          <div className="card-footer d-flex align-items-center">
              <p className="m-0 text-muted">{paginationText}</p>
              <ul className="pagination m-0 ms-auto">
                  <li className="page-item">
                      <button
                          className="page-link"
                          onClick={handleBackButtonClick}
                          disabled={previosDisabled}
                          aria-disabled={previosDisabled}
                          aria-label="previous page"
                          pagerangedisplayed={10}
                      >
                      « 
                      </button>
                  </li >
              { pageDecremenEllipses }
              { pageNumbers }
              { pageIncrementEllipses }
              <li className="page-item">
              <button
                  className="page-link"
                  onClick={handleNextButtonClick}
                  disabled={nextDisabled}
                  aria-disabled={nextDisabled}
                  aria-label="next page">
                  »
              </button>
              </li>
          </ul >
      </div >
      );
};
/* END DATATABLE */
const CustomLoader = () => (
    <div style={{ padding: "24px" }}>
        <DivLoader color="primary" />
    </div>
);
return (
    <>
        <Loading
            loading={fullLoading}
            background="rgba(0, 0, 0, 0.5)"
            loaderColor="#fff"
        />
        <HeaderSection
            title="Manage"
            heading="Policy Type"
            addButton={() => handleShow('Add', 0)}
            addLink="#"
            addLinkName="Add Policy Type"
            linktype="add"
        />
        <div className="page-body">
            <div className="container-xl">
                <div className="row row-cards">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Listing</h3>
                            </div>
                            <div className="card-body border-bottom py-3">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group mb-3 ">
                                            <label className="form-label required">Policy Type</label>
                                            <div>
                                                <input
                                                    type="text"
                                                    name="searchPolicyTypeName"
                                                    id="searchPolicyTypeName"
                                                    className={`form-control`}
                                                    placeholder="Enter Policy Type"
                                                    onChange={(e) => setSearchPolicyTypeName(e.target.value)}
                                                    value={searchPolicyTypeName}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group mb-3 ">
                                            <label className="form-label required">Policy Code</label>
                                            <div>
                                                <input
                                                    type="text"
                                                    name="searchPolicyCode"
                                                    id="searchPolicyCode"
                                                    className={`form-control`}
                                                    placeholder="Enter Policy Code"
                                                    onChange={(e) => setSearchPolicyCode(e.target.value)}
                                                    value={searchPolicyCode}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className="mb-3">
                                            <label className="form-label required">
                                                Status
                                            </label>

                                            <select
                                                className="form-select form-control"
                                                name="searchPolicyStatus"
                                                id="searchPolicyStatus"
                                                placeholder="Select status"
                                                onChange={(e) => setSearchPolicyStatus(e.target.value) } value={searchPolicyStatus}
 >                                                <option value="">Select</option>
                                                <option value="1">Active</option>
                                                <option value="0">Deactive</option>
                                            </select>
                                        </div>
                                    </div>                                 

                                    <div className="col-md-2">
                                        <div className="form-group mb-3 ">
                                            <label className="form-label">&nbsp;</label>
                                            <div>
                                                <button
                                                    type="button"
                                                    name="btnSearch"
                                                    id="btnSearch"
                                                    className="btn btn-blue d-none d-sm-inline-block ml-3"
                                                    onClick={policySearch}
                                                ><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <circle cx="10" cy="10" r="7"></circle>
                                                        <line x1="21" y1="21" x2="15" y2="15"></line>
                                                    </svg>Search</button>&nbsp;
                                                <button
                                                    type="button"
                                                    name="btnReset"
                                                    id="btnReset"
                                                    className="btn btn-outline-primary "
                                                    onClick={clearSearch}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                                    </svg>Clear</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">

                                <DataTable
                                    columns={columns}
                                    data={policyTypeData}
                                    progressPending={loading}
                                    progressComponent={<CustomLoader />}
                                    pagination="true"
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    //onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                    paginationPerPage={perPage}
                                    paginationComponent={BootyPagination}
                                    sortServer
                                    onSort={handleSort}/>

                            </div>
                            <div className="card-footer d-flex align-items-center">
                                <p className="m-0 text-muted">
                                    Showing {/* <span>1</span> to <span>8</span> of{" "} */}
                                    <span>{totalRecords}</span> entries
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Modal size="lg" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{action} Policy Type</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <form method="POST" onSubmit={handleSubmit}  autoComplete={"off"}>
                <div className="row">
                    <div className="col-md-6">
                        <label className="form-label required">
                            Policy Type
                        </label>
                        <div>
                            <input type="text" name="policyName"   id="policyName"
                                className={`form-control ${validate.validate && validate.validate.policyName ? "is-invalid" : "" }`}
                                placeholder="Enter Policy Type" onChange = {(e) => setPolicyName(e.target.value)}  value={policyName} />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <label className="form-label required">
                            Code
                        </label>
                        <div>
                            <input type="text" name="policyCode" id="policyCode"
                                className={`form-control ${validate.validate && validate.validate.policyName ? "is-invalid" : "" }`}
                                placeholder="Enter Code" onChange = {(e) => setPolicyCode(e.target.value)}  value={policyCode} />
                        </div>
                    </div>

                    <div className="col-md-6">
                        <label className="form-label required">
                            Status
                        </label>
                        
                        <select className={`form-select form-control ${validate.validate && validate.validate.status ? "is-invalid " : ""  }`}
                            name="status"  id="status" placeholder="Select status"
                            onChange={(e) => setStatus(e.target.value)} value={status}>
                            <option value="">Select</option>
                            <option value="1">Active</option>
                            <option value="0">Deactive</option>
                            </select>

                    </div>  
                </div>

                <input type="hidden" name="policyTypeId" id="policyTypeId" value={policyTypeId} />
            </form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    </>
)
}

export default PolicyTypeList