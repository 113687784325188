import React from "react";
import DatePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';

const EditableRow = ({ editFormData, handleEditFormChange, handleCancelClick }) => {
  //alert('editFormData'+JSON.stringify(editFormData) );
  return (
    <tr key='edit_education_tr'>
    <td> 
        <input
            type="text"
            name="institute_name"
            value= {editFormData.institute_name}
            className={`form-control`}
            placeholder="Enter Institute Name"
            onChange={(e) => handleEditFormChange(e.target.value,'institute_name')}
            required="required" 
        />
        <span className={`invalid-feedback text-start ${editFormData.error_institute_name ? "d-block" : "d-none"}`}>{editFormData.error_institute_name !== "" ? editFormData.error_institute_name : ''}
        </span>    
    </td>
    <td>
        <input
        type="text"
        name="degree"
        id="degree"
        className={`form-control`}
        placeholder="Enter degree"
        value= {editFormData.degree}
        onChange={(e) => handleEditFormChange(e.target.value,'degree')}
        required="required" />  
        <span className={`invalid-feedback text-start ${editFormData.error_degree ? "d-block" : "d-none"}`}>{editFormData.error_degree !== "" ? editFormData.error_degree : ''}
        </span>     
    </td>

    <td>
      <DatePicker
        name="start_date"
        id={ `start_date`}
        value={(editFormData.start_date)}
        selected={editFormData.start_date}
        timeFormat=""
        dateFormat="DD-MM-YYYY"
        className=''
        onChange={(e) => handleEditFormChange(e,'start_date')}
      />    
      <span className={`invalid-feedback text-start ${editFormData.error_start_date ? "d-block" : "d-none"}`}>{editFormData.error_start_date !== "" ? editFormData.error_start_date : ''}
        </span>   
    </td>

    <td>
      <DatePicker
        name="end_date"
        id={ `end_date`}
        value={(editFormData.end_date) }
        selected={editFormData.end_date}
        timeFormat=""
        dateFormat="DD-MM-YYYY"
        className=''
        onChange={(e) => handleEditFormChange(e,'end_date')}
      />    
      <span className={`invalid-feedback text-start ${editFormData.error_end_date ? "d-block" : "d-none"}`}>{editFormData.error_end_date !== "" ? editFormData.error_end_date : ''}
        </span>   
    </td>
    
    <td>
        <div className="inline-btn-grp">
          <button className="btn btn-primary" type="submit">Save</button> &nbsp;
          <button className="btn btn-danger" type="button" onClick={handleCancelClick}>Cancel</button>
        </div>
    </td>
        
    </tr>
  )
}

export default EditableRow