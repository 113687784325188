import React, { useContext, useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { AuthContext } from '../../../component/context/AuthContext';
import moment from 'moment';

const ViewLeaveDetailsForAdmin = ({ closeLeave, editLeaveId, viewData }) => {
    //alert(JSON.stringify(viewData))
    const [authUserData, setAuthUserData] = useContext(AuthContext);
    const [show, setShow] = useState('show');
    const [loading, setLoading] = useState(false);
    const [leaveId, setLeaveId] = useState(editLeaveId);

    let isToken = '';
    let user_id = '';
    if (authUserData) {
        if (authUserData[0]) {
            isToken = authUserData[0].token ? authUserData[0].token : "";
            user_id = authUserData[0].user_id ? authUserData[0].user_id : "";
        }
    }

    const formatTime = (date) => {
        return date.slice(0, -3);
    }
    return (
        <>
            <Modal
                size="lg"
                show={show}
                onHide={() => closeLeave('hide')}
                backdrop="static"
                keyboard={false}
                centered
                fullscreen={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Leave Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>
                        {(viewData.user.first_name && viewData.user.last_name)
                            ? <> {`${viewData.user.first_name} ${viewData.user.last_name}`}</>
                            : ''
                        }
                    </h5>
                    <hr />

                    <div className="form-row leave-status-alert">
                        {(() => {

                            if (viewData.status == '1') {
                                return (
                                    <>
                                        <label className="leave-status-alert-approve">Leave is approved by {viewData.approvedByUser.first_name + ' ' + viewData.approvedByUser.last_name}.</label>
                                    </>
                                )
                            } else if (viewData.status == '2') {
                                return (
                                    <>
                                        <label className="leave-status-alert-reject">Leave is rejected by {viewData.approvedByUser.first_name + ' ' + viewData.approvedByUser.last_name}.</label>
                                    </>
                                )
                            } else if (viewData.status == '3') {
                                return (
                                    <>
                                        <label className="leave-status-alert-cancel">Employee has cancelled the leave.</label>
                                    </>
                                )
                            } else {
                                return (
                                    <>
                                        <label className="leave-status-alert-pending">Leave is not approved yet.</label>
                                    </>
                                )
                            }


                        })()}
                    </div>

                    <div className="row">
                        <div className="col-md-4">
                            <div className="form-group mb-3 ">
                                <h5>
                                    Leave Type:  <small class="fw-400">{(viewData.leave_type == 1) ? 'Full Day' : (viewData.leave_type == 2) ? 'Short Leave' : 'Half Day'} / {(viewData.e_leave_type.name) ? viewData.e_leave_type.name : '-'}</small>
                                </h5>
                                {/*  <label className="form-label bold">
                                    Leave Sub Type: <span class="info">{(viewData.e_leave_type.name) ? viewData.e_leave_type.name : '-'}</span>
                                </label> */}
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="form-group mb-3 ">
                                <h5>
                                    Date : <small class="fw-400">{moment(viewData.leave_from_date).format("DD-MM-YYYY") + ((viewData.leave_type == 'Full Day') ? ' to ' + moment(viewData.leave_to_date).format("DD-MM-YYYY") : '')} / {((viewData.leave_type == '1') ? ' - ' : formatTime(viewData.from_time) + ' - ' + formatTime(viewData.to_time))}</small>
                                </h5>
                                {/* <label className="form-label bold">
                                    Date : {viewData.leave_from_date + ((viewData.leave_type == 'Full Day') ? ' to ' + viewData.leave_from_date : '')}
                                </label> */}
                                {/*  <label className="form-label bold">Time :  <span>{((viewData.leave_type == '1') ? ' - ' : formatTime(viewData.from_time) + ' - ' + formatTime(viewData.to_time))}</span>
                                </label> */}
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="form-group mb-3 ">
                                <h5>Status  : <span className='fw-400'>
                                   
                                    {
                                        viewData.status && (viewData.status == 0) && (
                                            <><span>Pending</span></>
                                        )
                                    }
                                    {
                                        viewData.status && (viewData.status == 1) && (
                                            <><span>Approved</span></>
                                        )
                                    }
                                    {
                                        viewData.status && (viewData.status == 2) && (
                                            <><span>Rejected</span></>
                                        )
                                    }
                                    {
                                        viewData.status && (viewData.status == 3) && (
                                            <><span>Cancelled</span></>
                                        )
                                    }

                                </span>
                                </h5>
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group mb-3 ">
                                <h5>
                                    Subject: <span class="fw-400" dangerouslySetInnerHTML={{ __html: viewData.subject }} />
                                </h5>
                                {/*   <label className="form-label bold">
                                    Subject: <span class="info" dangerouslySetInnerHTML={{ __html: viewData.subject }} />
                                </label> */}
                            </div>
                        </div>

                        <div className="col-md-12">
                            <div className="form-group mb-3 ">
                                <h5>Reason:   </h5>
                                <span class="fw-400" dangerouslySetInnerHTML={{ __html: viewData.reason }} />

                                {/*   <label className="form-label bold">
                                    Reason: <span class="info" dangerouslySetInnerHTML={{ __html: viewData.reason }} />
                                </label> */}
                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="form-group mb-3 ">
                                <h5>   Comments:</h5>
                                <span class="fw-400"> {viewData.leave_comment} </span>
                                {/* <label className="form-label bold">
                                    Comments: <span> {viewData.leave_comment} </span>
                                </label> */}

                            </div>
                        </div>
                    </div>
                </Modal.Body>

            </Modal >
        </>
    );
};

export default ViewLeaveDetailsForAdmin;