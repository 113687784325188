import React, { useContext, useEffect } from "react";
import Loading from "react-fullscreen-loading";
import HeaderSection from "../banner/HeaderSection";
import * as CONST from "../../../component/config/Constant";
import { AuthContext } from "../../../component/context/AuthContext";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import DivSpinner from "../../../component/utilities/Spinner";
import deleteImg from "../../../assets/img/icon/delete.svg";
import Form from "../../../component/utilities/Forms";
/* import Accordion from 'react-bootstrap/Accordion'; */

function MyRoles() {
  const [authUserData, setAuthUserData] = useContext(AuthContext);

  const [rulesListing, setRulesListing] = useState([]);
  const [validate, setValidate] = useState({});
  const [loading, setLoading] = useState(false);

  let isToken = "";
  let user_id = "";
  let department_id = '';
  let designation_id = '';
  if (authUserData) {
    if (authUserData[0]) {
      isToken = authUserData[0].token ? authUserData[0].token : "";
      user_id = authUserData[0].user_id ? authUserData[0].user_id : "";
      department_id = authUserData[0].department_id ? authUserData[0].department_id : "";
      designation_id = authUserData[0].designation_id ? authUserData[0].designation_id : "";
    }
  }

  useEffect(() => {
    fetchDeptList();
    console.log('kkkkkkkkk', authUserData)
    // setRulesListing(Listing)
  }, []);
  async function fetchDeptList() {
    setLoading(true);
    await axios
      .post(
        CONST.GET_USER_RESPONSIBLITY,
        {
          user_id: user_id,
          department_id: department_id,
          designation_id:designation_id
        },
        {
          headers: {
            Authorization: `Basic ${isToken}`,
          },
        }
      )
      .then((response) => {
        let status = response.data.status;
        console.log(response);
        if (status == "success") {
          // const permissions = response.data.data;
          // if (permissions){
          //   const updatedArray = permissions.map(item=>({
          //     id:item.id, 
          //     title: item.title.title,
          //     title_id:item.title_id,
          //     rules:item.user_has_rules.map(rule =>({
          //      rule_id: rule.rules_id,
          //      checked:rule.checked == 'true' ? true : false,
          //      rules:rule.responsibilities_has_rule.rules
          //     }))
          //   }))
          //  setRulesListing(updatedArray)
          // }
          const flag = response.data.checked;
          const permissions = response.data.data;
          if (permissions && flag === false) {
            console.log('hey public')
            const updatedArray = permissions.map(item => ({
              id: item.id,
              title: item.title,
              title_id: item.id,
              rules: item.responsibilities_has_rules.map((rule, key) => ({
                rule_id: key + 1,
                checked: false,
                rules: rule.rules
              }))
            }))

            console.log('my aray11', updatedArray)
            setRulesListing(updatedArray)
          } else if (permissions && flag === true) {
            console.log('hey public')
            const updatedArray = permissions.map(item => ({
              id: item.id,
              title: item.title.title,
              title_id: item.title_id,
              rules: item.user_has_rules.map(rule => ({
                rule_id: rule.rules_id,
                checked: rule.checked == 'true' ? true : false,
                rules: rule.responsibilities_has_rule.rules
              }))
            }))

            console.log('my aray22', updatedArray)
            setRulesListing(updatedArray)
          }


        }
      })
      .catch((error) => {
        toast.error(error);
      });
    setLoading(false);
  }


  const handleSubmit = async (e) => {
    e.preventDefault();
    // setLoading(true);
    //     console.log('submittttt', rulesListing)
    // setLoading(false);
    let InputValid = true;
    rulesListing.forEach((val, key) => {
      if (val.rules.length > 0) {
        val.rules.forEach(element => {
          if (element.checked === false) {
            // console.log('data:', element);
            InputValid = false;
            return false;
          }
        });
      }
    });
    if (InputValid) {
      setLoading(true);
      await axios
        .post(CONST.UPDATE_USER_RESPONSIBLITY, {
          user_id: user_id,
          sections: rulesListing,
        }, {
          headers: {
            'Authorization': `Basic ${isToken}`
          }
        })
        .then((response) => {

          const status = response.data.status;
          setLoading(false);
          if (status === 'error') {
            const errorMsg = response.data.errors;
            let isArray = Array.isArray(errorMsg);
            if (isArray) {
              errorMsg.map(element => {
                toast.error(element.msg);
              })
            }
            else {
              toast.error(errorMsg);
            }
            console.log(errorMsg);
          } else {
            toast.success("Permissions updated successfully");
          }
        })
        .catch((error) => {
          toast.error("Something went wrong. Please try again.");
        });
      setLoading(false);
    } else {
      toast.error('Please fill all required fields');
    }

  };


  const handleCheckboxChange = (groupId, ruleIndex, mainIndex, value) => {
    const list = [...rulesListing];
    if (value == 'true') {
      list[mainIndex]['rules'][ruleIndex]['checked'] = false;
    } else if (value == 'false') {
      list[mainIndex]['rules'][ruleIndex]['checked'] = true;
    }
    setRulesListing(list);
  }

  return (
    <>
      <Loading
        loading={loading}
        background="rgba(0, 0, 0, 0.5)"
        loaderColor="#fff"
      />

      <HeaderSection title="" />
      <div className="page-body">
        <div className="container-xl">
          <div className="row row-cards">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h1 className="card-title">Roles & Responsibility</h1>
                </div>

                <div className="card-body border-bottom py-3">
                  <div className="row">
                    {
                      rulesListing.map((group, rulesIndex) => (
                        <div class="col-12 col-md-6  " key={rulesIndex}>
                          <div className="custom_card p-3">
                            <div>
                              <label className="form-label required d-flex">
                                <h4>{group.title}</h4>
                              </label>
                            </div>
                            <div>
                              {
                                group.rules.map((rule, ruleIndex) => (
                                  <div key={ruleIndex} className="pb-1 pt-1">
                                    <input
                                      type="checkbox"
                                      value={rule.checked}
                                      onChange={(e) => handleCheckboxChange(group.id, ruleIndex, rulesIndex, e.target.value)}
                                      checked={rule.checked}
                                    />
                                    &nbsp;&nbsp;
                                    <span >{rule.rules}</span>
                                  </div>
                                ))
                              }
                            </div>
                          </div>
                        </div>
                      ))
                    }

                    {
                      rulesListing ? (<div className="row">
                        <div className="col-md-12 text-right mr-2">
                          <button
                            type="button"
                            name="btnSearchReset"
                            id="btnSearchReset"
                            className="mr-3 btn btn-primary"
                            onClick={handleSubmit}
                          >
                            Update
                          </button>
                        </div>{" "}
                      </div>
                      ) : 'Empty'
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyRoles;
