import { React, useState, useContext, useEffect } from 'react'
import Loading from "react-fullscreen-loading";
import HeaderSection from "../banner/HeaderSection";
import * as  CONST from "../../../component/config/Constant"
import "../../../assets/css/form.css";
import axios from 'axios';
import { AuthContext } from '../../../component/context/AuthContext';
import { toast } from "react-toastify";
import deleteImg from "../../../assets/img/icon/delete.svg";
import DatePicker from 'react-datetime';
import moment from 'moment';
import 'react-datetime/css/react-datetime.css';
import EditableRow from '../../../component/education/EditableRow';
import ReadOnlyRow from '../../../component/education/ReadOnlyRow';
import Swal from 'sweetalert2';
//import { compareAsc, format } from 'date-fns'

function EducationInformation() {
  const [authUserData, setAuthUserData] = useContext(AuthContext);
	
	const [loading, setloading] = useState(false);
    const [editEducationId, setEditEducationId] = useState(null);
    const [inputFields, setInputFields] = useState([{
        institute_name:'',
        degree:'',
        start_date: '', //new Date('DD-MM-YYYY'),
        end_date: '', //new Date('DD-MM-YYYY'), 
        validate: {}
        } ]);
        

    const [educationDetails, setEducationDetails] = useState([{
        institute_name:'',
        degree:'',
        start_date: '', //new Date('DD-MM-YYYY'),
        end_date: '', //new Date('DD-MM-YYYY'),
        
    } ]);

    const [editFormData, setEditFormData] = useState({
        institute_name:'',
        degree:'',
        start_date: '',//new Date('DD-MM-YYYY'),
        end_date: '',//new Date('DD-MM-YYYY'),
        
    } );

	let isToken = '';
	if (authUserData) {
		if (authUserData[0]) {
			isToken = authUserData[0].token ? authUserData[0].token : "";
		}
	}

    useEffect(() => {
        fetchEducationData();
    }, []);

    async function fetchEducationData(){
        setloading(true);
        //GET_EDUCATION_DATA
        await axios
        .get(CONST.GET_EDUCATION_DATA,
        {
            headers: {
                'Authorization': `Bearer ${isToken}`
            },    
            
        })

        .then((response) => {
            
            const status = response.data.status;
            
            if (status === 'success') {
                let newData = response.data.data;
                ;
               
                if(newData != null && newData.length > 0 ){
               
                    {Object.keys(newData).map((key, index) => {
                        newData[index].validate = {}
                    })}
                    setEducationDetails(newData)
                }else{
                    setEducationDetails([{
                        institute_name:'',
                        degree:'',
                        start_date: new Date('DD-MM-YYYY'),
                        end_date: new Date('DD-MM-YYYY'),
                        
                    }])
                }
                
            }else{
                toast.error(response.data.message);
            }
        })
        .catch((error) => {
            console.log(error);
        });
        setloading(false);
    }

    const valdations = async () =>{
        let valid = true;
        
        Object.entries(inputFields).map(([key, value]) => {
            let insError, degreeError, sdateError, edateError  = ''
           
            if(value.institute_name == ''){
                insError = 'Insttute name field is required' 
              valid = false;
            }
            validateChange(key, 'institute_name' ,insError );

            if(value.degree == ''){
                degreeError = 'Degree field is required' 
                valid = false;
            }else{
                if (value.degree.match(/[0-9]/)) {
                    degreeError = 'Invalid degree format' 
                  valid = false;
                }
            }
            validateChange(key, 'degree' ,degreeError );

            if(value.start_date == ''){
                sdateError = 'Start Date field is required' 
                valid = false;
            }
            validateChange(key, 'start_date' ,sdateError );

            if(value.end_date == ''){
                edateError = 'End Date field is required' 
                valid = false;
            }
            validateChange(key, 'end_date' ,edateError );
            //console.log(key+' start Date: '+ value.start_date+ ' end DAte: ' + value.start_date);
            //if(value.start_date >= value.end_date){
            if(new Date(value.start_date) >= new Date(value.end_date)){
                edateError = 'End Date should be greater than Start Date' 
                valid = false;
            }
            validateChange(key, 'end_date' ,edateError );
            
        });
        return valid ;
    }

    const handleSubmit = async (e) => {
       
        let isValid = await valdations();
        if(isValid === true){
            
            setloading(true);
            axios
                .post(CONST.ADD_EDUCATION, 
                    {
                        "education": inputFields
                    }  ,
                    {
                    headers: {
                        'Authorization': `Bearer ${isToken}`,
                        'Content-Type': 'application/json'   
                    }
                })
                .then((response) => {
                    
                    if (response) {

                        if (response.data.status == 'error') {
                            toast.error(response.data.message);
                        } else {
                            toast.success(response.data.message);
                            fetchEducationData()
                            setInputFields(
                                [{
                                    institute_name:'',
                                    degree:'',
                                    start_date: '',
                                    end_date: '', 
                                    validate: {}
                                }]  
                            );
                        }
                    }
                })
                .catch((error) => {
                    setloading(false);
                    console.log(error);
                    toast.error("Something went wrong. Please try again." + error);
                });
            setloading(false);
        }else{
            
        }
    }

    const addExperienceRow = () => {
        
        setInputFields([...inputFields, {
            institute_name:'',
            degree:'',
            start_date: moment(), //'',
            end_date: moment(), //'',  
            validate: {}
        } ])
    }

    const removeInputFields = (index)=>{
        const rows = [...inputFields];
        rows.splice(index, 1);
        setInputFields(rows);
        
    }

    const handleChange = (index, evnt)=>{
        //alert(index + ' '+ JSON.stringify(evnt));
        const { name, value } = evnt.target;
        const list = [...inputFields];
        if(evnt.target.name == 'start_date' || evnt.target.name == 'end_date'){
            const dateString = new Date(value).toLocaleDateString();
            list[index][name] = dateString;
        }else{
            list[index][name] = value;
        }
        
        setInputFields(list);
    }

    const validateChange = (index, fieldName ,error)=>{
        const list = [...inputFields];
        
        if(error != ''){
            list[index]['validate']['validate'] = 1;
            list[index]['validate'][fieldName] = error;    
        }
        
        
        setInputFields(list);
    }

    const validateEditFields = (editFormData,index) =>{
        const newFormData = [...educationDetails];
        let formData = editFormData;
        if(formData.institute_name == ''){
            formData.error_institute_name = 'Institute Name field is required';
        }else if(!/^[a-zA-Z\s]+$/.test(formData.institute_name) ){
			formData.error_institute_name = "Institute name is not valid";
		}else{
            formData.error_institute_name = "";
        }

        if(formData.degree == ''){
            formData.error_degree = 'Degree field is required';
        }else if(!/^[a-zA-Z\s]+$/.test(formData.degree) ){
			formData.error_degree = "Degree is not valid";
		}else{
            formData.error_degree = ""; 
        }

        if(formData.start_date == ''){
            formData.error_start_date = 'Start Date field is required';
        }else if(new Date(formData.start_date) >=  new Date(formData.end_date)){
            formData.error_start_date = 'Start date should be smaller than End date';
        }else{
            formData.error_start_date = '';
        }

        if(formData.end_date == ''){
            formData.error_end_date = 'Start Date field is required';
        }else if(new Date(formData.end_date) <= new Date(formData.start_date)){
            formData.error_end_date = 'End date should be greater than Start date';
        }else{
            formData.error_end_date = '';
        }
        setEditFormData(formData);
        newFormData[index]	= formData;
	 	setEducationDetails(newFormData);

        if(formData.error_institute_name == "" ||
            formData.error_degree == "" ||
            formData.error_start_date == "" ||
            formData.error_end_date == "" ){
                return true;
        }else{
            return false;
        }
    }

    const handleEducationEditFormSubmit = (event, education) => {
		event.preventDefault();
		const index = educationDetails.findIndex((education) => education.id === editEducationId );
	    const isEditDataValid = validateEditFields(editFormData,index);
      
		if (isEditDataValid) {
                        
			axios.put(CONST.EDIT_EDUCATION_DETAILS,
					{
						'id'	   		: editEducationId,
						'institute_name': editFormData.institute_name,
						'degree'    	: editFormData.degree,
                        'start_date'    : moment(editFormData.start_date, 'DD-MM-YYYY').format('DD-MM-YYYY'),
                        'end_date'    : moment(editFormData.end_date, 'DD-MM-YYYY').format('DD-MM-YYYY'),
						
					},{
						headers: {
							'Authorization': `Basic ${isToken}`,
						}
					}
				)
				.then((response) => {
					const status = response.data.status;
					setloading(false);
					
					if (status === 'success') {
						toast.success(response.data.message);
						fetchEducationData();
						setEditEducationId(null);
					} else {
						toast.error(response.data.message);
					}
				})
				.catch((error) => {
					setloading(false);
     				toast.error("Something went wrong. Please try again.");
				});
		}
	}

    const handleEditFormChange = (fieldValue, fieldName) => {
		const newFormData = { ...editFormData };    
        let new_dt = '';
		//newFormData[fieldName] = fieldValue; //event.target.value;
        if (fieldValue == 'start_date' || fieldValue == 'end_date') {
            new_dt = moment(fieldValue).format('DD-MM-YYYY');
            newFormData[fieldName] = new_dt;
           } else
            newFormData[fieldName] = fieldValue; 
		setEditFormData(newFormData);
        
	}

    const handleEditEducation = (event, education) =>{
		event.preventDefault();
		setEditEducationId(education.id);
		const formValues = { 
			'id': education.id,
			'institute_name' : education.institute_name,
			'degree'  : education.degree,
			'start_date' : education.start_date,
			'end_date' : education.end_date
		}
		setEditFormData(formValues);
	}

    const handleCancelClick = (event, guardian) => {
		setEditEducationId(null);
	}

    function deleteEducation(id) {
        Swal.fire({
			title: 'Are you sure?',
			text: "Do you want to delete education details?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then((result) => {
			if (result.isConfirmed) {
				setloading(true);
				const newFormData = [...educationDetails];
				const index = educationDetails.findIndex((education) => education.id === editEducationId );
                if (id > 0) {
                    axios
                        .delete(CONST.DELETE_EDUCATION_DETAILS, {
                            headers: {
                                'Authorization': `Bearer ${isToken}`
                            },
                            data: {
                                edu_id: id
                            }
                        })
                        .then((response) => {

                            if (response) {
                                if (response.data.status == 'error') {
                                    toast.error(response.data.message);
                                } else {
                                    setloading(false);
                                    toast.success(response.data.message);
                                    fetchEducationData();
									// newFormData.splice(index,1);
									// setEducationDetails(newFormData);
                                    //handleClose();
									
                                }
						    }
                        })
                        .catch((error) => {
                            setloading(false);
                            toast.error("Something went wrong. Please try again." + error);
                        });
                        setloading(false);
                }
			}
		})
    }
    //console.log('educationDetails for edit: '+JSON.stringify(educationDetails));
    return (
        <>
			<Loading
				loading={loading}
				background="rgba(0, 0, 0, 0.5)"
				loaderColor="#fff"
			/>
			<HeaderSection
				title=""
				heading="Education Information"
				addButton={addExperienceRow}
				addLink="#"
				addLinkName="Add More"
				linktype=""
			/>
            <div className="page-body">
                <div className="container-xl">
                    <div className="row row-cards">
                        <div className="col-12">
                            <div className="card">
                            <div className="card" key="gcard">

                                <div className="card-body">
                                    <div className="col-12">
                                        <div className="card">
                                            <div className="table-responsive" key="div_0">
                                                <form onSubmit={handleEducationEditFormSubmit}>
                                                <table className="table table-vcenter card-table table-striped" key="table_0" id="editEducationTbl">
                                                    <thead>
                                                        <tr key="tr_head">
                                                        <th>Institute Name</th>
                                                        <th>Degree</th>
                                                        <th>Start Date</th>
                                                        <th>End Date</th>
                                                        <th className="w-1">Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    
                                                    {
                                                        
                                                       educationDetails.map((data, i)=>{
                                                            
                                                            //alert('data edu>>' + JSON.stringify(data));
                                                            const {institute_name, degree, start_date, end_date, validate}= data;
                                                           
                                                            //alert(start_date);
                                                            return(
                                                            <>
                                                            {(editEducationId != null  && editEducationId == data.id) ?
                                                            ( <EditableRow  editFormData={editFormData}
                                                                handleEditFormChange={handleEditFormChange} handleCancelClick={handleCancelClick} /> ):
                                                            ( <ReadOnlyRow data={data} handleEditEducation={handleEditEducation} deleteEducation={deleteEducation} deleteImg={deleteImg}  start_date = {start_date} end_date = {end_date} /> )  
                                                            }
                                                            </>
                                                    
                                                            )
                                                        })
                                                    }
                                                    </tbody>
                                                </table>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                                <div className="card-body">
                                {
                                     
                                inputFields.map((data, i)=>{
                                
                                const {institute_name, degree, start_date, end_date, validate}= data;
                               
                                //alert(start_date);
                                return(
                                <div className="row" key={`row_${i}`}  >
                                    <div className="col-md-6">
                                        <div className="form-group mb-3 ">
                                            <label className="form-label required">
                                                Institute Name
                                            </label>
                                        <div>

                                            <input
                                            type="text"
                                            name="institute_name"
                                            id={ `institute_name_${i}`}
                                            value={institute_name}
                                            className={`form-control ${validate.validate && validate.institute_name
                                                ? "is-invalid "
                                                : ""
                                                }`}
                                            aria-describedby="emailHelp"
                                            placeholder="Enter Institute Name"
                                            onChange={(evnt)=>handleChange(i, evnt)}
                                            />
                                        </div>
                                        <div
                                            className={`invalid-feedback text-start ${validate.validate && validate.institute_name
                                            ? "d-block"
                                            : "d-none"
                                            }`}
                                        >
                                           {validate.validate && validate.institute_name
                                        ? validate.institute_name
                                        : ""}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mb-3 ">
                                    <label className="form-label required">
                                    Degree
                                    </label>
                                    <div>
                                        <input
                                        type="text"
                                        name="degree"
                                        id={ `degree_${i}`}
                                        className={`form-control ${validate.validate && validate.degree
                                            ? "is-invalid "
                                            : ""
                                            }`}
                                        aria-describedby="emailHelp"
                                        placeholder="Enter degree"
                                        onChange={(evnt)=>handleChange(i, evnt)}
                                        value={degree}
                                        />
                                    </div>            
                                    <div
                                        className={`invalid-feedback text-start ${validate.validate && validate.degree
                                        ? "d-block"
                                        : "d-none"
                                        }`}
                                    >
                                        {validate.validate && validate.degree
                                        ? validate.degree
                                        : ""}
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group mb-3 ">
                                    <label className="form-label required">Start Date</label>
                                    <div>
                                    <DatePicker
                                        name="start_date"
                                        id={ `start_date_${i}`}
                                        value={start_date}
                                        selected={start_date}
                                        timeFormat=""
                                        dateFormat="DD-MM-YYYY"
                                        className={`${validate.validate && validate.start_date
                                            ? "is-invalid "
                                            : ""
                                            }`}
                                        onChange={date => handleChange(i, { target: { value: date, name: 'start_date' } })}
                                    />
                                     {/*<input
                                        type="text"
                                        name="start_date"
                                        id={ `start_date_${i}`}
                                        className={`form-control ${validate.validate && validate.start_date
                                            ? "is-invalid "
                                            : ""
                                            }`}
                                        aria-describedby="emailHelp"
                                        placeholder="Enter Start date"
                                        onChange={(evnt)=>handleChange(i, evnt)}
                                        value={start_date}
                                        />*/}
                                        <div
                                        className={`invalid-feedback text-start mb-2 ${validate.validate && validate.start_date
                                        ? "d-block"
                                        : "d-none"
                                        }`}
                                        >
                                        {validate.validate && validate.start_date
                                        ? validate.start_date
                                        : ""}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mb-3 ">
                                    <label className="form-label required">
                                        End Date
                                    </label>
                                    <div>
                                        <DatePicker
                                            name="end_date"
                                            id={ `end_date_${i}`}
                                            value={end_date}
                                            selected={end_date}
                                            dateFormat="DD-MM-YYYY"
                                            timeFormat=""
                                            className={`${validate.validate && validate.end_date
                                                ? "is-invalid "
                                                : ""
                                                }`}
                                            onChange={date => handleChange(i, { target: { value: date, name: 'end_date' } })}
                                        />
                                    </div>
                                    <div
                                    className={`invalid-feedback text-start 
                                        ${validate.validate && validate.end_date
                                        ? "d-block"
                                        : "d-none"
                                    }`}>
                                    {validate.validate && validate.end_date
                                    ? validate.end_date
                                    : ""}
                                    </div>
                                    <div
                                        className="invalid-feedback text-start" >
                                    </div>
                                </div>
                            </div>
                            
                            
                            <div className="col-md-6">
                            {(inputFields.length!==1 && i>0)? 
                            <button className="mr-3 btn btn-danger mt-4" onClick={() => removeInputFields(i)} style={{margin: "1px 10px 30px 1px"}}>Remove</button>:''
                            }
                            </div>
                            <hr></hr>
                        </div>
                        
                        )
                    })
                    }

                       

                        <div className="col-md-12">
                            <div className="form-footer">
                                <button
                                type="button"
                                className="mr-3 btn btn-primary"
                                onClick={handleSubmit}
                                >
                                Submit
                                </button>
                                    &nbsp;&nbsp;
                                    <button
                                    type="reset"
                                    className="btn btn-outline-primary"
                                    
                                    >
                                    Reset
                                </button>
                            </div>
                        </div>
                       
                     
                    </div>
                  </div>
                </div>
              </div>
                </div>
           </div >
    </>
    )
}

export default EducationInformation