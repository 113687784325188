import { React, useState, useEffect, useContext, useMemo } from 'react'
import { Link } from 'react-router-dom';
import Loading from "react-fullscreen-loading";
import HeaderSection from "../banner/HeaderSection";
import DivLoader from "../../../component/utilities/Spinner";
import DataTable from "react-data-table-component";
import axios from "axios";
import { toast } from "react-toastify";
import * as  CONST from "../../../component/config/Constant"
import Form from "../../../component/utilities/Forms";
import editImg from "../../../assets/img/icon/edit.svg";
import deleteImg from "../../../assets/img/icon/delete.svg";
import ReactSwitch from 'react-switch';
import * as SwitchProps from '../../../component/utilities/ReactSwitchReactSwitchProps';
import { AuthContext } from '../../../component/context/AuthContext';
/**For modal popup */
//import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import DivSpinner from '../../../component/utilities/Spinner';


function List() {
    const [authUserData, setAuthUserData] = useContext(AuthContext);
    const [fullLoading, setFullLoading] = useState(false);
    const [validate, setValidate] = useState({});
    const [processing, setProcessing] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [leaveTypeId, setLeaveTypeId] = useState(0);
    const [action, setAction] = useState('');
    const [typeLisiting, setTypeLisiting] = useState('');
    const MySwal = withReactContent(Swal)
    const [listLimit, setListLimit] = useState(50);


    //For Form
    const [name, setName] = useState('');
    const [status, setStatus] = useState(false);
    const [code, setCode] = useState('');



    // Search 
    const [srchName, setSrchName] = useState('');
    const [srchStatus, setSrchStatus] = useState('');


    /* START DATATABLE */
    const [totalRecords, settotalRecords] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [maxPageLimit, setMaxPageLimit] = useState(10);
    const [minPageLimit, setMinPageLimit] = useState(0);
    const [searchParam, setSearchParam] = useState(
        {
            "srchName": srchName,
            "srchStatus": srchStatus
        }
    );

    const [dataSort, setDataSort] = useState({
        order: 'desc',
        order_by: 'id'
    });
    const pageNumberLimit = { perPage };


    let isToken = '';
    let userId = '';
    if (authUserData) {
        if (authUserData[0]) {
            isToken = authUserData[0].token ? authUserData[0].token : "";
            userId = authUserData[0].user_id ? authUserData[0].user_id : 0;
        }
    }
    useEffect(() => {
        fetchLeaveTypeData(searchParam, dataSort, activePage, perPage);
    }, [searchParam, dataSort, activePage, listLimit]);

    /** For Modalpopup */
    const [show, setShow] = useState(false);


    function handleShow(action, type) {
        let type_id = type ? type : 0;
        setLeaveTypeId(type_id)
        if (leaveTypeId > 0) {
            // getLeaveTypeByID(leaveTypeId);
        }
        setAction(action)
        setShow(true);
    }

    const handleClose = () => {
        setShow(false);
        setLeaveTypeId(0);
        setName('');
        setStatus('')
        setCode('')
    }


    const handleSubmit = async (e) => {

        e.preventDefault();
        //console.log('dept: ' + deptName + ' email: ' + deptEmail + ' status :' + status + ' leaveTypeId: ' + leaveTypeId)

        let isValid = true;

        let validator = Form.validator({
            name: {
                value: name,
                isRequired: true,
                label: "Name",

            },
            code: {
                value: code,
                isRequired: true,
                isEmail: false,
                label: "Code",
            }

        });

        if (validator !== null) {
            setValidate({
                validate: validator.errors,
            });

            isValid = false;
        }

        if (isValid) {
            setLoading(true);
            let api_url = '';
            let sucessMessage = '';
            let method = '';
            let typeStatus = (status == true) ? '1' : '0';

            if (leaveTypeId == 0) {
                api_url = CONST.CREATE_LEAVE_TYPE;
                sucessMessage = 'Leave Type added succesfully';
                method = 'post';

            } else {
                api_url = CONST.UPDATE_LEAVE_TYPE;
                sucessMessage = 'Leave Type updated succesfully';
                method = 'post';
            }


            await axios({
                method: method,
                url: api_url,
                data: {
                    name: name,
                    code: code,
                    is_active: status,
                    id: userId,
                    leave_type_id: leaveTypeId
                },
                headers: {
                    'Authorization': `Bearer ${isToken}`
                }

            })
                .then((response) => {
                    console.log(response.data.errors);

                    if (response) {

                        if (response.data.status == 'error') {
                            console.log('in error');
                            toast.error("Something went wrong. Please try again.");
                        } else {
                            toast.success(response.data.message);
                            handleClose();
                            fetchLeaveTypeData(searchParam, dataSort, activePage, perPage);
                            setName('');
                            setStatus('')
                            setCode('')

                            setLoading(false);
                        }
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                    toast.error("Something went wrong. Please try again." + error);
                });


        } else {
            console.log('err-' + JSON.stringify(validator));
            //toast.error("Error: "+ validator.errors);
        }

    }

    const columns = [
        {
            name: 'Sr. No',
            selector: (row, index) => index + 1,
            width: "10%",
        },

        {
            name: 'Leave Type',
            selector: (row) => row.name,
            width: "25%",

        }, {
            name: 'Code',
            selector: (row) => row.code,
            wrap: true,


        },
        {
            name: 'Status',
            selector: (row) => 'Active',
            width: "10%",
            selector: (row, index) => <ReactSwitch
                onColor={SwitchProps.onColor}
                onHandleColor={SwitchProps.onHandleColor}
                handleDiameter={SwitchProps.handleDiameter}
                uncheckedIcon={SwitchProps.uncheckedIcon}
                checkedIcon={SwitchProps.checkedIcon}
                boxShadow={SwitchProps.boxShadow}
                activeBoxShadow={SwitchProps.activeBoxShadow}
                height={SwitchProps.height}
                width={SwitchProps.width}
                className={SwitchProps.className}
                checked={(row.is_active == 1) ? true : false}
                onChange={() => { handleChangeStatus(index, row.is_active, row.id) }}
            />,
        },
        {
            name: 'Action',
            width: "20%",
            wrap: true,
            sortable: false,
            cell: (row) => <Action row={row} />,
        }
    ]
    const Action = ({ row }) => (
        <>
            <Link to={'#'} data-id={row.id} onClick={() => handleShow('Edit', row.id)}>
                <img src={editImg} alt='editUser' width={32} className='cursor' />
            </Link>
            <Link onClick={() => { deleteHandler(row.id) }}>
                <img src={deleteImg} alt='deleteImg' width={32} className='cursor' />
            </Link>


        </>
    )

    function deleteHandler(del_id) {
        alert(del_id)
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to delete this Leave Type?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);
                if (del_id > 0) {
                    setLoading(true);
                    /*axios
                        .post(CONST.DELETE_LEAVE_TYPE, {
                            headers: {
                                'Authorization': `Bearer ${isToken}`
                            },
                            data: {
                                id:userId,
                                leave_type_id:del_id
                            }
                        })*/
                    axios({
                        method: "post",
                        url: CONST.DELETE_LEAVE_TYPE,
                        data: {
                            id: userId,
                            leave_type_id: del_id
                        },
                        headers: {
                            'Authorization': `Bearer ${isToken}`
                        }

                    })
                        .then((response) => {
                            console.log(response.data);

                            if (response) {

                                if (response.data.status == 'error') {
                                    toast.error(response.data.message);
                                } else {
                                    toast.success(response.data.message);
                                    handleClose();
                                    fetchLeaveTypeData(searchParam, dataSort, activePage, perPage);

                                }
                            }
                        })
                        .catch((error) => {
                            setLoading(false);
                            console.log(error);
                            toast.error("Something went wrong. Please try again." + error);
                        });
                    setLoading(false);
                }

            }
        })
    }

    async function searchLeaveType() {
        setSearchParam({
            "srchName": srchName,
            "srchStatus": srchStatus
        })
        setDataSort({
            order: "desc",
            order_by: "id"
        });
        setActivePage("1");
    }

    function clearSearch() {
        setSearchParam({
            "srchName": '',
            "srchStatus": ''
        })
        setDataSort({
            order: "desc",
            order_by: "id"
        });
        setActivePage("1");

    }


    /*async function getLeaveTypeByID(dept_id) {
       
        await axios
            .get(CONST.GET_DEPARTMENT_BY_ID + '/' + dept_id,
                {
                headers: {
                    'Authorization': `Bearer ${isToken}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                params: { 
                    //'dept_id': dept_id 
                },
                
            },
            
            )

            .then((response) => {
                const status = response.data.status;
                
                console.log(response.data.data[0]);
                if (status === 'success') {
                    let data = response.data.data[0] ? response.data.data[0] : [];
                    setName( data['name'] ? data['name'] : '' );
                    setStatus( data['status'] ? data['status'] : '' );
                    setCode( data['code'] ? data['code'] : '' );
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }*/

    async function fetchLeaveTypeData(paramSearch, paramSort, paramPage, paramLimit) {
        setLoading(true);
        let searchParams = JSON.stringify({
            'limit': paramLimit,
            'search_input': paramSearch,
            'page_number': paramPage,
            'sort': paramSort
        })

        await axios
            .post(CONST.GET_LEAVE_TYPE_LIST,
                {
                    'limit': paramLimit,
                    'search_input': paramSearch,
                    'page_number': paramPage,
                    'sort': paramSort
                },
                {
                    headers: {
                        'Authorization': `Bearer ${isToken}`
                    },


                })

            .then((response) => {
                console.log('LEave types Res- ' + JSON.stringify(response));
                const status = response.data.status;

                if (status === 'success') {
                    setTypeLisiting(response.data.data);
                    setTotalRows(response.data.count);

                    settotalRecords(response.data.count);
                    setData(response.data.data);
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        setLoading(false);
    }

    const handleChangeStatus = (index, val, leave_type_id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to change the status?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, change it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);
                alert('Update status');
                /*axios.patch(CONST.UPDATE_DEPT_STATUS,
                    {
                        'leave_type_id': leave_type_id,
                        'status': (val == '0') ? '1' : '0',
                    },
                    {
                        headers: {
                            'Authorization': `Basic ${isToken}`
                        }
                    }
                )
                    .then((response) => {
                        console.log(response);
                        const status = response.data.status;
                        if (status == 'success') {
                            toast.success(response.data.message);
                        } else {
                            toast.error(response.data.message);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        toast.error("There was an error.");
                    });*/
                setLoading(false);

                const newData = [...data];
                newData[index].status = (val == 0) ? 1 : 0;
                setData(newData);
            }
        })

    }

    const handlePerRowsChange = async (perPage, page) => {
        console.log('handlePerRowsChange');
        setLoading(true);
        const response = await axios.get(
            `https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`
        );
        setData(response.data);
        setPerPage(perPage);
        setLoading(false);
    };
    function toPages(pages) {
        const results = [];
        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }
        return results;
    }
    function getNumberOfPages(rowCount, rowsPerPage) {
        return Math.ceil(rowCount / rowsPerPage);
    }
    const handleSort = async (column, sortDirection) => {
        setActivePage(1);
        setSearchParam({
            search_input: '',
            status: '',
        });

        setDataSort({
            order: sortDirection,
            order_by: column.sortField
        })
        /*  setLoading(false); */

    };
    /*  PAGINATION FUNCTIONS  */
    const handlePageChange = async (page) => {
        console.log(page);
        setActivePage(page)
    };

    const BootyPagination = ({
        rowsPerPage,
        rowCount,
        onChangePage,
        onChangeRowsPerPage, // available but not used here
        currentPage,
    }) => {

        const handleBackButtonClick = async () => {
            if ((currentPage - 1) % perPage === 0) {
                setMaxPageLimit(maxPageLimit - perPage);
                setMinPageLimit(minPageLimit - perPage);
            }
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = async () => {
            if (currentPage + 1 > maxPageLimit) {
                setMaxPageLimit(maxPageLimit + perPage);
                setMinPageLimit(minPageLimit + perPage);
            }

            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        let paginationText = "Page:  " + currentPage + ", Showing ";

        paginationText =
            paginationText +
            ((currentPage - 1) * perPage + 1) +
            " to " +
            perPage * currentPage;
        paginationText = paginationText + " of " + rowCount + " entries;";
        const pages = getNumberOfPages(rowCount, rowsPerPage);
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === pageItems.length;
        const previosDisabled = currentPage === 1;

        const pageNumbers = pageItems.map((page) => {
            if (page <= maxPageLimit && page > minPageLimit) {
                return (
                    <li
                        key={page}
                        id={page}
                        className={`page-item ${currentPage === page ? "active" : ""}`}
                    >
                        <button
                            className={`page-link ${currentPage === page ? "active" : ""}`}
                            onClick={handlePageNumber}
                            value={page}
                        >
                            {page}
                        </button>
                    </li>
                );
            } else {
                return null;
            }
        });
        let pageIncrementEllipses = null;
        if (pageItems.length > maxPageLimit) {
            pageIncrementEllipses = (
                <li className="page-item" onClick={handleNextButtonClick}>
                    &hellip;
                </li>
            );
        }
        let pageDecremenEllipses = null;
        if (minPageLimit >= 1) {
            pageDecremenEllipses = (
                <li className="page-item" onClick={handleBackButtonClick}>
                    &hellip;
                </li>
            );
        }
        return (
            <div className="card-footer d-flex align-items-center">
                <p className="m-0 text-muted">{paginationText}</p>

                <ul className="pagination m-0 ms-auto">
                    <li className="page-item">
                        <button
                            className="page-link"
                            onClick={handleBackButtonClick}
                            disabled={previosDisabled}
                            aria-disabled={previosDisabled}
                            aria-label="previous page"
                        //pageRangeDisplayed={perPage}
                        >
                            «
                        </button>
                    </li>
                    {pageDecremenEllipses}
                    {pageNumbers}
                    {pageIncrementEllipses}
                    <li className="page-item">
                        <button
                            className="page-link"
                            onClick={handleNextButtonClick}
                            disabled={nextDisabled}
                            aria-disabled={nextDisabled}
                            aria-label="next page"
                        >
                            »
                        </button>
                    </li>
                </ul>
            </div>
        );
    };
    /* END DATATABLE */
    const CustomLoader = () => (
        <div style={{ padding: "24px" }}>
            <DivLoader color="primary" />
        </div>
    );


    return (
        <>
            <Loading
                loading={fullLoading}
                background="rgba(0, 0, 0, 0.5)"
                loaderColor="#fff"
            />
            <HeaderSection
                title="Manage"
                heading="Leave Types"
                //addButton={handleShow}
                addButton={() => handleShow('Add', 0)}
                addLink="#"
                addLinkName="Add Leave Type"
                linktype="add"
            />
            <div className="page-body">
                <div className="container-xl">
                    <div className="row row-cards">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Listing</h3>
                                </div>
                                <div className="card-body border-bottom py-3">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group mb-3 ">
                                                <label className="form-label required">Name</label>
                                                <div>
                                                    <input
                                                        type="text"
                                                        name="srchName"
                                                        id="srchName"
                                                        className="form-control"
                                                        aria-describedby="deptHelp"
                                                        placeholder="Enter Name"
                                                        onChange={(e) => setSrchName(e.target.value)}
                                                        value={srchName}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="mb-3">
                                                <label className="form-label required">
                                                    Status
                                                </label>

                                                <select
                                                    className="form-select form-control"
                                                    name="srchStatus"
                                                    id="srchStatus"
                                                    placeholder="Select status"
                                                    value={srchStatus}
                                                    onChange={(e) => setSrchStatus(e.target.value)}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="1">Active</option>
                                                    <option value="0">Deactive</option>

                                                </select>


                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group mb-3 ">
                                                <label className="form-label">&nbsp;</label>
                                                <div>
                                                    <button
                                                        type="button"
                                                        name="btnSearch"
                                                        id="btnSearch"
                                                        className="btn btn-blue d-none d-sm-inline-block ml-3"
                                                        onClick={searchLeaveType}
                                                    ><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <circle cx="10" cy="10" r="7"></circle>
                                                            <line x1="21" y1="21" x2="15" y2="15"></line>
                                                        </svg>Search</button>&nbsp;
                                                    <button
                                                        type="button"
                                                        name="btnReset"
                                                        id="btnReset"
                                                        className="btn btn-outline-primary "
                                                        onClick={clearSearch}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <line x1="18" y1="6" x2="6" y2="18"></line>
                                                            <line x1="6" y1="6" x2="18" y2="18"></line>
                                                        </svg>Clear</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">

                                    <DataTable
                                        columns={columns}
                                        data={typeLisiting}
                                        progressPending={loading}
                                        progressComponent={<CustomLoader />}
                                        pagination="true"
                                        paginationServer
                                        paginationTotalRows={totalRows}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        onChangePage={handlePageChange}
                                        paginationPerPage={perPage}
                                        paginationComponent={BootyPagination}
                                        sortServer
                                        onSort={handleSort}
                                    />

                                </div>
                                <div className="card-footer d-flex align-items-center">
                                    <p className="m-0 text-muted">
                                        Showing {/* <span>1</span> to <span>8</span> of{" "} */}
                                        <span>{totalRecords}</span> entries
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal size="lg" show={show} onHide={handleClose}>

                <Modal.Header closeButton>
                    <Modal.Title>{action} Leave Type</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <form
                        method="POST"
                        onSubmit={handleSubmit}
                        autoComplete={"off"}
                    >
                        <div className="row">

                            <div className="col-md-6">
                                <div className="form-group mb-3 ">
                                    <label className="form-label required">
                                        Name
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            className={`form-control ${validate.validate && validate.validate.name
                                                ? "is-invalid "
                                                : ""
                                                }`}
                                            aria-describedby="emailHelp"
                                            placeholder="Enter name"
                                            onChange={(e) => setName(e.target.value)}
                                            value={name}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group mb-3 ">
                                    <label className="form-label required">
                                        Code
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            name="code"
                                            id="code"
                                            className={`form-control ${validate.validate && validate.validate.code
                                                ? "is-invalid "
                                                : ""
                                                }`}
                                            aria-describedby="emailHelp"
                                            placeholder="Enter code"
                                            onChange={(e) => setCode(e.target.value)}
                                            value={code}
                                        />
                                    </div>
                                </div>
                            </div>
                            {(leaveTypeId != 0)
                                ?
                                <>
                                    <div className="col-md-6">
                                        <div className="form-group mb-3 ">
                                            <label className="form-label required">
                                                Status
                                            </label>
                                            <div>
                                                <select
                                                    className={`form-select form-control ${validate.validate && validate.validate.status
                                                        ? "is-invalid "
                                                        : ""
                                                        }`}
                                                    name="status"
                                                    id="status"
                                                    placeholder="Select status"
                                                    onChange={(e) => setStatus(e.target.value)}
                                                    value={status}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="1">Active</option>
                                                    <option value="0">Deactive</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </>

                                :
                                ''
                            }
                            <div className="col-md-12">
                                <div className="form-footer">

                                    <button
                                        type="submit"
                                        className="mr-3 btn btn-primary"
                                        onClick={handleSubmit}
                                    >Submit</button>

                                    &nbsp;&nbsp;

                                </div>
                            </div>
                        </div>
                        <input type="hidden" name="leaveTypeId" id="leaveTypeId" value={leaveTypeId} />

                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default List