import { React, useState, useEffect, useContext, useRef, useMemo } from 'react'
import { Link } from 'react-router-dom';
import Loading from "react-fullscreen-loading";
import HeaderSection from "../banner/HeaderSection";
import DivLoader from "../../../component/utilities/Spinner";
import DataTable from "react-data-table-component";
import axios from "axios";
import { toast } from "react-toastify";
import * as  CONST from "../../../component/config/Constant"
import Form from "../../../component/utilities/Forms";
import editImg from "../../../assets/img/icon/edit.svg";
import deleteImg from "../../../assets/img/icon/delete.svg";
import ReactSwitch from 'react-switch';
import * as SwitchProps from '../../../component/utilities/ReactSwitchReactSwitchProps';
import { AuthContext } from '../../../component/context/AuthContext';
/**For modal popup */
//import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import DatePicker from 'react-datetime';
import moment from 'moment';
import JoditEditor from 'jodit-react';
import ViewLeaveDetails from './ViewLeaveDetails';
import LeaveImg from "../../../assets/img/icon/index.png";
import CancelLeaveImg from "../../../assets/img/icon/delete.svg";
import Select2 from 'react-select2-wrapper';
import 'react-select2-wrapper/css/select2.css';

import MultiSelect from 'react-multiple-select-dropdown-lite'
import 'react-multiple-select-dropdown-lite/dist/index.css'
import ExportCsv from '../../../component/export/ExportCsv';

//import TimePicker from 'react-time-picker';

function List() {
    const [authUserData, setAuthUserData] = useContext(AuthContext);
    const [fullLoading, setFullLoading] = useState(false);
    const [validate, setValidate] = useState({});
    const [processing, setProcessing] = useState(false);
    const [data, setData] = useState([]);
    const [headEmails, setHeadEmails] = useState([]);
    const [leaveTypeData, setLeaveTypeData] = useState([]);

    const [newHeads, setNewHeads] = useState([]);//newHeads = [];
    const [newHeadsCC, setNewHeadsCC] = useState([]);//newHeads = [];

    const [loading, setLoading] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [action, setAction] = useState('');
    const [leaveLisiting, setLeaveLisiting] = useState('');
    const MySwal = withReactContent(Swal)
    const [listLimit, setListLimit] = useState(50);
    const [editLeaveId, setEditLeaveId] = useState(50);

    const [disabled, setDisabled] = useState('');


    /** form model popup */
    const [isLeaveModalShow, setLeaveModalShow] = useState(false);
    const [showLeaveModel, setLeaveShowModel] = useState({
        show: 'hide'
    });

    const editor = useRef(null);

    const config = useMemo(
        () => ({
            readonly: false,
            uploader: { "insertImageAsBase64URI": true }
        }),
        []
    );

    //For Form
    const [to, setTo] = useState('');
    const [cc, setCc] = useState('');

    // const [toMail, setToMail] = useState([]);
    // const [ccMail, setCcMail] = useState([]);

    const [subject, setSubject] = useState('');
    const [leaveType, setLeaveType] = useState('');
    const [leaveTypeId, setLeaveTypeId] = useState('');
    const [reason, setReason] = useState('');
    const [leaveStartDate, setLeaveStartDate] = useState('');
    const [leaveEndDate, setLeaveEndDate] = useState('');
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [filterUsers, setfilterUsers] = useState([]);
    const headerData = [
		{ label: "Name", key: "first_name" },
		{ label: "Leave Type", key: "leave_type" },
		{ label: "Leave Date", key: "leave_date" },
		{ label: "Time", key: "time" },
		{ label: "Leave Count", key: "leave_count" },
		{ label: "Status", key: "status" }
	  ];



    // Search Leave
    const [srchName, setSrchName] = useState('');
    const [srchStatus, setSrchStatus] = useState('');
    const [srchLeaveType, setSrchLeaveType] = useState('');
    const [srchLeaveDate, setSrchLeaveDate] = useState('');
    const [srchYear, setSrchYear] = useState(moment().format('YYYY'));
    const [srchMonth, setSrchMonth] = useState('');
    const [srchLeaveStartDate, setSrchLeaveStartDate] = useState(moment().format('DD-MM-YYYY'));
    const [srchLeaveEndDate, setSrchLeaveEndDate] = useState(moment().format('DD-MM-YYYY'));

    //const [srchDeptCode, setSrchDeptCode] = useState('');

    /* START DATATABLE */
    const [totalRecords, settotalRecords] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [maxPageLimit, setMaxPageLimit] = useState(10);
    const [minPageLimit, setMinPageLimit] = useState(0);
    const [searchParam, setSearchParam] = useState(
        {
            "srchName": srchName,
            "srchStatus": srchStatus,
            "srchLeaveDate": (srchLeaveDate != '') ? moment(srchLeaveDate).format('DD-MM-YYYY') : '',
            //"srchLeaveStartDate": (srchLeaveStartDate != '') ? moment(srchLeaveStartDate).format('DD-MM-YYYY'): '',
            //"srchLeaveEndDate": (srchLeaveEndDate != '') ? moment(srchLeaveEndDate).format('DD-MM-YYYY'): '',
            "srchLeaveType": srchLeaveType
        }
    );

    const [dataSort, setDataSort] = useState({
        order: 'desc',
        order_by: 'id'
    });
    const [viewData, setViewData] = useState([])
    const [showInput, setShowInput] = useState(false);
    const pageNumberLimit = { perPage };


    let isToken = '';
    let userId = '';
    let deptId = '';
    if (authUserData) {
        if (authUserData[0]) {
            isToken = authUserData[0].token ? authUserData[0].token : "";
            userId = authUserData[0].user_id ? authUserData[0].user_id : 0;
            deptId = authUserData[0].department_id ? authUserData[0].department_id : 0;
        }
    }
    //alert(JSON.stringify(authUserData));
    useEffect(() => {
        fetchLeavesData(searchParam, dataSort, activePage, perPage);
        fetchLeaveTypes();
        fetchHeadMails();
        fetchHeadCCMails();
    }, [searchParam, dataSort, activePage, listLimit]);

    /** For Modalpopup */
    const [show, setShow] = useState(false);
    const [showCompOff, setShowCompOff] = useState(false);
    

    const closeLeave = async (type) => {
        setLeaveShowModel({
            show: 'hide'
        });
    }

    const handleLeaveShow = (row) => {
        setLoading(true);
        setLeaveModalShow(true);
        setLeaveShowModel({
            show: 'show'
        });
        setEditLeaveId(row.id);
        setViewData(row);
        setLoading(false);
    }

    const handleCancelLeave = (row) => {
        Swal.fire({
            // title: 'Are you sure?',
            html: "<strong>Do you want to cancel this leave?</strong>",
            content: "input",
            type: "input",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Cancel it!',
            animation: "slide-from-top",
            inputPlaceholder: "Add Your Comments",
            input: 'text',
            inputValue: "",
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);
                if (row.id > 0) {

                    axios
                        .post(CONST.LEAVE_CANCEL,
                            {
                                leave_id: row.id,
                                id: userId,
                                status: '3',
                                comments: result.value,
                                cancel_by:userId
                            },
                            {
                                headers: {
                                    'Authorization': `Bearer ${isToken}`
                                }

                            })
                        .then((response) => {

                            if (response) {
                                if (response.data.status == 'error') {
                                    toast.error(response.data.message);
                                } else {
                                    toast.success(response.data.message);
                                    fetchLeavesData(searchParam, dataSort, activePage, perPage);
                                }
                            }
                        })
                        .catch((error) => {
                            setLoading(false);
                            toast.error("Something went wrong. Please try again." + error);
                        });
                    setLoading(false);
                }
            }
        })
    }

    //fetch all Department details

    async function fetchLeaveTypes() {
        setLoading(true);
        await axios
            .post(CONST.LEAVE_TYPE_LIST, {
                search_input: '',
                page_number: ''
            }, {
                headers: {
                    'Authorization': `Bearer ${isToken}`
                }
            })

            .then((response) => {//alert('leave types: '+response);
                const status = response.data.status;
                if (status === 'success') {
                    setLeaveTypeData(response.data.data);
                }
            })
            .catch((error) => {
                alert(error);
            });
        setLoading(false);
    }


    async function fetchHeadMails() {
        let EmailArray = [];
        setLoading(true);
        await axios
            .get(CONST.GET_HEAD_EMAILS, {
                headers: {
                    'Authorization': `Bearer ${isToken}`
                }
            })

            .then((response) => {
                const status = response.data.status;
                if (status === 'success') {
                    //alert(JSON.stringify(response.data.data));
                    setHeadEmails(response.data.data);
                    let newData = response.data.data;
                    //alert('eduData >> '+ JSON.stringify(newData));
                    if (newData.length > 0) {
                        {
                            Object.keys(newData).map((key, index) => {
                                //newData[index].validate = {}
                                //newHeads.text = newData[index].name;
                                //newHeads.text = newData[index].name;
                                let optionsObj = {
                                    'text': newData[index].name,
                                    'value': newData[index].email,
                                }
                                EmailArray.push(optionsObj);

                            })
                        }


                        //alert('EmailArray'+JSON.stringify(newHeads));
                    }

                    let optionsVersion = newData.map((v, index) => (
                        { label: v.name, value: v.email }
                    ));


                    setNewHeads(optionsVersion);
                    //alert('EmailArray'+JSON.stringify(newHeads));
                }
            })
            .catch((error) => {
                alert(error);
            });
        setLoading(false);
    }

    async function fetchHeadCCMails() {
        let EmailArray = [];
        setLoading(true);
        await axios
            .post(CONST.GET_HEAD_CC_EMAILS, {
                id: userId,
            }, {
                headers: {
                    'Authorization': `Bearer ${isToken}`
                }
            })

            .then((response) => {
                const status = response.data.status;
                if (status === 'success') {
                    //alert(JSON.stringify(response.data.data));
                    setNewHeadsCC(response.data.data);
                    let newData = response.data.data;
                    //alert('eduData >> '+ JSON.stringify(newData));
                    if (newData.length > 0) {
                        {
                            Object.keys(newData).map((key, index) => {
                                //newData[index].validate = {}
                                //newHeads.text = newData[index].name;
                                //newHeads.text = newData[index].name;
                                let optionsObj = {
                                    'text': newData[index].name,
                                    'value': newData[index].email,
                                }
                                EmailArray.push(optionsObj);

                            })
                        }


                        //alert('EmailArray'+JSON.stringify(newHeads));
                    }

                    let optionsVersion = newData.map((v, index) => (
                        { label: v.name, value: v.email }
                    ));


                    setNewHeadsCC(optionsVersion);
                    //alert('EmailArray'+JSON.stringify(newHeads));
                }
            })
            .catch((error) => {
                alert(error);
            });
        setLoading(false);
    }

    const handleOnchangeTo = val => {
        if (val.indexOf('other') !== -1) {
            setShowInput(true);
        }
        else {
            setShowInput(false);
        }
        setTo(val)
    }

    const handleOnchangeCC = val => {
        if (val.indexOf('other') !== -1) {
            setShowInput(true);
        }
        else {
            setShowInput(false);
        }
        setCc(val)
    }

    const validateLeave = async () => {
        let isValid = true;

        let validator = Form.validator({
            to: {
                value: to,
                isRequired: true,
                isEmail: false,
                label: "To",
            },
            /*cc: {
                value: cc,
                isRequired: false,
                isEmail: true,
                label: "CC",
            },*/
            subject: {
                value: subject,
                isRequired: true,
                isEmail: false,
                label: "Subject",
            },
            reason: {
                value: reason,
                isRequired: true,
                isEmail: false,
                label: "Reason",
            },
            leaveType: {
                value: leaveType,
                isRequired: true,
                isEmail: false,
                label: "Leave Type",
            },
            leaveTypeId: {
                value: leaveTypeId,
                isRequired: true,
                isEmail: false,
                label: "Leave Type",
            },
            leaveStartDate: {
                value: leaveStartDate,
                isRequired: true,
                isEmail: false,
                label: "Start Date",
            },

            /*leaveEndDate: {
                value: leaveEndDate,
                isRequired: true,
                isEmail: false,
                label: "leaveEndDate",
            },*/

        });
        console.log('validator:' + JSON.stringify(validator));
        if (validator !== null) {
            setValidate({
                validate: validator.errors,
            });
            isValid = false;
        } else {
            setValidate({
                validate: "",
            });
            isValid = true;
        }
        return isValid;
    };

    const handleSetMails = (e, field) => {
        //alert('23');
        var options = e.target.options;

        let value = Array.from(e.target.selectedOptions, option => option.value);
        //alert(options.length+'||'+value)

        // if(field === 'to'){
        //     setTo(value);
        // }else{
        //     setCc(value);
        // }
        //setTo({multiValue: [...e.target.selectedOptions].map(o => o.value)}); 
        console.log("todays to>> " + to);
    }

    const handleSubmit = async (e) => {

        // alert('toooo:'+to); alert('ccccc:'+cc);
        e.preventDefault();
        const validate = await validateLeave();
        if (validate) {
            setLoading(true);
            setDisabled('disabled');
            let sendData = {
                id: userId,
                to: to,
                cc: cc,
                subject: subject,
                reason: reason,
                leave_type: leaveType,
                leave_type_id: leaveTypeId,
                leave_from_date: moment(leaveStartDate).format('YYYY-MM-DD'),
                approve_leave_url: CONST.APP_LINK + 'admin/employee-leaves',
                //leave_to_date: moment(leaveEndDate).format('YYYY-MM-DD')
            }
            if (leaveType == "1") {
                sendData.leave_to_date = moment(leaveEndDate).format('YYYY-MM-DD');
            } else {
                sendData.from_time = moment(startTime).format('HH:mm:ss');
                sendData.to_time = moment(endTime).format('HH:mm:ss');
            }
            //alert(JSON.stringify(sendData));

            await axios({
                method: "post",
                url: CONST.APPLY_LEAVE,
                data: sendData,
                headers: {
                    'Authorization': `Bearer ${isToken}`
                }

            })
                .then((response) => {
                    console.log('apply leave response: ' + JSON.stringify(response));
                    //alert(response.data.status);
                    if (response) {

                        if (response.data.status == 'error') {

                            toast.error("Something went wrong. Please try again.");
                        } else {
                            toast.success(response.data.message);
                            handleClose();
                            fetchLeavesData(searchParam, dataSort, activePage, perPage)
                            setLoading(false);
                            setDisabled('');
                        }
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    setDisabled('');
                    console.log('leave Error: ' + error);
                    toast.error("Something went wrong. Please try again." + error);
                });


        } else {
            //console.log('err-'+JSON.stringify(validator));
            //toast.error("Error: "+ validator.errors);
        }

    }



    const geLeaveType = (leave_type) => {
        //console.log('LLLL'+leave_type)
        /* if(leave_type == '0'){
             return 'Full Day';
         }else if(leave_type == '1'){
             return 'Half Day';
         }else{
             return 'Short Leave';
         }*/
        return (leave_type);
    }

    const formatTime = (date) => {
        return date.slice(0, -3);
    }

    const leaveTypeArr = {
        '0': 'Full Day',
        '1': 'Half Day',
        '2': 'Short Leave',
    }

    const CustomLeaveType = ({ row }) => (

        <div className="d-flex py-1 align-items-center">

            <div className="flex-fill pl-2">
                <div className="font-weight-medium"> {row.leave_type}</div>
                <div className="text-muted">
                    <span className="text-muted" >
                        <small>
                            {row.e_leave_type.name} Leave
                        </small>
                    </span>
                </div>


            </div>
        </div>
    );

    const CustomLeaveDate = ({ row }) => (

        <div className="d-flex py-1 align-items-center">

            <div className="flex-fill pl-2">
                <div className="font-weight-medium"> {moment(row.leave_from_date).format('DD-MM-YYYY') + ((row.leave_type == 'Full Day') ? ' to ' + moment(row.leave_to_date).format('DD-MM-YYYY') : '')}</div>
                <div className="text-muted">
                    <span className="text-muted" >
                        <small>
                            {((row.leave_type == 'Full Day') ? row.no_of_days + ' Day(s)' : '')}

                        </small>
                    </span>
                </div>


            </div>
        </div>
    );

    const CustomLeaveCount = ({ row }) => (

        <div className="d-flex py-1 align-items-center">

            <div className="flex-fill pl-2">
                <div className="font-weight-medium">
                    Total : {row.totalLeaveCount}
                </div>
                <div className="font-weight-medium">
                    Used : {row.consumedLeaveCount}
                </div>
                <div className="font-weight-medium">
                    Balanced : {row.balanceLeaveCount}
                </div>
            </div>
        </div>
    );
    const LeaveStatus = ({ row }) => (
        <>
            {
                row.status && (
                    <>
                        {row.status == 0 ? <span class='badge bg-info '>Pending</span> : ""}
                        {row.status == 1 ? <span class='badge bg-success '>Approved</span> : ""}
                        {row.status == 2 ? <span class='badge bg-warning '>Rejected</span> : ""}
                        {row.status == 3 ? <span class='badge bg-danger '>Cancelled</span> : ""}
                    </>
                )
            }
        </>
    )

    const LeaveStatusChangedBy = ({ row }) => (
        <>

            {(() => {


                if (row.status == 1) {
                    return (
                        <div>

                            <span class=''>Approved by {(row.approvedByUser != '') ? row.approvedByUser.first_name + ' ' + row.approvedByUser.last_name : '-'}</span>
                        </div>
                    )
                } else if (row.status == 2) {
                    return (
                        <div>

                            <span class=''>Rejected by {(row.approvedByUser != '') ? row.approvedByUser.first_name + ' ' + row.approvedByUser.last_name : '-'}</span>
                        </div>
                    )
                } else {
                    return (
                        <span class=''>-</span>
                    )
                }


            })()}
        </>
    )

    const columns = [
        {
            name: 'Sr. No',
            selector: (row, index) => index + 1,
            width: "10%",
        },

        {
            name: 'Leave Type',
            // selector: (row) => row.leave_type,//{geLeaveType(row.leave_type)},
            selector: (row) => row,
            cell: (row) => <CustomLeaveType row={row} />,
            wrap: true,
        },

        {
            name: 'Date',
            // selector: (row) => row.leave_from_date + ((row.leave_type == 'Full Day') ? ' to '+ row.leave_to_date : '') ,

            selector: (row) => row,
            cell: (row) => <CustomLeaveDate row={row} />,
            wrap: true,
            sortable: true,
            sortField: "leave_from_date",
        },

        {
            name: 'Time',
            selector: (row) => ((row.leave_type == 'Full Day') ? ' - ' : formatTime(row.from_time) + ' - ' + formatTime(row.to_time)),
            wrap: true,
            sortable: true,
            sortField: "from_time",
        },
        {
            name: 'Leave Count',
            selector: (row) => row,
            cell: (row) => <CustomLeaveCount row={row} />,
            wrap: true,
        },

        {
            name: 'Subject',
            selector: (row) => row.subject,
            wrap: true,
        },
        {
            name: 'Status',
            selector: (row) => (row.status == 1) ? 'Approved' : (row.status == 2) ? 'Rejected' : (row.status == 3) ? 'Cancelled' : 'Pending',
            width: "10%",
            cell: (row) => <LeaveStatus row={row} />,

            /*selector: (row, index) => <ReactSwitch
                onColor={SwitchProps.onColor}
                onHandleColor={SwitchProps.onHandleColor}
                handleDiameter={SwitchProps.handleDiameter}
                uncheckedIcon={SwitchProps.uncheckedIcon}
                checkedIcon={SwitchProps.checkedIcon}
                boxShadow={SwitchProps.boxShadow}
                activeBoxShadow={SwitchProps.activeBoxShadow}
                height={SwitchProps.height}
                width={SwitchProps.width}
                className={SwitchProps.className}
                checked={(row.status == 1) ? true : false}
                onChange={() => { handleChangeStatus(index, row.status, row.id) }}
        />,*/
        },
        {
            name: 'Approved/Rejected By',
            selector: (row) => (row.e_leave.status == 1) ? 'Approved' : (row.e_leave.status == 2) ? 'Rejected' : (row.e_leave.status == 3) ? 'Cancelled' : 'Pending',
            cell: (row) => <LeaveStatusChangedBy row={row} />,
            width: "10%",

        },
        {
            name: "Action",
            cell: (row) => <Leave row={row} />,
            sortable: true,
            sortField: "leave",
            wrap: true,

        },

    ]

    const Leave = ({ row }) => (
        <>
            <Link to={'#'} data-id={row.id} onClick={() => handleLeaveShow(row)}>
                <img src={LeaveImg} alt='View Leave Details' title='View Leave Details' width={20} className='cursor' />
            </Link>


            {(() => {
                if (row.status != 3) {
                    return (
                        <>
                            <Link to={'#'} data-id={row.id} onClick={() => handleCancelLeave(row)}>
                                <img src={CancelLeaveImg} title='Cancel Leave' width={20} className='cursor cancel-leave' />
                            </Link>
                        </>
                    )
                }
            })()}
        </>
    )

    const handleChangeStatus = (index, val, leaveID) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to change the status?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, change it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);
                axios.post(CONST.UPDATE_LEAVE_STATUS,
                    {
                        'id': userId,
                        'leave_id': leaveID,
                        'status': (val == '0') ? '1' : '0',
                    },
                    {
                        headers: {
                            'Authorization': `Basic ${isToken}`
                        }
                    }
                )
                    .then((response) => {
                        console.log(response);
                        const status = response.data.status;
                        if (status == 'success') {
                            toast.success(response.data.message);
                        } else {
                            toast.error(response.data.message);
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        toast.error("There was an error.");
                    });
                setLoading(false);

                const newData = [...data];
                newData[index].status = (val == 0) ? 1 : 0;
                setData(newData);
            }
        })

    }

    {/*const Action = ({ row }) => (
        <>
            <Link to={'#'} data-id={row.id} onClick={() => handleShow('Edit', row.id)}>
                <img src={editImg} alt='editUser' width={32} className='cursor' />
            </Link>
            <Link onClick=''>
                <img src={deleteImg} alt='deleteImg' width={32} className='cursor' />
            </Link>


        </>
    )*/ }



    const searchMonthChange = val => {

        if (val != '') {
            setSrchMonth(moment(val).format('MM'))
        } else {
            setSrchMonth('')
        }
    }

    const searchYearChange = val => {
        if (val != '') {
            setSrchYear(moment(val).format('YYYY'))
        } else {
            setSrchYear('')
        }

    }

    async function searchLeaves() {
        setSearchParam({
            "srchName": srchName,
            "srchStatus": srchStatus,
            "srchLeaveDate": (srchLeaveDate != '') ? moment(srchLeaveDate).format('DD-MM-YYYY') : '',
            //"srchLeaveStartDate": (srchLeaveStartDate != '') ?moment(srchLeaveStartDate).format('DD-MM-YYYY'): '',
            //"srchLeaveEndDate": (srchLeaveEndDate != '') ?moment(srchLeaveEndDate).format('DD-MM-YYYY'): '',
            "srchLeaveType": srchLeaveType
        })
        setDataSort({
            order: "desc",
            order_by: "id"
        });
        setActivePage("1");

    }

    function clearSearch() {
        setSearchParam({
            "srchName": '',
            "srchStatus": '',
            "srchLeaveDate": '',
            //"srchLeaveStartDate":'',
            //"srchLeaveEndDate":'',
            "srchLeaveType": '',
        })
        setDataSort({
            order: "desc",
            order_by: "id"
        });
        setActivePage("1");
        setSrchMonth('');
        setSrchYear(moment().format('YYYY'));

    }


    // async function getDeptByID(dept_id) {

    //     await axios
    //         .get(CONST.GET_DEPARTMENT_BY_ID + '/' + dept_id,
    //             {
    //             headers: {
    //                 'Authorization': `Bearer ${isToken}`,
    //                 'Content-Type': 'application/x-www-form-urlencoded',
    //             },
    //             params: { 
    //                 //'dept_id': dept_id 
    //             },

    //         },

    //         )

    //         .then((response) => {
    //             const status = response.data.status;

    //             console.log(response.data.data[0]);
    //             if (status === 'success') {
    //                 let deptData = response.data.data[0] ? response.data.data[0] : [];
    //                 setDepartmentName( deptData['name'] ? deptData['name'] : '' );
    //                 setStatus( deptData['status'] ? deptData['status'] : '' );
    //                 setEmail( deptData['email'] ? deptData['email'] : '' );
    //             }
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         });
    // }

    async function fetchLeavesData(paramSearch, paramSort, paramPage, paramLimit) {
        setLoading(true);

        let param = {
            'id': userId,
            'limit': paramLimit,
            'search_input': paramSearch,
            'page_number': paramPage,
            'sort': paramSort
        }
        console.log('leave param: ' + JSON.stringify(param));
        //alert('srchLeaveDate'+srchLeaveDate);
        await axios
            .post(CONST.GET_USER_LEAVES,
                {
                    // "department_id":deptId,
                    "id": userId,
                    "limit": paramLimit,
                    "year": srchYear,
                    "month": srchMonth,
                    "page_number": paramPage,
                    "search_input": paramSearch,
                    "sort": paramSort
                },
                {
                    headers: {
                        'Authorization': `Bearer ${isToken}`
                    },

                })

            .then((response) => {
                console.log('Leaves Res- ' + JSON.stringify(response));
                const status = response.data.status;

                if (status === 'success') {
                    setLeaveLisiting(response.data.data);
                    setTotalRows(response.data.count);

                    settotalRecords(response.data.count);
                    setData(response.data.data);
                    const userListJson = response.data.data;
                    const transformedData = userListJson.map(data => ({
                    ...data,
                    first_name: `${data.user.first_name} ${data.user.last_name}`,
                    leave_type: `${data.leave_type} ${data.e_leave_type.name}`,
                    leave_date:`${moment(data.leave_from_date).format('DD-MM-YYYY') + ((data.leave_type == 'Full Day') ? ' to ' + moment(data.leave_to_date).format('DD-MM-YYYY') : '')} ${((data.leave_type == 'Full Day') ? data.no_of_days + ' Day(s)' : '')}`,
                    time:`${(data.leave_type == 'Full Day') ? ' - ' : formatTime(data.from_time) + ' - ' + formatTime(data.to_time)}`,
                    leave_count:`${'Total:'+''+data.totalLeaveCount} ${'Used:'+''+data.consumedLeaveCount} ${'Balanced:'+''+data.balanceLeaveCount}`,
                    status:`${(data.status == 0) ? 'pendding' :(data.status == 1) ? 'Approved' : (data.status == 2) ? 'Rejected' : 'Cancelled'}`,
                    }));
                    // console.log('myfinaldata', transformedData)
                    setfilterUsers(transformedData)
                } else {
                    setLeaveLisiting('');
                    setTotalRows(0);
                    settotalRecords(0);
                    setData('');
                    toast.error(response.data.message);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        setLoading(false);
    }


    const handlePerRowsChange = async (perPage, page) => {
        console.log('handlePerRowsChange');
        setLoading(true);
        const response = await axios.get(
            `https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`
        );
        setData(response.data);
        setPerPage(perPage);
        setLoading(false);
    };

    function toPages(pages) {
        const results = [];
        for (let i = 1; i <= pages; i++) {
            results.push(i);
        }
        return results;
    }

    function getNumberOfPages(rowCount, rowsPerPage) {
        return Math.ceil(rowCount / rowsPerPage);
    }

    const handleSort = async (column, sortDirection) => {
        setActivePage(1);
        setSearchParam({
            "srchName": srchName,
            "srchStatus": srchStatus,
            "srchLeaveDate": (srchLeaveDate != '') ? moment(srchLeaveDate).format('DD-MM-YYYY') : '',
            "srchLeaveType": srchLeaveType
        })

        setDataSort({
            order: sortDirection,
            order_by: column.sortField
        })
    };



    /*  PAGINATION FUNCTIONS  */
    const handlePageChange = async (page) => {
        console.log(page);
        setActivePage(page)
    };

    const BootyPagination = ({
        rowsPerPage,
        rowCount,
        onChangePage,
        onChangeRowsPerPage, // available but not used here
        currentPage,
    }) => {

        const handleBackButtonClick = async () => {
            if ((currentPage - 1) % perPage === 0) {
                setMaxPageLimit(maxPageLimit - perPage);
                setMinPageLimit(minPageLimit - perPage);
            }
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = async () => {
            if (currentPage + 1 > maxPageLimit) {
                setMaxPageLimit(maxPageLimit + perPage);
                setMinPageLimit(minPageLimit + perPage);
            }

            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        let paginationText = "Page:  " + currentPage + ", Showing ";

        paginationText =
            paginationText +
            ((currentPage - 1) * perPage + 1) +
            " to " +
            perPage * currentPage;
        paginationText = paginationText + " of " + rowCount + " entries;";
        const pages = getNumberOfPages(rowCount, rowsPerPage);
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === pageItems.length;
        const previosDisabled = currentPage === 1;

        const pageNumbers = pageItems.map((page) => {
            if (page <= maxPageLimit && page > minPageLimit) {
                return (
                    <li
                        key={page}
                        id={page}
                        className={`page-item ${currentPage === page ? "active" : ""}`}
                    >
                        <button
                            className={`page-link ${currentPage === page ? "active" : ""}`}
                            onClick={handlePageNumber}
                            value={page}
                        >
                            {page}
                        </button>
                    </li>
                );
            } else {
                return null;
            }
        });
        let pageIncrementEllipses = null;
        if (pageItems.length > maxPageLimit) {
            pageIncrementEllipses = (
                <li className="page-item" onClick={handleNextButtonClick}>
                    &hellip;
                </li>
            );
        }
        let pageDecremenEllipses = null;
        if (minPageLimit >= 1) {
            pageDecremenEllipses = (
                <li className="page-item" onClick={handleBackButtonClick}>
                    &hellip;
                </li>
            );
        }
        return (
            <div className="card-footer d-flex align-items-center">
                <p className="m-0 text-muted">{paginationText}</p>

                <ul className="pagination m-0 ms-auto">
                    <li className="page-item">
                        <button
                            className="page-link"
                            onClick={handleBackButtonClick}
                            disabled={previosDisabled}
                            aria-disabled={previosDisabled}
                            aria-label="previous page"
                        //pageRangeDisplayed={perPage}
                        >
                            «
                        </button>
                    </li>
                    {pageDecremenEllipses}
                    {pageNumbers}
                    {pageIncrementEllipses}
                    <li className="page-item">
                        <button
                            className="page-link"
                            onClick={handleNextButtonClick}
                            disabled={nextDisabled}
                            aria-disabled={nextDisabled}
                            aria-label="next page"
                        >
                            »
                        </button>
                    </li>
                </ul>
            </div>
        );
    };
    /* END DATATABLE */
    const CustomLoader = () => (
        <div style={{ padding: "24px" }}>
            <DivLoader color="primary" />
        </div>
    );


    const handleShow = (action, leaveId) => {
        let id = leaveId ? leaveId : 0;
        //setLeaveId(id)
        /*if(leaveId > 0){
            getDeptByID(leaveId);
        }*/
        setAction(action)
        setShow(true);
    }

    

    const handleClose = () => {
        setShow(false);
        
        setTo('');
        setCc('');
        setSubject('');
        setLeaveStartDate('');
        setLeaveEndDate('');
    }

    return (
        <>
            <Loading
                loading={fullLoading}
                background="rgba(0, 0, 0, 0.5)"
                loaderColor="#fff"
            />
            
            <div className="page-header d-print-none">
                <div className="container-xl">
                    <div className="row g-2 align-items-center">
                        <div className="col">
                            <div className="page-pretitle">Manage</div>
                            <h2 className="page-title ">Leaves</h2>
                        </div>
                        <div className="col-12 col-md-auto ms-auto d-print-none">

                            <div className="btn-list">
                                
                                <Link
                                    to="#"
                                    className="btn btn-primary"
                                    onClick={() => handleShow('Add', 0)}>

                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="icon icon-tabler icon-tabler-plus"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <line x1="12" y1="5" x2="12" y2="19"></line>
                                        <line x1="5" y1="12" x2="19" y2="12"></line>
                                    </svg>

                                    &nbsp;Apply Leave
                                </Link>

                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-body">
                <div className="container-xl">
                    <div className="row row-cards">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Listing</h3>
                                </div>
                                <div className="card-body border-bottom py-3">
                                    <div className="row">


                                        <div className="col-md-2">
                                            <div className="mb-2">
                                                <label className="form-label required">
                                                    Status
                                                </label>

                                                <select
                                                    className="form-select form-control"
                                                    name="srchStatus"
                                                    id="srchStatus"
                                                    placeholder="Select status"
                                                    value={srchStatus}
                                                    onChange={(e) => setSrchStatus(e.target.value)}
                                                >
                                                    <option value="">Select</option>
                                                    <option value="0">Pending</option>
                                                    <option value="1">Approved</option>
                                                    <option value="2">Rejected</option>
                                                    <option value="3">Cancelled</option>
                                                </select>


                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="mb-2">
                                                <label className="form-label required">
                                                    Leave Type
                                                </label>
                                                <select
                                                    className="form-select form-control"
                                                    name="srchLeaveType"
                                                    id="srchLeaveType"
                                                    placeholder="Select leaveType"
                                                    onChange={(e) => setSrchLeaveType(e.target.value)}
                                                    value={srchLeaveType}
                                                >
                                                    <option value="">Select Leave Type</option>
                                                    <option value="0">Half Day</option>
                                                    <option value="1">Full day</option>

                                                </select>
                                            </div>
                                        </div>
                                        {/*<div className="col-md-2">
                                            <div className="mb-2">
                                                <label className="form-label required">
                                                    Leave Date
                                                </label>                  
                                                <DatePicker
                                                    name="srchLeaveDate"
                                                    value={srchLeaveDate}
                                                    selected={srchLeaveDate}
                                                    dateFormat="DD-MM-YYYY"
                                                    timeFormat=""
                                                    className=""
                                                    onChange={(e) => setSrchLeaveDate(e)}
                                                />
                                            </div>  
                                        </div>*/}

                                        <div className="col-md-2">
                                            <div className="mb-2">
                                                <label className="form-label required">
                                                    Month
                                                </label>
                                                <DatePicker
                                                    name="srchMonth"
                                                    value={srchMonth}
                                                    selected={srchMonth}
                                                    dateFormat="MM"
                                                    timeFormat=""
                                                    className=""
                                                    onChange={searchMonthChange}
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-2">
                                            <div className="mb-2">
                                                <label className="form-label required">
                                                    Year
                                                </label>
                                                <DatePicker
                                                    name="srchYear"
                                                    value={srchYear}
                                                    selected={srchYear}
                                                    dateFormat="YYYY"
                                                    timeFormat=""
                                                    className=""
                                                    onChange={(e) => searchYearChange(e)}
                                                />
                                            </div>
                                        </div>
                                        {/* <div className="col-md-2">
                                            <div className="mb-2">
                                                <label className="form-label required">
                                                    Leave Start Date
                                                </label>                  
                                                <DatePicker
                                                    name="srchLeaveStartDate"
                                                    value={srchLeaveStartDate}
                                                    selected={srchLeaveStartDate}
                                                    dateFormat="DD-MM-YYYY"
                                                    timeFormat=""
                                                    className=""
                                                    onChange={(e) => setSrchLeaveStartDate(e)}
                                                />
                                            </div>  
                                        </div>
                                       <div className="col-md-2">
                                            <div className="mb-2">
                                                <label className="form-label required">
                                                    Leave End Date
                                                </label>                  
                                                <DatePicker
                                                    name="srchLeaveEndDate"
                                                    value={srchLeaveEndDate}
                                                    selected={srchLeaveEndDate}
                                                    dateFormat="DD-MM-YYYY"
                                                    timeFormat=""
                                                    className=""
                                                    onChange={(e) => setSrchLeaveEndDate(e)}
                                                />
                                            </div>  
                                        </div>*/}

                                        <div className="col-md-3">
                                            <div className="form-group mb-3 ">
                                                <label className="form-label">&nbsp;</label>
                                                <div>
                                                    <button
                                                        type="button"
                                                        name="btnSearch"
                                                        id="btnSearch"
                                                        className="btn btn-blue d-none d-sm-inline-block ml-3"
                                                        onClick={searchLeaves}
                                                    ><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <circle cx="10" cy="10" r="7"></circle>
                                                            <line x1="21" y1="21" x2="15" y2="15"></line>
                                                        </svg>Search</button>&nbsp;
                                                    <button
                                                        type="button"
                                                        name="btnReset"
                                                        id="btnReset"
                                                        className="btn btn-outline-primary "
                                                        onClick={clearSearch}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <line x1="18" y1="6" x2="6" y2="18"></line>
                                                            <line x1="6" y1="6" x2="18" y2="18"></line>
                                                        </svg>Clear</button>
                                                        <ExportCsv propsEmpName={filterUsers} propsHeaders={headerData} /> 
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">

                                    <DataTable
                                        columns={columns}
                                        data={leaveLisiting}
                                        progressPending={loading}
                                        progressComponent={<CustomLoader />}
                                        pagination="true"
                                        paginationServer
                                        paginationTotalRows={totalRows}
                                        onChangeRowsPerPage={handlePerRowsChange}
                                        onChangePage={handlePageChange}
                                        paginationPerPage={perPage}
                                        paginationComponent={BootyPagination}
                                        sortServer
                                        onSort={handleSort}
                                    />

                                </div>
                                <div className="card-footer d-flex align-items-center">
                                    <p className="m-0 text-muted">
                                        Showing {/* <span>1</span> to <span>8</span> of{" "} */}
                                        <span>{totalRecords}</span> entries
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal size="lg" show={show} onHide={handleClose}>

                <Loading
                    loading={fullLoading}
                    background="rgba(0, 0, 0, 0.5)"
                    loaderColor="#fff"
                />

                <Modal.Header closeButton>
                    <Modal.Title>{action} Leave</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <form
                        method="POST"
                        // onSubmit={handleSubmit}
                        autoComplete={"off"}
                    >
                        <div className="row">

                            <div className="col-md-6">
                                <div className="form-group mb-3 ">
                                    <label className="form-label required">
                                        To
                                    </label>
                                    <div>



                                        <MultiSelect

                                            onChange={handleOnchangeTo}
                                            options={newHeads} className={`${validate.validate && validate.validate.newHeads
                                                ? "is-invalid "
                                                : ""
                                                }`}
                                        //closeOnSelect={true} 
                                        />


                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group mb-3 ">
                                    <label className="form-label required">
                                        CC
                                    </label>
                                    <div>

                                        <MultiSelect

                                            onChange={handleOnchangeCC}
                                            options={newHeadsCC} className={`${validate.validate && validate.validate.newHeadsCC
                                                ? "is-invalid "
                                                : ""
                                                }`}
                                        //closeOnSelect={true} 
                                        />




                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group mb-3 ">
                                    <label className="form-label required">
                                        Subject
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            name="subject"
                                            id="subject"
                                            className={`form-control ${validate.validate && validate.validate.subject
                                                ? "is-invalid "
                                                : ""
                                                }`}
                                            aria-describedby="emailHelp"
                                            placeholder="Enter subject"
                                            onChange={(e) => setSubject(e.target.value)}
                                            value={subject}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group mb-3 ">
                                    <label className="form-label required">
                                        Reason
                                    </label>
                                    <div>

                                        <JoditEditor
                                            ref={editor}
                                            config={config}
                                            value={reason}
                                            tabIndex={1} // tabIndex of textarea
                                            onBlur={newContent => setReason(newContent)}
                                            onChange={(newContent) => { }}
                                        // className={`form-control ${validate.validate && validate.validate.policyDescription ? "is-invalid": "" }`}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mb-3 ">
                                    <label className="form-label required">
                                        Leave Type
                                    </label>
                                    <div>
                                        <select
                                            className={`form-select form-control ${validate.validate && validate.validate.leaveType
                                                ? "is-invalid "
                                                : ""
                                                }`}
                                            name="leaveType"
                                            id="leaveType"
                                            placeholder="Select leaveType"
                                            onChange={(e) => setLeaveType(e.target.value)}
                                            value={leaveType}
                                        >
                                            <option>Select Leave Type</option>
                                            <option value="0">Half Day</option>
                                            <option value="1">Full day</option>

                                        </select>

                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group mb-3 ">
                                    <label className="form-label required">
                                        Leave Sub Type
                                    </label>
                                    <div>
                                        <select
                                            className={`form-select form-control ${validate.validate && validate.validate.leaveTypeId
                                                ? "is-invalid "
                                                : ""
                                                }`}
                                            name="leaveTypeId"
                                            id="leaveTypeId"
                                            placeholder="Select leaveType"
                                            onChange={(e) => setLeaveTypeId(e.target.value)}
                                            value={leaveTypeId}
                                        >
                                            <option>Select Leave Type</option>

                                            {leaveTypeData.map((l, index) => (

                                                <option key={l.id} value={l.id}>
                                                    {l.name}
                                                </option>
                                            ))}

                                        </select>

                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group mb-3 ">
                                    <label className="form-label required">
                                        Start Date
                                    </label>
                                    <div>
                                        <DatePicker
                                            name="leaveStartDate"
                                            value={leaveStartDate}
                                            selected={leaveStartDate}
                                            dateFormat="DD-MM-YYYY"
                                            timeFormat=""
                                            className={`${validate.validate && validate.validate.leaveStartDate
                                                ? "is-invalid "
                                                : ""
                                                }`}
                                            onChange={(e) => setLeaveStartDate(e)}
                                        />
                                    </div>
                                    <div
                                        className={`invalid-feedback text-start 
                                        ${validate.validate && validate.validate.leaveStartDate
                                                ? "d-block"
                                                : "d-none"
                                            }`}>
                                        {validate.validate && validate.validate.leaveStartDate
                                            ? validate.validate.leaveStartDate
                                            : ""}
                                    </div>
                                    <div
                                        className="invalid-feedback text-start" >
                                    </div>
                                </div>
                            </div>

                            {
                                (leaveType == 1)
                                    ?
                                    (
                                        <div className="col-md-6">
                                            <div className="form-group mb-3 ">
                                                <label className="form-label required">
                                                    End Date
                                                </label>
                                                <div>
                                                    <DatePicker
                                                        name="leaveEndDate"
                                                        value={leaveEndDate}
                                                        selected={leaveEndDate}
                                                        dateFormat="DD-MM-YYYY"
                                                        timeFormat=""
                                                        minDate={moment().toDate()}
                                                        className={`${validate.validate && validate.validate.leaveEndDate
                                                            ? "is-invalid "
                                                            : ""
                                                            }`}
                                                        onChange={(e) => setLeaveEndDate(e)}
                                                    />
                                                </div>
                                                <div
                                                    className={`invalid-feedback text-start 
                                            ${validate.validate && validate.validate.leaveEndDate
                                                            ? "d-block"
                                                            : "d-none"
                                                        }`}>
                                                    {validate.validate && validate.validate.leaveStartDate
                                                        ? validate.validate.leaveEndDate
                                                        : ""}
                                                </div>
                                                <div
                                                    className="invalid-feedback text-start" >
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    :
                                    ''
                            }

                            {
                                (leaveType != 1)
                                    ?
                                    (
                                        <div className="col-md-6">
                                            <div className="form-group mb-3 ">
                                                <label className="form-label required">
                                                    Start Time
                                                </label>
                                                <div>
                                                    <DatePicker
                                                        selected={startTime}
                                                        onChange={(e) => setStartTime(e)}
                                                        showTimeSelect
                                                        minDate={moment().toDate()}
                                                        timeFormat="HH:mm"
                                                        timeIntervals={15}
                                                        dateFormat=""
                                                    />
                                                </div>
                                                <div
                                                    className={`invalid-feedback text-start 
                                                ${validate.validate && validate.validate.startTime
                                                            ? "d-block"
                                                            : "d-none"
                                                        }`}>
                                                    {validate.validate && validate.validate.startTime
                                                        ? validate.validate.startTime
                                                        : ""}
                                                </div>
                                                <div
                                                    className="invalid-feedback text-start" >
                                                </div>
                                            </div>
                                        </div>

                                    )
                                    :
                                    ''
                            }

                            {
                                (leaveType != 1)
                                    ?
                                    (
                                        <div className="col-md-6">
                                            <div className="form-group mb-3 ">
                                                <label className="form-label required">
                                                    End Time
                                                </label>
                                                <div>
                                                    <DatePicker
                                                        selected={endTime}
                                                        onChange={(e) => setEndTime(e)}
                                                        showTimeSelect
                                                        timeFormat="HH:mm"
                                                        timeIntervals={15}
                                                        dateFormat=""
                                                    />
                                                </div>
                                                <div
                                                    className={`invalid-feedback text-start 
                                                ${validate.validate && validate.validate.endTime
                                                            ? "d-block"
                                                            : "d-none"
                                                        }`}>
                                                    {validate.validate && validate.validate.endTime
                                                        ? validate.validate.endTime
                                                        : ""}
                                                </div>
                                                <div
                                                    className="invalid-feedback text-start" >
                                                </div>
                                            </div>
                                        </div>

                                    )
                                    :
                                    ''
                            }

                            <div className="col-md-12">
                                <div className="form-footer">

                                    <button
                                        type="button"
                                        className="mr-3 btn btn-primary"
                                        onClick={handleSubmit}
                                        disabled={disabled}
                                    >Submit</button>


                                    &nbsp;&nbsp;

                                </div>
                            </div>
                        </div>


                    </form>
                </Modal.Body>
            </Modal>

           
            {
                (showLeaveModel.show == 'show' && <ViewLeaveDetails closeLeave={closeLeave} editLeaveId={editLeaveId} viewData={viewData} />)
            }
        </>
    )
}

export default List