import { React, useState, useContext, useEffect, useRef } from 'react'
import Loading from "react-fullscreen-loading";
import HeaderSection from "../banner/HeaderSection";
import * as  CONST from "../../../component/config/Constant"
import Form from "../../../component/utilities/Forms";
import "../../../assets/css/form.css";
import axios from 'axios';
import { AuthContext } from '../../../component/context/AuthContext';
import { toast } from "react-toastify";
import DivSpinner from '../../../component/utilities/Spinner';
import DatePicker from 'react-datetime';
import moment from 'moment';
import 'react-datetime/css/react-datetime.css';
import ImageCropperPopup from '../cropper/ImageCropperPopup';
import deleteImg from "../../../assets/img/icon/delete.svg";
import Swal from 'sweetalert2';
import ReadOnlyRow from '../../../component/guardian/ReadOnlyRow';
import EditableRow from '../../../component/guardian/EditableRow';
import "./table.css";


function PersonalInformation() {

	const [authUserData, setAuthUserData] = useContext(AuthContext);
	const [validate, setValidate] = useState({});
	const [firstName, setFirstName] = useState("");
	const [middleName, setMiddleName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [personalEmail, setPersonalEmail] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [address, setAddress] = useState("");
	const [aadharAddress, setAadharAddress] = useState("");
	const [aadharNumber, setAadharNumber] = useState("");
	const [panNumber, setPanNumber] = useState("");
	const [uanNumber, setUanNumber] = useState("");
	const [esicNumber, setEsicNumber] = useState("");
	const [loading, setLoading] = useState(false);
	const [dptListing, setDptListing] = useState("");
	const [desigListing, setDesigListing] = useState("");

	const [isImageCropperOpen, setImageCropperOpen] = useState(false);
	const [croppedImage, setCroppedImage] = useState(null);


	// For required field in API
	const [status, setStatus] = useState("");
	const [department, setDepartment] = useState("");
	const [designation, setDesignation] = useState("");
	const [profile, setProfile] = useState(null);
	const [profileInput, setProfileInput] = useState([]);
	const [profilePath, setProfilePath] = useState("");
	const [dateOfBirth, setDateOfBirth] = useState();
	const [dateOfJoining, setDateOfJoining] = useState();
    const [teamdepartmentid, setTeamDepartmentId] = useState("");
	const [teamdepartmentList, setTeamDepartmentList] = useState("");

	/* Social Information */
	const [LinkedIn, setLinkedInUrl] = useState('');
	const [Facebook, setFacebookUrl] = useState('');
	const [socialDetails, setSocialDetails] = useState([{
		social_name: '',
		social_link: '',
	}]);

	let isToken = '';
	let userId = '';
	let is_head = 0;
	let is_admin = 0;
	let role_id = '';
	let department_id = '';
	let department_name = '';
	if (authUserData) {
		if (authUserData[0]) {
			isToken = authUserData[0].token ? authUserData[0].token : "";
			userId = authUserData[0].user_id ? authUserData[0].user_id : 0;
			department_id = authUserData[0].department_id ? authUserData[0].department_id : "";
			department_name = authUserData[0].department ? authUserData[0].department.name : "";
			is_head = authUserData[0].is_head ? authUserData[0].is_head : 0;
			is_admin = authUserData[0].is_admin ? authUserData[0].is_admin : 0;
			role_id = authUserData[0].role_id ? authUserData[0].role_id : "";
		}
	}
  

	useEffect(() => {
		fetchUserData();
		fetchDeptList();
		fetchDesigtList();
		fetchSocialData();
		fetchGuardianData();
		fetchTeamDepartment();
	}, []);

	async function fetchDeptList() {
		setLoading(true);
		await axios
			.get(CONST.GET_DEPARTMENT, {
				headers: {
					'Authorization': `Basic ${isToken}`
				}
			})
			.then((response) => {
				let status = response.data.status;
				//console.log(response)
				if (status == 'success') {
					setDptListing(response.data.data);
				}
			})
			.catch((error) => {
				toast.error(error);
			});
		setLoading(false);
	}

	const fetchDesigtList = async (desig_id) => {
		setLoading(true);
		setDepartment(desig_id);
		await axios
			.get(CONST.GET_DESIG_BY_ID + '/' + desig_id, {
				headers: {
					'Authorization': `Basic ${isToken}`
				}
			})
			.then((response) => {
				let status = response.data.status;

				if (status == 'success') {
					setDesigListing(response.data.data);
				}
			})
			.catch((error) => {
				toast.error(error);
			});
		setLoading(false);
	}
	async function fetchUserData() {
		setLoading(true);
		await axios
			.post(CONST.GET_EMP_BY_ID,
				{
					'id': userId,
					'user_id': userId
				},
				{
					headers: {
						'Authorization': `Bearer ${isToken}`
					},


				})

			.then((response) => {
				const status = response.data.status;

				if (status === 'success') {


					let usertData = response.data.data ? response.data.data : [];

					/* const found = usertData.euser_has_sociallinks.find(obj => {
						return obj.social_name === 'LinkedIn';
					});

					if (found) {
						setLinkedInUrl(found.social_link);
					} else {
						setLinkedInUrl('');
					}

					const foundFacebook = usertData.euser_has_sociallinks.find(obj => {
						return obj.social_name === 'Facebook';
					});

					if (foundFacebook) {
						setFacebookUrl(foundFacebook.social_link);
					} else {
						setFacebookUrl('');
					} */

					setFirstName(usertData['first_name'] ? usertData['first_name'] : '');
					setMiddleName(usertData['middle_name'] ? usertData['middle_name'] : '');
					setLastName(usertData['last_name'] ? usertData['last_name'] : '');
					setEmail(usertData['email'] ? usertData['email'] : '');
					setPersonalEmail(usertData['personal_email'] ? usertData['personal_email'] : '');
					setPhoneNumber(usertData['phone_number'] ? usertData['phone_number'] : '');
					setAddress(usertData['user_address'] ? usertData['user_address'] : '');
					setAadharAddress(usertData['user_aadhar_address'] ? usertData['user_aadhar_address'] : '');
					setAadharNumber(usertData['aadhar_number'] ? usertData['aadhar_number'] : '');
					setPanNumber(usertData['pan_number'] ? usertData['pan_number'] : '');
					setUanNumber(usertData['uan_number'] ? usertData['uan_number'] : '');
					setEsicNumber(usertData['esic_number'] ? usertData['esic_number'] : '');
					//setDateOfBirth(usertData['date_of_birth'] ? usertData['date_of_birth'] : '');
					setDateOfBirth(usertData['date_of_birth'] && usertData['date_of_birth'] ? moment(usertData['date_of_birth']).format('DD-MM-YYYY') : "");
					setDateOfJoining(usertData['date_of_joining'] && usertData['date_of_joining'] ? moment(usertData['date_of_joining']).format('DD-MM-YYYY') : "");
					setStatus(usertData['status'] ? usertData['status'] : '');
					setDepartment(usertData['department_id'] ? usertData['department_id'] : '');
					fetchDesigtList(usertData['department_id'] ? usertData['department_id'] : '0');
					setDesignation(usertData['designation'].id ? usertData['designation'].id : '0');
					setProfilePath(usertData['profile_photo_path'] ? usertData['profile_photo_path'] : '');
					setTeamDepartmentId(usertData['department_team_id'] ? usertData['department_team_id'] : '')
				} else {
					toast.error(response.data.message);
				}
			})
			.catch((error) => { //alert('error')
				console.log(error);
			});
		setLoading(false);
	}
	const fetchTeamDepartment = async () => {
		await axios
			.get(CONST.GET_DEPARTMENT_TEAM_LIST, {
				headers: {
					'Authorization': `Basic ${isToken}`
				}
			})
			.then((response) => {
				const status = response.data.status;
				if (status === 'success') {
					setTeamDepartmentList(response.data.data);
					// setTeamDepartmentList(departmentTeamList);
				}
			})
			.catch((error) => {
				alert(error);
			});
	}
	const validateEmployee = async () => {
		let isValid = true;

		let validator = Form.validator({
			firstName: {
				value: firstName,
				isRequired: true,
				isEmail: false,
				label: "first name",
			},
			email: {
				value: email,
				isRequired: true,
				isEmail: true,
				label: "email",
			},
			personalEmail: {
				value: personalEmail,
				isRequired: true,
				isEmail: true,
				label: "email",
			},
			phoneNumber: {
				value: phoneNumber,
				isRequired: true,
				isEmail: false,
				minLength: '10',
				isNumber: true,
				label: "phone number",
			},

			address: {
				value: address,
				isRequired: true,
				isEmail: false,
				label: "address",
			},
			aadharAddress: {
				value: aadharAddress,
				isRequired: true,
				isEmail: false,
				label: "aadhar address",
			},
			aadharNumber: {
				value: aadharNumber,
				isRequired: true,
				isEmail: false,
				label: "aadhar number",
			},
			panNumber: {
				value: panNumber,
				isRequired: true,
				isEmail: false,
				label: "pan number",
			},

			department: {
				value: department,
				isRequired: true,
				isEmail: false,
				label: "department",
			},

			designation: {
				value: designation,
				isRequired: true,
				isEmail: false,
				label: "designation",
			},
			dateOfBirth: {
				value: dateOfBirth,
				isRequired: true,
				isEmail: false,
				label: "date of birth",
			},
			dateOfJoining: {
				value: dateOfJoining,
				isRequired: true,
				isEmail: false,
				label: "date of joining",
			},


		});

		if (validator !== null) {
			setValidate({
				validate: validator.errors,
			});
			isValid = false;
		} else {
			setValidate({
				validate: "",
			});
			isValid = true;
		}
		return isValid;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const validate = await validateEmployee();


		if (validate) {
			// console.log('Profile >> ');
			//alert('Profile photo : '+ JSON.stringify(profile));
			let date_of_birthday = moment(dateOfBirth, 'DD-MM-YYYY').format('YYYY-MM-DD');
			let date_ofjoingday = moment(dateOfJoining, 'DD-MM-YYYY').format('YYYY-MM-DD');
			setLoading(false);
			const formData = new FormData();
			formData.append("first_name", firstName);
			formData.append("last_name", lastName);
			formData.append("middle_name", middleName);
			formData.append("email", email);
			formData.append("personal_email", personalEmail);
			formData.append("address", address);
			formData.append("user_aadhar_address", aadharAddress);
			formData.append("aadhar_number", aadharNumber);
			formData.append("pan_number", panNumber);
			formData.append("uan_number", uanNumber);
			formData.append("esic_number", esicNumber);
			formData.append("phone_number", phoneNumber);
			formData.append("status", status);
			formData.append("department_team_id", department == 1 ? teamdepartmentid : '');
			formData.append("department_id", department);
			formData.append("designation_id", designation);
			formData.append("date_of_birth", date_of_birthday);
			formData.append("date_of_joining", date_ofjoingday);
			formData.append('profile_photo', profile);

			//Social 
			//formData.append('linkedin_url', LinkedIn);

			axios.defaults.maxContentLength = Infinity;
			axios.defaults.maxBodyLength = Infinity;
			await axios({
				method: 'put',
				url: CONST.UPDATE_PERSONAL_INFO,
				data: formData,
				headers: {
					"Authorization": `Bearer ${isToken}`,
					"content-type": "multipart/form-data",
				}

			})
				.then((response) => {
					console.log('Personal info >>' + JSON.stringify(response));
					const status = response.data.status;
					//alert(status);
					setLoading(false);
					if (status == 'error') {
						/* 	toast.error("Something went wrong. Please try again.");			 */
						const errorMsg = response.data.errors;
						let isArray = Array.isArray(errorMsg);
						if (isArray) {
							errorMsg.map(element => {
								toast.error(element.msg);
							})
						}
						else {
							toast.error(response.data.message);
						}
						console.log(errorMsg);
					} else {

						toast.success(response.data.message);


						const access_token = response.data.data.access_token
							? response.data.data.access_token
							: "";
						if (access_token) {
							localStorage.setItem("eToken", access_token);
						}

						fetchUserData();

						const UserData = [
							{
								first_name: firstName,
								middle_name: middleName,
								last_name: lastName,
								email: email,
								personal_email: personalEmail,
								status: status,
								token: isToken,
								user_id: userId,
								profile_photo_path: profilePath,
								role_id: role_id,
								department_id: department_id,
								department_name: department_name,
								is_head: is_head,
								is_admin: is_admin

							},
						];
						setAuthUserData(UserData);
					}
				})
				.catch((error) => {
					setLoading(false);
					toast.error("Something went wrong. Please try again.");
				});
		}
	}

	const handleOpenCropper = () => {
		setImageCropperOpen(true);
	};

	const handleCloseCropper = () => {
		setImageCropperOpen(false);
	};

	const handleSaveCroppedImage = async (croppedImageData, fName, fType) => {
		setCroppedImage(croppedImageData);
		const base64String = croppedImageData; // Replace with your Base64 string
		const fileName = fName; // Replace with the desired file name
		const mimeType = fType; // Replace with the appropriate MIME type
		const file = await base64ToFile(base64String, fileName, mimeType);
		setProfile(file)
	};

	async function base64ToFile(base64String, fileName, mimeType) {
		// Remove the data URI prefix
		const base64Data = base64String.replace(/^data:[^;]+;base64,/, '');

		// Convert the Base64 string to a Uint8Array
		const binaryData = atob(base64Data);
		const arrayBuffer = new ArrayBuffer(binaryData.length);
		const uint8Array = new Uint8Array(arrayBuffer);
		for (let i = 0; i < binaryData.length; i++) {
			uint8Array[i] = binaryData.charCodeAt(i);
		}

		// Create a Blob from the Uint8Array
		const blob = new Blob([uint8Array], { type: mimeType });

		// Create a File object from the Blob
		const file = new File([blob], fileName, { type: mimeType });

		return file;
	}
	//------------------------


	/*  SOCIAL INFORMATION */

	/* Social Infotmation */
	async function fetchSocialData() {
		setLoading(true);
		//GET SOCIAL DATA
		await axios
			.get(CONST.GET_SOCIAL_DATA,
				{
					headers: {
						'Authorization': `Bearer ${isToken}`
					},

				})

			.then((response) => {

				const status = response.data.status;

				if (status === 'success') {

					let newData = response.data.data;
					console.log(newData);
					/* if (newData.length > 0) {
						setSocialDetails(newData)
					} else {
						setSocialDetails([{
							social_name: '',
							social_link: '',
						}]);

					} */
					if (newData.length > 0) {
						console.log(newData);
						const LinkedInFound = newData.find(obj => {
							return obj.social_name === 'LinkedIn';
						});
						if (LinkedInFound) {
							setLinkedInUrl(LinkedInFound.social_link);
						} else {
							setLinkedInUrl('');
						}
						const foundFacebook = newData.find(obj => {
							return obj.social_name === 'Facebook';
						});

						if (foundFacebook) {
							setFacebookUrl(foundFacebook.social_link);
						} else {
							setFacebookUrl('');
						}

					}

				} else {
					toast.error(response.data.message);
				}
			})
			.catch((error) => {
				console.log(error);
			});
		setLoading(false);
	}
	const handleSocialSubmit = async (e) => {
		e.preventDefault();
		/* socialDetails */
		console.log('socialDetails');
		console.log(socialDetails);
		let empSocialData = [
			{
				social_name: 'LinkedIn',
				social_link: LinkedIn,
			},
			{
				social_name: 'Facebook',
				social_link: Facebook,
			}
		]
		console.log(empSocialData);
		setLoading(true);
		axios
			.post(CONST.ADD_SOCIAL,
				{
					"sociallink": empSocialData,
					"euser_id": userId
				},
				{
					headers: {
						'Authorization': `Bearer ${isToken}`,
						'Content-Type': 'application/json'
					}
				})
			.then((response) => {
				if (response) {
					console.log(response);
					if (response.data.status == 'error') {
						toast.error(response.data.message);
					} else {
						toast.success(response.data.message);
						fetchSocialData()
					}
				}
			})
			.catch((error) => {
				setLoading(false);
				console.log(error);
				toast.error("Something went wrong. Please try again." + error);
			});
		setLoading(false);
		return false;
	}
	/* Guardian Information 	 */
	const [gaurdFormValues, setGaurdFormValues] = useState([{ relation: '', gName: '', email: '', gPhoneNumber: '', gAddress: '' }]);

	const [guardianDetails, setGuardianDetails] = useState([{ relation: '', gName: '', email: '', gPhoneNumber: '', gAddress: '' }]);

	const [editGuardianId, seteditGuardianId] = useState(null);

	const [editGaurdFormData, setEditGaurdFormData] = useState({ relation: '', gName: '', email: '', gPhoneNumber: '', gAddress: '' });

	let regex =
		/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
	let isNumber = /^(?!0+$)[0-9]{10}$/;
	let isAlphabetic = /^[a-zA-Z\s]+$/;

	const handleGaurdCancelClick = (event, guardian) => {
		seteditGuardianId(null);
	}

	const handleGaurdEditFormSubmit = (event, guardian) => {
		event.preventDefault();
		const index = guardianDetails.findIndex((guardian) => guardian.id === editGuardianId);
		const isEditDataValid = validateGaurdEditFields(editGaurdFormData, index);

		if (isEditDataValid) {
			axios.put(CONST.EDIT_GUARDIAN_DETAILS,
				{
					'id': editGuardianId,
					'relation_name': editGaurdFormData.relation,
					'name': editGaurdFormData.gName,
					'email': editGaurdFormData.email,
					'phone': editGaurdFormData.gPhoneNumber,
					'address': editGaurdFormData.gAddress

				}, {
				headers: {
					'Authorization': `Basic ${isToken}`,
				}
			}
			)
				.then((response) => {
					const status = response.data.status;
					setLoading(false);

					if (status === 'success') {
						toast.success(response.data.message);
						fetchGuardianData();
						seteditGuardianId(null);
					} else {
						toast.error(response.data.message);
					}
				})
				.catch((error) => {
					setLoading(false);
					toast.error("Something went wrong. Please try again.");
				});
		}
	}

	const validateGaurdEditFields = (editGaurdFormData, index) => {
		const newFormData = [...guardianDetails];
		let editData = editGaurdFormData;
		if (editData.relation === '') {
			editData.editRelationError = "Guardian relation required";
		}
		else if (!isAlphabetic.test(editData.relation)) {
			editData.editRelationError = "Guardian relation is not valid";
		}
		else {
			editData.editRelationError = "";
		}

		if (editData.gName === '') {
			editData.editGNameError = "Guardian Name required";
		}
		else if (!isAlphabetic.test(editData.gName)) {
			editData.editGNameError = "Name is not valid";
		}
		else {
			editData.editGNameError = "";
		}

		if (editData.email !== '') {
			if (!regex.test(editData.email)) {
				editData.editEmailError = "Email is not valid";
			}
			else {
				editData.editEmailError = "";
			}
		}
		else {
			editData.emailError = "";
		}

		if (editData.gPhoneNumber === '') {
			editData.editGPhoneNumberError = "Phone number required";
		}
		else if (!isNumber.test(editData.gPhoneNumber)) {
			editData.editGPhoneNumberError = "Phone number is not valid";
		}
		else {
			editData.editGPhoneNumberError = "";
		}

		if (editData.gAddress === '') {
			editData.editGAddressError = "Address required";
		}
		else {
			editData.editGAddressError = "";
		}

		setEditGaurdFormData(editData);
		newFormData[index] = editData;
		setGuardianDetails(newFormData);
		if (editData.editRelationError == "" &&
			editData.editGNameError == "" &&
			editData.editEmailError == "" &&
			editData.editGPhoneNumberError == "" &&
			editData.editGAddressError == "") {
			return true;
		} else {
			return false;
		}
	}

	const handleGaurdEditClick = (event, guardian) => {
		event.preventDefault();
		seteditGuardianId(guardian.id);
		const gaurdFormValuesues = {
			'id': guardian.id,
			'relation': guardian.relation,
			'gName': guardian.gName,
			'email': guardian.email,
			'gPhoneNumber': guardian.gPhoneNumber,
			'gAddress': guardian.gAddress
		}
		setEditGaurdFormData(gaurdFormValuesues);
	}


	const handleGaurdEditFormChange = (event) => {
		event.preventDefault();
		const fieldName = event.target.getAttribute("name");
		const fieldValue = event.target.value;

		const newFormData = { ...editGaurdFormData };
		newFormData[fieldName] = fieldValue;

		setEditGaurdFormData(newFormData);
	}

	function handleGaurdDelete(id) {
		Swal.fire({
			title: 'Are you sure?',
			text: "Do you want to delete this details?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then((result) => {
			if (result.isConfirmed) {
				//setLoading(true);
				const newFormData = [...guardianDetails];
				const index = guardianDetails.findIndex((guardian) => guardian.id === editGuardianId);
				if (id > 0) {
					axios
						.delete(CONST.DELETE_GUARDIAN_DETAILS, {
							headers: {
								'Authorization': `Bearer ${isToken}`
							},
							data: {
								guard_id: id
							}
						})
						.then((response) => {

							if (response) {
								if (response.data.status == 'error') {
									toast.error(response.data.message);
								} else {
									toast.success(response.data.message);
									//newFormData.splice(index,1);
									//setGuardianDetails(newFormData);
									//handleClose();
									fetchGuardianData()
								}
							}
						})
						.catch((error) => {
							setLoading(false);
							toast.error("Something went wrong. Please try again." + error);
						});
					setLoading(false);
				}
			}
		})
	}

	//fetch all guardian details
	async function fetchGuardianData() {
		setLoading(true);
		await axios
			.get(CONST.GET_GUARDIAN_DETAILS, {
				headers: {
					'Authorization': `Bearer ${isToken}`
				}
			})

			.then((response) => {
				const status = response.data.status;
				if (status === 'success') {
					const responseData = response.data.data;
					var guardian = [];
					let isArray = Array.isArray(responseData);
					if (isArray) {
						responseData.map((g, index) => {
							guardian.push({
								'id': g.id,
								'relation': g.relation_name,
								'gName': g.guardian_name,
								'email': g.guardian_email,
								'gPhoneNumber': g.guardian_phone,
								'gAddress': g.guardian_address
							});

						})
					}
					setGuardianDetails(guardian);
				}
				else {
					toast.error(response.data.message);
				}
			})
			.catch((error) => {
				console.log(error);
			});
		setLoading(false);
	}

	const addGaurdFieldsRow = () => {
		setGaurdFormValues([...gaurdFormValues, { relation: '', gName: '', email: '', gPhoneNumber: '', gAddress: '' }])
	}

	function handleGaurdOnRemove(i, id) {
		const newFormData = [...gaurdFormValues];
		newFormData.splice(i, 1);
		setGaurdFormValues(newFormData);
	}

	const handleGaurdOnHandle = (e, i) => {
		let newForm = [...gaurdFormValues];
		newForm[i][e.target.name] = e.target.value;
		setGaurdFormValues(newForm);
	}

	const validateGuardianInfo = (formInputValues) => {
		let data = [...formInputValues];
		let valid = true;

		for (let index = 0; index < data.length; index++) {
			if (data[index].relation === '') {
				data[index].relationError = "Guardian relation required";
				valid = false;
			}
			else if (!isAlphabetic.test(data[index].relation)) {
				data[index].relationError = "Guardian relation is not valid";
				valid = false;
			}
			else {
				data[index].relationError = "";
				valid = true;
			}

			if (data[index].gName === '') {
				data[index].gNameError = "Guardian Name required";
				valid = false;
			}
			else if (!isAlphabetic.test(data[index].gName)) {
				data[index].gNameError = "Name is not valid";
				valid = false;
			}
			else {
				data[index].gNameError = "";
				valid = true;
			}

			if (data[index].email !== '') {
				if (!regex.test(data[index].email)) {
					data[index].emailError = "Email is not valid";
					valid = false;
				}
				else {
					data[index].emailError = "";
					valid = true;
				}
			}
			else {
				data[index].emailError = "";
				valid = true;
			}

			if (data[index].gPhoneNumber === '') {
				data[index].gPhoneNumberError = "Phone number required";
				valid = false;
			}
			else if (!isNumber.test(data[index].gPhoneNumber)) {
				data[index].gPhoneNumberError = "Phone number is not valid";
				valid = false;
			}
			else {
				data[index].gPhoneNumberError = "";
				valid = true;
			}

			if (data[index].gAddress === '') {
				data[index].gAddressError = "Address required";
				valid = false;
			}
			else {
				data[index].gAddressError = "";
				valid = true;
			}
		}
		setGaurdFormValues(data);
		return valid;
	};

	const handleGaurdFormSubmit = async (e) => {
		e.preventDefault();
		var guardian = [];
		const isValid = validateGuardianInfo(gaurdFormValues);
		if (isValid) {
			setLoading(false);
			for (let i = 0; i < gaurdFormValues.length; i++) {
				guardian.push({
					'relation_name': gaurdFormValues[i].relation,
					'name': gaurdFormValues[i].gName,
					'email': gaurdFormValues[i].email,
					'phone': gaurdFormValues[i].gPhoneNumber,
					'address': gaurdFormValues[i].gAddress
				});
			}

			await axios
				.post(CONST.ADD_GUARDIAN_DETAILS, JSON.stringify({ guardian }), {
					headers: {
						'Authorization': `Basic ${isToken}`,
						'Content-Type': 'application/json'
					}
				})
				.then((response) => {
					const status = response.data.status;
					setLoading(false);

					if (status === 'success') {
						toast.success(response.data.message);
						fetchGuardianData();
						setGaurdFormValues([{ relation: '', gName: '', email: '', gPhoneNumber: '', gAddress: '' }]);
					} else {
						toast.error(response.data.message);
					}

				})
				.catch((error) => {
					setLoading(false);
					toast.error("Something went wrong. Please try again.");
				});
		}
	}

	return (
		<>
			<Loading
				loading={loading}
				background="rgba(0, 0, 0, 0.5)"
				loaderColor="#fff"
			/>


			<div className="page-body">
				<div className="container-xl">
					<div className="row row-cards">
						<div className="col-12">
							<div className="card">
								<div className="card-body">
									{/* Personal Information */}
									<form
										method="POST"
										onSubmit={handleSubmit}
										autoComplete={"off"}
										encType="multipart/form-data"
									>
										<div className="row">
											<div className='col-md-12'>
												<h5>
													Personal Information<hr />
												</h5>
											</div>
											{/* First Name */}
											<div className="col-md-4">
												<div className="form-group mb-3 ">
													<label className="form-label required">
														First Name
													</label>
													<div>
														<input
															type="text"
															name="firstName"
															id="firstName"
															className={`form-control ${validate.validate && validate.validate.firstName
																? "is-invalid "
																: ""
																}`}
															aria-describedby="emailHelp"
															placeholder="Enter first name"
															onChange={(e) => setFirstName(e.target.value)}
															value={firstName}
														/>
													</div>
													<div
														className={`invalid-feedback text-start ${validate.validate && validate.validate.firstName
															? "d-block"
															: "d-none"
															}`}
													>
														{validate.validate && validate.validate.firstName
															? validate.validate.firstName[0]
															: ""}{" "}
													</div>{" "}
												</div>
											</div>
											{/* Middle Name */}
											<div className="col-md-4">
												<div className="form-group mb-3 ">
													<label className="form-label required">
														Middle Name
													</label>
													<div>
														<input
															type="text"
															name="middleName"
															id="middleName"
															className={`form-control ${validate.validate && validate.validate.middleName
																? "is-invalid "
																: ""
																}`}
															placeholder="Enter middle name"
															onChange={(e) => setMiddleName(e.target.value)}
															value={middleName}
														/>
													</div>
													<div
														className={`invalid-feedback text-start ${validate.validate && validate.validate.middleName
															? "d-block"
															: "d-none"
															}`}
													>
														{validate.validate && validate.validate.middleName
															? validate.validate.middleName[0]
															: ""}{" "}
													</div>{" "}
												</div>
											</div>
											{/* Last Name */}
											<div className="col-md-4">
												<div className="form-group mb-3 ">
													<label className="form-label required">
														Last Name
													</label>
													<div>
														<input
															type="text"
															name="lastName"
															id="lastName"
															className={`form-control ${validate.validate && validate.validate.lastName
																? "is-invalid "
																: ""
																}`}

															placeholder="Enter last name"
															onChange={(e) => setLastName(e.target.value)}
															value={lastName}
														/>
													</div>
													<div
														className={`invalid-feedback text-start ${validate.validate && validate.validate.lastName
															? "d-block"
															: "d-none"
															}`}
													>
														{validate.validate && validate.validate.lastName
															? validate.validate.lastName[0]
															: ""}{" "}
													</div>{" "}
												</div>
											</div>
											{/* Phone Number */}
											<div className="col-md-4">
												<div className="form-group mb-3 ">
													<label className="form-label required">
														Phone Number
													</label>
													<div>
														<input
															type="text"
															name="phoneNumber"
															id="phoneNumber"
															className={`form-control ${validate.validate && validate.validate.phoneNumber
																? "is-invalid "
																: ""
																}`}
															aria-describedby="emailHelp"
															placeholder="Enter phone number"
															onChange={(e) => setPhoneNumber(e.target.value)}
															value={phoneNumber}
														/>
													</div>
													<div
														className={`invalid-feedback text-start 
                            ${validate.validate && validate.validate.phoneNumber
																? "d-block"
																: "d-none"
															}
                           `}
													>
														{validate.validate && validate.validate.phoneNumber
															? validate.validate.phoneNumber[0]
															: ""}{" "}
													</div>{" "}
													<div
														className="invalid-feedback text-start" >
													</div>
												</div>
											</div>
											{/* Department */}
											<div className="col-md-4">
												<div className="form-group mb-3 ">
													<label className="form-label required">
														Department
													</label>
													<div>
														{
															dptListing ?
																(
																	<>
																		<select
																			className="form-select form-control"
																			name="department"
																			id="department"
																			placeholder="Select department"
																			onChange={(e) => fetchDesigtList(e.target.value)}
																			value={department}
																		>
																			<option value="">Select</option>
																			{
																				dptListing && dptListing.map((element, key) => {
																					return (
																						<>
																							<option key={key} value={element.id}>{element.name}</option>
																						</>
																					)
																				}
																				)
																			}
																		</select>
																	</>
																)
																: (
																	<DivSpinner size="sm" color="primary" />
																)
														}
													</div>
													<div
														className={`invalid-feedback text-start 
                            ${validate.validate && validate.validate.department
																? "d-block"
																: "d-none"
															}
														`}
													>
														{validate.validate && validate.validate.department
															? validate.validate.department[0]
															: ""}
													</div>
													<div
														className="invalid-feedback text-start" >
													</div>
												</div>
											</div>
											{/* Designation */}
											<div className="col-md-4">
												<div className="form-group mb-3 ">
													<label className="form-label required">
														Designation
													</label>
													<div>
														{
															desigListing ?
																(
																	<>
																		<select
																			className="form-select form-control"
																			name="designation"
																			id="designation"
																			placeholder="Select designation"
																			onChange={(e) => setDesignation(e.target.value)}
																			value={designation}
																		>
																			<option value="">Select</option>
																			{
																				desigListing && desigListing.map((element, key) => {
																					return (
																						<>
																							<option key={key} value={element.id}>{element.name}</option>
																						</>
																					)
																				}
																				)
																			}
																		</select>
																	</>
																)
																: (
																	<DivSpinner size="sm" color="primary" />
																)
														}
													</div>
													<div
														className={`invalid-feedback text-start 
                            ${validate.validate && validate.validate.designation
																? "d-block"
																: "d-none"
															}
                           `}
													>
														{validate.validate && validate.validate.designation
															? validate.validate.designation[0]
															: ""}
													</div>
													<div
														className="invalid-feedback text-start" >
													</div>
												</div>
											</div>
											{/* Department Team */}
											{
											department == '1' &&
											(
												<div className="col-md-4">
													<div className="mb-3">
														<label className="form-label required">Department Team</label>
														<select
															className={`form-select form-control ${validate.validate && validate.validate.designation
																? "is-invalid "
																: ""
																}`}
															name="teamdepartmentid"
															id="teamdepartmentid"
															placeholder="Select designation"
															onChange={(e) => setTeamDepartmentId(e.target.value)}
															value={teamdepartmentid}
														>
															<option value="">Select</option>
															{
																teamdepartmentList && teamdepartmentList.map((element, index) => {
																	return (
																		<option
																			value={element.id}
																			key={index}
																		>{element.name}</option>

																	);
																})
															}
														</select>
														<div
															className={`invalid-feedback text-start ${validate.validate && validate.validate.teamdepartmentid
																? "d-block"
																: "d-none"
																}`}
														>
															{validate.validate && validate.validate.teamdepartmentid
																? validate.validate.teamdepartmentid[0]
																: ""}{" "}
														</div>{" "}
													</div>
												</div>
											)
											}
											{/* 	Date of Birth */}
											<div className="col-md-4">
												<div className="form-group mb-3 ">
													<label className="form-label required">
														Date of Birth
													</label>
													<div>
														<DatePicker
															name="dateOfBirth"
															id='dateOfBirth'
															value={dateOfBirth}
															selected={dateOfBirth}
															timeFormat=""
															dateFormat="DD-MM-YYYY"
															className={`${validate.validate && validate.validate.dateOfBirth
																? "is-invalid "
																: ""
																}`}
															onChange={(date) => setDateOfBirth(date)}
														/>
													</div>
													<div
														className={`invalid-feedback text-start ${validate.validate && validate.validate.dateOfBirth
															? "d-block"
															: "d-none"
															}`}
													>
														{validate.validate && validate.validate.dateOfBirth
															? validate.validate.dateOfBirth[0]
															: ""}{" "}
													</div>{" "}
												</div>
											</div>
											{/* 	Date of Joining */}
											<div className="col-md-4">
												<div className="form-group mb-3 ">
													<label className="form-label required">
														Date of Joining
													</label>
													<div>
														<DatePicker
															name="dateOfJoining"
															id='dateOfJoining'
															value={dateOfJoining}
															selected={dateOfJoining}
															timeFormat=""
															dateFormat="DD-MM-YYYY"
															className={`${validate.validate && validate.validate.dateOfJoining
																? "is-invalid "
																: ""
																}`}
															onChange={(date) => setDateOfJoining(date)}
														/>
													</div>
													<div
														className={`invalid-feedback text-start ${validate.validate && validate.validate.dateOfJoining
															? "d-block"
															: "d-none"
															}`}
													>
														{validate.validate && validate.validate.dateOfJoining
															? validate.validate.dateOfJoining[0]
															: ""}{" "}
													</div>{" "}
												</div>
											</div>
											{/* Official EMail  */}
											<div className="col-md-6">
												<div className="form-group mb-3 ">
													<label className="form-label required">Official Email</label>
													<div>
														<input
															type="email"
															className={`form-control ${validate.validate && validate.validate.email
																? "is-invalid "
																: ""
																}`}
															id="email"
															name="email"
															value={email}
															placeholder="your@email.com"
															onChange={(e) => setEmail(e.target.value)}
														/>
														<div
															className={`invalid-feedback text-start mb-2 ${validate.validate && validate.validate.email
																? "d-block"
																: "d-none"
																}`}
														>
															{validate.validate && validate.validate.email
																? validate.validate.email[0]
																: ""}
														</div>
													</div>
												</div>
											</div>
											{/* Personal Email */}
											<div className="col-md-6">
												<div className="form-group mb-3 ">
													<label className="form-label required">Personal Email</label>
													<div>
														<input
															type="email"
															className={`form-control ${validate.validate && validate.validate.personalEmail
																? "is-invalid "
																: ""
																}`}
															id="personalEmail"
															name="personalEmail"
															value={personalEmail}
															placeholder="your@email.com"
															onChange={(e) => setPersonalEmail(e.target.value)}
														/>
														<div
															className={`invalid-feedback text-start mb-2 ${validate.validate && validate.validate.personalEmail
																? "d-block"
																: "d-none"
																}`}
														>
															{validate.validate && validate.validate.personalEmail
																? validate.validate.personalEmail[0]
																: ""}
														</div>
													</div>
												</div>
											</div>
											{/* Current Address */}
											<div className="col-md-6">
												<div className="form-group mb-3 ">
													<label className="form-label required">Current Address</label>
													<div>
														<textarea
															className={`form-control ${validate.validate && validate.validate.address
																? "is-invalid "
																: ""
																}`}
															id="address"
															name="address"
															value={address}
															placeholder="Enter address"
															onChange={(e) => setAddress(e.target.value)}
														>{address}</textarea>
														<div
															className={`invalid-feedback text-start mb-2 ${validate.validate && validate.validate.address
																? "d-block"
																: "d-none"
																}`}
														>
															{validate.validate && validate.validate.address
																? validate.validate.address[0]
																: ""}
														</div>
													</div>
												</div>
											</div>


											{/* Aadhar Address */}
											<div className="col-md-6">
												<div className="form-group mb-3 ">
													<label className="form-label required">Aadhar Address</label>
													<div>
														<textarea
															type="text"
															className={`form-control ${validate.validate && validate.validate.aadharAddress
																? "is-invalid "
																: ""
																}`}
															id="aadharAddress"
															name="aadharAddress"
															value={aadharAddress}
															placeholder="Enter aadhar address"
															onChange={(e) => setAadharAddress(e.target.value)}
														>{aadharAddress}</textarea>
														<div
															className={`invalid-feedback text-start mb-2 ${validate.validate && validate.validate.address
																? "d-block"
																: "d-none"
																}`}
														>
															{validate.validate && validate.validate.aadharAddress
																? validate.validate.aadharAddress[0]
																: ""}
														</div>
													</div>
												</div>
											</div>

											{/* Profile */}
											<div className="col-md-4">
												<div className="form-group mb-3 ">
													<label className="form-label required">
														Profile
													</label>
													{/* <input
														type="file"
														name="profile"
														id="profile"
														accept="image/*"
														onChange={onFileChange}
														onChange={(e) => setProfile(e.target.files)}
													/> */}
													<a href='javascript:;' className="mr-3 btn btn-primary" onClick={handleOpenCropper}>Choose Image</a>
													{/* {croppedImage && <img src={croppedImage} alt="Cropped" />} */}

													{<ImageCropperPopup
														isOpen={isImageCropperOpen}
														onClose={handleCloseCropper}
														onSave={handleSaveCroppedImage}
														srcImg={profilePath}
													/>}

												</div>
											</div>
											<div className="col-md-4">
												{
													(profilePath !== '' || profilePath !== null) && (croppedImage == '' || croppedImage == null) ?
														(
															<div className='profile'>
																<img src={CONST.HOST + profilePath} alt={firstName} width="100" />
																{/* <img src={profilePath} alt={firstName} width="50" /> */}
															</div>
														) : ''
												}
												{
													(croppedImage !== '' || croppedImage !== null) ?
														(
															<div className='profile'>
																<img src={croppedImage} alt={firstName} width="100" />
																{/* <img src={profilePath} alt={firstName} width="50" /> */}
															</div>
														) : ''
												}


												{/* hereee */}

												{/* end */}

											</div>

										</div>

										{/* Documents Information */}

										<div className="row">
											<div className='col-md-12'>
												<h5>
													Documents Information<hr />
												</h5>
											</div>


											{/* Aadhar Number */}
											<div className="col-md-6">
												<div className="form-group mb-3 ">
													<label className="form-label required">
														Aadhar Number
													</label>
													<div>
														<input
															type="text"
															name="aadharNumber"
															id="aadharNumber"
															className={`form-control ${validate.validate && validate.validate.aadharNumber
																? "is-invalid "
																: ""
																}`}
															placeholder="Enter aadhar number"
															onChange={(e) => setAadharNumber(e.target.value)}
															value={aadharNumber}
														/>
													</div>
													<div
														className={`invalid-feedback text-start ${validate.validate && validate.validate.aadharNumber
															? "d-block"
															: "d-none"
															}`}
													>
														{validate.validate && validate.validate.aadharNumber
															? validate.validate.aadharNumber[0]
															: ""}{" "}
													</div>{" "}
												</div>
											</div>
											{/* PAN Number */}
											<div className="col-md-6">
												<div className="form-group mb-3 ">
													<label className="form-label required">
														PAN Number
													</label>
													<div>
														<input
															type="text"
															name="panNumber"
															id="panNumber"
															className={`form-control ${validate.validate && validate.validate.panNumber
																? "is-invalid "
																: ""
																}`}
															aria-describedby="emailHelp"
															placeholder="Enter pan number"
															onChange={(e) => setPanNumber(e.target.value)}
															value={panNumber}
														/>
													</div>
													<div
														className={`invalid-feedback text-start ${validate.validate && validate.validate.panNumber
															? "d-block"
															: "d-none"
															}`}
													>
														{validate.validate && validate.validate.panNumber
															? validate.validate.panNumber[0]
															: ""}{" "}
													</div>{" "}
												</div>
											</div>
											{/* UAN Number*/}
											<div className="col-md-6">
												<div className="form-group mb-3 ">
													<label className="form-label required">
														UAN Number
													</label>
													<div>
														<input
															type="text"
															name="uanNumber"
															id="uanNumber"
															className={`form-control ${validate.validate && validate.validate.uanNumber
																? "is-invalid "
																: ""
																}`}
															aria-describedby="emailHelp"
															placeholder="Enter uan number"
															onChange={(e) => setUanNumber(e.target.value)}
															value={uanNumber}
														/>
													</div>
													<div
														className={`invalid-feedback text-start ${validate.validate && validate.validate.uanNumber
															? "d-block"
															: "d-none"
															}`}
													>
														{validate.validate && validate.validate.firuanNumberstName
															? validate.validate.uanNumber[0]
															: ""}{" "}
													</div>{" "}
												</div>
											</div>
											{/* ESIC Number */}
											<div className="col-md-6">
												<div className="form-group mb-3 ">
													<label className="form-label required">
														ESIC Number
													</label>
													<div>
														<input
															type="text"
															name="esicNumber"
															id="esicNumber"
															className={`form-control ${validate.validate && validate.validate.esicNumber
																? "is-invalid "
																: ""
																}`}
															aria-describedby="emailHelp"
															placeholder="Enter esic number"
															onChange={(e) => setEsicNumber(e.target.value)}
															value={esicNumber}
														/>
													</div>
													<div
														className={`invalid-feedback text-start ${validate.validate && validate.validate.esicNumber
															? "d-block"
															: "d-none"
															}`}
													>
														{validate.validate && validate.validate.esicNumber
															? validate.validate.esicNumber[0]
															: ""}{" "}
													</div>{" "}
												</div>
											</div>
											<div className="col-md-12 pt-3 pb-5 text-right">
												<div className="form-footer">
													<button
														type="submit"
														className="mr-3 btn btn-primary"
													>
														<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-file-symlink" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
															<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
															<path d="M4 21v-4a3 3 0 0 1 3 -3h5"></path>
															<path d="M9 17l3 -3l-3 -3"></path>
															<path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
															<path d="M5 11v-6a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2h-9.5"></path>
														</svg>	Update Information
													</button>
													&nbsp;&nbsp;
													<button
														type="reset"
														className="btn btn-outline-primary"
														onClick={fetchUserData}
													>
														Reset
													</button>
													<hr />
												</div>
											</div>
										</div>
									</form>
									{/* Guardian Information */}
									<div className="row">
										<div className='col-md-12'>
											<h5>
												Gaurdian Information<hr />
											</h5>
										</div>

										{/* Gaurdian DIT  FOrm */}
										<div className='col-md-12 pb-3'>
											<div className="card">
												<div className="table-responsive" key="div_0">
													<form onSubmit={handleGaurdEditFormSubmit}>
														<table className="table table-vcenter card-table table-striped" key="table_0" id="custTable">
															<thead>
																<tr key="tr_head">
																	<th>Relation</th>
																	<th>Name</th>
																	<th>Email</th>
																	<th>Phone Number</th>
																	<th>Address</th>
																	<th className="w-1">Actions</th>
																</tr>
															</thead>
															<tbody>
																{guardianDetails.map((gItem, i) => (
																	<>
																		{editGuardianId === gItem.id ?
																			(<EditableRow gItem={[gItem, i]}
																				editFormData={editGaurdFormData}
																				handleEditFormChange={handleGaurdEditFormChange}
																				handleCancelClick={handleGaurdCancelClick} />) :
																			(<ReadOnlyRow gItem={gItem}
																				handleEditClick={handleGaurdEditClick} deleteHandler={handleGaurdDelete} deleteImg={deleteImg} />)
																		}
																	</>
																))}
															</tbody>
														</table>
													</form>
												</div>
											</div>
										</div>


										{/* Gaurdian Main FOrm */}
										<div className='col-md-12'>
											<form
												method="POST"
												onSubmit={handleGaurdFormSubmit}
												autoComplete={"off"}
											>
												{gaurdFormValues.map((item, i) => (
													<div className="row" key={`row_${i}`} >
														<div className="col-md-6">
															<div className="form-group mb-3 ">
																<label className="form-label required">
																	Relation
																</label>
																<div>

																	<input
																		type="text"
																		name="relation"
																		id={`relation_${i}`}
																		value={item.relation || ""}
																		className={`form-control`}
																		aria-describedby="emailHelp"
																		placeholder="Enter relation"
																		onChange={(e) => handleGaurdOnHandle(e, i)}
																	/>

																</div>

																<div className={`invalid-feedback text-start ${item.relationError
																	? "d-block"
																	: "d-none"
																	}`}>										{item.relationError}
																</div>
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group mb-3 ">
																<label className="form-label required">
																	Name
																</label>
																<div>
																	<input
																		type="text"
																		name="gName"
																		id="gName"
																		className={`form-control`}
																		aria-describedby="emailHelp"
																		placeholder="Enter name"
																		onChange={(e) => handleGaurdOnHandle(e, i)}
																		value={item.gName}
																	/>
																</div>
																<div className={`invalid-feedback text-start ${item.gNameError
																	? "d-block"
																	: "d-none"
																	}`}>										{item.gNameError}
																</div>
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group mb-3 ">
																<label className="form-label required">Email</label>
																<div>
																	<input
																		type="text"
																		className={`form-control`}
																		id="email"
																		name="email"
																		value={item.email}
																		placeholder="your@email.com"
																		onChange={(e) => handleGaurdOnHandle(e, i)}
																	/>
																	<div className={`invalid-feedback text-start ${item.emailError
																		? "d-block"
																		: "d-none"
																		}`}>{item.emailError}
																	</div>
																</div>
															</div>
														</div>
														<div className="col-md-6">
															<div className="form-group mb-3 ">
																<label className="form-label required">
																	Phone Number
																</label>
																<div>
																	<input
																		type="text"
																		name="gPhoneNumber"
																		id="gPhoneNumber"
																		className={`form-control`}
																		aria-describedby="emailHelp"
																		placeholder="Enter phone number"
																		onChange={(e) => handleGaurdOnHandle(e, i)}
																		value={item.gPhoneNumber}
																	/>
																</div>
																<div className={`invalid-feedback text-start ${item.gPhoneNumberError
																	? "d-block"
																	: "d-none"
																	}`}>{item.gPhoneNumberError}
																</div>
																<div
																	className="invalid-feedback text-start" >
																</div>
															</div>
														</div>
														<div className="col-md-4">
															<div className="form-group mb-3 ">
																<label className="form-label required">Address</label>
																<div>

																	<textarea className={`form-control`}
																		id="gAddress"
																		name="gAddress"
																		value={item.gAddress}
																		placeholder="Enter address"
																		onChange={(e) => handleGaurdOnHandle(e, i)} ></textarea>
																	<div className={`invalid-feedback text-start ${item.gAddressError
																		? "d-block"
																		: "d-none"
																		}`}>{item.gAddressError}
																	</div>
																</div>
															</div>
														</div>
														{i === 0 ? "" :
															<div className="col-md-4">
																<div className="form-group mb-3 ">
																	<img src={deleteImg} alt='deleteImg' width={32} className='cursor' onClick={() => handleGaurdOnRemove(i, item.id)} />

																</div>

															</div>

														}
														<hr></hr>
													</div>
												))}


												<div className="col-md-12 pt-3 pb-5 text-right">
													<div className="form-footer">
														<button
															type="submit"
															className="mr-3 btn btn-primary"
														>
															<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-file-symlink" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
																<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
																<path d="M4 21v-4a3 3 0 0 1 3 -3h5"></path>
																<path d="M9 17l3 -3l-3 -3"></path>
																<path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
																<path d="M5 11v-6a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2h-9.5"></path>
															</svg>
															Update Gaurdian Information
														</button>

													</div>
												</div>
											</form>
										</div>

										{/* Social Information */}
										<div className='col-md-12'>
											<form
												method="POST"
												onSubmit={handleSocialSubmit}
												autoComplete={"off"}
												encType="multipart/form-data"
											>
												<div className="row">
													<div className='col-md-12'>
														<h5>
															Social Information<hr />
														</h5>
													</div>
													{/* LinkedIn */}
													<div className="col-md-6">
														<div className="form-group mb-3 ">
															<label className="form-label required">
																LinkedIn URL
															</label>
															<div>
																<input
																	type="text"
																	name="firstName"
																	id="firstName"
																	className={`form-control ${validate.validate && validate.validate.LinkedIn
																		? "is-invalid "
																		: ""
																		}`}
																	placeholder="Enter LinkedIn url"
																	onChange={(e) => setLinkedInUrl(e.target.value)}
																	value={LinkedIn}
																/>
															</div>
															<div
																className={`invalid-feedback text-start ${validate.validate && validate.validate.LinkedIn
																	? "d-block"
																	: "d-none"
																	}`}
															>
																{validate.validate && validate.validate.LinkedIn
																	? validate.validate.LinkedIn[0]
																	: ""}{" "}
															</div>{" "}
														</div>
													</div>
													{/* Facebook */}
													<div className="col-md-6">
														<div className="form-group mb-3 ">
															<label className="form-label ">
																Facebook URL
															</label>
															<div>
																<input
																	type="text"
																	name="firstName"
																	id="firstName"
																	className={`form-control ${validate.validate && validate.validate.Facebook
																		? "is-invalid "
																		: ""
																		}`}
																	placeholder="Enter Facebook 
															url"
																	onChange={(e) => setFacebookUrl(e.target.value)}
																	value={Facebook}
																/>
															</div>
															<div
																className={`invalid-feedback text-start ${validate.validate && validate.validate.Facebook
																	? "d-block"
																	: "d-none"
																	}`}
															>
																{validate.validate && validate.validate.Facebook
																	? validate.validate.Facebook[0]
																	: ""}{" "}
															</div>{" "}
														</div>
													</div>
													<div className="col-md-12 pt-3 pb-5 text-right">
														<div className="form-footer">
															<button
																type="submit"
																className="mr-3 btn btn-primary"
															>
																<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-file-symlink" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
																	<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
																	<path d="M4 21v-4a3 3 0 0 1 3 -3h5"></path>
																	<path d="M9 17l3 -3l-3 -3"></path>
																	<path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
																	<path d="M5 11v-6a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2h-9.5"></path>
																</svg>Update Social Information
															</button>
															&nbsp;&nbsp;

															<hr />
														</div>
													</div>
												</div>
											</form>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div >
			</div >

		</>
	)
}

export default PersonalInformation