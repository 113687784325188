export const onColor = "#81b3ee";
export const onHandleColor = "#206bc4";
export const handleDiameter = 20;
export const uncheckedIcon = true;
export const checkedIcon = true;
export const boxShadow = "0px 1px 5px rgba(0, 0, 0, 0.6)";
export const activeBoxShadow = "0px 0px 1px 10px rgba(0, 0, 0, 0.2)";
export const height = 20;
export const width = 48;
export const id = "material-switch";
export const className="react-switch"
export const top ="7"