import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import Form from '../../../component/utilities/Forms';
import * as CONST from "../../../component/config/Constant";
import { AuthContext } from '../../../component/context/AuthContext';
import { toast } from "react-toastify";
import DivSpinner from '../../../component/utilities/Spinner';
function AddTestimonials({ childToParent }) {
	const [authUserData, setAuthUserData] = useContext(AuthContext);
	const [textTestimonials, settextTestimonials] = useState("");
	const [validate, setValidate] = useState({});
	const [show, setShow] = useState('show');
	const [emplData, setEmplData] = useState('');
	const [emplName, setEmplName] = useState('');
	const [status, setStatus] = useState("");
	const [loading, setLoading] = useState(false);
	const handleShow = () => setShow(true);
	let user_id = '';
	let isToken = '';
	if (authUserData) {
		if (authUserData[0]) {
			isToken = authUserData[0].token ? authUserData[0].token : "";
			user_id = authUserData[0].user_id ? authUserData[0].user_id : "";
		}
	}

	useEffect(() => {
		fetchUserData();
	}, []);

	async function fetchUserData() {
		setLoading(true);
		const response = await axios
			.post(CONST.EMP_LIST,
				{
					"limit": "",
					"page_number": "",
					"search_input": "",
					"sort": {
						order: 'desc',
						order_by: 'id'
					}
				},
				{
					headers: {
						'Authorization': `Basic ${isToken}`
					}
				}
			)
			.then((response) => {
				const status = response.data.status;
				if (status == 'success') {
					setEmplData(response.data.data);
				} else {
					toast.error(response.data.message);
				}
			})
			.catch((error) => {
				toast.error("There was an error.");
			});
		setLoading(false);
	}

	const validTestimonials = async () => {
		let isValid = true;

		let validator = Form.validator({
			textTestimonials: {
				value: textTestimonials,
				isRequired: true,
				isEmail: false,
				label: "testimonials",
			},
			emplName: {
				value: emplName,
				isRequired: true,
				label: "Employee Name",
			},
			status: {
				value: status,
				isRequired: true,
				isEmail: false,
				label: "status",
			},
		});

		if (validator !== null) {
			setValidate({
				validate: validator.errors,
			});
			isValid = false;
		} else {
			setValidate({
				validate: "",
			});
			isValid = true;
		}
		return isValid;
	};

	const handleForm = async (e) => {
		e.preventDefault();
		const validate = await validTestimonials();
		if (validate) {
			await axios
				.post(CONST.ADMIN_ADD_TESTIMONIAL, {
					description: textTestimonials,
					user_id: emplName,
					added_by: user_id,
					status: status

				}, {
					headers: {
						'Authorization': `Basic ${isToken}`
					}
				})
				.then((response) => {
					console.log(response);
					console.log(response.data.status);
					const status = response.data.status;
					setLoading(false);
					if (status === 'error') {
						const errorMsg = response.data.message;
						toast.error(errorMsg);

					} else {
						const errorMsg = response.data.message;
						toast.success(errorMsg);

					}
				})
				.catch((error) => {
					setLoading(false);
					console.log(error);
					toast.error("Something went wrong. Please try again.");
				});
		}

	}

	return (
		<>
			<Modal
				show={show}
				onHide={() => childToParent('hide')}
				backdrop="static"
				keyboard={false}
				centered
				size="md"
			>
				<Modal.Header closeButton>
					<Modal.Title>Add Testimonials</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleForm}>
						<div className="row">
							<div className="col-md-12">
								<div className="form-group mb-3 ">
									<label className="form-label required">
										Select Employee
									</label>
									<div>
										{
											emplData ?
												(
													<>
														<select
															className="form-select form-control select2"
															name="emplName"
															id="emplName"
															placeholder="Select employee"
															onChange={(e) => setEmplName(e.target.value)}
															value={emplName}
														>
															<option value="">Select</option>
															{
																emplData && emplData.map((employee, key) => {
																	return (
																		<>
																			<option key={key} value={employee.id}>{employee.first_name} {employee.last_name} - {employee.department.name}</option>
																		</>
																	)
																}
																)
															}
														</select>
													</>
												)
												: (
													<DivSpinner size="sm" color="primary" />
												)
										}
									</div>
									<div
										className={`invalid-feedback text-start 
                            ${validate.validate && validate.validate.emplName
												? "d-block"
												: "d-none"
											}
                           `}
									>
										{validate.validate && validate.validate.emplName
											? validate.validate.emplName[0]
											: ""}
									</div>
									<div
										className="invalid-feedback text-start" >
									</div>
								</div>
							</div>
							<div className="col-md-12">
								<div className="form-group mb-3 ">
									<label className="form-label required">Enter testimonial
									</label>
									<div>
										<textarea
											name="textTestimonials"
											id="textTestimonials"
											className={`form-control ${validate.validate && validate.validate.textTestimonials
												? "is-invalid "
												: ""
												}`}
											aria-describedby="emailHelp"
											placeholder="Enter testimonial"
											onChange={(e) => settextTestimonials(e.target.value)}
											style={{ height: '100px !important;' }}
											value={textTestimonials}
										>{textTestimonials}</textarea>
									</div>
									<div
										className={`invalid-feedback text-start ${validate.validate && validate.validate.textTestimonials
											? "d-block"
											: "d-none"
											}`}
									>
										{validate.validate && validate.validate.textTestimonials
											? validate.validate.textTestimonials[0]
											: ""}{" "}
									</div>{" "}
								</div>
							</div>
							<div className="col-md-12">
								<div className="mb-3">
									<label className="form-label required">Status</label>
									<select
										className={`form-select form-control ${validate.validate && validate.validate.status
											? "is-invalid "
											: ""
											}`}
										name="status"
										id="status"
										placeholder="Select status"
										onChange={(e) => setStatus(e.target.value)}
										value={status}
									>
										<option value="">Select</option>
										<option value="1">Active</option>
										<option value="0">Deactive</option>
									</select>
									<div
										className={`invalid-feedback text-start ${validate.validate && validate.validate.status
											? "d-block"
											: "d-none"
											}`}
									>
										{validate.validate && validate.validate.status
											? validate.validate.status[0]
											: ""}{" "}
									</div>{" "}
								</div>
							</div>
							<div className="col-md-12 text-right">
								<div className="form-footer">
									<button
										type="submit"
										className="mr-3 btn btn-primary"
									>
										Submit
									</button>
									&nbsp;&nbsp;
									<button
										type="reset"
										className="btn btn-outline-primary"
									>
										Reset
									</button>
								</div>
							</div>
						</div>
					</form>
				</Modal.Body>

			</Modal>
		</>
	)
}

export default AddTestimonials