import React from "react";
import DatePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment';

const EditableRow = ({editFormData, handleEditFormChange, handleCancelClick, handleEditCheck }) => {
  //console.log('editFormData'+JSON.stringify(editFormData) );
  return (
    <tr key='editExperince'>
    <td> 
        <input
            type="text"
            name="company_name"
            value= {editFormData.company_name}
            className={`form-control`}
            placeholder="Enter Company Name"
            onChange={(e) => handleEditFormChange(e.target.value,'company_name')}
            required="required" 
        />
        <span className={`invalid-feedback text-start ${editFormData.error_company_name ? "d-block" : "d-none"}`}>{editFormData.error_company_name !== "" ? editFormData.error_company_name : ''}
        </span>    
    </td>
    <td>
        <input
        type="text"
        name="position"
        id="position"
        className={`form-control`}
        placeholder="Enter position"
        value= {editFormData.position}
        onChange={(e) => handleEditFormChange(e.target.value,'position')}
        required="required" />  
        <span className={`invalid-feedback text-start ${editFormData.error_position ? "d-block" : "d-none"}`}>{editFormData.error_position !== "" ? editFormData.error_position : ''}
        </span>     
    </td>

    <td>
      <input
        type="checkbox"
        name="is_current_job"
        id={ `is_current_job`}
        checked={(editFormData.is_current_job == '1' ) ? 'checked' : '' }
        aria-describedby="emailHelp"
        onChange={(evnt)=>handleEditCheck(evnt)}
        
      />
          
    </td>

    <td>
      <DatePicker
        name="start_date"
        id={ `start_date`}
        value={(editFormData.start_date)}
        selected={editFormData.start_date}
        timeFormat=""
        dateFormat="DD-MM-YYYY"
        className=''
        onChange={(e) => handleEditFormChange(e,'start_date')}
      />    
      <span className={`invalid-feedback text-start ${editFormData.error_start_date ? "d-block" : "d-none"}`}>{editFormData.error_start_date !== "" ? editFormData.error_start_date : ''}
        </span>   
    </td>

    {
        (editFormData.is_current_job == '0') ?
        (
          <td>
            <DatePicker
              name="end_date"
              id={ `end_date`}
              value={(editFormData.end_date) }
              selected={editFormData.end_date}
              timeFormat=""
              dateFormat="DD-MM-YYYY"
              className=''
              onChange={(e) => handleEditFormChange(e,'end_date')}
            />    
            <span className={`invalid-feedback text-start ${editFormData.error_end_date ? "d-block" : "d-none"}`}>{editFormData.error_end_date !== "" ? editFormData.error_end_date : ''}
            </span>   
          </td>
        )
        :
        ''
    }
    
    
    <td>
      <div className="inline-btn-grp">
        <button className="btn btn-primary" type="submit">Save</button>&nbsp;
        <button className="btn btn-danger" type="button" onClick={handleCancelClick}>Cancel</button>
      </div>
    </td>
    </tr>
  )
}

export default EditableRow