import { React, useState, useEffect, useContext } from 'react'
import Loading from "react-fullscreen-loading";
import HeaderSection from "../banner/HeaderSection";
import * as  CONST from "../../../component/config/Constant"
import "../../../assets/css/form.css";
import Swal from 'sweetalert2';
import deleteImg from "../../../assets/img/icon/delete.svg";
import axios from 'axios';
import { AuthContext } from '../../../component/context/AuthContext';
import { toast } from "react-toastify";
import ReadOnlyRow from '../../../component/guardian/ReadOnlyRow';
import EditableRow from '../../../component/guardian/EditableRow';
function GuardianInformation() {
	const [authUserData, setAuthUserData] = useContext(AuthContext);
	const [loading, setLoading] = useState(false);
	const [formVal, setFormVal] = useState([{ relation: '', gName: '', email: '', gPhoneNumber: '', gAddress: '' }]);

	const [guardianDetails, setGuardianDetails] = useState([{ relation: '', gName: '', email: '', gPhoneNumber: '', gAddress: '' }]);

	const [editGuardianId, seteditGuardianId] = useState(null);

	const [editFormData, setEditFormData] = useState({ relation: '', gName: '', email: '', gPhoneNumber: '', gAddress: '' });

	let regex =
		/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
	let isNumber = /^(?!0+$)[0-9]{10}$/;
	let isAlphabetic = /^[a-zA-Z\s]+$/;


	let isToken = '';
	if (authUserData) {
		if (authUserData[0]) {
			isToken = authUserData[0].token ? authUserData[0].token : "";
		}
	}

	useEffect(() => {
		fetchGuardianData();
	}, []);

	const handleCancelClick = (event, guardian) => {
		seteditGuardianId(null);
	}

	const handleEditFormSubmit = (event, guardian) => {
		event.preventDefault();
		const index = guardianDetails.findIndex((guardian) => guardian.id === editGuardianId);
		const isEditDataValid = validateEditFields(editFormData, index);

		if (isEditDataValid) {
			axios.put(CONST.EDIT_GUARDIAN_DETAILS,
				{
					'id': editGuardianId,
					'relation_name': editFormData.relation,
					'name': editFormData.gName,
					'email': editFormData.email,
					'phone': editFormData.gPhoneNumber,
					'address': editFormData.gAddress

				}, {
				headers: {
					'Authorization': `Basic ${isToken}`,
				}
			}
			)
				.then((response) => {
					const status = response.data.status;
					setLoading(false);

					if (status === 'success') {
						toast.success(response.data.message);
						fetchGuardianData();
						seteditGuardianId(null);
					} else {
						toast.error(response.data.message);
					}
				})
				.catch((error) => {
					setLoading(false);
					toast.error("Something went wrong. Please try again.");
				});
		}
	}

	const validateEditFields = (editFormData, index) => {
		const newFormData = [...guardianDetails];
		let editData = editFormData;
		if (editData.relation === '') {
			editData.editRelationError = "Guardian relation required";
		}
		else if (!isAlphabetic.test(editData.relation)) {
			editData.editRelationError = "Guardian relation is not valid";
		}
		else {
			editData.editRelationError = "";
		}

		if (editData.gName === '') {
			editData.editGNameError = "Guardian Name required";
		}
		else if (!isAlphabetic.test(editData.gName)) {
			editData.editGNameError = "Name is not valid";
		}
		else {
			editData.editGNameError = "";
		}

		if (editData.email !== '') {
			if (!regex.test(editData.email)) {
				editData.editEmailError = "Email is not valid";
			}
			else {
				editData.editEmailError = "";
			}
		}
		else {
			editData.emailError = "";
		}

		if (editData.gPhoneNumber === '') {
			editData.editGPhoneNumberError = "Phone number required";
		}
		else if (!isNumber.test(editData.gPhoneNumber)) {
			editData.editGPhoneNumberError = "Phone number is not valid";
		}
		else {
			editData.editGPhoneNumberError = "";
		}

		if (editData.gAddress === '') {
			editData.editGAddressError = "Address required";
		}
		else {
			editData.editGAddressError = "";
		}

		setEditFormData(editData);
		newFormData[index] = editData;
		setGuardianDetails(newFormData);
		if (editData.editRelationError == "" &&
			editData.editGNameError == "" &&
			editData.editEmailError == "" &&
			editData.editGPhoneNumberError == "" &&
			editData.editGAddressError == "") {
			return true;
		} else {
			return false;
		}
	}

	const handleEditClick = (event, guardian) => {
		event.preventDefault();
		seteditGuardianId(guardian.id);
		const formValues = {
			'id': guardian.id,
			'relation': guardian.relation,
			'gName': guardian.gName,
			'email': guardian.email,
			'gPhoneNumber': guardian.gPhoneNumber,
			'gAddress': guardian.gAddress
		}
		setEditFormData(formValues);
	}


	const handleEditFormChange = (event) => {
		event.preventDefault();
		const fieldName = event.target.getAttribute("name");
		const fieldValue = event.target.value;

		const newFormData = { ...editFormData };
		newFormData[fieldName] = fieldValue;

		setEditFormData(newFormData);
	}

	function deleteHandler(id) {
		Swal.fire({
			title: 'Are you sure?',
			text: "Do you want to delete this details?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then((result) => {
			if (result.isConfirmed) {
				//setLoading(true);
				const newFormData = [...guardianDetails];
				const index = guardianDetails.findIndex((guardian) => guardian.id === editGuardianId);
				if (id > 0) {
					axios
						.delete(CONST.DELETE_GUARDIAN_DETAILS, {
							headers: {
								'Authorization': `Bearer ${isToken}`
							},
							data: {
								guard_id: id
							}
						})
						.then((response) => {

							if (response) {
								if (response.data.status == 'error') {
									toast.error(response.data.message);
								} else {
									toast.success(response.data.message);
									//newFormData.splice(index,1);
									//setGuardianDetails(newFormData);
									//handleClose();
									fetchGuardianData()
								}
							}
						})
						.catch((error) => {
							setLoading(false);
							toast.error("Something went wrong. Please try again." + error);
						});
					setLoading(false);
				}
			}
		})
	}

	//fetch all guardian details
	async function fetchGuardianData() {
		setLoading(true);
		await axios
			.get(CONST.GET_GUARDIAN_DETAILS, {
				headers: {
					'Authorization': `Bearer ${isToken}`
				}
			})

			.then((response) => {
				const status = response.data.status;
				if (status === 'success') {
					const responseData = response.data.data;
					var guardian = [];
					let isArray = Array.isArray(responseData);
					if (isArray) {
						responseData.map((g, index) => {
							guardian.push({
								'id': g.id,
								'relation': g.relation_name,
								'gName': g.guardian_name,
								'email': g.guardian_email,
								'gPhoneNumber': g.guardian_phone,
								'gAddress': g.guardian_address
							});

						})
					}
					setGuardianDetails(guardian);
				}
				else {
					toast.error(response.data.message);
				}
			})
			.catch((error) => {
				console.log(error);
			});
		setLoading(false);
	}

	const addRow = () => {
		setFormVal([...formVal, { relation: '', gName: '', email: '', gPhoneNumber: '', gAddress: '' }])
	}

	function onRemove(i, id) {
		const newFormData = [...formVal];
		newFormData.splice(i, 1);
		setFormVal(newFormData);
	}

	const onHandle = (e, i) => {
		let newForm = [...formVal];
		newForm[i][e.target.name] = e.target.value;
		setFormVal(newForm);
	}

	const validateGuardianInfo = (formInputValues) => {
		let data = [...formInputValues];
		let valid = true;

		for (let index = 0; index < data.length; index++) {
			if (data[index].relation === '') {
				data[index].relationError = "Guardian relation required";
				valid = false;
			}
			else if (!isAlphabetic.test(data[index].relation)) {
				data[index].relationError = "Guardian relation is not valid";
				valid = false;
			}
			else {
				data[index].relationError = "";
				valid = true;
			}

			if (data[index].gName === '') {
				data[index].gNameError = "Guardian Name required";
				valid = false;
			}
			else if (!isAlphabetic.test(data[index].gName)) {
				data[index].gNameError = "Name is not valid";
				valid = false;
			}
			else {
				data[index].gNameError = "";
				valid = true;
			}

			if (data[index].email !== '') {
				if (!regex.test(data[index].email)) {
					data[index].emailError = "Email is not valid";
					valid = false;
				}
				else {
					data[index].emailError = "";
					valid = true;
				}
			}
			else {
				data[index].emailError = "";
				valid = true;
			}

			if (data[index].gPhoneNumber === '') {
				data[index].gPhoneNumberError = "Phone number required";
				valid = false;
			}
			else if (!isNumber.test(data[index].gPhoneNumber)) {
				data[index].gPhoneNumberError = "Phone number is not valid";
				valid = false;
			}
			else {
				data[index].gPhoneNumberError = "";
				valid = true;
			}

			if (data[index].gAddress === '') {
				data[index].gAddressError = "Address required";
				valid = false;
			}
			else {
				data[index].gAddressError = "";
				valid = true;
			}
		}
		setFormVal(data);
		return valid;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		var guardian = [];
		const isValid = validateGuardianInfo(formVal);
		if (isValid) {
			setLoading(false);
			for (let i = 0; i < formVal.length; i++) {
				guardian.push({
					'relation_name': formVal[i].relation,
					'name': formVal[i].gName,
					'email': formVal[i].email,
					'phone': formVal[i].gPhoneNumber,
					'address': formVal[i].gAddress
				});
			}

			await axios
				.post(CONST.ADD_GUARDIAN_DETAILS, JSON.stringify({ guardian }), {
					headers: {
						'Authorization': `Basic ${isToken}`,
						'Content-Type': 'application/json'
					}
				})
				.then((response) => {
					const status = response.data.status;
					setLoading(false);

					if (status === 'success') {
						toast.success(response.data.message);
						fetchGuardianData();
						setFormVal([{ relation: '', gName: '', email: '', gPhoneNumber: '', gAddress: '' }]);
					} else {
						toast.error(response.data.message);
					}

				})
				.catch((error) => {
					setLoading(false);
					toast.error("Something went wrong. Please try again.");
				});
		}
	}

	return (
		<>
			<Loading
				loading={loading}
				background="rgba(0, 0, 0, 0.5)"
				loaderColor="#fff"
			/>
			<HeaderSection
				title=""
				heading="Guardian Information"
				addButton={addRow}
				addLink="#"
				addLinkName="Add More"
				linktype="add" />

			<div className="page-body">
				<div className="container-xl">
					<div className="row row-cards">
						<div className="col-12">

							<div className="card" key="gcard">

								<div className="card-body">

									<div className="col-12">
										<div className="card">
											<div className="table-responsive" key="div_0">
												<form onSubmit={handleEditFormSubmit}>
													<table className="table table-vcenter card-table table-striped" key="table_0" id="custTable">
														<thead>
															<tr key="tr_head">
																<th>Relation</th>
																<th>Name</th>
																<th>Email</th>
																<th>Phone Number</th>
																<th>Address</th>
																<th className="w-1">Actions</th>
															</tr>
														</thead>
														<tbody>
															{guardianDetails.map((gItem, i) => (
																<>
																	{editGuardianId === gItem.id ?
																		(<EditableRow gItem={[gItem, i]}
																			editFormData={editFormData}
																			handleEditFormChange={handleEditFormChange}
																			handleCancelClick={handleCancelClick} />) :
																		(<ReadOnlyRow gItem={gItem}
																			handleEditClick={handleEditClick} deleteHandler={deleteHandler} deleteImg={deleteImg} />)
																	}
																</>
															))}
														</tbody>
													</table>
												</form>
											</div>
										</div>
									</div>

								</div>
							</div>


							<div className="card">

								<div className="card-body">
									<form
										method="POST"
										onSubmit={handleSubmit}
										autoComplete={"off"}
									>
										{formVal.map((item, i) => (
											<div className="row" key={`row_${i}`} >
												<div className="col-md-6">
													<div className="form-group mb-3 ">
														<label className="form-label required">
															Relation
														</label>
														<div>

															<input
																type="text"
																name="relation"
																id={`relation_${i}`}
																value={item.relation || ""}
																className={`form-control`}
																aria-describedby="emailHelp"
																placeholder="Enter relation"
																onChange={(e) => onHandle(e, i)}
															/>

														</div>

														<div className={`invalid-feedback text-start ${item.relationError
															? "d-block"
															: "d-none"
															}`}>										{item.relationError}
														</div>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group mb-3 ">
														<label className="form-label required">
															Name
														</label>
														<div>
															<input
																type="text"
																name="gName"
																id="gName"
																className={`form-control`}
																aria-describedby="emailHelp"
																placeholder="Enter name"
																onChange={(e) => onHandle(e, i)}
																value={item.gName}
															/>
														</div>
														<div className={`invalid-feedback text-start ${item.gNameError
															? "d-block"
															: "d-none"
															}`}>										{item.gNameError}
														</div>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group mb-3 ">
														<label className="form-label required">Email</label>
														<div>
															<input
																type="text"
																className={`form-control`}
																id="email"
																name="email"
																value={item.email}
																placeholder="your@email.com"
																onChange={(e) => onHandle(e, i)}
															/>
															<div className={`invalid-feedback text-start ${item.emailError
																? "d-block"
																: "d-none"
																}`}>{item.emailError}
															</div>
														</div>
													</div>
												</div>
												<div className="col-md-6">
													<div className="form-group mb-3 ">
														<label className="form-label required">
															Phone Number
														</label>
														<div>
															<input
																type="text"
																name="gPhoneNumber"
																id="gPhoneNumber"
																className={`form-control`}
																aria-describedby="emailHelp"
																placeholder="Enter phone number"
																onChange={(e) => onHandle(e, i)}
																value={item.gPhoneNumber}
															/>
														</div>
														<div className={`invalid-feedback text-start ${item.gPhoneNumberError
															? "d-block"
															: "d-none"
															}`}>{item.gPhoneNumberError}
														</div>
														<div
															className="invalid-feedback text-start" >
														</div>
													</div>
												</div>
												<div className="col-md-4">
													<div className="form-group mb-3 ">
														<label className="form-label required">Address</label>
														<div>

															<textarea className={`form-control`}
																id="gAddress"
																name="gAddress"
																value={item.gAddress}
																placeholder="Enter address"
																onChange={(e) => onHandle(e, i)} ></textarea>
															<div className={`invalid-feedback text-start ${item.gAddressError
																? "d-block"
																: "d-none"
																}`}>{item.gAddressError}
															</div>
														</div>
													</div>
												</div>
												{i === 0 ? "" :
													<div className="col-md-4">
														<div className="form-group mb-3 ">
															<img src={deleteImg} alt='deleteImg' width={32} className='cursor' onClick={() => onRemove(i, item.id)} />

														</div>

													</div>

												}
												<hr></hr>
											</div>
										))}


										<div className="col-md-12">
											<div className="form-footer">
												<button
													type="submit"
													className="mr-3 btn btn-primary"
												>
													Submit
												</button>
												&nbsp;&nbsp;
												<button
													type="reset"
													className="btn btn-outline-primary"
												>
													Reset
												</button>
											</div>
										</div>
									</form>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div >
		</>
	)
}

export default GuardianInformation