import { React, useState, useEffect, useContext, useMemo, ChangeEvent  } from 'react';
import { useParams,useNavigate } from "react-router-dom";
import "../../../assets/css/table.css";
import Loading from "react-fullscreen-loading";
import DivLoader from "../../../component/utilities/Spinner";
import DataTable from "react-data-table-component";
import axios from "axios";
import { toast } from "react-toastify";
import * as  CONST from "../../../component/config/Constant"
import { AuthContext } from '../../../component/context/AuthContext';
import { Link } from 'react-router-dom';
import Moment from "moment";
import editImg from "../../../assets/img/icon/edit.svg";
import deleteImg from "../../../assets/img/icon/delete.svg";
import ReactSwitch from 'react-switch';
import * as SwitchProps from '../../../component/utilities/ReactSwitchReactSwitchProps';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import Form from '../../../component/utilities/Forms';
import DivSpinner from '../../../component/utilities/Spinner';



function Details() {

    const [authUserData, setAuthUserData] = useContext(AuthContext);
    const [fullLoading, setFullLoading] = useState(false);
    const [validate, setValidate] = useState({});
    const [processing, setProcessing] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [srchEventName, setSrchEventName] = useState("");
    const [srchStatus, setSrchStatus] = useState("");
    const [srchCatName, setSrchCatName] = useState("");
    const [webOpeningList, setwebOpeningList] = useState('');
    const [webOpeningDetails, setwebOpeningDetails] = useState('');
    const [isAddModalShow, setAddModalShow] = useState(false);
    const [isEditModalShow, setEditModalShow] = useState(false);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [number, setNumber] = useState("");
    const [address, setAddress] = useState("");
    const [current_organization, setCurrentOrganization] = useState("");
    const [experience, setExperience] = useState("");
    const [notice_period, setNoticePeriod] = useState("");
    const [images, setImages] = useState({})
    const [selectedImage, setSelectedImage] = useState(null);
    const [refereBy, setReferBy] = useState("");

    let isToken = '';
    let user_id = '';
    let user_name = '';
    if (authUserData) {
        if (authUserData[0]) {
          isToken = authUserData[0].token ? authUserData[0].token : "";
          user_id = authUserData[0].user_id ? authUserData[0].user_id : "";
          user_name = authUserData[0].first_name ? authUserData[0].first_name+' '+authUserData[0].last_name : "";
        }
    }

    const { id } = useParams();
    const navigate = useNavigate();

    const config = useMemo(
        () => ({
            readonly: false, 
            uploader: { "insertImageAsBase64URI": true }
        }),
        []
    );

    useEffect(() => {
        fetchWebOpeningsData();
        fetchWebOpeningsDetailsData(id);
    },[]);

    async function fetchWebOpeningsData() {
        setLoading(true);
        const response = await axios
          .post(CONST.GET_OPENINGS_WEB_LIST)
          .then((response) => {
                const status = response.data.status;
                setLoading(false);
                if (status === 'success') {
                    setwebOpeningList(response.data.data);
                }else{
                    toast.error(response.data.message);
                }
          })
          .catch((error) => {
            setLoading(false);
            toast.error("There was an error.");
          });
      }

    async function fetchWebOpeningsDetailsData(id) {
      setLoading(true);
      const response = await axios
        .get(CONST.GET_OPENINGS_WEB_DETAILS+id)
        .then((response) => {
              const status = response.data.status;
              setLoading(false);
              if (status === 'success') {
                  setwebOpeningDetails(response.data.data);
              }else{
                  toast.error(response.data.message);
              }
        })
        .catch((error) => {
          setLoading(false);
          toast.error("There was an error.");
        });
    }

    const capitalizeFirst = str => {
      return str?.charAt(0).toUpperCase() + str?.slice(1);
    };

    const handleChange = (event) => {
      console.log(event.target.checked);
      if (event.target.checked) {
        setReferBy(user_name);
      } else {
        setReferBy('');
      }
    }

    const validateEmployee = async () => {
      let isValid = true;

      let validator = Form.validator({
        name: {
          value: name,
          isRequired: true,
          isNumber:false,
          isEmail: false,
          label: "first name",
        },
        email: {
          value: email,
          isRequired: true,
          isNumber:false,
          isEmail: true,
          label: "email",
        },
        number: {
          value: number,
          isRequired: true,
          minLength:10,
          isNumber:true,
          isEmail: false,
          label: "phone number",
        },
        address: {
          value: address,
          isRequired: true,
          isNumber:false,
          isEmail: false,
          label: "address",
        },
        current_organization: {
          value: current_organization,
          isRequired: true,
          isNumber:false,
          isEmail: false,
          label: "current organization",
        },
        experience: {
          value: experience,
          isRequired: true,
          isNumber:false,
          isEmail: false,
          label: "experience",
        },
        notice_period: {
          value: notice_period,
          isRequired: true,
          isNumber:false,
          isEmail: false,
          label: "notice period",
        },

        
        

      });

      if (validator !== null) {
        setValidate({
          validate: validator.errors,
        });
        //console.log('validate emp : '+JSON.stringify(validate));
        isValid = false;
      } else {
        setValidate({
          validate: "",
        });
        isValid = true;
      }
      return isValid;
    };

    const handleApplyFrm = async (e) => {
      e.preventDefault();
      const validate = await validateEmployee();
      
      if (validate) {
       setLoading(true);
        
        await axios
          .post(CONST.SAVE_OPENINGS_APPLY, {
            id:id,
            name: name,
            email: email,
            phone_number: number,
            address: address,
            current_organization:current_organization,
            experience:experience,
            notice_period:notice_period,
            resume:selectedImage,
            reffered_by:refereBy

          }, {
            headers: {
              'Content-Type' : 'multipart/form-data'
            }
          })
          .then((response) => {
            console.log(response);
            console.log(response.data.status);
            const status = response.data.status;alert(status);
            setLoading(false);
            if (status === 'error') {
              const errorMsg = response.data.errors;
              let isArray = Array.isArray(errorMsg);
              if (isArray) {
                errorMsg.map(element => {
                  toast.error(element.msg);
                })
              }
              else {
                toast.error(errorMsg);
              }
            } else {
              toast.success(response.data.message);
              navigate("/apply/thank-you");
            }
          })
          .catch((error) => {
            setLoading(false);
            toast.error(error);
          });
      }
    }


    return (
        <>

            <Loading
              loading={loading}
              background="rgba(0, 0, 0, 0.5)"
              loaderColor="#fff"
            />
      
            <section className='container'>
                <div className='row'>
                    <div className="col-12">
                        <div className="section-title text-center mb-60 mt-60">
                            <h3 className="mb-15">{webOpeningDetails.title}</h3>
                            <p> <b>Exp Req : </b>
                              {(() => {
                                const test = '"'+webOpeningDetails.exp_required+'"';
                                if (test.includes('years')) { 
                                  return (
                                    webOpeningDetails.exp_required
                                  )
                                }else{
                                  return (
                                    webOpeningDetails.exp_required+' years'
                                  )  
                                }


                              })()}
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className='web-opening-section'>
                <div className='container'>
                    <div className="single-blog-wrapper">
                        <div className="row">
                            <div className="open_position_box">

                                <div className="card openings-card">

                                    <div className="card-body openings-card-body">
                                      <div className="open_wrapper_section">
                                        <div className="openings">Openings : <b>{ webOpeningDetails.total_openings }</b></div>
                                        <div className="openings">Location : <b>{ capitalizeFirst(webOpeningDetails.location) }</b></div>
                                        <div className="recruiter">Email :
                                        <a href="mailto:sales@eluminoustechnologies.com">recruiter@eluminoustechnologies.com</a></div>
                                      </div>

                                      <form onSubmit={handleApplyFrm} enctype="multipart/form-data">
                                        <div className="row">
                                          <div className="col-md-6">
                                              <div className="openings-apply-form-left">
                                                
                                                <div className="form-row">
                                                  <div className="col-md-12 mb-3">
                                                    <div className="input_white_bg">
                                                      <span className="img-icon"><img src={process.env.PUBLIC_URL + "/assets/images/apply-opening-icon/name.webp"} alt="name"/></span>
                                                      <input type="text" onChange={(e) => setName(e.target.value)} className="form-control" id="name" name="name" placeholder="Name"/>
                                                      
                                                    </div>
                                                    <div
                                                      className={`invalid-feedback text-start ${validate.validate && validate.validate.name
                                                        ? "d-block"
                                                        : "d-none"
                                                        }`}
                                                    >
                                                      {validate.validate && validate.validate.name
                                                        ? validate.validate.name[0]
                                                        : ""}{" "}
                                                    </div>{" "}
                                                  </div>
                                                </div>

                                                <div className="form-row">
                                                  <div className="col-md-12 mb-3">
                                                    <div className="input_white_bg">
                                                      <span className="img-icon"><img src={process.env.PUBLIC_URL + "/assets/images/apply-opening-icon/your_email.webp"} alt="name"/></span>
                                                      <input type="email" onChange={(e) => setEmail(e.target.value)} className="form-control" id="email" name="email" placeholder="Your Email *"/>
                                                    </div>
                                                    <div
                                                      className={`invalid-feedback text-start ${validate.validate && validate.validate.email
                                                        ? "d-block"
                                                        : "d-none"
                                                        }`}
                                                    >
                                                      {validate.validate && validate.validate.email
                                                        ? validate.validate.email[0]
                                                        : ""}{" "}
                                                    </div>{" "}
                                                  </div>
                                                </div>                                            
                                                      

                                                <div className="form-row">
                                                  <div className="col-md-12 mb-3">
                                                    <div className="input_white_bg">
                                                      <span><img src={process.env.PUBLIC_URL + "/assets/images/apply-opening-icon/mobile_number.webp"} alt="Mobile Number"/></span>
                                                      <input type="text" onChange={(e) => setNumber(e.target.value)} className="form-control" id="number" placeholder="Mobile Number *" name="number"/>
                                                    </div>
                                                    <div
                                                      className={`invalid-feedback text-start ${validate.validate && validate.validate.number
                                                        ? "d-block"
                                                        : "d-none"
                                                        }`}
                                                    >
                                                      {validate.validate && validate.validate.number
                                                        ? validate.validate.number[0]
                                                        : ""}{" "}
                                                    </div>{" "}
                                                  </div>
                                                </div>

                                                <input type="hidden" className="form-control" id="id" name="id" value="{id}" />
                                                
                                                <div className="form-row">
                                                  <div className="col-md-12 mb-3">
                                                    <div className="input_white_bg">
                                                      <span className="img-icon"><img src={process.env.PUBLIC_URL + "/assets/images/apply-opening-icon/company_name.webp"} alt="name"/></span>
                                                      <input type="text" onChange={(e) => setAddress(e.target.value)} className="form-control" id="address" name="address" placeholder="Address" />
                                                    </div>
                                                    <div
                                                      className={`invalid-feedback text-start ${validate.validate && validate.validate.address
                                                        ? "d-block"
                                                        : "d-none"
                                                        }`}
                                                    >
                                                      {validate.validate && validate.validate.address
                                                        ? validate.validate.address[0]
                                                        : ""}{" "}
                                                    </div>{" "}
                                                  </div>
                                                </div>

                                                {(() => {
                                                    if (user_id == '') {
                                                      return (
                                                        <>
                                                          <div className="form-row">
                                                            <div className="col-md-12 mb-3">
                                                              <div className="input_white_bg">
                                                                <span className="img-icon"><img src={process.env.PUBLIC_URL + "/assets/images/apply-opening-icon/name.webp"} alt="name"/></span>
                                                                <input type="text" onChange={(e) => setReferBy(e.target.value)} className="form-control" id="reffered_by" name="reffered_by" placeholder="Reffered By" />
                                                              </div>
                                                              
                                                            </div>
                                                          </div>
                                                        </>
                                                      )
                                                    }else{
                                                      return (
                                                        <>
                                                        
                                                          <input type="hidden" name="reffered_by_id" value={user_id} />

                                                          <div className="form-row">
                                                            <div className="col-md-12 mb-3">
                                                              <div className="input_white_bg">
                                                                  <input type="checkbox" id="reffered_by" name="reffered_by" 
                                                                   onChange={handleChange} />
                                                                   &nbsp;Referred by me
                                                                
                                                              </div>
                                                              
                                                            </div>
                                                          </div>

                                                        </>
                                                      )  
                                                    }
                                                })()}

                                                

                                              </div>
                                          </div>

                                          <div className="col-md-6">
                                              <div className="openings-apply-form-right">
                                            
                                                <div className="form-row">
                                                  <div className="col-md-12 mb-3">
                                                    <div className="input_white_bg_left">
                                                      <span className="img-icon">
                                                      <img src={process.env.PUBLIC_URL + "/assets/images/apply-opening-icon/job_title.webp"} alt="logo"/></span>

                                                      <input type="text" onChange={(e) => setCurrentOrganization(e.target.value)} className="form-control" id="current_organization" name="current_organization" placeholder="Current Organization" />
                                                    </div>

                                                    <div
                                                      className={`invalid-feedback text-start ${validate.validate && validate.validate.current_organization
                                                        ? "d-block"
                                                        : "d-none"
                                                        }`}
                                                    >
                                                      {validate.validate && validate.validate.current_organization
                                                        ? validate.validate.current_organization[0]
                                                        : ""}{" "}
                                                    </div>{" "}
                                                  </div>
                                                </div>

                                                <div className="form-row">
                                                  <div className="col-md-12 mb-3">
                                                    <div className="input_white_bg_left">
                                                      <span className="img-icon"><img src={process.env.PUBLIC_URL + "/assets/images/apply-opening-icon/relevant.webp"} alt="name"/></span>
                                                      <input type="text" onChange={(e) => setExperience(e.target.value)} className="form-control" id="experience" name="experience" placeholder="Total Experience" />
                                                    </div>

                                                    <div
                                                      className={`invalid-feedback text-start ${validate.validate && validate.validate.experience
                                                        ? "d-block"
                                                        : "d-none"
                                                        }`}
                                                    >
                                                      {validate.validate && validate.validate.experience
                                                        ? validate.validate.experience[0]
                                                        : ""}{" "}
                                                    </div>{" "}
                                                  </div>
                                                </div>

                                                <div className="form-row">
                                                  <div className="col-md-12 mb-3">
                                                    <div className="input_white_bg_left">
                                                      <span className="img-icon"><img src={process.env.PUBLIC_URL + "/assets/images/apply-opening-icon/job_title.webp"} alt="name"/></span>
                                                      <input type="text" onChange={(e) => setNoticePeriod(e.target.value)} className="form-control" id="notice_period" name="notice_period" placeholder="Notice Period" />
                                                    </div>
                                                    <div
                                                      className={`invalid-feedback text-start ${validate.validate && validate.validate.notice_period
                                                        ? "d-block"
                                                        : "d-none"
                                                        }`}
                                                    >
                                                      {validate.validate && validate.validate.notice_period
                                                        ? validate.validate.notice_period[0]
                                                        : ""}{" "}
                                                    </div>{" "}
                                                  </div>
                                                </div>

                                                <div className="form-row">
                                                  <div className="col-md-12 mb-3">
                                                    <div className="input_white_bg_left">
                                                      <label>Upload Resume *</label>
                                                      <span className="img-icon"><img src={process.env.PUBLIC_URL + "/assets/images/apply-opening-icon/upload.webp"} alt="name"/></span>

                                                        <input 
                                                          className="form-control"
                                                          type="file" 
                                                          name="myImage" 
                                                          accept="application/pdf,application/docx,application/doc,"onChange={(event) => {
                                                            setSelectedImage(event.target.files[0]);
                                                          }}
                                                          required
                                                        />
                                                        
                                                    </div>

                                                    
                                                  </div>
                                                </div>

                                                <div className="form-row">
                                                  <div className="col-md-12 brief_project">
                                                    <div className="row">
                                                      <div className="contact-form-error-message">
                                                        <span className="fileError"></span>
                                                      </div>
                                                      <div className="submit_frm text-center col-12">
                                                        

                                                        <button
                                                          type="submit"
                                                          className="mr-3 btn btn-primary"
                                                        >
                                                          Apply
                                                        </button>
                                                        <Link onClick={() => navigate(-1)} className="mr-3 btn btn-outline-primary">Cancel</Link>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                          </div>
                                        </div>
                                      </form>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div></div>
            </section>
        </>
    )
}

export default Details