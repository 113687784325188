import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Loading from "react-fullscreen-loading";

function Single() {
    const [loading, setloaing] = useState(false);
    const params = useParams();
    let slug = params.slug;

    const fetchSingleEvent = async (slug) => {
        if (slug) {
            setloaing(true);
            console.log(slug);
            setloaing(false);
        }
    }
    useEffect(() => {
        fetchSingleEvent(slug);
    }, [slug])
    return (
        <>
            <Loading
                loading={loading}
                background="rgba(0, 0, 0, 0.5)"
                loaderColor="#fff"
            />
            
            <section className="blog-section pt-20">
                <div className="container">
                    <div className="thank-you-banner">
                        <div className="row">
                            <div className="text-center thank_you m-3">
                                <div className="tick_icon text-center col-12">
                                    <img src="https://eluminoustechnologies.com/assets/images/home/xcheck.png.pagespeed.ic.hVmyGIf6Lr.webp" alt="thank you" className="mt-1 mb-4 img-fluid" data-pagespeed-url-hash="21919434" onload="pagespeed.CriticalImages.checkImageForCriticality(this);"/>
                                </div>
                                <h1>
                                    Thank You
                                </h1>
                                <p><strong>We have received your enquiry.</strong> <br/>
                                    One of our IT Consultants will get back to you within 1 Business Day
                                </p>
                                <p className="red_btn call_contact call">
                                    
                                    <Link to={{ pathname: "/careers" }} className="mr-3 btn btn-primary">Back to Openings</Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Single