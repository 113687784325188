import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import * as CONST from "../../../component/config/Constant";
import { AuthContext } from '../../../component/context/AuthContext';
import { toast } from "react-toastify";
import moment from 'moment';
import { differenceInCalendarMonths } from 'date-fns';
function ViewExperience({ closeExperience, editEmpId, firstName, lastName }) { //alert('editEmpId'+editEmpId)
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  const [show, setShow] = useState('show');
  const [empId, setEmpId] = useState(editEmpId);
  const [loading, setLoading] = useState(false);
  const [totalExp, setTotalExp] = useState('');
  const moment = require('moment');

  //console.log('Demo');
  const [expFields, setExpFields] = useState([{
    company_name: '',
    position: '',
    start_date: '',//moment().format('DD-MM-YYYY'),
    end_date: '',//moment().format('DD-MM-YYYY'),
    is_current_job: 0,
  }]);

  let isToken = '';
  let user_id = '';
  if (authUserData) {
    if (authUserData[0]) {
      isToken = authUserData[0].token ? authUserData[0].token : "";
      user_id = authUserData[0].user_id ? authUserData[0].user_id : "";
    }
  }

  useEffect(() => {
    fetchExperience(empId);
  }, [])

  const getMonthDifference = (startDate, endDate) => {
    //alert('sdt'+startDate)
    const monthDifference = moment(new Date(endDate)).diff(new Date(startDate), 'months', true);
    //alert('monthDifference:'+monthDifference);
    const years = ((Math.round(monthDifference / 12)) > 0) ? (Math.round(monthDifference / 12)) + ' yrs' : '';
    const months = ((monthDifference % 12) > 0) ? (monthDifference % 12) + ' mos' : '';
    const total_exp = years + ' ' + months;
    return total_exp;

  }

  const fetchExperience = async (empId) => {
    setLoading(true);
    await axios
      .get(CONST.GET_EXPERIENCE_BY_USERID + empId,
        {
          headers: {
            'Authorization': `Bearer ${isToken}`
          },

        })

      .then((response) => {

        const status = response.data.status;

        if (status === 'success') {
          if (response.data.data != null) {
            setExpFields(response.data.data);
          }
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
    setLoading(false);
  }

  /*   function calculateExperience(startDate, endDate) {
      if (startDate && endDate) {
        function parseDate(dateString) {
          const [day, month, year] = dateString.split('-').map(Number);
          return new Date(year, month - 1, day); // Months are 0-based in JavaScript Date object
        }
  
        const startDateObj = parseDate(startDate);
        const endDateObj = parseDate(endDate);
  
        // Calculate the time difference in milliseconds between the two dates
        const timeDifference = endDateObj - startDateObj;
  
        // Convert the time difference to years, months, and days
        const millisecondsInYear = 1000 * 60 * 60 * 24 * 365.25; // Consider leap years
        const experienceInYears = timeDifference / millisecondsInYear;
  
        // Display only one decimal place without rounding up
        const roundedExperience = Math.floor(experienceInYears * 10) / 10;
  
        return roundedExperience ? parseFloat(roundedExperience) : 0;
      } else {
        return 0;
      }
  
    } */


  function calculateExperience(startDate, endDate) {
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = endDate === 'present' ? new Date() : new Date(endDate);

      const totalMonths = differenceInCalendarMonths(end, start);
      const years = Math.floor(totalMonths / 12);
      const months = totalMonths % 12;

      const experience = years + months / 10; // Display one decimal place

      return experience;
    } else {
      return 0;
    }
  }

  let todayDate = moment().format('DD-MM-YYYY');
  let totalExperience = 0;
  if ((expFields)) {
    expFields.map(exe => {
      if (exe.is_current_job == 1) {
        totalExperience = parseFloat(totalExperience) + calculateExperience(exe.start_date, 'present');
      } else {

        if (exe.start_date && exe.end_date) {
          totalExperience = parseFloat(totalExperience) + calculateExperience(exe.start_date, exe.end_date);
        }

      }

    })
    //totalExperience = calculateTotalExperience(currentCompanyStartDate, pastCompanyStartDate, pastCompanyEndDate);
  }
  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={() => closeExperience('hide')}
        backdrop="static"
        keyboard={false}
        centered
        fullscreen={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Experience</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row pb-5">
            <div className="col-md-6">
              <h5>
                {(firstName && lastName)
                  ? <> {`${firstName && firstName} ${lastName && lastName}`}</>
                  : ''
                }
              </h5>
            </div>
            <div className="col-md-6">
              <label ><b>Total Experience: </b>{(totalExperience)} Year(s)</label>
            </div>
          </div>
          <div className="row">
            {

              expFields.map((data, i) => {


                const { company_name, position, start_date, end_date, is_current_job } = data;


                return (
                  <div className="row">

                    <div className="col-md-6">
                      <div className="form-group mb-3 ">
                        <label className="form-label bold">
                          <h5>{company_name}</h5>
                        </label>
                        {
                          position &&
                          (
                            <label><b>Position:</b> {position}</label>
                          )
                        }

                        <div>
                          {
                            start_date
                            &&
                            (
                              <>
                                <label><b>From: </b> {start_date}</label>
                              </>
                            )

                          }
                          {
                            (is_current_job != "" && end_date != "") &&
                            (
                              <label className='pl-3'><b>To: </b>
                                {
                                  (is_current_job == '1')
                                    ? (<>Present</>)
                                    : (<>{end_date}</>)
                                }
                              </label>
                            )
                          }

                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      {
                        (is_current_job != "") && (
                          <label ><b>Experience: </b>
                            {
                              (is_current_job == '1')
                                ?
                                (
                                  <>
                                    <span> {calculateExperience(start_date, 'present')}  </span>
                                  </>
                                )
                                : (
                                  <>
                                    <span> {calculateExperience(start_date, end_date)}  </span>
                                  </>
                                )
                            }
                            <span> Year(s)</span>
                          </label>
                        )
                      }
                    </div>
                    <hr />
                  </div>
                )
              })
            }
          </div>
        </Modal.Body >

      </Modal >
    </>
  )
}

export default ViewExperience