import { React, useState, useEffect, useContext } from "react";
import "../../../assets/css/table.css";
import Loading from "react-fullscreen-loading";
import { Link } from "react-router-dom";
import HeaderSection from "../banner/HeaderSection";
import DivLoader from "../../../component/utilities/Spinner";
import DataTable from "react-data-table-component";
import axios from "axios";
import { toast } from "react-toastify";
import * as CONST from "../../../component/config/Constant";
import Form from "../../../component/utilities/Forms";
// import editImg from "../../../assets/img/icon/edit.svg";
// import deleteImg from "../../../assets/img/icon/delete.svg";
import { Modal, Button } from "react-bootstrap";
import { AuthContext } from "../../../component/context/AuthContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
// import ReactSwitch from "react-switch";
// import * as SwitchProps from "../../../component/utilities/ReactSwitchReactSwitchProps";
import Moment from "moment";
import DatePicker from "react-datetime";
import Select from "react-select";

function EmployeeAssetsList() {
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  const [fullLoading, setFullLoading] = useState(false);
  const [validate, setValidate] = useState({});
  // const [processing, setProcessing] = useState(false);
  const [employeeAssetsData, setEmployeeAssetsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);

  // const MySwal = withReactContent(Swal);
  const [listLimit, setListLimit] = useState(50);
  // const [allocatedId, setAllocatedId] = useState("");
  const [action, setAction] = useState("");
  const [statusText, setStatusText] = useState([
    {
      id: "0",
      name: "New Request",
    },
    {
      id: "1",
      name: "In process",
    },
    {
      id: "2",
      name: "Allocated",
    },
    {
      id: "3",
      name: "Rejected",
    },
    {
      id: "4",
      name: "Return",
    },
  ]);

  /** For Modalpopup */
  const [show, setShow] = useState(false);

  /* START DATATABLE */
  const [totalRecords, settotalRecords] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [maxPageLimit, setMaxPageLimit] = useState(10);
  const [minPageLimit, setMinPageLimit] = useState(0);

  //Search
  const [searchAssetTypeId, setSearchAssetTypeId] = useState("");
  const [searchAssetStatus, setSearchAssetStatus] = useState("");
  const [searchBrandId, SetSearchBrandID] = useState("");
  const [dataSort, setEmployeeAssetsDataSort] = useState({
    order: "desc",
    order_by: "id",
  });
  const pageNumberLimit = { perPage };

  //For Form
  // const [AssetBrandData, setAssetBrandData] = useState([]);
  const [empAssetId, setEmpAssetId] = useState(0);
  const [assetTypeId, setAssetTypeId] = useState("");
  const [empId, setEmpId] = useState("");
  const [department_id, SetDepartmentID] = useState("");
  const [assetBrandId, setAssetBrandId] = useState("");
  const [assetAllocatedDate, setAssetAllocatedDate] = useState(new Date());
  const [assetStatus, setAssetStatus] = useState("");
  const [assetQuantity, setAssetQuantity] = useState("");
  const [assetTypeList, setAssetTypeList] = useState([]);
  const [assetBrandList, setAssetBrandList] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [assetByBrandList, setAssetByBrandList] = useState([]);

  // const today = new Date();

  let isToken = "";
  if (authUserData) {
    if (authUserData[0]) {
      isToken = authUserData[0].token ? authUserData[0].token : "";
    }
  }

  const [searchParam, setSearchParam] = useState({
    srchAssetTypeName: "",
    srchBrandName: "",
    srchAssetStatus: "",
  });

  useEffect(() => {
    fetchEmployeeAssetsData(searchParam, dataSort, activePage, perPage);
    // fetchBranByAssetTypeId(assetTypeId);
    fetchBrandData();
    fetchAssetTypeData();
    fetchDepartmentData();
  }, [searchParam, dataSort, activePage, listLimit]);

  function handleShow(action, row) {
    if (row && row.id > 0) {
      getDartment(row.empData.department.id);
      getAssetsByBrand(row.brand_id);
      setEmpAssetId(row.id);
      setAssetTypeId(row.asset_type_id);
      setEmpId(row.euser_id);
      SetDepartmentID(row.empData.department.id);
      setAssetBrandId(row.brand_id);
      setAssetAllocatedDate(
        Moment(row.allocated_date, "YYYY-MM-DD").format("DD-MM-YYYY")
      );
      setAssetStatus(row.status);
      setAssetQuantity(row.quantity);
    } else {
      setEmpAssetId(0);
      setAssetTypeId("");
      setEmpId("");
      SetDepartmentID("");
      setAssetBrandId("");
      setAssetAllocatedDate(new Date());
      setAssetStatus("");
      setAssetQuantity("");
    }

    setAction(action);
    setShow(true);
  }

  async function searchEmpAssets() {
    setSearchParam({
      srchAssetTypeName: searchAssetTypeId,
      srchBrandName: searchBrandId,
      srchAssetStatus: searchAssetStatus,
    });

    setEmployeeAssetsDataSort({
      order: "desc",
      order_by: "id",
    });

    setActivePage("1");
  }
  function clearSearch() {
    setSearchAssetTypeId("");
    SetSearchBrandID("");
    setSearchAssetStatus("");
    setSearchParam({
      srchAssetTypeName: "",
      srchBrandName: "",
      srchAssetStatus: "",
    });

    setEmployeeAssetsDataSort({
      order: "desc",
      order_by: "id",
    });

    setActivePage("1");
  }

  const handleClose = () => {
    setShow(false);
    setEmpAssetId(0);
    setAssetTypeId("");
    setEmpId("");
    SetDepartmentID("");
    setAssetBrandId("");
    setAssetAllocatedDate(new Date());
    setAssetStatus("");
    setAssetQuantity("");
  };

  const validateEmployeeAssets = async () => {
    let isValid = true;
    let validator = Form.validator({
      department_id: {
        value: department_id,
        isRequired: true,
        isEmail: false,
        label: "Department",
      },
      empId: {
        value: empId,
        isRequired: true,
        isEmail: false,
        label: "Employee Name",
      },
      assetTypeId: {
        value: assetTypeId,
        isRequired: true,
        isEmail: false,
        label: "Asset Type",
      },
      assetBrandId: {
        value: assetBrandId,
        isRequired: true,
        isEmail: false,
        label: "Brand Name",
      },
      assetQuantity: {
        value: assetQuantity,
        isRequired: true,
        isEmail: false,
        label: "Quantity",
      },
      assetAllocatedDate: {
        value: assetAllocatedDate,
        isRequired: true,
        isEmail: false,
        label: "Date",
      },
      assetStatus: {
        value: assetStatus,
        isRequired: true,
        isEmail: false,
        label: "Status",
      },
    });
    if (validator !== null) {
      setValidate({
        validate: validator.errors,
      });
      isValid = false;
    } else {
      setValidate({
        validate: "",
      });
      isValid = true;
    }
    return isValid;
  };

  //submit allocated form

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validate = await validateEmployeeAssets();
    if (validate) {
      let apiUrl = "";
      let sucessMessage = "";
      if (empAssetId == 0) {
        apiUrl = CONST.ADD_ASSET_TO_EMPLOYEE;
        sucessMessage = "Asset added to employee succesfully";
      } else {
        apiUrl = CONST.UPDATE_ASSET_TO_EMPLOYEE;
        sucessMessage = "Asset updated to employee succesfully";
      }
      await axios({
        method: "post",
        url: apiUrl,
        data: {
          id: empAssetId,
          allocated_by: authUserData[0].user_id,
          user_id: empId,
          asset_type_id: assetTypeId,
          brand_id: assetBrandId,
          quantity: assetQuantity,
          status: assetStatus,
          allocated_date: Moment(assetAllocatedDate, "DD-MM-YYYY").format(
            "YYYY-MM-DD"
          ),
          department_id: department_id,
        },
        headers: {
          Authorization: `Bearer ${isToken}`,
        },
      })
        .then((response) => {
          if (response) {
            if (response.data.status == "error") {
              toast.error(response.data.message);
            } else {
              toast.success(sucessMessage);
              fetchEmployeeAssetsData(
                searchParam,
                dataSort,
                activePage,
                perPage
              );
              handleClose();
            }
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error("Something went wrong. Please try again.");
        });
    }
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  //fetch all Brand by asset name
  // async function fetchBranByAssetTypeId(assetTypeId) {
  //   setLoading(true);
  //   await axios
  //     .post(
  //       CONST.GET_BRANDS_BY_ASSETS,
  //       {
  //         asset_type_id: assetTypeId,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${isToken}`,
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       const status = response.data.status;
  //       if (status === "success") {
  //         setAssetBrandData(response.data.data);
  //       } else {
  //         //   toast.error(response.data.message);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   setLoading(false);
  // }

  function getDartment(id) {
    SetDepartmentID(id);
    fetchEmployeeData(id);
  }

  function getAssetsByBrand(id) {
    setAssetBrandId(id);
    fetchAssetsDataByBrand(id);
  }
  async function fetchEmployeeData(id) {
    // setLoading(true);
    await axios
      .post(
        CONST.DEPT_WISE_USER_LIST,
        {
          department_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${isToken}`,
          },
        }
      )

      .then((response) => {
        const status = response.data.status;
        if (status === "success") {
          const options = response.data.data.map((employee) => ({
            value: employee.id,
            label: `${employee.first_name} ${employee.last_name}`,
          }));
          setEmployeeList(options);
        } else {
          setEmployeeList([]);
        }
      })
      .catch((error) => {});
    setLoading(false);
  }
  async function fetchDepartmentData() {
    setLoading(true);
    await axios
      .get(CONST.GET_DEPARTMENT, {
        headers: {
          Authorization: `Bearer ${isToken}`,
        },
      })

      .then((response) => {
        const status = response.data.status;
        if (status === "success") {
          setDepartmentList(response.data.data);
        } else {
          setDepartmentList([]);
        }
      })
      .catch((error) => {});
    setLoading(false);
  }

  async function fetchAssetsDataByBrand(id) {
    setLoading(true);
    await axios
      .post(
        CONST.GET_ASSETS_BY_BRAND_ID,
        {
          brand_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${isToken}`,
          },
        }
      )

      .then((response) => {
        const status = response.data.status;
        if (status === "success") {
          setAssetByBrandList(response.data.data);
        } else {
          setAssetByBrandList([]);
        }
      })
      .catch((error) => {});
    setLoading(false);
  }

  async function fetchBrandData() {
    setLoading(true);
    await axios
      .get(CONST.GET_ACTIVE_BRAND_LIST, {
        headers: {
          Authorization: `Bearer ${isToken}`,
        },
      })

      .then((response) => {
        const status = response.data.status;
        if (status === "success") {
          setAssetBrandList(response.data.data);
        } else {
          setAssetBrandList([]);
        }
      })
      .catch((error) => {});
    setLoading(false);
  }

  async function fetchAssetTypeData() {
    setLoading(true);
    await axios
      .get(CONST.GET_ACTIVE_ASSET_TYPE_LIST, {
        headers: {
          Authorization: `Bearer ${isToken}`,
        },
      })

      .then((response) => {
        const status = response.data.status;
        if (status === "success") {
          setAssetTypeList(response.data.data);
        } else {
          setAssetTypeList([]);
        }
      })
      .catch((error) => {});
    setLoading(false);
  }
  //fetch all Employee Assets List
  async function fetchEmployeeAssetsData(
    paramSearch,
    paramSort,
    paramPage,
    paramLimit
  ) {
    setLoading(true);

    let searchParams = JSON.stringify({
      limit: paramLimit,
      search_input: paramSearch,
      page_number: paramPage,
      sort: paramSort,
    });
    setLoading(true);
    await axios({
      method: "post",
      url: CONST.GET_EMPLOYEE_ASSETS,
      data: {
        limit: paramLimit,
        search_input: paramSearch,
        page_number: paramPage,
        sort: paramSort,
      },
      headers: {
        Authorization: `Bearer ${isToken}`,
      },
    })
      .then((response) => {
        const status = response.data.status;
        if (status === "success") {
          setEmployeeAssetsData(response.data.data);
          setTotalRows(response.data.count);
          settotalRecords(response.data.count);
        } else {
          setEmployeeAssetsData([]);
          setTotalRows(0);
          settotalRecords(0);
          // toast.error(response.data.message);
        }
      })
      .catch((error) => {});
    setLoading(false);
  }

  const columns = [
    // {
    //   name: "Sr. No",
    //   selector: (row, index) => index + 1,
    //   width: "5%",
    // },
    {
      name: "Name",
      selector: (row) => row.empData.first_name,
      cell: (row) => <CustomName row={row} />,
      // sortable: true,
      // sortField: "first_name",
      width: "30%",
      wrap: true,
    },
    {
      name: "eConnection",
      selector: (row) => row.empData.designation.name,
      cell: (row) => <EConnection row={row} />,
      // sortable: false,
      // sortField: "name",
      wrap: true,
      width: "15%",
    },
    {
      name: "Asset Type",
      selector: (row) => row.empData.designation.name,
      cell: (row) => <AssetName row={row} />,
      // sortable: false,
      // sortField: "name",
      wrap: true,
      width: "15%",
    },
    {
      name: "Allocated By",
      selector: (row) =>
        (row.allocatedByData?.first_name || "") +
        " " +
        (row.allocatedByData?.last_name || ""),
      width: "10%",
    },
    {
      name: "Date",
      selector: (row) =>
        Moment(row?.allocated_date || "").format("DD/MM/YYYY "),
      width: "10%",
    },
    {
      name: "Status",
      selector: (row) =>
        statusText.find((status) => status.id === row.status)?.name || "",
      width: "10%",
    },
    {
      name: "Action",
      width: "10%",
      wrap: true,
      sortable: false,
      cell: (row) => <Action row={row} />,
    },
  ];
  const Action = ({ row }) => (
    <>
      <Link to={"#"} data-id={row} onClick={() => handleShow("Edit", row)}>
        <button className="mr-3 btn btn-primary">Edit</button>
      </Link>
    </>
  );

  const CustomName = ({ row }) => (
    <div>
      <div className="d-flex py-1 align-items-center">
        {row.empData.profile_photo_path && row.empData.profile_photo_path ? (
          <span className="avatar    ">
            <img
              src={CONST.HOST + row.empData.profile_photo_path}
              alt=""
              width={40}
              className="rounded-circle"
            />
          </span>
        ) : (
          <span className="avatar  rounded-circle  ">
            <span className="">
              <Link to={"#"} data-id={row.empData.id}>
                {row.empData.first_name && row.empData.last_name ? (
                  <>
                    {" "}
                    {row.empData.first_name &&
                      row.empData.first_name.substring(0, 1)}
                    {row.empData.last_name &&
                      row.empData.last_name.substring(0, 1)}
                  </>
                ) : (
                  row.empData.name && row.empData.name.substring(0, 2)
                )}
              </Link>
            </span>
          </span>
        )}

        <div className="flex-fill pl-2">
          <div className="font-weight-medium">
            <Link to={"#"} data-id={row.empData.id}>
              {row.empData.first_name && row.empData.last_name ? (
                <>
                  {capitalizeFirstLetter(row.empData.first_name)} {capitalizeFirstLetter(row.empData.last_name)}
                </>
              ) : (
                '-'
              )}
            </Link>
          </div>
          <div className="text-muted">
            <Link
              target="_blank"
              to={`mailto:${row.empData.email}`}
              className="text-muted"
            >
              {row.empData.email}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );

  const EConnection = ({ row }) => (
    <>
      <div className="flex-fill">
        <div className="font-weight-medium">
          {row.empData.designation ? <>{row.empData.designation.name}</> : ""}
        </div>
        <div className="text-muted">
          {row.empData.department ? <>{row.empData.department.name}</> : ""}
        </div>
      </div>
    </>
  );

  const AssetName = ({ row }) => (
    <>
      <div className="flex-fill">
        <div className="font-weight-medium">
          {row.e_asset_type ? <>{row.e_asset_type.name}</> : ""}
        </div>
        <div className="text-muted">
          Quantity -{row.quantity ? <>{row.quantity}</> : "0"}
        </div>
      </div>
    </>
  );

  const handleSort = async (column, sortDirection) => {
    setActivePage(1);
    setSearchParam({
      srchAssetTypeName: "",
      srchBrandName: "",
      srchAssetStatus: "",
    });

    setEmployeeAssetsDataSort({
      order: sortDirection,
      order_by: column.sortField,
    });
    /*  setLoading(false); */
  };
  /*  PAGINATION FUNCTIONS  */
  const handlePageChange = async (page) => {
    setActivePage(parseInt(page));
  };

  const BootyPagination = ({
    rowsPerPage,
    rowCount,
    onChangePage,
    onChangeRowsPerPage, // available but not used here
    currentPage,
  }) => {
    function toPages(pages) {
      const results = [];
      for (let i = 1; i <= pages; i++) {
        results.push(i);
      }
      return results;
    }
    function getNumberOfPages(rowCount, rowsPerPage) {
      return Math.ceil(rowCount / rowsPerPage);
    }

    const handleBackButtonClick = async () => {
      if ((currentPage - 1) % pageNumberLimit === 0) {
        setMaxPageLimit(maxPageLimit - pageNumberLimit);
        setMinPageLimit(minPageLimit - pageNumberLimit);
      }
      onChangePage(currentPage - 1);
    };

    const handleNextButtonClick = async () => {
      if (currentPage + 1 > maxPageLimit) {
        setMaxPageLimit(maxPageLimit + pageNumberLimit);
        setMinPageLimit(minPageLimit + pageNumberLimit);
      }

      onChangePage(currentPage + 1);
    };

    const handlePageNumber = (e) => {
      onChangePage(Number(e.target.value));
    };

    let paginationText = "Page:  " + currentPage + ", Showing ";

    paginationText =
      paginationText +
      ((currentPage - 1) * perPage + 1) +
      " to " +
      perPage * currentPage;
    paginationText = paginationText + " of " + rowCount + " entries;";

    const pages = getNumberOfPages(rowCount, rowsPerPage);
    const pageItems = toPages(pages);
    const nextDisabled = currentPage === pageItems.length;
    const previosDisabled = currentPage === 1;

    const pageNumbers = pageItems.map((page) => {
      if (page <= maxPageLimit && page > minPageLimit) {
        return (
          <li
            key={page}
            id={page}
            className={`page-item ${currentPage === page ? "active" : ""}`}
          >
            <button
              className={`page-link ${currentPage === page ? "active" : ""}`}
              onClick={handlePageNumber}
              value={page}
            >
              {page}
            </button>
          </li>
        );
      } else {
        return null;
      }
    });
    let pageIncrementEllipses = null;
    if (pageItems.length > maxPageLimit) {
      pageIncrementEllipses = (
        <li className="page-item" onClick={handleNextButtonClick}>
          &hellip;
        </li>
      );
    }
    let pageDecremenEllipses = null;
    if (minPageLimit >= 1) {
      pageDecremenEllipses = (
        <li className="page-item" onClick={handleBackButtonClick}>
          &hellip;
        </li>
      );
    }
    return (
      <div className="card-footer d-flex align-items-center">
        <p className="m-0 text-muted">{paginationText}</p>
        <ul className="pagination m-0 ms-auto">
          <li className="page-item">
            <button
              className="page-link"
              onClick={handleBackButtonClick}
              disabled={previosDisabled}
              aria-disabled={previosDisabled}
              aria-label="previous page"
              pagerangedisplayed={10}
            >
              «
            </button>
          </li>
          {pageDecremenEllipses}
          {pageNumbers}
          {pageIncrementEllipses}
          <li className="page-item">
            <button
              className="page-link"
              onClick={handleNextButtonClick}
              disabled={nextDisabled}
              aria-disabled={nextDisabled}
              aria-label="next page"
            >
              »
            </button>
          </li>
        </ul>
      </div>
    );
  };
  /* END DATATABLE */
  const CustomLoader = () => (
    <div style={{ padding: "24px" }}>
      <DivLoader color="primary" />
    </div>
  );
  return (
    <>
      <Loading
        loading={fullLoading}
        background="rgba(0, 0, 0, 0.5)"
        loaderColor="#fff"
      />
      <HeaderSection
        title="Manage"
        heading="Employee Assets"
        addButton={() => handleShow("Add", 0)}
        addLink="#"
        addLinkName="Add Assets"
        linktype="add"
      />
      <div className="page-body">
        <div className="container-xl">
          <div className="row row-cards">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Employee Assets List</h3>
                </div>
                <div className="card-body border-bottom py-3">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group mb-3 ">
                        <label className="form-label required">
                          Assets Type
                        </label>
                        <div>
                          <select
                            name="searchAssetTypeId"
                            className={`form-select form-control`}
                            id="searchAssetTypeId"
                            placeholder="Select Asset Type"
                            onChange={(e) =>
                              setSearchAssetTypeId(e.target.value)
                            }
                            value={searchAssetTypeId}
                          >
                            <option value="">Select Asset Type</option>
                            {assetTypeList &&
                              assetTypeList.map((element) => (
                                <option key={element.id} value={element.id}>
                                  {element.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3 ">
                        <label className="form-label required">Brand</label>
                        <div>
                          <select
                            className={`form-select form-control`}
                            name="searchBrandId"
                            id="searchBrandId"
                            placeholder="Select Brand"
                            onChange={(e) => SetSearchBrandID(e.target.value)}
                            value={searchBrandId}
                          >
                            <option value="">Select Brand</option>
                            {assetBrandList &&
                              assetBrandList.map((element) => (
                                <option key={element.id} value={element.id}>
                                  {element.name}
                                </option>
                              ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* <div className="col-md-3">
                      <div className="form-group mb-3 ">
                        <label className="form-label required">
                          Employee Name
                        </label>
                        <div>
                          <input
                            type="text"
                            name="searchEmp"
                            id="searchEmp"
                            className={`form-control`}
                            placeholder="Enter Employee Name"
                            onChange={(e) => setSearchEmp(e.target.value)}
                            value={searchEmp}
                          />
                        </div>
                      </div>
                    </div> */}

                    <div className="col-md-2">
                      <div className="mb-3">
                        <label className="form-label required">Status</label>

                        <select
                          className="form-select form-control"
                          name="searchAssetStatus"
                          id="searchAssetStatus"
                          placeholder="Select status"
                          onChange={(e) => setSearchAssetStatus(e.target.value)}
                          value={searchAssetStatus}
                        >
                          <option value="">Select Status</option>
                          {statusText &&
                            statusText.map((element) => (
                              <option key={element.id} value={element.id}>
                                {element.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group mb-3 ">
                        <label className="form-label">&nbsp;</label>
                        <div>
                          <button
                            type="button"
                            name="btnSearch"
                            id="btnSearch"
                            className="btn btn-blue d-none d-sm-inline-block ml-3"
                            onClick={searchEmpAssets}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon icon-tabler icon-tabler-search"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <circle cx="10" cy="10" r="7"></circle>
                              <line x1="21" y1="21" x2="15" y2="15"></line>
                            </svg>
                            Search
                          </button>
                          &nbsp;
                          <button
                            type="button"
                            name="btnReset"
                            id="btnReset"
                            className="btn btn-outline-primary "
                            onClick={clearSearch}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon icon-tabler icon-tabler-x"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <line x1="18" y1="6" x2="6" y2="18"></line>
                              <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={employeeAssetsData}
                    progressPending={loading}
                    progressComponent={<CustomLoader />}
                    pagination="true"
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangePage={handlePageChange}
                    paginationPerPage={perPage}
                    paginationComponent={BootyPagination}
                    sortServer
                    onSort={handleSort}
                  />
                </div>
                <div className="card-footer d-flex align-items-center">
                  <p className="m-0 text-muted">
                    Showing
                    <span>{totalRecords}</span> entries
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title> Allocated</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form method="POST" onSubmit={handleSubmit} autoComplete={"off"}>
            <div className="row">
              <div className="col-md-6">
                <label className="form-label required">Department</label>
                <div>
                  <select
                    className={`form-select form-control ${
                      validate.validate && validate.validate.department_id
                        ? "is-invalid "
                        : ""
                    }`}
                    name="department_id"
                    id="department_id"
                    placeholder="Select Department"
                    onChange={(e) => getDartment(e.target.value)}
                    value={department_id}
                  >
                    <option value="">Select Department</option>
                    {departmentList &&
                      departmentList.map((element) => (
                        <option key={element.id} value={element.id}>
                          {element.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <label className="form-label required">Employee Name</label>
                <div>
                  {/* <select
                    className={`form-select form-control ${
                      validate.validate && validate.validate.empId
                        ? "is-invalid "
                        : ""
                    }`}
                    name="empId"
                    id="empId"
                    placeholder="Select Employee"
                    disabled={!department_id}
                    onChange={(e) => setEmpId(e.target.value)}
                    value={empId}
                  >
                    <option value="">Select Employee</option>
                    {employeeList &&
                      employeeList.map((element) => (
                        <option key={element.id} value={element.id}>
                          {element.first_name + " " + element.last_name}
                        </option>
                      ))}
                  </select> */}
                  {/* <Select
                    className="basic-single"
                    classNamePrefix="select"
                    defaultValue={employeeList}
                    isClearable={true}
                    isSearchable={true}
                    name="color"
                    options={employeeList}
                  /> */}
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isDisabled={!department_id}
                    isClearable={true}
                    isSearchable={true}
                    name="empId"
                    options={employeeList}
                    value={employeeList.find(
                      (option) => option.value === empId
                    )}
                    onChange={(selectedOption) =>
                      setEmpId(selectedOption ? selectedOption.value : null)
                    }
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label className="form-label required">Brand Name</label>
                <div>
                  <select
                    className={`form-select form-control ${
                      validate.validate && validate.validate.assetBrandId
                        ? "is-invalid "
                        : ""
                    }`}
                    name="assetBrandId"
                    id="assetBrandId"
                    placeholder="Select Brand Name"
                    onChange={(e) => getAssetsByBrand(e.target.value)}
                    value={assetBrandId}
                  >
                    <option value="">Select Brand</option>
                    {assetBrandList &&
                      assetBrandList.map((element) => (
                        <option key={element.id} value={element.id}>
                          {element.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <label className="form-label required">Asset Type</label>
                <div>
                  <select
                    className={`form-select form-control ${
                      validate.validate && validate.validate.assetTypeId
                        ? "is-invalid "
                        : ""
                    }`}
                    name="assetTypeId"
                    id="assetTypeId"
                    placeholder="Select Asset Type"
                    disabled={!assetBrandId}
                    onChange={(e) => setAssetTypeId(e.target.value)}
                    value={assetTypeId}
                  >
                    <option value="">Select Asset Type</option>
                    {assetByBrandList &&
                      assetByBrandList.map((element) => (
                        <option
                          key={element.asset_type_id}
                          value={element.asset_type_id}
                        >
                          {element.e_asset_type
                            ? element.e_asset_type.name
                            : "-"}
                        </option>
                      ))}
                  </select>
                </div>
              </div>

              <div className="col-md-6">
                <label className="form-label required">Quantity</label>
                <input
                  type="number"
                  name="assetQuantity"
                  id="assetQuantity"
                  className={`form-control ${
                    validate.validate && validate.validate.assetQuantity
                      ? "is-invalid"
                      : ""
                  }`}
                  min={0}
                  placeholder="Enter Assets Quantity"
                  onChange={(e) => setAssetQuantity(e.target.value)}
                  value={assetQuantity}
                />
              </div>
              <div className="col-md-6">
                <div className="form-group mb-3">
                  <label className="form-label required">Date</label>
                  <div>
                    <DatePicker
                      name="assetAllocatedDate"
                      id="assetAllocatedDate"
                      value={assetAllocatedDate}
                      selected={assetAllocatedDate}
                      dateFormat="DD-MM-YYYY"
                      timeFormat=""
                      className={`${
                        validate.validate &&
                        validate.validate.assetAllocatedDate
                          ? "is-invalid "
                          : ""
                      }`}
                      onChange={(e) => setAssetAllocatedDate(e)}
                    />
                  </div>
                  <div
                    className={`invalid-feedback text-start 
                                        ${
                                          validate.validate &&
                                          validate.validate.assetAllocatedDate
                                            ? "d-block"
                                            : "d-none"
                                        }`}
                  >
                    {validate.validate && validate.validate.assetAllocatedDate
                      ? validate.validate.assetAllocatedDate
                      : ""}
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <label className="form-label required">Status</label>

                <select
                  className={`form-select form-control ${
                    validate.validate && validate.validate.assetStatus
                      ? "is-invalid "
                      : ""
                  }`}
                  name="status"
                  id="status"
                  placeholder="Select status"
                  onChange={(e) => setAssetStatus(e.target.value)}
                  value={assetStatus}
                >
                  <option value="">Select Status</option>
                  {statusText &&
                    statusText.map((element) => (
                      <option key={element.id} value={element.id}>
                        {element.name}
                      </option>
                    ))}
                </select>
              </div>
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EmployeeAssetsList;
