import { React, useState, useEffect, useContext } from "react";
import "../../../assets/css/table.css";
import Loading from "react-fullscreen-loading";
import { Link } from "react-router-dom";
import HeaderSection from "../banner/HeaderSection";
import DivLoader from "../../../component/utilities/Spinner";
import DataTable from "react-data-table-component";
import axios from "axios";
import { toast } from "react-toastify";
import * as CONST from "../../../component/config/Constant";
import Form from "../../../component/utilities/Forms";
import editImg from "../../../assets/img/icon/edit.svg";
import deleteImg from "../../../assets/img/icon/delete.svg";
import { Modal, Button } from "react-bootstrap";
import { AuthContext } from "../../../component/context/AuthContext";
import Swal from "sweetalert2";
import DatePicker from "react-datetime";
import moment from "moment";

function HolidayList() {
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  const [fullLoading, setFullLoading] = useState(false);
  const [validate, setValidate] = useState({});
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [listLimit, setListLimit] = useState(50);
  const [action, setAction] = useState("");
  /** For Modalpopup */
  const [show, setShow] = useState(false);
  /* START DATATABLE */
  const [totalRecords, settotalRecords] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [maxPageLimit, setMaxPageLimit] = useState(10);
  const [minPageLimit, setMinPageLimit] = useState(0);
  //Search
  const [srchYear, setSrchYear] = useState(moment().format("YYYY"));

  const [holidayData, setholidayData] = useState([]);

  //For Form
  const [holiday, setHoliday] = useState();
  const [holidayDate, setHolidayDate] = useState(new Date());
  const [holidayDay, setEventDay] = useState("");
  const [holidayId, setHolidayId] = useState(0);

  const [dataSort, setHolidayDataSort] = useState({
    order: "desc",
    order_by: "id",
  });
  const pageNumberLimit = { perPage };

  let isToken = "";
  if (authUserData) {
    if (authUserData[0]) {
      isToken = authUserData[0].token ? authUserData[0].token : "";
    }
  }

  const [searchParam, setSearchParam] = useState({
    searchYear: srchYear,
  });

  useEffect(() => {
    fetchHolidayData(searchParam, dataSort, activePage, perPage);
  }, [searchParam, dataSort, activePage, listLimit, srchYear]);

  function handleShow(action, data) {
    if (data && data.holiday_id !== 0) {
      setHoliday(data.holiday_name);
      setHolidayDate(
        moment(data.holiday_date, "YYYY-MM-DD").format("DD-MM-YYYY")
      );
      handleHolidayDate(
        moment(data.holiday_date, "YYYY-MM-DD").format("DD-MM-YYYY"),
        "0"
      );
      setHolidayId(data.holiday_id);
      setEventDay(data.holiday_day);
    } else {
      setHolidayId(0);
      handleHolidayDate(holidayDate, "0");
    }
    setAction(action);
    setShow(true);
  }

  async function holidaySearch() {
    setSearchParam({
      searchYear: srchYear,
    });

    setHolidayDataSort({
      order: "desc",
      order_by: "id",
    });

    setActivePage("1");
  }

  function clearSearch() {
    setSrchYear("");

    setSearchParam({
      searchYear: "",
    });

    setHolidayDataSort({
      order: "desc",
      order_by: "id",
    });

    setActivePage("1");
  }

  const handleClose = () => {
    setShow(false);
    setHoliday("");
    setHolidayDate(new Date());
    setHolidayId(0);
    setEventDay("");
  };

  const handleHolidayDate = (date, status) => {
    if (status == 1) {
      setHolidayDate(date);
    }

    const dateString = new Date(date);
    const dayOfWeek = dateString.getUTCDay();
    const daysOfWeek = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const dayName = daysOfWeek[dayOfWeek];

    setEventDay(dayName);
  };

  const searchYear = (val) => {
    if (val != "") {
      setSrchYear(moment(val).format("YYYY"));
    } else {
      setSrchYear("");
    }
  };
  function deleteHandler(id) {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this holiday?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        if (id > 0) {
          setLoading(true);
          axios
            .delete(CONST.DELETE_HOLIDAY, {
              headers: {
                Authorization: `Bearer ${isToken}`,
              },
              data: {
                id: id,
              },
            })
            .then((response) => {
              if (response) {
                if (response.data.status == "error") {
                  toast.error(response.data.message);
                } else {
                  toast.success(response.data.message);
                  fetchHolidayData(searchParam, dataSort, activePage, perPage);
                }
              }
            })
            .catch((error) => {
              setLoading(false);
              toast.error("Something went wrong. Please try again." + error);
            });
          setLoading(false);
        }
      }
    });
  }

  //fetch all holiday details

  async function fetchHolidayData(
    paramSearch,
    paramSort,
    paramPage,
    paramLimit
  ) {
    setLoading(true);

    await axios
      .post(
        CONST.GET_HOLIDAY_LIST,
        {
          year: srchYear,
        },
        {
          headers: {
            Authorization: `Bearer ${isToken}`,
          },
        }
      )

      .then((response) => {
        const status = response.data.status;
        if (status === "success" && response.data.data) {
          setTotalRows(response.data.count);
          settotalRecords(response.data.count);
          setholidayData(response.data.data);
        } else {
          setTotalRows(0);
          settotalRecords(0);
          setholidayData([]);
          //   toast.error(response.data.message);
        }
      })
      .catch((error) => {});
    setLoading(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = true;
    let validator = Form.validator({
      holiday: {
        value: holiday,
        isRequired: true,
        label: "holiday",
      },
      holidayDate: {
        value: holidayDate,
        isRequired: true,
        label: "HolidayDate",
      },
      holidayDay: {
        value: holidayDay,
        isRequired: true,
        label: "HolidayDay",
      },
    });

    if (validator !== null) {
      setValidate({
        validate: validator.errors,
      });
      isValid = false;
    }

    if (isValid) {
      let apiUrl = "";
      let sucessMessage = "";
      let strMethod = "";
      if (holidayId == 0) {
        apiUrl = CONST.ADD_HOLIDAY;
        strMethod = "post";
        sucessMessage = "Holiday added succesfully";
      } else {
        apiUrl = CONST.UPDATE_HOLIDAY;
        strMethod = "put";
        sucessMessage = "Holiday updated succesfully";
      }

      await axios({
        method: strMethod,
        url: apiUrl,
        data: {
          name: holiday,
          day: holidayDay,
          date: moment(holidayDate, "DD-MM-YYYY").format("YYYY-MM-DD"),
          status: 1,
          id: holidayId,
        },
        headers: {
          Authorization: `Bearer ${isToken}`,
        },
      })
        .then((response) => {
          if (response) {
            if (response.data.status == "error") {
              toast.error(response.data.message);
            } else {
              setHoliday("");
              setHolidayDate(new Date());
              setHolidayId(0);
              setEventDay("");
              toast.success(sucessMessage);
              fetchHolidayData(searchParam, dataSort, activePage, perPage);
              handleClose();
            }
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error("Something went wrong. Please try again.");
        });
    }
  };

  const columns = [
    {
      name: "Sr. No",
      selector: (row, index) => index + 1,
      width: "10%",
    },
    {
      name: "Date",
      selector: (row) =>
        moment(row?.holiday_date || "").format("DD, MMM. YYYY "),
      width: "15%",
    },
    {
      name: "Day",
      selector: (row) => row.holiday_day,
      width: "15%",
    },
    {
      name: "Holiday",
      selector: (row) => row.holiday_name,
      width: "25%",
    },

    {
      name: "Action",
      width: "20%",
      wrap: true,
      sortable: false,
      cell: (row) => <Action row={row} />,
    },
  ];

  const Action = ({ row }) => (
    <>
      <Link to={"#"} data-id={row.id} onClick={() => handleShow("Edit", row)}>
        <img src={editImg} alt="editAssets" width={32} className="cursor" />
      </Link>
      <Link onClick={() => deleteHandler(row.holiday_id)}>
        <img src={deleteImg} alt="deleteImg" width={32} className="cursor" />
      </Link>
    </>
  );

  const handleSort = async (column, sortDirection) => {
    setActivePage(1);
    setSearchParam({
      search: "no",
      name: "",
      status: "",
    });

    setHolidayDataSort({
      order: sortDirection,
      order_by: column.sortField,
    });
    /*  setLoading(false); */
  };
  /*  PAGINATION FUNCTIONS  */
  const handlePageChange = async (page) => {
    setActivePage(parseInt(page));
  };

  const BootyPagination = ({
    rowsPerPage,
    rowCount,
    onChangePage,
    onChangeRowsPerPage, // available but not used here
    currentPage,
  }) => {
    function toPages(pages) {
      const results = [];
      for (let i = 1; i <= pages; i++) {
        results.push(i);
      }
      return results;
    }
    function getNumberOfPages(rowCount, rowsPerPage) {
      return Math.ceil(rowCount / rowsPerPage);
    }

    const handleBackButtonClick = async () => {
      if ((currentPage - 1) % pageNumberLimit === 0) {
        setMaxPageLimit(maxPageLimit - pageNumberLimit);
        setMinPageLimit(minPageLimit - pageNumberLimit);
      }
      onChangePage(currentPage - 1);
    };

    const handleNextButtonClick = async () => {
      if (currentPage + 1 > maxPageLimit) {
        setMaxPageLimit(maxPageLimit + pageNumberLimit);
        setMinPageLimit(minPageLimit + pageNumberLimit);
      }

      onChangePage(currentPage + 1);
    };

    const handlePageNumber = (e) => {
      onChangePage(Number(e.target.value));
    };

    let paginationText = "Page:  " + currentPage + ", Showing ";

    paginationText =
      paginationText +
      ((currentPage - 1) * perPage + 1) +
      " to " +
      perPage * currentPage;
    paginationText = paginationText + " of " + rowCount + " entries;";

    const pages = getNumberOfPages(rowCount, rowsPerPage);
    const pageItems = toPages(pages);
    const nextDisabled = currentPage === pageItems.length;
    const previosDisabled = currentPage === 1;

    const pageNumbers = pageItems.map((page) => {
      if (page <= maxPageLimit && page > minPageLimit) {
        return (
          <li
            key={page}
            id={page}
            className={`page-item ${currentPage === page ? "active" : ""}`}
          >
            <button
              className={`page-link ${currentPage === page ? "active" : ""}`}
              onClick={handlePageNumber}
              value={page}
            >
              {page}
            </button>
          </li>
        );
      } else {
        return null;
      }
    });
    let pageIncrementEllipses = null;
    if (pageItems.length > maxPageLimit) {
      pageIncrementEllipses = (
        <li className="page-item" onClick={handleNextButtonClick}>
          &hellip;
        </li>
      );
    }
    let pageDecremenEllipses = null;
    if (minPageLimit >= 1) {
      pageDecremenEllipses = (
        <li className="page-item" onClick={handleBackButtonClick}>
          &hellip;
        </li>
      );
    }
    return (
      <div className="card-footer d-flex align-items-center">
        <p className="m-0 text-muted">{paginationText}</p>
        <ul className="pagination m-0 ms-auto">
          <li className="page-item">
            <button
              className="page-link"
              onClick={handleBackButtonClick}
              disabled={previosDisabled}
              aria-disabled={previosDisabled}
              aria-label="previous page"
              pagerangedisplayed={10}
            >
              «
            </button>
          </li>
          {pageDecremenEllipses}
          {pageNumbers}
          {pageIncrementEllipses}
          <li className="page-item">
            <button
              className="page-link"
              onClick={handleNextButtonClick}
              disabled={nextDisabled}
              aria-disabled={nextDisabled}
              aria-label="next page"
            >
              »
            </button>
          </li>
        </ul>
      </div>
    );
  };
  /* END DATATABLE */
  const CustomLoader = () => (
    <div style={{ padding: "24px" }}>
      <DivLoader color="primary" />
    </div>
  );
  return (
    <>
      <Loading
        loading={fullLoading}
        background="rgba(0, 0, 0, 0.5)"
        loaderColor="#fff"
      />
      <HeaderSection
        title="Manage"
        heading="Holiday"
        addButton={() => handleShow("Add", 0)}
        addLink="#"
        addLinkName="Add Holiday"
        linktype="add"
      />
      <div className="page-body">
        <div className="container-xl">
          <div className="row row-cards">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Listing</h3>
                </div>
                <div className="card-body border-bottom py-3">
                  <div className="row">
                    <div className="col-md-2">
                      <div className="mb-2">
                        <label className="form-label required">Year</label>
                        {/* <select
                          className="form-select form-control"
                          value={srchYear}
                          onChange={(e) => searchYear(e)}
                        >
                          <option value="">Select Year</option>
                          {Array.from({ length: 8 }, (_, index) => {
                            const startYear =
                              new Date().getFullYear() - 5 + index;
                            const endYear = startYear + 1;
                            const formattedYear = `${startYear}-${endYear}`;

                            return (
                              <option key={formattedYear} value={formattedYear}>
                                {formattedYear}
                              </option>
                            );
                          })}
                        </select> */}
                        <DatePicker
                          name="srchYear"
                          value={srchYear}
                          selected={srchYear}
                          dateFormat="YYYY"
                          timeFormat=""
                          className=""
                          onChange={(e) => searchYear(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={holidayData}
                    progressPending={loading}
                    progressComponent={<CustomLoader />}
                    pagination="true"
                    paginationServer
                    paginationTotalRows={totalRows}
                    //onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationPerPage={perPage}
                    paginationComponent={BootyPagination}
                    sortServer
                    onSort={handleSort}
                  />
                </div>
                <div className="card-footer d-flex align-items-center">
                  <p className="m-0 text-muted">
                    Showing {/* <span>1</span> to <span>8</span> of{" "} */}
                    <span>{totalRecords}</span> entries
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{action} Holiday</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form method="POST" onSubmit={handleSubmit} autoComplete={"off"}>
            <div className="row">
              <div className="col-md-6">
                <label className="form-label required">Holiday Date</label>
                <div>
                  <DatePicker
                    name="holidayDate"
                    id="holidayDate"
                    value={holidayDate}
                    selected={holidayDate}
                    dateFormat="DD-MM-YYYY"
                    timeFormat=""
                    className={`${
                      validate.validate && validate.validate.holidayDate
                        ? "is-invalid "
                        : ""
                    }`}
                    onChange={(e) => handleHolidayDate(e, 1)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label className="form-label required">Holiday Day</label>
                <div>
                  <input
                    type="text"
                    name="holidayDay"
                    id="holidayDay"
                    className={`form-control ${
                      validate.validate && validate.validate.holidayDay
                        ? "is-invalid"
                        : ""
                    }`}
                    placeholder="Enter Holiday Day"
                    onChange={(e) => setEventDay(e.target.value)}
                    value={holidayDay}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <label className="form-label required">Holiday</label>
                <div>
                  <input
                    type="text"
                    name="holiday"
                    id="holiday"
                    className={`form-control ${
                      validate.validate && validate.validate.holiday
                        ? "is-invalid"
                        : ""
                    }`}
                    placeholder="Enter Holiday"
                    onChange={(e) => setHoliday(e.target.value)}
                    value={holiday}
                  />
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default HolidayList;
