import { React, useState, useEffect, useContext } from "react";
import "../../../assets/css/table.css";
import Loading from "react-fullscreen-loading";
import { Link } from "react-router-dom";
import HeaderSection from "../banner/HeaderSection";
import DivLoader from "../../../component/utilities/Spinner";
import DataTable from "react-data-table-component";
import axios from "axios";
import { toast } from "react-toastify";
import * as CONST from "../../../component/config/Constant";
import Form from "../../../component/utilities/Forms";
import editImg from "../../../assets/img/icon/edit.svg";
import deleteImg from "../../../assets/img/icon/delete.svg";
import { Modal, Button } from "react-bootstrap";
import { AuthContext } from "../../../component/context/AuthContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ReactSwitch from "react-switch";
import * as SwitchProps from "../../../component/utilities/ReactSwitchReactSwitchProps";
function AssetsTypeList() {
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  const [fullLoading, setFullLoading] = useState(false);
  const [validate, setValidate] = useState({});
  const [processing, setProcessing] = useState(false);
  const [assetsTypeData, setAssetsTypeData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const MySwal = withReactContent(Swal);
  const [listLimit, setListLimit] = useState(50);
  const [action, setAction] = useState("");
  /** For Modalpopup */
  const [show, setShow] = useState(false);
  /* START DATATABLE */
  const [totalRecords, settotalRecords] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [maxPageLimit, setMaxPageLimit] = useState(10);
  const [minPageLimit, setMinPageLimit] = useState(0);
  //Search
  const [searchAssetTypeName, setSearchAssetsTypeName] = useState("");
  const [searchAssetsStatus, setSearchAssetsStatus] = useState("");

  const [dataSort, setAssetsTypeDataSort] = useState({
    order: "desc",
    order_by: "id",
  });
  const pageNumberLimit = { perPage };
  //For Form
  const [assetsName, setAssetsName] = useState("");
  const [status, setStatus] = useState(false);
  const [assetsTypeId, setAssetsTypeId] = useState(0);

  let isToken = "";
  if (authUserData) {
    if (authUserData[0]) {
      isToken = authUserData[0].token ? authUserData[0].token : "";
    }
  }

  const [searchParam, setSearchParam] = useState({
    srchAssetTypeName: searchAssetTypeName,
    srchAssetTypeStatus: searchAssetsStatus,
  });

  useEffect(() => {
    fetchAssetsData(searchParam, dataSort, activePage, perPage);
  }, [searchParam, dataSort, activePage, listLimit]);

  function handleShow(action, assetsTypeId) {
    const assets_id = assetsTypeId ? assetsTypeId : 0;
    setAssetsName("");
    setAssetsTypeId("");
    setStatus("");

    if (assets_id !== 0) {
      fetchAssetsById(assets_id);
    }
    setAction(action);
    setShow(true);
  }

  async function assetsSearch() {
    setSearchParam({
      srchAssetTypeName: searchAssetTypeName,
      srchAssetTypeStatus: searchAssetsStatus,
    });

    setAssetsTypeDataSort({
      order: "desc",
      order_by: "id",
    });

    setActivePage("1");
  }

  function clearSearch() {
    setSearchAssetsTypeName("");
    setSearchAssetsStatus("");

    setSearchParam({
      srchAssetTypeName: "",
      srchAssetTypeStatus: "",
    });

    setAssetsTypeDataSort({
      order: "desc",
      order_by: "id",
    });

    setActivePage("1");
  }

  const handleClose = () => {
    setShow(false);
    setAssetsTypeId("");
    setAssetsName("");
    setStatus("");
  };

  function deleteHandler(asset_type_id) {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this assets type?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        if (asset_type_id > 0) {
          setLoading(true);
          axios
            .delete(CONST.DELETE_ASSETS_TYPE, {
              headers: {
                Authorization: `Bearer ${isToken}`,
              },
              data: {
                asset_type_id: asset_type_id,
              },
            })
            .then((response) => {
              if (response) {
                if (response.data.status == "error") {
                  toast.error(response.data.message);
                } else {
                  toast.success(response.data.message);
                  fetchAssetsData(searchParam, dataSort, activePage, perPage);
                }
              }
            })
            .catch((error) => {
              setLoading(false);
              toast.error("Something went wrong. Please try again." + error);
            });
          setLoading(false);
        }
      }
    });
  }

  //fetch all Assets data by id
  async function fetchAssetsById(assetsTypeId) {
    setLoading(true);
    await axios
      .get(CONST.GET_ASSETS_TYPE_BY_ID + `${assetsTypeId}`, {
        headers: {
          Authorization: `Bearer ${isToken}`,
        },
      })
      .then((response) => {
        const status = response.data.status;

        if (status === "success") {
          let assetsData = response.data.data[0] ? response.data.data[0] : [];
          setAssetsName(assetsData["name"] ? assetsData["name"] : "");
          setAssetsTypeId(assetsData["id"] ? assetsData["id"] : "");
          setStatus(assetsData["is_active"] ? assetsData["is_active"] : "");
        }
      })
      .catch((error) => {
        alert(error);
      });
    setLoading(false);
  }

  //fetch all assets details
  async function fetchAssetsData(
    paramSearch,
    paramSort,
    paramPage,
    paramLimit
  ) {
    setLoading(true);
    await axios
      .post(
        CONST.GET_ASSETS_TYPE_LIST,
        {
          limit: paramLimit,
          search_input: paramSearch,
          page_number: paramPage,
          sort: paramSort,
        },
        {
          headers: {
            Authorization: `Bearer ${isToken}`,
          },
        }
      )

      .then((response) => {
        const status = response.data.status;
        if (status === "success") {
          setAssetsTypeData(response.data.data);
          setTotalRows(response.data.count);
          settotalRecords(response.data.count);
        } else {
          setAssetsTypeData([]);
          setTotalRows(0);
          settotalRecords(0);
          // toast.error(response.data.message);
        }
      })
      .catch((error) => {});
    setLoading(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    let isValid = true;

    let validator = Form.validator({
      assetsName: {
        value: assetsName,
        isRequired: true,
        label: "Assets Name",
      },
    });

    if (validator !== null) {
      setValidate({
        validate: validator.errors,
      });
      isValid = false;
    }

    if (isValid) {
      let apiUrl = "";
      let sucessMessage = "";
      let strMethod = "";
      if (assetsTypeId == 0) {
        apiUrl = CONST.ADD_ASSETS_TYPE;
        strMethod = "post";
        sucessMessage = "Assets type added succesfully";
      } else {
        apiUrl = CONST.EDIT_ASSETS_TYPE;
        strMethod = "put";
        sucessMessage = "Assets type updated succesfully";
      }

      await axios({
        method: strMethod,
        url: apiUrl,
        data: {
          name: assetsName,
          asset_type_id: assetsTypeId,
          is_active: status == "1" || status == "" ? "1" : "0",
        },
        headers: {
          Authorization: `Bearer ${isToken}`,
        },
      })
        .then((response) => {
          if (response) {
            if (response.data.status == "error") {
              toast.error("Something went wrong. Please try again.");
            } else {
              toast.success(sucessMessage);
              fetchAssetsData(searchParam, dataSort, activePage, perPage);
              handleClose();
            }
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error("Something went wrong. Please try again.");
        });
    }
  };
  //this is remaining
  const handleChangeStatus = (index, status_value, assets_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to change the status?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        axios
          .patch(
            CONST.UPDATE_ASSETS_TYPE_STATUS,
            {
              asset_type_id: assets_id,
              status: status_value == "0" ? "1" : "0",
            },
            {
              headers: {
                Authorization: `Basic ${isToken}`,
              },
            }
          )
          .then((response) => {
            const status = response.data.status;
            if (status == "success") {
              toast.success(response.data.message);
            } else {
              toast.error(response.data.message);
            }
          })
          .catch((error) => {
            toast.error("There was an error.");
          });
        setLoading(false);
        const newData = [...assetsTypeData];
        newData[index].is_active = status_value == 0 ? 1 : 0;
        setAssetsTypeData(newData);
      }
    });
  };

  const columns = [
    {
      name: "Sr. No",
      selector: (row, index) => index + 1,
      width: "10%",
    },
    {
      name: "Assets Name",
      selector: (row) => row.name,
      width: "25%",
    },

    {
      name: "Status",
      selector: (row) => "Active",
      width: "15%",
      selector: (row, index) => (
        <ReactSwitch
          onColor={SwitchProps.onColor}
          onHandleColor={SwitchProps.onHandleColor}
          handleDiameter={SwitchProps.handleDiameter}
          uncheckedIcon={SwitchProps.uncheckedIcon}
          checkedIcon={SwitchProps.checkedIcon}
          boxShadow={SwitchProps.boxShadow}
          activeBoxShadow={SwitchProps.activeBoxShadow}
          height={SwitchProps.height}
          width={SwitchProps.width}
          className={SwitchProps.className}
          checked={row.is_active == 1 ? true : false}
          onChange={() => {
            handleChangeStatus(index, row.is_active, row.id);
          }}
        />
      ),
    },
    {
      name: "Action",
      width: "20%",
      wrap: true,
      sortable: false,
      cell: (row) => <Action row={row} />,
    },
  ];

  const Action = ({ row }) => (
    <>
      <Link
        to={"#"}
        data-id={row.id}
        onClick={() => handleShow("Edit", row.id)}
      >
        <img src={editImg} alt="editAssets" width={32} className="cursor" />
      </Link>
      <Link onClick={() => deleteHandler(row.id)}>
        <img src={deleteImg} alt="deleteImg" width={32} className="cursor" />
      </Link>
    </>
  );

  const handleSort = async (column, sortDirection) => {
    setActivePage(1);
    setSearchParam({
      search: "no",
      name: "",
      status: "",
    });

    setAssetsTypeDataSort({
      order: sortDirection,
      order_by: column.sortField,
    });
    /*  setLoading(false); */
  };
  /*  PAGINATION FUNCTIONS  */
  const handlePageChange = async (page) => {
    setActivePage(parseInt(page));
  };

  const BootyPagination = ({
    rowsPerPage,
    rowCount,
    onChangePage,
    onChangeRowsPerPage, // available but not used here
    currentPage,
  }) => {
    function toPages(pages) {
      const results = [];
      for (let i = 1; i <= pages; i++) {
        results.push(i);
      }
      return results;
    }
    function getNumberOfPages(rowCount, rowsPerPage) {
      return Math.ceil(rowCount / rowsPerPage);
    }

    const handleBackButtonClick = async () => {
      if ((currentPage - 1) % pageNumberLimit === 0) {
        setMaxPageLimit(maxPageLimit - pageNumberLimit);
        setMinPageLimit(minPageLimit - pageNumberLimit);
      }
      onChangePage(currentPage - 1);
    };

    const handleNextButtonClick = async () => {
      if (currentPage + 1 > maxPageLimit) {
        setMaxPageLimit(maxPageLimit + pageNumberLimit);
        setMinPageLimit(minPageLimit + pageNumberLimit);
      }

      onChangePage(currentPage + 1);
    };

    const handlePageNumber = (e) => {
      onChangePage(Number(e.target.value));
    };

    let paginationText = "Page:  " + currentPage + ", Showing ";

    paginationText =
      paginationText +
      ((currentPage - 1) * perPage + 1) +
      " to " +
      perPage * currentPage;
    paginationText = paginationText + " of " + rowCount + " entries;";

    const pages = getNumberOfPages(rowCount, rowsPerPage);
    const pageItems = toPages(pages);
    const nextDisabled = currentPage === pageItems.length;
    const previosDisabled = currentPage === 1;

    const pageNumbers = pageItems.map((page) => {
      if (page <= maxPageLimit && page > minPageLimit) {
        return (
          <li
            key={page}
            id={page}
            className={`page-item ${currentPage === page ? "active" : ""}`}
          >
            <button
              className={`page-link ${currentPage === page ? "active" : ""}`}
              onClick={handlePageNumber}
              value={page}
            >
              {page}
            </button>
          </li>
        );
      } else {
        return null;
      }
    });
    let pageIncrementEllipses = null;
    if (pageItems.length > maxPageLimit) {
      pageIncrementEllipses = (
        <li className="page-item" onClick={handleNextButtonClick}>
          &hellip;
        </li>
      );
    }
    let pageDecremenEllipses = null;
    if (minPageLimit >= 1) {
      pageDecremenEllipses = (
        <li className="page-item" onClick={handleBackButtonClick}>
          &hellip;
        </li>
      );
    }
    return (
      <div className="card-footer d-flex align-items-center">
        <p className="m-0 text-muted">{paginationText}</p>
        <ul className="pagination m-0 ms-auto">
          <li className="page-item">
            <button
              className="page-link"
              onClick={handleBackButtonClick}
              disabled={previosDisabled}
              aria-disabled={previosDisabled}
              aria-label="previous page"
              pagerangedisplayed={10}
            >
              «
            </button>
          </li>
          {pageDecremenEllipses}
          {pageNumbers}
          {pageIncrementEllipses}
          <li className="page-item">
            <button
              className="page-link"
              onClick={handleNextButtonClick}
              disabled={nextDisabled}
              aria-disabled={nextDisabled}
              aria-label="next page"
            >
              »
            </button>
          </li>
        </ul>
      </div>
    );
  };
  /* END DATATABLE */
  const CustomLoader = () => (
    <div style={{ padding: "24px" }}>
      <DivLoader color="primary" />
    </div>
  );
  return (
    <>
      <Loading
        loading={fullLoading}
        background="rgba(0, 0, 0, 0.5)"
        loaderColor="#fff"
      />
      <HeaderSection
        title="Manage"
        heading="Assets Type"
        addButton={() => handleShow("Add", 0)}
        addLink="#"
        addLinkName="Add Assets Type"
        linktype="add"
      />
      <div className="page-body">
        <div className="container-xl">
          <div className="row row-cards">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Listing</h3>
                </div>
                <div className="card-body border-bottom py-3">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group mb-3 ">
                        <label className="form-label required">
                          Assets Type
                        </label>
                        <div>
                          <input
                            type="text"
                            name="searchAssetTypeName"
                            id="searchAssetTypeName"
                            className={`form-control`}
                            placeholder="Enter Assets Type"
                            onChange={(e) =>
                              setSearchAssetsTypeName(e.target.value)
                            }
                            value={searchAssetTypeName}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="mb-3">
                        <label className="form-label required">Status</label>

                        <select
                          className="form-select form-control"
                          name="searchAssetsStatus"
                          id="searchAssetsStatus"
                          placeholder="Select status"
                          onChange={(e) =>
                            setSearchAssetsStatus(e.target.value)
                          }
                          value={searchAssetsStatus}
                        >
                          {" "}
                          <option value="">Select</option>
                          <option value="1">Active</option>
                          <option value="0">Deactive</option>
                        </select>
                      </div>
                    </div>

                    <div className="col-md-3">
                      <div className="form-group mb-3 ">
                        <label className="form-label">&nbsp;</label>
                        <div>
                          <button
                            type="button"
                            name="btnSearch"
                            id="btnSearch"
                            className="btn btn-blue d-none d-sm-inline-block ml-3"
                            onClick={assetsSearch}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon icon-tabler icon-tabler-search"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <circle cx="10" cy="10" r="7"></circle>
                              <line x1="21" y1="21" x2="15" y2="15"></line>
                            </svg>
                            Search
                          </button>
                          &nbsp;
                          <button
                            type="button"
                            name="btnReset"
                            id="btnReset"
                            className="btn btn-outline-primary "
                            onClick={clearSearch}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon icon-tabler icon-tabler-x"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <line x1="18" y1="6" x2="6" y2="18"></line>
                              <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={assetsTypeData}
                    progressPending={loading}
                    progressComponent={<CustomLoader />}
                    pagination="true"
                    paginationServer
                    paginationTotalRows={totalRows}
                    //onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationPerPage={perPage}
                    paginationComponent={BootyPagination}
                    sortServer
                    onSort={handleSort}
                  />
                </div>
                <div className="card-footer d-flex align-items-center">
                  <p className="m-0 text-muted">
                    Showing {/* <span>1</span> to <span>8</span> of{" "} */}
                    <span>{totalRecords}</span> entries
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{action} Assets Type</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form method="POST" onSubmit={handleSubmit} autoComplete={"off"}>
            <div className="row">
              <div className="col-md-6">
                <label className="form-label required">Assets Type</label>
                <div>
                  <input
                    type="text"
                    name="assetsName"
                    id="assetsName"
                    className={`form-control ${
                      validate.validate && validate.validate.assetsName
                        ? "is-invalid"
                        : ""
                    }`}
                    placeholder="Enter Assets Type"
                    onChange={(e) => setAssetsName(e.target.value)}
                    value={assetsName}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label className="form-label required">Status</label>
                <select
                  className={`form-select form-control ${
                    validate.validate && validate.validate.status
                      ? "is-invalid "
                      : ""
                  }`}
                  name="status"
                  id="status"
                  placeholder="Select status"
                  onChange={(e) => setStatus(e.target.value)}
                  value={status}
                >
                  <option value="">Select</option>
                  <option value="1">Active</option>
                  <option value="0">Deactive</option>
                </select>
              </div>
            </div>

            <input
              type="hidden"
              name="assetsTypeId"
              id="assetsTypeId"
              value={assetsTypeId}
            />
          </form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AssetsTypeList;
