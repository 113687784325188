import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import Form from '../../../component/utilities/Forms';
import * as CONST from "../../../component/config/Constant";
import { AuthContext } from '../../../component/context/AuthContext';
import { toast } from "react-toastify";
import DivSpinner from '../../../component/utilities/Spinner';

import DatePicker from 'react-datetime';
import moment from 'moment';
import 'react-datetime/css/react-datetime.css';

function EditEmp({ childToParent, editEmpId }) {
	const [authUserData, setAuthUserData] = useContext(AuthContext);
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [phoneNumber, setPhoneNumber] = useState("");
	const [address, setAddress] = useState("");
	const [department, setDepartment] = useState("");
	const [departmentList, setDepartmentList] = useState("");
	const [designation, setDesignation] = useState("");
	const [designationList, setDesignationList] = useState("");
	const [empDetails, setEmpDetails] = useState("");
	const [status, setStatus] = useState("");
	const [validate, setValidate] = useState({});
	const [show, setShow] = useState('show');
	const [loading, setLoading] = useState(false);
	const [empId, setEmpId] = useState(editEmpId);
	const handleShow = () => setShow(true);

	const [dateOfBirth, setDateOfBirth] = useState();
	const [dateOfJoining, setDateOfJoining] = useState();
	//const handleChange = (date) => setDateOfBirth(date);
	//const moment = require('moment');

	let isToken = '';
	let user_id = '';
	if (authUserData) {
		if (authUserData[0]) {
			isToken = authUserData[0].token ? authUserData[0].token : "";
			user_id = authUserData[0].user_id ? authUserData[0].user_id : "";
		}
	}
	const handleDesignation = async (did) => {
		setLoading(true);
		setDepartment(did);
		if (did) {
			await axios
				.get(CONST.GET_DESIG_BY_ID + '/' + did, {
					headers: {
						'Authorization': `Basic ${isToken}`
					}
				})
				.then((response) => {
					const status = response.data.status;
					if (status === 'success') {
						setDesignationList(response.data.data);
					}
				})
				.catch((error) => {
					toast.error(error);
				});
		} else {
			setDesignationList("");
		}
		setLoading(false);
	}
	const fetchDepartment = async () => {
		await axios
			.get(CONST.GET_DEPARTMENT, {
				headers: {
					'Authorization': `Basic ${isToken}`
				}
			})
			.then((response) => {
				const status = response.data.status;
				if (status === 'success') {
					setDepartmentList(response.data.data);
				}
			})
			.catch((error) => {
				alert(error);
			});
	}
	const validateEmployee = async () => {
		let isValid = true;

		let validator = Form.validator({
			firstName: {
				value: firstName,
				isRequired: true,
				isEmail: false,
				label: "first name",
			},
			lastName: {
				value: lastName,
				isRequired: true,
				isEmail: false,
				label: "last name",
			},
			email: {
				value: email,
				isRequired: true,
				isEmail: true,
				label: "email",
			},
			phoneNumber: {
				value: phoneNumber,
				isRequired: true,
				isEmail: false,
				label: "phone number",
			},
			department: {
				value: department,
				isRequired: true,
				isEmail: false,
				label: "department",
			},
			address: {
				value: address,
				isRequired: true,
				isEmail: false,
				label: "address",
			},
			status: {
				value: status,
				isRequired: true,
				isEmail: false,
				label: "status",
			},
			dateOfBirth: {
				value: dateOfBirth,
				isRequired: true,
				isEmail: false,
				label: "Date of birth",
			},
			dateOfJoining: {
				value: dateOfJoining,
				isRequired: true,
				isEmail: false,
				label: "Date of Joining",
			},

		});

		if (validator !== null) {
			setValidate({
				validate: validator.errors,
			});
			//console.log('validate emp : '+JSON.stringify(validate));
			isValid = false;
		} else {
			setValidate({
				validate: "",
			});
			isValid = true;
		}
		return isValid;
	};

	const handleEditFrm = async (e) => {
		e.preventDefault();
		const validate = await validateEmployee();
		if (validate) {
			setLoading(false);
			await axios
				.post(CONST.UPDATE_EMP, {
					id: empId,
					first_name: firstName,
					last_name: lastName,
					email: email,
					address: address,
					department: department,

					designation: designation,
					status: status,
					phone_number: phoneNumber,
					date_of_birth: moment(dateOfBirth, "DD-MM-YYYY").format("YYYY-MM-DD"),
					date_of_joining: moment(dateOfJoining, "DD-MM-YYYY").format("YYYY-MM-DD"),
				}, {
					headers: {
						'Authorization': `Basic ${isToken}`
					}
				})
				.then((response) => {
					console.log(response);
					console.log(response.data.status);
					const status = response.data.status;
					setLoading(false);
					if (status === 'error') {
						const errorMsg = response.data.errors;
						let isArray = Array.isArray(errorMsg);
						if (isArray) {
							errorMsg.map(element => {
								toast.error(element.msg);
							})
						}
						else {
							toast.error(errorMsg);
						}
					} else {
						toast.success("Employee Info has been updates successfully.");
					}
				})
				.catch((error) => {
					setLoading(false);
					toast.error(error);
				});
		}
	}
	const handleClose = () => {

	};
	useEffect(() => {
		fetchDepartment();
		fetchEmployee();
	}, [])

	const fetchEmployee = async () => {
		await axios
			.post(CONST.GET_EMP_BY_ID, {
				id: user_id,
				user_id: empId
			}, {
				headers: {
					'Authorization': `Basic ${isToken}`
				}
			})
			.then((response) => {
				const status = response.data.status;
				if (status === 'success') {
					setLoading(true);
					console.log(response.data.data);
					setFirstName(response.data && response.data.data.first_name ? response.data.data.first_name : "");
					setLastName(response.data && response.data.data.last_name ? response.data.data.last_name : "");
					setEmail(response.data && response.data.data.email ? response.data.data.email : "");
					setPhoneNumber(response.data && response.data.data.phone_number ? response.data.data.phone_number : "");
					setAddress(response.data && response.data.data.user_address ? response.data.data.user_address : "");
					setDepartment(response.data && response.data.data.department.id ? response.data.data.department.id : "");
					handleDesignation(response.data && response.data.data.department.id ? response.data.data.department.id : "");
					setDesignation(response.data && response.data.data.designation.id ? response.data.data.designation.id : "");
					setDateOfBirth(response.data && response.data.data.date_of_birth ? moment(response.data.data.date_of_birth).format('DD-MM-YYYY') : "");
					console.log(response.data.data.date_of_joining);
					setDateOfJoining(response.data && response.data.data ? moment(response.data.data.date_of_joining).format('DD-MM-YYYY') : "");
					//setDateOfBirth(response.data && response.data.data.date_of_birth ? response.data.data.date_of_birth : "");
					setStatus(response.data.data.status);
					setLoading(false);
					setEmpDetails(response.data.data);
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	}
	return (
		<>
			<Modal
				size="lg"
				show={show}
				onHide={() => childToParent('hide')}
				backdrop="static"
				keyboard={false}
				centered
				fullscreen={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>Edit Employee</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleEditFrm}>
						<div className="row">
							<label className="form-label"><h5>Personal Information</h5></label>
							<hr />
							<div className="col-md-6">
								<div className="form-group mb-3 ">
									<label className="form-label required">
										First Name
									</label>
									<div>


										<input
											type="text"
											name="firstName"
											id="firstName"
											className={`form-control ${validate.validate && validate.validate.firstName
												? "is-invalid "
												: ""
												}`}
											aria-describedby="emailHelp"
											placeholder="Enter first name"
											onChange={(e) => setFirstName(e.target.value)}
											value={firstName}
										/>
									</div>
									<div
										className={`invalid-feedback text-start ${validate.validate && validate.validate.firstName
											? "d-block"
											: "d-none"
											}`}
									>
										{validate.validate && validate.validate.firstName
											? validate.validate.firstName[0]
											: ""}{" "}
									</div>{" "}
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group mb-3 ">
									<label className="form-label required">
										Last Name
									</label>
									<div>
										<input
											type="text"
											name="lastName"
											id="lastName"
											className={`form-control ${validate.validate && validate.validate.lastName
												? "is-invalid "
												: ""
												}`}
											aria-describedby="emailHelp"
											placeholder="Enter last name"
											onChange={(e) => setLastName(e.target.value)}
											value={lastName}
										/>
									</div>
									<div
										className={`invalid-feedback text-start ${validate.validate && validate.validate.lastName
											? "d-block"
											: "d-none"
											}`}
									>
										{validate.validate && validate.validate.lastName
											? validate.validate.lastName[0]
											: ""}{" "}
									</div>{" "}
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group mb-3 ">
									<label className="form-label required">Email</label>
									<div>
										<input
											type="email"
											className={`form-control ${validate.validate && validate.validate.email
												? "is-invalid "
												: ""
												}`}
											id="email"
											name="email"
											value={email}
											placeholder="your@email.com"
											onChange={(e) => setEmail(e.target.value)}
										/>
										<div
											className={`invalid-feedback text-start mb-2 ${validate.validate && validate.validate.email
												? "d-block"
												: "d-none"
												}`}
										>
											{validate.validate && validate.validate.email
												? validate.validate.email[0]
												: ""}
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group mb-3 ">
									<label className="form-label required">
										Phone Number
									</label>
									<div>
										<input
											international
											defaultCountry="US"
											type="text"
											name="phoneNumber"
											id="phoneNumber"
											className={`form-control ${validate.validate && validate.validate.phoneNumber
												? "is-invalid "
												: ""
												}`}
											aria-describedby="emailHelp"
											placeholder="Enter phone number"
											onChange={(e) => setPhoneNumber(e.target.value)}
											value={phoneNumber}
										/>
									</div>
									<div
										className={`invalid-feedback text-start 
                            ${validate.validate && validate.validate.phoneNumber
												? "d-block"
												: "d-none"
											}
                           `}
									>
										{validate.validate && validate.validate.phoneNumber
											? validate.validate.phoneNumber[0]
											: ""}{" "}
									</div>{" "}
									<div
										className="invalid-feedback text-start" >
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group mb-3 ">
									<label className="form-label required">Address</label>
									<div>
										<input
											type="text"
											className={`form-control ${validate.validate && validate.validate.address
												? "is-invalid "
												: ""
												}`}
											id="address"
											name="address"
											value={address}
											placeholder="Enter address"
											onChange={(e) => setAddress(e.target.value)}
										/>
										<div
											className={`invalid-feedback text-start mb-2 ${validate.validate && validate.validate.address
												? "d-block"
												: "d-none"
												}`}
										>
											{validate.validate && validate.validate.address
												? validate.validate.address[0]
												: ""}
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="form-group mb-3 ">
									<label className="form-label required">Date Of Birth</label>
									<div>
										<DatePicker
											name="dateOfBirth"
											id='dateOfBirth'
											value={dateOfBirth}
											selected={dateOfBirth}
											timeFormat=""
											dateFormat="DD-MM-YYYY"
											className={`${validate.validate && validate.validate.dateOfBirth
												? "is-invalid "
												: ""
												}`}
											onChange={(date) => setDateOfBirth(date)}
										/>

										<div
											className={`invalid-feedback text-start mb-2 ${validate.validate && validate.validate.dateOfBirth
												? "d-block"
												: "d-none"
												}`}
										>
											{validate.validate && validate.validate.dateOfBirth
												? validate.validate.dateOfBirth[0]
												: ""}
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="mb-3">
									<label className="form-label required">Department</label>
									{
										(departmentList) ? (
											<>



												<select
													className={`form-select form-control ${validate.validate && validate.validate.department
														? "is-invalid "
														: ""
														}`}
													name="department"
													id="department"
													placeholder="Select department"
													onChange={(e) => handleDesignation(e.target.value)}

													value={department}
												>
													<option value="">Select</option>

													{
														departmentList && departmentList.map((element, index) => {
															return (
																<option
																	value={element.id}
																	key={index}
																>{element.name}</option>

															);
														})
													}
												</select>
											</>
										) :
											(
												<>
													<DivSpinner size="sm" color="primary" />
												</>
											)
									}
									<div
										className={`invalid-feedback text-start ${validate.validate && validate.validate.department
											? "d-block"
											: "d-none"
											}`}
									>
										{validate.validate && validate.validate.department
											? validate.validate.department[0]
											: ""}{" "}
									</div>{" "}
								</div>
							</div>
							{
								designationList &&
								(
									<div className="col-md-6">
										<div className="mb-3">
											<label className="form-label required">Designation</label>
											<select
												className={`form-select form-control ${validate.validate && validate.validate.designation
													? "is-invalid "
													: ""
													}`}
												name="designation"
												id="designation"
												placeholder="Select designation"
												onChange={(e) => setDesignation(e.target.value)}
												value={designation}
											>
												<option value="">Select</option>
												{
													designationList && designationList.map((element, index) => {
														return (
															<option
																value={element.id}
																key={index}
															>{element.name}</option>

														);
													})
												}
											</select>
											<div
												className={`invalid-feedback text-start ${validate.validate && validate.validate.designation
													? "d-block"
													: "d-none"
													}`}
											>
												{validate.validate && validate.validate.designation
													? validate.validate.designation[0]
													: ""}{" "}
											</div>{" "}
										</div>
									</div>
								)
							}
							<div className="col-md-6">
								<div className="form-group mb-3 ">
									<label className="form-label required">Date Of Joining</label>
									<div>
										<DatePicker
											name="dateOfJoining"
											id='dateOfJoining'
											value={dateOfJoining}
											selected={dateOfJoining}
											timeFormat=""
											dateFormat="DD-MM-YYYY"
											className={`${validate.validate && validate.validate.dateOfJoining
												? "is-invalid "
												: ""
												}`}
											onChange={(date) => setDateOfJoining(date)}
										/>

										<div
											className={`invalid-feedback text-start mb-2 ${validate.validate && validate.validate.dateOfJoining
												? "d-block"
												: "d-none"
												}`}
										>
											{validate.validate && validate.validate.dateOfJoining
												? validate.validate.dateOfJoining[0]
												: ""}
										</div>
									</div>
								</div>
							</div>
							<div className="col-md-6">
								<div className="mb-3">
									<label className="form-label required">Status</label>
									<select
										className={`form-select form-control ${validate.validate && validate.validate.status
											? "is-invalid "
											: ""
											}`}
										name="status"
										id="status"
										placeholder="Select status"
										onChange={(e) => setStatus(e.target.value)}
										value={status}
									>
										<option value="">Select</option>
										<option value="1">Active</option>
										<option value="0">Deactive</option>
									</select>
									<div
										className={`invalid-feedback text-start ${validate.validate && validate.validate.status
											? "d-block"
											: "d-none"
											}`}
									>
										{validate.validate && validate.validate.status
											? validate.validate.status[0]
											: ""}{" "}
									</div>{" "}
								</div>
							</div>
							<div className="col-md-12">
								<div className="form-footer">

									{
										empDetails
											?
											<>
												<button
													type="submit"
													className="mr-3 btn btn-primary"
												>Update</button>
											</>
											: <>
												<DivSpinner size="sm" color="primary" />
											</>
									}


									&nbsp;&nbsp;

								</div>
							</div>
						</div>
					</form>
				</Modal.Body>

			</Modal>
		</>
	)
}

export default EditEmp