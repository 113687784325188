import React, { useEffect } from 'react'
import HeaderSection from "../banner/HeaderSection";
import RecentTickets from './RecentTickets';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import JoditEditor from 'jodit-react';
import { useRef } from 'react';
import { useMemo } from 'react';
import { toast } from "react-toastify";
import axios from 'axios';
import * as  CONST from "../../../component/config/Constant"
import { useContext } from 'react';
import { AuthContext } from '../../../component/context/AuthContext';
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import MultiSelect from  'react-multiple-select-dropdown-lite'
import  'react-multiple-select-dropdown-lite/dist/index.css'
import TicketDetails from './TicketDetails';
import Loading from "react-fullscreen-loading";
import DatePicker from 'react-datetime';
import moment from 'moment';
import 'react-datetime/css/react-datetime.css';
import editImg from "../../../assets/img/icon/filter.png";
import { Link } from "react-router-dom";

function Tickets() {
  const [show, setShow] = useState(false);
  const [validate, setValidate] = useState({});
  const [to, setTo] = useState('');
  const [cc, setCc] = useState('');
  const [subject, setSubject] = useState('');
  const editor = useRef(null);
  const [reason, setReason] = useState('');
 
  const [headUsers, setHeadUsers] = useState('');
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  const [showTicketDetail, setShowTicketDetail] = useState(false);
  const [ticketDetail, setTicketDetail] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [fullLoading, setFullLoading] = useState(false);
  let user_id = '';
  let isToken = '';
  let eHeadId = '';
  if (authUserData) {
    if (authUserData[0]) {
      isToken = authUserData[0].token ? authUserData[0].token : "";
      user_id = authUserData[0].user_id ? authUserData[0].user_id : "";
      eHeadId = authUserData[0].is_head ? authUserData[0].is_head : "";
    }
  }

  const [showInput,setShowInput] = useState(false);
  const [newHeads, setNewHeads] = useState([]);//newHeads = [];
  const [newHeadsCC, setNewHeadsCC] = useState([]);//newHeads = [];
  const [loading, setLoading] = useState(false);
  const [multidept, setmultideptlist] = useState([]);//newHeads = [];
  const [newdeptId, setNewDeptId] = useState([]);//newHeads = [];

  const [toError,settoError] = useState('');
  const [ccError,setccError] = useState('');
  const [reasonError,setreasonError] = useState('');
  const [subjectError,setsubjectError] = useState('');

  const [perPage, setPerPage] = useState(10);
  const [activePage, setActivePage] = useState(1);
  const [dataSort, setDataSort] = useState({
    order: "desc",
    order_by: "id",
  });
  const [pageCount, setPageCount] = useState(0);
  const [filtershow, setFilterShow] = useState(false);
  const [date, setDate] = useState('');
  const [departmentName, setDepartmentName] = useState('');
  const [username, setUserName] = useState('');
  const [filterticketid, setFilterTicketId] = useState('');
  const[departmentList, setdepartmentList] = useState([]);
  const config = useMemo(
    () => ({
      readonly: false,
      uploader: { "insertImageAsBase64URI": true }
    }),
    []
  );

  function handleCreateTicketPopup() {
    setShow(true);
  }
  const handleClose = () => {
    setShow(false);
    setFilterShow(false)
  }
  const handleSubmit = async (e) => {
    let isValid = await valdations();
      if(isValid === true){
        setLoading(true);
        var data = [{
          cc_user_id:cc,
          title : subject,
          description : reason,
          createdby : user_id,
          createdfor: to,
          status : 0
        }]
        console.log('ticket data',data)
        await axios({
          method: "post",
          url: CONST.CREATE_SUPPORT_TICKET,
          data: {
            cc_user_id:cc,
            title : subject,
            description : reason,
            createdby : user_id,
            createdfor: to,
            status : 0
          },
          headers: {
						'Authorization': `Basic ${isToken}`,
						'Content-Type' : 'application/json'
					}
        })
          .then((response) => {
            // console.log("Personal info >>", response);
            getTicketList(dataSort, activePage, perPage, date, filterticketid, departmentName, username);
            handleClose();
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
            alert(error);
            toast.error("Something went wrong. Please try again.");
          });
      }
  }

  const handleFilter = () =>{
    setFilterShow(true)
  }

  useEffect(() => {
    fetchHeadUser();
    fetchHeadCCMails('');
    getDepartmentList();
    getTicketList(dataSort, activePage, perPage, date, filterticketid, departmentName, username);
  }, [dataSort, activePage, perPage])

  const fetchHeadUser = async (e) => {
    await axios
      .get(CONST.SUPPORT_USER_LIST,
        {
          headers: {
            'Authorization': `Basic ${isToken}`
          }
        }
      )
      .then((response) => {
        const status = response.data.status;
        if (status === 'success') {
          //console.log(response.data.data);
          setHeadUsers("");
          response.data.data && response.data.data.map((empList, index) => {
            /* console.log(empList.id);
            console.log(empList.name); */
            setHeadUsers(current => [...current, { value: empList.id, label: empList.name }]);
          }
          )
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  async function fetchHeadCCMails(val){
    let EmailArray =[];
    setLoading(true);
    await axios
        .post(CONST.GET_HEAD_CC_EMAILS, {
            id: user_id,
            department_id:val
        },{
            headers: {
                'Authorization': `Bearer ${isToken}`
            }
        })

        .then((response) => {
            const status = response.data.status;
            if (status === 'success') {
                //alert(JSON.stringify(response.data.data));
                setNewHeadsCC(response.data.data);
                let newData = response.data.data;
                //alert('eduData >> '+ JSON.stringify(newData));
                if(newData.length > 0 ){
                    {Object.keys(newData).map((key, index) => {
                        //newData[index].validate = {}
                        //newHeads.text = newData[index].name;
                        //newHeads.text = newData[index].name;
                        let optionsObj = {
                            'text':  newData[index].name,
                            'value': newData[index].email,
                        }
                        EmailArray.push( optionsObj );
                        
                    })}
                    
                    
                    //alert('EmailArray'+JSON.stringify(newHeads));
                }

                let optionsVersion = newData.map( (v,index) => (  
                    {label:  v.name, value:  v.id.toString(), }
                ));

                const sortedData = [...optionsVersion].sort((a, b) => {
                  // Compare the names alphabetically
                  return a.label.localeCompare(b.label);
                });
               

                setNewHeadsCC(sortedData);
                //alert('EmailArray'+JSON.stringify(newHeads));
            }
        })
        .catch((error) => {
            alert(error);
        });
    setLoading(false);
}

const valdations = async () =>{
  let valid = true;

      let ccError, reasonError,toError  = ''
     
      if(to == ''){
        settoError('Assign to field is required') 
        valid = false;
      }

      if(cc == ''){
        setccError('CC field is required')
        valid = false;
      }

      if(subject == ''){
        setsubjectError('Subject field is required')
        valid = false;
      }

      if(reason == ''){
        setreasonError('Reason field is required') 
          valid = false;
      }
      
      
      
  
  return valid ;
}


const handleOnchangeCC  =  val  => {
    if(val.indexOf('other') !== -1){
        setShowInput(true);
    }
    else{
        setShowInput(false);
    }
    setCc(val)
}


  async function getTicketList( paramSort, paramPage, paramLimit, filterDate, ticketId, departmentId, empname) {
    setLoading(true);
    let srch_param = {
      user_id:user_id,
      sort: paramSort,
      page_number: paramPage,
      limit: paramLimit,
      is_head:eHeadId,
      srchDate: filterDate ? moment(Date.parse(filterDate)).format('YYYY-MM-DD') :'',
      username:empname,
      departmentName:departmentId,
      ticketId:ticketId
    };
      await axios
        .post(CONST.GET_TICKET_LIST, srch_param, {
          headers: {
            Authorization: `Basic ${isToken}`,
          },
        })
        .then((response) => {
          const status = response.data.status;
          if (status == "success") {
            setTickets(response.data.data);
            // console.log('ggggggggg', response.data.data)
            setPageCount(response.data.totalPages); 
          }else{
            setTickets([]);
            setPageCount(0);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
  }

  async function getDepartmentList(){
    
    axios.post(CONST.GET_ACTIVE_DEPARTMENT_LIST, {
      headers: {
        Authorization: `Basic ${isToken}`,
      },
    }).then((response)=>{
      const status = response.data.status;
      if (status == "success") {
        setdepartmentList(response.data.data);
        let deptList = response.data.data
        let optionsList = deptList.map( (v,index) => (  
          {label:  v.name, value:  v.id.toString(), }
      ));
      console.log('fffffff', optionsList)
        setmultideptlist(optionsList)
      }else{
        setdepartmentList([]);
      }
    }).catch((error)=>{
      console.log(error)
    })
  }

  const handleDataFromRecentTicket = (data) => {
    setShowTicketDetail(true);
    setTicketDetail(data)
    console.log('Data received from child:', data);
  };

  const handlePageChange = (data) => {
    // console.log('ddddddchild:', data);
    setActivePage(data)
    getTicketList(dataSort, activePage, perPage, date, filterticketid, departmentName, username);
  };

  const handleSearch = () => {
    // setDate(filterdate());
    // console.log(filterdate())
    getTicketList(dataSort, activePage, perPage, date, filterticketid, departmentName, username);
    setFilterShow(false)
    
  }

  const clearSearch = () =>{
    setFilterShow(false)
    setDate('');
    setDepartmentName('');
    setFilterTicketId('');
    setUserName('');
    getTicketList(dataSort, activePage, perPage, '', '', '', '');
  }

  const filterdatechange = (date) =>{
    var data = moment(Date.parse(date)).format('YYYY-MM-DD');
    setDate(data);
  }

  const setToValue = async (selectedOption) => {
    console.log(selectedOption);
  }

  const handleOndepartment  =  async(val)  => {
    
    console.log('ggggggg', val)
    // var data = val
    // await setNewDeptId(data);
   await fetchHeadCCMails(val);
    // console.log('kkkkkkkkkkkk', newdeptId)
   
}

 
  /*   console.log(headUsers); */
  return (
    <>
      <Loading
        loading={fullLoading}
        background="rgba(0, 0, 0, 0.5)"
        loaderColor="#fff"
      />
      <HeaderSection
        title="Manage"
        heading="Support"
        addButton={() => handleCreateTicketPopup()}
        addLink="#"
        addLinkName="Create Ticket"
        linktype="add"
      />
      <div className="page-body">
        <div className="container-xl">
          <div className="row row-cards">
            <div className="col-md-4 col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Recent Tickets</h3>
                  <Link onClick={() => handleFilter()} style={{paddingLeft:'203px'}}>
                    <img
                      src={editImg}
                      alt="editUser"
                      width={20}
                      className="img-action cursor"
                    />
                  </Link>
                </div>
                <div className="card-body border-bottom py-3 ticket-pagination">
                  <RecentTickets data={tickets} onDataClick={handleDataFromRecentTicket} onPageChange= {handlePageChange} totalPageCount={pageCount}  />
                </div>
              </div>
            </div>
            <div className="col-md-8 col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Ticket Details</h3>
                </div>
                {showTicketDetail ? (
                  <div className="card-body border-bottom py-3">
                    <TicketDetails data={ticketDetail} defaultTab='details'/>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Create Ticket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         
            <div className="row">
              {/* <form
                method="POST"
                autoComplete={"off"}
              >  */}
               <div className="col-md-6">
                <div className="form-group mb-3 ">
                  <label className="form-label required">Department Name</label>
                  <div>
                   
                  <MultiSelect
                      onChange={handleOndepartment}
                      options={multidept}  
                      closeOnSelect={true} 
                      />
                 
                    {/* <select
                    className="form-control"
                    name="exemption_type"
                    value={departmentName}
                    aria-describedby="emailHelp"
                    placeholder="Enter Name"
                    onChange={(e) => setDepartmentName(e.target.value)}
                    >
                        <option value="">Select</option>
                        {
                        departmentList && departmentList.map((element)=>(
                          <option key={element.id} value={element.id}>{element.name}</option>
                        ))
                        }
                    </select> */}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-3 ">
                  <label className="form-label required">Assign To</label>
                  <div>
                    {/* <Select
                      isSearchable={true}
                      options={headUsers}
                      value={to}
                      onChange={setToValue}
                    /> */}
                    <select
                    className="form-control"
                    name="exemption_type"
                    value={to}
                    aria-describedby="emailHelp"
                    placeholder="Enter Name"
                    onChange={(e) => setTo(e.target.value)}
                    >
                        <option value="">Select</option>
                        {
                        headUsers && headUsers.map((element)=>(
                          <option key={element.value} value={element.value}>{element.label}</option>
                        ))
                        }
                    </select>
                  </div>
                  <div
                      className={`invalid-feedback text-start ${toError
                      ? "d-block"
                      : "d-none"
                      }`}
                  >
                      {toError
                  ? toError
                  : ""}
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group mb-3 ">
                  <label className="form-label required">CC</label>
                  <div>
                    {/* <input
                      type="text"
                      name="cc"
                      id="cc"
                      className={`form-control ${
                        validate.validate && validate.validate.cc
                          ? "is-invalid "
                          : ""
                      }`}
                      aria-describedby="emailHelp"
                      placeholder="Enter cc email"
                      onChange={(e) => setCc(e.target.value)}
                      value={cc}
                    /> */}
                    <MultiSelect
                      onChange={handleOnchangeCC}
                      options={newHeadsCC}  
                      closeOnSelect={true} 
                      />
                  </div>
                  <div
                      className={`invalid-feedback text-start ${ccError
                      ? "d-block"
                      : "d-none"
                      }`}
                  >
                      {ccError
                  ? ccError
                  : ""}
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group mb-3 ">
                  <label className="form-label required">Subject</label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      id="subject"
                      aria-describedby="emailHelp"
                      placeholder="Enter subject"
                      onChange={(e) => setSubject(e.target.value)}
                      value={subject}
                    />
                  </div>
                  <div
                      className={`invalid-feedback text-start ${subjectError
                      ? "d-block"
                      : "d-none"
                      }`}
                  >
                      {subjectError
                  ? subjectError
                  : ""}
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group mb-3 ">
                  <label className="form-label required">Reason</label>
                  <div>
                    <JoditEditor
                      ref={editor}
                      config={config}
                      value={reason}
                      tabIndex={1} // tabIndex of textarea
                      onBlur={(newContent) => setReason(newContent)}
                      onChange={(newContent) => {}}
                      // className={`form-control ${validate.validate && validate.validate.policyDescription ? "is-invalid": "" }`}
                    />
                  </div>
                  <div
                      className={`invalid-feedback text-start ${reasonError
                      ? "d-block"
                      : "d-none"
                      }`}
                  >
                      {reasonError
                  ? reasonError
                  : ""}
                  </div>
                </div>
              </div>
              {/* </form>          */}
              <div className="col-md-12">
                <div className="form-footer">
                  <button
                    type="submit"
                    className="mr-3 btn btn-primary"
                    onClick={handleSubmit}
                  >
                    Submit
                  </button>
                  &nbsp;&nbsp;
                </div>
              </div>
            </div>
        </Modal.Body>
      </Modal>
      <Modal size="lg" show={filtershow} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Filter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         
            <div className="row">
              {/* <form
                method="POST"
                autoComplete={"off"}
              >  */}
              <div className="col-md-6">
                <div className="form-group mb-3 ">
                  <label className="form-label required">Search Date</label>
                  <div>
                   
                    <DatePicker
                      name="date"
                      value={date}
											selected={date}
                      id='date'
                      timeFormat=""
                      placeholder="DD-MM-YYYY"
                      dateFormat="DD-MM-YYYY"
                      onChange={(date) => filterdatechange(date)}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-3 ">
                  <label className="form-label required">Employee Name</label>
                  <div>
                   
                  <input
                      type="text"
                      className="form-control"
                      name="empname"
                      id="empname"
                      aria-describedby="emailHelp"
                      placeholder="Enter Employee Name"
                      onChange={(e) => setUserName(e.target.value)}
                      value={username}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-3 ">
                  <label className="form-label required">Department Name</label>
                  <div>
                   
                  {/* <input
                      type="text"
                      className="form-control"
                      name="deptname"
                      id="deptname"
                      aria-describedby="emailHelp"
                      placeholder="Enter Department Name"
                      onChange={(e) => setDepartmentName(e.target.value)}
                      value={departmentName}
                    /> */}
                    <select
                    className="form-control"
                    name="exemption_type"
                    value={departmentName}
                    aria-describedby="emailHelp"
                    placeholder="Enter Name"
                    onChange={(e) => setDepartmentName(e.target.value)}
                    >
                        <option value="">Select</option>
                        {
                        departmentList && departmentList.map((element)=>(
                          <option key={element.id} value={element.id}>{element.name}</option>
                        ))
                        }
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group mb-3 ">
                  <label className="form-label required">TicketId</label>
                  <div>
                   
                  <input
                      type="number"
                      className="form-control"
                      name="ticketnumber"
                      id="ticketnumber"
                      aria-describedby="emailHelp"
                      placeholder="Enter Ticket Number"
                      onChange={(e) => setFilterTicketId(e.target.value)}
                      value={filterticketid}
                    />
                  </div>
                </div>
              </div>
              

             
              {/* </form>          */}
              <div className="col-md-12">
                <div className="form-footer">
                  <button
                    type="submit"
                    className="mr-3 btn btn-primary"
                    onClick={handleSearch}
                  >
                    Search
                  </button>
                  &nbsp;&nbsp;
                  <button
                    type="submit"
                    className="mr-3 btn btn-primary"
                    onClick={clearSearch}
                  >
                    Clear 
                  </button>
                </div>
              </div>
            </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Tickets