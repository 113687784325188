import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import * as CONST from "../../../component/config/Constant";
import { AuthContext } from '../../../component/context/AuthContext';
import { toast } from "react-toastify";
import Moment from "moment";

function AssetsDetails({ closeAssetsDetails, assetsTypeIdModal }) { //alert('editEmpId'+editEmpId)
    const [authUserData, setAuthUserData] = useContext(AuthContext);
    const [show, setShow] = useState('show');

    const [loading, setLoading] = useState(false);
    const [totalExp, setTotalExp] = useState('');

    const [assetsField, setAssetsField] = useState([{
   
    }]);
    const [assetsTypeId, setAssetsTypeId] = useState(assetsTypeIdModal);
   
    const moment = require('moment');


    let isToken = '';
    let user_id = '';
    if (authUserData) {
        if (authUserData[0]) {
            isToken = authUserData[0].token ? authUserData[0].token : "";
            user_id = authUserData[0].user_id ? authUserData[0].user_id : "";
        }
    }

    useEffect(() => {
        fetchAssetsDetails();
    }, [])

    const fetchAssetsDetails = async () => {
        setLoading(true);

        await axios
        .post(CONST.GET_USER_REQUEST_DETAILS_ASSETS, {
            user_id: user_id,
            asset_type_id: assetsTypeId,
        },{
            headers: {
                'Authorization': `Bearer ${isToken}`
            }
        }).then((response) => {
                console.log('<<<<<<<<<<<<<<<<<<<',response)
                const status = response.data.status;

                if (status === 'success') {
                    if (response.data.data != null) {
                        setAssetsField(response.data.data);
                    }
                } else {
                    // toast.error(response.data.message);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        setLoading(false);
    }



    return (
        <>
            <Modal
                size="lg"
                show={show}
                onHide={() => closeAssetsDetails('hide')}
                backdrop="static"
                keyboard={false}
                centered
                fullscreen={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <table className="table card-table table-vcenter">
                            <thead>
                              <tr>
                                <th className="w-25">Brand Name</th>
                                <th className="w-25">Allocated By</th>
                                <th className="w-25">Date</th>
                                <th className="w-25">Status</th>

                              </tr>
                            </thead>
                            <tbody>
                            {
                                assetsField.map((data, i) => {
                                let statusText;
                                if (data.request_status == 0) {
                                    statusText = 'New Request';
                                } else if (data.request_status == 1) {
                                    statusText = 'In process';
                                } else if (data.request_status == 2) {
                                    statusText = 'Approved';
                                } 
                                else if (data.request_status == 3) {
                                    statusText = 'Rejected';
                                }
                                else if (data.request_status == 4) {
                                    statusText = 'Return';
                                }
                               
                                return (
                                      <tr>

                                        <td>{data?.assetBrandDetails?.brand_name ?? 'NA'}</td>
                                        <td>{data?.asset_request_approved_by?.first_name ?? 'NA'}  {data?.asset_request_approved_by?.last_name ?? ''}</td>
                                        <td >
                                        {(Moment(data.status_changed_on).isValid()) ? Moment(data.status_changed_on).format("DD, MMM. YYYY ") : '-'}

                                        </td>
                                        <td className="w-25">
                                        {statusText}
                                        </td>
                                       
                                      </tr>
                                   
                                   )
                                })
                            }
                            </tbody>
                          </table>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default AssetsDetails