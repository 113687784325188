import { React, useState, useEffect, useContext, useMemo } from "react";
import "../../../assets/css/table.css";
import Loading from "react-fullscreen-loading";
import DivLoader from "../../../component/utilities/Spinner";
import DataTable from "react-data-table-component";
import axios from "axios";
import { toast } from "react-toastify";
import * as CONST from "../../../component/config/Constant";
import { AuthContext } from "../../../component/context/AuthContext";
import { Link } from "react-router-dom";
import editImg from "../../../assets/img/icon/edit.svg";
import deleteImg from "../../../assets/img/icon/delete.svg";
import Swal from "sweetalert2";
import DivSpinner from "../../../component/utilities/Spinner";
import fileImg from "../../../assets/img/icon/file.png";
// import DatePicker from 'react-datetime';
// import moment from 'moment';
import EditExemption from "./EditExemption";

function MyExemptions() {
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  const [fullLoading, setFullLoading] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [srchExemptionName, setSrchExemptionName] = useState("");
  const [srchType, setSrchType] = useState("");
  const [typeLisiting, setTypeLisiting] = useState([
    {
      id: "1",
      name: "80C",
    },
    {
      id: "2",
      name: "80D",
    },
    {
      id: "3",
      name: "80E",
    },
    {
      id: "4",
      name: "80G",
    },
    {
      id: "5",
      name: "80TTA",
    },
  ]);
  /* START DATATABLE */
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [maxPageLimit, setMaxPageLimit] = useState(10);
  const [minPageLimit, setMinPageLimit] = useState(0);
  const [editModel, setEditModel] = useState({
    show: "hide",
  });
  const [eventID, setEventID] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [dataSort, setDataSort] = useState({
    order: "desc",
    order_by: "id",
  });
  const [listLimit, setListLimit] = useState(50);
  const currentYear = new Date().getFullYear();
  const [srchYear, setSrchYear] = useState(currentYear - 1 + "-" + currentYear);

  const [searchParam, setSearchParam] = useState({
    srchName: srchExemptionName,
    srchYear: srchYear,
    srchType: srchType,
  });

  const [Form16A, setForm16A] = useState("");
  const [Form16B, setForm16B] = useState("");

  let isToken = "";
  let user_id = "";
  if (authUserData) {
    if (authUserData[0]) {
      isToken = authUserData[0].token ? authUserData[0].token : "";
      user_id = authUserData[0].user_id ? authUserData[0].user_id : "";
    }
  }

  useEffect(() => {
    fetchExemptiontData(searchParam, dataSort, activePage, perPage);
  }, [searchParam, dataSort, activePage, listLimit]);

  async function searchEvent() {
    setSearchParam({
      srchName: srchExemptionName,
      srchYear: srchYear,
      srchType: srchType,
    });
    setDataSort({
      order: "desc",
      order_by: "id",
    });
    setActivePage("1");
    // fetchExemptiontData(searchParam, dataSort, activePage, perPage);
  }

  function clearSearch() {
    setSearchParam({
      srchName: "",
      srchYear: currentYear - 1 + "-" + currentYear,
      srchType: "",
    });

    setSrchYear(currentYear - 1 + "-" + currentYear);
    setSrchExemptionName('');
    setSrchType("");
    setDataSort({
      order: "desc",
      order_by: "id",
    });
    setActivePage("1");
  }

  function searchYear(e) {
    setSrchYear(e.target.value);
  }
  const handleEventEdit = async (eventID) => {
    console.log('kkkkkkkk', eventID)
    setLoading(true);
    setEditModel({
      show: "edit",
    });
    setEventID(eventID);
    setLoading(false);
  };

  const closeEditForm = async (type) => {
    setEditModel({
      show: "hide",
    });
    fetchExemptiontData(searchParam, dataSort, activePage, perPage);
  };

  const getType = (data) => {
    let exemptionType = typeLisiting.find((item) => item.id == data);
    if(exemptionType){
      return exemptionType.name
    } 
  };

  const Action = ({ row }) => (
    <>
      <Link onClick={() => handleEventEdit(row)}>
        <img
          src={editImg}
          alt="editUser"
          width={32}
          className="img-action cursor"
        />
      </Link>
      <Link onClick={() => deleteEvent(row.id)}>
        <img
          src={deleteImg}
          alt="deleteImg"
          width={32}
          className=" img-action cursor"
        />
      </Link>
    </>
  );

  const deleteEvent = (exemption_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this event?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        if (exemption_id > 0) {
          setLoading(true);
          axios
            .delete(
              CONST.DELETE_EXEMPTION,
              {
                headers: {
                  Authorization: `Bearer ${isToken}`,
                },
                data: {
                  exemption_id: exemption_id
                }
              }
            )
            .then((response) => {
              if (response) {
                if (response.data.status == "error") {
                  toast.error(response.data.message);
                } else {
                  toast.success(response.data.message);
                  fetchExemptiontData(searchParam, dataSort, activePage, perPage);
                }
              }
            })
            .catch((error) => {
              setLoading(false);
              toast.error("Something went wrong. Please try again." + error);
            });
          setLoading(false);
        }
      }
    });
  };
  const columns = [
    {
      name: "Type",
      selector: (row) => getType(row.exemption_type),
      cell: (row) => getType(row.exemption_type),
      sortable: true,
      sortField: "exemption_type",
    },
    {
      name: "Name",
      selector: (row) => row.name,
      cell: (row) => row.name,
      sortable: true,
      sortField: "name",
    },
    {
      name: "Amount",
      selector: (row) => row.total_amount.toLocaleString("en-IN",{
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      cell: (row) => row.total_amount.toLocaleString("en-IN",{
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      sortable: true,
      sortField: "total_amount",
    },
    {
      name: "Action",
      cell: (row) => <Action row={row} />,
      sortable: false,
    },
  ];

  async function fetchExemptiontData(paramSearch, paramSort, paramPage, paramLimit) {
    setLoading(true);
    let srch_param = {
      limit: paramLimit,
      page_number: paramPage,
      search_input: paramSearch,
      sort: paramSort,
      user_id:user_id
    };
    const response = await axios
      .post(CONST.MY_EXEMPTION_LIST, srch_param, {
        headers: {
          Authorization: `Basic ${isToken}`,
        },
      })
      .then((response) => {
        const status = response.data.status;
        if (status == "success") {
          if(response.data.count > 0){
            if (response.data.data[0].user.etax_exemption_has_documents){
              response.data.data[0].user.etax_exemption_has_documents.forEach(element => {
                if (element.form_type == 'A') {
                  var path1 = CONST.FILE_BASE_URL + element.document_path;
                  setForm16A(path1)
                } else if (element.form_type == 'B'){
                  var path2 = CONST.FILE_BASE_URL + element.document_path;
                  setForm16B(path2)
                }
               
              });
              // setForm16A(response.data.data[0].user.etax_exemption_has_documents[0].form_type ==  ? response.data.data[0].user.etax_exemption_has_documents[0].document_path : '')
            }
            // setForm16A(response.data[0].user.etax_exemption_has_documents ? response.data[0].user.etax_exemption_has_documents[0].document_path : '')
            setTotalRows(response.data.count);
            setData(response.data.data);
            setTotalRecords(response.data.count);
          }else{
            setData([]);
            setForm16A('')
            setForm16B('')
            setTotalRows(0);
            setTotalRecords(0);
          }
        }else{
          setData([]);
          setTotalRows(0);
          setTotalRecords(0);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error("There was an error.");
      });
  }
  const handlePerRowsChange = async (perPage, page) => {
    setLoading(true);

    const response = await axios.get(
      `https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`
    );

    setData(response.data.data);
    setPerPage(perPage);
    setLoading(false);
  };
  function toPages(pages) {
    const results = [];
    for (let i = 1; i <= pages; i++) {
      results.push(i);
    }
    return results;
  }
  function getNumberOfPages(rowCount, rowsPerPage) {
    return Math.ceil(rowCount / rowsPerPage);
  }
  const handleSort = async (column, sortDirection) => {
    setActivePage(1);
    setSearchParam({
      srchName: srchExemptionName,
      srchYear: srchYear,
      srchType: srchType,
    });

    setDataSort({
      order: sortDirection,
      order_by: column.sortField,
    });
    /*  setLoading(false); */
  };
  /*  PAGINATION FUNCTIONS  */
  const handlePageChange = async (page) => {
    setActivePage(page);
  };

  const BootyPagination = ({
    rowsPerPage,
    rowCount,
    onChangePage,
    onChangeRowsPerPage,
    currentPage,
  }) => {
    const handleBackButtonClick = async () => {
      if ((currentPage - 1) % perPage === 0) {
        setMaxPageLimit(maxPageLimit - perPage);
        setMinPageLimit(minPageLimit - perPage);
      }
      onChangePage(currentPage - 1);
    };

    const handleNextButtonClick = async () => {
      if (currentPage + 1 > maxPageLimit) {
        setMaxPageLimit(maxPageLimit + perPage);
        setMinPageLimit(minPageLimit + perPage);
      }

      onChangePage(currentPage + 1);
    };

    const handlePageNumber = (e) => {
      onChangePage(Number(e.target.value));
    };

    let paginationText = "Page:  " + currentPage + ", Showing ";

    paginationText =
      paginationText +
      ((currentPage - 1) * perPage + 1) +
      " to " +
      perPage * currentPage;
    paginationText = paginationText + " of " + rowCount + " entries;";

    const pages = getNumberOfPages(rowCount, rowsPerPage);

    const pageItems = toPages(pages);
    const nextDisabled = currentPage === pageItems.length;
    const previosDisabled = currentPage === 1;

    const pageNumbers = pageItems.map((page) => {
      if (page <= maxPageLimit && page > minPageLimit) {
        return (
          <li
            key={page}
            id={page}
            className={`page-item ${currentPage === page ? "active" : ""}`}
          >
            <button
              className={`page-link ${currentPage === page ? "active" : ""}`}
              onClick={handlePageNumber}
              value={page}
            >
              {page}
            </button>
          </li>
        );
      } else {
        return null;
      }
    });
    let pageIncrementEllipses = null;
    if (pageItems.length > maxPageLimit) {
      pageIncrementEllipses = (
        <li className="page-item" onClick={handleNextButtonClick}>
          &hellip;
        </li>
      );
    }
    let pageDecremenEllipses = null;
    if (minPageLimit >= 1) {
      pageDecremenEllipses = (
        <li className="page-item" onClick={handleBackButtonClick}>
          &hellip;
        </li>
      );
    }
    return (
      <div className="card-footer d-flex align-items-center">
        <p className="m-0 text-muted">{paginationText}</p>

        <ul className="pagination m-0 ms-auto">
          <li className="page-item">
            <button
              className="page-link"
              onClick={handleBackButtonClick}
              disabled={previosDisabled}
              aria-disabled={previosDisabled}
              aria-label="previous page"
              // pageRangeDisplayed={10}
            >
              «
            </button>
          </li>
          {pageDecremenEllipses}
          {pageNumbers}
          {pageIncrementEllipses}
          <li className="page-item">
            <button
              className="page-link"
              onClick={handleNextButtonClick}
              disabled={nextDisabled}
              aria-disabled={nextDisabled}
              aria-label="next page"
            >
              »
            </button>
          </li>
        </ul>
      </div>
    );
  };

  /* END DATATABLE */
  const CustomLoader = () => (
    <div style={{ padding: "24px" }}>
      <DivLoader color="primary" />
    </div>
  );
  return (
    <>
      <Loading
        loading={fullLoading}
        background="rgba(0, 0, 0, 0.5)"
        loaderColor="#fff"
      />
      <div className="page-header d-print-none">
        <div className="container-xl">
          <div className="row g-2 align-items-center">
            <div className="col">
              <div className="page-pretitle">Manage</div>
              <h2 className="page-title ">Exemption</h2>
            </div>
            <div className="col-12 col-md-auto ms-auto d-print-none">
              <div className="btn-list">
                <Link
                  to={CONST.ADMIN_PRE + "add-exemption"}
                  className="btn btn-primary"
                  //   onClick={handleEventEdit}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-plus"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <line x1="12" y1="5" x2="12" y2="19"></line>
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                  </svg>
                  &nbsp;Add Exemption
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-body">
        <div className="container-xl">
          <div className="row row-cards">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Listing</h3>
                </div>
                <div className="card-body border-bottom py-3">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group mb-3 ">
                        <label className="form-label required">Name</label>
                        <div>
                          <input
                            type="text"
                            name="srchExemptionName"
                            id="srchExemptionName"
                            className="form-control"
                            aria-describedby="emailHelp"
                            placeholder="Search Exemption name"
                            onChange={(e) =>
                              setSrchExemptionName(e.target.value)
                            }
                            value={srchExemptionName}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className="mb-3">
                        <label className="form-label required">Type</label>

                        {typeLisiting ? (
                          <>
                            <select
                              className="form-select form-control"
                              name="srchType"
                              id="srchType"
                              placeholder="Select type"
                              onChange={(e) => setSrchType(e.target.value)}
                              value={srchType}
                            >
                              <option value="">Select</option>
                              {typeLisiting &&
                                typeLisiting.map((element) => (
                                  <option key={element.id} value={element.id}>
                                    {element.name}
                                  </option>
                                ))}
                            </select>
                          </>
                        ) : (
                          <DivSpinner size="sm" color="primary" />
                        )}
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className="mb-2">
                        <label className="form-label required">Year</label>
                        {/* <DatePicker
                          name="srchYear"
                          value={srchYear}
                          selected={srchYear}
                          dateFormat="YYYY"
                          timeFormat=""
                          className=""
                          onChange={(e) => setSrchYear(e)}
                        /> */}
                        <select className="form-select form-control"
                          value={srchYear}
                          onChange={(e) => searchYear(e)}
                        >
                          <option value="">Select Year</option>
                          {Array.from({ length: 8 }, (_, index) => {
                            const startYear =
                              new Date().getFullYear() - 5 + index;
                            const endYear = startYear + 1;
                            const formattedYear = `${startYear}-${endYear}`;

                            return (
                              <option key={formattedYear} value={formattedYear}>
                                {formattedYear}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group mb-3 ">
                        <label className="form-label">&nbsp;</label>
                        <div>
                          <button
                            type="button"
                            name="btnSearch"
                            id="btnSearch"
                            className="btn btn-blue d-none d-sm-inline-block ml-3"
                            onClick={searchEvent}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon icon-tabler icon-tabler-search"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <circle cx="10" cy="10" r="7"></circle>
                              <line x1="21" y1="21" x2="15" y2="15"></line>
                            </svg>
                            Search
                          </button>
                          &nbsp;
                          <button
                            type="button"
                            name="btnSearchReset"
                            id="btnSearchReset"
                            className="btn btn-outline-primary "
                            onClick={clearSearch}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon icon-tabler icon-tabler-x"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <line x1="18" y1="6" x2="6" y2="18"></line>
                              <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                    {Form16A ?
                    <div className="col-md-1">
                      <div className="form-group mb-3 ">
                        <label className="form-label">&nbsp;</label>
                          <div>
                          <a
                            href={Form16A}
                            download="Form16A-PDF-document"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <button
                            type="button"
                            name="btnSearch"
                            className="btn btn-outline-primary"
                          >
                            
                            Form16 A &nbsp;
                            <img
                              src={fileImg}
                              alt="upload document"
                              width={20}
                              className="img-action cursor"
                            />
                          </button>
                          </a>
                          </div>
                        
                      </div>
                    </div>
                    :''
                    }
                    {Form16B ? 
                    <div className="col-md-1">
                      <div className="form-group mb-2 ">
                        <label className="form-label">&nbsp;</label>
                       
                          <a
                            href={Form16B}
                            download="Form16B-PDF-document"
                            target="_blank"
                            rel="noreferrer"
                          >
                            <button
                            type="button"
                            name="btnSearch"
                            className="btn btn-outline-primary"
                          >
                            
                            Form16 B &nbsp;
                            <img
                              src={fileImg}
                              alt="upload document"
                              width={20}
                              className="img-action cursor"
                            />
                          </button>
                          </a>
                          &nbsp;
                          
                          
                        
                      </div>
                    </div>
                    :''
                    }
                  </div>
                </div>
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={data}
                    progressPending={loading}
                    progressComponent={<CustomLoader />}
                    pagination="true"
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationPerPage={perPage}
                    paginationComponent={BootyPagination}
                    /*  sortServer */
                    onSort={handleSort}
                  />
                </div>
                <div className="card-footer d-flex align-items-center">
                  <p className="m-0 text-muted">
                    Showing {/* <span>1</span> to <span>8</span> of{" "} */}
                    <span>{totalRecords}</span> entries
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {editModel.show === "edit" && (
        <EditExemption closeEditEventForm={closeEditForm} eventID={eventID} />
      )}
    </>
  );
}

export default MyExemptions;
