import { React, useState, useEffect, useContext, useMemo } from 'react'
import { Link } from 'react-router-dom';
import Loading from "react-fullscreen-loading";
import HeaderSection from "../banner/HeaderSection";
import DivLoader from "../../../component/utilities/Spinner";
import DataTable from "react-data-table-component";
import axios from "axios";
import { toast } from "react-toastify";
import * as  CONST from "../../../component/config/Constant"
import Form from "../../../component/utilities/Forms";
import editImg from "../../../assets/img/icon/edit.svg";
import deleteImg from "../../../assets/img/icon/delete.svg";
import ReactSwitch from 'react-switch';
import * as SwitchProps from '../../../component/utilities/ReactSwitchReactSwitchProps';
import { AuthContext } from '../../../component/context/AuthContext';
/**For modal popup */
//import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import DivSpinner from '../../../component/utilities/Spinner';


function EventCategoryList() {
    const [authUserData, setAuthUserData] = useContext(AuthContext);
    const [fullLoading, setFullLoading] = useState(false);
    const [validate, setValidate] = useState({});
    const [processing, setProcessing] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [catId, setCatId] = useState(0);
    
    const [action, setAction] = useState('');
    const [catLisiting, setCatLisiting] = useState('');
    const MySwal = withReactContent(Swal)
    const [listLimit, setListLimit] = useState(50);
    
    
    const [totalRecords, settotalRecords] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [maxPageLimit, setMaxPageLimit] = useState(10);
    const [minPageLimit, setMinPageLimit] = useState(0);

    //For Form
    const [catName, setCatName] = useState('');
    const pageNumberLimit = { perPage };

    const [dataSort, setDataSort] = useState({
        order: 'desc',
        order_by: 'id'
    });

    let isToken = '';
    if (authUserData) {
        if (authUserData[0]) {
            isToken = authUserData[0].token ? authUserData[0].token : "";
        }
    }
    useEffect(() => {
        fetchCatData();
    },[]);

    async function fetchCatData() {
        //setLoading(true);
       
        await axios({
                method: 'post',
                url: CONST.GET_EVENT_CATEGORY_LIST,
                headers: {
                    'Authorization': `Bearer ${isToken}`
                }
                        
            })

            .then((response) => {
                setLoading(false);
                //console.log('Event Cat Res- '+response);
                const status = response.data.status;
                
                if (status === 'success') {//console.log('Cat response: '+JSON.stringify(response))
                    setCatLisiting(response.data.data);
                    setTotalRows(response.data.count);
                    
                    settotalRecords(response.data.count);
                    setData(response.data.data);
                }else{
                    toast.error(response.data.message);
                }
            })
            .catch((error) => {
                console.log(error);
            });
            //setLoading(false);
    }

    /** For Modalpopup */
    const [show, setShow] = useState(false);
    

    function handleShow(action, catId) { 
        const cat_id = catId ? catId : 0;
        setCatId(cat_id)
        if(cat_id > 0){
            getCatByID(cat_id);
        }
        //alert(cat_id)
        setAction(action)
        setShow(true);
    }

    const handleClose = () => {
        setShow(false);
        setCatId(0);
        setCatName('');
               
    }
    
    const handleSubmit = async (e) => {
        
        e.preventDefault();
        //console.log('dept: ' + deptName + ' email: ' + deptEmail + ' status :' + status + ' deptID: ' + deptId)

        let isValid = true;

        let validator = Form.validator({
            deptName: {
                value: catName,
                isRequired: true,
                label: "Category Name",
            }
           
        });

        if (validator !== null) {
            setValidate({
                validate: validator.errors,
            });

            isValid = false;
        }

        if (isValid) {
            setLoading(true);
            let api_url = '';
            
            let params = {
                            name: catName,
                        }
            if (catId == 0) {
                api_url = CONST.ADD_EVENT_CATEGORY;
              
            } else {
                api_url = CONST.UPDATE_EVENT_CATEGORY;
                params.id = catId;
            }
            

            await axios({
                    method: 'post',
                    url: api_url,
                    data: params, 
                    headers: {
                        'Authorization': `Bearer ${isToken}`
                    },
                            
                })
                .then((response) => {
                    console.log(response.data.errors);
                    
                    if (response) {

                        if (response.data.status == 'error') {
                            console.log('in error');
                            toast.error(response.data.message);
                        } else {
                            toast.success(response.data.message);
                            handleClose();
                            fetchCatData()
                            setCatName('');
                                                               
                            setLoading(false);
                        }
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    console.log(error);
                    toast.error("Something went wrong. Please try again." + error);
                });

           
        } else {
            console.log('err-'+JSON.stringify(validator));
            //toast.error("Error: "+ validator.errors);
        }

    }
   
    const columns = [
        {
            name: 'Sr. No',
            selector: (row, index) => index + 1,
            width: "10%",
        },

        {
            name: 'Category Name',
            selector: (row) => row.name,
            width: "25%",

        },
        {
            name: 'Action',
            width: "20%",
            wrap: true,
            sortable: false,
            cell: (row) => <Action row={row} />,
        }
    ]

    const Action = ({ row }) => (
        <>
            <Link to={'#'} data-id={row.id} onClick={() => handleShow('Edit', row.id)}>
                <img src={editImg} alt='editUser' width={32} className='cursor' />
            </Link>
            <Link onClick={() => { deleteHandler(row.id) } }>
                <img src={deleteImg} alt='deleteImg' width={32} className='cursor' />
            </Link>


        </>
    )
   
    function deleteHandler(del_id) {//alert(del_id)
        Swal.fire({
			title: 'Are you sure?',
			text: "Do you want to delete this category?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then((result) => {
			if (result.isConfirmed) {
				setLoading(true);
                if (del_id > 0) {
                    setLoading(true);
                    
                    axios({
                            method: 'post',
                            url: CONST.DELETE_EVENT_CATEGORY,
                            headers: {
                                'Authorization': `Bearer ${isToken}`
                            },
                            data: {
                                id: del_id
                            }
                                    
                        })
                        .then((response) => {
                            //console.log(response.data);
                            
                            if (response) {
        
                                if (response.data.status == 'error') {
                                    toast.error(response.data.message);
                                } else {
                                    toast.success(response.data.message);
                                    handleClose();
                                    fetchCatData();
                                    
                                }
                            }
                        })
                        .catch((error) => {
                            setLoading(false);
                            //console.log(error);
                            toast.error("Something went wrong. Please try again." + error);
                        });
                    setLoading(false);
                }
				
			}
		})
    }


    async function getCatByID(cat_id) {
       
        await axios({
                method: 'put',
                url: CONST.GET_EVENT_CATEGORY_BY_ID + '/' + cat_id,
                headers: {
                    'Authorization': `Bearer ${isToken}`
                }
                        
            })
            .then((response) => {
                const status = response.data.status;
                
               //alert(JSON.stringify(response.data.data));
                if (status === 'success') {
                    //let catData = response.data.data ? response.data.data : ;
                    setCatName( response.data.data.name ? response.data.data.name : '' );
                   
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    

    
    /* END DATATABLE */
    const CustomLoader = () => (
        <div style={{ padding: "24px" }}>
            <DivLoader color="primary" />
        </div>
    );


    return (
        <>
            <Loading
                loading={fullLoading}
                background="rgba(0, 0, 0, 0.5)"
                loaderColor="#fff"
            />
            <HeaderSection
                title="Manage"
                heading="Event Category"
                //addButton={handleShow}
                addButton={() => handleShow('Add', 0)}
                addLink="#"
                addLinkName="Add Category"
                linktype="add"
            />
            <div className="page-body">
                <div className="container-xl">
                    <div className="row row-cards">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Listing</h3>
                                </div>
                                
                                <div className="table-responsive">

                                    <DataTable
                                        columns={columns}
                                        data={catLisiting}
                                        
                                    />

                                </div>
                                <div className="card-footer d-flex align-items-center">
                                    <p className="m-0 text-muted">
                                        Showing {/* <span>1</span> to <span>8</span> of */}
                                        <span>{totalRecords}</span> entries
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal size="" show={show} onHide={handleClose}>

                <Modal.Header closeButton>
                    <Modal.Title>{action} Event Category</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <form
                        method="POST"
                        onSubmit={handleSubmit}
                        autoComplete={"off"}
                    > 
                        <div className="row">
                          
                            <div className="col-md-6">
                                <div className="form-group mb-3 ">
                                    <label className="form-label required">
                                        Category Name
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            name="catName"
                                            id="catName"
                                            className={`form-control ${validate.validate && validate.validate.catName
                                                ? "is-invalid "
                                                : ""
                                                }`}
                                            aria-describedby="emailHelp"
                                            placeholder="Enter Category name"
                                            onChange={(e) => setCatName(e.target.value)}
                                            value={catName}
                                        />
                                    </div>
                                </div>
                            </div>
                       
                            <div className="col-md-12">
								<div className="form-footer">

                                <button
                                    type="submit"
                                    className="mr-3 btn btn-primary"
                                    onClick={handleSubmit}
                                >Submit</button>
											
									&nbsp;&nbsp;

								</div>
							</div>
                        </div>
                        <input type="hidden" name="catId" id="catId" value={catId} />
                        
                    </form>                 
                </Modal.Body>
            </Modal>
        </>
    )
}

export default EventCategoryList