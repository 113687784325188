import React from 'react'
import DivLoader from "./Spinner";

function CustomLoader() {
    return (
        <div style={{ padding: "24px" }}>
            <DivLoader color="primary" />
        </div>
    )
}

export default CustomLoader