import React, { useContext, useEffect } from "react";
import Loading from "react-fullscreen-loading";
import HeaderSection from "../banner/HeaderSection";
import * as CONST from "../../../component/config/Constant";
import { AuthContext } from "../../../component/context/AuthContext";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import DivSpinner from "../../../component/utilities/Spinner";
import deleteImg from "../../../assets/img/icon/delete.svg";
import Form from "../../../component/utilities/Forms";
import { Link } from "react-router-dom";
/* import Accordion from 'react-bootstrap/Accordion'; */

function AdminPermissions() {
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  const [designationListing, setDesignationListing] = useState("");
  const [desitName, setDesName] = useState("");
  const [dptListing, setDptListing] = useState("");

  const [validate, setValidate] = useState({});
  const [loading, setLoading] = useState(false);

  let isToken = "";
  let user_id = "";
  let default_dpt_id = '';
  let department_id = '';
  if (authUserData) {
    if (authUserData[0]) {
      isToken = authUserData[0].token ? authUserData[0].token : "";
      user_id = authUserData[0].user_id ? authUserData[0].user_id : "";
      department_id = authUserData[0].department_id ? authUserData[0].department_id : "";
    }
  }

  if (department_id !== 5 && department_id !== 8) {
    default_dpt_id = department_id;
  }

  const [srchEmpDepartment, setSrchEmpDepartment] = useState(default_dpt_id);

  const [sections, setSections] = useState([
    {
      title: "",
      delete: 0,
      inputs: [],
      section_validate: { validate: 0, delete: 0}
    },
  ]);

 
  const addSection = () => {
    setSections([...sections, { title: "", delete: 0, inputs: [], section_validate: { validate: 0,  delete: 0} }]);
  };

  const removeSection = (sectionIndex) => {
    const updatedSections = [...sections];
    //------new code
    updatedSections[sectionIndex].delete = 1;
    updatedSections[sectionIndex].section_validate.delete = 1;
    setSections(updatedSections);

    //------previous code direct section
    // updatedSections.splice(sectionIndex, 1);
    // setSections(updatedSections);
  };

  const addInput = (sectionIndex) => {
    const updatedSections = [...sections];
    const inputObj = { validate: 0, delete: 0, rules: '', responsibility_id: updatedSections[sectionIndex].id ? updatedSections[sectionIndex].id :''};
    updatedSections[sectionIndex].inputs.push(inputObj);
    setSections(updatedSections);
  };

  const removeInput = (sectionIndex, inputIndex) => {
    const updatedSections = [...sections];
    //------new code
    updatedSections[sectionIndex].inputs[inputIndex]['delete'] = 1;
    setSections(updatedSections);

    //------previous code direct remove index
    // updatedSections[sectionIndex].inputs.splice(inputIndex, 1);
    // setSections(updatedSections);
  };

  const handleTitleChange = (sectionIndex, event) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].title = event.target.value;
    updatedSections[sectionIndex].section_validate.validate = 0;
    setSections(updatedSections);
  };

  const handleInputChange = (sectionIndex, inputIndex, event) => {
    const updatedSections = [...sections];
    updatedSections[sectionIndex].inputs[inputIndex]['rules'] = event.target.value;
    updatedSections[sectionIndex].inputs[inputIndex]['validate'] = 0;
    setSections(updatedSections);
  };

  const valdations = async () => {
    let valid = true;

    Object.entries(sections).map(async ([key, value]) => {
      let title = ''

      if (value.title == '') {
        title = 'title field is required'
        valid = false;
      }
      await validateChange(key, 'title', title, 'section', '');

      if (value.inputs.length > 0) {
        await value.inputs.forEach(async (val, ky) => {
          let inp = ''
          if (val.rules == '') {
            inp = 'input field is required'
            valid = false;
          }
          await validateChange(key, 'input', inp, 'inputFeild', ky);
        });
      }
    });
    return valid;
  }
  const validateChange = (index, fieldName, error, type, inputky) => {
    const list = [...sections];

    if (error != '' && type == 'section') {
      list[index]['section_validate']['validate'] = 1;
      list[index]['section_validate'][fieldName] = error;
    } else if (error != '' && type == 'inputFeild') {
      list[index]['inputs'][inputky]['validate'] = 1;
    }
    setSections(list);
  }

  useEffect(() => {
    fetchDeptList();
    fetchDesigtList(srchEmpDepartment);
  }, []);
  async function fetchDeptList() {
    setLoading(true);
    await axios
      .get(CONST.GET_DEPARTMENT, {
        headers: {
          'Authorization': `Basic ${isToken}`
        }
      })
      .then((response) => {
        let status = response.data.status;
        //console.log(response)
        if (status == 'success') {
          setDptListing(response.data.data);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
    setLoading(false);
  }
  const fetchDesigtList = async (dept_id) => {
    setSrchEmpDepartment(dept_id);
    await axios
      .get(CONST.GET_DESIG_BY_ID + '/' + dept_id, {
        headers: {
          'Authorization': `Basic ${isToken}`
        }
      })
      .then((response) => {
        let status = response.data.status;

        if (status == 'success') {
          setDesignationListing(response.data.data);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  }
  // async function fetchDeptList() {
  //   setLoading(true);
  //   await axios
  //     .post(
  //       CONST.GET_DEPARTMENT_WISE_DESIGNATION,
  //       {
  //         user_id: user_id,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Basic ${isToken}`,
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       let status = response.data.status;
  //       console.log(response);
  //       if (status == "success") {
  //         setDesignationListing(response.data.data);
  //       }
  //     })
  //     .catch((error) => {
  //       toast.error(error);
  //     });
  //   setLoading(false);
  // }
  const validatePermissionsForm = async () => {
    let isValid = true;
    let validator = Form.validator({
      desitName: {
        value: desitName,
        isRequired: true,
        isEmail: false,
        label: "department name",
      },
    });

    if (validator !== null) {
      setValidate({
        validate: validator.errors,
      });
      isValid = false;
    } else {
      setValidate({
        validate: "",
      });
      isValid = true;
    }
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let InputValid = true;
    sections.forEach((val, key) => {
      if (val.inputs.length > 0) {
        val.inputs.forEach(element => {
          if (element.validate == '1') {
            console.log('data:', element);
            InputValid = false;
            return false;
          }
        });
      }
    });

    let isValid = await valdations();
    const validate = await validatePermissionsForm();
    if (isValid && InputValid && validate) {
      console.log('Form data:', sections);
      setLoading(true);
      await axios
        .post(CONST.CREATE_ROLES_RESPONSIBLITY, {
          department_id: parseInt(srchEmpDepartment),
          designation_id: parseInt(desitName),
          sections: sections,
        }, {
          headers: {
            'Authorization': `Basic ${isToken}`
          }
        })
        .then((response) => {

          const status = response.data.status;
          setLoading(false);
          if (status === 'error') {
            const errorMsg = response.data.errors;
            let isArray = Array.isArray(errorMsg);
            if (isArray) {
              errorMsg.map(element => {
                toast.error(element.msg);
              })
            }
            else {
              toast.error(errorMsg);
            }
            console.log(errorMsg);
          } else {
            handleChangeDesignation(desitName)
            toast.success("Permissions updated successfully");
          }
        })
        .catch((error) => {
          toast.error("Something went wrong. Please try again.");
        });
      setLoading(false);
    }
    // 
    // if (validate) {

    // }
  };

  const handleChangeDesignation = async (desid) => {
    setLoading(true);
    setDesName(desid);
    if (desid) {
      await axios.post(CONST.GET_CREATED_RULES_RESPONSIBLITY, {
        designation_id: parseInt(desid)
      }, {
        headers: {
          'Authorization': `Basic ${isToken}`
        }
      }).then((response) => {
        console.log(response);
        const status = response.data.status;
        if (status === 'success') {
          console.log("aaaaaaaaa", response.data.data)
          const permissions = response.data.data;


          const updatedArray = permissions.map(item => ({
            id: item.id,
            designation_Id: item.users_designation_Id, // Rename the key
            title: item.title,
            delete: 0,
            inputs: item.responsibilities_has_rules.map(rule => ({
              id: rule.id,
              responsibility_id: rule.responsibility_id,
              rules: rule.rules,
              validate: 0,
              delete: 0
            })),
            section_validate: {
              "validate": 0,
              "delete": 0
            } // Rename the key and structure
          }));

          // const array = {
          //                 designationId:2,
          //                 data_section:[
          //                   {
          //                       "title": "Job responsibilities1",
          //                       "inputs": [
          //                           {

          //                               "rules": "Lead a team of sales associates"
          //                           },
          //                           {
          //                               "validate": 0,
          //                               "rules": "Provide quality customer service"
          //                           },
          //                           {
          //                               "validate": 0,
          //                               "rules": "Create and coordinate sales associates’ schedules"
          //                           }
          //                       ],
          //                       "section_validate": {
          //                           "validate": 0
          //                       }
          //                   },
          //                   {
          //                       "title": "Job responsibilities2",
          //                       "inputs": [
          //                           {
          //                               "validate": 0,
          //                               "rules": "Leading basic dog training, including teaching dogs to sit, stay, lie down and come"
          //                           },
          //                           {
          //                               "validate": 0,
          //                               "rules": "Create a wide variety of deliverables using Adobe Creative Cloud Work with the creative team to brainstorm innovative advertising"
          //                           },
          //                           {
          //                               "validate": 0,
          //                               "rules": "Three to five years of graphic design experience"
          //                           }
          //                       ],
          //                       "section_validate": {
          //                           "validate": 0,
          //                           "title": "title field is required"
          //                       }
          //                   }
          //                  ]
          //             }
          // console.log('dataaaaaa', array)
          setSections(updatedArray)

        } else {
          setSections([
            {
              title: "",
              inputs: [],
              section_validate: { validate: 0, delete: 0}
            },
          ])
        }
      })
        .catch((error) => {
          alert(error);
        });
    }

    setLoading(false);
  }

  return (
    <>
      <Loading
        loading={loading}
        background="rgba(0, 0, 0, 0.5)"
        loaderColor="#fff"
      />

      <HeaderSection title="" heading="" />
      <div className="page-body">
        <div className="container-xl">
          <div className="row row-cards">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Roles & Responsibility</h3>
                </div>

                <div className="card-body border-bottom py-3">
                  <div className="row">
                    <div className="col-12">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label className="form-label required">
                              Department
                            </label>
                            {
                              dptListing ?
                                (
                                  <>
                                    <select
                                      className="form-select form-control"
                                      name="srchEmpDepartment"
                                      id="srchEmpDepartment"
                                      placeholder="Select status"
                                      onChange={(e) => fetchDesigtList(e.target.value)}
                                      value={srchEmpDepartment}
                                    >
                                      {/* <option value="">Select</option> */}
                                      {
                                        dptListing && dptListing.map((element, key) => {
                                          return (
                                            <>
                                              <option key={key} value={element.id}>{element.name}</option>
                                            </>
                                          )
                                        }
                                        )
                                      }
                                    </select>
                                  </>
                                )
                                : (
                                  <DivSpinner size="sm" color="primary" />
                                )
                            }



                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-3">
                            <label className="form-label">
                              Select Designation <span className="red">*</span>
                            </label>
                            {designationListing ? (
                              <>
                                <select
                                  className="form-select form-control"
                                  name="desitName"
                                  id="desitName"
                                  className={`form-control ${validate.validate &&
                                    validate.validate.desitName
                                    ? "is-invalid "
                                    : ""
                                    }`}
                                  placeholder="Select status"
                                  onChange={(e) => handleChangeDesignation(e.target.value)}
                                  value={desitName}
                                >
                                  <option value="">Select designation</option>
                                  {designationListing &&
                                    designationListing.map((element, key) => {
                                      return (
                                        <>
                                          <option key={key} value={element.id}>
                                            {element.name}
                                          </option>
                                        </>
                                      );
                                    })}
                                </select>
                              </>
                            ) : (
                              <DivSpinner size="sm" color="primary" />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 mb-4">
                      {/* <label className="form-label"><h6>Manage Setting</h6></label>
                        <label class="switch ml-auto">
                          
                        </label> */}


                      {sections.map((section, sectionIndex) => (
                        (section.section_validate && section.section_validate.delete == 0 &&(<div class="row custom_card p-3" key={sectionIndex}>
                          <div className="col-12">
                            <div className="row">
                              <div className="col-12 col-md-6">
                                <label className="form-label required d-flex">
                                  <h4>Section Title {sectionIndex != 0 ? sectionIndex : ""} </h4>
                                </label>
                                <input
                                  type="text"
                                  className={`form-control mb-3 ${section.section_validate && section.section_validate.validate
                                    ? "is-invalid "
                                    : ""
                                    }`}
                                  placeholder="Section Title"
                                  value={section.title}
                                  onChange={(e) =>
                                    handleTitleChange(sectionIndex, e)
                                  }
                                />
                              </div>
                              {
                                (sectionIndex != 0 && (
                                  <div className="col-12 col-md-6 text-left">
                                    <Link className='cursor red' onClick={() => removeSection(sectionIndex)}>- Remove Section </Link>
                                  </div>
                                ))
                              }

                            </div>
                            <div className="row p-3">
                              {section.inputs.map((input, inputIndex) => (

                               (input && input.delete == 0 && ( <div className="col-12 col-md-6">
                                  <label className="form-label required d-flex">
                                    <h5>Title {inputIndex != 0 ? inputIndex : ""}</h5>
                                  </label>
                                  <input
                                    key={inputIndex}
                                    type="text"
                                    placeholder="rules enter here"
                                    className={`form-control ${input && input.validate
                                      ? "is-invalid "
                                      : ""
                                      }`}
                                    value={input.rules}
                                    onChange={(e) =>
                                      handleInputChange(sectionIndex, inputIndex, e)
                                    }
                                  />
                                  <div className="text-right pt-2">
                                    <Link className='cursor red' onClick={() => removeInput(sectionIndex, inputIndex)}>- Remove Title </Link>
                                  </div>
                                </div>))

                              ))}

                            </div>
                          </div>
                          <div className="form-group mb-3">
                            <div className="form-group mb-3 text-right">
                              <button
                                className="btn btn-outline-primary ml-auto"
                                onClick={() => addInput(sectionIndex)}
                              >
                                + Add Responsibilities
                              </button>
                            </div>
                          </div>

                        </div>))
                      ))}

                      <div className="col-12 text-right mb-4">
                        <button
                          className="btn btn-primary"
                          onClick={() => addSection()}
                        >
                          + Add More Section
                        </button>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12 text-right mr-2">
                        <button
                          type="button"
                          name="btnSearchReset"
                          id="btnSearchReset"
                          className="mr-3 btn btn-primary"
                          onClick={handleSubmit}
                        >
                          Update
                        </button>
                      </div>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminPermissions;
