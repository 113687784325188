import { React, useState, useEffect, useContext } from 'react'
import Loading from "react-fullscreen-loading";
import HeaderSection from "../banner/HeaderSection";
import * as  CONST from "../../../component/config/Constant"
import "../../../assets/css/form.css";
import Swal from 'sweetalert2';
import axios from 'axios';
import { AuthContext } from '../../../component/context/AuthContext';
import { toast } from "react-toastify";
import deleteImg from "../../../assets/img/icon/delete.svg";
import viewImg from "../../../assets/img/icon/index.png";
import { Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { IconJson } from '@tabler/icons-react';

function Document() {
	const [authUserData, setAuthUserData] = useContext(AuthContext);
	const [loading, setLoading] = useState(false);
	const [formVal, setFormVal] = useState([{ document_name: '', documents: '' }]);
	const [userDocDetails, setUserDocDetails] = useState([{ doc_name: '', doc_file: '' }]);

	/** For Modalpopup */
	const [show, setShow] = useState(false);
	const [imagePreview, setImagePreview] = useState();
	const [docName, setDocName] = useState('');
	const [aadharDoc, setAadharDoc] = useState('');
	const [pancardDoc, setPancardDoc] = useState('');
	const [licenseDoc, setLicenseDoc] = useState('');

	const [viewAadhar, setViewAadhar] = useState(0);
	const [viewPan, setViewPan] = useState(0);
	const [viewLicense, setViewLicense] = useState(0);

	const [aadharLink, setAadharLink] = useState('');
	const [panLink, setPanLink] = useState('');
	const [licenseLink, setLicenseLink] = useState('');

	const [validate, setValidate] = useState({});


	let isToken = '';
	let userId = '';
	if (authUserData) {
		if (authUserData[0]) {
			isToken = authUserData[0].token ? authUserData[0].token : "";
			userId = authUserData[0].user_id ? authUserData[0].user_id : 0;
		}
	}

	useEffect(() => {
		fetchDocumentData();
	}, []);



	function viewDocImage(i, docName, docPath) {
		setShow(true);
		setDocName(docName)
		setImagePreview(docPath);
	}

	const handleClose = () => {
		setShow(false);
	}

	function handleShow(name, path) {

		setDocName(name);
		setImagePreview(path);
		setShow(true);
	}

	function deleteHandler(i, id) {
		Swal.fire({
			title: 'Are you sure?',
			text: "Do you want to delete this details?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then((result) => {
			if (result.isConfirmed) {
				setLoading(true);
				const newFormData = [...userDocDetails];
				if (id > 0) {
					setLoading(true);
					axios
						.delete(CONST.DELETE_DOCUMENTS_DETAILS, {
							headers: {
								'Authorization': `Bearer ${isToken}`
							},
							data: {
								doc_id: id
							}
						})
						.then((response) => {

							if (response) {
								if (response.data.status == 'error') {
									toast.error(response.data.message);
								} else {
									toast.success(response.data.message);
									newFormData.splice(i, 1);
									setUserDocDetails(newFormData);
									//handleClose();
									//fetchDocumentData()                                }
								}
							}
						})
						.catch((error) => {
							setLoading(false);
							console.log(error);
							toast.error("Something went wrong. Please try again." + error);
						});
					setLoading(false);
				}
			}
		})
	}

	//fetch all documents details
	async function fetchDocumentData() {
		setLoading(true);
		await axios
			.get(CONST.GET_DOCUMENTS_DETAILS, {
				headers: {
					'Authorization': `Bearer ${isToken}`
				}
			})

			.then((response) => {
				const status = response.data.status;
				if (status === 'success') {
					const responseData = response.data.data;
					var documentsInfo = [];
					let isArray = Array.isArray(responseData);
					if (isArray) {
						// responseData.map((d, index) => {
						// 	documentsInfo.push({ 
						// 		'doc_name' : d.document_name,
						// 		'doc_file' : d.document_path,
						// 		'doc_id'   : d.id,
						// 	});
						// })
					}
					setUserDocDetails(responseData);
					//alert('Doc::' + JSON.stringify(responseData[0]));
					Object.keys(responseData).forEach(key => {

						if (responseData[key].document_name == 'Aadhar Card') {
							setViewAadhar(1);
							setAadharLink(responseData[key].document_path);
						} else if (responseData[key].document_name == 'Pan Card') {
							setViewPan(1);
							setPanLink(responseData[key].document_path);
						} else if (responseData[key].document_name == 'Driving License') {
							setViewLicense(1);
							setLicenseLink(responseData[key].document_path);
						}

					});


					//setAadharDoc((responseData.document_name == 'Aadhar Card') ? )
				}
				else {
					toast.error(response.data.message);
				}
			})
			.catch((error) => {
				console.log(error);
			});
		setLoading(false);
	}


	const checkValidDoc = (doc_name) => {
		let isValid = true;
		let validator = {}
		if (doc_name == 'aadhar_card') {
			if (aadharDoc == '') {
				alert('err');
				validator.aadhar_card = 'Aadhar card field is required';
				validator.error = 1;
			}
		} else if (doc_name == 'pan_card') {
			if (pancardDoc == '') {
				validator.pan_card = 'Pan card field is required';
				validator.error = 1;
			}
		} else {
			if (licenseDoc == '') {
				validator.driving_license = 'Driving License field is required';
				validator.error = 1;
			}
		}
		//alert(JSON.stringify(validator));
		if (validator.error == 1) {
			setValidate({
				validate: validator
			});

			isValid = false;
		}
		return isValid;
	}

	const handleSubmit = async (doc) => {
		console.log('DOC: ' + doc)

		const isValid = checkValidDoc(doc);
		//alert('isValid : '+ isValid);
		let params = {
			user_id: userId
		};
		if (doc == 'aadhar_card') {
			params.document_name = 'Aadhar Card';
			params.document = aadharDoc;
			
		} else if (doc == 'pan_card') {
			params.document_name = 'Pan Card';
			params.document = pancardDoc;
			
		} else {
			params.document_name = 'Driving License';
			params.document = licenseDoc;
			
		}
		//alert(JSON.stringify(params));
		if (isValid) {
			await axios
				.post(CONST.ADD_DOCUMENTS_DETAILS,
					params,
					{
						headers: {
							'Authorization': `Basic ${isToken}`,
							'content-type': 'multipart/form-data',
						}
					})
				.then((response) => {
					console.log(response);
					const status = response.data.status;
					setLoading(false);

					if (status == 'success') {
						toast.success(response.data.message);
					} else {
						toast.error(response.data.message);
					}

				})
				.catch((error) => {
					setLoading(false);
					console.log(error);
					toast.error("Something went wrong. Please try again.");
				});
		}

	}

	return (
		<>
			<Loading
				loading={loading}
				background="rgba(0, 0, 0, 0.5)"
				loaderColor="#fff"
			/>
			<HeaderSection
				title=""
				heading="Document Information"
				addButton=''
				addLink="#"
				addLinkName="Add More"
				linktype="add" />

			<div className="page-body">
				<div className="container-xl">
					<div className="row row-cards">
						<div className="col-12">
							<div className="card">
								<section className='aadhar-card-section' >
									{/* <h5>Aadhar Card</h5> */}
									<form method="POST"
										// onSubmit={handleSubmit('aadhar_card')}
										autoComplete={"off"}
									>
										<div className="card-body">
											<div className="row">
												<div className="col-md-3">
													<div className="form-group mb-3 ">
														<label className="form-label required">
															Aadhar Card
														</label>
													</div>
												</div>

												<div className="col-md-4">
													<div className="form-group mb-3 ">

														<input
															type="file"
															name="aadhar_card"
															accept="application/msword, application/pdf"
															onChange={(e) => setAadharDoc(e.target.files[0])}
														/>
													</div>
												</div>

												<div className="col-md-2">
													<div className="form-footer">
														<button
															type="button"
															className="mr-3 btn btn-primary"
															onClick={() => handleSubmit('aadhar_card')}
														>
															Submit
														</button>

													</div>
												</div>

												{
													(viewAadhar == 1)
														?
														<>
															<div className="col-md-2">
																<div className="form-group mb-3 ">
																	<Link onClick={() => handleShow("Aadhar Card", aadharLink)}>
																		<img src={viewImg} alt='preview' width={32} className='cursor' />
																	</Link>
																</div>
															</div>
														</>
														:
														''
												}


											</div>
										</div>
									</form>
								</section>

								<section className='pan-card-section' >
									{/* <h5>Aadhar Card</h5> */}
									<form method="POST"
										// onSubmit={handleSubmit('pan_card')}
										autoComplete={"off"}
									>
										<div className="card-body">
											<div className="row">
												<div className="col-md-3">
													<div className="form-group mb-3 ">
														<label className="form-label required">
															Pan Card
														</label>
													</div>
												</div>

												<div className="col-md-4">
													<div className="form-group mb-3 ">

														<input
															type="file"
															name="pan_card"
															accept="application/msword, application/pdf"
															onChange={(e) => setPancardDoc(e.target.files[0])}
														/>
													</div>
												</div>

												<div className="col-md-2">
													<div className="form-footer">
														<button
															type="button"
															className="mr-3 btn btn-primary"
															onClick={() => handleSubmit('pan_card')}
														>
															Submit
														</button>

													</div>
												</div>

												{
													(viewPan == 1)
														?
														<>
															<div className="col-md-2">
																<div className="form-group mb-3 ">
																	<Link onClick={() => handleShow("Pan Card", panLink)}>
																		<img src={viewImg} alt='preview' width={32} className='cursor' />
																	</Link>
																</div>
															</div>
														</>
														:
														''
												}


											</div>
										</div>
									</form>
								</section>

								<section className='driving-license-section' >
									{/* <h5>Aadhar Card</h5> */}
									<form method="POST"
										// onSubmit={handleSubmit('driving_license')}
										autoComplete={"off"}
									>
										<div className="card-body">
											<div className="row">
												<div className="col-md-3">
													<div className="form-group mb-3 ">
														<label className="form-label required">
															Driving license
														</label>
													</div>
												</div>

												<div className="col-md-4">
													<div className="form-group mb-3 ">

														<input
															type="file"
															name="driving_license"
															accept="application/msword, application/pdf"
															onChange={(e) => setLicenseDoc(e.target.files[0])}
														/>
													</div>
												</div>

												<div className="col-md-2">
													<div className="form-footer">
														<button
															type="button"
															onClick={() => handleSubmit('driving_license')}
															className="mr-3 btn btn-primary"
														>
															Submit
														</button>

													</div>
												</div>
												{
													viewLicense
														?
														<>
															<div className="col-md-2">
																<div className="form-group mb-3 ">
																	<Link onClick={() => handleShow("Driving License", licenseLink)}>
																		<img src={viewImg} alt='preview' width={32} className='cursor' />
																	</Link>
																</div>
															</div>
														</>
														:
														''
												}


											</div>
										</div>
									</form>
								</section>

							</div>
						</div>
					</div>
				</div >
			</div>

			<Modal show={show} onHide={handleClose} fullscreen={true}>

				<Modal.Header closeButton>
					<Modal.Title>Uploaded Dcoument - {docName}</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<div className="page-body">
						<iframe style={{ display: "block", width: "100vw", height: "100vh", border: "none" }} src={CONST.HOST + imagePreview} ></iframe>
					</div>
				</Modal.Body>

				<Modal.Footer>
				</Modal.Footer>

			</Modal>
		</>
	)
}

export default Document