import { React, useState, useEffect, useContext, useMemo } from 'react'
import "../../../assets/css/table.css";
import Loading from "react-fullscreen-loading";
import HeaderSection from "../banner/HeaderSection";
import DivLoader from "../../../component/utilities/Spinner";
import DataTable from "react-data-table-component";
import axios from "axios";
import { toast } from "react-toastify";
import * as  CONST from "../../../component/config/Constant"
import { AuthContext } from '../../../component/context/AuthContext';
import { Link } from 'react-router-dom';
import Moment from "moment";
import editImg from "../../../assets/img/icon/edit.svg";
import ExperienceImg from "../../../assets/img/icon/experience-icon.png";
import GuardianImg from "../../../assets/img/icon/guardian-icon.png";
import EducationImg from "../../../assets/img/icon/education-icon.png";
import LeaveImg from "../../../assets/img/icon/leave-icon.png";
import DivSpinner from '../../../component/utilities/Spinner';
import ReactSwitch from 'react-switch';
import * as SwitchProps from '../../../component/utilities/ReactSwitchReactSwitchProps';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import EditEmp from './EditEmp';
import ViewExperience from './ViewExperience';
import ViewGuardian from './ViewGuardian';
import ViewEducation from './ViewEducation';
import ViewLeaves from './ViewLeaves';
import ShowDetails from './ShowDetails';
import ViewCompOff from './ViewCompOff';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import moment from 'moment';
import { differenceInCalendarMonths } from 'date-fns';

import ExportCsv from './ExportCsv';
function List() {
	const [authUserData, setAuthUserData] = useContext(AuthContext);
	const [fullLoading, setFullLoading] = useState(false);
	const [validate, setValidate] = useState({});
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [srchEmpName, setSrchEmpName] = useState("");
	const [srchEmpEmail, setSrchEmpEmail] = useState("");
	const [srchEmpStatus, setSrchEmpStatus] = useState("");
	const [filterUsers, setfilterUsers] = useState([]);

	const [dptListing, setDptListing] = useState("");
	const [srchdesignation, setsrchDesignation] = useState("");
	const [desigListing, setDesigListing] = useState("");
	const MySwal = withReactContent(Swal)
	let srchDptId = '';
	//For Popup
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [totalLeaves, setTotalLeaves] = useState("");
	const [remainingLeaves, setRemainingLeaves] = useState("");
	const [consumedLeaves, setConsumedLeaves] = useState("");

	const [approvedCompoff, setApprovedCompoff] = useState("");
	const [rejectedCompoff, setRejectedCompoff] = useState("");
	const [empEmail, seteEmpEmail] = useState("");
	

	const headers = [
		{ label: "Name", key: "first_name" },
		{ label: "Office Email", key: "email" },
		{ label: "Personal Email", key: "personal_email" },
		{ label: "Phone", key: "phone_number" },
		{ label: "Department", key: "department" },
		{ label: "Designation", key: "designation" },
		{ label: "Current Adress", key: "user_address" },
		{ label: "Aadhar Adress", key: "user_aadhar_address" },
		{ label: "Date of Birth", key: "date_of_birth" },
		{ label: "Date of Joining", key: "date_of_joining" },
		{ label: "Aadhar Number", key: "aadhar_number" },
		{ label: "Pan Card", key: "pan_number" },
		{ label: "UAN Number", key: "uan_number" },
		{ label: "ESIC Number", key: "esic_number" },
	  ];


	let isToken = '';
	let user_id = '';
	let department_id = '';
	let is_head = 0;
	let is_admin = 0;
	let hr_head = 0;
	let default_dpt_id = '';
	if (authUserData) {
		if (authUserData[0]) {
			isToken = authUserData[0].token ? authUserData[0].token : "";
			user_id = authUserData[0].user_id ? authUserData[0].user_id : "";
			is_head = authUserData[0].is_head ? authUserData[0].is_head : 0;
			is_admin = authUserData[0].is_admin ? authUserData[0].is_admin : 0;
			hr_head = authUserData[0].hr_head ? authUserData[0].hr_head : 0;
			department_id = authUserData[0].department_id ? authUserData[0].department_id : "";
		}
	}

	if (department_id !== 5 && department_id !== 8) {
		default_dpt_id = department_id;
	}

	const [srchEmpDepartment, setSrchEmpDepartment] = useState(default_dpt_id);


	/* START DATATABLE */
	const [totalRecords, settotalRecords] = useState(0);
	const [totalRows, setTotalRows] = useState(0);
	const [perPage, setPerPage] = useState(50);
	const [maxPageLimit, setMaxPageLimit] = useState(10);
	const [minPageLimit, setMinPageLimit] = useState(0);
	const [searchParam, setSearchParam] = useState(
		{
			"srchEmpName": srchEmpName,
			"srchEmpEmail": srchEmpEmail,
			"srchEmpStatus": srchEmpStatus,
			"srchEmpDepartment": srchEmpDepartment,
			"srchdesignation": srchdesignation,
		}
	);

	const [activePage, setActivePage] = useState(1);
	const [dataSort, setDataSort] = useState({
		order: 'desc',
		order_by: 'id'
	});
	useEffect(() => {
		/* 	if (department_id !== 5 && department_id !== 8) {
				if (is_admin == 1) {
					srchDptId = "";
				} else if (is_head == 1) {
					srchDptId = department_id;
				}
				fetchUserData(
					{
						"srchEmpName": srchEmpName,
						"srchEmpEmail": srchEmpEmail,
						"srchEmpStatus": srchEmpStatus,
						"srchEmpDepartment": srchDptId
					}, dataSort, activePage, perPage);
	
			} else {
				fetchUserData(searchParam, dataSort, activePage, perPage);
			} */
		fetchUserData(searchParam, dataSort, activePage, perPage);
		fetchDeptList();
		fetchDesigtList(srchEmpDepartment);
	}, [searchParam, dataSort, activePage, perPage]);
	/* START EDIT EMPLOYEE  */
	const [editEmpId, setEditEmpId] = useState("");
	const [isAddModalShow, setAddModalShow] = useState(false);
	const [isEducationModalShow, setEducatinModalShow] = useState(false);
	const [isExperienceModalShow, setExperienceModalShow] = useState(false);
	const [isGuardianModalShow, setGuardianModalShow] = useState(false);
	const [isLeaveModalShow, setLeaveModalShow] = useState(false);
	const [isEmpDetailModalShow, setEmpDetailModalShow] = useState(false);
	const [isCompOffModalShow, setCompoffModalShow] = useState(false);

	const [showModel, setShowModel] = useState({
		show: 'hide'
	});
	const [showEducationModel, setEducationShowModel] = useState({
		show: 'hide'
	});
	const [showExperienceModel, setExperienceShowModel] = useState({
		show: 'hide'
	});
	const [showGuardianModel, setGuardianShowModel] = useState({
		show: 'hide'
	});
	const [showLeaveModel, setLeaveShowModel] = useState({
		show: 'hide'
	});
	const [showEmpDetailsModel, setEmpDetailsShowModel] = useState({
		show: 'hide'
	});
	const [showCompoffModel, setCompoffShowModel] = useState({
		show: 'hide'
	});


	



	const handleEmpEdit = async (empId) => {
		setLoading(true);
		setAddModalShow(true);
		setShowModel({
			show: 'show'
		});
		setEditEmpId(empId);
		setLoading(false);
	}
	const childToParent = async (type) => {
		setShowModel({
			show: 'hide'
		});
		fetchUserData(searchParam, dataSort, activePage, perPage);
	}

	const closeEducation = async (type) => {
		setEducationShowModel({
			show: 'hide'
		});
	}


	const closeExperience = async (type) => {
		setExperienceShowModel({
			show: 'hide'
		});
	}

	const closeGuardian = async (type) => {
		setGuardianShowModel({
			show: 'hide'
		});
	}

	const closeLeave = async (type) => {
		setLeaveShowModel({
			show: 'hide'
		});
	}

	const closeCompoff = async (type) => {
		setCompoffShowModel({
			show: 'hide'
		});
	}

	

	const closeEmpDetails = async (type) => {
		setEmpDetailsShowModel({
			show: 'hide'
		});
	}

	function calculateExperience(startDate, endDate) {
	    if (startDate && endDate) {
	      const start = new Date(startDate);
	      const end = endDate === 'present' ? new Date() : new Date(endDate);

	      const totalMonths = differenceInCalendarMonths(end, start);
	      const years = Math.floor(totalMonths / 12);
	      const months = totalMonths % 12;

	      const experience = years + months / 10; // Display one decimal place

	      return experience;
	    } else {
	      return 0;
	    }
	}

	const fetchEmpTotalExperiance = async (row) => {
		let totalExperience = 0;
		if ((row.euser_has_experiences)) {
		    row.euser_has_experiences.map(exe => {
		      if (exe.is_current_job == 1) {
		        totalExperience = parseFloat(totalExperience) + calculateExperience(exe.start_date, 'present');
		      } else {

		        if (exe.start_date && exe.end_date) {
		          totalExperience = parseFloat(totalExperience) + calculateExperience(exe.start_date, exe.end_date);
		        }

		      }

		    })
		}
		return totalExperience;
	}
	/* END EDIT EMPLOYEE  */
	const CustomName = ({ row }) => (

		<div>

			<div className="d-flex py-1 align-items-center">

				
				{

					(row.profile_photo_path && row.profile_photo_path)
						? (
							<span className="avatar    " >

								<img src={CONST.HOST + row.profile_photo_path} alt='' width={40} className="rounded-circle" />
							</span>
						) : (
							<span className="avatar  rounded-circle  ">
								<span className=''>
									<Link to={'#'} data-id={row.id} onClick={() => handleEmpDetailsShow(row)}>
										{(row.first_name && row.last_name)
											? <> {row.first_name && row.first_name.substring(0, 1)}
												{row.last_name && row.last_name.substring(0, 1)}</>
											: row.name && row.name.substring(0, 2)
										}
									</Link>
								</span>
							</span>)

				}

				<div className="flex-fill pl-2">
					<div className="font-weight-medium">
						<Link to={'#'} data-id={row.id} onClick={() => handleEmpDetailsShow(row)}>
							{(row.first_name && row.last_name)
								? <>
									<LinkWithTooltip tooltip="View More Information" id="more-information" css="color_blue" >
										{row.first_name}{row.middle_name && row.middle_name} {row.last_name}
									</LinkWithTooltip>
								</>
								: row.name
							}
						</Link>
					</div>
					<div className="text-muted">
						<Link target="_blank" to={`mailto:${row.email}`} className="text-muted">
							{row.email.toLowerCase()}
						</Link>
					</div>

					{(() => {
						let totalExperience = 0;
						if ((row.euser_has_experiences)) {
						    row.euser_has_experiences.map(exe => {
						      if (exe.is_current_job == 1) {
						        totalExperience = parseFloat(totalExperience) + calculateExperience(exe.start_date, 'present');
						        
						      } else {

						        if (exe.start_date && exe.end_date) {
						          totalExperience = parseFloat(totalExperience) + calculateExperience(exe.start_date, exe.end_date);
						          
						        }

						      }

						    })
						    return (
	                                  <>
	                                  	<div className="text-muted">
	                                    <span className="experience-count"><b> Total Exp. : {totalExperience} Year(s) </b></span></div>
	                                  </>
	                                )
						}
					})()}

				</div>
			</div>
		</div>
	);
	const EConnection = ({ row }) => (
		<>

			<div className="flex-fill">
				<div className="font-weight-medium">
					{(row.designation)
						? <>{row.designation.name}</>
						: ""
					}
				</div>
				<div className="text-muted">
					{(row.department)
						? <>{row.department.name}</>
						: ""
					}
				</div>
			</div>
		</>
	)
	const eDocuments = ({ row }) => (
		<>
			<ul className='socialView'>
				{
					(row.userAadharNumber && (
						<li className=' ' >
							<LinkWithTooltip tooltip="Aadhar Card" href="#" id="tooltip-1">
								<img src={'/assets/img/document/adhaar-card-icon.svg'} width={20} />&nbsp;
								{row.userAadharNumber}
							</LinkWithTooltip>{' '}
						</li>

					))
				}
				{
					(row.userPanNumber && (
						<li className=' '>
							<LinkWithTooltip tooltip="Pan Card" href="#" id="tooltip-2">
								<img src={'/assets/img/document/pan-icon.svg'} width={20} />&nbsp;{row.userPanNumber}
							</LinkWithTooltip>
						</li>
					))
				}
				{
					(row.userUanNumber && (
						<li className=' '>
							<LinkWithTooltip tooltip="UAN Card" href="#" id="tooltip-2"><img src={'/assets/img/document/uan-number.svg'} width={20} />&nbsp;{row.userUanNumber}</LinkWithTooltip></li>
					))
				}

				{
					(row.userEsicNumber && (
						<li className=' '>
							<LinkWithTooltip tooltip="ESIC Card" href="#" id="tooltip-2"><img src={'/assets/img/document/esic-number.svg'} width={20} />&nbsp;{row.userEsicNumber}</LinkWithTooltip></li>
					))
				}

			</ul>
		</>
	)
	const Experience = ({ row }) => (
		<>

		</>
	)

	const Education = ({ row }) => (
		<>

		</>
	)

	/* 	const Guardian = ({ row }) => (
			<>
	
			</>
		) */

	const Leave = ({ row }) => (
		<>

		</>
	)


	const handleEducationShow = (row) => {
		setLoading(true);
		setEducatinModalShow(true);
		setEducationShowModel({
			show: 'show'
		});
		setEditEmpId(row.id);
		setFirstName(row.first_name ? row.first_name : '')
		setLastName(row.last_name ? row.last_name : '')
		setLoading(false);
	}

	const handleExperienceShow = (row) => {
		setLoading(true);
		setFirstName(row.first_name ? row.first_name : '')
		setLastName(row.last_name ? row.last_name : '')
		setEditEmpId(row.id);
		setExperienceModalShow(true);
		setExperienceShowModel({
			show: 'show'
		});

		setLoading(false);
	}

	const handleGuardianShow = (row) => {
		setLoading(true);
		setGuardianModalShow(true);
		setGuardianShowModel({
			show: 'show'
		});
		setEditEmpId(row.id);
		setFirstName(row.first_name ? row.first_name : '')
		setLastName(row.last_name ? row.last_name : '')
		setLoading(false);
	}

	const handleLeaveShow = (row) => {
		setLoading(true);
		setLeaveModalShow(true);
		setLeaveShowModel({
			show: 'show'
		});
		setEditEmpId(row.id);
		setFirstName(row.first_name ? row.first_name : '')
		setLastName(row.last_name ? row.last_name : '')
		setTotalLeaves(row.total_leaves_allocated ? row.total_leaves_allocated : '0')
		setRemainingLeaves(row.remaining_leave_count ? row.remaining_leave_count : '0')
		setConsumedLeaves(row.total_consumed_leaves_count ? row.total_consumed_leaves_count : '0')
		setLoading(false);
	}

	const handleCompOffShow = (row) => {
		setLoading(true);
		setCompoffModalShow(true);
		setCompoffShowModel({
			show: 'show'
		});
		setEditEmpId(row.id);
		setFirstName(row.first_name ? row.first_name : '')
		setLastName(row.last_name ? row.last_name : '')
		seteEmpEmail(row.email ? row.email : '')
		setApprovedCompoff(row.total_approved_compoffs);
		setRejectedCompoff(row.total_rejected_compoffs);
		setLoading(false);
	}

	const handleEmpDetailsShow = (row) => {
		setLoading(true);
		setEmpDetailModalShow(true);
		setEmpDetailsShowModel({
			show: 'show'
		});
		setEditEmpId(row.id);
		setFirstName(row.first_name ? row.first_name : '')
		setLastName(row.last_name ? row.last_name : '')
		setLoading(false);
	}

	const PhoneNumber = ({ row }) => (
		<>
			<div>
				{
					(row.phone_number) && (
						<>
							<LinkWithTooltip tooltip="Phone Number" href="#" id="phone_number" class="">
								<Link href={`tel:${row.phone_number}`} className="text-reset"><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-phone-call" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
									<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
									<path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"></path>
									<path d="M15 7a2 2 0 0 1 2 2"></path>
									<path d="M15 3a6 6 0 0 1 6 6"></path>
								</svg>
									{row.phone_number}</Link>
							</LinkWithTooltip>
						</>
					)
				}
			</div>
			<div className='pt-2'>
				{
					(row.personal_email) && (
						<>
							<LinkWithTooltip tooltip="Personal Email" href="#" id="personal_email" class="">
								<Link href={`mailto:${row.personal_email}`} className="text-reset">
									<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-at" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
										<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
										<path d="M12 12m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"></path>
										<path d="M16 12v1.5a2.5 2.5 0 0 0 5 0v-1.5a9 9 0 1 0 -5.5 8.28"></path>
									</svg>
									{row.personal_email}</Link>
							</LinkWithTooltip>
						</>
					)
				}
			</div>
		</>
	)
	const JoingDate = ({ row }) => (

		<div>

			&nbsp;
			{
				(row && row.date_of_joining) && (
					<>
						<div>
							<LinkWithTooltip tooltip="Date of Joining" href="#" id="date_of_joining" class="">
								<svg xmlns="http://www.w3.org/2000/svg" class=" text-left mb-1  icon icon-tabler icon-tabler-calendar-plus  " width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
									<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
									<path d="M12.5 21h-6.5a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v5"></path>
									<path d="M16 3v4"></path>
									<path d="M8 3v4"></path>
									<path d="M4 11h16"></path>
									<path d="M16 19h6"></path>
									<path d="M19 16v6"></path>
								</svg> {
									(row && row.date_of_joining) ? moment(row.date_of_joining).format('DD-MM-YYYY') : ""
								}
							</LinkWithTooltip>
						</div>
					</>
				)
			}

			{
				(row && row.date_of_birth) &&
				(
					<>
						<div>
							<LinkWithTooltip tooltip="Date of Birth" href="#" id="date_of_birth" class=""  >
								<svg xmlns="http://www.w3.org/2000/svg" className="text-left icon icon-tabler icon-tabler-cake  " width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
									<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
									<path d="M3 20h18v-8a3 3 0 0 0 -3 -3h-12a3 3 0 0 0 -3 3v8z"></path>
									<path d="M3 14.803c.312 .135 .654 .204 1 .197a2.4 2.4 0 0 0 2 -1a2.4 2.4 0 0 1 2 -1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2 -1a2.4 2.4 0 0 1 2 -1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1c.35 .007 .692 -.062 1 -.197"></path>
									<path d="M12 4l1.465 1.638a2 2 0 1 1 -3.015 .099l1.55 -1.737z"></path>
								</svg>
								{
									(row && row.date_of_birth) ? moment(row.date_of_birth).format('DD-MM-YYYY') : ""
								}
							</LinkWithTooltip>
						</div>
					</>
				)
			}
		</div>
	)
	const Action = ({ row }) => (
		<div>



			<Link to={'#'} data-id={row.id} onClick={() => handleExperienceShow(row)}>
				<LinkWithTooltip tooltip="View Experience" id="OfficeEmail" css="color_blue">
					{/* <img src={ExperienceImg} alt='View Experience' width={25} className='cursor mr-2' /> */}
					<svg xmlns="http://www.w3.org/2000/svg" class=" cursor mr-2 icon icon-tabler icon-tabler-briefcase" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
						<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
						<path d="M3 7m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z"></path>
						<path d="M8 7v-2a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v2"></path>
						<path d="M12 12l0 .01"></path>
						<path d="M3 13a20 20 0 0 0 18 0"></path>
					</svg>
				</LinkWithTooltip>
			</Link>
			<Link to={'#'} data-id={row.id} onClick={() => handleEducationShow(row)}>
				<LinkWithTooltip tooltip="View Education" id="Education" css="color_blue">
					<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-award-filled cursor mr-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
						<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
						<path d="M19.496 13.983l1.966 3.406a1.001 1.001 0 0 1 -.705 1.488l-.113 .011l-.112 -.001l-2.933 -.19l-1.303 2.636a1.001 1.001 0 0 1 -1.608 .26l-.082 -.094l-.072 -.11l-1.968 -3.407a8.994 8.994 0 0 0 6.93 -3.999z" stroke-width="0" fill="currentColor"></path>
						<path d="M11.43 17.982l-1.966 3.408a1.001 1.001 0 0 1 -1.622 .157l-.076 -.1l-.064 -.114l-1.304 -2.635l-2.931 .19a1.001 1.001 0 0 1 -1.022 -1.29l.04 -.107l.05 -.1l1.968 -3.409a8.994 8.994 0 0 0 6.927 4.001z" stroke-width="0" fill="currentColor"></path>
						<path d="M12 2l.24 .004a7 7 0 0 1 6.76 6.996l-.003 .193l-.007 .192l-.018 .245l-.026 .242l-.024 .178a6.985 6.985 0 0 1 -.317 1.268l-.116 .308l-.153 .348a7.001 7.001 0 0 1 -12.688 -.028l-.13 -.297l-.052 -.133l-.08 -.217l-.095 -.294a6.96 6.96 0 0 1 -.093 -.344l-.06 -.271l-.049 -.271l-.02 -.139l-.039 -.323l-.024 -.365l-.006 -.292a7 7 0 0 1 6.76 -6.996l.24 -.004z" stroke-width="0" fill="currentColor"></path>
					</svg>
					{/* 	<img src={EducationImg} alt='View Education' width={25} className='cursor mr-2' /> */}
				</LinkWithTooltip>
			</Link>
			<Link to={'#'} data-id={row.id} onClick={() => handleGuardianShow(row)}>
				{/* <img src={GuardianImg} alt='View Guardian' width={25} className='cursor mr-2' /> */}
				<LinkWithTooltip tooltip="View Guardian" id="Guardian" css="color_blue">
					<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-guardian cursor mr-2" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
						<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
						<path d="M14 13h6"></path>
						<path d="M4 12c0 -9.296 9.5 -9 9.5 -9c-2.808 0 -4.5 4.373 -4.5 9s1.763 8.976 4.572 8.976c0 .023 -9.572 1.092 -9.572 -8.976z"></path>
						<path d="M14.5 3c1.416 0 3.853 1.16 4.5 2v3.5"></path>
						<path d="M15 13v8s2.77 -.37 4 -2v-6"></path>
						<path d="M13.5 21h1.5"></path>
						<path d="M13.5 3h1"></path>
					</svg></LinkWithTooltip>
			</Link>
			<Link to={'#'} data-id={row.id} onClick={() => handleLeaveShow(row)}>
				{/* 	<img src={LeaveImg} alt='View Education' width={25} className='cursor mr-2' /> */}
				<LinkWithTooltip tooltip="View Leaves" id="Leaves" css="color_blue">
					<svg xmlns="http://www.w3.org/2000/svg" class="cursor mr-2 icon icon-tabler icon-tabler-door-exit" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
						<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
						<path d="M13 12v.01"></path>
						<path d="M3 21h18"></path>
						<path d="M5 21v-16a2 2 0 0 1 2 -2h7.5m2.5 10.5v7.5"></path>
						<path d="M14 7h7m-3 -3l3 3l-3 3"></path>
					</svg>
				</LinkWithTooltip>

			</Link>
			<Link to={'#'} data-id={row.id} onClick={() => handleCompOffShow(row)}>
				<LinkWithTooltip tooltip="View Comp-off" id="Leaves" css="color_blue">
					<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-align-box-bottom-left" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                       <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                       <path d="M3 3m0 2a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v14a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2z"></path>
                       <path d="M7 15v2"></path>
                       <path d="M10 11v6"></path>
                       <path d="M13 13v4"></path>
                    </svg>  
				</LinkWithTooltip>
			</Link>
			{
				(is_admin == 1 || department_id == 5 || department_id == 8) && (<>
					{/* <img src={editImg} alt='editUser' width={25} className='cursor' onClick={() => handleEmpEdit(row.id)} /> */}
					<LinkWithTooltip tooltip="Edit Emp Info" id="edit" onClick={() => handleEmpEdit(row.id)} css="color_blue">
						<svg xmlns="http://www.w3.org/2000/svg" class="cursor icon icon-tabler icon-tabler-user-edit" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" onClick={() => handleEmpEdit(row.id)}>
							<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
							<path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path>
							<path d="M6 21v-2a4 4 0 0 1 4 -4h3.5"></path>
							<path d="M18.42 15.61a2.1 2.1 0 0 1 2.97 2.97l-3.39 3.42h-3v-3l3.42 -3.39z"></path>
						</svg>
					</LinkWithTooltip>
				</>)
			}


		</div>
	)
	const columns = [
		{
			name: "Name",
			selector: (row) => row.first_name,
			cell: (row) => <CustomName row={row} />,
			sortable: true,
			sortField: "first_name",
			width: '25%',
			wrap: true,
		},
		{
			name: "eConnection",
			selector: (row) => row.erolesData.name,
			cell: (row) => <EConnection row={row} />,
			sortable: false,
			sortField: "name",
			wrap: true, width: '15%',

		},
		{
			name: "Documents",
			selector: (row) => row.erolesData.name,
			cell: (row) => <eDocuments row={row} />,
			sortable: false,
			sortField: "name",
			wrap: true, width: '15%',

		},
		{
			name: "Connect",
			selector: (row) => row.phone_number,
			cell: (row) => <PhoneNumber row={row} />,
			sortable: true,
			sortField: "phone_number",
			wrap: true,
			width: '12%',


		},
		/* {
			name: "Experience",
			cell: (row) => <Experience row={row} />,
			sortable: true,
			sortField: "experience",
			wrap: true,
	
		},
		{
			name: "Education",
			cell: (row) => <Education row={row} />,
			sortable: true,
			sortField: "education",
			wrap: true,
	
		}, */
		/* {
			name: "Guardian",
			cell: (row) => <Guardian row={row} />,
			sortable: true,
			sortField: "guardian",
			wrap: true,
	
		}, */
		/* {
			name: "Leave",
			cell: (row) => <Leave row={row} />,
			sortable: true,
			sortField: "leave",
			wrap: true,
	
		}, */
		{
			name: " Dates",
			cell: (row) => <JoingDate row={row} />,
			sortable: false,
			sortField: "joining_date",
			width: '10%',
			wrap: true,
		}
		,
		{
			name: 'Status',
			selector: (row) => 'Active',
			width: '7%',
			wrap: true,
			selector: (row, index) => <ReactSwitch
				onColor={SwitchProps.onColor}
				onHandleColor={SwitchProps.onHandleColor}
				handleDiameter={SwitchProps.handleDiameter}
				uncheckedIcon={SwitchProps.uncheckedIcon}
				checkedIcon={SwitchProps.checkedIcon}
				boxShadow={SwitchProps.boxShadow}
				activeBoxShadow={SwitchProps.activeBoxShadow}
				height={SwitchProps.height}
				width={SwitchProps.width}
				className={SwitchProps.className}
				id="material-switch"
				checked={(row.status == 1) ? true : false}
				onChange={() => { handleEmpStatusChange(index, row.status, row.id) }
				}

			/>,
		}
		,
		{
			name: "Action",
			cell: (row) => <Action row={row} />,
			sortable: false,
			sortField: "joining_date",
			width: '15%',
			wrap: true,

		}
	]
	/* STATUS CHANGE */
	const handleEmpStatusChange = (index, val, emp_id) => {
		Swal.fire({
			title: 'Are you sure?',
			text: "Do you want to change the status?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, change it!'
		}).then((result) => {
			if (result.isConfirmed) {
				setLoading(true);
				axios.post(CONST.CHANGE_EMP_STATUS,
					{
						'id': user_id,
						'user_id': emp_id,
						'status': (val == '0') ? '1' : '0',
					},
					{
						headers: {
							'Authorization': `Basic ${isToken}`
						}
					}
				)
					.then((response) => {
						//console.log(response);
						const status = response.data.status;
						if (status == 'success') {
							toast.success(response.data.message);
						} else {
							toast.error(response.data.message);
						}
					})
					.catch((error) => {
						//	console.log(error);
						toast.error("There was an error.");
					});
				const newData = [...data];
				newData[index].status = (val == 0) ? 1 : 0;
				setData(newData);
				setLoading(false);
			}
		})
	}
	const handleSearch = () => {
		setSearchParam({
			"srchEmpName": srchEmpName,
			"srchEmpEmail": srchEmpEmail,
			"srchEmpStatus": srchEmpStatus,
			"srchEmpDepartment": srchEmpDepartment,
			"srchdesignation": srchdesignation,
		});
		setDataSort({
			order: 'desc',
			order_by: 'id'
		});
		setActivePage(1);
	}
	const handleReSetSearch = () => {
		setSrchEmpName("");
		setSrchEmpEmail("");
		setSrchEmpStatus("");
		setSrchEmpDepartment("");
		setActivePage(1);
		setSearchParam(
			{
				"srchEmpName": "",
				"srchEmpEmail": "",
				"srchEmpStatus": "",
				"srchEmpDepartment": "",
				"srchdesignation": "",
			}
		);
		setDataSort({
			order: 'desc',
			order_by: 'id'
		});
		setActivePage(1);
	}
	const handleSort = async (column, sortDirection) => {
		setSrchEmpName("");
		setSrchEmpEmail("");
		setSrchEmpStatus("");
		setSrchEmpDepartment("");
		setActivePage(1);
		setDataSort({
			order: sortDirection,
			order_by: column.sortField
		})
		fetchUserData(
			{
				srchEmpName,
				srchEmpEmail,
				srchEmpStatus,
				srchEmpDepartment,
			},
			{
				order: sortDirection,
				order_by: column.sortField
			},
			activePage, perPage
		);
	};
	async function fetchDeptList() {
		setLoading(true);
		await axios
			.get(CONST.GET_DEPARTMENT, {
				headers: {
					'Authorization': `Basic ${isToken}`
				}
			})
			.then((response) => {
				let status = response.data.status;
				//console.log(response)
				if (status == 'success') {
					setDptListing(response.data.data);
				}
			})
			.catch((error) => {
				toast.error(error);
			});
		setLoading(false);
	}
	async function fetchUserData(paramSearch, paramSort, paramPage, paramLimit) {
		//alert(JSON.stringify(paramSearch));
		console.log(paramSearch);
		setLoading(true);
		const response = await axios
			.post(CONST.EMP_LIST,
				{
					"limit": paramLimit,
					"page_number": paramPage,
					"search_input": paramSearch,
					"sort": paramSort,
				},
				{
					headers: {
						'Authorization': `Basic ${isToken}`
					}
				}
			)
			.then((response) => {
				//console.log('emp list >> ' + response);
				const status = response.data.status;
				if (status == 'success') {
					setTotalRows(response.data.count);
					setData(response.data.data);
					const userListJson = response.data.data;
					const transformedData = userListJson.map(user => ({
						...user,
						first_name: `${user.first_name} ${user.last_name}`,
						department: `${user.department.name}`,
						designation: `${user.designation.name}`,
					}));
					setfilterUsers(transformedData);
					settotalRecords(response.data.count);
				} else {
					setTotalRows("0");
					setData("");
					settotalRecords("0");
					//toast.error(response.data.message);
				}
			})
			.catch((error) => {
				setTotalRows("0");
				setData("");
				settotalRecords("0");
				toast.error("There was an error.");
			});
		setLoading(false);
	}
	/* 	const handlePerRowsChange = async (perPage, page) => {
			alert('here');
			setLoading(true);
			const response = await axios.get(
				`https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`
			);
			setData(response.data.data);
			setPerPage(perPage);
			setLoading(false);
		}; */
	const handlePageLimit = async (val) => {
		setSrchEmpName("");
		setSrchEmpEmail("");
		setSrchEmpStatus("");
		setSrchEmpDepartment("");
		setActivePage(1);
		setSearchParam(
			{
				"srchEmpName": "",
				"srchEmpEmail": "",
				"srchEmpStatus": "",
				"srchEmpDepartment": "",
				"srchdesignation": "",
			}
		);
		setDataSort({
			order: 'desc',
			order_by: 'id'
		});
		setActivePage(1);
		setPerPage(parseInt(val));

	}

	/*  PAGINATION FUNCTIONS  */
	const handlePageChange = async (page) => {
		setActivePage(parseInt(page));
	};
	const BootyPagination = ({
		rowsPerPage,
		rowCount,
		onChangePage,
		onChangeRowsPerPage, // available but not used here
		currentPage,
	}) => {

		function toPages(pages) {
			const results = [];
			for (let i = 1; i <= pages; i++) {
				results.push(i);
			}
			return results;
		}
		function getNumberOfPages(rowCount, rowsPerPage) {
			//console.log(rowCount, rowsPerPage);
			return Math.ceil(rowCount / rowsPerPage);
		}

		const handleBackButtonClick = async () => {
			if ((currentPage - 1) % perPage === 0) {
				setMaxPageLimit(maxPageLimit - perPage);
				setMinPageLimit(minPageLimit - perPage);
			}
			onChangePage(currentPage - 1);
		};

		const handleNextButtonClick = async () => {
			if (currentPage + 1 > maxPageLimit) {
				setMaxPageLimit(maxPageLimit + perPage);
				setMinPageLimit(minPageLimit + perPage);
			}

			onChangePage(currentPage + 1);
		};

		const handlePageNumber = (e) => {
			onChangePage(Number(e.target.value));
		};

		let paginationText = "Page:  " + currentPage + ", Showing ";

		paginationText =
			paginationText +
			((currentPage - 1) * perPage + 1) +
			" to " +
			perPage * currentPage;
		paginationText = paginationText + " of " + rowCount + " entries;";


		const pages = getNumberOfPages(rowCount, perPage);

		const pageItems = toPages(pages);
		const nextDisabled = currentPage === pageItems.length;
		const previosDisabled = currentPage === 1;
		const pageNumbers = pageItems.map((page, index) => {
			if (page <= maxPageLimit && page > minPageLimit) {
				return (
					<li
						key={index}
						id={page}
						className={`page-item ${currentPage === page ? "active" : ""}`}
					>
						<button
							className={`page-link ${currentPage === page ? "active" : ""}`}
							onClick={handlePageNumber}
							value={page}
						>
							{page}
						</button>
					</li>
				);
			} else {
				return null;
			}
		});
		let pageIncrementEllipses = null;
		if (pageItems.length > maxPageLimit) {
			pageIncrementEllipses = (
				<li className="page-item" onClick={handleNextButtonClick}>
					&hellip;
				</li>
			);
		}
		let pageDecremenEllipses = null;
		if (minPageLimit >= 1) {
			pageDecremenEllipses = (
				<li className="page-item" onClick={handleBackButtonClick}>
					&hellip;
				</li>
			);
		}


		return (
			<>
				<div className='row card-footer'>
					<div className='col-md-4 align-self-center'>{paginationText}</div>
					<div className='col-md-8 text-align-right'>
						<ul className="pagination pagination text-align-right float-right">
							<li className="page-item">
								<button
									className="page-link"
									onClick={handleBackButtonClick}
									disabled={previosDisabled}
									aria-disabled={previosDisabled}
									aria-label="previous page"
									pageRangeDisplayed={10}
								>
									«
								</button>
							</li>
							{pageDecremenEllipses}
							{pageNumbers}
							{pageIncrementEllipses}
							<li className="page-item">
								<button
									className="page-link"
									onClick={handleNextButtonClick}
									disabled={nextDisabled}
									aria-disabled={nextDisabled}
									aria-label="next page"
								>
									»
								</button>
							</li>
						</ul></div>
				</div>

			</>
		);
	};
	/* END DATATABLE */
	const CustomLoader = () => (
		<div style={{ padding: "24px" }}>
			<DivLoader color="primary" />
		</div>
	);

	const fetchDesigtList = async (desig_id) => {
		setSrchEmpDepartment(desig_id);
		await axios
			.get(CONST.GET_DESIG_BY_ID + '/' + desig_id, {
				headers: {
					'Authorization': `Basic ${isToken}`
				}
			})
			.then((response) => {
				let status = response.data.status;

				if (status == 'success') {
					setDesigListing(response.data.data);
				}
			})
			.catch((error) => {
				toast.error(error);
			});
	}

	function LinkWithTooltip({ id, children, href, tooltip, css }) {
		return (
			<OverlayTrigger
				overlay={<Tooltip id={id}>{tooltip}</Tooltip>}
				placement="top"
				delayShow={300}
				delayHide={150}
			>
				<span class={css}>{children}</span>
			</OverlayTrigger >
		);
	}


	return (
		<>
			<Loading
				loading={fullLoading}
				background="rgba(0, 0, 0, 0.5)"
				loaderColor="#fff"
			/>
			<div className="page-header d-print-none">
				<div className="container-xl">
					<div className="row g-2 align-items-center">
						<div className="col">
							<div className="page-pretitle">Manage</div>
							<h2 className="page-title">Employees</h2>
						</div>

						<div className="col-12 col-md-auto ms-auto d-print-none">
							<div className="btn-list">
								<Link
									to={CONST.ADMIN_PRE + 'employee/add'}
									className="btn btn-primary pr-1"

								>
									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="icon icon-tabler icon-tabler-plus"
										width="24"
										height="24"
										viewBox="0 0 24 24"
										strokeWidth="2"
										stroke="currentColor"
										fill="none"
										strokeLinecap="round"
										strokeLinejoin="round"
									>
										<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
										<line x1="12" y1="5" x2="12" y2="19"></line>
										<line x1="5" y1="12" x2="19" y2="12"></line>
									</svg>

									&nbsp;Add Employee
								</Link>


							</div>
						</div>


					</div>
				</div>
			</div>
			<div className="page-body">
				<div className="container-xl">
					<div className="row row-cards">
						<div className="col-12">
							<div className="card">
								<div className="card-header">
									<h3 className="card-title">Listing</h3>
								</div>
								<div className="card-body border-bottom py-3">
									<div className="row">
										<div className="col-md-2">
											<div className="form-group mb-3 ">
												<label className="form-label required">Name</label>
												<div>
													<input
														type="text"
														name="srchEmpName"
														id="srchEmpName"
														className={`form-control ${validate.validate && validate.validate.srchEmpName
															? "is-invalid "
															: ""
															}`}
														aria-describedby="emailHelp"
														placeholder="Enter name"
														onChange={(e) => setSrchEmpName(e.target.value)}
														value={srchEmpName}
													/>
												</div>
											</div>
										</div>
										<div className="col-md-2">
											<div className="form-group mb-3 ">
												<label className="form-label required">Email</label>
												<div>
													<input
														type="text"
														name="srchEmpEmail"
														id="srchEmpEmail"
														className={`form-control ${validate.validate && validate.validate.srchEmpEmail
															? "is-invalid "
															: ""
															}`}
														aria-describedby="emailHelp"
														placeholder="Enter email"
														onChange={(e) => setSrchEmpEmail(e.target.value)}
														value={srchEmpEmail}
													/>
												</div>
											</div>
										</div>
										{
											(department_id == 5 || department_id == 8 || is_admin == 1) &&
											(
												<>
													<div className="col-md-2">
														<div className="mb-3">
															<label className="form-label required">
																Department
															</label>
															{
																dptListing ?
																	(
																		<>
																			<select
																				className="form-select form-control"
																				name="srchEmpDepartment"
																				id="srchEmpDepartment"
																				placeholder="Select status"
																				onChange={(e) => fetchDesigtList(e.target.value)}
																				value={srchEmpDepartment}
																			>
																				<option value="">Select</option>
																				{
																					dptListing && dptListing.map((element, key) => {
																						return (
																							<>
																								<option key={key} value={element.id}>{element.name}</option>
																							</>
																						)
																					}
																					)
																				}
																			</select>
																		</>
																	)
																	: (
																		<DivSpinner size="sm" color="primary" />
																	)
															}



														</div>
													</div>
													<div className="col-md-2">
														<div className="mb-3 ">
															<label className="form-label required">
																Designation
															</label>
															<div>
																{
																	dptListing ?
																		(
																			<>
																				<select
																					className="form-select form-control"
																					name="designation"
																					id="designation"
																					placeholder="Select designation"
																					onChange={(e) => setsrchDesignation(e.target.value)}
																					value={srchdesignation}
																				>
																					<option value="">Select</option>
																					{
																						desigListing && desigListing.map((element, key) => {
																							return (
																								<>
																									<option key={key} value={element.id}>{element.name}</option>
																								</>
																							)
																						}
																						)
																					}
																				</select>
																			</>
																		)
																		: (
																			<DivSpinner size="sm" color="primary" />
																		)
																}
															</div>


														</div>
													</div>
												</>
											)
										}
										<div className="col-md-2">
											<div className="mb-3">
												<label className="form-label required">
													Status
												</label>

												<select
													className="form-select form-control"
													name="srchEmpStatus"
													id="srchEmpStatus"
													placeholder="Select status"
													onChange={(e) => setSrchEmpStatus(e.target.value)}
													value={srchEmpStatus}
												>
													<option value="">Select</option>
													<option value="1">Active</option>
													<option value="0">Deactive</option>

												</select>


											</div>
										</div>
										<div className="col-md-4">
											<div className="form-group mb-3 ">
												<label className="form-label">&nbsp;</label>
												<div>
													<button
														type="button"
														name="btnSearch"
														id="btnSearch"
														className="btn btn-blue d-none d-sm-inline-block mr-2 "
														onClick={handleSearch}
													><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
															<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
															<circle cx="10" cy="10" r="7"></circle>
															<line x1="21" y1="21" x2="15" y2="15"></line>
														</svg>Search</button>&nbsp;
													<button
														type="button"
														name="btnSearchReset"
														id="btnSearchReset"
														className="btn btn-outline-primary mr-2"
														onClick={handleReSetSearch}
													>
														<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
															<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
															<line x1="18" y1="6" x2="6" y2="18"></line>
															<line x1="6" y1="6" x2="18" y2="18"></line>
														</svg>Clear</button>
													<ExportCsv propsEmpName={filterUsers} propsHeaders={headers} />
												</div>
											</div>
										</div>

									</div>
									<div className='row'>
										<div className="col-md-6">
											<nav class="limit-pagination pt-3">
												<span class="">Rows per page:</span>
												<select aria-label="Rows per page:"
													class="sc-cwSeag jaZsat"
													value={perPage}
													onChange={(e) => handlePageLimit(e.target.value)}>
													<option value="10">10</option>
													<option value="20">20</option>
													<option value="30">30</option>
													<option value="50">50</option>
													<option value="100">100</option>
												</select>
												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7 10l5 5 5-5z"></path><path d="M0 0h24v24H0z" fill="none"></path></svg>
											</nav>

										</div>
									</div>
								</div>
								<div className="table-responsive">

									<DataTable
										columns={columns}
										data={data}
										progressPending={loading}
										progressComponent={<CustomLoader />}
										pagination="true"
										paginationServer
										paginationTotalRows={totalRows}
										/* 	onChangeRowsPerPage={handlePerRowsChange} */
										onChangePage={handlePageChange}
										paginationPerPage={perPage}
										paginationComponent={BootyPagination}
										sortServer
										onSort={handleSort}
									/>

								</div>
								<div className="card-footer d-flex align-items-center">
									<p className="m-0 text-muted">
										Showing {/* <span>1</span> to <span>8</span> of{" "} */}
										<span>{totalRecords}</span> entries
									</p>

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{
				(showModel.show == 'show' && <EditEmp childToParent={childToParent} editEmpId={editEmpId} />)
			}
			{
				(showGuardianModel.show == 'show' && <ViewGuardian closeGuardian={closeGuardian} editEmpId={editEmpId} firstName={firstName} lastName={lastName} />)
			}
			{
				(showExperienceModel.show == 'show' && <ViewExperience closeExperience={closeExperience} editEmpId={editEmpId} firstName={firstName} lastName={lastName} />)
			}
			{
				(showEducationModel.show == 'show' && <ViewEducation closeEducation={closeEducation} editEmpId={editEmpId} firstName={firstName} lastName={lastName} />)
			}
			{
				(showLeaveModel.show == 'show' && <ViewLeaves closeLeave={closeLeave} editEmpId={editEmpId} firstName={firstName} lastName={lastName} totalLeaves={totalLeaves} remainingLeaves={remainingLeaves} consumedLeaves={consumedLeaves} />)
			}
			{
				(showCompoffModel.show == 'show' && <ViewCompOff closeCompoff={closeCompoff} editEmpId={editEmpId} firstName={firstName} lastName={lastName} empEmail={empEmail} approvedCompoff={approvedCompoff} rejectedCompoff={rejectedCompoff} />)
			}
			{
				(showEmpDetailsModel.show == 'show' && <ShowDetails closeEmpDetails={closeEmpDetails} editEmpId={editEmpId} firstName={firstName} lastName={lastName} />)
			}
		</>
	)
}

export default List