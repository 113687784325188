import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import { Link } from "react-router-dom";
import "../../assets/css/home.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Helmet } from "react-helmet";
import * as  CONST from "../../component/config/Constant"
import axios from "axios";
import parse from 'html-react-parser';
/* WEB_POLICY_LISTING */
function Home() {

  const [policies, setPolicies] = useState("");
  const [webOpeningList, setwebOpeningList] = useState('');
  const [testimonials, setTestimonials] = useState("");
  const [testimonialsCount, setTestimonialsCount] = useState(0);
  const [events, setEvents] = useState("");


  useEffect(() => {
    fetchPolicyDetails();
    fetchWebOpeningsData();
    fetchTestimonials();
    fetchEventData();
    //fetchEvents();
  }, []);

  const [searchParam, setSearchParam] = useState(
    {
      "srchStatus": '1',
    }
  );

  const fetchTestimonials = async () => {
    await axios
      .get(CONST.TESTIMONIAL_LIST,
        {
          page_number: 1,
          limit: 10
        })
      .then((response) => {
        console.log('tESTIMONIALS-'+JSON.stringify(response.data.count));
        const status = response.data.status;
        if (status === 'success') {
          
          setTestimonials(response.data.data);
          setTestimonialsCount(response.data.count);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const fetchPolicyDetails = async () => {
    await axios
      .get(CONST.WEB_GET_POLICY_LIST,
        {
          page_number: 1,
          limit: 10
        })
      .then((response) => {
        const status = response.data.status;
        if (status === 'success') {
          console.log(response.data.data);
          setPolicies(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  async function fetchWebOpeningsData() {
    const response = await axios
      .post(CONST.GET_OPENINGS_WEB_LIST, {
        'page_number': 1,
        'limit': 6,
      })
      .then((response) => {
        const status = response.data.status;
        if (status === 'success') {
          setwebOpeningList(response.data.data);
        }
      });
  }

  const fetchEventData = async (cat_id) => {

    await axios
      .post(CONST.WEB_GET_EVENTDATA,
        {
          "sort": {
            "order": "asc",
            "order_by": "id"
          },
          "page_number": 1,
          "limit": 6

        })

      .then((response) => {
        console.log("eventweb events: " + JSON.stringify(response.data))
        const status = response.data.status;
        if (status === 'success') {
          setEvents(response.data.data);
          console.log('web events=' + JSON.stringify(events));
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const TestimonialOptions = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 3,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
  };
  const RedablePolicyOptions = {
    margin: 30,
    responsiveClass: true,
    nav: true,
    autoplay: true,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      400: {
        items: 1,
      },
      600: {
        items: 3,
      },
      700: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
  };

  function convertToSlug(Text) {
    return Text.toLowerCase()
      .replace(/[^\w ]+/g, '')
      .replace(/ +/g, '-');
  }

  const capitalizeFirst = str => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };
  let policy_link = '';
  let policy_target_link = '';

  console.log(webOpeningList.length);

  return (
    <>
      <Helmet>
        <title>eLuminous Technologies | Human Resource Management</title>
        <link rel="canonical" href="/" />
      </Helmet>
      {/* BANNER  */}
      <section className="hero-section-wrapper-1 ">
        <div className="hero-section hero-style-1">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <div className="hero-content-wrapper pt-5 wow fadeInLeft " data-wow-delay="0.5s">
                  <h1>
                    Welcome to{" "}
                    <span className="color_blue">eLuminous Technologies</span>
                  </h1>
                  <h5 className="mt-3 mb-5">
                    We deliver Technology Solutions that gives your business
                    the edge it demands the most.
                  </h5>
                  <h6></h6>
                  {/*  <Link to="" className="button button-lg radius-0">
                    Get Started <i className="lni lni-chevron-right pl-2"></i>
                  </Link> */}
                </div>
              </div>
              <div className="col-lg-6 align-self-end">
                <div className="hero-image wow fadeInRight" data-wow-delay="0.5s">
                  <img className=""
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/img/hero/hero-1/hero-img-1.svg"
                    }
                    alt="banner-image"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="shapes">
            <img
              src={
                process.env.PUBLIC_URL + "/assets/img/hero/hero-1/shape-1.svg"
              }
              alt=""
              className="shape shape-1"
            />
            <img
              src={
                process.env.PUBLIC_URL + "/assets/img/hero/hero-1/shape-2.svg"
              }
              alt=""
              className="shape shape-2"
            />
            <img
              src={
                process.env.PUBLIC_URL + "/assets/img/hero/hero-1/shape-3.svg"
              }
              alt=""
              className="shape shape-3"
            />
            <img
              src={
                process.env.PUBLIC_URL + "/assets/img/hero/hero-1/shape-4.svg"
              }
              alt=""
              className="shape shape-4"
            />
          </div>
        </div>
      </section>
      {/*Normal Policy */}
      <section className="gallery-style-2 our_policy ">
        <div className="gallery-wrapper">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="section-title text-center mb-5">
                  <h3 className="mb-15">Our Policies</h3>

                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              {

                policies && policies.map((policy, index) => {
                  if (policy.e_policy_type.name == 'Textual Policies') {
                    policy_link = "/policy/" + policy.id + "/" + convertToSlug(policy.policy_name);
                    policy_target_link = "";
                  } else {
                    policy_link = CONST.HOST + policy.policy_document_path;
                    policy_target_link = "_blank";
                  }
                  return (

                    <>
                      <div className="col-12 col-md-4 text-align-left mb-3">
                        <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-caret-right-filled mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M9 6c0 -.852 .986 -1.297 1.623 -.783l.084 .076l6 6a1 1 0 0 1 .083 1.32l-.083 .094l-6 6l-.094 .083l-.077 .054l-.096 .054l-.036 .017l-.067 .027l-.108 .032l-.053 .01l-.06 .01l-.057 .004l-.059 .002l-.059 -.002l-.058 -.005l-.06 -.009l-.052 -.01l-.108 -.032l-.067 -.027l-.132 -.07l-.09 -.065l-.081 -.073l-.083 -.094l-.054 -.077l-.054 -.096l-.017 -.036l-.027 -.067l-.032 -.108l-.01 -.053l-.01 -.06l-.004 -.057l-.002 -12.059z" stroke-width="0" fill="currentColor"></path></svg><Link to={policy_link} target={policy_target_link}>
                          {policy.policy_name}
                        </Link>
                      </div>
                    </>
                  )
                })
              }
            </div>


          </div>
        </div>
      </section>


      {/* Career */}
      <section className=" career careers container mt-5 pb-5 background_color" >
        <div className="row">
          <div className="row justify-content-center">
            <div className="col-xxl-5 col-xl-5 col-lg-7 col-md-8">
              <div className="section-title text-center mb-5  wow fadeIn " data-wow-delay="0.5s">
                <h3 className="mb-15">Career</h3>

              </div>
            </div>
          </div>
          <div className="row">
            {
              webOpeningList && webOpeningList.map((element, key) => {
                return (
                  <>
                    {(() => {

                      if (element.status == '1') {
                        return (
                          <>
                            <div className="col-md-12 col-lg-6 col-sm-12">
                              <Link className="page-scroll" to={"/openings-details/" + element.id}>
                                <div className="card p-3 mb-3  wow fadeInRight " data-wow-delay="0.5s">
                                  <div className="d-flex justify-content-between">
                                    <div className="d-flex flex-row align-items-center">

                                      <div className="ms-2 c-details">
                                        <h6 className="mb-0"> {capitalizeFirst(element.location)} <span>{element.posted_at} days ago</span></h6>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mt-3">
                                    <h4 className="heading">
                                      {element.title}
                                    </h4>
                                    <div className='row'>
                                      <div className='col-md-9 '>
                                        <div className="mt-3">
                                          <div className="progress">
                                            <div
                                              className="progress-bar"
                                              role="progressbar"
                                              style=
                                              {{
                                                width: ((element.applied_count / element.total_openings) * 100) + "%"
                                              }}
                                              aria-valuenow={element.applied_count}
                                              aria-valuemin="0"
                                              aria-valuemax={element.total_openings}
                                            ></div>
                                          </div>
                                          <div className="mt-2">
                                            {" "}
                                            <span className="text1">
                                              {element.applied_count} Applied
                                            </span>{" "}
                                          </div>
                                        </div>
                                      </div>
                                      <div className='col-md-3 text-right'>
                                        <button className='btn btn-primary'>Apply Now</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </div>
                          </>
                        )
                      }
                    })()}
                  </>
                )
              })
            }
          </div>
          {(() => {

            if (webOpeningList.length > 1) {
              return (
                <>
                  <div className="col-md-12 ">
                    <div className="row">
                      <div className="section-title text-center mt-5  wow fadeIn">
                        <Link to="/careers" className="btn btn-primary">View All</Link>
                      </div>
                    </div>
                  </div>
                </>
              )
            }
          })()}
        </div>
      </section >

      {/* Testimonials */}
      < section className="testimonial-style-2 background_white testimonials_section" >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title text-center mb-60  wow fadeIn " data-wow-delay="1.5s">
                <h3>Testimonials</h3>
                <p>What our employee Think about us</p>
              </div>
            </div>
          </div>
          {/* {
                testimonials
                ?
                <>
                (
                <div className="row wow fadeIn " data-wow-delay="0.5s">
                  <OwlCarousel {...TestimonialOptions} id="testimonial">
                    
                      testimonials && testimonials.map((testimonial, index) => {
                        let testimonials_desc = testimonial.description.replace(/(<([^>]+)>)/ig, '');
                        testimonials_desc = testimonials_desc.replace('&nbsp;', ' ');
                        return (
                          <div className="item">
                            <div className="single-testimonial">
                              <div className="content-wrapper">
                                <div className="info">
                                  <div className="image">
                                    {
                                      testimonial.user && (
                                        (testimonial.user.profile_photo_path && testimonial.user.profile_photo_path)
                                          ? (
                                            <span className="avatar me-2" >

                                              <img src={CONST.HOST + testimonial.user.profile_photo_path} alt='' width={40} />
                                            </span>
                                          ) : (
                                            <span className="avatar me-2">
                                              <span>

                                                {(testimonial.user.first_name && testimonial.user.last_name)
                                                  ? <> {testimonial.user.first_name && testimonial.user.first_name.substring(0, 1)}
                                                    {testimonial.user.last_name && testimonial.user.last_name.substring(0, 1)}</>
                                                  : testimonial.user.name && testimonial.user.name.substring(0, 2)
                                                }
                                              </span>
                                            </span>
                                          )
                                      )
                                    }
                                  </div>
                                  <div className="text">
                                    <h6>
                                      {
                                        testimonial.user && (
                                          <>
                                            {testimonial.user.first_name} {testimonial.user.last_name}
                                          </>

                                        )
                                      }
                                    </h6>
                                    <p>{
                                      testimonial.user && (
                                        testimonial.user.department.name
                                      )
                                    }
                                    </p>
                                  </div>
                                </div>
                                <div className="content">
                                  <p>
                                    {testimonials_desc}
                                  </p>
                                  <div className="quote">
                                    <i className="lni lni-quotation"></i>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      })
                      
                  </OwlCarousel>
                </div>
                )
                </>
                :
                <><div>Testimonials not found!</div></>
          } */}

          {
            (testimonials && (testimonialsCount > 0)) ? (
              <>
                <div className="row wow fadeIn " data-wow-delay="0.5s">
                  <OwlCarousel {...TestimonialOptions} id="testimonial">
                    {testimonials.map((testimonial, index) => {
                      let testimonials_desc = testimonial.description.replace(/(<([^>]+)>)/ig, '');
                      testimonials_desc = testimonials_desc.replace('&nbsp;', ' ');
                      return (
                        <div className="item" key={index}>
                          <div className="single-testimonial">
                            <div className="content-wrapper">
                              <div className="info">
                                <div className="image">
                                  {testimonial.user && (
                                    (testimonial.user.profile_photo_path && testimonial.user.profile_photo_path)
                                      ? (
                                        <span className="avatar me-2">
                                          <img src={CONST.HOST + testimonial.user.profile_photo_path} alt='' width={40} />
                                        </span>
                                      ) : (
                                        <span className="avatar me-2">
                                          <span>
                                            {(testimonial.user.first_name && testimonial.user.last_name)
                                              ? <> {testimonial.user.first_name && testimonial.user.first_name.substring(0, 1)}
                                                {testimonial.user.last_name && testimonial.user.last_name.substring(0, 1)}</>
                                              : testimonial.user.name && testimonial.user.name.substring(0, 2)
                                            }
                                          </span>
                                        </span>
                                      )
                                  )}
                                </div>
                                <div className="text">
                                  <h6>
                                    {testimonial.user && (
                                      <>
                                        {testimonial.user.first_name} {testimonial.user.last_name}
                                      </>
                                    )}
                                  </h6>
                                  <p>
                                    {testimonial.user && (
                                      testimonial.user.department.name
                                    )}
                                  </p>
                                </div>
                              </div>
                              <div className="content">
                                <p>
                                  {testimonials_desc}
                                </p>
                                <div className="quote">
                                  <i className="lni lni-quotation"></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </OwlCarousel>
                </div>
              </>
            ) : (
              <div class="center">Testimonials not found!</div>
            )
          }

        </div>
      </section >
      {/* About Us */}

      {/*   <section id="about" className="about-section about-style-4 trusted_partner background_white " >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-7 col-lg-6">
              <div className="about-image text-lg-left wow fadeInLeft " data-wow-delay="0.5s">
                <img
                  className="img-fluid"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/img/about/about-1/trus.svg"
                  }
                  alt=""
                />
              </div>
            </div>
            <div className="col-xl-5 col-lg-6">
              <div className="about-content-wrapper wow fadeInRight " data-wow-delay="0.5s">
                <div className="section-title mb-30">
                  <h3 className="mb-25">
                    eLuminous Technologies - Your Trusted IT Partner
                  </h3>
                  <p>
                    We deliver Technology Solutions that gives your business the
                    edge it demands the most.
                  </p>
                </div>
                <ul className="pl-0">
                  <li>
                    <i className="lni lni-checkmark-circle color_blue"></i>
                    100% Export Oriented Unit
                  </li>
                  <li>
                    <i className="lni lni-checkmark-circle color_blue"></i>
                    Member of STPI (Software Technology Parks of India)
                  </li>
                  <li>
                    <i className="lni lni-checkmark-circle color_blue"></i>
                    20+ Years of IT Experience
                  </li>
                  <li>
                    <i className="lni lni-checkmark-circle color_blue"></i>
                    100+ Development Experts
                  </li>
                  <li>
                    <i className="lni lni-checkmark-circle color_blue"></i>
                    30+ Industry Segments served successfully
                  </li>
                </ul>
                <a href="#0" className="button button-lg radius-0">
                  Learn More <i className="lni lni-chevron-right pl-2"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      {/*   <section id="feature" className="feature-section specializing mb-0 mt-0 our_expertise pt-0 position_rel" >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-5 col-xl-5 col-lg-7 col-md-8">
              <div className="section-title text-center mb-60">
                <h3 className="mb-15">Our Expertise</h3>
                <p>Cutting-edge solutions using latest technologies</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6  wow bounceInUp " data-wow-delay="0.5s">
              <div className="single-feature">
                <div className="icon">
               
                  <i className="lni lni-code"></i>
                </div>
                <div className="content">
                  <h5>Web Application Development</h5>
                  <p>Responsive, Scalable, Customer - centric Web Apps</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6  wow bounceInUp " data-wow-delay="0.5s">
              <div className="single-feature">
                <div className="icon">
                  <i className="lni lni-vector"></i>
                  
                </div>
                <div className="content">
                  <h5>Front-End Development</h5>
                  <p>Pixel Perfect PSD to HTML, PSD to CMS, PSD to Email</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6  wow bounceInUp " data-wow-delay="0.5s">
              <div className="single-feature">
                <div className="icon">
                 
                  <i className="lni lni-mobile"></i>
                </div>
                <div className="content">
                  <h5>Mobile App Development</h5>
                  <p>Android, iOS, Cross-Platform, Hybrid Apps.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6  wow bounceInUp " data-wow-delay="0.9s">
              <div className="single-feature">
                <div className="icon">
                  <i className="lni lni-stats-up"></i>
                 
                </div>
                <div className="content">
                  <h5>Data Analytics & BI</h5>
                  <p>
                    Dashboard Creation, 100%, Data Security, Right Business,
                    Insights
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6  wow bounceInUp " data-wow-delay="0.9s">
              <div className="single-feature">
                <div className="icon">
                 
                  <i className="lni lni-code-alt"></i>
                </div>
                <div className="content">
                  <h5>Digital Marketing Services</h5>
                  <p>SEO,SEM, SMM</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6  wow bounceInUp " data-wow-delay="0.9s">
              <div className="single-feature">
                <div className="icon">
                  <i className="lni lni-users"></i>
                </div>
                <div className="content">
                  <h5>Virtual Assistant Services</h5>
                  <p>Experience & Talented Virtual Executives</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img src={process.env.PUBLIC_URL + "/assets/img/hero/hero-1/shape-4.svg"} alt="" className="shape shape-7" />
      </section> */}

      {/* Events */}
      <section id="gallery" className="gallery-style-1 event_section background_color ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xxl-5 col-xl-5 col-lg-7 col-md-8">
              <div className="section-title text-center mb-60   wow fadeIn " data-wow-delay="0.5s">
                <h3 className="mb-15">Events</h3>

              </div>
            </div>
          </div>
          <div className="grid_images">
            {
              events && events.map((event, index) => {
                return (
                  <>
                    <div className="single-gallery wow fadeIn " data-wow-delay="0.5s">
                      
                      {
                        event.e_event_images && event.e_event_images.map((ele, eKey) => {
                          return (          
                            (ele.is_featured_image == 1)
                            ?
                            <>                   
                            <div className="image">                                          
                                <img src={CONST.HOST+ele.image_path} class="img-fluid" alt="Responsive image" />
                            </div>
                            </>
                            :
                            ''
                          )
                        })
                      }
                        
                      
                      <div className="overlay">
                        <div className="overlay-content">
                          <div className="action">
                            <a href="#0">
                              
                              <i className="lni lni-link"></i>
                            </a>
                          </div>
                          <div className="info">
                            <h5>{event.title}</h5>
                            <div class="desc">{parse(event.description.substring(0,100))}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )
              })
            }
          </div>
        </div>
      </section>


    </>
  );
}
export default Home;
