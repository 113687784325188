import { React, useState, useEffect, useContext, useMemo } from 'react'
import "../../../assets/css/table.css";
import Loading from "react-fullscreen-loading";
import DivLoader from "../../../component/utilities/Spinner";
import DataTable from "react-data-table-component";
import axios from "axios";
import { toast } from "react-toastify";
import * as  CONST from "../../../component/config/Constant"
import { AuthContext } from '../../../component/context/AuthContext';
import { Link } from 'react-router-dom';
import Moment from "moment";
import editImg from "../../../assets/img/icon/edit.svg";
import deleteImg from "../../../assets/img/icon/delete.svg";
import ReactSwitch from 'react-switch';
import * as SwitchProps from '../../../component/utilities/ReactSwitchReactSwitchProps';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
//import EventCategory from '../../FieldComponents/EventCategory';
import DivSpinner from '../../../component/utilities/Spinner';
import ReactPaginate from 'react-paginate';


function List() {

  const [authUserData, setAuthUserData] = useContext(AuthContext);
  const [fullLoading, setFullLoading] = useState(false);
  const [validate, setValidate] = useState({});
  const [processing, setProcessing] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [srchEventName, setSrchEventName] = useState("");
  const [srchStatus, setSrchStatus] = useState("");
  const [srchCatName, setSrchCatName] = useState("");
  const [webOpeningList, setwebOpeningList] = useState('');
  const [isAddModalShow, setAddModalShow] = useState(false);
  const [isEditModalShow, setEditModalShow] = useState(false);

  /* START DATATABLE */
  const [totalRecords, settotalRecords] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [maxPageLimit, setMaxPageLimit] = useState(10);
  const [minPageLimit, setMinPageLimit] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, settotalPages] = useState(0);
  const [offset, setOffset] = useState(1);

  //Search
  const [searchTitle, setSearchTitle] = useState('');
  const [searchStatus, setSearchStatus] = useState('');
  const [searchLocation, setSearchLocation] = useState('');
  const [dataSort, setDataSort] = useState({
    order: 'desc',
    order_by: 'id'
  });
  const pageNumberLimit = { perPage };

  let isToken = '';
  let user_id = '';
  if (authUserData) {
    if (authUserData[0]) {
      isToken = authUserData[0].token ? authUserData[0].token : "";
      user_id = authUserData[0].user_id ? authUserData[0].user_id : "";
    }
  }

  useEffect(() => {
    fetchWebOpeningsData(perPage, searchParam, activePage, dataSort);
    console.log(webOpeningList);
  }, []);

  const [searchParam, setSearchParam] = useState(
    {
      "srchTitle": searchTitle,
      "srchLocation": searchLocation,
      "srchStatus": '1',
    }
  );

  const handlePageClick = (event) => {
    const selectedPage = event.selected;
    const activePageNo = selectedPage + 1;
    fetchWebOpeningsData(perPage, searchParam, activePageNo, dataSort)
  };

  async function fetchWebOpeningsData(perPage, searchParam, activePage, dataSort) {
    setLoading(true);
    const response = await axios
      .post(CONST.GET_OPENINGS_WEB_LIST, {
        'limit': perPage,
        'search_input': searchParam,
        'page_number': activePage,
        'sort': dataSort
      })
      .then((response) => {
        const status = response.data.status;
        setLoading(false);
        if (status === 'success') {
          setwebOpeningList(response.data.data);
          settotalRecords(response.data.count);
          settotalPages(response.data.totalPages);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("There was an error.");
      });
  }

  const capitalizeFirst = str => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };


  return (
    <>
      <Loading
        loading={loading}
        background="rgba(0, 0, 0, 0.5)"
        loaderColor="#fff"
      />

      <section className='container'>
        <div className='row'>
          <div className="col-12">
            <div className="section-title text-center mb-60 mt-60">
              <h3 className="mb-15">Careers</h3>
              {(() => {

              if (webOpeningList) { 
                return(
                  <>
                      <p>We have below openings.</p>
                  </>
                  )
              }else{
                return(
                  <>
                      <p>We don't have openings right now!!</p>
                  </>
                  )
              }
              })()}
            </div>
          </div>
        </div>
      </section>
      <section className='career careers container mt-5 pb-70 background_color web-opening-section'>
        <div className='container'>
          <div className="single-blog-wrapper">
            <div className="row">
              <div className="open_position_box">



                {
                  webOpeningList && webOpeningList.map((element, key) => {
                    {
                      (() => {
                      })()
                    }
                    return (
                      <>
                        <Link className="page-scroll" to={"/openings-details/" + element.id}>
                          <div className="card p-3 mb-5  wow fadeInRight " data-wow-delay="0.5s">

                            <div className="d-flex flex-row align-items-center">
                              <div className="col-12 ms-2 c-details">
                                <div className="col-10">
                                  <h6 className="mb-0"> {capitalizeFirst(element.location)}
                                    &nbsp;&nbsp;<span>{(element.posted_at)} days ago</span>  
                                    {(() => {

                                      if (element.type_of_opening == '1') { 
                                        return (
                                          <>
                                            <span className="opening-type"><b> Full-Time </b></span>
                                          </>
                                        )
                                      }else if (element.type_of_opening == '2'){
                                        return (
                                          <>
                                            <span className="opening-type"><b> Part-Time </b></span>
                                          </>
                                        )  
                                      }else if (element.type_of_opening == '3'){
                                        return (
                                          <>
                                            <span className="opening-type"><b> Contractual </b></span>
                                          </>
                                        )  
                                      }


                                    })()}

                                  </h6>
                                </div>

                              </div>
                            </div>

                            <div className="mt-3">
                              <h4 className="heading">
                                {element.title}
                              </h4>


                              <div className='row'>
                                <div className='col-md-10'><div className="mt-3">
                                  <div className="progress">
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      style=
                                      {{
                                        width: ((element.applied_count / element.total_openings) * 100) + "%"
                                      }}
                                      aria-valuenow={element.applied_count}
                                      aria-valuemin="0"
                                      aria-valuemax={element.total_openings}
                                    ></div>
                                  </div>
                                  <div className="mt-2">
                                    {" "}
                                    <span className="text1">
                                      {element.applied_count} Applied
                                    </span>{" "}
                                  </div>
                                </div>
                                </div>
                                <div className='col-md-2'>
                                  <button className='btn btn-primary'>Apply Now</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </>
                    )
                  })
                }

              </div>


              {(() => {

              if (webOpeningList) { 
                return(
                  <>
                    <div className="open_position_box">
                      <ReactPaginate
                        previousLabel={"Prev."}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={totalPages}
                        onPageChange={handlePageClick}
                        containerClassName={"opening_pagination_div"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"} />
                    </div>
                  </>
                  )
              }
              })()}

            </div>
          </div>



        </div>
      </section>
    </>
  )
}

export default List