import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Link, useNavigate, Navigate } from "react-router-dom";
import Form from '../../../component/utilities/Forms';
import * as CONST from "../../../component/config/Constant";
import { AuthContext } from '../../../component/context/AuthContext';
import { toast } from "react-toastify";
import Loading from "react-fullscreen-loading";


function ChangePassword() {
    const navigate = useNavigate();
    const [authUserData, setAuthUserData] = useContext(AuthContext);
	const [old_password, setOldPassword] = useState("");
    const [new_password, setNewPassword] = useState("");
    const [confirm_password, setConfirmPassword] = useState("");
    const [validate, setValidate] = useState({});
    const [isPasswordEqual, setIsPasswordEqual] = useState({});
    const [loading, setLoading] = useState(false);

    let isToken = '';
    let user_id = '';
	if (authUserData) {
		if (authUserData[0]) {
			isToken = authUserData[0].token ? authUserData[0].token : "";
			user_id = authUserData[0].user_id ? authUserData[0].user_id : "";
		}
	}

    const validateChangePassword = async () => {
        let isValid = true;
    
        let validator = Form.validator({
            old_password: {
                value: old_password,
                isRequired: true,
                minLength: 8,
                label: "old password",
                isSymbol: true,
              },
            new_password: {
                value: new_password,
                isRequired: true,
                minLength: 8,
                label: "new password",
                isSymbol: true,
            },
            confirm_password: {
                value: confirm_password,
                isRequired: true,
                minLength: 8,
                isSymbol: true,
                label: "confirm password",
            },
        });
    
        if (validator !== null) {
          setValidate({
            validate: validator.errors,
          });
          isValid = false;
        } else {
            if (new_password !== confirm_password) {
                setIsPasswordEqual({
                status: 'yes',
                msg: "The new password and confirm password are not equal",
                });
                isValid = false;
            } else {
                setValidate({
                validate: "",
                });
                isValid = true;
                setIsPasswordEqual({});
            }
        }
        return isValid;
    };

    const handleChangePassFrm = async(e) => {
        setLoading(false);
        let valid = await validateChangePassword();
        //alert(new_password+'|'+confirm_password)
        if(valid){
            //alert('vll' + JSON.stringify(isPasswordEqual.status));
            if(isPasswordEqual.status == 0){
                toast.error(isPasswordEqual.mgs)
            }else{
                const requestData = {
                    old_password:old_password,
                    new_password: new_password,
                    confirm_password: confirm_password
                  };

                await axios
                .post(CONST.CHANGE_PASSWORD,
                    requestData,
                    {
                        headers: {
                            'Authorization': `Bearer ${isToken}`,
                            'Accept': 'application/json, text/plain, */*',
                            'Content-Type': 'application/json'    
                        }, 
                    })
                    
                    .then((response) => {console.log('change pass>>'+response.data)
                        const status = response.data.status;
                        if (status === 'success') {
                            toast.success(response.data.message);
                            navigate("/");
                            const UserData = [
                            {
                                first_name: "",
                                last_name: "",
                                email: "",
                                status: "",
                                token: "",
                                role_id: "",
                                profile_photo_path: "",
                            },
                            ];
                            setAuthUserData(UserData);
                            localStorage.removeItem("eToken");
                        }else{
                            alert(JSON.stringify(response.data.errors));
                            toast.error(response.data.message);
                        }
                    })
                    .catch((error) => {//salert('change pass axios err: '+error);
                        console.log(error.message);
                    });
                    setLoading(false);
            }
        }else{
            toast.error('Something went wrong');
        }
    }
    return (
        <>
            {/* <form > */}
                <div className="row">
                    <label className="form-label"><h5>Change Password</h5></label>
                    <hr />
                    
                    <div className="col-md-12">
                        <div className="form-group mb-3 col-md-6">
                            <label className="form-label required">
                                Old Password
                            </label>
                            <div>
                                <input
                                    type="password"
                                    name="old_password"
                                    id="old_password"
                                    className={`form-control ${validate.validate && validate.validate.old_password
                                        ? "is-invalid "
                                        : ""
                                        }`}
                                    aria-describedby="emailHelp"
                                    placeholder="Enter old password"
                                    onChange={(e) => setOldPassword(e.target.value)}
                                    value={old_password}
                                />
                            </div>
                            <div
                                className={`invalid-feedback text-start ${validate.validate && validate.validate.old_password
                                    ? "d-block"
                                    : "d-none"
                                    }`}
                            >
                                {validate.validate && validate.validate.old_password
                                    ? validate.validate.old_password[0]
                                    : ""}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group mb-3 col-md-6">
                            <label className="form-label required">
                                New Password
                            </label>
                            <div>
                                <input
                                    type="password"
                                    name="new_password"
                                    id="new_password"
                                    className={`form-control ${validate.validate && validate.validate.new_password
                                        ? "is-invalid "
                                        : ""
                                        }`}
                                    aria-describedby="emailHelp"
                                    placeholder="Enter new password"
                                    onChange={(e) => setNewPassword(e.target.value)}
                                    value={new_password}
                                />
                            </div>
                            <div
                                className={`invalid-feedback text-start ${validate.validate && validate.validate.new_password
                                    ? "d-block"
                                    : "d-none"
                                    }`}
                            >
                                {validate.validate && validate.validate.new_password
                                    ? validate.validate.new_password[0]
                                    : ""}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="form-group mb-3 col-md-6">
                            <label className="form-label required">
                                Confirm Password
                            </label>
                            <div>
                                <input
                                    type="password"
                                    name="confirm_password"
                                    id="confirm_password"
                                    className={`form-control ${validate.validate && validate.validate.confirm_password
                                        ? "is-invalid "
                                        : ""
                                        }`}
                                    aria-describedby="emailHelp"
                                    placeholder="Enter confirm password"
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                    value={confirm_password}
                                />
                            </div>
                            <div
                                className={`invalid-feedback text-start ${validate.validate && validate.validate.confirm_password
                                    ? "d-block"
                                    : "d-none"
                                    }`}
                            >
                                {validate.validate && validate.validate.confirm_password
                                    ? validate.validate.confirm_password[0]
                                    : ""}
                            </div>
                            <div
                            className={`invalid-feedback text-start mb-2 ${isPasswordEqual.status && isPasswordEqual.msg
                                ? "d-block"
                                : "d-none"
                                }`}
                            >
                            {isPasswordEqual.status && isPasswordEqual.msg
                                ? isPasswordEqual.msg
                                : ""}
                            </div>

                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="form-footer">
                            <button type="submit" onClick={handleChangePassFrm} className='mr-3 btn btn-primary'>Update</button>
                        </div>
                    </div>
                </div>
            {/* </form> */}
        </>
       
    )
}

export default ChangePassword