import { React, useState, useEffect, useContext } from "react";
import "../../../assets/css/table.css";
import Loading from "react-fullscreen-loading";
import { Link } from "react-router-dom";
import HeaderSection from "../banner/HeaderSection";
import DivLoader from "../../../component/utilities/Spinner";
import DataTable from "react-data-table-component";
import axios from "axios";
import { toast } from "react-toastify";
import * as CONST from "../../../component/config/Constant";
import Form from "../../../component/utilities/Forms";
import editImg from "../../../assets/img/icon/edit.svg";
import deleteImg from "../../../assets/img/icon/delete.svg";
import { Modal, Button } from "react-bootstrap";
import { AuthContext } from "../../../component/context/AuthContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ReactSwitch from "react-switch";
import * as SwitchProps from "../../../component/utilities/ReactSwitchReactSwitchProps";
import DatePicker from "react-datetime";
import AssetsDetails from "./AssetsDetails";
import Moment from "moment";
function AssetsList() {
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  const [fullLoading, setFullLoading] = useState(false);
  const [validate, setValidate] = useState({});
  const [assetsData, setAssetsData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const MySwal = withReactContent(Swal);
  const [listLimit, setListLimit] = useState(50);
  const [action, setAction] = useState("");
  /** For Modalpopup */
  const [show, setShow] = useState(false);
  //For Count show Popup
  const [quantity, setQuantity] = useState("");
  const today = new Date();
  const [requestCreateDate, setRequestCreateDate] = useState(new Date());
  const [AssetsTypeData, setAssetsTypeData] = useState([]);
  const [assetsTypeId, setAssetsTypeId] = useState("");
  const [assetsTypeIdModal, setAssetsTypeIdModal] = useState("");
  const [assetsId, setAssetId] = useState(0);

  const closeAssetsDetails = async (type) => {
    setAssetsDetailsShowModel({
      show: "hide",
    });
  };

  /* START DATATABLE */
  const [totalRecords, settotalRecords] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [maxPageLimit, setMaxPageLimit] = useState(10);
  const [minPageLimit, setMinPageLimit] = useState(0);

  //Search
  const [searchAssetName, setsearchAssetName] = useState("");

  const [dataSort, setAssetDataSort] = useState({
    order: "desc",
    order_by: "id",
  });

  const pageNumberLimit = { perPage };
  const [isAssetDetailsModalShow, setAssetDetailsModalShow] = useState(false);
  let isToken = "";
  let userId = "";

  if (authUserData) {
    if (authUserData[0]) {
      userId = authUserData[0].user_id ? authUserData[0].user_id : 0;
      isToken = authUserData[0].token ? authUserData[0].token : "";
    }
  }

  const [searchParam, setSearchParam] = useState({
    srchAssetName: searchAssetName,
  });

  useEffect(() => {
    fetchAssetsType();
    fetchAllAssetsRequestData(searchParam, dataSort, activePage, perPage);
  }, [searchParam, dataSort, activePage, listLimit]);

  const [showAssetsDetailsModel, setAssetsDetailsShowModel] = useState({
    show: "hide",
  });

  const AssetDetails = ({ row }) => (
    <>
      <Link
        to={"#"}
        data-id={row.id ? row.id :""}
        onClick={() => handleAssetDetailsShow(row)}
      >
        {row.total_count ? row.total_count:""}
     
      </Link>
    </>
  );

  function handleShow(action) {

    setAction(action);
    setShow(true);
  }


  
  async function assetSearch() {
    setSearchParam({
      srchAssetName: searchAssetName,
    });

    setAssetDataSort({
      order: "desc",
      order_by: "id",
    });

    setActivePage("1");
  }

  function clearSearch() {
    setsearchAssetName("");
    setSearchParam({
      srchAssetName: "",
    });

    setActivePage("1");
  }

  const handleClose = () => {
    setShow(false);
    setAssetsTypeId("");
    setRequestCreateDate("");
    setQuantity("");
     
  };

  const handleAssetDetailsShow = (row) => {
    setLoading(true);
    setAssetsTypeIdModal(row.asset_type_id ? row.asset_type_id : "");
    setAssetDetailsModalShow(true);
    setAssetsDetailsShowModel({
      show: "show",
    });

    setLoading(false);
  };


  //Fetch All assets Request List

  async function fetchAllAssetsRequestData(
    paramSearch,
    paramSort,
    paramPage,
    paramLimit
  ) {
    let searchParams = JSON.stringify({
      limit: paramLimit,
      search_input: paramSearch,
      page_number: paramPage,
      sort: paramSort,
    });
    setLoading(true);
    await axios({
      method: "post",
      url: CONST.GET_ASSETS_LIST,
      data: {
        user_id: userId,
        limit: paramLimit,
        search_input: paramSearch,
        page_number: paramPage,
        sort: paramSort,
      },
      headers: {
        Authorization: `Bearer ${isToken}`,
      },
    })
      .then((response) => {
        console.log("Dept Res- " +  response.data.data);
        const status = response.data.status;
        if (status === "success") {
          setAssetsData(response.data.data);
          setTotalRows(response.data.count);
          settotalRecords(response.data.count);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setLoading(false);
  }

 //fetch all AssetsName
  async function fetchAssetsType() {
    setLoading(true);
    let searchParams = JSON.stringify({
      limit: "",
      search_input: "",
      page_number: "",
      sort: "",
    });
    await axios({
      method: "get",
      url: CONST.ASSETS_TYPE_LIST,
      headers: {
        Authorization: `Bearer ${isToken}`,
      },
    })
      .then((response) => {
        const status = response.data.status;
        if (status === "success") {
          setAssetsTypeData(response.data.data);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setLoading(false);
  }

  const validateAsset = async () => {
    let isValid = true;
    let validator = Form.validator({
      assetsTypeId: {
        value: assetsTypeId,
        isRequired: true,
        isEmail: false,
        label: "Assets Name",
      },
      // quantity: {
      //   value: quantity,
      //   isRequired: true,
      //   isEmail: false,
      //   label: "Count",
      // },
    });
    console.log("validator:" + JSON.stringify(validator));
    if (validator !== null) {
      setValidate({
        validate: validator.errors,
      });
      isValid = false;
    } else {
      setValidate({
        validate: "",
      });
      isValid = true;
    }
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validate = await validateAsset();
    if (validate) {
 
      let apiUrl = "";
      let sucessMessage = "";
      let strMethod = "";

      apiUrl = CONST.ADD_ASSETS;
      strMethod = "post";
      sucessMessage = "Assets added succesfully";

      if (assetsId == 0) {
        apiUrl = CONST.ADD_ASSETS;
        strMethod = "post";
        sucessMessage = "Assets added succesfully";
      } else {
        apiUrl = CONST.ADD_ASSETS;
        strMethod = "put";
        sucessMessage = "Assets updated succesfully";
      }
        await axios({
        method: strMethod,
        url: apiUrl,
        data: {
          asset_type_id: assetsTypeId,
          //request_create_date: requestCreateDate,
          quantity: '1',
        },
        headers: {
          Authorization: `Bearer ${isToken}`,
        },
      })
        .then((response) => {
          if (response) {
            if (response.data.status == "error") {
              toast.error("Something went wrong. Please try again.");
            } else {
              toast.success(sucessMessage);
              fetchAllAssetsRequestData(
                searchParam,
                dataSort,
                activePage,
                perPage
              );
              handleClose();
            }
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error("Something went wrong. Please try again.");
        });
    }
  };
 
  const columns = [
    {
      name: "Sr. No",
      selector: (row, index) => index + 1,
      width: "10%",
    },
    {
      name: "Name of Assets",
      selector: (row) => row.e_asset_type?.name || "",
      width: "25%",
    },
    {
      name: "Date",
      selector: (row) =>
        Moment(row?. created_at || "").format("DD, MMM. YYYY "),
      width: "25%",
    },
    {
      name: "Count",
      cell: (row) => <AssetDetails row={row} />,
      sortable: true,
      sortField: "count",
      wrap: true,
    },
  ];

  const handleSort = async (column, sortDirection) => {
    setActivePage(1);
    setSearchParam({
      search: "no",
      name: "",
      status: "",
    });

  };
  /*  PAGINATION FUNCTIONS  */
  const handlePageChange = async (page) => {
    setActivePage(parseInt(page));
  };

  const BootyPagination = ({
    rowsPerPage,
    rowCount,
    onChangePage,
    onChangeRowsPerPage, // available but not used here
    currentPage,
  }) => {
    function toPages(pages) {
      const results = [];
      for (let i = 1; i <= pages; i++) {
        results.push(i);
      }
      return results;
    }
    function getNumberOfPages(rowCount, rowsPerPage) {
      return Math.ceil(rowCount / rowsPerPage);
    }

    const handleBackButtonClick = async () => {
      if ((currentPage - 1) % pageNumberLimit === 0) {
        setMaxPageLimit(maxPageLimit - pageNumberLimit);
        setMinPageLimit(minPageLimit - pageNumberLimit);
      }
      onChangePage(currentPage - 1);
    };

    const handleNextButtonClick = async () => {
      if (currentPage + 1 > maxPageLimit) {
        setMaxPageLimit(maxPageLimit + pageNumberLimit);
        setMinPageLimit(minPageLimit + pageNumberLimit);
      }

      onChangePage(currentPage + 1);
    };

    const handlePageNumber = (e) => {
      onChangePage(Number(e.target.value));
    };

    let paginationText = "Page:  " + currentPage + ", Showing ";

    paginationText =
      paginationText +
      ((currentPage - 1) * perPage + 1) +
      " to " +
      perPage * currentPage;
    paginationText = paginationText + " of " + rowCount + " entries;";

    const pages = getNumberOfPages(rowCount, rowsPerPage);
    const pageItems = toPages(pages);
    const nextDisabled = currentPage === pageItems.length;
    const previosDisabled = currentPage === 1;

    const pageNumbers = pageItems.map((page) => {
      if (page <= maxPageLimit && page > minPageLimit) {
        return (
          <li
            key={page}
            id={page}
            className={`page-item ${currentPage === page ? "active" : ""}`}
          >
            <button
              className={`page-link ${currentPage === page ? "active" : ""}`}
              onClick={handlePageNumber}
              value={page}
            >
              {page}
            </button>
          </li>
        );
      } else {
        return null;
      }
    });
    let pageIncrementEllipses = null;
    if (pageItems.length > maxPageLimit) {
      pageIncrementEllipses = (
        <li className="page-item" onClick={handleNextButtonClick}>
          &hellip;
        </li>
      );
    }
    let pageDecremenEllipses = null;
    if (minPageLimit >= 1) {
      pageDecremenEllipses = (
        <li className="page-item" onClick={handleBackButtonClick}>
          &hellip;
        </li>
      );
    }
    return (
      <div className="card-footer d-flex align-items-center">
        <p className="m-0 text-muted">{paginationText}</p>
        <ul className="pagination m-0 ms-auto">
          <li className="page-item">
            <button
              className="page-link"
              onClick={handleBackButtonClick}
              disabled={previosDisabled}
              aria-disabled={previosDisabled}
              aria-label="previous page"
              pagerangedisplayed={10}
            >
              «
            </button>
          </li>
          {pageDecremenEllipses}
          {pageNumbers}
          {pageIncrementEllipses}
          <li className="page-item">
            <button
              className="page-link"
              onClick={handleNextButtonClick}
              disabled={nextDisabled}
              aria-disabled={nextDisabled}
              aria-label="next page"
            >
              »
            </button>
          </li>
        </ul>
      </div>
    );
  };
  /* END DATATABLE */
  const CustomLoader = () => (
    <div style={{ padding: "24px" }}>
      <DivLoader color="primary" />
    </div>
  );
  return (
    <>
      <Loading
        loading={fullLoading}
        background="rgba(0, 0, 0, 0.5)"
        loaderColor="#fff"
      />
      <HeaderSection
        title="Manage"
        heading="Assets"
        addButton={() => handleShow("Add", 0)}
        addLink="#"
        addLinkName="Request Assets"
        linktype="add"
      />
      <div className="page-body">
        <div className="container-xl">
          <div className="row row-cards">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Listing</h3>
                </div>
                <div className="card-body border-bottom py-3">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group mb-3 ">
                        <label className="form-label required">Asests</label>
                        <div>
                          <input
                            type="text"
                            name="searchAssetName"
                            id="searchAssetName"
                            className={`form-control`}
                            placeholder="Enter Asests"
                            onChange={(e) => setsearchAssetName(e.target.value)}
                            value={searchAssetName}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group mb-3 ">
                        <label className="form-label">&nbsp;</label>
                        <div>
                          <button
                            type="button"
                            name="btnSearch"
                            id="btnSearch"
                            className="btn btn-blue d-none d-sm-inline-block ml-3"
                            onClick={assetSearch}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon icon-tabler icon-tabler-search"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <circle cx="10" cy="10" r="7"></circle>
                              <line x1="21" y1="21" x2="15" y2="15"></line>
                            </svg>
                            Search
                          </button>
                          &nbsp;
                          <button
                            type="button"
                            name="btnReset"
                            id="btnReset"
                            className="btn btn-outline-primary "
                            onClick={clearSearch}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon icon-tabler icon-tabler-x"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <line x1="18" y1="6" x2="6" y2="18"></line>
                              <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={assetsData}
                    progressPending={loading}
                    progressComponent={<CustomLoader />}
                    pagination="true"
                    paginationServer
                    paginationTotalRows={totalRows}
                    //onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationPerPage={perPage}
                    paginationComponent={BootyPagination}
                    sortServer
                    onSort={handleSort}
                  />
                </div>
                <div className="card-footer d-flex align-items-center">
                  <p className="m-0 text-muted">
                    Showing {/* <span>1</span> to <span>8</span> of{" "} */}
                    <span>{totalRecords}</span> entries
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Request Asests </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form method="POST" onSubmit={handleSubmit} autoComplete={"off"}>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group mb-3 ">
                  <label className="form-label required">Asests Name</label>
                  <div>
                    <select
                      className={`form-select form-control ${
                        validate.validate && validate.validate.assetsTypeId
                          ? "is-invalid "
                          : ""
                      }`}
                      name="assetsTypeId"
                      id="assetsTypeId"
                      placeholder="Select leaveType"
                      onChange={(e) => setAssetsTypeId(e.target.value)}
                      value={assetsTypeId}
                    >
                      <option>Select Asests Type</option>

                      {AssetsTypeData.map((element, index) => (
                        <option value={element.id} key={index}>
                          {element.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-6">
                <div className="form-group mb-3">
                  <label className="form-label required">Count</label>
                  <div>
                    <select
                      className={`form-select form-control ${
                        validate.validate && validate.validate.quantity
                          ? "is-invalid "
                          : ""
                      }`}
                      name="quantity"
                      id="quantity"
                      placeholder="Select Count"
                      onChange={(e) => setQuantity(e.target.value)}
                      value={quantity}
                    >
                      <option value="">Select</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                    </select>
                  </div>
                </div>
              </div> */}
            </div>

            <input
              type="hidden"
              name="assetsId"
              id="assetsId"
              value={assetsId}
            />
          </form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      {showAssetsDetailsModel.show == "show" && (
        <AssetsDetails
          closeAssetsDetails={closeAssetsDetails}
          assetsTypeIdModal={assetsTypeIdModal}
        />
      )}
    </>
  );
}

export default AssetsList;
