import { React, useState, useEffect, useContext, useMemo } from 'react'
import HeaderSection from '../banner/HeaderSection';
import * as CONST from "../../../component/config/Constant";
import AddTestimonial from "./AddTestimonials";
import DataTable from "react-data-table-component";
import Moment from "moment";
import editImg from "../../../assets/img/icon/edit.svg";
import CustomLoader from '../../../component/utilities/CustomLoader';
import { Link } from 'react-router-dom';
import axios from "axios";
import { AuthContext } from '../../../component/context/AuthContext';
import { toast } from "react-toastify";
import ReactSwitch from 'react-switch';
import * as SwitchProps from '../../../component/utilities/ReactSwitchReactSwitchProps';
import Swal from 'sweetalert2';
import EditTestimonials from './EditTestimonials';

function AdminTestimonilasListings({ count }) {
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  const [validate, setValidate] = useState({});
  const [srchEmpName, setSrchEmpName] = useState("");
  const [srchEmpEmail, setSrchEmpEmail] = useState("");
  const [srchEmpStatus, setSrchEmpStatus] = useState("");
  const [isAddModalShow, setAddModalShow] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  /* START DATATABLE */
  const [totalRecords, settotalRecords] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [maxPageLimit, setMaxPageLimit] = useState(10);
  const [minPageLimit, setMinPageLimit] = useState(0);
  const [activePage, setActivePage] = useState("1");
  const [dataSort, setDataSort] = useState({
    order: 'desc',
    order_by: 'id'
  });
  const [searchParam, setSearchParam] = useState(
    {
      "srchStatus": '',

    }
  );
  const [showModel, setShowModel] = useState({
    show: 'hide'
  });
  const handleClose = () => {
    alert('there');
  };

  /* Edit */
  const [editTestiId, setEditTestiId] = useState("");
  const [editEmpId, setEditEmpId] = useState("");
  const [isEditModalShow, setEditModalShow] = useState(false);
  const [editEmpName, setEditEmpName] = useState(false);
  const [showEditModel, setShowEditModel] = useState({
    show: 'hide'
  });

  let isToken = '';
  let user_id = '';
  if (authUserData) {
    if (authUserData[0]) {
      isToken = authUserData[0].token ? authUserData[0].token : "";
      user_id = authUserData[0].user_id ? authUserData[0].user_id : "";
    }
  }
  async function showFunction() {
    setAddModalShow(true);
    setShowModel({
      show: 'show'
    });
  }
  async function fetchUserData() {
    console.log(activePage);
    setLoading(true);
    const response = await axios
      .post(CONST.ADMIN_TESTIMONIAL_LIST, {
        "limit": perPage,
        "page_number": activePage,
        "search_input": searchParam,
        "sort": dataSort
      },
        {
          headers: {
            'Authorization': `Basic ${isToken}`
          }
        })
      .then((response) => {
        const status = response.data.status;
        if (status == 'success') {
          console.log(response.data.totalPages);
          setTotalRows(response.data.count);
          setLoading(false);
          setData(response.data.data);
          settotalRecords(response.data.count);
        }
      })
      .catch((error) => {
        setLoading(false);

        toast.error("There was an error.");
      });
  }
  const handleTestiStatusChange = (index, val, row_id) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to change the status?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, change it!'
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        axios.put(CONST.ADMIN_TESTIMONIAL_STATUS,
          {
            'testimonial_id': row_id,
            'status': (val == '0') ? '1' : '0',
          },
          {
            headers: {
              'Authorization': `Basic ${isToken}`
            }
          }
        )
          .then((response) => {
            console.log(response);
            const status = response.data.status;
            if (status == 'success') {
              toast.success(response.data.message);
            } else {
              toast.error(response.data.message);
            }
          })
          .catch((error) => {
            console.log(error);
            toast.error("There was an error.");
          });
        const newData = [...data];
        newData[index].status = (val == 0) ? 1 : 0;
        setData(newData);
        setLoading(false);
      }
    })
  }

  const childToParent = async (type) => {

    setShowEditModel({
      show: 'hide'
    });
    setShowModel({
      show: 'hide'
    });
    fetchUserData();
    //fetchUserData(searchParam, dataSort, activePage, perPage);
  }


  const CustomName = ({ row }) => (

    <div className="d-flex py-1 align-items-center">
      <span className="avatar ">
        {
          row.user && (
            row.user.profile_photo_path
              ? (
                <>
                  <img src={CONST.HOST + row.user.profile_photo_path} alt="" width="40" className="rounded-circle" />
                </>
              ) : (
                <>
                  <span>
                    <span class="badge bg-success me-1" data-toggle="tooltip" data-placement="top" title="Active">
                      {(row.first_name && row.last_name)
                        ? <> {row.first_name && row.first_name.substring(0, 1)}
                          {row.last_name && row.last_name.substring(0, 1)}</>
                        : row.name && row.name.substring(0, 2)
                      }
                    </span>
                  </span>
                </>
              )
          )
        }
      </span>
      <div className="flex-fill pl-2">
        <div className="font-weight-medium">
          {
            row.user && (
              <>
                {row.user.first_name} {row.user.last_name}
              </>
            )
          }
        </div>
        <div className="text-muted">
          <span className="text-muted" >
            <small>
              {
                row.user && (row.user.department.name)

              }
            </small>
          </span>
        </div>
      </div>
    </div>
  );
  const Testimonial = ({ row }) => (
    <>

      <div className="flex-fill">
        <div className="">
          {
            (row.description)
              ? <>{row.description}</>
              : ""
          }
        </div>

      </div>
    </>
  )
  const AddedBy = ({ row }) => (
    <>

      <div className="d-flex py-1 align-items-center">
        <span className="avatar ">
          {
            row.added_by_admin.profile_photo_path
              ? (
                <>
                  <img src={CONST.HOST + row.added_by_admin.profile_photo_path} alt="" width="40" className="rounded-circle" />
                </>
              ) : (
                <>
                  <span>
                    <span class="badge bg-success me-1" data-toggle="tooltip" data-placement="top" title="Active">
                      {(row.first_name && row.last_name)
                        ? <> {row.first_name && row.first_name.substring(0, 1)}
                          {row.last_name && row.last_name.substring(0, 1)}</>
                        : row.name && row.name.substring(0, 2)
                      }
                    </span>
                  </span>
                </>
              )
          }
        </span>
        <div className="flex-fill pl-2">
          <div className="font-weight-medium"> {row.added_by_admin.first_name} {row.added_by_admin.last_name}</div>
          <div className="text-muted">
            <span className="text-muted" ><small>{
              row.added_by_admin.department.name}</small></span>
          </div>
        </div>
      </div>
    </>
  )

  const TestimonialStatus = ({ row }) => (
    <>

      <div className="flex-fill">
        <div className="font-weight-medium">
          {
            (row.description)
              ? <>{row.added_by_admin.first_name} {row.added_by_admin.last_name}</>
              : ""
          }
        </div>

      </div>
    </>
  )
  const JoingDate = ({ row }) => (

    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="icon"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        strokeWidth="2"
        stroke="currentColor"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path
          stroke="none"
          d="M0 0h24v24H0z"
          fill="none"
        ></path>
        <rect
          x="4"
          y="5"
          width="16"
          height="16"
          rx="2"
        ></rect>
        <line x1="16" y1="3" x2="16" y2="7"></line>
        <line x1="8" y1="3" x2="8" y2="7"></line>
        <line x1="4" y1="11" x2="20" y2="11"></line>
        <line x1="11" y1="15" x2="12" y2="15"></line>
        <line x1="12" y1="15" x2="12" y2="18"></line>
      </svg>{" "}
      &nbsp;
      {Moment(row.created_at).format(
        "DD, MMM. YYYY "
      )}
    </div>
  )
  const Action = ({ row }) => (
    <><img src={editImg} alt='editUser' width={24} className='cursor' onClick={() => handleTestiEdit(row.id, row.user.id, row.user.first_name + " " + row.user.last_name)} />  </>
  )
  const columns = [
    {
      name: "Employee Name",
      selector: (row) => row.first_name,
      cell: (row) => <CustomName row={row} />,
      sortable: true,
      sortField: "first_name"
    },
    {
      name: "Testimonial",
      cell: (row) => <Testimonial row={row} />,
      sortable: false,

    },
    {
      name: "Added By",
      cell: (row) => <AddedBy row={row} />,
      sortable: true,
      sortField: "joining_date"
    },
    {
      name: "Submitted Date",
      cell: (row) => <JoingDate row={row} />,
      selector: (row) => row.created_at,
      sortable: true,
      sortField: "created_at"
    }
    ,

    {
      name: "Status",
      selector: (row) => 'Active',
      width: '10%',
      wrap: true,
      selector: (row, index) => <ReactSwitch
        onColor={SwitchProps.onColor}
        onHandleColor={SwitchProps.onHandleColor}
        handleDiameter={SwitchProps.handleDiameter}
        uncheckedIcon={SwitchProps.uncheckedIcon}
        checkedIcon={SwitchProps.checkedIcon}
        boxShadow={SwitchProps.boxShadow}
        activeBoxShadow={SwitchProps.activeBoxShadow}
        height={SwitchProps.height}
        width={SwitchProps.width}
        className={SwitchProps.className}
        id="material-switch"
        checked={(row.status == 1) ? true : false}
        onChange={() => { handleTestiStatusChange(index, row.status, row.id) }
        }

      />,
    }
    ,
    {
      name: "Action",

      cell: (row) => <Action row={row} />,
      sortable: false,
      sortField: "joining_date"
    }
  ]

  const handleTestiEdit = async (testiID, empId, empName) => {
    setLoading(true);
    setEditModalShow(true);
    setShowEditModel({
      show: 'show'
    });
    setEditTestiId(testiID);
    setEditEmpId(empId);
    setEditEmpName(empName);
    setLoading(false);
  }


  const handlePerRowsChange = async (perPage, page) => {
    console.log(handlePerRowsChange);
    setLoading(true);

    const response = await axios.get(
      `https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`
    );

    setData(response.data.data);
    setPerPage(perPage);
    setLoading(false);
  };
  function toPages(pages) {
    const results = [];
    for (let i = 1; i <= pages; i++) {
      results.push(i);
    }
    return results;
  }
  function getNumberOfPages(rowCount, rowsPerPage) {
    console.log("rowCount = " + rowCount);
    console.log("rowsPerPage = " + rowsPerPage);
    console.log("getNumberOfPages = " + Math.ceil(rowCount / rowsPerPage));
    return Math.ceil(rowCount / rowsPerPage);
  }
  const handleSort = async (column, sortDirection) => {
    setActivePage("1");
    setSearchParam({
      search: 'no',
      name: '',
      email: '',
      plan: '',
      status: '',
    });

    setDataSort({
      order: sortDirection,
      order_by: column.sortField
    })
    /*  setLoading(false); */

  };
  /*  PAGINATION FUNCTIONS  */
  const handlePageChange = async (page) => {
    console.log(page);
    setActivePage(page)
  };
  const changePageNumber = useMemo(() => {
    return fetchUserData();
  }, [activePage, searchParam, dataSort]);
  const BootyPagination = ({
    rowsPerPage,
    rowCount,
    onChangePage,
    onChangeRowsPerPage, // available but not used here
    currentPage,
  }) => {

    const handleBackButtonClick = async () => {
      if ((currentPage - 1) % perPage === 0) {
        setMaxPageLimit(maxPageLimit - perPage);
        setMinPageLimit(minPageLimit - perPage);
      }
      onChangePage(currentPage - 1);
    };

    const handleNextButtonClick = async () => {
      if (currentPage + 1 > maxPageLimit) {
        setMaxPageLimit(maxPageLimit + perPage);
        setMinPageLimit(minPageLimit + perPage);
      }

      onChangePage(currentPage + 1);
    };

    const handlePageNumber = (e) => {
      onChangePage(Number(e.target.value));
    };

    let paginationText = "Page:  " + currentPage + ", Showing ";

    paginationText =
      paginationText +
      ((currentPage - 1) * perPage + 1) +
      " to " +
      perPage * currentPage;
    paginationText = paginationText + " of " + rowCount + " entries;";


    const pages = getNumberOfPages(rowCount, rowsPerPage);

    const pageItems = toPages(pages);
    const nextDisabled = currentPage === pageItems.length;
    const previosDisabled = currentPage === 1;

    const pageNumbers = pageItems.map((page) => {
      if (page <= maxPageLimit && page > minPageLimit) {
        return (
          <li
            key={page}
            id={page}
            className={`page-item ${currentPage === page ? "active" : ""}`}
          >
            <button
              className={`page-link ${currentPage === page ? "active" : ""}`}
              onClick={handlePageNumber}
              value={page}
            >
              {page}
            </button>
          </li>
        );
      } else {
        return null;
      }
    });
    let pageIncrementEllipses = null;
    if (pageItems.length > maxPageLimit) {
      pageIncrementEllipses = (
        <li className="page-item" onClick={handleNextButtonClick}>
          &hellip;
        </li>
      );
    }
    let pageDecremenEllipses = null;
    if (minPageLimit >= 1) {
      pageDecremenEllipses = (
        <li className="page-item" onClick={handleBackButtonClick}>
          &hellip;
        </li>
      );
    }
    return (
      <div className="card-footer d-flex align-items-center">
        <p className="m-0 text-muted">{paginationText}</p>

        <ul className="pagination m-0 ms-auto">
          <li className="page-item">
            <button
              className="page-link"
              onClick={handleBackButtonClick}
              disabled={previosDisabled}
              aria-disabled={previosDisabled}
              aria-label="previous page"
              pageRangeDisplayed={10}
            >
              «
            </button>
          </li>
          {pageDecremenEllipses}
          {pageNumbers}
          {pageIncrementEllipses}
          <li className="page-item">
            <button
              className="page-link"
              onClick={handleNextButtonClick}
              disabled={nextDisabled}
              aria-disabled={nextDisabled}
              aria-label="next page"
            >
              »
            </button>
          </li>
        </ul>
      </div>
    );
  };

  console.log(showModel);
  return (
    <>
      <div className="page-header d-print-none">
        <div className="container-xl">
          <div className="row g-2 align-items-center">
            <div className="col">
              <div className="page-pretitle">Manage</div>
              <h2 className="page-title">Testimonials</h2>
            </div>
            <div className="col-12 col-md-auto ms-auto d-print-none">
              <div className="btn-list">

                <Link
                  className="btn btn-primary"
                  onClick={showFunction}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-plus"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <line x1="12" y1="5" x2="12" y2="19"></line>
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                  </svg>
                  &nbsp;Add Testimonial
                </Link>
              </div>
            </div>

          </div>
        </div>
      </div>
      <div className="page-body">
        <div className="container-xl">
          <div className="row row-cards">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Listing</h3>
                </div>
                <div className="card-body border-bottom py-3">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group mb-3 ">
                        <label className="form-label required">Name</label>
                        <div>
                          <input
                            type="text"
                            name="srchEmpName"
                            id="srchEmpName"
                            className={`form-control ${validate.validate && validate.validate.srchEmpName
                              ? "is-invalid "
                              : ""
                              }`}
                            aria-describedby="emailHelp"
                            placeholder="Enter name"
                            onChange={(e) => setSrchEmpName(e.target.value)}
                            value={srchEmpName}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3 ">
                        <label className="form-label required">Email</label>
                        <div>
                          <input
                            type="text"
                            name="srchEmpEmail"
                            id="srchEmpEmail"
                            className={`form-control ${validate.validate && validate.validate.srchEmpEmail
                              ? "is-invalid "
                              : ""
                              }`}
                            aria-describedby="emailHelp"
                            placeholder="Enter email name"
                            onChange={(e) => setSrchEmpEmail(e.target.value)}
                            value={srchEmpEmail}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className="mb-3">
                        <label className="form-label required">
                          Status
                        </label>

                        <select
                          className="form-select form-control"
                          name="srchEmpStatus"
                          id="srchEmpStatus"
                          placeholder="Select status"
                          onChange={(e) => setSrchEmpStatus(e.target.value)}
                          value={srchEmpStatus}
                        >
                          <option value="">Select</option>
                          <option value="1">Active</option>
                          <option value="0">Deactive</option>

                        </select>


                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group mb-3 ">
                        <label className="form-label">&nbsp;</label>
                        <div>
                          <button
                            type="button"
                            name="btnSearch"
                            id="btnSearch"
                            className="btn btn-blue d-none d-sm-inline-block ml-3"
                            onClick=''
                          ><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                              <circle cx="10" cy="10" r="7"></circle>
                              <line x1="21" y1="21" x2="15" y2="15"></line>
                            </svg>Search</button>&nbsp;
                          <button
                            type="button"
                            name="btnSearchReset"
                            id="btnSearchReset"
                            className="btn btn-outline-primary "
                            onClick=''
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                              <line x1="18" y1="6" x2="6" y2="18"></line>
                              <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>Clear</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">

                  <DataTable
                    columns={columns}
                    data={data}
                    progressPending={loading}
                    progressComponent={<CustomLoader />}
                    pagination="true"
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationPerPage={perPage}
                    paginationComponent={BootyPagination}
                    /*  sortServer */
                    onSort={handleSort}
                  />

                </div>
                <div className="card-footer d-flex align-items-center">
                  <p className="m-0 text-muted">
                    Showing {/* <span>1</span> to <span>8</span> of{" "} */}
                    <span>{totalRecords}</span> entries
                  </p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        (showModel.show == 'show' && <AddTestimonial childToParent={childToParent} />)
      }
      {
        (showEditModel.show == 'show' && <EditTestimonials childToParent={childToParent} editTestiId={editTestiId} logged_user_id={user_id} added_by_id={editEmpId} editEmpName={editEmpName} />)
      }
    </>
  )
}

export default AdminTestimonilasListings