import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import * as CONST from "../../../component/config/Constant";
import { AuthContext } from '../../../component/context/AuthContext';
import { toast } from "react-toastify";
import moment from 'moment';

function ViewGuardian({ closeGuardian, editEmpId, firstName, lastName }) {
    console.log('guardId:' + editEmpId);
    const [authUserData, setAuthUserData] = useContext(AuthContext);
    const [show, setShow] = useState('show');
    const [loading, setLoading] = useState(false);
    const [empId, setEmpId] = useState(editEmpId);
    const [guardianFields, setGuardianFields] = useState([{
        relation_name: '',
        guardian_name: '',
        guardian_email: '',
        guardian_phone: '',
        guardian_address: '',
    }]);

    let isToken = '';
    let user_id = '';
    if (authUserData) {
        if (authUserData[0]) {
            isToken = authUserData[0].token ? authUserData[0].token : "";
            user_id = authUserData[0].user_id ? authUserData[0].user_id : "";
        }
    }

    useEffect(() => {
        fetchGuardian(empId);
    }, [])

    const fetchGuardian = async (empId) => {
        setLoading(true);
        await axios
            .get(CONST.GET_GUARDIAN_BY_USERID + empId,
                {
                    headers: {
                        'Authorization': `Bearer ${isToken}`
                    },

                })

            .then((response) => {

                const status = response.data.status;

                if (status === 'success') {
                    if (response.data.data != null) {
                        setGuardianFields(response.data.data);
                    }
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        setLoading(false);
    }
    return (
        <>
            <Modal
                size="lg"
                show={show}
                onHide={() => closeGuardian('hide')}
                backdrop="static"
                keyboard={false}
                centered
                fullscreen={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Guardian Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>
                        {(firstName && lastName)
                            ? <> {`${firstName && firstName} ${lastName && lastName}`}</>
                            : ''
                        }
                    </h5>
                    <hr />
                    <div className="row">
                        {

                            guardianFields.map((data, i) => {
                                const { relation_name, guardian_name, guardian_email, guardian_phone, guardian_address } = data;
                                return (
                                    <div className="row">

                                        <div className="col-md-12">
                                            <div className="form-group mb-3 ">
                                                <label className="form-label bold">
                                                    <b>Relation:</b>  {(relation_name) ? relation_name : '-'}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-3 ">
                                                <label><b>Guardian Name: </b> {(guardian_name) ? guardian_name : '-'}</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-3 ">
                                                <label><b>Guardian Email:</b> {(guardian_email) ? guardian_email : '-'}</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-3 ">
                                                <label><b>Guardian Phone: </b> {(guardian_phone) ? guardian_phone : '-'}</label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-3 ">
                                                <label><b>Guardian Address: </b> {(guardian_address) ? guardian_address : '-'}</label>
                                            </div>
                                        </div>
                                        <hr />
                                    </div>
                                )
                            })
                        }
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default ViewGuardian