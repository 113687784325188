import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { AuthContext } from '../../../component/context/AuthContext';
import DatePicker from 'react-datetime';
import moment from 'moment';
import axios from 'axios';
import { toast } from "react-toastify";
import Loading from "react-fullscreen-loading";
import * as  CONST from "../../../component/config/Constant"
import DivSpinner from '../../../component/utilities/Spinner';
import ExportCsv from '../../../component/export/ExportCsv';
function ViewLeaves({ closeLeave, editEmpId, firstName, lastName, totalLeaves, remainingLeaves, consumedLeaves }) {
	const [authUserData, setAuthUserData] = useContext(AuthContext);
	const [show, setShow] = useState('show');

	let isToken = '';
	let user_id = '';
	if (authUserData) {
		if (authUserData[0]) {
			isToken = authUserData[0].token ? authUserData[0].token : "";
			user_id = authUserData[0].user_id ? authUserData[0].user_id : "";
		}
	}

	const currentYear = new Date().getFullYear();
	// Generate an array of years from currentYear till 2003
	const years = Array.from({ length: currentYear - 2002 }, (_, index) => currentYear - index);
	const currentDate = new Date();
	const currentMonth = String(currentDate.getMonth() + 1).padStart(2, '0');

	// List of month names
	const months = [
		{ value: '01', label: 'January' },
		{ value: '02', label: 'February' },
		{ value: '03', label: 'March' },
		{ value: '04', label: 'April' },
		{ value: '05', label: 'May' },
		{ value: '06', label: 'June' },
		{ value: '07', label: 'July' },
		{ value: '08', label: 'August' },
		{ value: '09', label: 'September' },
		{ value: '10', label: 'October' },
		{ value: '11', label: 'November' },
		{ value: '12', label: 'December' },
	];


	const [totalLeaveCount, setTotalLeaveCount] = useState(totalLeaves);
	const [consumedLeaveCount, setConsumedLeaveCount] = useState(consumedLeaves);
	const [balanceLeaveCount, setBalanceLeaveCount] = useState(remainingLeaves);
	const [selectedYear, setSelectedYear] = useState(currentYear);
	const [srchYear, setSrchYear] = useState(currentYear);
	const [srchLeaveAppliedDate, setSrchLeaveAppliedDate] = useState('');
	const [leaveAppliedDate, setLeaveAppliedDate] = useState(true);
	const [srchStatus, setSerchStatus] = useState('');
	const [selectedStatus, setSelectedStatus] = useState('');
	const [srchMonth, setSrchMonth] = useState(currentMonth);
	const [selectedMonth, setSelectedMonth] = useState(currentMonth);
	const [srchDateOfLeaves, setSrchDateOfLeaves] = useState('');
	const [selectedDateOfLeaves, setSelectedDateOfLeaves] = useState('');
	const [srchDateOfAppliedLeaves, setSrchDateOfAppliedLeaves] = useState('');
	const [popupLoading, setPopupLoading] = useState(true);
	const [filterUsers, setfilterUsers] = useState([]);
    const headerData = [
          { label: "Name", key: "first_name" },
          { label: "Department", key: "department" },
          { label: "Leave Date", key: "leave_date" },
		  { label: "Leave Type", key: "leave_type" },
		  { label: "Information", key: "information" },
          { label: "Comments", key: "comment" },
          { label: "Status", key: "status" }
        ];


	const [dataSort, setDataSort] = useState({
		order: 'desc',
		order_by: 'id'
	});
	const [leaveData, setLeaveData] = useState();
	const handleYearChange = (event) => {
		setSelectedYear(parseInt(event.target.value));
	};
	const handleStausChange = (event) => {
		setSelectedStatus(event.target.value);
	};
	const handleSrchMonth = (event) => {
		setSelectedMonth(event.target.value);
	}

	const fetchEmployeeLeaves = async (editEmpId, srchYear, srchMonth) => {
		setPopupLoading(true);
		const response = await axios
			.post(CONST.LEAVES_BY_EMP_ID,
				{
					"id": editEmpId,
					"limit": 100,
					"page_number": "1",
					"year": srchYear,
					"month": srchMonth,
					"search_input": {
						"srchStatus": srchStatus,
						"srchLeaveType": "",
						'srchDateOfLeaves': srchDateOfLeaves,
						'srchDateOfLeavesApply': srchLeaveAppliedDate
					},
					"sort": {
						"order": "desc",
						"order_by": "id"
					}
				},
				{
					headers: {
						'Authorization': `Basic ${isToken}`
					}
				}
			)
			.then((response) => {
				console.log(response);
				const status = response.data.status;
				if (status == 'success') {
					const userListJson = response.data.data;
                    const transformedData = userListJson.map(data => ({
                        ...data,
                        first_name: `${data.user.first_name} ${data.user.last_name}`,
                        department: `${data.user.department.name}`,
                        leave_date:`${moment(data.leave_from_date).format("DD-MM-YYYY")}`,
						leave_type:`${data.leave_type}`,
                        information:`${data.reason }`,
						comment:`${data.leave_comment }`,
                        status:`${(data.status == 0) ? 'pendding' :(data.status == 1) ? 'Approved' : (data.status == 2) ? 'Rejected' : 'Cancelled'}`,
                    }));
                    // console.log('myfinaldata', transformedData)
                    setfilterUsers(transformedData)

				} else {
					toast.error(response.data.message);
				}
				const leaves = response.data.data;
				setLeaveData(leaves);
				setTotalLeaveCount(response.data.totalLeaveCount);
				setConsumedLeaveCount(response.data.ConsumedLeaveCount);
				setBalanceLeaveCount(response.data.BalanceLeaveCount);
			})
			.catch((error) => {
				//console.log(error);
				toast.error("There was an error.");
			});
		setPopupLoading(true);
	}
	/* LEAVES_BY_EMP_ID */
	useEffect(() => {
		fetchEmployeeLeaves(editEmpId, srchYear, srchMonth);
	}, [editEmpId, srchYear, srchMonth, srchStatus, srchDateOfLeaves, srchLeaveAppliedDate])

	const handleSearch = () => {
		setSrchYear(selectedYear);
		setSerchStatus(selectedStatus);
		setSrchMonth(selectedMonth);
		setSrchDateOfLeaves((selectedDateOfLeaves != '') ? moment(selectedDateOfLeaves).format('DD-MM-YYYY') : '');
		setSrchLeaveAppliedDate((srchLeaveAppliedDate != '') ? moment(srchLeaveAppliedDate).format('DD-MM-YYYY') : '');
	}

	const handleReSetSearch = () => {
		/* Reset year */
		setSrchYear(currentYear);
		setSelectedYear(currentYear);
		/* Reset Status */
		setSerchStatus("");
		setSelectedStatus("");
		/* Month Reset */
		setSrchMonth(currentMonth);
		setSelectedMonth(currentMonth);
		/* Reset Date of leaves */
		setSrchDateOfLeaves("");
		setSelectedDateOfLeaves("");
		setSrchLeaveAppliedDate("");
	}


	return (
		<>
			<Loading
				loading={popupLoading}
				background="rgba(0, 0, 0, 0.5)"
				loaderColor="#fff"
			/>
			<Modal
				size="lg"
				show={show}
				onHide={() => closeLeave('hide')}
				backdrop="static"
				keyboard={false}
				centered
				fullscreen={true}
			>
				<Modal.Header closeButton>
					<Modal.Title>Leave</Modal.Title>
				</Modal.Header>
				<Modal.Body>

					<h5>
						{(firstName && lastName)
							? <> {`${firstName && firstName} ${lastName && lastName}`}</>
							: ''
						}
					</h5>

					<hr />

					<div class="row">
						<div class="col-md-2">
							<div class="mb-3">
								<label class="form-label required">Year</label>
								<select class="form-select form-control" name="srchEmpYear" id="srchEmpYear" placeholder="Select Year"
									value={selectedYear}
									onChange={handleYearChange}
								>
									<option value="">Select</option>
									{
										years.map((year) => (
											<option key={year} value={year} >
												{year}
											</option>
										))
									}
								</select>
							</div>
						</div>
						<div class="col-md-2">
							<div class="mb-3">
								<label class="form-label required">Status</label>
								<select class="form-select form-control" name="srchStatus" id="srchStatus" placeholder="Select Year"
									value={selectedStatus}
									onChange={handleStausChange}
								>
									<option value="">All</option>
									<option value="0">Pending</option>
									<option value="1">Approved</option>
									<option value="2">Rejected</option>
									<option value="3">Cancelled</option>
								</select>
							</div>
						</div>
						<div className="col-md-2">
							<div className="mb-2">
								<label className="form-label required">
									Month
								</label>
								<select class="form-select form-control" name="srchMonth" id="srchMonth" placeholder="Select Year"
									value={selectedMonth}
									onChange={handleSrchMonth}
								>
									<option value="">Select</option>
									{
										months.map((month) => (
											<option key={month.value} value={month.value}>
												{month.label}
											</option>
										))
									}
								</select>
							</div>
						</div>
						<div className="col-md-2">
							<div className="form-group mb-3 ">
								<label className="form-label required">
									Date of Leave(s)
								</label>
								<div>
									<DatePicker
										name="srchDateOfLeaves"
										id='srchDateOfLeaves'
										value={selectedDateOfLeaves}
										selected={selectedDateOfLeaves}
										timeFormat=""
										placeholder="Select Date"
										dateFormat="DD-MM-YYYY"
										onChange={(date) => setSelectedDateOfLeaves(date)}
										inputProps={{
											placeholder: 'Select a date',
										}}
									/>
								</div>

							</div>
						</div>
						<div className="col-md-2">
							<div className="form-group mb-3 ">
								<label className="form-label required">
									Date of Applied Leave(s)
								</label>
								<div>
									<DatePicker
										name="srchLeaveAppliedDate"
										id='srchLeaveAppliedDate'
										value={srchLeaveAppliedDate}
										selected={srchLeaveAppliedDate}
										timeFormat=""
										placeholder="Select Date"
										dateFormat="DD-MM-YYYY"
										onChange={(date) => setSrchLeaveAppliedDate(date)}
										inputProps={{
											placeholder: 'Select a date',
										}}
									/>
								</div>

							</div>
						</div>

						<div class="col-md-3">
							<div class="form-group mb-3 ">
								<label class="form-label">&nbsp;</label>
								<div>
									<button
										type="button"
										name="btnSearch"
										id="btnSearch"
										class="btn btn-blue d-none d-sm-inline-block pr-2 mr-2"
										onClick={handleSearch}>
										<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-search" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
											<path stroke="none" d="M0 0h24v24H0z" fill="none">
											</path>
											<circle cx="10" cy="10" r="7"></circle>
											<line x1="21" y1="21" x2="15" y2="15"></line>
										</svg>Search</button>
									<button type="button" name="btnSearchReset" id="btnSearchReset" class="btn btn-outline-primary " onClick={handleReSetSearch}>
										<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
											<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
											<line x1="18" y1="6" x2="6" y2="18"></line>
											<line x1="6" y1="6" x2="18" y2="18"></line>
										</svg>Clear
									</button>
									{filterUsers && <ExportCsv propsEmpName={filterUsers} propsHeaders={headerData} />}
								</div>
							</div>
						</div>
					</div>


					<div className="row pt-2   d-flex align-items-center justify-content-center">
						<div className="col-md-3">
							<div class="card card-sm">
								<div class="card-body">
									<div class="row align-items-center">
										<div class="col-auto">
											<span class="bg-green-lt avatar">
												<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-inner-shadow-bottom-right" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
													<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
													<path d="M12 21a9 9 0 1 1 0 -18a9 9 0 0 1 0 18z"></path>
													<path d="M18 12a6 6 0 0 1 -6 6"></path>
												</svg>
											</span>
										</div>
										<div class="col">
											<div class="font-weight-medium"><strong>Total Leaves</strong></div>
											<div class="text-muted">
												{
													(totalLeaveCount || totalLeaveCount == 0) ? (
														totalLeaveCount
													) : (
														<div className='p-3 text-center'>
															<DivSpinner size="sm" color="primary" />

														</div>
													)
												}


											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-3">
							<div class="card card-sm">
								<div class="card-body">
									<div class="row align-items-center">
										<div class="col-auto">
											<span class="bg-red-lt avatar">
												<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
													<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
													<line x1="12" y1="5" x2="12" y2="19"></line>
													<line x1="18" y1="13" x2="12" y2="19"></line>
													<line x1="6" y1="13" x2="12" y2="19"></line>
												</svg>
											</span>
										</div>
										<div class="col">
											<div class="font-weight-medium">
												<strong>Leaves Used</strong>
											</div>
											<div class="text-muted">
												{
													(consumedLeaveCount || consumedLeaveCount == 0) ? (
														consumedLeaveCount
													) : (
														<div className='p-3 text-center'>
															<DivSpinner size="sm" color="primary" />

														</div>
													)
												}

											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-3">
							<div class="card card-sm">
								<div class="card-body">
									<div class="row align-items-center">
										<div class="col-auto">
											<span class="bg-green-lt avatar">
												<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
													<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
													<line x1="12" y1="5" x2="12" y2="19"></line><line x1="18" y1="11" x2="12" y2="5"></line>
													<line x1="6" y1="11" x2="12" y2="5"></line>
												</svg>
											</span>
										</div>
										<div class="col"><div class="font-weight-medium"><strong>Pending Leaves</strong>
										</div>
											<div class="text-muted">
												{
													(balanceLeaveCount || balanceLeaveCount == 0) ? (
														balanceLeaveCount
													) : (
														<div className='p-3 text-center'>
															<DivSpinner size="sm" color="primary" />

														</div>
													)
												}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<hr />
					<div class="col-12">
						<div class="card">
							<div class="card-header">
								<h3 class="card-title">Leaves</h3>
							</div>
							<div class="card-table table-responsive">
								<table class="table table-vcenter" style={{ fontSize: '14px' }}>
									<thead>
										<tr>
											<th>Leave Type</th>
											<th>Leaves Date</th>
											<th>Leave applied Date</th>
											<th>Information</th>
											<th>Comment</th>
											<th>Status</th>
											<th>By</th>
										</tr>
									</thead>
									<tbody>
										{
											leaveData ? (leaveData.map((leave, index) => {
												return (
													<tr>
														<td class="w-1">{leave && leave.leave_type}
															<br /><small>{leave.e_leave_type.name}</small>
														</td>
														<td class="w-1">
															{
																leave && (
																	<>
																		<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><rect x="4" y="5" width="16" height="16" rx="2"></rect><line x1="16" y1="3" x2="16" y2="7"></line><line x1="8" y1="3" x2="8" y2="7"></line><line x1="4" y1="11" x2="20" y2="11"></line><line x1="11" y1="15" x2="12" y2="15"></line><line x1="12" y1="15" x2="12" y2="18"></line></svg>&nbsp;{leave.leave_from_date}

																		{
																			(
																				leave.leave_type == 'Full Day') && (
																				<> <b>To</b>  {leave.leave_to_date} </>
																			)
																		}
																	</>

																)
															}
														</td>
														<td class="w-1">{leave && leave.leave_type}</td>
														<td class="w-1 leave-details">
															<h5 style={{ fontSize: '13px' }}>{leave && leave.subject}</h5>
															<p style={{ fontSize: '12px' }} className='small_text'>
																{
																	leave &&
																	(
																		<small className='small_text' style={{ fontSize: '12px' }} dangerouslySetInnerHTML={{ __html: leave.reason }} ></small>

																	)
																}
															</p>
														</td>
														<td class="w-1">{leave && leave.leave_comment}</td>
														<td class="w-1">
															{
																leave && (
																	<>
																		{leave.status == 0 ? <span class='badge bg-info '>Pending</span> : ""}
																		{leave.status == 1 ? <span class='badge bg-success '>Approved</span> : ""}
																		{leave.status == 2 ? <span class='badge bg-warning '>Rejected</span> : ""}
																		{leave.status == 3 ? <span class='badge bg-danger '>Cancelled</span> : ""}
																	</>
																)
															}
														</td>
														<td>
															{
																leave.approvedByUser && (
																	<>
																		{leave.approvedByUser.first_name}&nbsp;{leave.approvedByUser.last_name}
																	</>
																)
															}
														</td>
													</tr>
												)
											})
											) : (
												<>
													<tr>
														<td class="w-1">
															<h6 className=''>No Record Found</h6></td>
													</tr>
												</>
											)
										}
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</Modal.Body>

			</Modal >
		</>
	)
}

export default ViewLeaves