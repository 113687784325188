import React, { useContext, useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { AuthContext } from '../../../component/context/AuthContext';
import axios from "axios";
import { toast } from "react-toastify";
import * as  CONST from "../../../component/config/Constant";
import Form from "../../../component/utilities/Forms";
import { Link } from 'react-router-dom';
import DivSpinner from '../../../component/utilities/Spinner';
import moment from 'moment';

const ApproveRejectLeaveDetails = ({ closeLeave, editLeaveId, viewData }) => {
    const [authUserData, setAuthUserData] = useContext(AuthContext);
    const [show, setShow] = useState('show');
    const [loading, setLoading] = useState(false);
    const [leaveId, setLeaveId] = useState(editLeaveId);
    const [validate, setValidate] = useState({});
    const [comments, setComments] = useState('');
    const [empDetails, setEmpDetails] = useState('');
    const [changeStatus, setChangeStatus] = useState('');
    let isToken = '';
    let user_id = '';
    if (authUserData) {
        if (authUserData[0]) {
            isToken = authUserData[0].token ? authUserData[0].token : "";
            user_id = authUserData[0].user_id ? authUserData[0].user_id : "";
        }
    }

    const formatDate = (date) => {
        return moment(date).format('YYYY-MM-DD HH:MM')
    }

    const fetchEmployee = async () => {
        await axios
            .post(CONST.GET_EMP_BY_ID, {
                id: user_id,
                user_id: viewData.user_id
            }, {
                headers: {
                    'Authorization': `Basic ${isToken}`
                }
            })
            .then((response) => {
                const status = response.data.status;
                if (status === 'success') {
                    setLoading(true);
                    console.log(response.data.data);
                    setEmpDetails(response.data.data);
                }
            })
            .catch((error) => {
                toast.error(error);
            });
    }

    useEffect(() => {

        setChangeStatus(viewData.status);
        setComments(viewData.compoff_comment);
        fetchEmployee();
        console.log(viewData);
    }, []);


    const validateLeave = async () => {
        let isValid = true;

        let validator = Form.validator({

            comments: {
                value: comments,
                isRequired: true,
                isEmail: false,
                label: "Comments",
            },
            changeStatus: {
                value: changeStatus,
                isRequired: true,
                isEmail: false,
                label: "Status",
            },

        });
        console.log('validator:' + JSON.stringify(validator));
        if (validator !== null) {
            setValidate({
                validate: validator.errors,
            });
            isValid = false;
        } else {
            setValidate({
                validate: "",
            });
            isValid = true;
        }
        return isValid;
    };

    const handleSubmit = async (e) => {

        // alert('toooo:'+to); alert('ccccc:'+cc);
        e.preventDefault();
        
            setLoading(true);
            let sendData = {

                id: user_id,
                compoff_id: viewData.id,
                compoff_comment: comments,
                status: changeStatus
            }


            await axios({
                method: "post",
                url: CONST.COMP_OFF_CHANGE_STATUS,
                data: sendData,
                headers: {
                    'Authorization': `Bearer ${isToken}`
                }

            })
                .then((response) => {
                    console.log('apply leave response: ' + JSON.stringify(response));
                    //alert(response.data.status);
                    if (response) {

                        if (response.data.status == 'error') {

                            toast.error("Something went wrong. Please try again.");
                        } else {
                            toast.success(response.data.message);
                            closeLeave();
                            setLoading(false);
                        }
                    }
                })
                .catch((error) => {
                    setLoading(false);

                    toast.error("Something went wrong. Please try again." + error);
                });


        

    }

    const formatTime = (date) => {
        return date.slice(0, -3);
    }
    return (
        <>
            <Modal
                size="lg"
                show={show}
                onHide={() => closeLeave('hide')}
                backdrop="static"
                keyboard={false}
                centered
                fullscreen={true}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Approve/Reject Comp Off</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row mt-2'>
                        <div className='col-4 mb-5'>
                            {
                                empDetails ? (
                                    <div>

                                        <div className="pt-2">
                                        {
                                            (() => {

                                                if (viewData.status == '1') {
                                                    if (viewData.compoffApprovedByUser) {
                                                        return (
                                                            <>
                                                                <span className="badge bg-success f-14">Comp-Off is approved by {viewData.compoffApprovedByUser.first_name + ' ' + viewData.compoffApprovedByUser.last_name}.</span>
                                                            </>
                                                        )
                                                    }
                                                } else if (viewData.status == '2') {
                                                    if (viewData.CompoffApproveRejectByData) {
                                                        return (
                                                            <>
                                                                <span className="badge bg-warning f-14">Comp-Off is rejected by {viewData.CompoffApproveRejectByData[0].CompoffApproveRejectUserData.first_name + ' ' + viewData.CompoffApproveRejectByData[0].CompoffApproveRejectUserData.last_name}.</span>
                                                            </>
                                                        )
                                                    }
                                                } else if (viewData.status == '3') {
                                                    return (
                                                        <>
                                                            <span className="badge bg-danger text-danger-fg f-14">Employee has cancelled the Comp off.</span>
                                                        </>
                                                    )
                                                } else {
                                                    return (
                                                        <>
                                                            <span className="badge bg-info f-14">Comp-off is pending</span>
                                                        </>
                                                    )
                                                }


                                            })()
                                        }
                                    </div>
                                    <div className="form-group mb-3 ">
                                        <label className="form-label ">
                                            <b><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-calendar-due mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M4 5m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"></path>
                                                <path d="M16 3v4"></path>
                                                <path d="M8 3v4"></path>
                                                <path d="M4 11h16"></path>
                                                <path d="M12 16m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                                            </svg>
                                                Date : </b>  {viewData.leave_from_date + ((viewData.leave_type == '1') ? ' to ' + viewData.leave_from_date : '')}
                                        </label>
                                    </div>
                                        {
                                          viewData.compoffUsers.map((element, key) => {
                                            
                                              return (
                                                <>
                                                  
                                                  <div className="d-flex py-1 align-items-center">
                                                    {
                                                        (element.compoffUserData.profile_photo_path && element.compoffUserData.profile_photo_path)
                                                            ? (
                                                                <span className="avatar    " >

                                                                    <img src={CONST.HOST + element.compoffUserData.profile_photo_path} alt='' width={40} className="rounded-circle" />
                                                                </span>
                                                            ) : (
                                                                <span className="avatar  rounded-circle  ">
                                                                    <span className=''>
                                                                        {
                                                                            (element.compoffUserData.first_name && element.compoffUserData.last_name)
                                                                                ? <> {element.compoffUserData.first_name && element.compoffUserData.first_name.substring(0, 1)}
                                                                                    {element.compoffUserData.last_name && element.compoffUserData.last_name.substring(0, 1)}</>
                                                                                : element.compoffUserData.name && element.compoffUserData.name.substring(0, 2)
                                                                        }

                                                                    </span>
                                                                </span>)

                                                    }

                                                    <div className="flex-fill pl-2">
                                                        <div className="font-weight-medium color_blue">
                                                            {(element.compoffUserData.first_name && element.compoffUserData.last_name)
                                                                ? <>{element.compoffUserData.first_name} {element.compoffUserData.last_name}</>
                                                                : element.compoffUserData.name
                                                            }

                                                        </div>
                                                        <div className="text-muted">
                                                            <Link target="_blank" to={`mailto:${element.compoffUserData.email}`} className="text-muted">
                                                                {element.compoffUserData.email.toLowerCase()}
                                                            </Link>
                                                        </div>
                                                        
                                                    </div>
                                                </div>

                                                <div className="form-group mb-3 mt-3 ">
                                                    <label className="form-label">
                                                        <b><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-door-exit mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <path d="M13 12v.01"></path>
                                                            <path d="M3 21h18"></path>
                                                            <path d="M5 21v-16a2 2 0 0 1 2 -2h7.5m2.5 10.5v7.5"></path>
                                                            <path d="M14 7h7m-3 -3l3 3l-3 3"></path>
                                                        </svg>Comp-Off Type:</b> <span class="info">{(element.leave_type == '0') ? 'Half day' : 'Full day'}</span>
                                                        {
                                                            viewData.e_leave_type && (<span class="info">{(viewData.e_leave_type.name) ? ' / ' + viewData.e_leave_type.name : ''}</span>)
                                                        }

                                                    </label>
                                                </div>
                                                <hr/>
                                                </>
                                              )
                                            
                                          })
                                        }
                                        
                                        {/*<div className="d-flex py-1 align-items-center">
                                            {
                                                (empDetails.profile_photo_path && empDetails.profile_photo_path)
                                                    ? (
                                                        <span className="avatar    " >

                                                            <img src={CONST.HOST + empDetails.profile_photo_path} alt='' width={40} className="rounded-circle" />
                                                        </span>
                                                    ) : (
                                                        <span className="avatar  rounded-circle  ">
                                                            <span className=''>
                                                                {
                                                                    (empDetails.first_name && empDetails.last_name)
                                                                        ? <> {empDetails.first_name && empDetails.first_name.substring(0, 1)}
                                                                            {empDetails.last_name && empDetails.last_name.substring(0, 1)}</>
                                                                        : empDetails.name && empDetails.name.substring(0, 2)
                                                                }

                                                            </span>
                                                        </span>)

                                            }

                                            <div className="flex-fill pl-2">
                                                <div className="font-weight-medium color_blue">
                                                    {(empDetails.first_name && empDetails.last_name)
                                                        ? <>{empDetails.first_name} {empDetails.last_name}</>
                                                        : empDetails.name
                                                    }

                                                </div>
                                                <div className="text-muted">
                                                    <Link target="_blank" to={`mailto:${empDetails.email}`} className="text-muted">
                                                        {empDetails.email.toLowerCase()}
                                                    </Link>
                                                </div>
                                                <div className="pt-2">
                                                    {
                                                        (() => {

                                                            if (viewData.status == '1') {
                                                                if (viewData.approvedByUser) {
                                                                    return (
                                                                        <>
                                                                            <span className="badge bg-success f-14">Approved by {viewData.approvedByUser.first_name + ' ' + viewData.approvedByUser.last_name}.</span>
                                                                        </>
                                                                    )
                                                                }
                                                            } else if (viewData.status == '2') {
                                                                if (viewData.approvedByUser) {
                                                                    return (
                                                                        <>
                                                                            <span className="badge bg-danger f-14">Rejected by {viewData.approvedByUser.first_name + ' ' + viewData.approvedByUser.last_name}.</span>
                                                                        </>
                                                                    )
                                                                }
                                                            } else if (viewData.status == '3') {
                                                                return (
                                                                    <>
                                                                        <span className="badge bg-orange text-orange-fg f-14">Employee has cancelled the leave.</span>
                                                                    </>
                                                                )
                                                            } else {
                                                                return (
                                                                    <>
                                                                        <span className="badge bg-warning f-14">Comp-off is pending</span>
                                                                    </>
                                                                )
                                                            }


                                                        })()
                                                    }
                                                </div>
                                            </div>
                                        </div>*/}

                                        {/*<div className="form-group mb-3 mt-3 ">
                                            <label className="form-label">
                                                <b>
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-bear-right-2 pr-1 " width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M15 3h5v5"></path>
                                                        <path d="M20 3l-7.536 7.536a5 5 0 0 0 -1.464 3.534v6.93"></path>
                                                        <path d="M4 5l4.5 4.5"></path>
                                                    </svg>
                                                </b>
                                                {
                                                    empDetails.department && (
                                                        <>
                                                            <span className='color_blue'> {empDetails.department.name}</span> {empDetails.designation && (
                                                                <>
                                                                    /  <span className='color_blue'> {empDetails.designation.name}</span>
                                                                </>
                                                            )
                                                            }
                                                        </>
                                                    )

                                                }
                                            </label>
                                        </div>*/}

                                    </div>
                                ) : (
                                    < div className='p-3 text-center'>
                                        <DivSpinner size="sm" color="primary" />

                                    </div>
                                )


                            }


                            

                            
                            

                            
                        

                            {/*<div className="form-group mb-3 ">
                                <label className="form-label ">
                                    <b><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-calendar-due mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M4 5m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"></path>
                                        <path d="M16 3v4"></path>
                                        <path d="M8 3v4"></path>
                                        <path d="M4 11h16"></path>
                                        <path d="M12 16m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                                    </svg>
                                        Comp-Off Added on : </b>  {formatDate(viewData.leave_applied_date)}
                                </label>
                            </div>*/}

                            

                            {
                                viewData.appliedByUser && (
                                    <div className="form-group mb-3 ">
                                        <label className="form-label ">
                                            <b>  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-calendar-stats mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <path d="M11.795 21h-6.795a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v4"></path>
                                                <path d="M18 14v4h4"></path>
                                                <path d="M18 18m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"></path>
                                                <path d="M15 3v4"></path>
                                                <path d="M7 3v4"></path>
                                                <path d="M3 11h16"></path>
                                            </svg>
                                                 Added By :</b>  <span>{viewData.appliedByUser.first_name + ' ' + viewData.appliedByUser.last_name}</span>
                                        </label>
                                    </div>
                                )
                            }

                        </div>
                        <div className='col-8'>

                            <div className="form-group mb-3 ">
                                <label className="form-label ">
                                  {
                                    viewData.CompoffCcMailData.map((element, key) => {

                                      return (
                                        <>
                                          <span className='pr-5'>
                                            <b>To : </b>
                                            <span>{element.user.first_name + ' ' + element.user.last_name}</span>
                                          </span>
                                        </>
                                      )
                                    })

                                  }

                                  {
                                    viewData.CompoffToMailData.map((element, key) => {

                                      return (
                                        <>
                                          <span className='pr-5'>
                                            <b>CC : </b>
                                            <span>{element.user.first_name + ' ' + element.user.last_name}</span>
                                          </span >
                                        </>
                                      )
                                    })

                                  }

                                </label>
                                <hr />
                              </div>
                            <div className="form-group mb-3 ">
                                <label className="form-label">
                                    <h5><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-mail-question mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M15 19h-10a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v4.5"></path>
                                        <path d="M19 22v.01"></path>
                                        <path d="M19 19a2.003 2.003 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483"></path>
                                        <path d="M3 7l9 6l9 -6"></path>
                                    </svg><span class="info" dangerouslySetInnerHTML={{ __html: viewData.subject }} /></h5>
                                </label>
                                <hr />
                            </div>
                            <div className="form-group mb-3 ">
                                <label className="form-label ">
                                    <span class="info" dangerouslySetInnerHTML={{ __html: viewData.reason }} />
                                </label>
                            </div>
                            <div className="form-group mb-3 ">
                                {(() => {

                                    if (viewData.status != '3') {
                                        return (
                                            <>
                                                <form
                                                    method="POST"
                                                    autoComplete={"off"}
                                                >
                                                    <div className="  mb-3 ">
                                                        <label className="form-label">
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-adjustments mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                <path d="M4 10a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
                                                                <path d="M6 4v4"></path>
                                                                <path d="M6 12v8"></path>
                                                                <path d="M10 16a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
                                                                <path d="M12 4v10"></path>
                                                                <path d="M12 18v2"></path>
                                                                <path d="M16 7a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
                                                                <path d="M18 4v1"></path>
                                                                <path d="M18 9v11"></path>
                                                            </svg>
                                                            <b>Status : </b>  <span>{
                                                                (viewData.status == 1) ? 'Approved' : (viewData.status == 2) ? 'Rejected' : (viewData.status == 3) ? 'Cancelled' : 'Pending'}</span>
                                                        </label>
                                                        <select
                                                            className={`select-form-control  form-select form-control ${validate.validate && validate.validate.changeStatus
                                                                ? "is-invalid "
                                                                : ""
                                                                }`}
                                                            name="changeStatus"
                                                            id="changeStatus"
                                                            placeholder="Select status"
                                                            onChange={(e) => setChangeStatus(e.target.value)}
                                                            value={changeStatus}
                                                            style={{ width: '100px' }}
                                                        >
                                                            <option>Select Status </option>
                                                            <option value="0">Pending</option>
                                                            <option value="1">Approved</option>
                                                            <option value="2">Rejected</option>

                                                        </select>
                                                    </div>
                                                    <div className="  mb-3 ">
                                                        <label className="form-label bold">
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-message-plus mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                <path d="M8 9h8"></path>
                                                                <path d="M8 13h6"></path>
                                                                <path d="M12.01 18.594l-4.01 2.406v-3h-2a3 3 0 0 1 -3 -3v-8a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v5.5"></path>
                                                                <path d="M16 19h6"></path>
                                                                <path d="M19 16v6"></path>
                                                            </svg> Comments
                                                        </label>

                                                        <div>
                                                            <textarea
                                                                type="text"
                                                                name="comments"
                                                                id="comments"
                                                                className={`form-control col-6 ${validate.validate && validate.validate.comments
                                                                    ? "is-invalid "
                                                                    : ""
                                                                    }`}
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Comments"
                                                                onChange={(e) => setComments(e.target.value)}
                                                                value={comments}
                                                                rows="3"
                                                                width="150"
                                                            >{comments}</textarea>
                                                            {/*  <input
                                                                type="text"
                                                                name="comments"
                                                                id="comments"
                                                                className={`form-control ${validate.validate && validate.validate.comments
                                                                    ? "is-invalid "
                                                                    : ""
                                                                    }`}
                                                                aria-describedby="emailHelp"
                                                                placeholder="Enter Comments"
                                                                onChange={(e) => setComments(e.target.value)}
                                                                value={comments}
                                                            /> */}
                                                        </div>
                                                    </div>
                                                    <div className="form-group mb-3 ">

                                                        <button
                                                            type="button"
                                                            className="mr-3 btn btn-primary"
                                                            onClick={handleSubmit}
                                                        >
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-location mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                <path d="M21 3l-6.5 18a.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a.55 .55 0 0 1 0 -1l18 -6.5"></path>
                                                            </svg>Submit
                                                        </button>

                                                        &nbsp;&nbsp;

                                                    </div>
                                                </form>
                                            </>
                                        )
                                    }


                                })()}


                            </div>
                        </div>
                    </div>
                </Modal.Body>

            </Modal >
        </>
    );
};

export default ApproveRejectLeaveDetails;