import React from "react";
import ReactDOM from "react-dom/client";
/* import './index.css'; */
import App from "./App";
//import SimplerReactLightbox from "simpler-react-lightbox";
import reportWebVitals from "./reportWebVitals";
//import SSRProvider from "react-bootstrap/SSRProvider";
import { ToastContainer, Zoom } from "react-toastify";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    
    <>
        <App />
        <ToastContainer
        autoClose={8000}
        transition={Zoom}
        position="top-right"
        theme="dark" />
    </>
    
);
reportWebVitals();
