/* import 'bootstrap/dist/css/bootstrap.min.css'; */
import { BrowserRouter } from "react-router-dom";
import NavRoutes from "./component/nav-routes/NavRoutes";
import Header from "./component/include/Header"
import Footer from "./component/include/Footer"
import { AuthProvider } from "./component/context/AuthContext";
import React from "react";
function App() {

  return (
    <BrowserRouter basename="">
      <AuthProvider>
        <Header></Header>
        <NavRoutes />
        <Footer></Footer>
      </AuthProvider>
    </BrowserRouter>
  );
}
export default App;
