import { React, useState, useEffect, useContext, useMemo } from 'react'
import "../../../assets/css/table.css";
import Loading from "react-fullscreen-loading";
import EventHeader from '../banner/EventHeader';
import DivLoader from "../../../component/utilities/Spinner";
import DataTable from "react-data-table-component";
import axios from "axios";
import { toast } from "react-toastify";
import * as  CONST from "../../../component/config/Constant"
import { AuthContext } from '../../../component/context/AuthContext';
import { Link } from 'react-router-dom';
import Moment from "moment";
import editImg from "../../../assets/img/icon/edit.svg";
import deleteImg from "../../../assets/img/icon/delete.svg";
import ReactSwitch from 'react-switch';
import * as SwitchProps from '../../../component/utilities/ReactSwitchReactSwitchProps';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
/* import EventCategory from '../../FieldComponents/EventCategory'; */
import DivSpinner from '../../../component/utilities/Spinner';
import AddEvent from './AddEvent';
import EditEvent from './EditEvent';

function EventIndex() {
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  const [fullLoading, setFullLoading] = useState(false);
  const [validate, setValidate] = useState({});
  const [processing, setProcessing] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [srchEventName, setSrchEventName] = useState("");
  const [srchStatus, setSrchStatus] = useState("");
  const [srchCatName, setSrchCatName] = useState("");
  const [catLisiting, setCatLisiting] = useState('');
  const [isAddModalShow, setAddModalShow] = useState(false);
  const [isEditModalShow, setEditModalShow] = useState(false);
  /* START DATATABLE */
  const [totalRecords, settotalRecords] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [maxPageLimit, setMaxPageLimit] = useState(10);
  const [minPageLimit, setMinPageLimit] = useState(0);
  const [showModel, setShowModel] = useState({
		show: 'hide'
	});
  const [editModel, setEditModel] = useState({
		show: 'hide'
	});
  const [eventID, setEventID] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [dataSort, setDataSort] = useState({
    order: 'desc',
    order_by: 'id'
  });
  const [listLimit, setListLimit] = useState(50);

  const [searchParam, setSearchParam] = useState(
    {
      "srchTitle": srchEventName,
      "srchStatus": srchStatus,
      "srchCategory": srchCatName 
    }
);

  let isToken = '';
  let user_id = '';
  if (authUserData) {
    if (authUserData[0]) {
      isToken = authUserData[0].token ? authUserData[0].token : "";
      user_id = authUserData[0].user_id ? authUserData[0].user_id : "";
    }
  }

  useEffect(() => {
    fetchEventData(searchParam, dataSort, activePage, perPage);
    fetchCategoryData();
  }, [searchParam, dataSort, activePage, listLimit]);

  async function fetchCategoryData() {
    setLoading(true);
    await axios({
        method: 'post',
        url: CONST.GET_EVENT_CATEGORY_LIST,
        headers: {
            'Authorization': `Bearer ${isToken}`
        }
                
    })
    .then((response) => {
        console.log('Cat-list Res- '+JSON.stringify(response.data));
        const status = response.data.status;
        
        if (status === 'success') {
            setCatLisiting(response.data.data);
        }else{
            toast.error(response.data.message);
        }
    })
    .catch((error) => {
        console.log(error);
    });
    setLoading(false);
  }
  
  async function searchEvent(){
    setSearchParam({
      "srchTitle": srchEventName,
      "srchStatus": srchStatus,
      "srchCategory": srchCatName 
    })
    setDataSort({
      order: "desc",
      order_by: "id"
    });
    setActivePage("1");
    fetchEventData(searchParam, dataSort, activePage, perPage);
    //console.log('srch- '+searchParam)
  }

  function clearSearch() {
    setSearchParam({
      "srchTitle": "",
      "srchStatus": "",
      "srchCategory": ""
    })
    setSrchEventName('')
    setSrchStatus('')
    setSrchCatName('')
    setDataSort({
      order: "desc",
      order_by: "id"
    });
    setActivePage("1");
    
  }

  const handleEventEdit = async (eventID) => {
		setLoading(true);
		setEditModalShow(true);
		setEditModel({
			show: 'edit'
		});
    console.log(eventID);
    setEventID(eventID);	
		setLoading(false);
	}
	const closeForm = async (type) => {
		setShowModel({
			show: 'hide'
		});
		fetchEventData(searchParam, dataSort, activePage, perPage);
	}

  const closeEditForm = async (type) => {
		setEditModel({
			show: 'hide'
		});
		fetchEventData(searchParam, dataSort, activePage, perPage);
	}

  
  
  const Action = ({ row }) => (
    <>
      <Link onClick={()=> handleEventEdit(row.id)}><img src={editImg} alt='editUser' width={32} className='img-action cursor' /></Link>
      <Link onClick={()=> deleteEvent(row.id)} >
      <img src={deleteImg} alt='deleteImg' width={32} className=' img-action cursor'  />
      </Link>
    </>
  )

  const deleteEvent = (event_id) => {
    Swal.fire({
			title: 'Are you sure?',
			text: "Do you want to delete this event?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then((result) => {
			if (result.isConfirmed) {
				setLoading(true);
                if (event_id > 0) {
                    setLoading(true);
                    axios
                        .post(CONST.DELETE_EVENT, 
                          { 'id': event_id },
                          {
                            headers: {
                                'Authorization': `Bearer ${isToken}`
                            },
                            // data: {
                            //   id: event_id
                            // }
                        })
                        .then((response) => {
                            //console.log(response.data);
                            
                            if (response) {
        
                                if (response.data.status == 'error') {
                                    toast.error(response.data.message);
                                } else {
                                    toast.success(response.data.message);
                                    fetchEventData(searchParam, dataSort, activePage, perPage);
                                    
                                }
                            }
                        })
                        .catch((error) => {
                            setLoading(false);
                            console.log(error);
                            toast.error("Something went wrong. Please try again." + error);
                        });
                    setLoading(false);
                }
				
			}
		})
  }
  const columns = [
    {
      name: "Event Name",
      selector: (row) => row.title,
      cell: (row) => row.title,
      sortable: true,
      sortField: "event_name"
    },

    {
      name: "Category",
      selector: (row) => row.eventCategoryData.name,
      cell: (row) => row.eventCategoryData.name,
      sortable: true,
      sortField: "category"
    }
    ,
    {
      name: "Date",
      cell: (row) => Moment(row.event_date).format('DD-MM-YYYY'),
      sortable: true,
      sortField: "startdate"
    },
    
    {
      name: "Status",
      selector: (row) => row.status,
      cell: (row, index) => <ReactSwitch
      onColor={SwitchProps.onColor}
      onHandleColor={SwitchProps.onHandleColor}
      handleDiameter={SwitchProps.handleDiameter}
      uncheckedIcon={SwitchProps.uncheckedIcon}
      checkedIcon={SwitchProps.checkedIcon}
      boxShadow={SwitchProps.boxShadow}
      activeBoxShadow={SwitchProps.activeBoxShadow}
      height={SwitchProps.height}
      width={SwitchProps.width}
      className={SwitchProps.className}
      id="material-switch"
      checked={(row.status == 1) ? true : false}
      onChange={() => { handleEventStatusChange(index, row.status, row.id) }
      }
    />,
      sortable: true,
      sortField: "status"
    },
    {
      name: "Action",

      cell: (row) => <Action row={row} />,
      sortable: false,

    }
  ]

  const handleEventStatusChange = (index, val, event_id) => {
    Swal.fire({
			title: 'Are you sure?',
			text: "Do you want to change the status?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, change it!'
		}).then((result) => {
			if (result.isConfirmed) {
				setLoading(true);
				axios.post(CONST.UPDATE_EVENT_STATUS,
					{
						'id': user_id,
						'event_id': event_id,
						'status': (val == '0') ? '1' : '0',
					},
					{
						headers: {
							'Authorization': `Basic ${isToken}`
						}
					}
				)
					.then((response) => {
						console.log(response);
						const status = response.data.status;
						if (status == 'success') {
							toast.success(response.data.message);
						} else {
							toast.error(response.data.message);
						}
					})
					.catch((error) => {
						console.log(error);
						toast.error("There was an error.");
					});
				const newData = [...data];
				newData[index].status = (val == 0) ? 1 : 0;
				setData(newData);
				setLoading(false);
			}
		})
  }
  const eventImage = (row) =>{
    <>
        <img src={row.featured_image_path} alt={row.title} />
        </>
    /*{
      (row.featured_image_path !== 'null' || row.featured_image_path !== '')
      ?
      (
        <>
        <img src={row.featured_image_path} alt={row.title} />
        </>
      )
      :''
      
    }*/
  }

  async function fetchEventData(paramSearch, paramSort, paramPage, paramLimit) {
    console.log(activePage);
    setLoading(true);
    let srch_param = {
      "limit": paramLimit,
      "page_number": paramPage,
      "search_input": paramSearch,
      "sort": paramSort
    }
    console.log('event search param >>'+JSON.stringify(srch_param));
    const response = await axios
      .post(CONST.EVENT_LIST,  
        srch_param,
      {
        headers: {
          'Authorization': `Basic ${isToken}`
        }
      })
      .then((response) => {
        const status = response.data.status;
        if (status == 'success') {
          //console.log('Event List >> '+JSON.stringify(response.data.data));
          setTotalRows(response.data.count);
          setLoading(false);
          setData(response.data.data);
          settotalRecords(response.data.count);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("There was an error.");
      });
  }
  const handlePerRowsChange = async (perPage, page) => {
    console.log(handlePerRowsChange);
    setLoading(true);

    const response = await axios.get(
      `https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`
    );

    setData(response.data.data);
    setPerPage(perPage);
    setLoading(false);
  };
  function toPages(pages) {
    const results = [];
    for (let i = 1; i <= pages; i++) {
      results.push(i);
    }
    return results;
  }
  function getNumberOfPages(rowCount, rowsPerPage) {
    // console.log("rowCount = " + rowCount);
    // console.log("rowsPerPage = " + rowsPerPage);
    // console.log("getNumberOfPages = " + Math.ceil(rowCount / rowsPerPage));
    return Math.ceil(rowCount / rowsPerPage);
  }
  const handleSort = async (column, sortDirection) => {
    setActivePage(1);
    setSearchParam({
      "srchTitle": '',
      "srchStatus": '',
      "srchCategory": '',
    });

    setDataSort({
      order: sortDirection,
      order_by: column.sortField
    })
    /*  setLoading(false); */

  };
  /*  PAGINATION FUNCTIONS  */
  const handlePageChange = async (page) => {
    console.log(page);
    setActivePage(page)
  };
  const changePageNumber = useMemo(() => {
    return fetchEventData();
  }, [activePage, searchParam, dataSort]);
  const BootyPagination = ({
    rowsPerPage,
    rowCount,
    onChangePage,
    onChangeRowsPerPage, // available but not used here
    currentPage,
  }) => {

   
    const handleBackButtonClick = async () => {
      if ((currentPage - 1) % perPage === 0) {
        setMaxPageLimit(maxPageLimit - perPage);
        setMinPageLimit(minPageLimit - perPage);
      }
      onChangePage(currentPage - 1);
    };

    const handleNextButtonClick = async () => {
      if (currentPage + 1 > maxPageLimit) {
        setMaxPageLimit(maxPageLimit + perPage);
        setMinPageLimit(minPageLimit + perPage);
      }

      onChangePage(currentPage + 1);
    };

    const handlePageNumber = (e) => {
      onChangePage(Number(e.target.value));
    };

    let paginationText = "Page:  " + currentPage + ", Showing ";

    paginationText =
      paginationText +
      ((currentPage - 1) * perPage + 1) +
      " to " +
      perPage * currentPage;
    paginationText = paginationText + " of " + rowCount + " entries;";


    const pages = getNumberOfPages(rowCount, rowsPerPage);

    const pageItems = toPages(pages);
    const nextDisabled = currentPage === pageItems.length;
    const previosDisabled = currentPage === 1;

    const pageNumbers = pageItems.map((page) => {
      if (page <= maxPageLimit && page > minPageLimit) {
        return (
          <li
            key={page}
            id={page}
            className={`page-item ${currentPage === page ? "active" : ""}`}
          >
            <button
              className={`page-link ${currentPage === page ? "active" : ""}`}
              onClick={handlePageNumber}
              value={page}
            >
              {page}
            </button>
          </li>
        );
      } else {
        return null;
      }
    });
    let pageIncrementEllipses = null;
    if (pageItems.length > maxPageLimit) {
      pageIncrementEllipses = (
        <li className="page-item" onClick={handleNextButtonClick}>
          &hellip;
        </li>
      );
    }
    let pageDecremenEllipses = null;
    if (minPageLimit >= 1) {
      pageDecremenEllipses = (
        <li className="page-item" onClick={handleBackButtonClick}>
          &hellip;
        </li>
      );
    }
    return (
      <div className="card-footer d-flex align-items-center">
        <p className="m-0 text-muted">{paginationText}</p>

        <ul className="pagination m-0 ms-auto">
          <li className="page-item">
            <button
              className="page-link"
              onClick={handleBackButtonClick}
              disabled={previosDisabled}
              aria-disabled={previosDisabled}
              aria-label="previous page"
              pageRangeDisplayed={10}
            >
              «
            </button>
          </li>
          {pageDecremenEllipses}
          {pageNumbers}
          {pageIncrementEllipses}
          <li className="page-item">
            <button
              className="page-link"
              onClick={handleNextButtonClick}
              disabled={nextDisabled}
              aria-disabled={nextDisabled}
              aria-label="next page"
            >
              »
            </button>
          </li>
        </ul>
      </div>
    );
  };

  
  /* END DATATABLE */
  const CustomLoader = () => (
    <div style={{ padding: "24px" }}>
      <DivLoader color="primary" />
    </div>
  );
  return (
    <>
      <Loading
        loading={fullLoading}
        background="rgba(0, 0, 0, 0.5)"
        loaderColor="#fff"
      />
      <EventHeader />
      <div className="page-body">
        <div className="container-xl">
          <div className="row row-cards">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Listing</h3>
                </div>
                <div className="card-body border-bottom py-3">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group mb-3 ">
                        <label className="form-label required">Event Name</label>
                        <div>
                          <input
                            type="text"
                            name="srchEventName"
                            id="srchEventName"
                            className='form-control'
                            aria-describedby="emailHelp"
                            placeholder="Enter Event name"
                            onChange={(e) => setSrchEventName(e.target.value)}
                            value={srchEventName}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className="mb-3">
                        <label className="form-label required">
                          Category Name
                        </label>
                        

                          {
                              catLisiting ?
                              (
                                  <>
                                      <select
                                          className="form-select form-control"
                                          name='srchCatName'
                                          id='srchCatName'
                                          placeholder="Select category"
                                          onChange={(e) => setSrchCatName(e.target.value)}
                                          value={srchCatName}
                                      >
                                          <option value="">Select</option>
                                          {
                                              catLisiting && catLisiting.map((element, key) => {
                                                  return (
                                                      <>
                                                          <option key={key} value={element.id}>{element.name}</option>
                                                      </>
                                                  )
                                              }
                                              )
                                          }
                                      </select>
                                  </>
                              )
                              : (
                                  <DivSpinner size="sm" color="primary" />
                              )
                          }
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="mb-3">
                        <label className="form-label required">
                          Status
                        </label>

                        <select
                          className="form-select form-control"
                          name="srchStatus"
                          id="srchStatus"
                          placeholder="Select status"
                          onChange={(e) => setSrchStatus(e.target.value)}
                          value={srchStatus}
                        >
                          <option value="">Select</option>
                          <option value="1">Active</option>
                          <option value="0">Deactive</option>

                        </select>


                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group mb-3 ">
                        <label className="form-label">&nbsp;</label>
                        <div>
                          <button
                            type="button"
                            name="btnSearch"
                            id="btnSearch"
                            className="btn btn-blue d-none d-sm-inline-block ml-3"
                            onClick={searchEvent}
                          ><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                              <circle cx="10" cy="10" r="7"></circle>
                              <line x1="21" y1="21" x2="15" y2="15"></line>
                            </svg>Search</button>&nbsp;
                          <button
                            type="button"
                            name="btnSearchReset"
                            id="btnSearchReset"
                            className="btn btn-outline-primary "
                            onClick={clearSearch}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                              <line x1="18" y1="6" x2="6" y2="18"></line>
                              <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>Clear</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">

                  <DataTable
                    columns={columns}
                    data={data}
                    progressPending={loading}
                    progressComponent={<CustomLoader />}
                    pagination="true"
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationPerPage={perPage}
                    paginationComponent={BootyPagination}
                    /*  sortServer */
                    onSort={handleSort}
                  />

                </div>
                <div className="card-footer d-flex align-items-center">
                  <p className="m-0 text-muted">
                    Showing {/* <span>1</span> to <span>8</span> of{" "} */}
                    <span>{totalRecords}</span> entries
                  </p>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
				(showModel.show == 'show' && <AddEvent  closeEventForm={closeForm}  />)
			}

      {
				(editModel.show == 'edit' && <EditEvent  closeEditEventForm={closeEditForm}  eventID={eventID} />)
			}
    </>
  )
}

export default EventIndex