import { React, useState, useEffect, useContext } from "react";
import * as CONST from "../../../component/config/Constant";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../../../component/context/AuthContext";
import { Link } from "react-router-dom";

function Technologies() {
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [listLimit, setListLimit] = useState(50);
  const [data, setData] = useState([]);

  /* START DATATABLE */
  const [totalRecords, settotalRecords] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [maxPageLimit, setMaxPageLimit] = useState(10);
  const [minPageLimit, setMinPageLimit] = useState(0);

  //Search
  const [searchTechnologyStatus, setSearchTechnologyStatus] = useState("");
  const [searchTechnologyName, setSearchTechnology] = useState("");
  const [dataSort, setDataSort] = useState({
    order: "desc",
    order_by: "id",
  });

  const [searchParam, setSearchParam] = useState({
    srchStatus: searchTechnologyStatus,
    srchTechnology: searchTechnologyName
  });
  const pageNumberLimit = { perPage };

  let isToken = "";
  if (authUserData) {
    if (authUserData[0]) {
      isToken = authUserData[0].token ? authUserData[0].token : "";
    }
  }

  useEffect(() => {
    fetchTechnologyData(searchParam, dataSort, activePage, perPage);
  }, [searchParam, dataSort, activePage, listLimit]);

  //fetch all technology details
  async function fetchTechnologyData(
    paramSearch,
    paramSort,
    paramPage,
    paramLimit
  ) {
    setLoading(true);
    let searchParams = JSON.stringify({
      limit: paramLimit,
      search_input: paramSearch,
      page_number: paramPage,
      sort: paramSort,
    });
    await axios
      .post(
        CONST.GET_TECH_LIST,
        {
          limit: paramLimit,
          search_input: paramSearch,
          page_number: paramPage,
          sort: paramSort,
        },
        // {
        //   headers: {
        //     Authorization: `Bearer ${isToken}`,
        //   },
        // }
      )
      .then((response) => {
        const status = response.data.status;
        if (status === "success") {
          setData(response.data.data);
          setTotalRows(response.data.count);
          settotalRecords(response.data.count);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => { });
    setLoading(false);
  }

  return (
    <>
      <div className="page-body">
        <div className="container-xl">
          <div className="col-12">
            <div className="section-title text-center mb-60 mt-60">
              <h3 className="mb-15">Technologies</h3>
            </div>
          </div>
          <div class="row row-cols-1 row-cols-md-3 g-4">
            {data.map((technologies, index) => {
              return (
                <>
                  <div class="col">
                    <div class="card">
                      <div class="empty">
                        <div class="pb-3">
                          <img src={CONST.HOST + technologies.image_path} height="120" alt={technologies.name} />
                        </div>
                        <p class="empty-title">{technologies.name}</p>

                        <div class="empty-action">
                          <Link to={"/tech-details/" + technologies.id + "/" + technologies.name} class="btn btn-primary">

                            View
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-md-6 col-xl-3">
                  <Link
                    to={"/tech-details/" + technologies.id+"/"+technologies.name}
                    className="card card-link">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-auto">
                          <img
                            src={CONST.HOST + technologies.image_path}
                            alt={technologies.name}/>
                        </div>
                        <div className="col">
                          <div className="font-weight-medium">
                            <strong>{technologies.name}</strong>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div> */}
                </>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default Technologies;