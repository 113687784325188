import { React, useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import Form from "../../component/utilities/Forms";
import axios from "axios";
import { toast } from "react-toastify";
import Loading from "react-fullscreen-loading";
import { AuthContext } from "../../component/context/AuthContext";
import * as CONST from "../../component/config/Constant";
import { useEffect } from "react";
export default function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [remember, setRemember] = useState(false);
  const [validate, setValidate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dptId, setDptId] = useState(false);
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  let permissions = '';

  const validateLogin = async () => {
    let isValid = true;
    let validator = Form.validator({
      email: {
        value: email,
        isRequired: true,
        isEmail: true,
        label: "email",
      },
      password: {
        value: password,
        isRequired: true,
        minLength: 6,
        label: "password",
      },
    });
    if (validator !== null) {
      setValidate({
        validate: validator.errors,
      });
      isValid = false;
    } else {
      isValid = true;
    }
    return isValid;
  };



  const authenticate = async (e) => {
    e.preventDefault();
    const validate = await validateLogin();
    console.log(validate);
    if (validate) {
      setLoading(true);
      await axios
        .post(CONST.LOGIN, {
          email: email,
          password: password,
        })
        .then((response) => {
          //console.log(response);
          if (response) {
            setLoading(false);
            const status = response.data.status;
            if (status === 'error') {
              toast.error(response.data.message);
            } else {
              //console.log(response.data.data);
              const access_token = response.data.data.access_token
                ? response.data.data.access_token
                : "";
              if (access_token) {
                localStorage.setItem("eToken", access_token);
                const first_name = response.data.data.first_name
                  ? response.data.data.first_name
                  : "";
                const last_name = response.data.data.last_name
                  ? response.data.data.last_name
                  : "";
                const role_id = response.data.data.role_id
                  ? response.data.data.role_id
                  : "";
                const email = response.data.data.email
                  ? response.data.data.email
                  : "";
                const profile_photo_path = response.data.data.profile_photo_path
                  ? response.data.data.profile_photo_path
                  : "";
                const status = response.data.data.status
                  ? response.data.data.status
                  : "";
                const user_id = response.data.data.id
                  ? response.data.data.id
                  : "";
                const department_id = response.data.data.department_id
                  ? response.data.data.department_id
                  : "";
                const designation_id = response.data.data.designation_id
                  ? response.data.data.designation_id
                  : "";  
                const department_name = response.data.data.department_name
                  ? response.data.data.department_name
                  : "";
                const is_head = response.data.data.is_head
                  ? response.data.data.is_head
                  : "";
                const is_admin = response.data.data.is_admin
                  ? response.data.data.is_admin
                  : "";
                const hr_head = response.data.data.hr_head
                  ? response.data.data.hr_head
                  : "";
                let permissions = response.data.data.permissionsArr[0].permissions;
                if (permissions) {
                  localStorage.setItem("ePermissions", btoa(permissions));
                }

                const UserData = [
                  {
                    first_name: first_name,
                    last_name: last_name,
                    email: email,
                    status: status,
                    token: access_token,
                    role_id: role_id,
                    profile_photo_path: profile_photo_path,
                    user_id: user_id,
                    department_id: department_id,
                    designation_id:designation_id,
                    department_name: department_name,
                    is_head: is_head,
                    is_admin: is_admin,
                    hr_head: hr_head,
                    permissions: permissions

                  },
                ];
                setAuthUserData(UserData);
                toast.success("Login Successfully.. Please wait");
                navigate("/admin/dashboard");
              }

            }
          }
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          toast.error("Something went wrong. Please try again.");
        });
    }
  };

  if (authUserData) {
    let isToken = authUserData[0].token ? authUserData[0].token : "";
    if (isToken) {
      navigate("/admin/dashboard");
    }
  }
  return (
    <>
      <Loading
        loading={loading}
        background="rgba(0, 0, 0, 0.5)"
        loaderColor="#fff"
      />
      <section className="page page-center login_forgot background_white">
        <img src="/eportal/assets/img/hero/hero-1/shape-1.svg" alt="" className="shape shape-1"></img>
        <div className="container container-normal py-4">
          <div className="row align-items-center g-4 d-flex justify-content-center min_height">


            <div className="col-lg-5">

              {/* <div className="text-center mb-4">
                  <a href="." className="navbar-brand navbar-brand-autodark">
                    <img src="assets/img/logo.svg" height="36" alt="" />
                  </a>
                </div> */}

              <div className="">
                <h3 className="h3 mb-4">
                  Login to your account
                </h3>
                <form
                  className="auth-form"
                  method="POST"
                  onSubmit={authenticate}
                  autoComplete={"off"}
                >
                  <div className="mb-3">
                    <label className="form-label"> Email address </label>
                    <input
                      type="email"
                      className={`form-control ${validate.validate && validate.validate.email
                        ? "is-invalid "
                        : ""
                        }`}
                      id="email"
                      name="email"
                      value={email}
                      placeholder="your@email.com"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <div
                      className={`invalid-feedback text-start mb-2 ${validate.validate && validate.validate.email
                        ? "d-block"
                        : "d-none"
                        }`}
                    >
                      {validate.validate && validate.validate.email
                        ? validate.validate.email[0]
                        : ""}
                    </div>
                  </div>
                  <div className="mb-2">
                    <label className="form-label">
                      Password
                    </label>
                    <div className="input-group input-group-flat">
                      <input
                        type="password"
                        className={`form-control ${validate.validate && validate.validate.password
                          ? "is-invalid "
                          : ""
                          }`}
                        name="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Your password"
                      />
                      <div
                        className={`invalid-feedback text-start mb-2 ${validate.validate && validate.validate.password
                          ? "d-block"
                          : "d-none"
                          }`}
                      >
                        {validate.validate && validate.validate.password
                          ? validate.validate.password[0]
                          : ""}
                      </div>
                    </div>
                  </div>
                  <div className="mb-2 d-flex justify-content-between">
                    <label className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="remember"
                        checked={remember}
                        onChange={(e) =>
                          setRemember(e.currentTarget.checked)
                        }
                      />
                      <span className="form-check-label">
                        Remember me on this device
                      </span>
                    </label>
                    <span className="form-label-description mt-2">
                      <Link to="/forget-password">
                        I forgot password..
                      </Link>
                    </span>
                  </div>

                  <div className="form-footer">
                    <button type="submit" className="btn btn-primary w-100">
                      Sign in
                    </button>
                  </div>
                </form>
              </div>


            </div>

            <div className="col-lg-6 offset-lg-1 ">
              <img className="img-fluid"
                src={
                  process.env.PUBLIC_URL +
                  "/assets/img/login/login.png"
                }
                alt="banner-image"
              />
            </div>
            {/* <div className="col-lg d-none d-lg-block">
              <img
                src="assets/img/illustrations/undraw_quitting_time_dm8t.svg"
                height="300"
                className="d-block mx-auto"
                alt=""
              />
            </div> */}
          </div>
        </div>

      </section>
    </>
  );
}

export { Login };
