
import React, { useContext, useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import { AuthContext } from '../../../component/context/AuthContext';
import axios from 'axios';
import * as  CONST from "../../../component/config/Constant";
import { toast } from "react-toastify";
import DivSpinner from '../../../component/utilities/Spinner';
import { Link } from 'react-router-dom';
import './employee-details.css';
import moment from 'moment';
import { differenceInCalendarMonths } from 'date-fns';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

function ShowDetails({ closeEmpDetails, editEmpId, firstName, lastName }) {
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  const [empFirstName, setFirstName] = useState(firstName);
  const [empLastName, setLastName] = useState(lastName);
  const [empMiddleName, setEmpMiddleName] = useState();
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [loading, setloaing] = useState(false);
  const [dptListing, setDptListing] = useState("");
  const [desigListing, setDesigListing] = useState("");
  const [status, setStatus] = useState("");
  const [department, setDepartment] = useState("");
  const [designation, setDesignation] = useState("");
  const [profile, setProfile] = useState([]);
  const [profileInput, setProfileInput] = useState([]);
  const [profilePath, setProfilePath] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(new Date());
  const [userPersonaInfo, setUserPersonaInfo] = useState("");
  const [userAadharNumber, setUserAadharNumber] = useState("");
  const [userPanNumber, setUserPanNumber] = useState("");
  const [userUanNumber, setUserUanNumber] = useState("");
  const [userEsicNumber, setUserEsicNumber] = useState("");

  const [show, setShow] = useState('show');

  let isToken = '';
  let userId = '';
  let is_head = 0;
  let is_admin = 0;
  let role_id = '';
  let department_id = '';
  let department_name = '';
  let todayDate = moment().format('DD-MM-YYYY');

  if (authUserData) {
    if (authUserData[0]) {
      isToken = authUserData[0].token ? authUserData[0].token : "";
      userId = authUserData[0].user_id ? authUserData[0].user_id : 0;
      department_id = authUserData[0].department_id ? authUserData[0].department_id : "";
      department_name = authUserData[0].department ? authUserData[0].department.name : "";
      is_head = authUserData[0].is_head ? authUserData[0].is_head : 0;
      is_admin = authUserData[0].is_admin ? authUserData[0].is_admin : 0;
      role_id = authUserData[0].role_id ? authUserData[0].role_id : "";
    }
  }
  async function fetchEmpPersonalInformation() {
    setloaing(true);
    await axios
      .post(CONST.GET_EMP_BY_ID,
        {
          'id': userId,
          'user_id': editEmpId
        },
        {
          headers: {
            'Authorization': `Bearer ${isToken}`
          },

        })
      .then((response) => {
        const status = response.data.status;
        if (status === 'success') {
          console.log(response.data.data);
          setUserPersonaInfo(response.data.data);
          let usertData = response.data.data ? response.data.data : [];
          setFirstName(usertData['first_name'] ? usertData['first_name'] : '');
          setLastName(usertData['last_name'] ? usertData['last_name'] : '');
          setEmpMiddleName(usertData['middle_name'] ? usertData['middle_name'] : '');
          setEmail(usertData['email'] ? usertData['email'] : '');
          setPhoneNumber(usertData['phone_number'] ? usertData['phone_number'] : '');
          setAddress(usertData['user_address'] ? usertData['user_address'] : '');
          setDateOfBirth(usertData['date_of_birth'] ? usertData['date_of_birth'] : '');
          setStatus(usertData['status'] ? usertData['status'] : '');
          setDepartment(usertData['department_id'] ? usertData['department_id'] : '');
          setProfilePath(usertData['profile_photo_path'] ? usertData['profile_photo_path'] : '');
          setUserAadharNumber(usertData['aadhar_number'] ? usertData['aadhar_number'] : '');
          setUserPanNumber(usertData['pan_number'] ? usertData['pan_number'] : '');
          setUserUanNumber(usertData['uan_number'] ? usertData['uan_number'] : '');
          setUserEsicNumber(usertData['esic_number'] ? usertData['esic_number'] : '');
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => { //alert('error')
        console.log(error);
      });
    setloaing(false);
  }
  useEffect(() => {
    fetchEmpPersonalInformation();
    /* fetchDeptList();
    fetchDesigtList(); */
  }, []);

  function calculateExperience(startDate, endDate) {
    console.log("startDate >> " + startDate);
    console.log("endDate >> " + endDate);
    if (startDate && endDate) {
      const start = new Date(startDate);
      const end = endDate === 'present' ? new Date() : new Date(endDate);

      const totalMonths = differenceInCalendarMonths(end, start);
      const years = Math.floor(totalMonths / 12);
      const months = totalMonths % 12;

      const experience = years + months / 10; // Display one decimal place

      return experience;
    } else {
      return 0;
    }
  }

  /* function calculateExperience(startDate, endDate) {
    startDate = moment(startDate).format('DD-MM-YYYY');
    endDate = moment(endDate).format('DD-MM-YYYY');
    if (startDate && endDate) {
      function parseDate(dateString) {
        const [day, month, year] = dateString.split('-').map(Number);
        return new Date(year, month - 1, day); // Months are 0-based in JavaScript Date object
      }

      const startDateObj = parseDate(startDate);
      const endDateObj = parseDate(endDate);

      // Calculate the time difference in milliseconds between the two dates
      const timeDifference = endDateObj - startDateObj;

      // Convert the time difference to years, months, and days
      const millisecondsInYear = 1000 * 60 * 60 * 24 * 365.25; // Consider leap years
      const experienceInYears = timeDifference / millisecondsInYear;

      // Display only one decimal place without rounding up
      const roundedExperience = Math.floor(experienceInYears * 10) / 10;

      return roundedExperience ? parseFloat(roundedExperience) : 0;
    } else {
      return 0;
    }

  } */

  let totalExperience = 0;
  if ((userPersonaInfo['euser_has_experiences'])) {
    userPersonaInfo['euser_has_experiences'].map(exe => {
      if (exe.is_current_job == 1) {
        totalExperience = parseFloat(totalExperience) + calculateExperience(exe.start_date, 'present');
      } else {
        totalExperience = parseFloat(totalExperience) + calculateExperience(exe.start_date, exe.end_date);
      }

    })
    //totalExperience = calculateTotalExperience(currentCompanyStartDate, pastCompanyStartDate, pastCompanyEndDate);
  }
  function LinkWithTooltip({ id, children, href, tooltip }) {
    return (
      <OverlayTrigger
        overlay={<Tooltip id={id}>{tooltip}</Tooltip>}
        placement="top"
        delayShow={300}
        delayHide={150}
      >
        <a href={href} style={{ color: '#000' }}>{children}</a>
      </OverlayTrigger >
    );
  }
  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => closeEmpDetails('hide')}
      backdrop="static"
      keyboard={false}
      centered
      fullscreen={true}
    >
      <Modal.Header closeButton>
        {/*  <h6>Personal Information - {firstName} {lastName}</h6> */}
      </Modal.Header>
      <Modal.Body style={{ backgroundColor: 'rgb(241, 245, 249)' }}>
        <div className="row">
          {
            userPersonaInfo ? (
              <>
                <div className='pt-2 pb-2 col-lg-4 col-md-4 left-sidebar'>
                  <div className="card">
                    <div className='card-body border-bottom py-3'>
                      <div className='employee-img text-center'>
                        {
                          (userPersonaInfo['profile_photo_path'] && userPersonaInfo['profile_photo_path'])
                            ? (
                              <span className="text-center" >
                                <img src={CONST.HOST + userPersonaInfo['profile_photo_path']} alt='' width={100} className="" />
                              </span>
                            ) : (
                              <span className="avatar  rounded-circle ">
                                <span className=''>
                                  {(firstName && lastName)
                                    ? <> {firstName && firstName.substring(0, 1)}
                                      {lastName && lastName.substring(0, 1)}</>
                                    : ''
                                  }

                                </span>
                              </span>
                            )
                        }
                      </div>
                      <div className="">
                        <div className="pt-2 pb-2">
                          <h5 className="text-center">
                            {(firstName && lastName)
                              ? <>{firstName} {empMiddleName && empMiddleName} {lastName}</>
                              : ""
                            }
                          </h5>
                          <ul className="text-center pt-1">
                            <li className=' '>
                              {
                                userPersonaInfo['euser_has_sociallinks'] && (
                                  <>
                                    {
                                      userPersonaInfo['euser_has_sociallinks'].map((social, index) => {
                                        return (
                                          <>

                                            {

                                              (social.social_name == 'LinkedIn') && (<>
                                                <Link to={social.social_link} target='_blank'>
                                                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-linkedin color_blue" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"></path>
                                                    <path d="M8 11l0 5"></path>
                                                    <path d="M8 8l0 .01"></path>
                                                    <path d="M12 16l0 -5"></path>
                                                    <path d="M16 16v-3a2 2 0 0 0 -4 0"></path>
                                                  </svg>
                                                </Link>
                                              </>
                                              )
                                            }
                                            {

                                              (social.social_name == 'Skype') && (<>
                                                <Link to={social.social_link} target='_blank'>
                                                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-skype color_blue" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <path d="M12 3a9 9 0 0 1 8.603 11.65a4.5 4.5 0 0 1 -5.953 5.953a9 9 0 0 1 -11.253 -11.253a4.5 4.5 0 0 1 5.953 -5.954a8.987 8.987 0 0 1 2.65 -.396z"></path>
                                                    <path d="M8 14.5c.5 2 2.358 2.5 4 2.5c2.905 0 4 -1.187 4 -2.5c0 -1.503 -1.927 -2.5 -4 -2.5s-4 -1 -4 -2.5c0 -1.313 1.095 -2.5 4 -2.5c1.642 0 3.5 .5 4 2.5"></path>
                                                  </svg>
                                                </Link>
                                              </>
                                              )
                                            }
                                            {

                                              (social.social_name == 'Instagram') && (<>
                                                <Link to={social.social_link} target='_blank'>
                                                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-instagram color_blue" width="20" height="20" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <path d="M4 4m0 4a4 4 0 0 1 4 -4h8a4 4 0 0 1 4 4v8a4 4 0 0 1 -4 4h-8a4 4 0 0 1 -4 -4z"></path>
                                                    <path d="M12 12m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                                                    <path d="M16.5 7.5l0 .01"></path>
                                                  </svg>
                                                </Link>
                                              </>
                                              )
                                            }

                                            {

                                              (social.social_name == 'Facebook') && (<>
                                                <Link to={social.social_link} target='_blank'>
                                                  <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-brand-facebook-filled color_blue" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                    <path d="M18 2a1 1 0 0 1 .993 .883l.007 .117v4a1 1 0 0 1 -.883 .993l-.117 .007h-3v1h3a1 1 0 0 1 .991 1.131l-.02 .112l-1 4a1 1 0 0 1 -.858 .75l-.113 .007h-2v6a1 1 0 0 1 -.883 .993l-.117 .007h-4a1 1 0 0 1 -.993 -.883l-.007 -.117v-6h-2a1 1 0 0 1 -.993 -.883l-.007 -.117v-4a1 1 0 0 1 .883 -.993l.117 -.007h2v-1a6 6 0 0 1 5.775 -5.996l.225 -.004h3z" stroke-width="0" fill="currentColor"></path>
                                                  </svg>
                                                </Link>
                                              </>
                                              )
                                            }
                                          </>
                                        )
                                      }
                                      )
                                    }
                                  </>
                                )
                              }
                            </li>
                          </ul>
                          <hr />
                          <ul className='personal-info'>
                            {
                              (userPersonaInfo && userPersonaInfo['email']) && (
                                <>
                                  <li className=' '>
                                    <LinkWithTooltip tooltip="Office Email" href={`mailto:${userPersonaInfo['email']}`} id="OfficeEmail" target="_blank">


                                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-mail-filled color_blue" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M22 7.535v9.465a3 3 0 0 1 -2.824 2.995l-.176 .005h-14a3 3 0 0 1 -2.995 -2.824l-.005 -.176v-9.465l9.445 6.297l.116 .066a1 1 0 0 0 .878 0l.116 -.066l9.445 -6.297z" stroke-width="0" fill="currentColor"></path>
                                        <path d="M19 4c1.08 0 2.027 .57 2.555 1.427l-9.555 6.37l-9.555 -6.37a2.999 2.999 0 0 1 2.354 -1.42l.201 -.007h14z" stroke-width="0" fill="currentColor"></path>
                                      </svg>
                                      {userPersonaInfo['email'].toLowerCase()}
                                    </LinkWithTooltip>
                                  </li>
                                </>
                              )
                            }

                            {
                              (userPersonaInfo && userPersonaInfo['personal_email']) && (
                                <> <li className=' '>
                                  <LinkWithTooltip tooltip="Personal Email" href={`mailto:${userPersonaInfo['personal_email']}`} id="personal_email" target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-at color_blue" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                      <path d="M12 12m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"></path>
                                      <path d="M16 12v1.5a2.5 2.5 0 0 0 5 0v-1.5a9 9 0 1 0 -5.5 8.28"></path>
                                    </svg>
                                    {userPersonaInfo['personal_email'].toLowerCase()}
                                  </LinkWithTooltip>
                                </li>
                                </>
                              )

                            }


                            <li className=' '>
                              <LinkWithTooltip tooltip="Phone Number" href={`tel:${userPersonaInfo['phone_number']}`} id="phone_number" target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-phone-filled color_blue" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                  <path d="M9 3a1 1 0 0 1 .877 .519l.051 .11l2 5a1 1 0 0 1 -.313 1.16l-.1 .068l-1.674 1.004l.063 .103a10 10 0 0 0 3.132 3.132l.102 .062l1.005 -1.672a1 1 0 0 1 1.113 -.453l.115 .039l5 2a1 1 0 0 1 .622 .807l.007 .121v4c0 1.657 -1.343 3 -3.06 2.998c-8.579 -.521 -15.418 -7.36 -15.94 -15.998a3 3 0 0 1 2.824 -2.995l.176 -.005h4z" stroke-width="0" fill="currentColor"></path>
                                </svg>
                                {
                                  (userPersonaInfo && userPersonaInfo['phone_number']) ? (
                                    <>
                                      {userPersonaInfo['phone_number']}
                                    </>
                                  ) : ""
                                }
                              </LinkWithTooltip>
                            </li>

                            <li className=' ' >

                              <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-building-skyscraper color_blue" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" alt="Department & Designation" title="Department & Designation" data-bs-toggle="Department & Designation" data-bs-placement="Department & Designation">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M3 21l18 0"></path>
                                <path d="M5 21v-14l8 -4v18"></path>
                                <path d="M19 21v-10l-6 -4"></path>
                                <path d="M9 9l0 .01"></path>
                                <path d="M9 12l0 .01"></path>
                                <path d="M9 15l0 .01"></path>
                                <path d="M9 18l0 .01"></path>
                              </svg>

                              {
                                (userPersonaInfo && userPersonaInfo['department']) &&
                                (
                                  <LinkWithTooltip tooltip="Department Name" href="#" id="department" target="_blank">
                                    {
                                      userPersonaInfo['department']['name']
                                    }
                                  </LinkWithTooltip>
                                )
                              }{
                                (userPersonaInfo && userPersonaInfo['designation']) &&
                                (
                                  <LinkWithTooltip tooltip="designation Name" href="#" id="designation" target="_blank">
                                    {
                                      " / " + userPersonaInfo['designation']['name']
                                    }
                                  </LinkWithTooltip>
                                )
                              }
                            </li>
                            <li className=' '>
                              {
                                (userPersonaInfo && userPersonaInfo['date_of_birth']) &&
                                (
                                  <>

                                    <LinkWithTooltip tooltip="Date of Birth" href="#" id="date_of_birth"  >
                                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-cake color_blue" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M3 20h18v-8a3 3 0 0 0 -3 -3h-12a3 3 0 0 0 -3 3v8z"></path>
                                        <path d="M3 14.803c.312 .135 .654 .204 1 .197a2.4 2.4 0 0 0 2 -1a2.4 2.4 0 0 1 2 -1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1a2.4 2.4 0 0 0 2 -1a2.4 2.4 0 0 1 2 -1a2.4 2.4 0 0 1 2 1a2.4 2.4 0 0 0 2 1c.35 .007 .692 -.062 1 -.197"></path>
                                        <path d="M12 4l1.465 1.638a2 2 0 1 1 -3.015 .099l1.55 -1.737z"></path>
                                      </svg>
                                      {
                                        (userPersonaInfo && userPersonaInfo['date_of_birth']) ? moment(userPersonaInfo['date_of_birth']).format('DD-MM-YYYY') : ""
                                      }
                                    </LinkWithTooltip>

                                  </>
                                )
                              }

                              {
                                (userPersonaInfo && userPersonaInfo['date_of_joining']) && (
                                  <>
                                    <LinkWithTooltip tooltip="Date of Joining" href="#" id="date_of_joining"  >
                                      &nbsp;&nbsp;/<svg xmlns="http://www.w3.org/2000/svg" class=" ml-2 icon icon-tabler icon-tabler-calendar-plus color_blue" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M12.5 21h-6.5a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v5"></path>
                                        <path d="M16 3v4"></path>
                                        <path d="M8 3v4"></path>
                                        <path d="M4 11h16"></path>
                                        <path d="M16 19h6"></path>
                                        <path d="M19 16v6"></path>
                                      </svg> {
                                        (userPersonaInfo && userPersonaInfo['date_of_joining']) ? moment(userPersonaInfo['date_of_joining']).format('DD-MM-YYYY') : ""
                                      }
                                    </LinkWithTooltip>
                                  </>
                                )
                              }


                            </li>
                            {
                              (userPersonaInfo && userPersonaInfo['user_address']) &&
                              (
                                <>
                                  <li className=' '>
                                    <LinkWithTooltip tooltip="Current Address" href="#" id="user_address"  >
                                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-home-link color_blue" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M20.085 11.085l-8.085 -8.085l-9 9h2v7a2 2 0 0 0 2 2h4.5"></path>
                                        <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 1.807 1.143"></path>
                                        <path d="M21 21m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                                        <path d="M21 16m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                                        <path d="M16 19m-1 0a1 1 0 1 0 2 0a1 1 0 1 0 -2 0"></path>
                                        <path d="M21 16l-5 3l5 2"></path>
                                      </svg>
                                      {
                                        (userPersonaInfo && userPersonaInfo['user_address']) ? userPersonaInfo['user_address'] : ""
                                      }
                                    </LinkWithTooltip>
                                  </li>
                                </>
                              )
                            }

                            {
                              (userPersonaInfo && userPersonaInfo['user_aadhar_address']) && (
                                <>
                                  <li className=' '>
                                    <LinkWithTooltip tooltip="Aadhar Address" href="#" id="user_aadhar_address"  >
                                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-address-book color_blue" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <path d="M20 6v12a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2z"></path>
                                        <path d="M10 16h6"></path>
                                        <path d="M13 11m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                                        <path d="M4 8h3"></path>
                                        <path d="M4 12h3"></path>
                                        <path d="M4 16h3"></path>
                                      </svg>
                                      {
                                        (userPersonaInfo && userPersonaInfo['user_aadhar_address']) ? userPersonaInfo['user_aadhar_address'] : ""
                                      }
                                    </LinkWithTooltip>
                                  </li>
                                </>
                              )
                            }
                          </ul>
                          <ul className='socialView'>
                            {
                              (userAadharNumber && (
                                <li className=' ' >
                                  <LinkWithTooltip tooltip="Aadhar Card" href="#" id="tooltip-1">
                                    <img src={'/assets/img/document/adhaar-card-icon.svg'} width={20} />&nbsp;
                                    {userAadharNumber}
                                  </LinkWithTooltip>{' '}
                                </li>

                              ))
                            }
                            {
                              (userPanNumber && (
                                <li className=' '>
                                  <LinkWithTooltip tooltip="Pan Card" href="#" id="tooltip-2">
                                    <img src={'/assets/img/document/pan-icon.svg'} width={20} />&nbsp;{userPanNumber}
                                  </LinkWithTooltip>
                                </li>
                              ))
                            }
                            {
                              (userUanNumber && (
                                <li className=' '>
                                  <LinkWithTooltip tooltip="UAN Card" href="#" id="tooltip-2"><img src={'/assets/img/document/uan-number.svg'} width={20} />&nbsp;{userUanNumber}</LinkWithTooltip></li>
                              ))
                            }

                            {
                              (userEsicNumber && (
                                <li className=' '>
                                  <LinkWithTooltip tooltip="ESIC Card" href="#" id="tooltip-2"><img src={'/assets/img/document/esic-number.svg'} width={20} />&nbsp;{userEsicNumber}</LinkWithTooltip></li>
                              ))
                            }

                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pt-2 pb-2 col-lg-8 col-md-8 right-sidebar">
                  <div className="card">
                    <div className='card-body border-bottom py-3'>
                      {/* Document Information  */}
                      <div className='pt-3'>
                        <h5><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-file-description color_blue mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M14 3v4a1 1 0 0 0 1 1h4"></path>
                          <path d="M17 21h-10a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v11a2 2 0 0 1 -2 2z"></path>
                          <path d="M9 17h6"></path>
                          <path d="M9 13h6"></path>
                        </svg>
                          Document Information </h5>
                        <hr />
                        <div className='row'>

                          {
                            userPersonaInfo['euser_has_documents'] && (
                              <>
                                {
                                  userPersonaInfo['euser_has_documents'].map((document, index) => {
                                    return (
                                      <>
                                        <div className='col-md-4 pb-2'>
                                          {
                                            (document && document.document_name)
                                              ?
                                              (
                                                <>
                                                  <span>
                                                    <Link to={CONST.HOST + document.document_path} target='_blank'>
                                                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-cloud-download mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M19 18a3.5 3.5 0 0 0 0 -7h-1a5 4.5 0 0 0 -11 -2a4.6 4.4 0 0 0 -2.1 8.4"></path>
                                                        <path d="M12 13l0 9"></path>
                                                        <path d="M9 19l3 3l3 -3"></path>
                                                      </svg>
                                                      <span>{document.document_name}</span>
                                                    </Link>
                                                  </span>

                                                </>
                                              ) : ""
                                          }
                                        </div>


                                      </>
                                    )
                                  })
                                }

                              </>
                            )
                          }
                          <hr />

                        </div>
                      </div>
                      {/* Experience Information  */}
                      <div className='pt-3'>
                        <h5><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-award pr-1 color_blue" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M12 9m-6 0a6 6 0 1 0 12 0a6 6 0 1 0 -12 0"></path>
                          <path d="M12 15l3.4 5.89l1.598 -3.233l3.598 .232l-3.4 -5.889"></path>
                          <path d="M6.802 12l-3.4 5.89l3.598 -.233l1.598 3.232l3.4 -5.889"></path>
                        </svg>Experience Information </h5>
                        <h6 className='pt-2 mt-2'> Total Experience: {parseFloat(totalExperience).toFixed(2)} <span> Year(s)</span></h6>
                        <hr />
                        <div className='row'>
                          <div>
                            <table className="table table-vcenter">
                              {
                                userPersonaInfo['euser_has_experiences'] && (
                                  <>
                                    <thead>
                                      <tr>
                                        <th>Company Name</th>
                                        <th>Position</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th>Experience</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        userPersonaInfo['euser_has_experiences'].map((experience, index) => {
                                          console.log(experience);
                                          let endDate = '';
                                          let startDate = '';
                                          if (experience.start_date) {
                                            startDate = moment(experience.start_date).format('DD-MM-YYYY');
                                          }
                                          if (experience.end_date) {
                                            endDate = moment(experience.end_date).format('DD-MM-YYYY');
                                          }


                                          return (
                                            <>

                                              <tr>
                                                <td className="w-1">
                                                  {
                                                    experience.company_name
                                                  }
                                                </td>
                                                <td className="w-1">
                                                  {
                                                    (experience && experience.position) ? experience.position : ""
                                                  }
                                                </td>
                                                <td className="w-1">
                                                  {
                                                    (experience && startDate) ? startDate : ""
                                                  }
                                                </td>
                                                <td className="w-1">
                                                  {
                                                    (experience && endDate) ? endDate : "Present"
                                                  }

                                                </td>
                                                <td>

                                                  {
                                                    (experience.is_current_job === '1')
                                                      ?
                                                      (
                                                        <>
                                                          <span> {calculateExperience(experience.start_date, 'present')}  </span> <span> Year(s)</span>
                                                        </>
                                                      )
                                                      : (
                                                        <>
                                                          <span> {calculateExperience(experience.start_date, experience.end_date)}  </span> <span> Year(s)</span>
                                                        </>
                                                      )
                                                  }

                                                </td>
                                              </tr>

                                            </>
                                          )
                                        })
                                      }
                                    </tbody>
                                  </>
                                )
                              }
                            </table>
                          </div>
                        </div>
                      </div>
                      {/* Education Information  */}
                      <div className='pt-3'>
                        <h5><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-books mr-1 color_blue" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M5 4m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z"></path>
                          <path d="M9 4m0 1a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1v14a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1z"></path>
                          <path d="M5 8h4"></path>
                          <path d="M9 16h4"></path>
                          <path d="M13.803 4.56l2.184 -.53c.562 -.135 1.133 .19 1.282 .732l3.695 13.418a1.02 1.02 0 0 1 -.634 1.219l-.133 .041l-2.184 .53c-.562 .135 -1.133 -.19 -1.282 -.732l-3.695 -13.418a1.02 1.02 0 0 1 .634 -1.219l.133 -.041z"></path>
                          <path d="M14 9l4 -1"></path>
                          <path d="M16 16l3.923 -.98"></path>
                        </svg>Education Information </h5>
                        <hr />
                        <div className='row'>
                          <div>
                            <table className="table table-vcenter">
                              {
                                userPersonaInfo['euser_has_educations'] && (
                                  <>
                                    <thead>
                                      <tr>
                                        <th>Name</th>
                                        <th>Degree</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        userPersonaInfo['euser_has_educations'].map((education, index) => {
                                          return (
                                            <>

                                              <tr>
                                                <td className="w-1">
                                                  {
                                                    education.institute_name
                                                  }
                                                </td>
                                                <td className="w-1">
                                                  {
                                                    (education && education.degree) ? education.degree : ""
                                                  }
                                                </td>
                                                <td className="w-1">
                                                  {
                                                    (education && education.start_date) ? education.start_date : ""
                                                  }
                                                </td>
                                                <td className="w-1">
                                                  {
                                                    (education && education.end_date) ? education.end_date : "-"
                                                  }
                                                </td>
                                              </tr>

                                            </>
                                          )
                                        })
                                      }
                                    </tbody>
                                  </>
                                )
                              }
                            </table>
                          </div>
                        </div>
                      </div>

                      {/* Guardian Information  */}
                      <div className='pt-3'>
                        <h5><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-ghost mr-1 color_blue" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                          <path d="M5 11a7 7 0 0 1 14 0v7a1.78 1.78 0 0 1 -3.1 1.4a1.65 1.65 0 0 0 -2.6 0a1.65 1.65 0 0 1 -2.6 0a1.65 1.65 0 0 0 -2.6 0a1.78 1.78 0 0 1 -3.1 -1.4v-7"></path>
                          <path d="M10 10l.01 0"></path>
                          <path d="M14 10l.01 0"></path>
                          <path d="M10 14a3.5 3.5 0 0 0 4 0"></path>
                        </svg>
                          Guardian Information </h5>
                        <hr />
                        <div className='row'>
                          <div>
                            <table className="table table-vcenter">
                              {
                                userPersonaInfo['euser_has_guardians'] && (
                                  <>
                                    <thead>
                                      <tr>
                                        <th>Relation</th>
                                        <th>Name</th>
                                        <th>Email</th>
                                        <th>Contact</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        userPersonaInfo['euser_has_guardians'].map((guardians, index) => {
                                          return (
                                            <>

                                              <tr>
                                                <td className="w-1">
                                                  {
                                                    guardians.relation_name
                                                  }
                                                </td>
                                                <td className="w-1">
                                                  {
                                                    (guardians && guardians.guardian_name) ? guardians.guardian_name : ""
                                                  }
                                                </td>
                                                <td className="w-1">
                                                  {
                                                    (guardians && guardians.guardian_email) ? guardians.guardian_email : ""
                                                  }
                                                </td>
                                                <td className="w-1">
                                                  {
                                                    (guardians && guardians.guardian_phone) ? guardians.guardian_phone : ""
                                                  }
                                                </td>
                                              </tr>

                                            </>
                                          )
                                        })
                                      }
                                    </tbody>
                                  </>
                                )
                              }
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )
              :
              (
                <>
                  <div className='p-3 text-center'>
                    <DivSpinner size="sm" color="primary" />
                  </div>
                </>
              )
          }
        </div>
      </Modal.Body>
    </Modal >
  )
}
export default ShowDetails