import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import Form from '../../../component/utilities/Forms';
import * as CONST from "../../../component/config/Constant";
import { AuthContext } from '../../../component/context/AuthContext';
import { toast } from "react-toastify";
function EditTestimonials({ childToParent, editTestiId, logged_user_id, added_by_id, editEmpName }) {
	const [authUserData, setAuthUserData] = useContext(AuthContext);
	const [textTestimonials, setTextTestimonials] = useState("");
	const [validate, setValidate] = useState({});
	const [show, setShow] = useState('show');
	const [loading, setLoading] = useState(false);
	const handleShow = () => setShow(true);
	const [testiId, setTestiId] = useState(editTestiId);

	let user_id = '';
	let isToken = '';
	if (authUserData) {
		if (authUserData[0]) {
			isToken = authUserData[0].token ? authUserData[0].token : "";
			user_id = authUserData[0].user_id ? authUserData[0].user_id : "";
		}
	}
	useEffect(() => {
		fetchTestimonials();
	}, []);

	const fetchTestimonials = async () => {
		await axios
			.post(CONST.ADMIN_EDIT_TESTIMONIALS, {
				testimonial_id: editTestiId,
				id: added_by_id
			}, {
				headers: {
					'Authorization': `Basic ${isToken}`
				}
			})
			.then((response) => {
				const status = response.data.status;
				if (status === 'success') {
					setLoading(true);
					console.log(response.data.data.description);
					/* alert(response.data.data.description); */
					setTextTestimonials(response.data.data.description);
					setLoading(false);

				}
			})
			.catch((error) => {
				toast.error(error);
			});
	}

	const validTestimonials = async () => {
		let isValid = true;

		let validator = Form.validator({
			textTestimonials: {
				value: textTestimonials,
				isRequired: true,
				isEmail: false,
				label: "testimonials",
			},
		});

		if (validator !== null) {
			setValidate({
				validate: validator.errors,
			});
			isValid = false;
		} else {
			setValidate({
				validate: "",
			});
			isValid = true;
		}
		return isValid;
	};

	const handleForm = async (e) => {
		e.preventDefault();
		const validate = await validTestimonials();
		if (validate) {
			await axios
				.put(CONST.ADMIN_TESTIMONIALS_UPDATE, {
					description: textTestimonials,
					user_id: logged_user_id,
					testimonial_id: editTestiId,
					added_by: added_by_id,
				},
					{
						headers: {
							'Authorization': `Basic ${isToken}`
						}
					})
				.then((response) => {
					const status = response.data.status;
					setLoading(false);
					if (status === 'error') {
						const errorMsg = response.data.message;
						toast.error(errorMsg);

					} else {
						const errorMsg = response.data.message;
						toast.success(errorMsg);

					}
				})
				.catch((error) => {
					setLoading(false);
					console.log(error);
					toast.error("Something went wrong. Please try again.");
				});
		}

	}
	const handleClose = () => {
		alert('her');
		//props.handleClose(true);
	};


	return (
		<>

			<Modal
				show={show}
				onHide={() => childToParent('hide')}
				backdrop="static"
				keyboard={false}
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title>Edit Testimonials</Modal.Title>
				</Modal.Header>
				<Modal.Header as="em">Employee Name: {editEmpName}</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleForm}>
						<div className="row">
							<div className="col-md-12">
								<div className="form-group mb-3 ">
									<label className="form-label required">

									</label>
									<div>
										{
											textTestimonials &&
											(
												<>
													<textarea
														name="textTestimonials"
														id="textTestimonials"
														className={`form-control ${validate.validate && validate.validate.textTestimonials
															? "is-invalid "
															: ""
															}`}
														aria-describedby="emailHelp"
														onChange={(e) => setTextTestimonials(e.target.value)}
														style={{ height: '100px !important;' }}
														value={textTestimonials ? textTestimonials : ""}
													>{textTestimonials}</textarea>
												</>
											)
										}

									</div>
									<div
										className={`invalid-feedback text-start ${validate.validate && validate.validate.textTestimonials
											? "d-block"
											: "d-none"
											}`}
									>
										{validate.validate && validate.validate.textTestimonials
											? validate.validate.textTestimonials[0]
											: ""}{" "}
									</div>{" "}
								</div>
							</div>
							<div className="col-md-12 text-right">
								<div className="form-footer">
									<button
										type="submit"
										className="mr-3 btn btn-primary"
									>
										Update
									</button>
									&nbsp;&nbsp;
									<button
										type="reset"
										className="btn btn-outline-primary"
									>
										Reset
									</button>

								</div>
							</div>
						</div>
					</form>
				</Modal.Body>

			</Modal >
		</>
	)
}

export default EditTestimonials