import { React, useState, useContext, useEffect } from 'react'
import Loading from "react-fullscreen-loading";
import HeaderSection from "../banner/HeaderSection";
import * as  CONST from "../../../component/config/Constant"
import "../../../assets/css/form.css";
import axios from 'axios';
import { AuthContext } from '../../../component/context/AuthContext';
import { toast } from "react-toastify";

import DatePicker from 'react-datetime';
//import DatePicker from "react-datepicker";  
import moment from 'moment';
import 'react-datetime/css/react-datetime.css';
import deleteImg from "../../../assets/img/icon/delete.svg";

import EditableRow from '../../../component/experience/EditableRow';
import ReadOnlyRow from '../../../component/experience/ReadOnlyRow';
import Swal from 'sweetalert2';

function Experience() {
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  const [editExperienceId, setEditExperienceId] = useState(null);
  const [loading, setloading] = useState(false);
  const [inputFields, setInputFields] = useState([{
    company_name: '',
    position: '',
    start_date: '',
    end_date: '',
    is_current_job: '0',
    validate: {}
  }]);

  const [experienceDetails, setExperienceDetails] = useState([{
    company_name: '',
    position: '',
    start_date: '',
    end_date: '',
    is_current_job: '0',

  }]);

  const [editFormData, setEditFormData] = useState([{
    company_name: '',
    position: '',
    start_date: '',
    end_date: '',
    is_current_job: '0',

  }]);

  let isToken = '';
  if (authUserData) {
    if (authUserData[0]) {
      isToken = authUserData[0].token ? authUserData[0].token : "";
    }
  }

  useEffect(() => {
    fetchExperienceData();
  }, []);

  async function fetchExperienceData() {
    setloading(true);
    //GET_EXPERIENCE_DATA
    await axios
      .get(CONST.GET_EXPERIENCE_DATA,
        {
          headers: {
            'Authorization': `Bearer ${isToken}`
          },

        })

      .then((response) => {

        const status = response.data.status;

        if (status === 'success') {


          let newData = response.data.data;


          if (newData.length > 0) {
            {
              Object.keys(newData).map((key, index) => {
                newData[index].validate = {}
              })
            }

            //setInputFields(newData)
            setExperienceDetails(newData)
          } else {
            setExperienceDetails([{
              company_name: '',
              position: '',
              start_date: '',
              end_date: '',
              is_current_job: '0',
              validate: {}
            }])
          }

        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setloading(false);
  }

  const valdations = async () => {
    let valid = true;

    Object.entries(inputFields).map(([key, value]) => {
      let companyError, positionError, sdateError, edateError = ''

      if (value.company_name == '') {
        companyError = 'Company name field is required'
        valid = false;
      }
      validateChange(key, 'company_name', companyError);

      if (value.position == '') {
        positionError = 'Position field is required'
        valid = false;
      }/*else{ 
                if (value.position.match(/[0-9]/)) {
                  positionError = 'Invalid position format' 
                  valid = false;
                }
            }*/
      validateChange(key, 'position', positionError);

      if (value.start_date == '') {
        sdateError = 'Start Date field is required'
        valid = false;
      }
      validateChange(key, 'start_date', sdateError);


      if (value.is_current_job == '0') {
        if (value.end_date == '') {
          edateError = 'End Date field is required'
          valid = false;
        }
        validateChange(key, 'end_date', edateError);

        if (value.start_date >= value.end_date) {
          edateError = 'End Date should be greater than Start Date'
          valid = false;
        }
      }

      validateChange(key, 'end_date', edateError);

    });
    return valid;
  }
  const handleSubmit = async (e) => {

    let isValid = await valdations();
    if (isValid === true) {

      setloading(true);
      axios
        .post(CONST.ADD_EXPERIENCE,
          {
            "experience": inputFields
          },
          {
            headers: {
              'Authorization': `Bearer ${isToken}`,
              'Content-Type': 'application/json'
            }
          })
        .then((response) => {

          if (response) {

            if (response.data.status == 'error') {
              toast.error(response.data.message);
            } else {
              toast.success(response.data.message);
              fetchExperienceData()

              setInputFields(
                [{
                  company_name: '',
                  position: '',
                  start_date: '',
                  end_date: '',
                  is_current_job: '0',
                  validate: {}
                }]
              );
            }
          }
        })
        .catch((error) => {
          setloading(false);
          console.log(error);
          toast.error("Something went wrong. Please try again." + error);
        });
      setloading(false);
    } else {

    }
  }

  const addExperienceRow = () => {

    setInputFields([...inputFields, {
      company_name: '',
      position: '',
      start_date: moment(),
      end_date: moment(),
      validate: {}
    }])
  }

  const removeInputFields = (index) => {
    const rows = [...inputFields];
    rows.splice(index, 1);
    setInputFields(rows);

  }

  const handleChange = (index, evnt) => {
    const { name, value } = evnt.target;
    const list = [...inputFields];
    list[index][name] = value;
    setInputFields(list);
  }

  const validateChange = (index, fieldName, error) => {
    const list = [...inputFields];

    // let valid = 0;
    if (error != '') {
      list[index]['validate']['validate'] = 1;
      list[index]['validate'][fieldName] = error;
    }


    setInputFields(list);
  }

  const validateEditFields = (editFormData, index) => {
    const newFormData = [...experienceDetails];
    let formData = editFormData;
    if (formData.company_name == '') {
      formData.error_company_name = 'Company Name field is required';
    } else if (!/^[a-zA-Z\s]+$/.test(formData.company_name)) {
      formData.error_company_name = "Company name is not valid";
    } else {
      formData.error_company_name = "";
    }

    if (formData.position == '') {
      formData.error_position = 'Position field is required';
    }/*else if(!/^[a-zA-Z\s]+$/.test(formData.position) ){
			formData.error_position = "Position is not valid";
		}*/else {
      formData.error_position = "";
    }

    if (formData.start_date == '') {
      formData.error_start_date = 'Start Date field is required';
    } else if (formData.is_current_job == '0' && (new Date(formData.start_date) >= new Date(formData.end_date))) {
      formData.error_start_date = 'Start date should be smaller than End date';
    } else {
      formData.error_start_date = '';
    }

    if (formData.is_current_job == '0') {
      if (formData.end_date == '') {
        formData.error_end_date = 'End Date field is required';
      } else if (new Date(formData.end_date) <= new Date(formData.start_date)) {
        formData.error_end_date = 'End date should be greater than Start date';
      } else {
        formData.error_end_date = '';
      }
    }

    setEditFormData(formData);
    newFormData[index] = formData;
    setExperienceDetails(newFormData);

    if (formData.error_institute_name == "" ||
      formData.error_degree == "" ||
      formData.error_start_date == "" ||
      (formData.is_current_job == '0' && formData.error_end_date == "")) {

      return true;
    } else {
      return false;
    }
  }

  const handleExperienceEditFormSubmit = (event, experience) => {
    event.preventDefault();
    const index = experienceDetails.findIndex((experience) => experience.id === editExperienceId);
    const isEditDataValid = validateEditFields(editFormData, index);

    let params = {
      'id': editExperienceId,
      'company_name': editFormData.company_name,
      'position': editFormData.position,
      'start_date': moment(editFormData.start_date, 'DD-MM-YYYY').format('YYYY-MM-DD'),
      //'end_date': editFormData.end_date,
      'is_current_job': editFormData.is_current_job
    }
    if (editFormData.is_current_job != 1) {
      params.end_date = moment(editFormData.end_date, 'DD-MM-YYYY').format('YYYY-MM-DD');
    }
    //alert(params);
    if (isEditDataValid) {

      axios({
        method: 'put',
        url: CONST.EDIT_EXPERIENCE_DETAILS,
        data: params,
        headers: {
          'Authorization': `Bearer ${isToken}`
        }

      })
        .then((response) => {

          const status = response.data.status;
          setloading(false);

          if (status === 'success') {
            toast.success(response.data.message);
            fetchExperienceData();
            setEditExperienceId(null);
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((error) => {
          setloading(false);

          toast.error("Something went wrong. Please try again.");
        });
    }
  }

  const handleEditFormChange = (fieldValue, fieldName) => {
    const newFormData = { ...editFormData };
    let new_dt = '';
    if (fieldValue == 'start_date' || fieldValue == 'end_date') {
      new_dt = moment(fieldValue).format('DD-MM-YYYY');
      newFormData[fieldName] = new_dt;
    } else
      newFormData[fieldName] = fieldValue; //event.target.value;
    setEditFormData(newFormData);

  }

  const handleEditExperience = (event, experience) => {
    event.preventDefault();
    setEditExperienceId(experience.id);
    const formValues = {
      'id': experience.id,
      'company_name': experience.company_name,
      'position': experience.position,
      'start_date': experience.start_date,
      'end_date': experience.end_date,
      'is_current_job': experience.is_current_job
    };
    setEditFormData(formValues);
  }

  const handleCancelClick = (event, guardian) => {
    setEditExperienceId(null);
  }

  function deleteExperience(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: "Do you want to delete experience details?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        //setLoading(true);
        const newFormData = [...experienceDetails];
        const index = experienceDetails.findIndex((experience) => experience.id === id);
        if (id > 0) {
          axios
            .delete(CONST.DELETE_EXPERIENCE_DETAILS, {
              headers: {
                'Authorization': `Bearer ${isToken}`
              },
              data: {
                exp_id: id
              }
            })
            .then((response) => {

              if (response) {
                if (response.data.status == 'error') {
                  toast.error(response.data.message);
                } else {
                  toast.success(response.data.message);
                  //newFormData.splice(index,1);
                  //setExperienceDetails(newFormData);
                  //handleClose();
                  fetchExperienceData()
                }
              }
            })
            .catch((error) => {
              setloading(false);
              toast.error("Something went wrong. Please try again." + error);
            });
          setloading(false);
        }
      }
    })
  }

  const handleCheck = (i, event) => {
    let current_job = '0';
    if (event.target.checked) {
      current_job = '1';
    }
    const { name, value } = event.target;
    const list = [...inputFields];
    list[i]['is_current_job'] = current_job;
    setInputFields(list);
  }

  const handleEditCheck = (event) => {
    let current_job = '0';
    if (event.target.checked) {
      current_job = '1';
    }
    const newFormData = { ...editFormData };
    newFormData['is_current_job'] = current_job;
    newFormData['end_date'] = '';

    setEditFormData(newFormData);
  }

  return (
    <>
      <Loading
        loading={loading}
        background="rgba(0, 0, 0, 0.5)"
        loaderColor="#fff"
      />
      <HeaderSection
        title=""
        heading="Experience Information"
        addButton={addExperienceRow}
        addLink="#"
        addLinkName="Add More"
        linktype=""
      />
      <div className="page-body">
        <div className="container-xl">
          <div className="row row-cards">
            <div className="col-12">

              <div className="card" key="gcard">
                <div className="card-body">
                  <div className="col-12">
                    <div className="card">
                      <div className="table-responsive" key="div_0">
                        <form onSubmit={handleExperienceEditFormSubmit}>
                          <table className="table table-vcenter card-table table-striped" key="table_0" id="custTable">
                            <thead>
                              <tr key="tr_head">
                                <th>Company Name</th>
                                <th>Position</th>
                                <th>Current Company</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th className="w-1">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {

                                experienceDetails.map((data, i) => {


                                  return (
                                    <>
                                      {(editExperienceId != null && editExperienceId === data.id) ?
                                        (<EditableRow editFormData={editFormData}
                                          handleEditFormChange={handleEditFormChange} handleCancelClick={handleCancelClick} handleEditCheck={handleEditCheck} />) :
                                        (<ReadOnlyRow data={data} handleEditExperience={handleEditExperience} deleteExperience={deleteExperience} deleteImg={deleteImg} />
                                        )
                                      }
                                    </>
                                  )
                                })
                              }
                            </tbody>
                          </table>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-body">
                  {

                    inputFields.map((data, i) => {

                      const { company_name, position, start_date, end_date, is_current_job, validate } = data;


                      return (
                        <div className="row" key={`row_${i}`}  >
                          <div className="col-md-12">
                            <div className="form-group mb-3 ">
                              <label className="form-label required">
                                I'm Working Here
                              </label>
                              <div>
                                <input
                                  type="checkbox"
                                  name="is_current_job"
                                  id={`is_current_job_${i}`}
                                  checked={(is_current_job == '1') ? 'checked' : ''}
                                  aria-describedby="emailHelp"
                                  onChange={(evnt) => handleCheck(i, evnt)}
                                  value={is_current_job}
                                />
                              </div>

                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mb-3 ">
                              <label className="form-label required">
                                Company Name
                              </label>
                              <div>

                                <input
                                  type="text"
                                  name="company_name"
                                  id={`company_name_${i}`}
                                  value={company_name}
                                  className={`form-control ${validate.validate && validate.company_name
                                    ? "is-invalid "
                                    : ""
                                    }`}
                                  aria-describedby="emailHelp"
                                  placeholder="Enter Company Name"
                                  onChange={(evnt) => handleChange(i, evnt)}
                                />
                              </div>
                              <div
                                className={`invalid-feedback text-start ${validate.validate && validate.company_name
                                  ? "d-block"
                                  : "d-none"
                                  }`}
                              >
                                {validate.validate && validate.company_name
                                  ? validate.company_name
                                  : ""}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group mb-3 ">
                              <label className="form-label required">
                                Position
                              </label>
                              <div>
                                <input
                                  type="text"
                                  name="position"
                                  id={`position_${i}`}
                                  className={`form-control ${validate.validate && validate.position
                                    ? "is-invalid "
                                    : ""
                                    }`}
                                  aria-describedby="emailHelp"
                                  placeholder="Enter position"
                                  onChange={(evnt) => handleChange(i, evnt)}
                                  value={position}
                                />
                              </div>
                              <div
                                className={`invalid-feedback text-start ${validate.validate && validate.position
                                  ? "d-block"
                                  : "d-none"
                                  }`}
                              >
                                {validate.validate && validate.position
                                  ? validate.position
                                  : ""}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group mb-3 ">
                              <label className="form-label required">Start Date</label>
                              <div>
                                <DatePicker
                                  name="start_date"
                                  id={`start_date_${i}`}
                                  value={start_date}
                                  selected={start_date}
                                  timeFormat=""
                                  dateFormat="DD-MM-YYYY"
                                  className={`${validate.validate && validate.start_date
                                    ? "is-invalid "
                                    : ""
                                    }`}
                                  onChange={date => handleChange(i, { target: { value: date, name: 'start_date' } })}
                                />

                                <div
                                  className={`invalid-feedback text-start mb-2 ${validate.validate && validate.start_date
                                    ? "d-block"
                                    : "d-none"
                                    }`}
                                >
                                  {validate.validate && validate.start_date
                                    ? validate.start_date
                                    : ""}
                                </div>
                              </div>
                            </div>
                          </div>
                          {
                            (is_current_job == 0) ?
                              (
                                <>
                                  <div className="col-md-6">
                                    <div className="form-group mb-3 ">
                                      <label className="form-label required">
                                        End Date
                                      </label>
                                      <div>
                                        <DatePicker
                                          name="end_date"
                                          id={`end_date_${i}`}
                                          value={end_date}
                                          selected={end_date}
                                          dateFormat="DD-MM-YYYY"
                                          timeFormat=""
                                          className={`${validate.validate && validate.end_date
                                            ? "is-invalid "
                                            : ""
                                            }`}
                                          onChange={date => handleChange(i, { target: { value: date, name: 'end_date' } })}
                                        />
                                      </div>
                                      <div
                                        className={`invalid-feedback text-start 
                                                ${validate.validate && validate.end_date
                                            ? "d-block"
                                            : "d-none"
                                          }`}>
                                        {validate.validate && validate.end_date
                                          ? validate.end_date
                                          : ""}
                                      </div>
                                      <div
                                        className="invalid-feedback text-start" >
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )
                              :
                              ''
                          }



                          <div className="col-md-6">
                            {(inputFields.length !== 1 && i > 0) ?
                              <button className="mr-3 btn btn-danger mt-4" onClick={() => removeInputFields(i)} style={{ margin: "1px 10px 30px 1px" }}>Remove</button> : ''
                            }
                          </div>
                          <hr></hr>
                        </div>

                      )
                    })
                  }



                  <div className="col-md-12">
                    <div className="form-footer">
                      <button
                        type="button"
                        className="mr-3 btn btn-primary"
                        onClick={handleSubmit}
                      >
                        Submit
                      </button>
                      &nbsp;&nbsp;
                      <button
                        type="reset"
                        className="btn btn-outline-primary"

                      >
                        Reset
                      </button>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}

export default Experience