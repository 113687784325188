// ImageCropperPopup.js
import React, { useState, useCallback } from 'react'
import Modal from 'react-modal';
import 'react-image-crop/dist/ReactCrop.css';
import Cropper from 'react-easy-crop'
import "react-image-crop/dist/ReactCrop.css";
import '../../../assets/css/croper.css'
import getCroppedImg from '../cropper/cropImage'
Modal.setAppElement('#root'); // Set the root element for the modal

function ImageCropperPopup({ isOpen, onClose, onSave}) {
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [fileName, setFileName] = useState('');
  const [fileType, setFileType] = useState('');

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  // Function to handle the crop change
  const onCropChange = useCallback((crop) => {
    setCrop(crop);
  }, []);

  // Function to handle the zoom change
  const onZoomChange = useCallback((zoom) => {
    setZoom(zoom);
  }, []);

  const showCroppedImage = async () => {
    try {
     
      const croppedImage = await getCroppedImg(src, croppedAreaPixels, 0);
      setCroppedImageUrl(croppedImage)
      // console.log('donee', { croppedImage });
      onSave(croppedImage, fileName, fileType)
      onClose();
    } catch (e) {
      console.error(e);
    }
  };

  const onFileChange = async (e) => {
		if (e.target.files && e.target.files.length > 0) {
		  const file = e.target.files[0];
      setFileName(file.name);
      setFileType(file.type)
		  let imageDataUrl = await readFile(file);
		  setSrc(imageDataUrl)
		}
	  };

    const readFile = (file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => resolve(reader.result), false);
        reader.readAsDataURL(file);
      });
      };


  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Image Cropper Modal"
    >
     
      <div className="App">
        <div className="crop-container">
       
        <Cropper
          image={src}
          crop={crop}
          zoom={zoom}
          aspect={4 / 4} 
          onCropChange={onCropChange}
          onZoomChange={onZoomChange}
          onCropComplete={onCropComplete}
        />
        
        </div>
        <div className="controls">
          <input type="file" accept="image/*" onChange={onFileChange} />
          {src && (<button onClick={showCroppedImage}>save</button>)}
        </div>
      </div>
    </Modal>
  );
}

export default ImageCropperPopup;
