import React, { useContext, useEffect } from 'react'
import Loading from "react-fullscreen-loading";
import HeaderSection from '../banner/HeaderSection'
import * as  CONST from "../../../component/config/Constant"
import ReactSwitch from 'react-switch';
import * as SwitchProps from '../../../component/utilities/ReactSwitchReactSwitchProps';
import { AuthContext } from '../../../component/context/AuthContext';
import { useState } from 'react';
import axios from 'axios';
import { toast } from "react-toastify";
import DivSpinner from '../../../component/utilities/Spinner';
import Form from '../../../component/utilities/Forms';
/* import Accordion from 'react-bootstrap/Accordion'; */

function AdminPermissions() {
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  const [dptListing, setDptListing] = useState("");
  const [deptName, setDeptName] = useState("");
  const [eventStatus, setEventStatus] = useState("");
  const [dptStatus, setDptStatus] = useState(0);
  const [empStatus, setEmpStatus] = useState(0);
  const [policyStatus, setPolicyStatus] = useState(0);
  const [openingStatus, setOpeningStatus] = useState(0);
  const [birthdayStatus, setBirthdayStatus] = useState(0);
  const [hrdStatus, setHrdStatus] = useState(0);
  const [supportStatus, setSupportStatus] = useState(0);
  const [libraryStatus, setLibraryStatus] = useState(0);
  const [exemptionStatus, setExemptionStatus] = useState(0);
  const [settingStatus, setSettingStatus] = useState(0);
  const [assetsStatus, setAssetsStatus] = useState(0);
  const [validate, setValidate] = useState({});
  const [loading, setLoading] = useState(false);

  let isToken = '';
  let user_id = '';
  if (authUserData) {
    if (authUserData[0]) {
      isToken = authUserData[0].token ? authUserData[0].token : "";
      user_id = authUserData[0].user_id ? authUserData[0].user_id : "";
    }
  }
  useEffect(() => {
    fetchDeptList();
  }, []);
  async function fetchDeptList() {
    setLoading(true);
    await axios
      .get(CONST.GET_DEPARTMENT, {
        headers: {
          'Authorization': `Basic ${isToken}`
        }
      })
      .then((response) => {
        let status = response.data.status;
        //console.log(response)
        if (status == 'success') {
          setDptListing(response.data.data);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
    setLoading(false);
  }
  const handleEmpPermissions = (id) => {
    setEmpStatus((id == '0') ? '1' : '0');
  }
  const handleSupportPermissions = (id) => {

    setSupportStatus((id == '0') ? '1' : '0');
  }
  const handleLibraryPermissions = (id) => {
    setLibraryStatus((id == '0') ? '1' : '0');
  }
  const handleAssetsPermissions = (id) => {
    setAssetsStatus((id == '0') ? '1' : '0');
  }
  const handleExemptionPermissions = (id) => {
    setExemptionStatus((id == '0') ? '1' : '0');
  }
  const handleSettingPermissions = (id) => {
    setSettingStatus((id == '0') ? '1' : '0');
  }
  const handlePolicyPermissions = (id) => {
    setPolicyStatus((id == '0') ? '1' : '0');
  }
  const handleOpeningPermissions = (id) => {
    setOpeningStatus((id == '0') ? '1' : '0');
  }
  const handleDptPermissions = (id) => {
    setDptStatus((id == '0') ? '1' : '0');
  }
  const handleEventPermissions = (id) => {
    setEventStatus((id == '0') ? '1' : '0');
  }
  const handleBirthdayPermissions = (id) => {
    setBirthdayStatus((id == '0') ? '1' : '0');
  }
  const handleHRDPermissions = (id) => {
    setHrdStatus((id == '0') ? '1' : '0');
  }
  const validatePermissionsForm = async () => {
    let isValid = true;
    let validator = Form.validator({
      deptName: {
        value: deptName,
        isRequired: true,
        isEmail: false,
        label: "department name",
      },

    });

    if (validator !== null) {
      setValidate({
        validate: validator.errors,
      });
      isValid = false;
    } else {
      setValidate({
        validate: "",
      });
      isValid = true;
    }
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validate = await validatePermissionsForm();
    if (validate) {
      setLoading(true);
      await axios
        .post(CONST.SAVE_PERMISSIONS, {
          department_id: parseInt(deptName),
          eventStatus: eventStatus,
          dptStatus: dptStatus,
          empStatus: empStatus,
          policyStatus: policyStatus,
          openingStatus: openingStatus,
          birthdayStatus: birthdayStatus,
          hrdStatus: hrdStatus,
          supportStatus: supportStatus,
          libraryStatus: libraryStatus,
          exemptionStatus: exemptionStatus,
          settingStatus: settingStatus,
          assetsStatus: assetsStatus,
        }, {
          headers: {
            'Authorization': `Basic ${isToken}`
          }
        })
        .then((response) => {

          const status = response.data.status;
          setLoading(false);
          if (status === 'error') {
            const errorMsg = response.data.errors;
            let isArray = Array.isArray(errorMsg);
            if (isArray) {
              errorMsg.map(element => {
                toast.error(element.msg);
              })
            }
            else {
              toast.error(errorMsg);
            }
            console.log(errorMsg);
          } else {
            toast.success("Permissions updated successfully");
          }
        })
        .catch((error) => {
          toast.error("Something went wrong. Please try again.");
        });
      setLoading(false);
    }
  }
  const handleChangeDpt = async (dptid) => {
    setLoading(true);
    setDeptName(dptid);
    const URL = CONST.GET_PERMISSIONS + '/' + dptid;
    if (dptid) {
      await axios
        .get(URL, {
          headers: {
            'Authorization': `Basic ${isToken}`
          }
        })
        .then((response) => {
          console.log(response);
          const status = response.data.status;
          if (status === 'success') {
            const permissions = JSON.parse(response.data.data[0].permissions);
            setEventStatus(permissions.eventStatus ? permissions.eventStatus : 0);
            setDptStatus(permissions.dptStatus ? permissions.dptStatus : 0);
            setEmpStatus(permissions.empStatus ? permissions.empStatus : 0);
            setPolicyStatus(permissions.policyStatus ? permissions.policyStatus : 0);
            setOpeningStatus(permissions.birthdayStatus ? permissions.openingStatus : 0);
            setBirthdayStatus(permissions.birthdayStatus ? permissions.birthdayStatus : 0);
            setHrdStatus(permissions.hrdStatus ? permissions.hrdStatus : 0);
            setSupportStatus(permissions.supportStatus ? permissions.supportStatus : 0);
            setLibraryStatus(permissions.libraryStatus ? permissions.libraryStatus : 0);
            setExemptionStatus(permissions.exemptionStatus ? permissions.exemptionStatus : 0);
            setAssetsStatus(permissions.assetsStatus ? permissions.assetsStatus : 0);
            setSettingStatus(permissions.settingStatus ? permissions.settingStatus : 0);
          }
        })
        .catch((error) => {
          alert(error);
        });
    }

    setLoading(false);
  }
  return (
    <>
      <Loading
        loading={loading}
        background="rgba(0, 0, 0, 0.5)"
        loaderColor="#fff"
      />

      <HeaderSection
        title="Manage"
        heading="Permissions"

      />
      <div className="page-body">
        <div className="container-xl">
          <div className="row row-cards">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Listing</h3>
                </div>
                <div className="card-body border-bottom py-3">
                  <div className="row">
                    <div className="col-md-2">
                      <div className="mb-3">
                        <label className="form-label">Select Department <span className='red'>*</span></label>
                        {
                          dptListing ?
                            (
                              <>
                                <select
                                  className="form-select form-control"
                                  name="deptName"
                                  id="deptName"
                                  className={`form-control ${validate.validate && validate.validate.deptName
                                    ? "is-invalid "
                                    : ""
                                    }`}
                                  placeholder="Select status"
                                  onChange={(e) => handleChangeDpt(e.target.value)}
                                  value={deptName}
                                >
                                  <option value="">Select</option>
                                  {
                                    dptListing && dptListing.map((element, key) => {
                                      return (
                                        <>
                                          <option key={key} value={element.id}>{element.name}</option>
                                        </>
                                      )
                                    }
                                    )
                                  }
                                </select>
                              </>
                            )
                            : (
                              <DivSpinner size="sm" color="primary" />
                            )
                        }

                      </div>
                    </div>
                    <div>
                      <div id="roleWisePermissions">


                        <div class="panel panel-default">
                          <div class="panel-heading active">
                            <div class="list-group-item d-flex">
                              <label className="form-label"><h6>Manage HRD </h6></label>
                              <label class="switch ml-auto">
                                <ReactSwitch
                                  onColor={SwitchProps.onColor}
                                  onHandleColor={SwitchProps.onHandleColor}
                                  handleDiameter={SwitchProps.handleDiameter}
                                  uncheckedIcon={SwitchProps.uncheckedIcon}
                                  checkedIcon={SwitchProps.checkedIcon}
                                  boxShadow={SwitchProps.boxShadow}
                                  activeBoxShadow={SwitchProps.activeBoxShadow}
                                  height={SwitchProps.height}
                                  width={SwitchProps.width}
                                  className={SwitchProps.className}
                                  top={SwitchProps.top}
                                  id="material-switch"
                                  checked={(hrdStatus == 1) ? true : false}
                                  onChange={() => { handleHRDPermissions(hrdStatus) }}
                                />
                              </label>
                              {/*  <a data-toggle="collapse" href="#permission_hrd" class="theme-green ml-3 text-underline collapsed" aria-expanded="false">details</a> */}
                            </div>
                          </div>
                          {/*  <div id="permission_hrd" class="panel-collapse collapse" aria-expanded="false" >
                            <div class="panel-body border-0 py-0">
                              <ul class="list-group toggle-wrapper">
                                <li class="list-group-item d-flex">
                                  <a href="#">Customer Details</a>
                                  <label class="switch ml-auto">
                                    <input type="checkbox" class="checkbox-permissions" id="permission-customers-details-access" name="customers-details-access" value="customers-details-access" />
                                    <span class="knob"></span>
                                  </label>
                                  <label class="sub-menu switch ml-1">
                                    <input type="checkbox" class="checkbox-permissions" id="permission-customer-details" name="customer-details" value="customer-details" />
                                    <span class="knob text-white"><em>view</em></span>
                                  </label>
                                </li>
                                <li class="list-group-item d-flex">
                                  <a href="#">Customer Notes</a>
                                  <label class="switch ml-auto">
                                    <input type="checkbox" class="checkbox-permissions" id="permission-customer-notes-access" name="customer-notes-access" value="customer-notes-access" />
                                    <span class="knob"></span>
                                  </label>
                                  <label class="sub-menu switch ml-1">
                                    <input type="checkbox" class="checkbox-permissions" id="permission-customer-notes" name="customer-notes" value="customer-notes" />
                                    <span class="knob text-white"><em>view</em></span>
                                  </label>
                                </li>

                              </ul>
                            </div>
                          </div> */}
                        </div>
                        <div class="panel panel-default">
                          <div class="panel-heading active">
                            <div class="list-group-item d-flex">
                              <label className="form-label"><h6>Manage Birthday</h6></label>
                              <label class="switch ml-auto">
                                <ReactSwitch
                                  onColor={SwitchProps.onColor}
                                  onHandleColor={SwitchProps.onHandleColor}
                                  handleDiameter={SwitchProps.handleDiameter}
                                  uncheckedIcon={SwitchProps.uncheckedIcon}
                                  checkedIcon={SwitchProps.checkedIcon}
                                  boxShadow={SwitchProps.boxShadow}
                                  activeBoxShadow={SwitchProps.activeBoxShadow}
                                  height={SwitchProps.height}
                                  width={SwitchProps.width}
                                  className={SwitchProps.className}
                                  top={SwitchProps.top}
                                  id="material-switch"
                                  checked={(birthdayStatus == 1) ? true : false}
                                  onChange={() => { handleBirthdayPermissions(birthdayStatus) }}
                                />
                              </label>
                              {/*  <a data-toggle="collapse" href="#permission1" class="theme-green ml-3 text-underline collapsed" aria-expanded="false">details</a> */}
                            </div>
                          </div>

                        </div>
                        <div class="panel panel-default">
                          <div class="panel-heading active">
                            <div class="list-group-item d-flex">
                              <label className="form-label"><h6>Manage Events</h6></label>
                              <label class="switch ml-auto">
                                <ReactSwitch
                                  onColor={SwitchProps.onColor}
                                  onHandleColor={SwitchProps.onHandleColor}
                                  handleDiameter={SwitchProps.handleDiameter}
                                  uncheckedIcon={SwitchProps.uncheckedIcon}
                                  checkedIcon={SwitchProps.checkedIcon}
                                  boxShadow={SwitchProps.boxShadow}
                                  activeBoxShadow={SwitchProps.activeBoxShadow}
                                  height={SwitchProps.height}
                                  width={SwitchProps.width}
                                  className={SwitchProps.className}
                                  id="material-switch"
                                  checked={(eventStatus == 1) ? true : false}
                                  onChange={() => { handleEventPermissions(eventStatus) }}

                                />
                              </label>

                            </div>
                          </div>

                        </div>

                        <div class="panel panel-default">
                          <div class="panel-heading active">
                            <div class="list-group-item d-flex">
                              <label className="form-label"><h6>Manage Department</h6></label>
                              <label class="switch ml-auto">
                                <ReactSwitch
                                  onColor={SwitchProps.onColor}
                                  onHandleColor={SwitchProps.onHandleColor}
                                  handleDiameter={SwitchProps.handleDiameter}
                                  uncheckedIcon={SwitchProps.uncheckedIcon}
                                  checkedIcon={SwitchProps.checkedIcon}
                                  boxShadow={SwitchProps.boxShadow}
                                  activeBoxShadow={SwitchProps.activeBoxShadow}
                                  height={SwitchProps.height}
                                  width={SwitchProps.width}
                                  className={SwitchProps.className}
                                  id="material-switch"
                                  checked={(dptStatus == 1) ? true : false}
                                  onChange={() => { handleDptPermissions(dptStatus) }}

                                />
                              </label>

                            </div>
                          </div>

                        </div>
                        <div class="panel panel-default">
                          <div class="panel-heading active">
                            <div class="list-group-item d-flex">
                              <label className="form-label"><h6>Manage Openings</h6></label>
                              <label class="switch ml-auto">
                                <ReactSwitch
                                  onColor={SwitchProps.onColor}
                                  onHandleColor={SwitchProps.onHandleColor}
                                  handleDiameter={SwitchProps.handleDiameter}
                                  uncheckedIcon={SwitchProps.uncheckedIcon}
                                  checkedIcon={SwitchProps.checkedIcon}
                                  boxShadow={SwitchProps.boxShadow}
                                  activeBoxShadow={SwitchProps.activeBoxShadow}
                                  height={SwitchProps.height}
                                  width={SwitchProps.width}
                                  className={SwitchProps.className}
                                  id="material-switch"
                                  checked={(openingStatus == 1) ? true : false}
                                  onChange={() => { handleOpeningPermissions(openingStatus) }}

                                />
                              </label>

                            </div>
                          </div>

                        </div>
                        <div class="panel panel-default">
                          <div class="panel-heading active">
                            <div class="list-group-item d-flex">
                              <label className="form-label"><h6>Manage Policies</h6></label>
                              <label class="switch ml-auto">
                                <ReactSwitch
                                  onColor={SwitchProps.onColor}
                                  onHandleColor={SwitchProps.onHandleColor}
                                  handleDiameter={SwitchProps.handleDiameter}
                                  uncheckedIcon={SwitchProps.uncheckedIcon}
                                  checkedIcon={SwitchProps.checkedIcon}
                                  boxShadow={SwitchProps.boxShadow}
                                  activeBoxShadow={SwitchProps.activeBoxShadow}
                                  height={SwitchProps.height}
                                  width={SwitchProps.width}
                                  className={SwitchProps.className}
                                  id="material-switch"
                                  checked={(policyStatus == 1) ? true : false}
                                  onChange={() => { handlePolicyPermissions(policyStatus) }}

                                />
                              </label>

                            </div>
                          </div>

                        </div>
                        <div class="panel panel-default">
                          <div class="panel-heading active">
                            <div class="list-group-item d-flex">
                              <label className="form-label"><h6>Manage Employee</h6></label>
                              <label class="switch ml-auto">
                                <ReactSwitch
                                  onColor={SwitchProps.onColor}
                                  onHandleColor={SwitchProps.onHandleColor}
                                  handleDiameter={SwitchProps.handleDiameter}
                                  uncheckedIcon={SwitchProps.uncheckedIcon}
                                  checkedIcon={SwitchProps.checkedIcon}
                                  boxShadow={SwitchProps.boxShadow}
                                  activeBoxShadow={SwitchProps.activeBoxShadow}
                                  height={SwitchProps.height}
                                  width={SwitchProps.width}
                                  className={SwitchProps.className}
                                  id="material-switch"
                                  checked={(empStatus == 1) ? true : false}
                                  onChange={() => { handleEmpPermissions(empStatus) }}

                                />
                              </label>

                            </div>
                          </div>

                        </div>
                        <div class="panel panel-default">
                          <div class="panel-heading active">
                            <div class="list-group-item d-flex">
                              <label className="form-label"><h6>Manage Support</h6></label>
                              <label class="switch ml-auto">
                                <ReactSwitch
                                  onColor={SwitchProps.onColor}
                                  onHandleColor={SwitchProps.onHandleColor}
                                  handleDiameter={SwitchProps.handleDiameter}
                                  uncheckedIcon={SwitchProps.uncheckedIcon}
                                  checkedIcon={SwitchProps.checkedIcon}
                                  boxShadow={SwitchProps.boxShadow}
                                  activeBoxShadow={SwitchProps.activeBoxShadow}
                                  height={SwitchProps.height}
                                  width={SwitchProps.width}
                                  className={SwitchProps.className}
                                  id="material-switch"
                                  checked={(supportStatus == 1) ? true : false}
                                  onChange={() => { handleSupportPermissions(supportStatus) }}

                                />
                              </label>

                            </div>
                          </div>

                        </div>
                        <div class="panel panel-default">
                          <div class="panel-heading active">
                            <div class="list-group-item d-flex">
                              <label className="form-label"><h6>Manage Exemption</h6></label>
                              <label class="switch ml-auto">
                                <ReactSwitch
                                  onColor={SwitchProps.onColor}
                                  onHandleColor={SwitchProps.onHandleColor}
                                  handleDiameter={SwitchProps.handleDiameter}
                                  uncheckedIcon={SwitchProps.uncheckedIcon}
                                  checkedIcon={SwitchProps.checkedIcon}
                                  boxShadow={SwitchProps.boxShadow}
                                  activeBoxShadow={SwitchProps.activeBoxShadow}
                                  height={SwitchProps.height}
                                  width={SwitchProps.width}
                                  className={SwitchProps.className}
                                  id="material-switch"
                                  checked={(exemptionStatus == 1) ? true : false}
                                  onChange={() => { handleExemptionPermissions(exemptionStatus) }}

                                />
                              </label>

                            </div>
                          </div>

                        </div>
                        <div class="panel panel-default">
                          <div class="panel-heading active">
                            <div class="list-group-item d-flex">
                              <label className="form-label"><h6>Manage Library</h6></label>
                              <label class="switch ml-auto">
                                <ReactSwitch
                                  onColor={SwitchProps.onColor}
                                  onHandleColor={SwitchProps.onHandleColor}
                                  handleDiameter={SwitchProps.handleDiameter}
                                  uncheckedIcon={SwitchProps.uncheckedIcon}
                                  checkedIcon={SwitchProps.checkedIcon}
                                  boxShadow={SwitchProps.boxShadow}
                                  activeBoxShadow={SwitchProps.activeBoxShadow}
                                  height={SwitchProps.height}
                                  width={SwitchProps.width}
                                  className={SwitchProps.className}
                                  id="material-switch"
                                  checked={(libraryStatus == 1) ? true : false}
                                  onChange={() => { handleLibraryPermissions(libraryStatus) }}

                                />
                              </label>

                            </div>
                          </div>

                        </div>
                        <div class="panel panel-default">
                          <div class="panel-heading active">
                            <div class="list-group-item d-flex">
                              <label className="form-label"><h6>Manage Assets</h6></label>
                              <label class="switch ml-auto">
                                <ReactSwitch
                                  onColor={SwitchProps.onColor}
                                  onHandleColor={SwitchProps.onHandleColor}
                                  handleDiameter={SwitchProps.handleDiameter}
                                  uncheckedIcon={SwitchProps.uncheckedIcon}
                                  checkedIcon={SwitchProps.checkedIcon}
                                  boxShadow={SwitchProps.boxShadow}
                                  activeBoxShadow={SwitchProps.activeBoxShadow}
                                  height={SwitchProps.height}
                                  width={SwitchProps.width}
                                  className={SwitchProps.className}
                                  id="material-switch"
                                  checked={(assetsStatus == 1) ? true : false}
                                  onChange={() => { handleAssetsPermissions(assetsStatus) }}

                                />
                              </label>

                            </div>
                          </div>

                        </div>
                        <div class="panel panel-default">
                          <div class="panel-heading active">
                            <div class="list-group-item d-flex">
                              <label className="form-label"><h6>Manage Setting</h6></label>
                              <label class="switch ml-auto">
                                <ReactSwitch
                                  onColor={SwitchProps.onColor}
                                  onHandleColor={SwitchProps.onHandleColor}
                                  handleDiameter={SwitchProps.handleDiameter}
                                  uncheckedIcon={SwitchProps.uncheckedIcon}
                                  checkedIcon={SwitchProps.checkedIcon}
                                  boxShadow={SwitchProps.boxShadow}
                                  activeBoxShadow={SwitchProps.activeBoxShadow}
                                  height={SwitchProps.height}
                                  width={SwitchProps.width}
                                  className={SwitchProps.className}
                                  id="material-switch"
                                  checked={(settingStatus == 1) ? true : false}
                                  onChange={() => { handleSettingPermissions(settingStatus) }}

                                />
                              </label>

                            </div>
                          </div>

                        </div>
                      </div>

                    </div>
                    <table className='table'>



                    </table>
                    <div className='row'>
                      <div className='col-md-12 text-right mr-2'>
                        <button
                          type="button"
                          name="btnSearchReset"
                          id="btnSearchReset"
                          className="mr-3 btn btn-primary"
                          onClick={handleSubmit}
                        >Update</button>
                      </div> </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >

    </>
  )
}

export default AdminPermissions