import React from 'react'
import { Routes, Route } from "react-router-dom";
import Home from '../../pages/web/Home'
import Login from '../../pages/auth/Login';
import ForgetPassword from '../../pages/auth/ForgetPassword';
import Dashboard from '../../pages/admin/dashboard/Dashboard';
import AuthRoutes from "../nav-routes/AuthRoute";
import * as CONST from "../config/Constant";
import ManageEmployee from "../../pages/admin/employee/List"
import AddEmployee from "../../pages/admin/employee/Add"
import WebEvents from "../../pages/web/events/List"
import WebSingleEvent from "../../pages/web/events/Single";
import ManageDepartment from '../../pages/admin/department/List';
import ManageDesignation from '../../pages/admin/designation/List';
import Profile from "../../pages/admin/profile/MyProfile"
import PasswordReset from '../../pages/auth/PasswordReset';
import Tickets from '../../pages/admin/ticket/Tickets';
import EventIndex from '../../pages/admin/events/EventIndex';
import ManagePolicies from '../../pages/admin/policy/List';
import ManagePolicyType from '../../pages/admin/policy/PolicyTypeList';
import ManagBirthday from '../../pages/admin/birthday/List';
import ManagBirthday2 from '../../pages/admin/birthday/List2';
import ManagOpenings from '../../pages/admin/openings/List';
import ManagApplicationList from '../../pages/admin/openings/ApplicationList';
import AdminLibraryListing from '../../pages/admin/library/AdminLibraryListing';
import ManageQuestionAnswer from '../../pages/admin/library/TechQuestionAnswer';
import ManageQuestionComments from '../../pages/admin/library/QuestionCommentList';
import TechLibrary from "../../pages/web/library/Technologies";
import TechnologyDetails from "../../pages/web/library/TechnologyDetails";
import WebCareers from "../../pages/web/careers/List";
import WebOpeningDetails from "../../pages/web/careers/Details";
import WebOpeningApply from "../../pages/web/careers/Apply";
import WebOpeningApplyThankYou from "../../pages/web/careers/ThankYou";
import WebSinglePolicy from "../../pages/web/policy/single"
import AdminPermissions from '../../pages/admin/permissions/AdminPermissions';
import ManageLeaves from '../../pages/admin/leaves/List';
import ManageLeaveTypes from '../../pages/admin/leaveTypes/List';
import WebTestimonials from '../../pages/web/testimonial/WebTestimonials';
import ManageAdminLeaves from '../../pages/admin/leaves/AdminList';
import ManageAssetsType from '../../pages/admin/assets/AssetsTypeList';
import ManageMyAssets from '../../pages/admin/assets/AssetsList';
import ManageEmployeeAssets from '../../pages/admin/assets/EmployeeAssetsList';
import AdminTestimonilasListings from '../../pages/admin/testimonials/AdminTestimonilasListings';
import MyExemptions from '../../pages/admin/tax/MyExemptions';
import AddExemptions from '../../pages/admin/tax/AddExemptions';
import ExemptionList from '../../pages/admin/tax/ExemptionList';
import ApproveLeave from '../../pages/admin/leaves/ApproveLeave';
import ManageCompOff from '../../pages/admin/comp-off/List';
import EventCategoryList from '../../pages/admin/events/EventCategoryList';
import ManageAssetsBrand from '../../pages/admin/assets/brand/AssetsBrandList';
import ManageAssets from '../../pages/admin/assets/assetsManagement/AssetsManagementList';
import Roles from '../../pages/admin/roles/Roles';
import ManageAdminCompOff from '../../pages/admin/comp-off/AdminList';
import BusinessCommunication from '../../pages/web/training/BusinessCommunication';
import Holiday from '../../pages/admin/holiday/Holidays';
import EmployeelLaves from '../../pages/admin/reports/employee-leaves';
import PaidHolidays from '../../pages/web/holiday/PaidHolidays';

import MyRoles from '../../pages/admin/roles/MyRoles';

function NavRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/forget-password" element={<ForgetPassword />} />
            <Route exact path="/reset-password/:token" element={<PasswordReset />} />
            <Route exact path="/events" element={<WebEvents />} />
            <Route exact path="/events/:category_id" element={<WebEvents />} />
            <Route exact path="/careers" element={<WebCareers />} />
            <Route exact path="/openings-details/:id" element={<WebOpeningDetails />} />
            <Route exact path="/openings-apply/:id" element={<WebOpeningApply />} />
            <Route exact path="/apply/thank-you" element={<WebOpeningApplyThankYou />} />
            <Route path="/event/:slug" element={<WebSingleEvent />} />
            {/*    <Route exact path="/policies" element={<WebEvents />} /> */}
            <Route path="/policy/:slug_id/:slug_name" element={<WebSinglePolicy />} />
            <Route exact path="/technologies" element={<TechLibrary />} />
            <Route exact path="/tech-details/:id/:tech_name" element={<TechnologyDetails />} />
            TechnologyDetails
            <Route exact path="/testimonials" element={<WebTestimonials />} />
            <Route exact path="/business-communication" element={<BusinessCommunication />} />
            <Route exact path="/holiday" element={<PaidHolidays />} />
            {/* ADMIN ROUTES */}
            <Route path="/" element={<AuthRoutes />}>
                <Route exact path={CONST.ADMIN_PRE + 'dashboard'} element={<Dashboard />} />
                <Route exact path={CONST.ADMIN_PRE + 'employee'} element={<ManageEmployee />} />
                <Route exact path={CONST.ADMIN_PRE + 'employee/add'} element={<AddEmployee />} />
                <Route exact path={CONST.ADMIN_PRE + 'department'} element={<ManageDepartment />} />
                <Route exact path={CONST.ADMIN_PRE + 'designation'} element={<ManageDesignation />} />
                <Route exact path={CONST.ADMIN_PRE + 'tickets'} element={<Tickets />} />
                <Route exact path={CONST.ADMIN_PRE + 'events'} element={<EventIndex />} />
                <Route exact path={CONST.ADMIN_PRE + 'policies'} element={<ManagePolicies />} />
                <Route exact path={CONST.ADMIN_PRE + 'policy-type'} element={<ManagePolicyType />} />
                <Route exact path={CONST.ADMIN_PRE + 'birthday'} element={<ManagBirthday />} />
                <Route exact path={CONST.ADMIN_PRE + 'birthday2'} element={<ManagBirthday2 />} />
                <Route exact path={CONST.ADMIN_PRE + 'openings'} element={<ManagOpenings />} />
                <Route exact path={CONST.ADMIN_PRE + 'applicationList/:id'} element={<ManagApplicationList />} />
                <Route exact path={CONST.ADMIN_PRE + 'leaves'} element={<ManageLeaves />} />
                <Route exact path={CONST.ADMIN_PRE + 'leaveTypes'} element={<ManageLeaveTypes />} />
                <Route exact path={CONST.ADMIN_PRE + 'employee-leaves'} element={<ManageAdminLeaves />} />
                <Route exact path={CONST.ADMIN_PRE + 'approve-leave/:id'} element={<ApproveLeave />} />
                <Route exact path={CONST.ADMIN_PRE + 'event-category'} element={<EventCategoryList />} />
                {/* Profile */}
                <Route exact path={CONST.ADMIN_PRE + 'my-profile'} element={<Profile />} />
                <Route exact path={CONST.ADMIN_PRE + 'testimonials'} element={<AdminTestimonilasListings />} />
                {/* Library */}
                <Route exact path={CONST.ADMIN_PRE + 'library'} element={<AdminLibraryListing />} />
                <Route exact path={CONST.ADMIN_PRE + 'techQuestionAnwser/:id'} element={<ManageQuestionAnswer />} />
                <Route exact path={CONST.ADMIN_PRE + 'questionComments/:techId/:id'} element={<ManageQuestionComments />} />
                {/* Permissions */}
                <Route exact path={CONST.ADMIN_PRE + 'permissions'} element={<AdminPermissions />} />
                {/* Assets Type */}
                <Route exact path={CONST.ADMIN_PRE + 'assets-type'} element={<ManageAssetsType />} />
                <Route exact path={CONST.ADMIN_PRE + 'assets-brand'} element={<ManageAssetsBrand />} />
                <Route exact path={CONST.ADMIN_PRE + 'assets-management'} element={<ManageAssets />} />
                {/* My Assets */}
                <Route exact path={CONST.ADMIN_PRE + 'my-assets'} element={<ManageMyAssets />} />
                 {/* Employee Assets List*/}
                 <Route exact path={CONST.ADMIN_PRE + 'employee-assets-list'} element={<ManageEmployeeAssets />} />
                {/* <Route exact path={CONST.ADMIN_PRE + 'my-assets'} element={<ManageAssets />} /> */}
                {/* Employee Assets List*/}
                <Route exact path={CONST.ADMIN_PRE + 'employee-assets-list'} element={<ManageEmployeeAssets />} />
                <Route exact path={CONST.ADMIN_PRE + 'my-exemption'} element={<MyExemptions />} />
                <Route exact path={CONST.ADMIN_PRE + 'add-exemption'} element={<AddExemptions />} />
                <Route exact path={CONST.ADMIN_PRE + 'exemption-list'} element={<ExemptionList />} />
                 {/* Roles & responsiblity */}
                 <Route exact path={CONST.ADMIN_PRE + 'roles-responsibility'} element={<Roles />} />
                 <Route exact path={CONST.ADMIN_PRE + 'my-roles'} element={<MyRoles />} />
                
                <Route exact path={CONST.ADMIN_PRE + 'comp-off'} element={<ManageCompOff />} />
                <Route exact path={CONST.ADMIN_PRE + 'employee-comp-off'} element={<ManageAdminCompOff />} />
                {/* Holidays */}
                <Route exact path={CONST.ADMIN_PRE + 'holidays'} element={<Holiday />} />
                <Route exact path={CONST.ADMIN_PRE + 'report/employee-leaves-comp-off'} element={<EmployeelLaves />} />
            </Route>
        </Routes>
    )
}
export default NavRoutes
