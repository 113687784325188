import React from "react";
import moment from 'moment';

const ReadOnlyRow = ({data, handleEditExperience, deleteExperience, deleteImg}) => {
    console.log('READ Experience'+JSON.stringify(data))

    const getDate = (date) => {
            let newD = date.toString();
            return newD;
    }
    //alert(data.id)
    return (
        <>
            {
                (data.id)
                ?
                (
                    <tr key={`tr_view_experience`}>
                        <td>{data.company_name ? data.company_name : ''}</td>
                        <td className="text-muted">{data.position ? data.position : '' }</td>
                        <td>{(data.is_current_job == 1) ? 'Yes' : 'No'}</td>
                        <td>{data.start_date ? getDate(data.start_date) : '' }</td>
                        <td>{data.end_date ? getDate(data.end_date) : '' }</td>
                        <td>
        
                        <img src="/static/media/edit.8b4759ee999d507ec6d9bc66c8538edc.svg" alt="editExperience" className="cursor" width="32" onClick={(event)=> handleEditExperience(event, data)} />
        
                        <img src={deleteImg} alt='deleteImg' width={32} className='cursor' onClick={() => deleteExperience(data.id) } />
                        </td>
                    </tr>
                )
                :
                (
                    <tr>
                        <td colSpan='6'>Experience not found!</td>
                    </tr>
                )
            }
            
        </>
    )
}

export default ReadOnlyRow