import { React, useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import "../../../assets/css/table.css";
import Loading from "react-fullscreen-loading";
import { Link } from 'react-router-dom';
import HeaderSection from "../banner/HeaderSection";
import DivLoader from "../../../component/utilities/Spinner";
import DataTable from "react-data-table-component";
import axios from "axios";
import { toast } from "react-toastify";
import * as  CONST from "../../../component/config/Constant"
import Form from "../../../component/utilities/Forms";
import viewImg from "../../../assets/img/icon/index.png";
import editImg from "../../../assets/img/icon/edit.svg";
import { Modal, Button } from 'react-bootstrap';
import { AuthContext } from '../../../component/context/AuthContext';
import Swal from 'sweetalert2';
//import withReactContent from 'sweetalert2-react-content';
import ReactSwitch from 'react-switch';
import * as SwitchProps from '../../../component/utilities/ReactSwitchReactSwitchProps';

function List() {
    const [authUserData, setAuthUserData] = useContext(AuthContext);
    const [fullLoading, setFullLoading] = useState(false);
    const [validate, setValidate] = useState({});
    const [processing, setProcessing] = useState(false);
    const [data, setData] = useState([]);

    const [loading, setLoading] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [openingId, setOpeningId] = useState(0);
    const [applyId, setApplyId] = useState(0);
    //const MySwal                            = withReactContent(Swal)
    const [listLimit, setListLimit] = useState(50);
    const [action, setAction]               = useState('');

    /** For Modalpopup */
    const [show, setShow] = useState(false);

    /* START DATATABLE */
    const [totalRecords, settotalRecords] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [maxPageLimit, setMaxPageLimit] = useState(10);
    const [minPageLimit, setMinPageLimit] = useState(0);

    //Search
    const [searchName, setSearchName] = useState('');
    const [searchPhoneNumber, setSearchPhoneNumber] = useState('');
    const [searchEmail, setSearchEmail] = useState('');
    const [searchOrganization, setSearchOrganization] = useState('');

    const [dataSort, setDataSort] = useState({
        order: 'desc',
        order_by: 'id'
    });
    const pageNumberLimit = { perPage };

    //For Form
    const [jobTitle, setJobTitle] = useState('');
    const [jobDescription, setJobDescription] = useState('');
    const [status, setStatus] = useState(false);
    const [totalOpenings, setTotalOpenings] = useState('');
    const [location, setLocation] = useState('');
    const [expRequired, setExpRequired] = useState('');

    const [resumePath, setResumePath] = useState('');
    const [resumeShow, setResumeShow] = useState(false);
    const [applicantName, setApplicantName] = useState('');


    const params = useParams();
    let id = params.id;
    let isToken = '';
    let user_id = '';
    if (authUserData) {
        if (authUserData[0]) {
            isToken = authUserData[0].token ? authUserData[0].token : "";
            user_id = authUserData[0].user_id ? authUserData[0].user_id : "";
        }
    }

    const [searchParam, setSearchParam] = useState(
        {
            "srchName": searchName,
            "srchEmail": searchEmail,
            "srchPhoneNumber": searchPhoneNumber,
            "srchOrganization": searchOrganization
        }
    );

    useEffect(() => {
        console.log("call function here");
        fetchAppliedUserData(searchParam, dataSort, activePage, perPage);
    }, [searchParam, dataSort, activePage, listLimit]);

    // function handleShow(action, openingId) {
    //     const opening_id = openingId ? openingId : 0;
    //     setOpeningId(opening_id);
    //     setJobTitle("");
    //     setStatus("");
    //     setTotalOpenings("");
    //     // if (opening_id !== 0) {
    //     //     fetchOpeningsById(opening_id);
    //     // }
    //     setAction(action)
    //     setShow(true);
    // }


    async function OpeningsSearch() {
        setSearchParam({
            "srchName": searchName,
            "srchEmail": searchEmail,
            "srchPhoneNumber": searchPhoneNumber,
            "srchOrganization": searchOrganization
        })
        setDataSort({
            order: "desc",
            order_by: "id"
        });
        setActivePage("1");
    }

    function clearSearch() {
        setSearchName('');
        setSearchPhoneNumber('');
        setSearchEmail('');
        setSearchOrganization('');

        setSearchParam({
            "srchName": '',
            "srchEmail": '',
            "srchPhoneNumber": '',
            "srchOrganization": ''
        })
        setDataSort({
            order: "desc",
            order_by: "id"
        });
        setActivePage("1");
    }

    const handleClose = () => {
        setShow(false);
        setOpeningId(0);
        setJobTitle("");
        setStatus("");
        setTotalOpenings("");
    }

    const viewResume = (applicantName, resume_path) => {
        console.log("resume_path >>> ");
        console.log(resume_path);
        setApplicantName(applicantName);
        setResumePath(resume_path);
        setResumeShow(true);
    }

    const handleResumeClose = () => {
        setResumePath('');
        setResumeShow(false);
    }
    // function deleteHandler(delete_opening_id) {
    //     Swal.fire({
    // 		title: 'Are you sure?',
    // 		text: "Do you want to delete this openings?",
    // 		icon: 'warning',
    // 		showCancelButton: true,
    // 		confirmButtonColor: '#3085d6',
    // 		cancelButtonColor: '#d33',
    // 		confirmButtonText: 'Yes, delete it!'
    // 	}).then((result) => {
    // 		if (result.isConfirmed) {
    // 			setLoading(true);
    //             if (delete_opening_id > 0) {
    //                 setLoading(true);
    //                 axios
    //                     .delete(CONST.DELETE_OPENINGS, {
    //                         headers: {
    //                             'Authorization': `Bearer ${isToken}`
    //                         },
    //                         data: {
    //                             opening_id: delete_opening_id
    //                         }
    //                     })
    //                     .then((response) => {

    //                         if (response) {
    //                             if (response.data.status == 'error') {
    //                                 toast.error(response.data.message);
    //                             } else {
    //                                 toast.success(response.data.message);
    //                                 fetchAppliedUserData(searchParam, dataSort, activePage, perPage);
    //                             }
    //                         }
    //                     })
    //                     .catch((error) => {
    //                         setLoading(false);
    //                         toast.error("Something went wrong. Please try again." + error);
    //                     });
    //                 setLoading(false);
    //             }
    // 		}
    // 	})
    // }

    //fetch all openings data by id
    // async function fetchOpeningsById(openingId) {
    //     setLoading(true);
    //     await axios
    //         .get(CONST.GET_OPENINGS_BY_ID + `${openingId}`, {
    //             headers: {
    //                 'Authorization': `Bearer ${isToken}`
    //             }
    //         })
    //         .then((response) => {
    //             const status = response.data.status;

    //             if (status === 'success') {
    //                 let openingsData = response.data.data[0] ? response.data.data[0] : [];
    //                 setJobTitle(openingsData['name'] ? openingsData['name'] : '' );
    //                 setOpeningId(openingsData['e_did'] ? openingsData['e_did'] : '');
    //                 setStatus(openingsData['status'] ? openingsData['status'] : '');
    //                 setTotalOpenings(openingsData['leaves_allocated'] ? openingsData['leaves_allocated'] : '');
    //             }
    //         })
    //         .catch((error) => {
    //             alert(error);
    //         });
    //     setLoading(false);
    // }

    //fetch all designation details
    async function fetchAppliedUserData(paramSearch, paramSort, paramPage, paramLimit) {
        console.log("user application call");
        console.log("id >>> " + id);
        setLoading(true);
        // let searchParams = JSON.stringify({
        //     'limit'         : paramLimit,
        //     'search_input'  : paramSearch,
        //     'page_number'   : paramPage,
        //     'sort'          : paramSort
        // })
        await axios
            .post(CONST.GET_APPLIED_USER, {
                'id': user_id,
                'openingId': id,
                'limit': paramLimit,
                'search_input': paramSearch,
                'page_number': paramPage,
                'sort': paramSort
            }, {
                headers: {
                    'Authorization': `Bearer ${isToken}`
                }
            })

            .then((response) => {
                const status = response.data.status;
                console.log(response.data.data)
                if (status === 'success') {
                    console.log(response.data.data)
                    setData(response.data.data);
                    setTotalRows(response.data.count);
                    settotalRecords(response.data.count);
                }
                else {
                    toast.error(response.data.message);
                }
            })
            .catch((error) => {
            });
        setLoading(false);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let isValid = true;

        let validator = Form.validator({
            jobTitle: {
                value: jobTitle,
                isRequired: true,
                label: "Designation Name",
            },
            openingId: {
                value: openingId,
                isRequired: true,
                label: "Department Name",
            },
            totalOpenings: {
                value: totalOpenings,
                isNumber: true,
                isRequired: true,
                label: "Total Leaves",
            },
        });

        if (validator !== null) {
            setValidate({
                validate: validator.errors,
            });

            isValid = false;
        }

        if (isValid) {
            let apiUrl = '';
            let sucessMessage = '';
            let strMethod = '';
            if (openingId == 0) {
                apiUrl = CONST.ADD_DESIGNATION;
                strMethod = 'post';
                sucessMessage = 'Designation added succesfully';
            } else {
                apiUrl = CONST.EDIT_DESIGNATION;
                strMethod = 'put';
                sucessMessage = 'Designation updated succesfully';
            }

            await axios({
                method: strMethod,
                url: apiUrl,
                data: {
                    name: jobTitle,
                    e_did: openingId,
                    status: status === true ? "1" : "0",
                    leaves_allocated: totalOpenings,
                    desg_id: openingId,
                },
                headers: {
                    'Authorization': `Bearer ${isToken}`
                }
            })
                .then((response) => {
                    if (response) {
                        if (response.data.status == 'error') {
                            toast.error("Something went wrong. Please try again.");
                        } else {
                            toast.success(sucessMessage);
                            fetchAppliedUserData(searchParam, dataSort, activePage, perPage);
                            handleClose();
                        }
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    toast.error("Something went wrong. Please try again.");
                });
        }
    }

    const handleChangeStatus = async (e) => {

        e.preventDefault();
        let isValid = true;

        let validator = Form.validator({
            status: {
                value: status,
                isRequired: true,
                label: "Status",
            },
        });

        if (validator !== null) {
            setValidate({
                validate: validator.errors,
            });
            isValid = false;
        }

        if (isValid) {
            Swal.fire({
                title: 'Are you sure?',
                text: "Do you want to change the status?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, change it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    setLoading(true);
                    axios.post(CONST.CHANGE_APPLICATION_STATUS,
                        {
                            'userId': user_id,
                            'applicationId': applyId,
                            'openingId': id,
                            'status': status,
                        },
                        {
                            headers: {
                                'Authorization': `Basic ${isToken}`
                            }
                        }
                    )
                        .then((response) => {
                            const status = response.data.status;
                            if (status == 'success') {
                                toast.success(response.data.message);
                            } else {
                                toast.error(response.data.message);
                            }
                        })
                        .catch((error) => {
                            toast.error("There was an error.");
                        });
                    setLoading(false);

                }
            })
        }
    }

    const columns = [
        {
            name: 'Sr. No',
            selector: (row, index) => index + 1,
            wrap: true,
            width: '4%',
        },
        {
            name: 'Name',
            selector: (row) => row.name,
            wrap: true,
            width: '10%',
        },
        {
            name: 'email',
            selector: (row) => row.email,
            wrap: true,
            width: '20%',
        },
        {
            name: 'Address',
            selector: (row) => row.address,
            wrap: true,

        },
        {
            name: 'Current Organization',
            selector: (row) => row.current_organization,
            wrap: true,

        },
        {
            name: 'Experience',
            selector: (row) => row.experience,
            wrap: true,

        },
        {
            name: 'Phone Number',
            selector: (row) => row.phone_number,
            wrap: true,
        },
        {
            name: 'Notice Period',
            selector: (row) => row.notice_period,
            wrap: true,
        },
        {
            name: 'Resume',
            selector: (row) => <div className="col-md-8 mt-4">
                <img src={viewImg} alt={viewImg} width={35} onClick={() => viewResume(row.name, CONST.HOST + row.resume_path)} />  </div>,
            wrap: true,
            width: '7%',
        },
        {
            name: 'Status',
            width: "7%",
            wrap: true,
            sortable: false,
            cell: (row) => <Action row={row} />,
            
        },

    ]

    const Action = ({ row }) => (
        <>
            <Link to={'#'} data-id={row.id} onClick={() => handleShowApplyStatus('Edit', row.id)}>
                <img src={editImg} alt='editUser' width={25} className='cursor' />
            </Link>
        </>
    )

    function handleShowApplyStatus(action, applyId) {
        const apply_id = applyId ? applyId : 0;
        setApplyId(apply_id);
        setAction(action)
        setShow(true);
    }

    const handleSort = async (column, sortDirection) => {
        setActivePage(1);
        setSearchParam({
            search: 'no',
            name: '',
            status: '',
        });

        setDataSort({
            order: sortDirection,
            order_by: column.sortField
        })
        /*  setLoading(false); */

    };
    /*  PAGINATION FUNCTIONS  */
    const handlePageChange = async (page) => {
        setActivePage(parseInt(page));
    };

    const BootyPagination = ({
        rowsPerPage,
        rowCount,
        onChangePage,
        onChangeRowsPerPage, // available but not used here
        currentPage,
    }) => {

        function toPages(pages) {
            const results = [];
            for (let i = 1; i <= pages; i++) {
                results.push(i);
            }
            return results;
        }
        function getNumberOfPages(rowCount, rowsPerPage) {
            return Math.ceil(rowCount / rowsPerPage);
        }


        const handleBackButtonClick = async () => {
            if ((currentPage - 1) % pageNumberLimit === 0) {
                setMaxPageLimit(maxPageLimit - pageNumberLimit);
                setMinPageLimit(minPageLimit - pageNumberLimit);
            }
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = async () => {
            if (currentPage + 1 > maxPageLimit) {
                setMaxPageLimit(maxPageLimit + pageNumberLimit);
                setMinPageLimit(minPageLimit + pageNumberLimit);
            }

            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        let paginationText = "Page:  " + currentPage + ", Showing ";

        paginationText =
            paginationText +
            ((currentPage - 1) * perPage + 1) +
            " to " +
            perPage * currentPage;
        paginationText = paginationText + " of " + rowCount + " entries;";

        const pages = getNumberOfPages(rowCount, rowsPerPage);
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === pageItems.length;
        const previosDisabled = currentPage === 1;

        const pageNumbers = pageItems.map((page) => {
            if (page <= maxPageLimit && page > minPageLimit) {
                return (
                    <li key={page}
                        id={page}
                        className={`page-item ${currentPage === page ? "active" : ""}`}>
                        <button
                            className={`page-link ${currentPage === page ? "active" : ""}`}
                            onClick={handlePageNumber}
                            value={page} >
                            {page}
                        </button>
                    </li>
                );
            } else {
                return null;
            }
        });
        let pageIncrementEllipses = null;
        if (pageItems.length > maxPageLimit) {
            pageIncrementEllipses = (
                <li className="page-item" onClick={handleNextButtonClick}>
                    &hellip;
                </li>
            );
        }
        let pageDecremenEllipses = null;
        if (minPageLimit >= 1) {
            pageDecremenEllipses = (
                <li className="page-item" onClick={handleBackButtonClick}>
                    &hellip;
                </li>
            );
        }
        return (
            <div className="card-footer d-flex align-items-center">
                <p className="m-0 text-muted">{paginationText}</p>
                <ul className="pagination m-0 ms-auto">
                    <li className="page-item">
                        <button
                            className="page-link"
                            onClick={handleBackButtonClick}
                            disabled={previosDisabled}
                            aria-disabled={previosDisabled}
                            aria-label="previous page"
                            pagerangedisplayed={10}
                        >
                            «
                        </button>
                    </li>
                    {pageDecremenEllipses}
                    {pageNumbers}
                    {pageIncrementEllipses}
                    <li className="page-item">
                        <button
                            className="page-link"
                            onClick={handleNextButtonClick}
                            disabled={nextDisabled}
                            aria-disabled={nextDisabled}
                            aria-label="next page">
                            »
                        </button>
                    </li>
                </ul >
            </div >
        );
    };
    /* END DATATABLE */
    const CustomLoader = () => (
        <div style={{ padding: "24px" }}>
            <DivLoader color="primary" />
        </div>
    );
    return (
        <>
            <Loading
                loading={fullLoading}
                background="rgba(0, 0, 0, 0.5)"
                loaderColor="#fff"
            />
            <HeaderSection
                title="Manage"
                heading="Applications"
                addLink={CONST.ADMIN_PRE + 'openings'}
                addLinkName="Openings"
                linktype="view"
            />
            <div className="page-body">
                <div className="container-xl">



                    <div className="row row-cards">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title"> Listing </h3>
                                </div>

                                <div className="card-body border-bottom py-3">
                                    <div className="row">
                                        <div className="col-md-2">
                                            <div className="form-group mb-3 ">
                                                <label className="form-label required">Name</label>
                                                <div>
                                                    <input
                                                        type="text"
                                                        name="searchName"
                                                        id="searchName"
                                                        className={`form-control`}
                                                        aria-describedby=""
                                                        placeholder="Enter Name"
                                                        onChange={(e) => setSearchName(e.target.value)}
                                                        value={searchName}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group mb-3 ">
                                                <label className="form-label required">Email</label>
                                                <div>
                                                    <input
                                                        type="text"
                                                        name="searchEmail"
                                                        id="searchEmail"
                                                        className={`form-control`}
                                                        aria-describedby=""
                                                        placeholder="Enter Email"
                                                        onChange={(e) => setSearchEmail(e.target.value)}
                                                        value={searchEmail}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-2">
                                            <div className="form-group mb-3 ">
                                                <label className="form-label required">Organization</label>
                                                <div>
                                                    <input type="text"
                                                        name="searchOrganization"
                                                        id="searchOrganization"
                                                        className={`form-control`}
                                                        aria-describedby=""
                                                        placeholder="Enter Organization"
                                                        onChange={(e) => setSearchOrganization(e.target.value)} value={searchOrganization} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-2">
                                            <label className="form-label required">
                                                Phone Number
                                            </label>
                                            <div>
                                                <input type="text" name="searchPhoneNumber" id="searchPhoneNumber"
                                                    className={`form-control ${validate.validate && validate.validate.searchPhoneNumber
                                                        ? "is-invalid" : ""}`}
                                                    aria-describedby="searchPhoneNumber"
                                                    placeholder="Enter Phone Number"
                                                    onChange={(e) => setSearchPhoneNumber(e.target.value)}
                                                    value={searchPhoneNumber} />
                                            </div>

                                        </div>

                                        <div className="col-md-3">
                                            <div className="form-group mb-3 ">
                                                <label className="form-label">&nbsp;</label>
                                                <div>
                                                    <button
                                                        type="button"
                                                        name="btnSearch"
                                                        id="btnSearch"
                                                        className="btn btn-blue d-none d-sm-inline-block ml-3"
                                                        onClick={OpeningsSearch}
                                                    ><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <circle cx="10" cy="10" r="7"></circle>
                                                            <line x1="21" y1="21" x2="15" y2="15"></line>
                                                        </svg>Search</button>&nbsp;
                                                    <button
                                                        type="button"
                                                        name="btnReset"
                                                        id="btnReset"
                                                        className="btn btn-outline-primary "
                                                        onClick={clearSearch}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <line x1="18" y1="6" x2="6" y2="18"></line>
                                                            <line x1="6" y1="6" x2="18" y2="18"></line>
                                                        </svg>Clear</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">

                                    <DataTable
                                        columns={columns}
                                        data={data}
                                        progressPending={loading}
                                        progressComponent={<CustomLoader />}
                                        pagination="true"
                                        paginationServer
                                        paginationTotalRows={totalRows}
                                        //onChangeRowsPerPage={handlePerRowsChange}
                                        onChangePage={handlePageChange}
                                        paginationPerPage={perPage}
                                        paginationComponent={BootyPagination}
                                        sortServer
                                        onSort={handleSort}
                                    />

                                </div>
                                <div className="card-footer d-flex align-items-center">
                                    <p className="m-0 text-muted">
                                        Showing {/* <span>1</span> to <span>8</span> of{" "} */}
                                        <span>{totalRecords}</span> entries
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Change Application Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <form method="POST" onSubmit={handleChangeStatus} autoComplete={"off"}>
                    <div className="row">
                        <div className="col-md-6">
                            <label className="form-label required">
                                Change Status
                            </label>

                            <select
                                className={`form-select form-control ${validate.validate && validate.validate.status
                                    ? "is-invalid "
                                    : ""
                                    }`}
                                name="status"
                                id="status"
                                placeholder="Select status"
                                onChange={(e) => setStatus(e.target.value)}
                                value={status}>
                                <option value="">Select</option>
                                <option value="Accepted">Accepted</option>
                                <option value="Rejected">Rejected</option>
                                <option value="Selected">Selected</option>
                                </select>

                        </div>
                    </div>

                <input type="hidden" name="openingId" id="openingId" value={openingId} />
                </form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleChangeStatus}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={resumeShow} onHide={handleResumeClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{applicantName} resume</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <object width="100%" height="400" data={resumePath} type="application/pdf">
                    </object>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default List