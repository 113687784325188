import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";

const ViewExemptions = ({ closeViewExemptionForm, data }) => {
  const [view, setView] = useState("view");
  const [typeLisiting, setTypeLisiting] = useState([
    {
      id: "1",
      name: "80C",
    },
    {
      id: "2",
      name: "80D",
    },
    {
      id: "3",
      name: "80E",
    },
    {
      id: "4",
      name: "80G",
    },
    {
      id: "5",
      name: "80TTA",
    },
  ]);

  return (
    <>
      <Modal
        size="lg"
        show={view}
        onHide={() => closeViewExemptionForm("hide")}
        backdrop="static"
        keyboard={false}
        centered
        fullscreen={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>View Exemption</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {data.e_tax_exemptions.map((exemption, index) => (
              <div key={index} className={`${index > 0  ? 'mt-2' : ''}`}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mb-3 ">
                      <label className="form-label required">
                        Exemption Type
                      </label>
                      <div>
                        {typeLisiting ? (
                          <>
                            <select
                              disabled={true}
                              className="form-select form-control"
                              name="srchType"
                              id="srchType"
                              placeholder="Select type"
                              value={exemption.exemption_type}
                              style={{background: 'white'}}
                            >
                              {typeLisiting &&
                                typeLisiting.map((element) => (
                                  <option key={element.id} value={element.id}>
                                    {element.name}
                                  </option>
                                ))}
                            </select>
                          </>
                        ) : (
                          {}
                          //   <DivSpinner size="sm" color="primary" />'
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3 ">
                      <label className="form-label required">Name</label>
                      <div className="form-control"> {exemption.name}</div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mb-3 ">
                      <label className="form-label required">
                        Total Amount
                      </label>
                      <div className="form-control">
                        {exemption.total_amount.toLocaleString("en-IN",{
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3 ">
                      <label className="form-label required">Year</label>
                      <div className="form-control">{exemption.year}</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <label className="form-label required">
                          Description
                        </label>
                        <textarea
                          name="description"
                          className="form-group w-100"
                          placeholder="Enter description"
                          rows="4"
                          value={exemption.description}
                          disabled={true}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <hr></hr>
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewExemptions;
<h1>view exemption</h1>;
