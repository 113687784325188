import axios from "axios";
import React, { useContext, useEffect, useState, useRef, useMemo } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import Form from "../../../component/utilities/Forms";
import * as CONST from "../../../component/config/Constant";
import { AuthContext } from "../../../component/context/AuthContext";
import { toast } from "react-toastify";
import DivSpinner from "../../../component/utilities/Spinner";
import "react-datetime/css/react-datetime.css";

function EditExemption({ closeEditEventForm, eventID }) {
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  const [exemption_type, setExemotionType] = useState("");
  const [name, setName] = useState("");
  const [total_amount, setTotalAmount] = useState("");
  const [year, setYear] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [validate, setValidate] = useState({});
  const [exmId, setExmId] = useState("");
  const [typeLisiting, setTypeLisiting] = useState([
    {
      id: "1",
      name: "80C",
    },
    {
      id: "2",
      name: "80D",
    },
    {
      id: "3",
      name: "80E",
    },
    {
      id: "4",
      name: "80G",
    },
    {
      id: "5",
      name: "80TTA",
    },
  ]);



  const [edit, setEdit] = useState("edit");
 
  const handleEdit = () => setEdit(true);
  
  let isToken = "";
  let user_id = "";
  if (authUserData) {
    if (authUserData[0]) {
      isToken = authUserData[0].token ? authUserData[0].token : "";
      user_id = authUserData[0].user_id ? authUserData[0].user_id : "";
    }
  }



  const validateEvent = async () => {
    let isValid = true;

    let validator = Form.validator({
      exemption_type: {
        value: exemption_type,
        isRequired: true,
        isEmail: false,
        label: "Exemption Type",
      },
      name: {
        value: name,
        isRequired: true,
        isEmail: false,
        label: "Name",
      },
      total_amount: {
        value: total_amount,
        isRequired: true,
        isEmail: false,
        label: "total amount",
      },
      description: {
        value: description,
        isRequired: true,
        isEmail: false,
        label: "description",
      },
      
      year: {
        value: year,
        isRequired: true,
        isEmail: false,
        label: "Year",
      },
      // images: {
      // 	value: images,
      // 	isRequired: true,
      // 	isEmail: false,
      //     isImage: true,
      // 	label: "Images",
      // },
    });

    if (validator !== null) {
      setValidate({
        validate: validator.errors,
      });
      console.log("validate event : " + JSON.stringify(validate));
      isValid = false;
    } else {
      setValidate({
        validate: "",
      });
      isValid = true;
    }
    return isValid;
  };
  
  const handleEditFrm = async (e) => {
    e.preventDefault();
    const validate = await validateEvent();
    if (validate) {
     
      setLoading(false);
      await axios({
        method: "put",
        url: CONST.UPDATE_TAX_EXEMOTIONS,
        headers: {
          Authorization: `Bearer ${isToken}`,
          'Content-Type': 'application/json',
        },
        data: {
          emp_id: user_id,
          name: name,
          description: description,
          year: year,
          exemption_type: exemption_type,
          total_amount: total_amount,
          exemption_id: exmId,
        },
      })
        .then((response) => {
          console.log("event add res >>" + response);
          const status = response.data.status;
          setLoading(false);
          if (status === "error") {
            const errorMsg = response.data.errors;
            let isArray = Array.isArray(errorMsg);
            if (isArray) {
              errorMsg.map((element) => {
                toast.error(element.msg);
              });
            } else {
              toast.error(errorMsg);
            }
          } else {
            toast.success("Event added sucessfully!");
            window.location.reload(false);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error);
        });
    }
  };

  useEffect(() => {
    // fetchCategoryData();
    // fetchEventData();
    setExmptionData();
  }, []);

  async function setExmptionData() {
    setLoading(true);
    setExmId(eventID.id ? eventID.id :'');
    setExemotionType(eventID.exemption_type ? eventID.exemption_type :'');
    setName(eventID.name ? eventID.name :'');
    setTotalAmount(eventID.total_amount ? eventID.total_amount :'');
    setYear(eventID.year ? eventID.year :'');
    setDescription(eventID.description ? eventID.description :'');
    setLoading(false);
  }

  // async function fetchEventData() {
  //   setLoading(true);
  //   await axios({
  //     method: "post",
  //     url: CONST.GET_EVENT_BY_ID,
  //     headers: {
  //       Authorization: `Bearer ${isToken}`,
  //     },
  //     data: {
  //       id: user_id,
  //       event_id: eventID,
  //     },
  //   })
  //     .then((response) => {
  //       const status = response.data.status;
  //       if (status === "success") {
  //         setLoading(true);
  //         setImages(response.data.data.e_event_images);
  //         setTitle(
  //           response.data && response.data.data.title
  //             ? response.data.data.title
  //             : ""
  //         );
  //         setDescription(
  //           response.data && response.data.data.description
  //             ? response.data.data.description
  //             : ""
  //         );
  //         setCategoryId(
  //           response.data && response.data.data.category_id
  //             ? response.data.data.category_id
  //             : ""
  //         );
  //         setEventDate(
  //           response.data && response.data.data.event_date
  //             ? moment(response.data.data.event_date).format("YYYY-MM-DD")
  //             : ""
  //         );
  //         setStatus(response.data.data.status);
  //         setLoading(false);
  //       } else {
  //         toast.error(response.data.message);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  //   setLoading(false);
  // }

  

  return (
    <>
      <Modal
        size="lg"
        show={edit}
        onHide={() => closeEditEventForm("hide")}
        backdrop="static"
        keyboard={false}
        centered
        fullscreen={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Exemption</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleEditFrm} encType="multipart/form-data">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group mb-3 ">
                  <label className="form-label required">Exemption Type</label>
                  <div>
                  <select
                    name="exemption_type"
                    id="exemption_type"
                    value={exemption_type}
                    className={`form-control ${validate.validate && validate.exemption_type
                        ? "is-invalid "
                        : ""
                        }`}
                    aria-describedby="emailHelp"
                    placeholder="Enter Name"
                    onChange={(e) => setExemotionType(e.target.value)}
                    >
                      <option value="">Select</option>
                      {
                        typeLisiting && typeLisiting.map((element)=>(
                          <option key={element.id} value={element.id}>{element.name}</option>
                        ))
                      }
                  </select>
                    
                  </div>
                  <div
                    className={`invalid-feedback text-start ${
                      validate.validate && validate.validate.exemption_type
                        ? "d-block"
                        : "d-none"
                    }`}
                  >
                    {validate.validate && validate.validate.exemption_type
                      ? validate.validate.exemption_type[0]
                      : ""}{" "}
                  </div>{" "}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group mb-3 ">
                  <label className="form-label required">Name</label>
                  <div>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className={`form-control ${
                        validate.validate && validate.validate.name
                          ? "is-invalid "
                          : ""
                      }`}
                      aria-describedby="emailHelp"
                      placeholder="Enter name"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                  </div>
                  <div
                    className={`invalid-feedback text-start ${
                      validate.validate && validate.validate.name
                        ? "d-block"
                        : "d-none"
                    }`}
                  >
                    {validate.validate && validate.validate.name
                      ? validate.validate.name[0]
                      : ""}{" "}
                  </div>{" "}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group mb-3 ">
                  <label className="form-label required"> Total amount</label>
                  <div>
                    <input
                      type="text"
                      name="total_amount"
                      id="total_amount"
                      className={`form-control ${
                        validate.validate && validate.validate.total_amount
                          ? "is-invalid "
                          : ""
                      }`}
                      aria-describedby="emailHelp"
                      placeholder="Enter Total amount"
                      onChange={(e) => setTotalAmount(e.target.value)}
                      value={total_amount}
                    />
                  </div>
                  <div
                    className={`invalid-feedback text-start ${
                      validate.validate && validate.validate.total_amount
                        ? "d-block"
                        : "d-none"
                    }`}
                  >
                    {validate.validate && validate.validate.total_amount
                      ? validate.validate.total_amount[0]
                      : ""}{" "}
                  </div>{" "}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group mb-3 ">
                  <label className="form-label required"> year</label>
                  <div>
                  <select
                    name="year"
                    value={year}
                    id="year"
                    className={`form-control ${validate.validate && validate.year
                        ? "is-invalid "
                        : ""
                        }`}
                    aria-describedby="emailHelp"
                    onChange={(e) => setYear(e.target.value)}
                    >
                    <option value="">Select Year</option>
                    {Array.from({ length: 8 }, (_, index) => {
                        const startYear =
                        new Date().getFullYear() - 5 + index;
                        const endYear = startYear + 1;
                        const formattedYear = `${startYear}-${endYear}`;

                        return (
                        <option key={formattedYear} value={formattedYear}>
                            {formattedYear}
                        </option>
                        );
                    })}
                  </select> 
                  </div>
                  <div
                    className={`invalid-feedback text-start ${
                      validate.validate && validate.validate.year
                        ? "d-block"
                        : "d-none"
                    }`}
                  >
                    {validate.validate && validate.validate.year
                      ? validate.validate.year[0]
                      : ""}{" "}
                  </div>{" "}
                </div>
              </div>

              <div className="col-md-12">
                <div className="col-md-12">
                  <div className="form-group mb-3 ">
                    <label className="form-label required">Description</label>
                    <div>
                      <input
                        type="text-area"
                        rows="4"
                        name="description"
                        id="description"
                        className={`form-control ${
                          validate.validate && validate.validate.description
                            ? "is-invalid "
                            : ""
                        }`}
                        aria-describedby="emailHelp"
                        placeholder="Enter description"
                        onChange={(e) => setDescription(e.target.value)}
                        value={description}
                      />
                    </div>
                    <div
                      className={`invalid-feedback text-start ${
                        validate.validate && validate.validate.description
                          ? "d-block"
                          : "d-none"
                      }`}
                    >
                      {validate.validate && validate.validate.description
                        ? validate.validate.description[0]
                        : ""}{" "}
                    </div>{" "}
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-footer">
                  <button type="submit" className="mr-3 btn btn-primary">
                    Update
                  </button>
                  &nbsp;&nbsp;
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default EditExemption;
