import axios from 'axios';
import React, { useContext, useEffect, useState, useRef, useMemo } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Routes, Route, useNavigate } from 'react-router-dom';
import Form from '../../../component/utilities/Forms';
import * as CONST from "../../../component/config/Constant";
import { AuthContext } from '../../../component/context/AuthContext';
import { toast } from "react-toastify";
import DivSpinner from '../../../component/utilities/Spinner';

import DatePicker from 'react-datetime';
import moment from 'moment';
import 'react-datetime/css/react-datetime.css';

import JoditEditor from 'jodit-react';

function AddEvent({ closeEventForm }) {
	const [authUserData, setAuthUserData] = useContext(AuthContext);
	const [title, setTitle] = useState("");
	const [categoryId, setCategoryId] = useState("");
	const [description, setDescription] = useState("");
	const [eventDate, setEventDate] = useState(new Date());
	const [images, setImages] = useState({})
	const [status, setStatus] = useState("");
	const [validate, setValidate] = useState({});
	const [show, setShow] = useState('show');
	const [loading, setLoading] = useState(false);
	const handleShow = () => setShow(true);
	const [catLisiting, setCatLisiting] = useState('');
	const today = new Date();
	const navigate = useNavigate();
	//const handleChange = (date) => setDateOfBirth(date);
	let isToken = '';
	let user_id = '';
	if (authUserData) {
		if (authUserData[0]) {
			isToken = authUserData[0].token ? authUserData[0].token : "";
			user_id = authUserData[0].user_id ? authUserData[0].user_id : "";
		}
	}

	const editor = useRef(null);

	const config = useMemo(
		() => ({
			readonly: false,
			uploader: { "insertImageAsBase64URI": true }
		}),
		[]
	);

	const validateEvent = async () => {
		let isValid = true;

		let validator = Form.validator({
			title: {
				value: title,
				isRequired: true,
				isEmail: false,
				label: "Event Name",
			},
			description: {
				value: description,
				isRequired: true,
				isEmail: false,
				label: "description",
			},
			categoryId: {
				value: categoryId,
				isRequired: true,
				isEmail: false,
				label: "categoryId",
			},
			eventDate: {
				value: eventDate,
				isRequired: true,
				isEmail: false,
				label: "Event Date",
			},
			status: {
				value: status,
				isRequired: true,
				isEmail: false,
				label: "status",
			},
			// images: {
			// 	value: images,
			// 	isRequired: true,
			// 	isEmail: false,
			//     isImage: true,
			// 	label: "Images",
			// },

		});

		if (validator !== null) {
			setValidate({
				validate: validator.errors,
			});
			console.log('validate event : ' + JSON.stringify(validate));
			isValid = false;
		} else {
			setValidate({
				validate: "",
			});
			isValid = true;
		}
		return isValid;
	};
	const selectFiles = (event) => {
		setImages(event.target.files);
	};
	const handleEditFrm = async (e) => {
		e.preventDefault();
		const validate = await validateEvent();

		if (validate) {

			setLoading(false);
			await axios({
				method: 'post',
				url: CONST.ADD_EVENT,
				headers: {
					'Authorization': `Bearer ${isToken}`,
					'Content-Type': 'multipart/form-data'
				},
				data: {
					'id': user_id,
					'title': title,
					'description': description,
					'category_id': categoryId,
					'event_date': moment(eventDate).format('YYYY-MM-DD'),
					'images[]': images,
					'status': status,
				}

			})
				.then((response) => {
					console.log('event add res >>' + response);
					const status = response.data.status;
					setLoading(false);
					if (status === 'error') {
						const errorMsg = response.data.errors;
						let isArray = Array.isArray(errorMsg);
						if (isArray) {
							errorMsg.map(element => {
								toast.error(element.msg);
							})
						}
						else {
							toast.error(errorMsg);
						}
					} else {
						toast.success('Event added sucessfully!');
						window.location.reload(false);
						//navigate('/admin/events');
					}
				})
				.catch((error) => {
					setLoading(false);
					toast.error(error);
				});
		}
	}

	useEffect(() => {
		fetchCategoryData();
	}, [])

	async function fetchCategoryData() {
		setLoading(true);
		await axios({
			method: 'post',
			url: CONST.GET_EVENT_CATEGORY_LIST,
			headers: {
				'Authorization': `Bearer ${isToken}`
			}

		})
			.then((response) => {
				//console.log('Cat-list Res- '+JSON.stringify(response.data));
				const status = response.data.status;

				if (status === 'success') {
					setCatLisiting(response.data.data);
				} else {
					toast.error(response.data.message);
				}
			})
			.catch((error) => {
				console.log(error);
			});
		setLoading(false);
	}



	return (
		<>
			<Modal
				size="lg"
				show={show}
				onHide={() => closeEventForm('hide')}
				backdrop="static"
				keyboard={false}
				centered
				fullscreen={true}
			>
				<Modal.Header closeButton>
					<Modal.Title>Add Event</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleEditFrm} enctype="multipart/form-data">
						<div className="row">
							<div className="col-md-12">
								<div className="form-group mb-3 ">
									<label className="form-label required">
										Event Name
									</label>
									<div>
										<input
											type="text"
											name="title"
											id="title"
											className={`form-control ${validate.validate && validate.validate.title
												? "is-invalid "
												: ""
												}`}
											aria-describedby="emailHelp"
											placeholder="Enter event name"
											onChange={(e) => setTitle(e.target.value)}
											value={title}
										/>
									</div>
									<div
										className={`invalid-feedback text-start ${validate.validate && validate.validate.title
											? "d-block"
											: "d-none"
											}`}
									>
										{validate.validate && validate.validate.title
											? validate.validate.title[0]
											: ""}{" "}
									</div>{" "}
								</div>
							</div>

							<div className="col-md-4">
								<div className="form-group mb-3 ">
									<label className="form-label required">Event Date</label>
									<div>
										<DatePicker
											name="eventDate"
											id='eventDate'
											value={eventDate}
											selected={eventDate}
											timeFormat=""
											dateFormat="DD-MM-YYYY"
											disabledDays={{ before: today }}
											className={`${validate.validate && validate.validate.eventDate
												? "is-invalid "
												: ""
												}`}
											onChange={(date) => setEventDate(date)}
										/>
										<div
											className={`invalid-feedback text-start mb-2 ${validate.validate && validate.validate.eventDate
												? "d-block"
												: "d-none"
												}`}
										>
											{validate.validate && validate.validate.eventDate
												? validate.validate.eventDate[0]
												: ""}
										</div>
									</div>
								</div>
							</div>

							{
								catLisiting &&
								(
									<div className="col-md-4">
										<div className="mb-3">
											<label className="form-label required">Category</label>
											<select
												className={`form-select form-control ${validate.validate && validate.validate.categoryId
													? "is-invalid "
													: ""
													}`}
												name="categoryId"
												id="categoryId"
												placeholder="Select category"
												onChange={(e) => setCategoryId(e.target.value)}
												value={categoryId}
											>
												<option value="">Select</option>
												{
													catLisiting && catLisiting.map((element, index) => {
														return (
															<option
																value={element.id}
																key={index}
															>{element.name}</option>

														);
													})
												}
											</select>
											<div
												className={`invalid-feedback text-start ${validate.validate && validate.validate.designation
													? "d-block"
													: "d-none"
													}`}
											>
												{validate.validate && validate.validate.designation
													? validate.validate.designation[0]
													: ""}
											</div>
										</div>
									</div>
								)
							}
							<div className="col-md-4">
								<div className="mb-3">
									<label className="form-label required">Status</label>
									<select
										className={`form-select form-control ${validate.validate && validate.validate.status
											? "is-invalid "
											: ""
											}`}
										name="status"
										id="status"
										placeholder="Select status"
										onChange={(e) => setStatus(e.target.value)}
										value={status}
									>
										<option value="">Select</option>
										<option value="1">Active</option>
										<option value="0">Deactive</option>
									</select>
									<div
										className={`invalid-feedback text-start ${validate.validate && validate.validate.status
											? "d-block"
											: "d-none"
											}`}
									>
										{validate.validate && validate.validate.status
											? validate.validate.status[0]
											: ""}
									</div>
								</div>
							</div>
							<div className="col-md-12">
								<div className="form-group mb-3 ">
									<label className="form-label required">
										Description
									</label>
									<div>
										{/* <input
											type="text"
											name="description"
											id="description"
											className={`form-control ${validate.validate && validate.validate.description
												? "is-invalid "
												: ""
												}`}
											aria-describedby="emailHelp"
											placeholder="Enter description"
											onChange={(e) => setDescription(e.target.value)}
											value={description}
										/> */}
										<JoditEditor
											ref={editor}
											config={config}
											value={description}
											tabIndex={1} // tabIndex of textarea
											onBlur={newContent => setDescription(newContent)}
											onChange={(newContent) => { }}
										// className={`form-control ${validate.validate && validate.validate.policyDescription ? "is-invalid": "" }`}
										/>
									</div>
									<div
										className={`invalid-feedback text-start ${validate.validate && validate.validate.description
											? "d-block"
											: "d-none"
											}`}
									>
										{validate.validate && validate.validate.description
											? validate.validate.description[0]
											: ""}{" "}
									</div>{" "}
								</div>
							</div>
							<div className="col-md-12">
								<div className="form-group mb-3 ">
									<label className="form-label required">
										Images
									</label>
									<div>
										<input
											type="file"
											name="images"
											id="images"
											className=''
											aria-describedby="emailHelp"
											accept="image/*"
											multiple
											placeholder="Enter images"
											onChange={selectFiles} //{(e) => setImages(e.target.files[0])}

										/>
									</div>

								</div>
							</div>

							<div className="col-md-12">
								<div className="form-footer">
									<button
										type="submit"
										className="mr-3 btn btn-primary"
									>Add</button>

									&nbsp;&nbsp;

								</div>
							</div>
						</div>
					</form>
				</Modal.Body>

			</Modal>
		</>
	)
}

export default AddEvent