import React,{useEffect,useState} from 'react'
import { Link } from 'react-router-dom'
import * as  CONST from "../../../component/config/Constant"
import axios from 'axios';
import parse from 'html-react-parser';
import Moment from "moment";
import { useParams } from 'react-router-dom';
import Pagination from '../../../component/utilities/Pagination';
import DivSpinner from '../../../component/utilities/Spinner';

function List() {
    const [eventList, setEventList] = useState("");
    const [eventCatList, setEventCatList] = useState("");
    const [eventImagesList, setImages] = useState({})
    const params = useParams();
    const categoryId = params.category_id ? params.category_id : 0;
    console.log('cat-param'+JSON.stringify(params.category_id))

    // Pagination
    const [currentPage, setCurrentPage] = useState("1");
    const [totalCount, setTotalCount] = useState(0);
    const [postsPerPage] = useState(4);
    
    // Change page
    const paginate = pageNumber => setCurrentPage(pageNumber);

    const fetchEventData = async (cat_id) => {
        
        await axios
          .post(CONST.WEB_GET_EVENTDATA,
          {
            "sort": {
                "order": "asc",
                "order_by": "id"
            },
            "page_number": currentPage,
            "limit": postsPerPage,
            "category_id": cat_id
          })
        
          .then((response) => {console.log("event by cat: "+JSON.stringify(response.data))
            const status = response.data.status;
            if (status === 'success') {
                setEventList(response.data.data);
                setTotalCount(response.data.count)
                //alert(totalCount)
            }
          })
          .catch((error) => {
            console.log(error);
          });
    }

    const fetchEventCatList = async () => {
        await axios
          .get(CONST.WEB_GET_EVENTCATDATA)
          .then((response) => {           
            const status = response.data.status;
            if (status === 'success') {
                setEventCatList(response.data.data);
                
            }
          })
          .catch((error) => {
            console.log(error);
          });
    }

    useEffect(() => {
        fetchEventData(categoryId);
        fetchEventCatList();
    }, [])

    // Get current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = eventList.slice(indexOfFirstPost, indexOfLastPost);
    //alert(postsPerPage);
    //alert(totalCount);
    return (
        
        <>
            <section className='container'>
                <div className='row'>
                    <div className="col-12">
                        <div className="section-title text-center mb-60 mt-60">
                            <h3 className="mb-15">Events</h3>
                            <p>Morbi et sagittis dui, sed fermentum ante. Pellentesque molestie sit amet dolor vel euismod.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className='event-section pt-100'>
                <div className='container'>
                    <div className="single-blog-wrapper">
                        <div className="row">                          
                        
                            <div className="col-xl-8 col-lg-7">
                                <div className="left-wrapper">
                                {/* {alert(JSON.stringify(eventList.length))} */}
                                    <div className="feature-style bg-white pt-0 pb-60">
                                        <div className="row">
                                       
                                        {    
                                            (eventList && eventList.length > 0)
                                            ?                                        
                                            eventList && eventList.map((element, key) => {
                                                return (
                                            <div className="col-lg-6 col-md-6"> 
                                                <Link to={`/event/${element.id}`}> 
                                                {/*<Link to={`/event/id=${element.id}`}> */}
                                                    <div className="single-feature">
                                                       {
                                                        element.e_event_images && element.e_event_images.map((ele, eKey) => {
                                                           
                                                            
                                                        return (
                                                            (ele.is_featured_image == 1)
                                                            ?
                                                            <>
                                                            <div className="image">                                          
                                                                <img src={CONST.HOST+ele.image_path} class="img-fluid" alt="Responsive image" />
                                                            </div>
                                                            </>
                                                            :
                                                            ''
                                                        )
                                                        
                                                        
                                                        })
                                                        }
                                                        <div className="content s-event">
                                                            <h5>{element.title}</h5>
                                                            <span className="event-dt">
                                                                {element.event_date ? 
                                                                
                                                                Moment(element.event_date).format(
                                                                    "DD, MMM. YYYY "
                                                                )
                                                                : ''}
                                                            </span>
                                                            {parse(element.description.substring(0,100))}
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div> 
                                                )
                                            }
                                            )
                                            :
                                            ( 
                                            <>
                                                <div className="col-lg-12 col-md-12">
                                                    <div className="content s-event">
                                                        <h6 className='center'>No record found!</h6>
                                                    </div>
                                                </div>
                                            </>
                                            )
                                        }

                                        </div>
                                        
                                        {
                                          totalCount > postsPerPage  && (
                                                <>
                                                <Pagination
                                                    postsPerPage={postsPerPage}
                                                    totalPosts={totalCount}
                                                    paginate={paginate}
                                                />
                                                </>
                                            )
                                        }

                                    </div>
                                </div>
                            </div>
                            
                        
                        

                            <div className="col-xl-4 col-lg-5">
                                <aside className="right-wrapper">



                                    <div className="sidebar-box category-box">
                                        <h4>Categories</h4>
                                        <ul>
                                        {
                                            eventCatList
                                            ?
                                            (
                                                eventCatList && eventCatList.map((element, key) => {
                                                    return (
                                                        // <li><a href="#0" onClick={() => fetchEventData(categoryId)}> <span>{element.name}</span> <span>(20)</span> </a></li>
                                                        //<Link to={`/events/${element.id}`} onClick={() => fetchEventData(element.id)} >
                                                        <li>
                                                        <Link to={`/events/`} onClick={() => fetchEventData(element.id)} >
                                                            <span>{element.name}</span> <span>({element.eventCount ? element.eventCount : ''})</span>
                                                        </Link>
                                                        </li>
                                                        

                                                    )
                                                })
                                            )
                                            :
                                            (
                                                <>
                                                <DivSpinner size="sm" color="primary" />
                                                </>
                                            )
                                        }
                                            
                                        </ul>
                                    </div>

                                    {/* <div className="sidebar-box tags-box">
                                        <h4>Popular Tag</h4>
                                        <ul>
                                            <li><a href="#0" className="button button-sm border-button radius-30">Web Design</a></li>
                                            <li><a href="#0" className="button button-sm border-button radius-30">Web Development</a></li>
                                            <li><a href="#0" className="button button-sm border-button radius-30">Mobile App</a></li>
                                            <li><a href="#0" className="button button-sm border-button radius-30">Business Analysis</a></li>
                                            <li><a href="#0" className="button button-sm border-button radius-30">Business Consulted</a></li>
                                            <li><a href="#0" className="button button-sm border-button radius-30">Seo</a></li>
                                            <li><a href="#0" className="button button-sm border-button radius-30">Landing page</a></li>
                                        </ul>
                                    </div> */}
                                </aside>
                            </div>
                        </div>
                    </div></div>
            </section>
        </>
    )
}

export default List