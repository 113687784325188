import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Loading from "react-fullscreen-loading";
import axios from 'axios';
import "../../../assets/css/single-policy.css";
import * as  CONST from "../../../component/config/Constant"
//import parse from 'html-react-parser';
function Single() {
  const [loading, setloaing] = useState(false);
  const [policy, setPolicy] = useState("");
  const params = useParams();
  let slug = params.slug_id;
  let slug_name = params.slug_name;

  const fetchPolicyDetails = async (slug) => {
    await axios
      .get(CONST.WEB_GET_POLICY_DETAILS + slug)
      .then((response) => {
        const status = response.data.status;
        if (status === 'success') {
          setPolicy(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(() => {
    fetchPolicyDetails(slug);
  }, [slug])

  const capitalizeFirst = str => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };


  return (
    <div className='single-policy'>
      <Loading
        loading={loading}
        background="rgba(0, 0, 0, 0.5)"
        loaderColor="#fff"
      />
      {
        policy && policy.map((policy, index) => {
          return (
            <>
              <div class="policy-detail container-fluid inner-header">
                <div class="container text-center">
                  <span class="breadcrumbs">
                    <span property="itemListElement" typeof="ListItem">
                      <h3 className="mb-15 policy-title">{policy.policy_name}</h3>
                      <meta property="position" content="1" />
                    </span>
                  </span>
                </div>
              </div>
              <section className="blog-section pt-5">
                <div className="container">
                  <div className="single-blog-wrapper">
                    <div className="row">

                      <div className="col-xl-4 col-lg-12">
                        <div className="left-wrapper">

                          <div className="blog-content-wrapper">
                            <div className="image mb-35">
                              {

                                policy.policy_image ?
                                  (
                                    <>
                                      <img
                                        src={CONST.HOST + policy.policy_image_path}
                                        alt={policy.policy_name}
                                      />
                                    </>
                                  ) :

                                  (
                                    <>
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/img/gallery/gallery-1/gallery-3.jpg"
                                        }
                                        alt={policy.policy_name}
                                      />
                                    </>
                                  )
                              }
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-xl-8 col-lg-12">
                        <div className="left-wrapper">

                          <div className="blog-content-wrapper">
                            <div dangerouslySetInnerHTML={{ __html: capitalizeFirst(policy.policy_description) }} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section >
            </>
          )

        })
      }

    </div>
  )
}

export default Single