import { React, useState, useEffect, useContext } from "react";
import "../../../assets/css/table.css";
import Loading from "react-fullscreen-loading";
import DivLoader from "../../../component/utilities/Spinner";
import DataTable from "react-data-table-component";
import axios from "axios";
import * as CONST from "../../../component/config/Constant";
import moment from "moment";
import { useParams } from "react-router-dom";
import DatePicker from "react-datetime";

function PaidHolidays() {
  const [fullLoading, setFullLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [listLimit, setListLimit] = useState(50);
  /* START DATATABLE */
  const [perPage, setPerPage] = useState(10);
  //Search
  // const params = useParams();
  const currentYear = new Date().getFullYear();
  const [srchYear, setSrchYear] = useState(currentYear);
  const yearOptions = Array.from({ length: 3 }, (_, index) => currentYear - 1 + index);
  const [holidayData, setholidayData] = useState([]);

  const [dataSort, setHolidayDataSort] = useState({
    order: "desc",
    order_by: "id",
  });

  const [searchParam, setSearchParam] = useState({
    searchYear: srchYear,
  });

  const searchYear = (val) => {
    // if (val != "") {
    //   setSrchYear(moment(val).format("YYYY"));
    // } else {
    //   setSrchYear("");
    // }
    setSrchYear(parseInt(val.target.value, 10));
    setLoading(true);
  };

  useEffect(() => {
    fetchHolidayData(searchParam, dataSort, activePage, perPage);
  }, [searchParam, dataSort, activePage, listLimit, srchYear]);

  //fetch all holiday details

  async function fetchHolidayData(
    paramSearch,
    paramSort,
    paramPage,
    paramLimit
  ) {
    setLoading(true);

    await axios
      .post(CONST.GET_PUBLIC_HOLIDAY_LIST, {
        year: srchYear,
      })

      .then((response) => {
        const status = response.data.status;
        if (status === "success" && response.data.data) {
          // setTotalRows(response.data.count);
          // settotalRecords(response.data.count);
          setholidayData(response.data.data);
        } else {
          // setTotalRows(0);
          // settotalRecords(0);
          setholidayData([]);
        }
      })
      .catch((error) => { });
    setLoading(false);
  }

  const columns = [
    {
      name: "Sr. No",
      selector: (row, index) => index + 1,
      width: "15%",
    },
    {
      name: "Date",
      selector: (row) =>
        moment(row?.holiday_date || "").format("DD, MMM. YYYY "),
      width: "25%",
    },
    {
      name: "Day",
      selector: (row) => row.holiday_day,
      width: "20%",
    },
    {
      name: "Holiday",
      selector: (row) => row.holiday_name,
      width: "25%",
    },
  ];

  /* END DATATABLE */
  const CustomLoader = () => (
    <div style={{ padding: "24px" }}>
      <DivLoader color="primary" />
    </div>
  );
  return (
    <>
      <Loading
        loading={fullLoading}
        background="rgba(0, 0, 0, 0.5)"
        loaderColor="#fff"
      />
      <div className="page-body">
        <div className="container-xl">
          <div className="row row-cards">
            <div className="col-12 ">
              <div className="card  pt-5">
                <div className="row">
                  <div className="col-md-4">
                    <img
                      src='assets/img/holiday.jpg'
                      alt="Image Description"
                      style={{ maxWidth: "100%", height: "auto" }}
                    />
                  </div>
                  <div className="col-md-8"><div>
                    <h1 className="card-title" style={{ marginBottom: "10px" }}>Paid Holiday's {srchYear}</h1>
                    <hr />
                    <div className="col-md-2" style={{ marginLeft: "auto" }}>
                      <div className="mb-2">
                        {/* <DatePicker
                          name="srchYear"
                          value={srchYear}
                          selected={srchYear}
                          dateFormat="YYYY"
                          timeFormat=""
                          className=""
                          onChange={(e) => searchYear(e)}
                        /> */}
                        <select
                          id="yearSelect"
                          value={srchYear}
                          onChange={searchYear}
                        >
                          {yearOptions.map((year) => (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="table-responsive" style={{ width: "100%" }}>
                      <DataTable
                        columns={columns}
                        data={holidayData}
                        progressPending={loading}
                        progressComponent={<CustomLoader />}
                        sortServer
                      // onSort={handleSort}
                      />
                    </div>
                  </div></div>
                </div>
                <div className="card-header">


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaidHolidays;
