import { React, useEffect, useState, useRef } from "react";
import {
  Link,
  useSearchParams,
  useParams,
  Navigate,
  useNavigate,
} from "react-router-dom";
import * as CONST from "../../component/config/Constant";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "react-fullscreen-loading";
import Form from "../../component/utilities/Forms";

export default function PasswordReset(props) {
  let { token, reset_email } = useParams();
  let [isExpired, setIsExpired] = useState(false);
  let [email, setEmail] = useState(reset_email);
  let [password, setPassword] = useState("");
  let [confirmPassword, setConfirmPassword] = useState("");
  let [isPasswordEqual, setIsPasswordEqual] = useState({});
  const [loading, setloaing] = useState(false);
  const [validate, setValidate] = useState({});
  const navigate = useNavigate();
  const dataFetchedRef = useRef(false);
  async function fetchData() {
    if (token) {
      let tokenData = JSON.parse(atob(token.split('.')[1]));
      console.log(tokenData);
      let tokenEmail = tokenData.userEmail ? tokenData.userEmail : "";
      setEmail(tokenEmail);
    }
  }
  useEffect(() => {
    if (dataFetchedRef.current) return;
    dataFetchedRef.current = true;
    fetchData();
  }, []);

  const validateResetPassword = async () => {
    let isValid = true;

    let validator = Form.validator({
      password: {
        value: password,
        isRequired: true,
        minLength: 8,
        label: "new password",
        isSymbol: true,
      },
      confirmPassword: {
        value: confirmPassword,
        isRequired: true,
        minLength: 8,
        isSymbol: true,
        label: "confirm password",
      },
    });

    if (validator !== null) {
      setValidate({
        validate: validator.errors,
      });
      isValid = false;
    } else {
      if (password !== confirmPassword) {
        setIsPasswordEqual({
          status: "yes",
          msg: "The new password and confirm password are not equal",
        });
        isValid = false;
      } else {
        setValidate({
          validate: "",
        });
        isValid = true;
        setIsPasswordEqual({});
      }
    }
    return isValid;
  };

  const submitForm = async (e) => {
    e.preventDefault();
    const validate = await validateResetPassword();

    if (validate) {
      setloaing(true);
      await axios
        .post(CONST.RESET_PASSWORD + '/' + token, {
          email: email,
          new_password: password,
          confirm_password: confirmPassword,
        })
        .then((response) => {
          console.log(response);
          setloaing(false);
          if (response) {
            const status = response.data.status;
            if (status === 'success') {
              toast.success(response.data.message);
              navigate("/login");
            } else {
              toast.error(response.data.message);
            }
          }
        })
        .catch((error) => {
          setloaing(false);
          toast.error("There was an error.");
        });
    } else {
    }
  };
  if (isExpired) {
    return <Navigate to="/forget-password" />;
  } else {
    return (
      <>
        <Loading
          loading={loading}
          background="rgba(0, 0, 0, 0.5)"
          loaderColor="#fff"
        />
        <section className="page page-center login_forgot background_white">
          <img src="/eportal/assets/img/hero/hero-1/shape-1.svg" alt="" className="shape shape-1"></img>
          <div className="container container-normal py-4">
            <div className="row align-items-center g-4 d-flex justify-content-center min_height">
              <div className="col-lg-5">
                <div className="container-tight">

                  <div className="card card-md">
                    <div className="card-body">
                      <h2 className="h2 text-center mb-4">Set Password</h2>
                      <p className="text-center pb-2">
                        Have you forgotten your password? Simply enter the email
                        associated with your account in the form below and we'll
                        send you the instructions to reset your password.
                      </p>
                      <div
                        className="w-100 mb-2
                      "
                      >
                        <div className="card">
                          <div className="ribbon ribbon-top bg-success">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"></path>
                            </svg>
                          </div>
                          <div className="card-body">
                            <p className="text-muted">
                              <p>
                                Password should contain min 8 characters and
                                contain at least one special character.
                              </p>
                            </p>
                          </div>
                        </div>
                      </div>
                      <form
                        className="auth-form"
                        method="POST"
                        onSubmit={submitForm}
                        autoComplete={"off"}
                      >
                        <div className="mb-3">
                          <label className="form-label"> Email address </label>
                          <input
                            type="email"
                            className={`form-control ${validate.validate && validate.validate.email
                              ? "is-invalid "
                              : ""
                              }`}
                            id="email"
                            name="email"
                            value={email}
                            placeholder="your@email.com"
                            onChange={(e) => setEmail(e.target.value)}
                            readOnly
                            disabled
                          />
                          <div
                            className={`invalid-feedback text-start mb-2 ${validate.validate && validate.validate.email
                              ? "d-block"
                              : "d-none"
                              }`}
                          >
                            {validate.validate && validate.validate.email
                              ? validate.validate.email[0]
                              : ""}
                          </div>
                        </div>
                        <div className="mb-2">
                          <label className="form-label"> New Password </label>
                          <input
                            type="password"
                            className={`form-control ${validate.validate && validate.validate.password
                              ? "is-invalid "
                              : ""
                              }`}
                            name="password"
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            autoComplete="false"
                          />
                          <div
                            className={`invalid-feedback text-start mb-2 ${validate.validate && validate.validate.password
                              ? "d-block"
                              : "d-none"
                              }`}
                          >
                            {validate.validate && validate.validate.password
                              ? validate.validate.password[0]
                              : ""}
                          </div>
                        </div>
                        <div className="mb-2">
                          <label className="form-label">
                            {" "}
                            Confirm Password{" "}
                          </label>
                          <input
                            type="password"
                            className={`form-control ${validate.validate &&
                              validate.validate.confirmPassword
                              ? "is-invalid "
                              : ""
                              }`}
                            name="password"
                            id="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            autoComplete="false"
                          />
                          <div
                            className={`invalid-feedback text-start mb-2 ${validate.validate &&
                              validate.validate.confirmPassword
                              ? "d-block"
                              : "d-none"
                              }`}
                          >
                            {validate.validate &&
                              validate.validate.confirmPassword
                              ? validate.validate.confirmPassword[0]
                              : ""}
                          </div>
                        </div>
                        <div
                          className={`invalid-feedback text-start mb-2 ${isPasswordEqual.status && isPasswordEqual.msg
                            ? "d-block"
                            : "d-none"
                            }`}
                        >
                          {isPasswordEqual.status && isPasswordEqual.msg
                            ? isPasswordEqual.msg
                            : ""}
                        </div>

                        <div className="form-footer">
                          <button
                            type="submit"
                            className="btn btn-primary w-100"
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                      <div className="text-center text-muted mt-3">
                        Forget it, <Link to="/login">send me back</Link> to the
                        sign in screen.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 offset-lg-1 ">
                <img className="img-fluid"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/img/login/login.png"
                  }
                  alt="banner-image"
                />
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
