import { React, useState, useEffect, useContext, useRef, useMemo } from 'react';
import "../../../assets/css/table.css";
import Loading from "react-fullscreen-loading";
import { Link } from 'react-router-dom';
import HeaderSection from "../banner/HeaderSection";
import DivLoader from "../../../component/utilities/Spinner";
import DataTable from "react-data-table-component";
import axios from "axios";
import { toast } from "react-toastify";
import * as  CONST from "../../../component/config/Constant"
import Form from "../../../component/utilities/Forms";
import editImg from "../../../assets/img/icon/edit.svg";
import deleteImg from "../../../assets/img/icon/delete.svg";
import viewImg from "../../../assets/img/icon/index.png";
import { Modal, Button } from 'react-bootstrap';
import { AuthContext } from '../../../component/context/AuthContext';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ReactSwitch from 'react-switch';
import * as SwitchProps from '../../../component/utilities/ReactSwitchReactSwitchProps';
import JoditEditor from 'jodit-react';
//import { Editor } from "react-draft-wysiwyg";
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

function List() {
    const [authUserData, setAuthUserData] = useContext(AuthContext);
    const [fullLoading, setFullLoading] = useState(false);
    const [validate, setValidate] = useState({});
    const [processing, setProcessing] = useState(false);
    const [data, setData] = useState([]);
    const [policyTypeData, setPolicyTypeData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activePage, setActivePage] = useState(1);

    const MySwal = withReactContent(Swal)
    const [listLimit, setListLimit] = useState(50);

    const [action, setAction] = useState('');

    /** For Modalpopup */
    const [show, setShow] = useState(false);

    /* START DATATABLE */
    const [totalRecords, settotalRecords] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [maxPageLimit, setMaxPageLimit] = useState(10);
    const [minPageLimit, setMinPageLimit] = useState(0);

    //Search
    const [searchPolicyName, setSearchPolicyName] = useState('');
    const [searchPolicyStatus, setsearchPolicyStatus] = useState('');
    const [searchPolicyCode, setsearchPolicyCode] = useState('');
    const [dataSort, setDataSort] = useState({
        order: 'desc',
        order_by: 'id'
    });
    const pageNumberLimit = { perPage };

    //For Form
    const [policyName, setPolicyName] = useState('');
    const [status, setStatus] = useState(false);
    const [policyDescription, setPolicyDescription] = useState('');
    const [policyTypeId, setPolicyTypeId] = useState();
    const [policyId, setPolicyId] = useState(0);
    const [policyImage, setPolicyImage] = useState([]);
    const [policyImagePath, setPolicyImagePath] = useState('');
    const [policyDocument, setPolicyDocument] = useState([]);
    const [policyDocPath, setPolicyDocPath] = useState('');

    const [viewPolicyName, setViewPolicyName] = useState('');
    const [viewDocPath, setViewDocPath] = useState('');
    const [viewPolicyDesc, setViewPolicyDesc] = useState('');
    const [viewPolicyShow, setViewPolicyShow] = useState(false);

    const editor = useRef(null);

    const config = useMemo(
        () => ({
            readonly: false,
            uploader: { "insertImageAsBase64URI": true },
        }),
        []
    );

    let isToken = '';
    if (authUserData) {
        if (authUserData[0]) {
            isToken = authUserData[0].token ? authUserData[0].token : "";
        }
    }

    const [searchParam, setSearchParam] = useState(
        {
            "srchPolicyName": searchPolicyName,
            "srchPolicyCode": searchPolicyCode,
            "srchPolicyStatus": searchPolicyStatus
        }
    );

    useEffect(() => {
        fetchPolicyData(searchParam, dataSort, activePage, perPage);
        fetchPolicyType();
    }, [searchParam, dataSort, activePage, listLimit]);

    const onPolicyImageSelect = (event) => {
        setPolicyImagePath(URL.createObjectURL(event));
        setPolicyImage(event);
    };

    const onPolicyDocSelect = (event) => {
        setPolicyDocPath(URL.createObjectURL(event))
        setPolicyDocument(event);
    };

    async function removePolicyImage()  {
            setPolicyImage('');
            setPolicyImagePath('');
    }

    async function removePolicyDocument()  {
            setPolicyDocument('');
            setPolicyDocPath('');
    }

    function handleShow(action, policyId) {
        const policy_id = policyId ? policyId : 0;
        setPolicyId(policy_id);
        setPolicyName("");
        setPolicyTypeId("");
        setStatus("");
        setPolicyDescription("");
        setPolicyDocument("");
        setPolicyImage("");

        if (policy_id !== 0) {
            fetchPolicyById(policy_id);
        }
        setAction(action)
        setShow(true);
    }

    async function policySearch() {
        setSearchParam({
            "srchPolicyName": searchPolicyName,
            "srchPolicyCode": searchPolicyCode,
            "srchPolicyStatus": searchPolicyStatus
        })

        setDataSort({
            order: "desc",
            order_by: "id"
        });

        setActivePage("1");
    }

    function clearSearch() {
        setSearchPolicyName('');
        setsearchPolicyStatus('');
        setsearchPolicyCode('');
        setSearchParam({
            "srchPolicyName": '',
            "srchPolicyCode": '',
            "srchPolicyStatus": ''
        });

        setDataSort({
            order: "desc",
            order_by: "id"
        });

        setActivePage("1");
    }

    const handleClose = () => {
        setShow(false);
        setPolicyId(0);
        setPolicyTypeId("");
        setPolicyName("");
        setStatus("");
        setPolicyDescription("");
        setPolicyImagePath("");
        setPolicyDocPath("");
    }

    function deleteHandler(delete_policy_id) {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to delete this policy?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);
                if (delete_policy_id > 0) {
                    setLoading(true);
                    axios
                        .delete(CONST.DELETE_POLICY, {
                            headers: {
                                'Authorization': `Bearer ${isToken}`
                            },
                            data: {
                                policy_id: delete_policy_id
                            }
                        })
                        .then((response) => {
                            if (response) {
                                if (response.data.status == 'error') {
                                    toast.error(response.data.message);
                                }
                                else {
                                    toast.success(response.data.message);
                                    fetchPolicyData(searchParam, dataSort, activePage, perPage);
                                }
                            }
                        })
                        .catch((error) => {
                            setLoading(false);
                            toast.error("Something went wrong. Please try again." + error);
                        });
                    setLoading(false);
                }
            }
        })
    }

    //fetch all policy data by id
    async function fetchPolicyById(policyTypeId) {
        setLoading(true);
        await axios
            .get(CONST.GET_POLICY_BY_ID + `${policyTypeId}`, {
                headers: {
                    'Authorization': `Bearer ${isToken}`
                }
            })
            .then((response) => {
                const status = response.data.status;
                console.log("get policy by id >>");
                console.log(response.data);
                if (status === 'success') {
                    let policyData = response.data.data[0] ? response.data.data[0] : [];

                    setPolicyName(policyData['policy_name'] ? policyData['policy_name'] : '');
                    setPolicyTypeId(policyData['policy_type_id'] ? policyData['policy_type_id'] : '');
                    setStatus(policyData['policy_status'] ? policyData['policy_status'] : '');
                    setPolicyDescription(policyData['policy_description'] ? policyData['policy_description'] : '');
                    setPolicyImage(policyData['policy_image_path'] ? policyData['policy_image_path'] : '');
                    setPolicyDocument(policyData['policy_document_path'] ? policyData['policy_document_path'] : '');
                }
            })
            .catch((error) => {
                alert(error);
            });
        setLoading(false);
    }

    //fetch all policy details
    async function fetchPolicyData(paramSearch, paramSort, paramPage, paramLimit) {
        setLoading(true);
        let searchParams = JSON.stringify({
            'limit': paramLimit,
            'search_input': paramSearch,
            'page_number': paramPage,
            'sort': paramSort
        })
        await axios
            .post(CONST.GET_POLICY_LIST, {
                'limit': paramLimit,
                'search_input': paramSearch,
                'page_number': paramPage,
                'sort': paramSort
            }, {
                headers: {
                    'Authorization': `Bearer ${isToken}`
                }
            })

            .then((response) => {
                const status = response.data.status;
                if (status === 'success') {
                    setData(response.data.data);
                    setTotalRows(response.data.count);
                    settotalRecords(response.data.count);
                }
                else {
                    toast.error(response.data.message);
                }
            })
            .catch((error) => {
            });
        setLoading(false);
    }

    //fetch all Department details
    async function fetchPolicyType() {
        setLoading(true);
        await axios
            .post(CONST.GET_POLICY_TYPE_LIST, {
                search_input: '',
                page_number: ''
            }, {
                headers: {
                    'Authorization': `Bearer ${isToken}`
                }
            })

            .then((response) => {
                const status = response.data.status;
                if (status === 'success') {
                    setPolicyTypeData(response.data.data);
                }
            })
            .catch((error) => {
                alert(error);
            });
        setLoading(false);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let isValid = true;

        let validator = Form.validator({
            policyName: {
                value: policyName,
                isRequired: true,
                label: "Policy Name",
            },
            policyTypeId: {
                value: policyTypeId,
                isRequired: true,
                label: "Policy Type",
            },
            policyDescription: {
                value: policyDescription,
                isNumber: true,
                isRequired: true,
                label: "Policy Description",
            },
        });

        if (validator !== null) {
            setValidate({
                validate: validator.errors,
            });
            isValid = false;
        }

        if (isValid) {
            let apiUrl = '';
            let sucessMessage = '';
            let strMethod = '';
            if (policyId == 0) {
                apiUrl = CONST.ADD_POLICY;
                strMethod = 'post';
                sucessMessage = 'Policy added succesfully';
            } else {
                apiUrl = CONST.EDIT_POLICY;
                strMethod = 'post';
                sucessMessage = 'Policy updated succesfully';
            }

            await axios({
                method: strMethod,
                url: apiUrl,
                data: {
                    policy_name: policyName,
                    policy_type_id: policyTypeId,
                    policy_status: (status == '1' || status == '') ? '1' : '0',
                    policy_description: policyDescription,
                    policy_document: policyDocument,
                    policy_image: policyImage,
                    policy_id: policyId
                },
                headers: {
                    'Authorization': `Bearer ${isToken}`,
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then((response) => {
                    if (response) {
                        if (response.data.status == 'error') {
                            toast.error("Something went wrong. Please try again.");
                        } else {
                            toast.success(sucessMessage);
                            fetchPolicyData(searchParam, dataSort, activePage, perPage);
                            handleClose();
                        }
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    toast.error("Something went wrong. Please try again.");
                });
        }
    }

    const handleChangeStatus = (index, status_value, policy_id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "Do you want to change the status?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, change it!'
        }).then((result) => {
            if (result.isConfirmed) {
                setLoading(true);
                axios.patch(CONST.UPDATE_POLICY_STATUS,
                    {
                        'policy_id': policy_id,
                        'status': (status_value == '0') ? '1' : '0',
                    },
                    {
                        headers: {
                            'Authorization': `Basic ${isToken}`
                        }
                    })
                    .then((response) => {
                        const status = response.data.status;
                        if (status == 'success') {
                            toast.success(response.data.message);
                        } else {
                            toast.error(response.data.message);
                        }
                    })
                    .catch((error) => {
                        toast.error("There was an error.");
                    });
                setLoading(false);

                const newData = [...data];
                newData[index].policy_status = (status_value == 0) ? 1 : 0;
                setData(newData);
            }
        })
    }

    const columns = [
        {
            name: 'Sr. No',
            selector: (row, index) => index + 1,
            width: "5%",

        },
        {
            name: 'Policy Name',
            selector: (row) => row.policy_name,
            width: "30%",
            wrap: true,

        },
        {
            name: 'Image',
            selector: (row) => <div className="col-md-8 mt-4">
                {row.policy_image_path !== "" ? <img src={CONST.HOST + row.policy_image_path} alt={row.policy_name} /> : "--"}
            </div>,

        },
        {
            name: 'Policy Type',
            selector: (row) => row.e_policy_type !== null ? row.e_policy_type.name : '',

        },
        /* {
            name: 'Policy Description',
            selector: (row) =>  row.policy_description.length > 200 ? row.policy_description.substring(0, 100) + "..." :  row.policy_description,
            width: "30%",
        }, */
        {
            name: 'Status',
            selector: (row) => 'Active',

            selector: (row, index) => <ReactSwitch
                onColor={SwitchProps.onColor}
                onHandleColor={SwitchProps.onHandleColor}
                handleDiameter={SwitchProps.handleDiameter}
                uncheckedIcon={SwitchProps.uncheckedIcon}
                checkedIcon={SwitchProps.checkedIcon}
                boxShadow={SwitchProps.boxShadow}
                activeBoxShadow={SwitchProps.activeBoxShadow}
                height={SwitchProps.height}
                width={SwitchProps.width}
                className={SwitchProps.className}
                checked={(row.policy_status == 1) ? true : false}
                onChange={() => { handleChangeStatus(index, row.policy_status, row.id) }}
            />,
        },
        {
            name: 'Action',
            width: "10%",
            wrap: true,
            sortable: false,
            cell: (row) => <Action row={row} />,
        },
    ]

    const Action = ({ row }) => (
        <>
            <Link to={'#'} data-id={row.id} onClick={() => handleShow('Edit', row.id)}>
                <img src={editImg} alt='editPolicy' width={32} className='cursor' />
            </Link>
            <Link onClick={() => deleteHandler(row.id)}>
                <img src={deleteImg} alt='deleteImg' width={32} className='cursor' />
            </Link>
            <Link onClick={() =>
                viewDetails(
                    row.policy_name,
                    row.policy_document_path !== '' ? CONST.HOST + row.policy_document_path : '',
                    row.policy_description !== '' ? row.policy_description : '',
                )}>
                <img src={viewImg} alt='viewImg' width={32} className='cursor' />
            </Link>
        </>
    )

    const viewDetails = (applicantName, doc_path, policy_description) => {
        console.log("resume_path >>> ");
        console.log(applicantName);
        setViewPolicyName(applicantName);
        setViewDocPath(doc_path !== '' ? doc_path : '');
        setViewPolicyDesc(policy_description !== '' ? policy_description : '');
        setViewPolicyShow(true);
    }

    const handleViewPolicyClose = () => {
        setViewPolicyName('');
        setViewDocPath('');
        setViewPolicyDesc('');
        setViewPolicyShow(false);
    }

    const handleSort = async (column, sortDirection) => {
        setActivePage(1);
        setSearchParam({
            search: 'no',
            name: '',
            status: '',
        });

        setDataSort({
            order: sortDirection,
            order_by: column.sortField
        })
        /*  setLoading(false); */
    };
    /*  PAGINATION FUNCTIONS  */
    const handlePageChange = async (page) => {
        setActivePage(parseInt(page));
    };

    const BootyPagination = ({
        rowsPerPage,
        rowCount,
        onChangePage,
        onChangeRowsPerPage, // available but not used here
        currentPage,
    }) => {
        function toPages(pages) {
            const results = [];
            for (let i = 1; i <= pages; i++) {
                results.push(i);
            }
            return results;
        }
        function getNumberOfPages(rowCount, rowsPerPage) {
            return Math.ceil(rowCount / rowsPerPage);
        }


        const handleBackButtonClick = async () => {
            if ((currentPage - 1) % pageNumberLimit === 0) {
                setMaxPageLimit(maxPageLimit - pageNumberLimit);
                setMinPageLimit(minPageLimit - pageNumberLimit);
            }
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = async () => {
            if (currentPage + 1 > maxPageLimit) {
                setMaxPageLimit(maxPageLimit + pageNumberLimit);
                setMinPageLimit(minPageLimit + pageNumberLimit);
            }

            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        let paginationText = "Page:  " + currentPage + ", Showing ";

        paginationText =
            paginationText +
            ((currentPage - 1) * perPage + 1) +
            " to " +
            perPage * currentPage;
        paginationText = paginationText + " of " + rowCount + " entries;";

        const pages = getNumberOfPages(rowCount, rowsPerPage);
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === pageItems.length;
        const previosDisabled = currentPage === 1;

        const pageNumbers = pageItems.map((page) => {
            if (page <= maxPageLimit && page > minPageLimit) {
                return (
                    <li
                        key={page}
                        id={page}
                        className={`page-item ${currentPage === page ? "active" : ""}`}
                    >
                        <button
                            className={`page-link ${currentPage === page ? "active" : ""}`}
                            onClick={handlePageNumber}
                            value={page}
                        >
                            {page}
                        </button>
                    </li>
                );
            } else {
                return null;
            }
        });
        let pageIncrementEllipses = null;
        if (pageItems.length > maxPageLimit) {
            pageIncrementEllipses = (
                <li className="page-item" onClick={handleNextButtonClick}>
                    &hellip;
                </li>
            );
        }
        let pageDecremenEllipses = null;
        if (minPageLimit >= 1) {
            pageDecremenEllipses = (
                <li className="page-item" onClick={handleBackButtonClick}>
                    &hellip;
                </li>
            );
        }
        return (
            <div className="card-footer d-flex align-items-center">
                <p className="m-0 text-muted">{paginationText}</p>
                <ul className="pagination m-0 ms-auto">
                    <li className="page-item">
                        <button
                            className="page-link"
                            onClick={handleBackButtonClick}
                            disabled={previosDisabled}
                            aria-disabled={previosDisabled}
                            aria-label="previous page"
                            pagerangedisplayed={10}
                        >
                            «
                        </button>
                    </li >
                    {pageDecremenEllipses}
                    {pageNumbers}
                    {pageIncrementEllipses}
                    <li className="page-item">
                        <button
                            className="page-link"
                            onClick={handleNextButtonClick}
                            disabled={nextDisabled}
                            aria-disabled={nextDisabled}
                            aria-label="next page">
                            »
                        </button>
                    </li>
                </ul >
            </div >
        );
    };
    /* END DATATABLE */
    const CustomLoader = () => (
        <div style={{ padding: "24px" }}>
            <DivLoader color="primary" />
        </div>
    );
    return (
        <>
            <Loading
                loading={fullLoading}
                background="rgba(0, 0, 0, 0.5)"
                loaderColor="#fff"
            />
            <HeaderSection
                title="Manage"
                heading="Policy"
                addButton={() => handleShow('Add', 0)}
                addLink="#"
                addLinkName="Add Policy"
                linktype="add"
            />
            <div className="page-body">
                <div className="container-xl">
                    <div className="row row-cards">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Listing</h3>
                                </div>
                                <div className="card-body border-bottom py-3">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="form-group mb-3 ">
                                                <label className="form-label required">Policy Name</label>
                                                <div>
                                                    <input
                                                        type="text"
                                                        name="searchPolicyName"
                                                        id="searchPolicyName"
                                                        className={`form-control`}
                                                        placeholder="Enter Policy Name"
                                                        onChange={(e) => setSearchPolicyName(e.target.value)}
                                                        value={searchPolicyName}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-2">
                                            <div className="mb-3">
                                                <label className="form-label required">
                                                    Status
                                                </label>

                                                <select
                                                    className="form-select form-control"
                                                    name="searchPolicyStatus"
                                                    id="searchPolicyStatus"
                                                    placeholder="Select status"
                                                    onChange={(e) => setsearchPolicyStatus(e.target.value)} value={searchPolicyStatus}
                                                >                                                <option value="">Select</option>
                                                    <option value="1">Active</option>
                                                    <option value="0">Deactive</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="col-md-2">
                                            <div className="form-group mb-3 ">
                                                <label className="form-label">&nbsp;</label>
                                                <div>
                                                    <button
                                                        type="button"
                                                        name="btnSearch"
                                                        id="btnSearch"
                                                        className="btn btn-blue d-none d-sm-inline-block ml-3"
                                                        onClick={policySearch}
                                                    ><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <circle cx="10" cy="10" r="7"></circle>
                                                            <line x1="21" y1="21" x2="15" y2="15"></line>
                                                        </svg>Search</button>&nbsp;
                                                    <button
                                                        type="button"
                                                        name="btnReset"
                                                        id="btnReset"
                                                        className="btn btn-outline-primary "
                                                        onClick={clearSearch}
                                                    >
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <line x1="18" y1="6" x2="6" y2="18"></line>
                                                            <line x1="6" y1="6" x2="18" y2="18"></line>
                                                        </svg>Clear</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="table-responsive">

                                    <DataTable
                                        columns={columns}
                                        data={data}
                                        progressPending={loading}
                                        progressComponent={<CustomLoader />}
                                        pagination="true"
                                        paginationServer
                                        paginationTotalRows={totalRows}
                                        //onChangeRowsPerPage={handlePerRowsChange}
                                        onChangePage={handlePageChange}
                                        paginationPerPage={perPage}
                                        paginationComponent={BootyPagination}
                                        sortServer
                                        onSort={handleSort}
                                    />

                                </div>
                                <div className="card-footer d-flex align-items-center">
                                    <p className="m-0 text-muted">
                                        Showing {/* <span>1</span> to <span>8</span> of{" "} */}
                                        <span>{totalRecords}</span> entries
                                    </p>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{action} Policy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form method="POST" onSubmit={handleSubmit} autoComplete={"off"}>
                        <div className="row">
                            <div className="col-md-6">
                                <label className="form-label required">
                                    Policy Name
                                </label>
                                <div>
                                    <input
                                        type="text"
                                        name="policyName"
                                        id="policyName"
                                        className={`form-control ${validate.validate && validate.validate.policyName ? "is-invalid" : ""}`}
                                        placeholder="Enter Policy name"
                                        onChange={(e) => setPolicyName(e.target.value)}
                                        value={policyName}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <label className="form-label required">
                                    Select Policy Type
                                </label>
                                <div>
                                    <select name="policyTypeId" id="policyTypeId" className={`form-select form-control ${validate.validate && validate.validate.policyTypeId
                                        ? "is-invalid" : ""}`} placeholder="Select policy type" onChange={(e) => setPolicyTypeId(e.target.value)} value={policyTypeId}>
                                        <option value="">Select policy type</option>
                                        {policyTypeData.map((p, index) => (
                                            <option key={`policy_type_id_+${p.id}`} value={p.id}>{p.name}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>


                            <div className="col-md-12">
                                <label className="form-label required">
                                    Description
                                </label>
                                <div>

                                    <JoditEditor
                                        ref={editor}
                                        config={config}
                                        value={policyDescription}
                                        onBlur={newContent => setPolicyDescription(newContent)}
                                        onChange={(newContent) => { }}
                                    />
                                </div>

                            </div>

                            <div className="col-md-6">
                                <label className="form-label required">
                                    Status
                                </label>

                                <select
                                    className={`form-select form-control ${validate.validate && validate.validate.status
                                        ? "is-invalid "
                                        : ""
                                        }`}
                                    name="status"
                                    id="status"
                                    placeholder="Select status"
                                    onChange={(e) => setStatus(e.target.value)}
                                    value={status}>
                                    <option value="">Select</option>
                                    <option value="1">Active</option>
                                    <option value="0">Deactive</option>
                                </select>

                            </div>

                            <div className="col-md-6">
                                <label className="form-label required">
                                    Upload Policy Document
                                </label>

                                <input type="file" name="policyDocument" id="policyDocument" className={`form-control`} accept="application/pdf" onChange={(e) => onPolicyDocSelect(e.target.files[0])} />


                                {(policyDocPath !== "") ?
                                    <object width="100%" height="400" data={policyDocPath} type="application/pdf"></object> :
                                    (policyDocument !== "" && policyId !== 0) ?
                                        <object width="100%" height="400" data={CONST.HOST + policyDocument} type="application/pdf"></object> : ""
                                }

                                {(() => {
                            
                                    if (policyDocument !== "" && policyId !== 0) { 
                                      return (
                                       <>
                                         <button
                                            type="button"
                                            name="removeImage"
                                            id="removeImage"
                                            className="btn btn-outline-danger policyDeleteBtn"
                                            onClick={removePolicyDocument}
                                        >

                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                                                <line x1="6" y1="6" x2="18" y2="18"></line>
                                                            </svg>
                                                             Remove</button>
                                       </>
                                      )
                                    }


                               })()}
                            </div>

                            <div className="col-md-6">
                                <label className="form-label required">
                                    Upload Policy Image
                                </label>

                                <input type="file" name="policyImage" id="policyImage" className={`form-control`} accept="image/png, image/jpg, image/jpeg, image/svg+xml" onChange={(e) => onPolicyImageSelect(e.target.files[0])} />
                                {(policyImagePath !== "") ?
                                    <img src={policyImagePath} alt='policyImage' width="150" /> :
                                    (policyImage !== "" && policyId !== 0) ?
                                        <img src={CONST.HOST + policyImage} alt='policyImage' width="150" /> :
                                        ""}

                                {(() => {
                            
                                    if (policyImage !== "" && policyId !== 0) { 
                                      return (
                                       <>
                                         <button
                                            type="button"
                                            name="removeImage"
                                            id="removeImage"
                                            className="btn btn-outline-danger policyDeleteBtn"
                                            onClick={removePolicyImage}
                                        >

                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                <line x1="18" y1="6" x2="6" y2="18"></line>
                                                                <line x1="6" y1="6" x2="18" y2="18"></line>
                                                            </svg>
                                                             Remove</button>
                                       </>
                                      )
                                    }


                               })()}

                            </div>

                        </div>

                        <input type="hidden" name="policyId" id="policyId" value={policyId} />
                    </form>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={handleSubmit}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={viewPolicyShow} onHide={handleViewPolicyClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{viewPolicyName}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {(() => {
                        if (viewDocPath !== '') {
                            return (
                                <>
                                    <object width="100%" height="400" data={viewDocPath} type="application/pdf">
                                    </object>
                                </>
                            )
                        } else {
                            return (
                                <>
                                    <div dangerouslySetInnerHTML={{ __html: viewPolicyDesc }} />
                                </>
                            )
                        }

                    })()}


                </Modal.Body>
            </Modal>
        </>
    )
}

export default List