import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import * as CONST from "../../../component/config/Constant";
import { AuthContext } from '../../../component/context/AuthContext';
import { toast } from "react-toastify";
import Form from '../../../component/utilities/Forms';

function AddEditCategory({closeCatForm}) {
	const [authUserData, setAuthUserData] = useContext(AuthContext);
    const [show, setShow] = useState('show');
	const [loading, setLoading] = useState(false);
	const [categoryName, setCategoryName] = useState("");
    const [validate, setValidate] = useState({});
    //const [categoryId, setCategoryId] = useState(categoryId);

	let isToken = '';
	let user_id = '';
	if (authUserData) {
		if (authUserData[0]) {
			isToken = authUserData[0].token ? authUserData[0].token : "";
			user_id = authUserData[0].user_id ? authUserData[0].user_id : "";
		}
	}

	useEffect(() => {
		fetchCategory();
	}, [])

	const fetchCategory = async () => {
        setLoading(true);
       /* await axios
        .get(CONST.GET_EDUCATION_DATA,
        {
            headers: {
                'Authorization': `Bearer ${isToken}`
            },    
            
        })

        .then((response) => {
            
            const status = response.data.status;
            
            if (status === 'success') {
                setEducationFields(response.data.data);
            }else{
                toast.error(response.data.message);
            }
        })
        .catch((error) => {
            console.log(error);
        });*/
        setLoading(false);
	}

    const validateCategory = async () => {
		let isValid = true;

		let validator = Form.validator({
			categoryName: {
				value: categoryName,
				isRequired: true,
				isEmail: false,
				label: "Category name",
			},

		});

		if (validator !== null) {
			setValidate({
				validate: validator.errors,
			});
			isValid = false;
		} else {
			setValidate({
				validate: "",
			});
			isValid = true;
		}
		return isValid;
	};

    const saveCategory = async(e) => {
        //ADD_EVENT_CATEGORY
        e.preventDefault();
		const validate = await validateCategory();
		if (validate) {
            setLoading(true);
            await axios
            .post(CONST.ADD_EVENT_CATEGORY,{
                'name' : categoryName
            },
            {
                headers: {
                    'Authorization': `Bearer ${isToken}`
                },    
                
            })
    
            .then((response) => {
                
                const status = response.data.status;
                
                if (status === 'success') {
                    toast.success(response.data.message);
                    closeCatForm('hide');
                }else{
                    toast.error(response.data.message);
                }
            })
            .catch((error) => {
                console.log(error);
            });
            setLoading(false);
        }
    }
	return (
		<>
			<Modal
				size="lg"
				show={show}
				onHide={() => closeCatForm('hide')}
				backdrop="static"
				keyboard={false}
				centered
				fullscreen={false}
			>
				<Modal.Header closeButton>
					<Modal.Title>Event Category</Modal.Title>
				</Modal.Header>
				<Modal.Body>
                    <div className="row"> 
                        <div className="row">    
                            <div className="col-md-12">
                                <div className="form-group mb-3 ">
                                    <label className="form-label bold">
                                        Category Name
                                    </label>
                                    <div>
                                    <input
                                        type="text"
                                        className={`form-control ${validate.validate && validate.validate.categoryName
                                            ? "is-invalid "
                                            : ""
                                            }`}
                                        id="categoryName"
                                        name="categoryName"
                                        value={categoryName}
                                        placeholder="Enter category"
                                        onChange={(e) => setCategoryName(e.target.value)}
                                    />
                                    <div
                                        className={`invalid-feedback text-start mb-2 ${validate.validate && validate.validate.categoryName
                                            ? "d-block"
                                            : "d-none"
                                            }`}
                                    >
                                        {validate.validate && validate.validate.categoryName
                                            ? validate.validate.categoryName[0]
                                            : ""}
                                    </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="col-md-12">
                                <div className="form-group mb-3 ">
                                    <input type="submit" className="mr-3 btn btn-primary" onClick={saveCategory} />
                                </div>
                            </div>
                            
                            <hr />
                        </div>  
                    </div>
				</Modal.Body>

			</Modal>
		</>
	)
}

export default AddEditCategory