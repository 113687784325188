import { React, useState, useEffect, useContext } from 'react'
import "../../../assets/css/table.css";
import Loading from "react-fullscreen-loading";
import { Link } from 'react-router-dom';
import HeaderSection from "../banner/HeaderSection";
import DivLoader from "../../../component/utilities/Spinner";
import DataTable from "react-data-table-component";
import axios from "axios";
import { toast } from "react-toastify";
import * as  CONST from "../../../component/config/Constant"
import Form from "../../../component/utilities/Forms";
import editImg from "../../../assets/img/icon/edit.svg";
import deleteImg from "../../../assets/img/icon/delete.svg";
import { Modal, Button } from 'react-bootstrap';
import { AuthContext } from '../../../component/context/AuthContext';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ReactSwitch from 'react-switch';
import * as SwitchProps from '../../../component/utilities/ReactSwitchReactSwitchProps';
function List() {
    const [authUserData, setAuthUserData] = useContext(AuthContext);
    const [fullLoading, setFullLoading] = useState(false);
    const [validate, setValidate] = useState({});
    const [processing, setProcessing] = useState(false);
    const [data, setData] = useState([]);
    const [deptData, setDeptData] = useState([]);

    const [loading, setLoading] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [designationId, setDesignationId] = useState(0);
    const MySwal = withReactContent(Swal)
    const [listLimit, setListLimit] = useState(50);

    const [action, setAction] = useState('');

    /** For Modalpopup */
    const [show, setShow] = useState(false);

    /* START DATATABLE */
    const [totalRecords, settotalRecords] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [maxPageLimit, setMaxPageLimit] = useState(10);
    const [minPageLimit, setMinPageLimit] = useState(0);

    //Search
    const [searchDesignationName, setSearchDesignation] = useState('');
    const [searchDesignationStatus, setSearchDesignationStatus] = useState('');
    const [searchDepartmentId, setSearchDepartmentId] = useState('');
    const [searchIsHead, setSearchIsHead] = useState('');
    const [searchLeavesAllocated, setSearchleavesAllocated] = useState('');
    const [dataSort, setDataSort] = useState({
        order: 'desc',
        order_by: 'id'
    });
    const pageNumberLimit = { perPage };

    //For Form
    const [designationName, setDesignationName] = useState('');
    const [isHead, setIsHead] = useState(false);
    const [departmentId, setDepartmentId] = useState('');
    const [status, setStatus] = useState(false);
    const [totalLeaves, setTotalLeaves] = useState('');

    let isToken = '';
    if (authUserData) {
        if (authUserData[0]) {
            isToken = authUserData[0].token ? authUserData[0].token : "";
        }
    }

    const [searchParam, setSearchParam] = useState(
        {
            "srchDesignationName": searchDesignationName,
            "srchDesignationDeptId": searchDepartmentId,
            "srchDesignationStatus": searchDesignationStatus,
            "srchIsHead": searchIsHead,
            "srchDesignationleavesAllocated": searchLeavesAllocated
        }
    );

    useEffect(() => {
        fetchDesignationData(searchParam, dataSort, activePage, perPage);
        fetchDeparatment();
    }, [searchParam, dataSort, activePage, listLimit]);

   
    const HandleIsHeadChecked = async () => {
        setIsHead(!isHead);
	}

    function handleShow(action, designationId) {

        const designation_id = designationId ? designationId : 0;
        setDesignationId(designation_id);
        setDesignationName("");
        setIsHead("");
        setDepartmentId("");
        setStatus("");
        setTotalLeaves("");
        
        if (designation_id !== 0) {
            fetchDesignationById(designation_id);
        }

        setAction(action)
        setShow(true);
    }


    async function designationSearch() {
        setSearchParam({
            "srchDesignationName": searchDesignationName,
            "srchDesignationDeptId": searchDepartmentId,
            "srchDesignationStatus": searchDesignationStatus,
            "srchIsHead": searchIsHead,
            "srchDesignationleavesAllocated": searchLeavesAllocated
        })
        setDataSort({
			order: "desc",
			order_by: "id"
		});
		setActivePage("1");
    }

    function clearSearch() {
        setSearchDesignation('');
        setSearchDesignationStatus('');
        setSearchDepartmentId('');
        setSearchIsHead('');
        setSearchleavesAllocated('');

        setSearchParam({
            "srchDesignationName": '',
            "srchDesignationDeptId": '',
            "srchDesignationStatus": '',
            "srchisHead": '',
            "srchDesignationleavesAllocated": ''
        })
        setDataSort({
			order: "desc",
			order_by: "id"
		});
		setActivePage("1");
    }

    const handleClose = () => {
        setShow(false);
        setDesignationId(0);
        setDesignationName("");
        setIsHead("");
        setDepartmentId("");
        setStatus("");
        setTotalLeaves("");
    }

    function deleteHandler(delete_design_id) {
        Swal.fire({
			title: 'Are you sure?',
			text: "Do you want to delete this designation?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then((result) => {
			if (result.isConfirmed) {
				setLoading(true);
                if (delete_design_id > 0) {
                    setLoading(true);
                    axios
                        .delete(CONST.DELETE_DESIGNATION, {
                            headers: {
                                'Authorization': `Bearer ${isToken}`
                            },
                            data: {
                                desg_id: delete_design_id
                            }
                        })
                        .then((response) => {

                            if (response) {
                                if (response.data.status == 'error') {
                                    toast.error(response.data.message);
                                } else {
                                    toast.success(response.data.message);
                                    fetchDesignationData(searchParam, dataSort, activePage, perPage);
                                }
                            }
                        })
                        .catch((error) => {
                            setLoading(false);
                            toast.error("Something went wrong. Please try again." + error);
                        });
                    setLoading(false);
                }
			}
		})
    }

    //fetch all designation data by id
    async function fetchDesignationById(designationId) {
        setLoading(true);
        await axios
            .get(CONST.GET_DESIGNATION_BY_ID + `${designationId}`, {
                headers: {
                    'Authorization': `Bearer ${isToken}`
                }
            })
            .then((response) => {
                const status = response.data.status;
               
                if (status === 'success') {
                    let designationData = response.data.data[0] ? response.data.data[0] : [];
                    console.log("is_head >> " + designationData['is_head']);
                    let isHeadValue = designationData['is_head'] == 1 ? true : false;
                    console.log(isHeadValue);
                    setIsHead(isHeadValue);
                    setDesignationName(designationData['name'] ? designationData['name'] : '' );
                    setDepartmentId(designationData['e_did'] ? designationData['e_did'] : '');
                    setStatus(designationData['status'] ? designationData['status'] : '');
                    setTotalLeaves(designationData['leaves_allocated'] ? designationData['leaves_allocated'] : '');
                }
            })
            .catch((error) => {
                alert(error);
            });
        setLoading(false);
    }

    //fetch all designation details
    async function fetchDesignationData(paramSearch, paramSort, paramPage, paramLimit) {
        setLoading(true);
        let searchParams = JSON.stringify({
            'limit': paramLimit,
            'search_input': paramSearch,
            'page_number': paramPage,
            'sort': paramSort
        })
        await axios
            .post(CONST.GET_DESIGNATION_LIST, {
                'limit': paramLimit,
                'search_input': paramSearch,
                'page_number': paramPage,
                'sort': paramSort
            }, {
                headers: {
                    'Authorization': `Bearer ${isToken}`
                }
            })

            .then((response) => {
                const status = response.data.status;

                if (status === 'success') {
                    setData(response.data.data);
                    setTotalRows(response.data.count);
                    settotalRecords(response.data.count);
                }
                else{
                    toast.error(response.data.message);
                }
            })
            .catch((error) => {
            });
        setLoading(false);
    }

    //fetch all Department details
    async function fetchDeparatment() {
        setLoading(true);
        await axios
            .post(CONST.GET_DEPARTMENT_LIST, {
                search_input: '',
                page_number: ''
            }, {
                headers: {
                    'Authorization': `Bearer ${isToken}`
                }
            })

            .then((response) => {
                const status = response.data.status;
                if (status === 'success') {
                    setDeptData(response.data.data);
                }
            })
            .catch((error) => {
                alert(error);
            });
        setLoading(false);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let isValid = true;

        let validator = Form.validator({
            designationName: {
                value: designationName,
                isRequired: true,
                label: "Designation Name",
            },
            departmentId: {
                value: departmentId,
                isRequired: true,
                label: "Department Name",
            },
            totalLeaves: {
                value: totalLeaves,
                isNumber: true,
                isRequired: true,
                label: "Total Leaves",
            },
        });

        if (validator !== null) {
            setValidate({
                validate: validator.errors,
            });

            isValid = false;
        }

        if (isValid) {
            let apiUrl = '';
            let sucessMessage = '';
            let strMethod = '';
            if (designationId == 0) {
                apiUrl = CONST.ADD_DESIGNATION;
                strMethod = 'post';
                sucessMessage = 'Designation added succesfully';
            } else {
                apiUrl = CONST.EDIT_DESIGNATION;
                strMethod = 'put';
                sucessMessage = 'Designation updated succesfully';
            }

            await axios({
                method: strMethod,
                url: apiUrl,
                data: {
                    name: designationName,
                    e_did: departmentId,
                    //status: status === true ? "1" : "0",
                    status: (status == '1' || status == '' ) ? '1' : '0',
                    leaves_allocated: totalLeaves,
                    desg_id: designationId,
                    is_head : isHead === true ? "1" : "0",
                },
                headers: {
                    'Authorization': `Bearer ${isToken}`
                }
            })
            .then((response) => {
                if (response) {
                    if (response.data.status == 'error') {
                        toast.error("Something went wrong. Please try again.");
                    } else {
                        toast.success(sucessMessage);
                        fetchDesignationData(searchParam, dataSort, activePage, perPage);
                        handleClose();
                    }
                }
            })
            .catch((error) => {
                setLoading(false);
                toast.error("Something went wrong. Please try again.");
            });
        } 
    }

    const handleChangeStatus = (index, status_value, designation_id) => {
        Swal.fire({
			title: 'Are you sure?',
			text: "Do you want to change the status?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, change it!'
		}).then((result) => {
			if (result.isConfirmed) {
				setLoading(true);
				axios.patch(CONST.UPDATE_DESIGNATION_STATUS,
					{
						'desg_id': designation_id,
						'status': (status_value == '0') ? '1' : '0',
					},
					{
						headers: {
							'Authorization': `Basic ${isToken}`
						}
					}
				)
					.then((response) => {
						const status = response.data.status;
						if (status == 'success') {
							toast.success(response.data.message);
						} else {
							toast.error(response.data.message);
						}
					})
					.catch((error) => {
						toast.error("There was an error.");
					});
				setLoading(false);
                
				const newData = [...data];
    			newData[index].status= (status_value == 0) ? 1 : 0;
				setData(newData);
			}
		})
    }

    const columns = [
        {
            name: 'Sr. No',
            selector: (row, index) => index + 1,
            width: "10%",
        },
        {
            name: 'Designation Name',
            selector: (row) => row.name,
            width: "25%",
        },
        {
            name: 'Is Head',
            selector: (row) => row.is_head ===  "1"  ? "Yes" : "No",
            width: "10%",
        },
        {
            name: 'Department',
            selector: (row) =>  row.edesignationData !== null ?  row.edesignationData.name : '',
            width: "25%",
        },
        {
            name: 'Total Employees',
            selector: (row) => row.employeeCount,
            width: "10%",
        },
        {
            name: 'Total Leaves',
            selector: (row) => row.leaves_allocated,
            width: "10%",
        },
        {
            name: 'Status',
            selector: (row) => 'Active',
            width: "10%",
            selector: (row, index) => <ReactSwitch
                onColor={SwitchProps.onColor}
                onHandleColor={SwitchProps.onHandleColor}
                handleDiameter={SwitchProps.handleDiameter}
                uncheckedIcon={SwitchProps.uncheckedIcon}
                checkedIcon={SwitchProps.checkedIcon}
                boxShadow={SwitchProps.boxShadow}
                activeBoxShadow={SwitchProps.activeBoxShadow}
                height={SwitchProps.height}
                width={SwitchProps.width}
                className={SwitchProps.className}
                checked={(row.status == 1) ? true : false}
                onChange={() => { handleChangeStatus(index, row.status, row.id) }}
            />,
        },
        {
            name: 'Action',
            width: "20%",
            wrap: true,
            sortable: false,
            cell: (row) => <Action row={row} />,
        },

    ]

    const Action = ({ row }) => (
        <>
            <Link to={'#'} data-id={row.id} onClick={() => handleShow('Edit', row.id)}>
                <img src={editImg} alt='editUser' width={32} className='cursor' />
            </Link>
           {/*  <Link onClick={() => deleteHandler(row.id) }>
                <img src={deleteImg} alt='deleteImg' width={32} className='cursor' />
            </Link> */}
        </>
    )

    const handleSort = async (column, sortDirection) => {
        setActivePage(1);
        setSearchParam({
            search: 'no',
            name: '',
            status: '',
        });

        setDataSort({
            order: sortDirection,
            order_by: column.sortField
        })
        /*  setLoading(false); */

    };
    /*  PAGINATION FUNCTIONS  */
    const handlePageChange = async (page) => {
        setActivePage(parseInt(page));
    };

    const BootyPagination = ({
        rowsPerPage,
        rowCount,
        onChangePage,
        onChangeRowsPerPage, // available but not used here
        currentPage,
    }) => {

        function toPages(pages) {
			const results = [];
			for (let i = 1; i <= pages; i++) {
				results.push(i);
			}
			return results;
		}
		function getNumberOfPages(rowCount, rowsPerPage) {
			return Math.ceil(rowCount / rowsPerPage);
		}


        const handleBackButtonClick = async () => {
            if ((currentPage - 1) % pageNumberLimit === 0) {
                setMaxPageLimit(maxPageLimit - pageNumberLimit);
                setMinPageLimit(minPageLimit - pageNumberLimit);
            }
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = async () => {
            if (currentPage + 1 > maxPageLimit) {
                setMaxPageLimit(maxPageLimit + pageNumberLimit);
                setMinPageLimit(minPageLimit + pageNumberLimit);
            }

            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        let paginationText = "Page:  " + currentPage + ", Showing ";

        paginationText =
            paginationText +
            ((currentPage - 1) * perPage + 1) +
            " to " +
            perPage * currentPage;
        paginationText = paginationText + " of " + rowCount + " entries;";

        const pages = getNumberOfPages(rowCount, rowsPerPage);
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === pageItems.length;
        const previosDisabled = currentPage === 1;

        const pageNumbers = pageItems.map((page) => {
            if (page <= maxPageLimit && page > minPageLimit) {
                return (
                    <li
                        key={page}
                        id={page}
                        className={`page-item ${currentPage === page ? "active" : ""}`}
                    >
                        <button
                            className={`page-link ${currentPage === page ? "active" : ""}`}
                            onClick={handlePageNumber}
                            value={page}
                        >
                            {page}
                        </button>
                    </li>
                );
            } else {
                return null;
            }
        });
        let pageIncrementEllipses = null;
        if (pageItems.length > maxPageLimit) {
            pageIncrementEllipses = (
                <li className="page-item" onClick={handleNextButtonClick}>
                    &hellip;
                </li>
            );
        }
        let pageDecremenEllipses = null;
        if (minPageLimit >= 1) {
            pageDecremenEllipses = (
                <li className="page-item" onClick={handleBackButtonClick}>
                    &hellip;
                </li>
            );
        }
        return (
            <div className="card-footer d-flex align-items-center">
                <p className="m-0 text-muted">{paginationText}</p>
                <ul className="pagination m-0 ms-auto">
                    <li className="page-item">
                        <button
                            className="page-link"
                            onClick={handleBackButtonClick}
                            disabled={previosDisabled}
                            aria-disabled={previosDisabled}
                            aria-label="previous page"
                            pagerangedisplayed={10}
                        >
                        « 
                        </button>
                    </li >
                { pageDecremenEllipses }
                { pageNumbers }
                { pageIncrementEllipses }
                <li className="page-item">
                <button
                    className="page-link"
                    onClick={handleNextButtonClick}
                    disabled={nextDisabled}
                    aria-disabled={nextDisabled}
                    aria-label="next page">
                    »
                </button>
                </li>
            </ul >
        </div >
        );
};
/* END DATATABLE */
const CustomLoader = () => (
    <div style={{ padding: "24px" }}>
        <DivLoader color="primary" />
    </div>
);
return (
    <>
        <Loading
            loading={fullLoading}
            background="rgba(0, 0, 0, 0.5)"
            loaderColor="#fff"
        />
        <HeaderSection
            title="Manage"
            heading="Designation"
            addButton={() => handleShow('Add', 0)}
            addLink="#"
            addLinkName="Add Designation"
            linktype="add"
        />
        <div className="page-body">
            <div className="container-xl">
                <div className="row row-cards">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title">Listing</h3>
                            </div>
                            <div className="card-body border-bottom py-3">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group mb-3 ">
                                            <label className="form-label required">Designation</label>
                                            <div>
                                                <input
                                                    type="text"
                                                    name="searchDesignationName"
                                                    id="searchDesignationName"
                                                    className={`form-control`}
                                                    aria-describedby="deptHelp"
                                                    placeholder="Enter Designation"
                                                    onChange={(e) => setSearchDesignation(e.target.value)}
                                                    value={searchDesignationName}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className="mb-3">
                                            <label className="form-label required">
                                                Status
                                            </label>

                                            <select
                                                className="form-select form-control"
                                                name="searchDesignationStatus"
                                                id="searchDesignationStatus"
                                                placeholder="Select status"
                                                onChange={(e) => setSearchDesignationStatus(e.target.value) } value={searchDesignationStatus}
 >
                                                <option value="">Select</option>
                                                <option value="1">Active</option>
                                                <option value="0">Deactive</option>

                                            </select>


                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                    <div className="mb-3">
                                    <label className="form-label required">
                                                        Select Department
                                                    </label>
                                                   
                                                        <select name="searchDepartmentId" id="searchDepartmentId" className={`form-select form-control ${validate.validate && validate.validate.searchDepartmentId
                                                            ? "is-invalid "
                                                            : ""
                                                            }`} placeholder="Select department" onChange={(e) => setSearchDepartmentId(e.target.value)} value={searchDepartmentId}>
                                                            <option>Select Department</option>

                                                            {deptData.map((d, index) => (

                                                                <option key={d.id} value={d.id}>
                                                                    {d.name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                    </div>


                                    <div className="col-md-2">
                                    <label className="form-label required">
                                                        Total Leaves
                                                    </label>
                                                    <div>
                                                        <input
                                                            type="number"
                                                            name="searchleavesAllocated"
                                                            id="searchleavesAllocated"
                                                            className={`form-control ${validate.validate && validate.validate.searchleavesAllocated
                                                                ? "is-invalid "
                                                                : ""
                                                                }`}
                                                            aria-describedby="searchTotalLeaves"
                                                            placeholder="Enter total leaves"
                                                            onChange={(e) => setSearchleavesAllocated(e.target.value)}
                                                            value={searchLeavesAllocated}
                                                        />
                                                    </div>

                                                    </div>

                                    <div className="col-md-2">
                                        <div className="form-group mb-3 ">
                                            <label className="form-label">&nbsp;</label>
                                            <div>
                                                <button
                                                    type="button"
                                                    name="btnSearch"
                                                    id="btnSearch"
                                                    className="btn btn-blue d-none d-sm-inline-block ml-3"
                                                    onClick={designationSearch}
                                                ><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <circle cx="10" cy="10" r="7"></circle>
                                                        <line x1="21" y1="21" x2="15" y2="15"></line>
                                                    </svg>Search</button>&nbsp;
                                                <button
                                                    type="button"
                                                    name="btnReset"
                                                    id="btnReset"
                                                    className="btn btn-outline-primary "
                                                    onClick={clearSearch}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                                    </svg>Clear</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">

                                <DataTable
                                    columns={columns}
                                    data={data}
                                    progressPending={loading}
                                    progressComponent={<CustomLoader />}
                                    pagination="true"
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    //onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                    paginationPerPage={perPage}
                                    paginationComponent={BootyPagination}
                                    sortServer
                                    onSort={handleSort}
                                />

                            </div>
                            <div className="card-footer d-flex align-items-center">
                                <p className="m-0 text-muted">
                                    Showing {/* <span>1</span> to <span>8</span> of{" "} */}
                                    <span>{totalRecords}</span> entries
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Modal size="lg" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{action} Designation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <form method="POST"
                    onSubmit={handleSubmit}
                    autoComplete={"off"}>
                    <div className="row">
                                <div className="col-md-6">
                                    <label className="form-label required">
                                        Designation Name
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            name="designationName"
                                            id="designationName"
                                            className={`form-control ${validate.validate && validate.validate.designationName
                                                ? "is-invalid "
                                                : ""
                                                }`}
                                            aria-describedby=""
                                            placeholder="Enter Designation name"
                                            onChange={(e) => setDesignationName(e.target.value)}
                                            value={designationName}
                                        />
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <input
                                        type="checkbox"
                                        className="checkbox"
                                        name="isHead"
                                        id="isHead"
                                        onChange={(e) => HandleIsHeadChecked(e.target.value)}
                                        value={isHead === "1" ? true : false}
                                        checked={isHead === "1" || isHead == true ? "checked" : ""}
                                    /><label className="form-label required">
                                        Is head 
                                    </label>                                    
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label required">
                                        Select Department
                                    </label>
                                    <div>
                                        <select name="departmentId" id="departmentId" className={`form-select form-control ${validate.validate && validate.validate.departmentId
                                            ? "is-invalid "
                                            : ""
                                            }`} placeholder="Select department" onChange={(e) => setDepartmentId(e.target.value)} value={departmentId}>
                                            <option value="">Select Department</option>

                                            {deptData.map((d, index) => (

                                                <option key={`dept_id_+${d.id}`} value={d.id}>
                                                    {d.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <label className="form-label required">
                                        Total Leaves
                                    </label>
                                    <div>
                                        <input
                                            type="number"
                                            name="totalLeaves"
                                            id="totalLeaves"
                                            className={`form-control ${validate.validate && validate.validate.totalLeaves
                                                ? "is-invalid "
                                                : ""
                                                }`}
                                            aria-describedby="totalLeaves"
                                            placeholder="Enter total leaves"
                                            onChange={(e) => setTotalLeaves(e.target.value)}
                                            value={totalLeaves}
                                        />
                                    </div>
                                </div>


                                <div className="col-md-6">
                                    <label className="form-label required">
                                        Status
                                    </label>

                                    <select
                                        className={`form-select form-control ${validate.validate && validate.validate.status
                                            ? "is-invalid "
                                            : ""
                                            }`}
                                        name="status"
                                        id="status"
                                        placeholder="Select status"
                                        onChange={(e) => setStatus(e.target.value)}
                                        value={status}>
                                        <option value="">Select</option>
                                        <option value="1">Active</option>
                                        <option value="0">Deactive</option>
                                     </select>

                                </div>
                            </div>

                            <input type="hidden" name="designationId" id="designationId" value={designationId} />
                        </form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    </>
)
}

export default List