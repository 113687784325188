import { React, useState, useEffect, useContext, useRef, useMemo } from 'react'
import "../../../assets/css/table.css";
import Loading from "react-fullscreen-loading";
import { Link } from 'react-router-dom';
import HeaderSection from "../banner/HeaderSection";
import DivLoader from "../../../component/utilities/Spinner";
import DataTable from "react-data-table-component";
import axios from "axios";
import { toast } from "react-toastify";
import * as  CONST from "../../../component/config/Constant"
import Form from "../../../component/utilities/Forms";
import editImg from "../../../assets/img/icon/edit.svg";
import deleteImg from "../../../assets/img/icon/delete.svg";
import viewImg from "../../../assets/img/icon/index.png";
import { Modal, Button } from 'react-bootstrap';
import { AuthContext } from '../../../component/context/AuthContext';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ReactSwitch from 'react-switch';
import * as SwitchProps from '../../../component/utilities/ReactSwitchReactSwitchProps';
import JoditEditor from 'jodit-react';

function List() {
    const [authUserData, setAuthUserData] = useContext(AuthContext);
    const [fullLoading, setFullLoading]   = useState(false);
    const [validate, setValidate]         = useState({});
    const [processing, setProcessing]     = useState(false);
    const [data, setData]                 = useState([]);

    const [loading, setLoading]             = useState(false);
    const [activePage, setActivePage]       = useState(1);
    const [openingId, setOpeningId]         = useState(0);
    const MySwal                            = withReactContent(Swal)
    const [listLimit, setListLimit]         = useState(50);

    const [action, setAction]               = useState('');

    /** For Modalpopup */
    const [show, setShow]                   = useState(false);

    /* START DATATABLE */
    const [totalRecords, settotalRecords]   = useState(0);
    const [totalRows, setTotalRows]         = useState(0);
    const [perPage, setPerPage]             = useState(10);
    const [maxPageLimit, setMaxPageLimit]   = useState(10);
    const [minPageLimit, setMinPageLimit]   = useState(0);

    //Search
    const [searchTitle, setSearchTitle]         = useState('');
    const [searchStatus, setSearchStatus]       = useState('');
    const [searchLocation, setSearchLocation]   = useState('');
    const [dataSort, setDataSort] = useState({
        order: 'desc',
        order_by: 'id'
    });
    const pageNumberLimit = { perPage };

    //For Form
    const [jobTitle, setJobTitle]               = useState('');
    const [jobDescription, setJobDescription]   = useState('');
    const [status, setStatus]                   = useState(false);
    const [totalOpenings, setTotalOpenings]     = useState('');
    const [location, setLocation]               = useState('');
    const [expRequired, setExpRequired]         = useState('');
    const [openingType, setOpeningType]         = useState('');

    const editor = useRef(null);
  
    const config = useMemo(
        () => ({
            readonly: false, 
            uploader: { "insertImageAsBase64URI": true }
        }),
        []
    );

    let isToken = '';
    let user_id = '';
    if (authUserData) {
        if (authUserData[0]) {
            isToken = authUserData[0].token ? authUserData[0].token : "";
            user_id = authUserData[0].user_id ? authUserData[0].user_id : "";
        }
    }

    const [searchParam, setSearchParam] = useState(
        {
            "srchTitle": searchTitle,
            "srchLocation": searchLocation,
            "srchStatus": searchStatus,
        }
    );

    useEffect(() => {
        fetchOpeningsData(searchParam, dataSort, activePage, perPage);
    }, [searchParam, dataSort, activePage, listLimit]);

    function handleShow(action, openingId) {
        const opening_id = openingId ? openingId : 0;
        setOpeningId(opening_id);
        setJobTitle("");
        setStatus("");
        setJobDescription("");
        setLocation("");
        setExpRequired("");
        setTotalOpenings("");

        if (opening_id !== 0) {
            fetchOpeningsById(opening_id);
        }
        setAction(action)
        setShow(true);
    }


    async function OpeningsSearch() {
        setSearchParam({
            "srchTitle": searchTitle,
            "srchLocation": searchLocation,
            "srchStatus": searchStatus
        })
        setDataSort({
			order: "desc",
			order_by: "id"
		});
		setActivePage("1");
    }

    function clearSearch() {
        setSearchTitle('');
        setSearchStatus('');
        setSearchLocation('');

        setSearchParam({
            "srchTitle": '',
            "srchLocation": '',
            "srchStatus": '',
        })
        setDataSort({
			order: "desc",
			order_by: "id"
		});
		setActivePage("1");
    }

    const handleClose = () => {
        setShow(false);
        setOpeningId(0);
        setJobTitle("");
        setStatus("");
        setTotalOpenings("");
        setJobDescription("");
        setLocation("");
        setExpRequired("");
    }

    function deleteHandler(delete_opening_id) {
        Swal.fire({
			title: 'Are you sure?',
			text: "Do you want to delete this openings?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, delete it!'
		}).then((result) => {
			if (result.isConfirmed) {
                setLoading(true);
                if (delete_opening_id > 0) {
                    setLoading(true);
                    axios
                        .delete(CONST.DELETE_OPENINGS, {
                            headers: {
                                'Authorization': `Bearer ${isToken}`
                            },
                            data: {
                                openingId: delete_opening_id
                            }
                        })
                        .then((response) => {
                            if (response) {
                                if (response.data.status == 'error') {
                                    toast.error(response.data.message);
                                } else {
                                    toast.success(response.data.message);
                                    fetchOpeningsData(searchParam, dataSort, activePage, perPage);
                                }
                            }
                        })
                        .catch((error) => {
                            setLoading(false);
                            toast.error("Something went wrong. Please try again." + error);
                        });
                        setLoading(false);
                }
			}
		})
    }

    //fetch all openings data by id
    async function fetchOpeningsById(openingId) {
        setLoading(true);
        await axios
            .get(CONST.GET_OPENINGS_BY_ID + `${openingId}`, {
                headers: {
                    'Authorization': `Bearer ${isToken}`
                }
            })
            .then((response) => {
                const status = response.data.status;
                console.log(response);
                if (status === 'success') {
                    let openingsData = response.data.data ? response.data.data : [];
                    setJobTitle(openingsData['title'] ? openingsData['title'] : '' );
                    setOpeningId(openingsData['id'] ? openingsData['id'] : '');
                    setStatus(openingsData['status'] ? openingsData['status'] : '');
                    setTotalOpenings(openingsData['total_openings'] ? openingsData['total_openings'] : '');
                    setJobDescription(openingsData['job_description'] ? openingsData['job_description'] : '');
                    setLocation(openingsData['location'] ? openingsData['location'] : '');
                    setExpRequired(openingsData['exp_required'] ? openingsData['exp_required'] : '');
                    setOpeningType(openingsData['type_of_opening'] ? openingsData['type_of_opening'] : '');
                }
            })
            .catch((error) => {
                alert(error);
            });
        setLoading(false);
    }

    //fetch all Openings details
    async function fetchOpeningsData(paramSearch, paramSort, paramPage, paramLimit) {
        setLoading(true);
        let searchParams = JSON.stringify({
            'limit'         : paramLimit,
            'search_input'  : paramSearch,
            'page_number'   : paramPage,
            'sort'          : paramSort
        })
        await axios
            .post(CONST.GET_OPENINGS_LIST, {
                'limit': paramLimit,
                'search_input': paramSearch,
                'page_number': paramPage,
                'sort': paramSort
            }, {
                headers: {
                    'Authorization': `Bearer ${isToken}`
                }
            })

            .then((response) => {
                const status = response.data.status;

                if (status === 'success') {
                    setData(response.data.data);
                    setTotalRows(response.data.count);
                    settotalRecords(response.data.count);
                }
                else{
                    toast.error(response.data.message);
                }
            })
            .catch((error) => {
            });
        setLoading(false);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let isValid = true;

        let validator = Form.validator({
            jobTitle: {
                value: jobTitle,
                isRequired: true,
                label: "Title",
            },
            jobDescription: {
                value: jobDescription,
                isRequired: true,
                label: "Job Description",
            },
            totalOpenings: {
                value: totalOpenings,
                isNumber: true,
                isRequired: true,
                label: "Total Openings",
            },
            expRequired: {
                value: expRequired,
                isRequired: true,
                label: "Experience Required",
            },
            location: {
                value: location,
                isRequired: true,
                label: "Location",
            },
            openingType:{
                value: openingType,
                isRequired: true,
                label: "Opening type",
            }
        });

        if (validator !== null) {
            setValidate({
                validate: validator.errors,
            });
            isValid = false;
        }

        if (isValid) {
            let apiUrl = '';
            let sucessMessage = '';
            //let strMethod = '';
            if (openingId == 0) {
                apiUrl = CONST.ADD_OPENINGS;
                //strMethod = 'post';
                sucessMessage = 'Openings added succesfully';
            } else {
                apiUrl = CONST.EDIT_OPENINGS;
                //strMethod = 'put';
                sucessMessage = 'Openings updated succesfully';
            }

            await axios({
                method: 'post',
                url: apiUrl,
                data: {
                    id              : user_id,
                    title           : jobTitle,
                    total_openings  : totalOpenings,
                    status          : status === true ? "1" : "0",
                    exp_required    : expRequired,
                    location        : location,
                    job_description : jobDescription,
                    openingId       : openingId,
                    type_of_opening : openingType
                },
                headers: {
                    'Authorization': `Bearer ${isToken}`
                }
            })
            .then((response) => {
                if (response) {
                    if (response.data.status == 'error') {
                        toast.error("Something went wrong. Please try again.");
                    } else {
                        toast.success('Openings added succesfully');
                        fetchOpeningsData(searchParam, dataSort, activePage, perPage);
                        handleClose();
                    }
                }
            })
            .catch((error) => {
                setLoading(false);
                toast.error("Something went wrong. Please try again.");
            });
        } 
    }

    const handleChangeStatus = (index, status_value, opening_id) => {
        Swal.fire({
			title: 'Are you sure?',
			text: "Do you want to change the status?",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, change it!'
		}).then((result) => {
			if (result.isConfirmed) {
				setLoading(true);
				axios.post(CONST.UPDATE_OPENINGS_STATUS,
					{
                        'id': user_id,
						'openingId': opening_id,
						'status': (status_value == '0') ? '1' : '0',
					},
					{
						headers: {
							'Authorization': `Basic ${isToken}`
						}
					}
				)
					.then((response) => {
						const status = response.data.status;
						if (status == 'success') {
							toast.success(response.data.message);
						} else {
							toast.error(response.data.message);
						}
					})
					.catch((error) => {
						toast.error("There was an error.");
					});
				setLoading(false);
                
				const newData = [...data];
    			newData[index].status= (status_value == 0) ? 1 : 0;
				setData(newData);
			}
		})
    }

    const columns = [
        {
            name: 'Sr. No',
            selector: (row, index) => index + 1,
            width: "5%",
        },
        {
            name: 'Title',
            selector: (row) => row.title,
            width: "15%",
        },
        {
            name: 'Total Openings',
            selector: (row) => row.total_openings,
            width: "10%",
        },
        {
            name: 'Experience Required',
            selector: (row) =>  row.exp_required,
            width: "10%",
        },
        /* {
            name: 'Job Description',
            selector: (row) =>  row.job_description.length > 200 ? row.job_description.substring(0, 100) + "..." :  row.job_description,
            width: "20%",
        }, */
        {
            name: 'Location',
            selector: (row) => row.location,
            width: "10%",
        },
        {
            name: 'Applied Count',
            selector: (row) => row.applied_count,
            width: "10%",
        },
        {
            name: 'Status',
            selector: (row) => 'Active',
            width: "10%",
            selector: (row, index) => <ReactSwitch
                onColor={SwitchProps.onColor}
                onHandleColor={SwitchProps.onHandleColor}
                handleDiameter={SwitchProps.handleDiameter}
                uncheckedIcon={SwitchProps.uncheckedIcon}
                checkedIcon={SwitchProps.checkedIcon}
                boxShadow={SwitchProps.boxShadow}
                activeBoxShadow={SwitchProps.activeBoxShadow}
                height={SwitchProps.height}
                width={SwitchProps.width}
                className={SwitchProps.className}
                checked={(row.status == 1) ? true : false}
                onChange={() => { handleChangeStatus(index, row.status, row.id) }}
            />,
        },
        {
            name: 'Action',
            width: "20%",
            wrap: true,
            sortable: false,
            cell: (row) => <Action row={row} />,
        },
    ]

    const Action = ({ row }) => (
        <>
            <Link to={'#'} data-id={row.id} onClick={() => handleShow('Edit', row.id)}>
                <img src={editImg} alt='editImg' width={32} className='cursor' />
            </Link>
            <Link onClick={() => deleteHandler(row.id) }>
                <img src={deleteImg} alt='deleteImg' width={32} className='cursor' />
            </Link>
            <Link to={CONST.ADMIN_PRE + 'applicationList/'+row.id}>
                <img src={viewImg} alt='editImg' width={30} className='cursor' />
            </Link>
         </>
    )

    const handleSort = async (column, sortDirection) => {
        setActivePage(1);
        setSearchParam({
            search: 'no',
            name: '',
            status: '',
        });

        setDataSort({
            order: sortDirection,
            order_by: column.sortField
        })
        /*  setLoading(false); */

    };
    /*  PAGINATION FUNCTIONS  */
    const handlePageChange = async (page) => {
        setActivePage(parseInt(page));
    };

    const BootyPagination = ({
        rowsPerPage,
        rowCount,
        onChangePage,
        onChangeRowsPerPage, // available but not used here
        currentPage,
    }) => {

        function toPages(pages) {
			const results = [];
			for (let i = 1; i <= pages; i++) {
				results.push(i);
			}
			return results;
		}
		function getNumberOfPages(rowCount, rowsPerPage) {
			return Math.ceil(rowCount / rowsPerPage);
		}


        const handleBackButtonClick = async () => {
            if ((currentPage - 1) % pageNumberLimit === 0) {
                setMaxPageLimit(maxPageLimit - pageNumberLimit);
                setMinPageLimit(minPageLimit - pageNumberLimit);
            }
            onChangePage(currentPage - 1);
        };

        const handleNextButtonClick = async () => {
            if (currentPage + 1 > maxPageLimit) {
                setMaxPageLimit(maxPageLimit + pageNumberLimit);
                setMinPageLimit(minPageLimit + pageNumberLimit);
            }

            onChangePage(currentPage + 1);
        };

        const handlePageNumber = (e) => {
            onChangePage(Number(e.target.value));
        };

        let paginationText = "Page:  " + currentPage + ", Showing ";

        paginationText =
            paginationText +
            ((currentPage - 1) * perPage + 1) +
            " to " +
            perPage * currentPage;
        paginationText = paginationText + " of " + rowCount + " entries;";

        const pages = getNumberOfPages(rowCount, rowsPerPage);
        const pageItems = toPages(pages);
        const nextDisabled = currentPage === pageItems.length;
        const previosDisabled = currentPage === 1;

        const pageNumbers = pageItems.map((page) => {
            if (page <= maxPageLimit && page > minPageLimit) {
                return (
                    <li
                        key={page}
                        id={page}
                        className={`page-item ${currentPage === page ? "active" : ""}`}
                    >
                        <button
                            className={`page-link ${currentPage === page ? "active" : ""}`}
                            onClick={handlePageNumber}
                            value={page}
                        >
                            {page}
                        </button>
                    </li>
                );
            } else {
                return null;
            }
        });
        let pageIncrementEllipses = null;
        if (pageItems.length > maxPageLimit) {
            pageIncrementEllipses = (
                <li className="page-item" onClick={handleNextButtonClick}>
                    &hellip;
                </li>
            );
        }
        let pageDecremenEllipses = null;
        if (minPageLimit >= 1) {
            pageDecremenEllipses = (
                <li className="page-item" onClick={handleBackButtonClick}>
                    &hellip;
                </li>
            );
        }
        return (
            <div className="card-footer d-flex align-items-center">
                <p className="m-0 text-muted">{paginationText}</p>
                <ul className="pagination m-0 ms-auto">
                    <li className="page-item">
                        <button
                            className="page-link"
                            onClick={handleBackButtonClick}
                            disabled={previosDisabled}
                            aria-disabled={previosDisabled}
                            aria-label="previous page"
                            pagerangedisplayed={10}
                        >
                        « 
                        </button>
                    </li >
                { pageDecremenEllipses }
                { pageNumbers }
                { pageIncrementEllipses }
                <li className="page-item">
                <button
                    className="page-link"
                    onClick={handleNextButtonClick}
                    disabled={nextDisabled}
                    aria-disabled={nextDisabled}
                    aria-label="next page">
                    »
                </button>
                </li>
            </ul >
        </div >
        );
};
/* END DATATABLE */
const CustomLoader = () => (
    <div style={{ padding: "24px" }}>
        <DivLoader color="primary" />
    </div>
);
return (
    <>
        <Loading
            loading={fullLoading}
            background="rgba(0, 0, 0, 0.5)"
            loaderColor="#fff"
        />
        <HeaderSection
            title="Manage"
            heading="Openings"
            addButton={() => handleShow('Add', 0)}
            addLink="#"
            addLinkName="Add openings"
            linktype="add"
        />
        <div className="page-body">
       
            <div className="container-xl">
                <div className="row row-cards">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title"> Listing </h3> 
                            </div>
                            
                            <div className="card-body border-bottom py-3">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="form-group mb-3 ">
                                            <label className="form-label required">Title</label>
                                            <div>
                                                <input
                                                    type="text"
                                                    name="searchTitle"
                                                    id="searchTitle"
                                                    className={`form-control`}
                                                    aria-describedby=""
                                                    placeholder="Enter Title"
                                                    onChange={(e) => setSearchTitle(e.target.value)}
                                                    value={searchTitle}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                        <div className="mb-3">
                                            <label className="form-label required">
                                                Status
                                            </label>

                                            <select
                                                className="form-select form-control"
                                                name="searchStatus"
                                                id="searchStatus"
                                                placeholder="Select status"
                                                onChange={(e) => setSearchStatus(e.target.value) } value={searchStatus}
 >
                                                <option value="">Select</option>
                                                <option value="1">Active</option>
                                                <option value="0">Deactive</option>

                                            </select>


                                        </div>
                                    </div>

                                    <div className="col-md-2">
                                    <label className="form-label required">
                                        Location
                                    </label>
                                    <div>
                                        <input type="text"  name="searchLocation" id="searchLocation"
                                            className={`form-control ${validate.validate && validate.validate.searchLocation
                                            ? "is-invalid" : "" }`}
                                            aria-describedby="searchLocation"
                                            placeholder="Enter Location"
                                            onChange={(e) => setSearchLocation(e.target.value)}
                                            value={searchLocation}  />
                                    </div>

                                    </div>

                                    <div className="col-md-2">
                                        <div className="form-group mb-3 ">
                                            <label className="form-label">&nbsp;</label>
                                            <div>
                                                <button
                                                    type="button"
                                                    name="btnSearch"
                                                    id="btnSearch"
                                                    className="btn btn-blue d-none d-sm-inline-block ml-3"
                                                    onClick={OpeningsSearch}
                                                ><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-search" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <circle cx="10" cy="10" r="7"></circle>
                                                        <line x1="21" y1="21" x2="15" y2="15"></line>
                                                    </svg>Search</button>&nbsp;
                                                <button
                                                    type="button"
                                                    name="btnReset"
                                                    id="btnReset"
                                                    className="btn btn-outline-primary "
                                                    onClick={clearSearch}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <line x1="18" y1="6" x2="6" y2="18"></line>
                                                        <line x1="6" y1="6" x2="18" y2="18"></line>
                                                    </svg>Clear</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="table-responsive">

                                <DataTable
                                    columns={columns}
                                    data={data}
                                    progressPending={loading}
                                    progressComponent={<CustomLoader />}
                                    pagination="true"
                                    paginationServer
                                    paginationTotalRows={totalRows}
                                    //onChangeRowsPerPage={handlePerRowsChange}
                                    onChangePage={handlePageChange}
                                    paginationPerPage={perPage}
                                    paginationComponent={BootyPagination}
                                    sortServer
                                    onSort={handleSort}
                                />

                            </div>
                            <div className="card-footer d-flex align-items-center">
                                <p className="m-0 text-muted">
                                    Showing {/* <span>1</span> to <span>8</span> of{" "} */}
                                    <span>{totalRecords}</span> entries
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Modal size="lg" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{action} Openings</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <form method="POST" onSubmit={handleSubmit} autoComplete={"off"}>
                <div className="row">
                <div className="col-md-6">
                    <label className="form-label required">
                        Title
                    </label>
                    <div>
                        <input
                            type="text"
                            name="jobTitle"
                            id="jobTitle"
                            className={`form-control ${validate.validate && validate.validate.jobTitle
                                ? "is-invalid "
                                : ""
                                }`}
                            aria-describedby=""
                            placeholder="Enter Title"
                            onChange={(e) => setJobTitle(e.target.value)}
                            value={jobTitle}
                        />
                    </div>
                </div>

                <div className="col-md-6">
                    <label className="form-label required">
                        Opening Type
                    </label>
                    <div>
                        <select
                            className={`form-select form-control ${validate.validate && validate.validate.openingType
                                ? "is-invalid "
                                : ""
                                }`}
                            name="openingType"
                            id="openingType"
                            placeholder="Select opening type"
                            onChange={(e) => setOpeningType(e.target.value)}
                            value={openingType}>
                            <option value="">Select</option>
                            <option value="1">Full-Time</option>
                            <option value="2">Part-Time</option>
                            <option value="3">Contractual</option>
                            </select>
                    </div>
                </div>

                <div className="col-md-12">
                    <label className="form-label required">
                     Job Description
                    </label>
                    <div>
                        {/* <textarea name="jobDescription"
                            id="jobDescription"
                            className={`form-control ${validate.validate && validate.validate.jobDescription ? "is-invalid ": "" }`}
                            aria-describedby=""
                            rows="30"
                            placeholder="Enter job description"
                            onChange={(e) => setJobDescription(e.target.value)}
                            value={jobDescription}></textarea> */}

                            <JoditEditor 
                                ref={editor}
                                config={config}
                                value={jobDescription}
                                tabIndex={1} // tabIndex of textarea
                                onBlur={newContent => setJobDescription(newContent)}
                                onChange={(newContent) => {}}
                                // className={`form-control ${validate.validate && validate.validate.policyDescription ? "is-invalid": "" }`}
                            />

                        

                        {/* <input
                            type="text"
                            name="jobDescription"
                            id="jobDescription"
                            className={`form-control ${validate.validate && validate.validate.jobDescription
                                ? "is-invalid "
                                : ""
                                }`}
                            aria-describedby=""
                            placeholder="Enter job description"
                            onChange={(e) => setJobDescription(e.target.value)}
                            value={jobDescription}
                        /> */}
                    </div>
                </div>


                <div className="col-md-6">
                    <label className="form-label required">
                     Experience Required
                    </label>
                    <div>
                        <input
                            type="text"
                            name="expRequired"
                            id="expRequired"
                            className={`form-control ${validate.validate && validate.validate.expRequired ? "is-invalid " : "" }`}
                            aria-describedby=""
                            placeholder = "Enter experience required"
                            onChange = {(e) => setExpRequired(e.target.value)}
                            value={expRequired}
                        />
                    </div>
                </div>

                <div className="col-md-6">
                    <label className="form-label required">
                        Total Openings
                    </label>
                    <div>
                        <input
                            type="number"
                            name="totalOpenings"
                            id="totalOpenings"
                            className={`form-control ${validate.validate && validate.validate.totalOpenings ? "is-invalid " : "" }`}
                            aria-describedby="totalOpenings"
                            placeholder="Enter total openings"
                            onChange={(e) => setTotalOpenings(e.target.value)}
                            value={totalOpenings}
                        />
                    </div>
                </div>

                <div className="col-md-6">
                    <label className="form-label required">
                        Location
                    </label>
                    <div>
                        <input
                            type="text"
                            name="location"
                            id="location"
                            className={`form-control ${validate.validate && validate.validate.location ? "is-invalid " : "" }`}
                            aria-describedby="location"
                            placeholder="Enter location"
                            onChange={(e) => setLocation(e.target.value)}
                            value={location}
                        />
                    </div>
                </div>                

                <div className="col-md-6">
                    <label className="form-label required">
                        Status
                    </label>

                    <select
                        className={`form-select form-control ${validate.validate && validate.validate.status
                            ? "is-invalid "
                            : ""
                            }`}
                        name="status"
                        id="status"
                        placeholder="Select status"
                        onChange={(e) => setStatus(e.target.value)}
                        value={status}>
                        <option value="">Select</option>
                        <option value="1">Active</option>
                        <option value="0">Deactive</option>
                        </select>

                </div>
            </div>

            <input type="hidden" name="openingId" id="openingId" value={openingId} />
            </form>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSubmit}>
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    </>
)
}

export default List