import React, { useContext, useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { AuthContext } from '../../../component/context/AuthContext';
import { toast } from "react-toastify";
import * as  CONST from "../../../component/config/Constant";
import axios from "axios";
import { Link } from 'react-router-dom';
import DivSpinner from '../../../component/utilities/Spinner';

const ViewLeaveDetails = ({ closeLeave, editLeaveId, viewData }) => {
  console.log(JSON.stringify(viewData))
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  const [show, setShow] = useState('show');
  const [loading, setLoading] = useState(false);
  const [leaveId, setLeaveId] = useState(editLeaveId);
  const [empDetails, setEmpDetails] = useState('');
  let isToken = '';
  let user_id = '';
  if (authUserData) {
    if (authUserData[0]) {
      isToken = authUserData[0].token ? authUserData[0].token : "";
      user_id = authUserData[0].user_id ? authUserData[0].user_id : "";
    }
  }

  const formatTime = (date) => {
    return date.slice(0, -3);
  }
  const fetchEmployee = async () => {
    console.log(user_id, viewData.user_id);
    await axios
      .post(CONST.GET_EMP_BY_ID, {
        id: user_id,
        user_id: viewData.user_id
      }, {
        headers: {
          'Authorization': `Basic ${isToken}`
        }
      })
      .then((response) => {
        const status = response.data.status;
        console.log(status);
        if (status === 'success') {
          setLoading(true);
          console.log(response.data.data);
          setEmpDetails(response.data.data);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  }

  useEffect(() => {
    fetchEmployee();
  }, []);
  return (
    <>
      <Modal
        size="lg"
        show={show}
        onHide={() => closeLeave('hide')}
        backdrop="static"
        keyboard={false}
        centered
        fullscreen={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Leave Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row mt-2'>
            <div className='col-4 mb-5'>
              {
                empDetails ? (
                  <div>

                    <div className="d-flex py-1 align-items-center">
                      {
                        (empDetails.profile_photo_path && empDetails.profile_photo_path)
                          ? (
                            <span className="avatar    " >

                              <img src={CONST.HOST + empDetails.profile_photo_path} alt='' width={40} className="rounded-circle" />
                            </span>
                          ) : (
                            <span className="avatar  rounded-circle  ">
                              <span className=''>
                                {
                                  (empDetails.first_name && empDetails.last_name)
                                    ? <> {empDetails.first_name && empDetails.first_name.substring(0, 1)}
                                      {empDetails.last_name && empDetails.last_name.substring(0, 1)}</>
                                    : empDetails.name && empDetails.name.substring(0, 2)
                                }

                              </span>
                            </span>)

                      }

                      <div className="flex-fill pl-2">
                        <div className="font-weight-medium color_blue">
                          {(empDetails.first_name && empDetails.last_name)
                            ? <>{empDetails.first_name} {empDetails.last_name}</>
                            : empDetails.name
                          }

                        </div>
                        <div className="text-muted">
                          <Link target="_blank" to={`mailto:${empDetails.email}`} className="text-muted">
                            {empDetails.email.toLowerCase()}
                          </Link>
                        </div>
                        <div className='pt-2'>
                          {(() => {

                            if (viewData.e_compoff.status == '1') {
                              return (
                                <>
                                  <span className="badge bg-success f-14">Comp-Off is approved by {viewData.e_compoff.compoffApprovedByUser.first_name + ' ' + viewData.e_compoff.compoffApprovedByUser.last_name}.</span>
                                </>
                              )
                            } else if (viewData.e_compoff.status == '2') {
                              return (
                                <>
                                  <span className="badge bg-warning f-14">Comp-Off is rejected by {viewData.e_compoff.CompoffApproveRejectByData[0].CompoffApproveRejectUserData.first_name + ' ' + viewData.e_compoff.CompoffApproveRejectByData[0].CompoffApproveRejectUserData.last_name}.</span>
                                </>
                              )
                            } else if (viewData.e_compoff.status == '3') {
                              return (
                                <>
                                  <span className="badge bg-danger text-danger-fg f-14">Employee has cancelled the Comp-Off.</span>
                                </>
                              )
                            } else {
                              return (
                                <>
                                  <span className="badge bg-info f-14">Comp-Off is pending.</span>
                                </>
                              )
                            }


                          })()
                          }
                        </div>

                      </div>

                    </div>
                    <div className="form-group mb-3 mt-3 ">
                      <label className="form-label">
                        <b>
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-arrow-bear-right-2 pr-1 " width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                            <path d="M15 3h5v5"></path>
                            <path d="M20 3l-7.536 7.536a5 5 0 0 0 -1.464 3.534v6.93"></path>
                            <path d="M4 5l4.5 4.5"></path>
                          </svg>
                        </b>
                        {
                          empDetails.department && (
                            <>
                              <span className='color_blue'> {empDetails.department.name}</span> {empDetails.designation && (
                                <>
                                  /  <span className='color_blue'> {empDetails.designation.name}</span>
                                </>
                              )
                              }
                            </>
                          )

                        }
                      </label>
                    </div>
                    <div className="form-group mb-3 ">
                      <label className="form-label">
                        <b>Comp-Off Type: </b><span class="info">{(viewData.leave_type == '0') ? 'Half day' : 'Full day'}</span>
                      </label>

                    </div>
                    <div className="form-group mb-3 ">
                      <label className="form-label ">
                        <b>Applied Date</b> : {viewData.compoff_date}
                      </label>

                    </div>
                  </div>
                ) : (
                  < div className='p-3 text-center'>
                    <DivSpinner size="sm" color="primary" />

                  </div>
                )


              }
            </div>
            <div className='col-8'>

              <div className="form-group mb-3 ">
                <label className="form-label ">
                  {
                    viewData.e_compoff.CompoffToMailData.map((element, key) => {

                      return (
                        <>
                          <span className='pr-5'>
                            <b>To : </b>
                            <span>{element.user.first_name + ' ' + element.user.last_name}</span>
                          </span>
                        </>
                      )
                    })

                  }

                  {
                    viewData.e_compoff.CompoffCcMailData.map((element, key) => {

                      return (
                        <>
                          <span className='pr-5'>
                            <b>CC : </b>
                            <span>{element.user.first_name + ' ' + element.user.last_name}</span>
                          </span >
                        </>
                      )
                    })

                  }

                </label>
                <hr />
              </div>
              <div className="form-group mb-3 ">
                <label className="form-label">
                  <h5><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-mail-question mr-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M15 19h-10a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v4.5"></path>
                    <path d="M19 22v.01"></path>
                    <path d="M19 19a2.003 2.003 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483"></path>
                    <path d="M3 7l9 6l9 -6"></path>
                  </svg><span class="info" dangerouslySetInnerHTML={{ __html: viewData.e_compoff.subject }} /></h5>
                </label>
                <hr />
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group mb-3 ">
                    <span class="info" dangerouslySetInnerHTML={{ __html: viewData.e_compoff.reason }} />
                  </div>
                </div>
                {(() => {
                  if ((viewData.e_compoff.compoffAddedBy.id == user_id) && viewData.e_compoff.e_compoffUsers) {
                    let e_compoffUsers = viewData.e_compoff.e_compoffUsers;
                    console.log(e_compoffUsers.length)
                    if (e_compoffUsers.length > 1) {
                      return (
                        <>
                          <label className="form-label bold"> Other Employees :  </label>
                        </>
                      )

                    } 
                  }
                })()}
                {
                  viewData.e_compoff.e_compoffUsers.map((element, key) => {
                    if (element.compoffUserData.id != user_id && (viewData.e_compoff.compoffAddedBy.id == user_id)) {
                      return (
                        <>
                          <div className="form-group mb-3 ">
                            <span>  {`${(key+1)})`}  <b> {element.compoffUserData.first_name} {element.compoffUserData.last_name}: </b> ({element.compoffUserData.email})</span>
                            <br />
                            <span> {(element.leave_type == '0') ? 'Half day' : 'Full day'} </span>
                          </div>

                        </>
                      )
                    }
                  })
                }
                <div className="form-group mb-3 ">
                  <label className="form-label bold">
                    Comments:
                  </label>
                  <span> {viewData.e_compoff.compoff_comment ?? '-'} </span>

                </div>
              </div>
            </div>
          </div>

        </Modal.Body>

      </Modal >
    </>
  );
};

export default ViewLeaveDetails;