import { React, useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import * as CONST from "../../../component/config/Constant";
import axios from "axios";
import { toast } from "react-toastify";
import { AuthContext } from "../../../component/context/AuthContext";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import Form from "../../../component/utilities/Forms";

function Technologies() {
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [listLimit, setListLimit] = useState(50);
  const [techQuestionData, setTechQuestionData] = useState([]);
  const [technologyName, setTechnologyName] = useState("");
  const [versions, setVersions] = useState([]);
  const [techVersion, setTechVersion] = useState("");
  const [techVersionId, setTechVersionId] = useState("");
  const [questionDescription, setQuestionDescription] = useState("");
  const [questionIndex, setQuestionIndex] = useState(0);
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");
  const [validate, setValidate] = useState({});
  const [questCommentData, setQuestCommentData] = useState([]);
  const [isReply, setIsReply] = useState(false);
  const [isCommentId, setIsCommentId] = useState("");
  const [commentIndex, setCommentIndex] = useState("");
  const [userReply, setUserReply] = useState("");

  /* START DATATABLE */
  const [perPage, setPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);

  //Search
  const [searchTechnologyStatus, setSearchTechnologyStatus] = useState("");
  const [dataSort, setDataSort] = useState({
    order: "desc",
    order_by: "id",
  });

  const [searchParam, setSearchParam] = useState({
    srchStatus: searchTechnologyStatus,
  });

  //Comment Sort 
  const [commentDataSort, setCommentDataSort] = useState({
    order: "desc",
    order_by: "id",
  });

  //comment limit
  const [commentPerPage, setCommentPerPage] = useState(10);
  //page no
  const [commentActivePage, setCommentActivePage] = useState(1);

  const params = useParams();
  let technologyId = params.id;

  let isToken = "";
  let loginUserName = "";
  let loginUserEmail = "";
  let loginUserId = "";

  if (authUserData) {
    if (authUserData[0]) {
      isToken = authUserData[0].token ? authUserData[0].token : "";
      var fname = authUserData[0].first_name ? authUserData[0].first_name : "";
      var lname = authUserData[0].last_name ? authUserData[0].last_name : "";
      if (fname !== "" && lname !== "") {
        loginUserName = fname + " " + lname;
      }
      loginUserEmail = authUserData[0].email ? authUserData[0].email : "";
      loginUserId = authUserData[0].user_id ? authUserData[0].user_id : "";
    }
  }
  const styles = {
    checkUserStyle: {
      display: loginUserId ? 'none' : 'block'
    }
  };

  useEffect(() => {
    fetchTechnologyById(technologyId);
    setUserName(loginUserName);
    setEmail(loginUserEmail);
  }, []);

  const replyHandler = (commentId, index) => {
    setIsReply(!isReply);
    setIsCommentId(commentId);
    setCommentIndex(index);
  }

  const handleOnchange = (e) => {
    //console.log("val >>> ");
    //console.log(e.target);
    //console.log(e.target.children);
    //console.log(e.target.value);

    var index = e.nativeEvent.target.selectedIndex;
    // e.nativeEvent.target[index].text;
    // console.log("label >>> ");
    // console.log(e.nativeEvent.target[index].text);
    var val = e.nativeEvent.target[index].text;
    var versionId = e.target.value;

    setTechVersion(val);
    setTechVersionId(versionId);
    fetchTechnologyQuestionData(
      searchParam,
      dataSort,
      activePage,
      perPage,
      technologyId,
      val,
      versionId
    );
    //console.log("questionId >> " + questionId);
    // fetchQuestionCommentReplyList(
    //   commentDataSort,
    //   commentActivePage,
    //   commentPerPage,
    //   versionId
    // );
  };

  const handlePrevDescription = (prevIndex) => {
    if (techQuestionData.length > prevIndex) {
      const strDescription =
        techQuestionData[prevIndex].questionData.description;
      setQuestionDescription(strDescription);
    }
    setQuestionIndex(prevIndex);
    //fetchQuestionCommentReplyList(commentDataSort,commentActivePage,commentPerPage,techVersionId,qId); 
  };

  const handleNextDescription = (nextIndex) => {
    if (techQuestionData.length > nextIndex) {
      const strDescription =
        techQuestionData[nextIndex].questionData.description;
      setQuestionDescription(strDescription);
    }
    setQuestionIndex(nextIndex);
    //fetchQuestionCommentReplyList(commentDataSort,commentActivePage,commentPerPage,techVersionId,qId); 
  };

  const handleGetDescription = (qId, index) => {
    const descriptionData = techQuestionData.filter(
      (d) => d.questionData.id === qId
    )[0].questionData.description;
    setQuestionDescription(descriptionData);
    var intIndex = index;
    setQuestionIndex(intIndex);

    fetchQuestionCommentReplyList(commentDataSort, commentActivePage, commentPerPage, techVersionId, qId);
  };

  const submitComment = async (e) => {
    e.preventDefault();
    let isValid = true;

    let validator = Form.validator({
      userName: {
        value: userName,
        isRequired: true,
        label: "User Name",
      },
      email: {
        value: email,
        isRequired: true,
        label: "Email",
      },
      comment: {
        value: comment,
        isRequired: true,
        label: "Comment",
      },
    });

    if (validator !== null) {
      setValidate({
        validate: validator.errors,
      });
      isValid = false;
    }
    else {
      setValidate({
        validate: "",
      });
      isValid = true;
    }
    if (isValid) {
      if (technologyId !== 0) {
        await axios({
          method: "post",
          url: CONST.ADD_COMMENT_FOR_QUESTION,
          data: {
            name: userName,
            email: email,
            comment: comment,
            technology_id: technologyId,
            question_id: techQuestionData[questionIndex].question_id,
            version_id: techQuestionData[questionIndex].id,
            user_id: loginUserId !== "" ? loginUserId : 0,
            is_guest_user: loginUserId !== "" ? 0 : 1,
          },
        })
          .then((response) => {
            if (response) {
              if (response.data.status == "error") {
                toast.error("Something went wrong. Please try again.");
              } else {
                toast.success("Comment added succesfully");
                setComment("");
                setEmail(loginUserId ? loginUserEmail : "");
                setUserName(loginUserId ? loginUserName : "");
                //fetchTechnologyData(searchParam, dataSort, activePage, perPage);
              }
            }
          })
          .catch((error) => {
            setLoading(false);
            toast.error("Something went wrong. Please try again.");
          });
      }

    }
  };

  //fetch all question comment reply details
  async function fetchQuestionCommentReplyList(
    paramSort,
    paramPage,
    paramLimit,
    versionId,
    questionId
  ) {

    setLoading(true);

    await axios
      .post(
        CONST.GET_QUESTION_COMMENT_REPLY_DETAILS,
        {
          question_id: questionId,
          version_id: versionId,
          limit: paramLimit,
          page_number: paramPage,
          sort: paramSort
        }
      )
      .then((response) => {
        const status = response.data.status;
        if (status === "success") {
          setQuestCommentData(response.data.data);
          // setQuestionDescription(
          //   response.data.data[0].questionData.description
          // );
        } else {
          // toast.error(response.data.message);
          setQuestCommentData("");
          //setQuestionDescription("");
        }
      })
      .catch((error) => { });
    setLoading(false);
  }

  //fetch all technology details
  async function fetchTechnologyQuestionData(
    paramSearch,
    paramSort,
    paramPage,
    paramLimit,
    technologyId,
    techVersion,
    versionId
  ) {
    setLoading(true);

    await axios
      .post(
        CONST.GET_TECH_QUESTIONS,
        {
          technology_id: technologyId,
          version: techVersion,
          limit: paramLimit,
          search_input: paramSearch,
          page_number: paramPage,
          sort: paramSort,
        },
      )
      .then((response) => {
        const status = response.data.status;
        if (status === "success") {
          setTechQuestionData(response.data.data);
          setTotalRows(response.data.count);
          //setQuestionId(response.data.data[0].question_id);
          setQuestionDescription(
            response.data.data[0].questionData.description
          );

          fetchQuestionCommentReplyList(
            commentDataSort,
            commentActivePage,
            commentPerPage,
            versionId,
            response.data.data[0].question_id);
        } else {
          toast.error(response.data.message);
          setTechQuestionData("");
          setQuestionDescription("");
        }
      })
      .catch((error) => { });
    setLoading(false);
  }

  async function fetchTechnologyById(technologyId) {
    setLoading(true);
    let apiUrl = CONST.GET_TECH_DETAILS_BY_ID + `${technologyId}`;
    let strMethod = "put";
    await axios({
      method: strMethod,
      url: apiUrl,
    })
      .then((response) => {
        const status = response.data.status;
        if (status === "success") {
          let technologyData = response.data.data ? response.data.data : [];
          setTechnologyName(
            technologyData["name"] ? technologyData["name"] : ""
          );

          console.log('technologyData >>> ');
          console.log(technologyData);

          // let arrVersion = technologyData["technologies_has_versions"]
          //   ? technologyData["technologies_has_versions"].map(
          //       (v, index) => v.version
          //     )
          //   : "";

          let arrVersion = technologyData["technologies_has_versions"]
            ? technologyData["technologies_has_versions"].map((v, index) => ({
              id: v.id,
              value: v.version,
            })) : "";

          // console.log("arrTechVersion >> ");
          // console.log(arrTechVersion);
          setVersions(arrVersion);
        }
      })
      .catch((error) => {
        //alert(error);
      });
    setLoading(false);
  }

  return (
    <>
      <div className="page-wrapper">
        <div className="page-header d-print-none">
          <div className="container-xl">
            <div className="row g-2 align-items-center">
              <div className="col">

                <div className="section-title mt-30"><Link to={"/technologies"}
                  className="icon icon-tabler icon-tabler-arrow-narrow-left">Back</Link>
                  <h3 className="mb-15 text-center">{technologyName}</h3></div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-body">
          <div className="container-xl">
            <div className="card">
              <div className="row g-0">
                <div className="col-12 col-lg-5 col-xl-3 border-end">
                  <div className="card-header d-none d-md-block">
                    <select
                      className="form-select form-control"
                      name="versions"
                      id="versions"
                      placeholder="Select version"
                      onChange={(e) => handleOnchange(e)}
                    //value={e.target.value}
                    >
                      <option>Select version</option>
                      {/* {versions.map((d) => (
                        <option key={`version_id_+${d}`} value={d}>
                          {d}
                        </option>
                      ))} */}

                      {versions.map((d) => (
                        <option key={`version_id_+${d.id}`} value={d.id}>
                          {d.value}
                        </option>
                      ))}

                    </select>
                  </div>
                  <div className="card-body scrollable">
                    <div
                      className="nav-link text-start mw-100 p-3  border-end"
                      role="tablist"
                    >
                      <ul className="list-group list-group-transparent">
                        {techQuestionData.length > 0
                          ? techQuestionData.map((qData, index) => {
                            return (
                              <>
                                <li
                                  className={`list-group-item list-group-item-action d-flex align-items-center ${index === questionIndex
                                    ? "color_blue"
                                    : ""
                                    }`}
                                  key={`q_id_+${qData.questionData.id}`}
                                  onClick={() =>
                                    handleGetDescription(
                                      qData.questionData.id,
                                      index
                                    )
                                  }
                                >
                                  {qData.questionData.title}
                                </li>
                              </>
                            );
                          })
                          : ""}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="card col-12 col-lg-7 col-xl-9 d-flex">
                  <div className="card-body scrollable">
                    {parse(questionDescription)}
                  </div>
                  <div className="card-footer">
                    {techQuestionData.length > 0 ? (
                      <p>
                        {questionIndex !== 0 ? (
                          <button
                            className="btn btn-primary"
                            onClick={() =>
                              handlePrevDescription(questionIndex - 1)
                            }
                          >
                            Prev
                          </button>
                        ) : (
                          ""
                        )}
                        {techQuestionData.length !== questionIndex + 1 ? (
                          <button
                            className="btn btn-primary"
                            onClick={() =>
                              handleNextDescription(questionIndex + 1)
                            }>
                            Next
                          </button>
                        ) : (
                          ""
                        )}{" "}
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            {techQuestionData.length
              ?
              <div className="row row-cards mt-30">
                <form method="POST" onSubmit={submitComment} autoComplete={"off"}>
                  <div className="col-12">
                    <div className="card-body">
                      <div className="row row-cards">

                        <div className="col-sm-6 col-md-6" style={styles.checkUserStyle}>
                          <div className="mb-3">
                            <label className="form-label">Name</label>
                            <input type="text" className={`form-control ${validate.validate && validate.validate.userName
                              ? "is-invalid " : ""}`} onChange={(e) => setUserName(e.target.value)} placeholder="Name" value={loginUserId ? loginUserName : userName} />
                          </div>
                        </div>
                        <div className="col-sm-6 col-md-6" style={styles.checkUserStyle}>
                          <div className="mb-3">
                            <label className="form-label">Email</label>
                            <input type="text" className={`form-control ${validate.validate && validate.validate.email
                              ? "is-invalid "
                              : ""
                              }`} onChange={(e) => setEmail(e.target.value)} placeholder="Email" value={loginUserId ? loginUserEmail : email} />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="mb-3 mb-0">
                            <textarea name="comment"
                              id="comment"
                              className={`form-control ${validate.validate && validate.validate.comment
                                ? "is-invalid "
                                : ""
                                }`} placeholder="Add your comment"
                              onChange={(e) => setComment(e.target.value)}
                              style={{ height: '100px !important' }}
                              value={comment ? comment : ""}
                            >{comment ? comment : ""}</textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-footer text-end">
                      <button className="btn btn-primary" onClick={submitComment}> Add </button>
                    </div>
                  </div>
                </form>
              </div>
              : ""}

            {questCommentData.length > 0
              ?
              <div className="card-body scrollable">
                <div className="chat">
                  <div className="chat-bubbles">
                    {questCommentData.map((cData, index) => {
                      let strName = {}
                      if (cData.name !== null) {
                        strName = cData.name.split(' ')
                      }
                      return (
                        <>
                          <div className="chat-item">
                            <div className="row align-items-end">
                              <div className="col-auto">
                                {cData.commentUserData !== null && cData.commentUserData.profile_photo_path && cData.commentUserData.profile_photo_path ?
                                  <span className="avatar">
                                    <img src={CONST.HOST + cData.commentUserData.profile_photo_path} alt='' width={40} className="rounded-circle" />
                                  </span> :

                                  <span className="avatar  rounded-circle">
                                    <span className=''>
                                      {(strName !== null && strName !== "") ? <>{strName[0].substring(0, 1) + "" + strName[1].substring(0, 1)}</>
                                        : ""}
                                    </span>
                                  </span>}

                              </div>
                              <div className="col col-lg-6">
                                <div className="chat-bubble">
                                  <div className="chat-bubble-title">
                                    <div className="row">
                                      <div className="col chat-bubble-author">
                                        {cData.commentUserData !== null && cData.commentUserData.first_name && cData.commentUserData.last_name ?
                                          cData.commentUserData.first_name + " " +
                                          cData.commentUserData.last_name : cData.name
                                        }
                                      </div>
                                    </div>
                                  </div>
                                  <div className="chat-bubble-body">
                                    <p>{cData.comment}</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {cData.commentReplyData.map(replyData => {
                            return (
                              <>
                                <div className="chat-item">
                                  <div className="row align-items-end justify-content-end">
                                    <div className="col-auto">
                                      {cData.commentUserData.profile_photo_path && cData.commentUserData.profile_photo_path ?
                                        <span className="avatar">
                                          <img src={CONST.HOST + cData.commentUserData.profile_photo_path} alt='' width={40} className="rounded-circle" />
                                        </span> :
                                        <span className="avatar rounded-circle">
                                          <span className=''>
                                            {(cData.commentUserData.first_name && cData.commentUserData.last_name)
                                              ? <> {cData.commentUserData.first_name && cData.commentUserData.first_name.substring(0, 1)}
                                                {cData.commentUserData.last_name && cData.commentUserData.last_name.substring(0, 1)}</>
                                              : ""
                                            }
                                          </span>
                                        </span>}
                                    </div>
                                    <div className="col col-lg-6">
                                      <div className="chat-bubble">
                                        <div className="chat-bubble-title">
                                          <div className="row">
                                            <div className="col chat-bubble-author">{replyData.replyUserData.first_name} {replyData.replyUserData.last_name}</div>
                                          </div>
                                        </div>
                                        <div className="chat-bubble-body">
                                          <p>{replyData.reply}</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )
                          })
                          }
                        </>
                      )
                    })
                    }
                  </div>
                </div>
              </div> : ""}
          </div>

        </div>

      </div>
    </>
  );
}

export default Technologies;
