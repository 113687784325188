import { useState, React, createContext, useEffect } from "react";
export const AuthContext = createContext();
export const AuthProvider = (props) => {
  let authUserFirstName = "";
  let authUserLastName = "";
  let authUserName = "";
  let authUserStatus = "";
  let authUseremail = '';
  let authUserProfilePhoto = '';
  let user_id = '';
  let department_id = '';
  let designation_id = '';
  let role_id = '';
  let department_name = '';
  let authUserToken = localStorage.getItem("eToken")
    ? localStorage.getItem("eToken")
    : "";
  let is_head = 0;
  let is_admin = 0;
  let hr_head = 0;
  let portal_permissions = localStorage.getItem("ePermissions")
    ? localStorage.getItem("ePermissions")
    : "";
  let ePermissions = '';
  if (authUserToken) {
    let tokenData = JSON.parse(atob(authUserToken.split('.')[1]));
    console.log(tokenData);
    authUserFirstName = tokenData.result.first_name ? tokenData.result.first_name : "";
    authUserLastName = tokenData.result.last_name ? tokenData.result.last_name : "";
    authUserStatus = tokenData.result.status ? "" : tokenData.result.status;
    authUseremail = tokenData.result.email ? tokenData.result.email : "";
    user_id = tokenData.result.id ? tokenData.result.id : "";
    department_id = tokenData.result.department ? tokenData.result.department.id : "";
    designation_id = tokenData.result.designation_id ? tokenData.result.designation_id : "";
    role_id = tokenData.result.role_id ? tokenData.result.role_id : "";
    is_head = tokenData.result.is_head ? tokenData.result.is_head : "";
    is_admin = tokenData.result.is_admin ? tokenData.result.is_admin : "";
    department_name = tokenData.result.department ? tokenData.result.department.name : "";
    authUserProfilePhoto = tokenData.result.profile_photo_path
      ? tokenData.result.profile_photo_path
      : "";
    if (portal_permissions) {
      //console.log(portal_permissions);
      ePermissions = atob(portal_permissions);
      // console.log(ePermissions);
    }
    hr_head = tokenData.result.hr_head ? tokenData.result.hr_head : "";
  }
  const [authUserData, setAuthUserData] = useState([
    {
      first_name: authUserFirstName,
      last_name: authUserLastName,
      status: authUserStatus,
      token: authUserToken,
      email: authUseremail,
      profile_photo_path: authUserProfilePhoto,
      user_id: user_id,
      department_id: department_id,
      designation_id:designation_id,
      department_name: department_name,
      role_id: role_id,
      is_head: is_head,
      is_admin: is_admin,
      permissions: ePermissions,
      hr_head:hr_head

    },
  ]);
  return (
    <AuthContext.Provider value={[authUserData, setAuthUserData]}>
      {" "}
      {props.children}{" "}
    </AuthContext.Provider>
  );
};