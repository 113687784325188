
import React from 'react'
import { Link } from 'react-router-dom'

function BusinessCommunication() {


  return (
    <>
      <section className='container'>
        <div className='row'>
          <div className="col-12">
            <div className="section-title text-center mb-60 mt-60">
              <h3 className="mb-15">Business Communication</h3>

            </div>
          </div>
        </div>
      </section>
      <section class="page-body">
        <div class="container-xl">
          <div class="row justify-content-center">
            <p>Dear Team,</p>
            <p>
              In today’s fast-paced and interconnected world, effective communication plays a pivotal role in driving success, both on an individual level and as a cohesive team.
            </p>
            <p>
              We have launched Communication Module, designed to empower and enrich our employees’ learning experience. This module has been carefully crafted to strengthen our communication skills, foster effective collaboration, and facilitate seamless information exchange throughout our organization.
            </p>
            <p>
              Throughout this module, you will have access to a range of resources, including interactive videos, engaging quizzes, practical exercises, and supplementary materials to reinforce your learning.
            </p>
            <p>
              We encourage you to take advantage of these resources to actively engage with the module content and apply the concepts to your daily work interactions.
            </p>
            <p>
              Please find the login Credentials below –
            </p>
            <p>
              <Link to={'https://www.udemy.com/course/professional-communication-and-business-writing/learn/lecture/25443052#overview'} target='_blank' >Business Communication Skills: Business Writing & Grammar | Udemy</Link>
            </p>
            <p>
              <b> Email: </b>leena_k@eluminoustechnologies.com
              <br />
              <b>  Password: </b>Eluminous@123
            </p>


          </div>
        </div>

      </section >
    </>
  )
}

export default BusinessCommunication