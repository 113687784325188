import React, {useState} from 'react'
import { Link } from 'react-router-dom'
import * as CONST from "../../../component/config/Constant";
import Loading from "react-fullscreen-loading";
import AddEditCategory from '../events/AddEditCategory';
import AddEvent from '../events/AddEvent';

function EventHeader(props) {
	const [loading, setLoading] = useState(false);
	const [isCatModalShow, setCatModalShow] = useState(false);
	const [showCatModel, setShowCatModel] = useState({
		show: 'hide'
	});
	const [isAddModalShow, setAddModalShow] = useState(false);
	const [showModel, setShowModel] = useState({
		show: 'hide'
	});

	const showCategoryForm = () =>{
		
		setLoading(true);
		setCatModalShow(true);
		setShowCatModel({
			show: 'show'
		});
		setLoading(false);
	}

	const closeCatForm = async (type) => {
		setShowCatModel({
			show: 'hide'
		});
	}

	const handleEventEdit = async (empId) => {
		setLoading(true);
		setAddModalShow(true);
		setShowModel({
			show: 'show'
		});
		
		setLoading(false);
	}
	const closeForm = async (type) => {
		setShowModel({
			show: 'hide'
		});
		//fetchEventData(searchParam, dataSort, activePage, perPage);
	}

	return (
		<>
			<div className="page-header d-print-none">
				<div className="container-xl">
					<div className="row g-2 align-items-center">
						<div className="col">
							<div className="page-pretitle">Manage</div>
							<h2 className="page-title ">Events</h2>
						</div>
						<div className="col-12 col-md-auto ms-auto d-print-none">

							<div className="btn-list">
								<span className="d-none d-sm-inline">
									<Link
										to={CONST.ADMIN_PRE + 'event-category'}
										className="btn btn-outline-primary"
										// onClick={showCategoryForm}	
										
									>
										
										Add & Edit Category
										
									</Link>
								</span>
								<Link
									to="#"
									className="btn btn-primary"
									onClick={handleEventEdit}
								>

									<svg
										xmlns="http://www.w3.org/2000/svg"
										className="icon icon-tabler icon-tabler-plus"
										width="24"
										height="24"
										viewBox="0 0 24 24"
										strokeWidth="2"
										stroke="currentColor"
										fill="none"
										strokeLinecap="round"
										strokeLinejoin="round"
									>
										<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
										<line x1="12" y1="5" x2="12" y2="19"></line>
										<line x1="5" y1="12" x2="19" y2="12"></line>
									</svg>

									&nbsp;Add Event
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
			{
				(showCatModel.show == 'show' && <AddEditCategory closeCatForm={closeCatForm} />)
			}
			{
				(showModel.show == 'show' && <AddEvent  closeEventForm={closeForm}  />)
			}
			</>
	)
}

export default EventHeader