
import React, { useState, useContext, useRef, useEffect } from 'react';
import axios from 'axios';
import { CSVLink } from "react-csv";

import { toast } from "react-toastify";
import * as  CONST from "../../../component/config/Constant"
import { AuthContext } from '../../../component/context/AuthContext';



function ExportEmployee({ propsEmpName, propsHeaders }) {

  // console.log(propsEmpName);
  const [listOfUsers, setListOfUsers] = useState([]);
  const csvLinkEl = useRef();
  const [loading, setLoading] = useState(false);
  const [srchEmpName, setSrchEmpName] = useState(propsEmpName);
  const [srchEmpEmail, setSrchEmpEmail] = useState("");
  const [srchEmpStatus, setSrchEmpStatus] = useState("");
  const [srchEmpDepartment, setSrchEmpDepartment] = useState("");
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  let isToken = '';
  let user_id = '';
  let department_id = '';
  let is_head = 0;
  let is_admin = 0;
  if (authUserData) {
    if (authUserData[0]) {
      isToken = authUserData[0].token ? authUserData[0].token : "";
      user_id = authUserData[0].user_id ? authUserData[0].user_id : "";
      is_head = authUserData[0].is_head ? authUserData[0].is_head : 0;
      is_admin = authUserData[0].is_admin ? authUserData[0].is_admin : 0;
      department_id = authUserData[0].department_id ? authUserData[0].department_id : "";
    }
  }

  // const headers = [
  //   { label: "Name", key: "first_name" },
  //   { label: "Office Email", key: "email" },
  //   { label: "Personal Email", key: "personal_email" },
  //   { label: "Phone", key: "phone_number" },
  //   { label: "Department", key: "department" },
  //   { label: "Designation", key: "designation" },
  //   { label: "Current Adress", key: "user_address" },
  //   { label: "Aadhar Adress", key: "user_aadhar_address" },
  //   { label: "Date of Birth", key: "date_of_birth" },
  //   { label: "Date of Joining", key: "date_of_joining" },
  //   { label: "Aadhar Number", key: "aadhar_number" },
  //   { label: "Pan Card", key: "pan_number" },
  //   { label: "UAN Number", key: "uan_number" },
  //   { label: "ESIC Number", key: "esic_number" },
  // ];

  const getUserList = async () => {
    console.log(srchEmpName, propsEmpName)
    if (!loading) {
      setLoading(true);
      try {
        const response = await axios
          .post(CONST.EMP_LIST,
            {
              "limit": 200,
              "page_number": 1,
              "search_input": {
                srchEmpName: propsEmpName,
                srchEmpEmail,
                srchEmpStatus,
                srchEmpDepartment,
              },
              'dptId': '',
              "sort": {
                order: 'desc',
                order_by: 'id'
              },

            },
            {
              headers: {
                'Authorization': `Basic ${isToken}`
              }
            }
          )

        console.log(response);
        const userListJson = response.data.data; // Assuming the user list is in the response data
        console.log(userListJson);
        setListOfUsers(userListJson);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching users:", error);
        setLoading(false);
      }
    }
  }
  const dataFromListOfUsersState = () => {
    //alert(propsEmpName);
    return listOfUsers.map(user => ({
      ...user,
      first_name: `${user.first_name} ${user.middle_name} ${user.last_name}`,
      department: `${user.department.name}`,
      designation: `${user.designation.name}`,
    }));
  };

  return (
    <>
      {/* <CSVLink
        data={dataFromListOfUsersState()}
        asyncOnClick={true}
        onClick={getUserList}
        headers={headers}
        className='btn btn-primary pr-1 ml-1'
      ><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-download" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2"></path>
          <path d="M7 11l5 5l5 -5"></path>
          <path d="M12 4l0 12"></path>
        </svg> &nbsp;Export
      </CSVLink> */}

      <CSVLink
        data={propsEmpName}
        asyncOnClick={true}
        headers={propsHeaders}
        className='btn btn-primary pr-1 ml-1'
      ><svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-download" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2"></path>
          <path d="M7 11l5 5l5 -5"></path>
          <path d="M12 4l0 12"></path>
        </svg> &nbsp;Export
      </CSVLink>

    </>
  );
}

export default ExportEmployee;

