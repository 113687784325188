import { React, useState, useEffect, useContext } from "react";
import Loading from "react-fullscreen-loading";
import "../../../assets/css/table.css";
import HeaderSection from "../banner/HeaderSection";
import * as CONST from "../../../component/config/Constant";
import Swal from "sweetalert2";
import axios from "axios";
import { AuthContext } from "../../../component/context/AuthContext";
import { toast } from "react-toastify";
import deleteImg from "../../../assets/img/icon/delete.svg";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import DivLoader from "../../../component/utilities/Spinner";
import DataTable from "react-data-table-component";
import withReactContent from "sweetalert2-react-content";
import ReactSwitch from "react-switch";
import * as SwitchProps from "../../../component/utilities/ReactSwitchReactSwitchProps";
import "../../../assets/css/imageUpload.css";

import "../../../assets/css/table.css";
// import "../../../assets/css/tabler.min.css";
import List2Style from "./List2.module.css";
//import { SRLWrapper } from "simpler-react-lightbox";

const List2 = () => {
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  const [fullLoading, setFullLoading] = useState(false);
  const [birthdayData, setBirthdayData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const MySwal = withReactContent(Swal);
  const [listLimit, setListLimit] = useState(50);

  const [action, setAction] = useState("");

  /** For Modalpopup */
  const [show, setShow] = useState(false);
  const [imgShow, setImgShow] = useState(false);
  const [imagePath, setImagePath] = useState();

  /* START DATATABLE */
  const [totalRecords, settotalRecords] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [maxPageLimit, setMaxPageLimit] = useState(10);
  const [minPageLimit, setMinPageLimit] = useState(0);

  //Search
  const [srchStatus, setSrchStatus] = useState("");
  const [dataSort, setDataSort] = useState({
    order: "desc",
    order_by: "created_at",
  });
  const pageNumberLimit = { perPage };

  //For Form
  const [selectedImages, setSelectedImages] = useState([]);

  let isToken = "";
  let user_id = "";
  if (authUserData) {
    if (authUserData[0]) {
      isToken = authUserData[0].token ? authUserData[0].token : "";
      user_id = authUserData[0].user_id ? authUserData[0].user_id : "";
    }
  }

  const [searchParam, setSearchParam] = useState({
    srchStatus: srchStatus,
  });

  useEffect(() => {
    fetchBirthdayData(searchParam, dataSort, activePage, perPage);
  }, [searchParam, dataSort, activePage, listLimit]);

  const onSelectFile = (event) => {
    const selectedFiles = event.target.files;
    setSelectedImages(selectedFiles);
    event.target.value = "";
  };

  function handleShow(action) {
    setAction(action);
    setShow(true);
  }

  const showImage = (image_path) => {
    setImagePath(image_path);
    setImgShow(true);
  };

  const handleClose = () => {
    setShow(false);
    setSrchStatus("");
  };
  const handleImageClose = () => {
    setImgShow(false);
  };

  function deleteHandler(img_id) {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this image?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        if (img_id > 0) {
          setLoading(true);
          axios({
            method: "post",
            url: CONST.DELETE_BIRTHDAY_IMG,
            data: {
              id: user_id,
              birthday_id: img_id,
            },
            headers: { Authorization: `Bearer ${isToken}` },
          })
            .then((response) => {
              if (response) {
                if (response.data.status == "error") {
                  toast.error(response.data.message);
                } else {
                  toast.success(response.data.message);
                  fetchBirthdayData(searchParam, dataSort, activePage, perPage);
                }
              }
            })
            .catch((error) => {
              setLoading(false);
              toast.error("Something went wrong. Please try again." + error);
            });
          setLoading(false);
        }
      }
    });
  }

  const handleSubmit = async (e) => {
    setLoading(true);
    var formData = new FormData();

    formData.append("id", user_id);
    for (let i = 0; i < selectedImages.length; i++) {
      formData.append("images[]", selectedImages[i]);
    }

    axios
      .post(CONST.ADD_BIRTHDAY_IMG, formData, {
        headers: {
          Authorization: `Basic ${isToken}`,
          "content-type": "multipart/form-data",
        },
      })
      .then((response) => {
        const status = response.data.status;
        setLoading(false);

        if (status === "success") {
          toast.success(response.data.message);
          fetchBirthdayData(searchParam, dataSort, activePage, perPage);
          handleClose();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Something went wrong. Please try again.");
      });
  };

  //fetch all birthday details
  async function fetchBirthdayData(
    paramSearch,
    paramSort,
    paramPage,
    paramLimit
  ) {
    setLoading(true);
    await axios
      .post(
        CONST.GET_BIRTHDAY_LIST,
        {
          id: user_id,
          limit: paramLimit,
          search_input: paramSearch,
          page_number: paramPage,
          sort: paramSort,
        },
        {
          headers: {
            Authorization: `Bearer ${isToken}`,
          },
        }
      )

      .then((response) => {
        const status = response.data.status;
        if (status === "success") {
          const responseData = response.data.data;
          var birthdayImages = [];
          let isArray = Array.isArray(responseData);
          if (isArray) {
            responseData.map((i, index) => {
              birthdayImages.push({
                id: i.id,
                image: i.image,
                image_path: i.image_path,
                status: i.status,
              });
            });
          }
          setBirthdayData(birthdayImages);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setLoading(false);
  }

  const handleChangeStatus = (index, status_value, img_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to change the status?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        axios
          .post(
            CONST.UPATE_BIRTHDAY_IMG_STATUS,
            {
              id: user_id,
              birthday_id: img_id,
              status: status_value == "0" ? "1" : "0",
            },
            {
              headers: {
                Authorization: `Basic ${isToken}`,
              },
            }
          )
          .then((response) => {
            const status = response.data.status;
            if (status == "success") {
              toast.success(response.data.message);
            } else {
              toast.error(response.data.message);
            }
          })
          .catch((error) => {
            toast.error("There was an error.");
          });
        setLoading(false);

        const newData = [...birthdayData];
        newData[index].status = status_value == 0 ? 1 : 0;
        setBirthdayData(newData);
      }
    });
  };

  const columns = [
    {
      name: "Sr. No",
      selector: (row, index) => index + 1,
      width: "10%",
    },
    {
      name: "Image",
      selector: (row) => (
        <div className="col-md-8 mt-4">
          {row.image_path !== "" ? (
            <img
              src={CONST.HOST + row.image_path}
              alt={row.image}
              onClick={() => showImage(CONST.HOST + row.image_path)}
            />
          ) : (
            ""
          )}
        </div>
      ),
      width: "25%",
    },
    {
      name: "Status",
      selector: (row) => "Active",
      width: "10%",
      selector: (row, index) => (
        <ReactSwitch
          onColor={SwitchProps.onColor}
          onHandleColor={SwitchProps.onHandleColor}
          handleDiameter={SwitchProps.handleDiameter}
          uncheckedIcon={SwitchProps.uncheckedIcon}
          checkedIcon={SwitchProps.checkedIcon}
          boxShadow={SwitchProps.boxShadow}
          activeBoxShadow={SwitchProps.activeBoxShadow}
          height={SwitchProps.height}
          width={SwitchProps.width}
          className={SwitchProps.className}
          checked={row.status == 1 ? true : false}
          onChange={() => {
            handleChangeStatus(index, row.status, row.id);
          }}
        />
      ),
    },
    {
      name: "Action",
      width: "20%",
      wrap: true,
      sortable: false,
      cell: (row) => <Action row={row} />,
    },
  ];

  const Action = ({ row }) => (
    <>
      <Link onClick={() => deleteHandler(row.id)}>
        <img src={deleteImg} alt="deleteImg" width={32} className="cursor" />
      </Link>
    </>
  );

  const handleSort = async (column, sortDirection) => {
    setActivePage(1);
    setSearchParam({
      search: "no",
      name: "",
      status: "",
    });

    setDataSort({
      order: sortDirection,
      order_by: column.sortField,
    });
    /*  setLoading(false); */
  };
  /*  PAGINATION FUNCTIONS  */
  const handlePageChange = async (page) => {
    setActivePage(parseInt(page));
  };

  const BootyPagination = ({
    rowsPerPage,
    rowCount,
    onChangePage,
    onChangeRowsPerPage, // available but not used here
    currentPage,
  }) => {
    function toPages(pages) {
      const results = [];
      for (let i = 1; i <= pages; i++) {
        results.push(i);
      }
      return results;
    }
    function getNumberOfPages(rowCount, rowsPerPage) {
      return Math.ceil(rowCount / rowsPerPage);
    }

    const handleBackButtonClick = async () => {
      if ((currentPage - 1) % pageNumberLimit === 0) {
        setMaxPageLimit(maxPageLimit - pageNumberLimit);
        setMinPageLimit(minPageLimit - pageNumberLimit);
      }
      onChangePage(currentPage - 1);
    };

    const handleNextButtonClick = async () => {
      if (currentPage + 1 > maxPageLimit) {
        setMaxPageLimit(maxPageLimit + pageNumberLimit);
        setMinPageLimit(minPageLimit + pageNumberLimit);
      }

      onChangePage(currentPage + 1);
    };

    const handlePageNumber = (e) => {
      onChangePage(Number(e.target.value));
    };

    let paginationText = "Page:  " + currentPage + ", Showing ";

    paginationText =
      paginationText +
      ((currentPage - 1) * perPage + 1) +
      " to " +
      perPage * currentPage;
    paginationText = paginationText + " of " + rowCount + " entries;";

    const pages = getNumberOfPages(rowCount, rowsPerPage);
    const pageItems = toPages(pages);
    const nextDisabled = currentPage === pageItems.length;
    const previosDisabled = currentPage === 1;

    const pageNumbers = pageItems.map((page) => {
      if (page <= maxPageLimit && page > minPageLimit) {
        return (
          <li
            key={page}
            id={page}
            className={`page-item ${currentPage === page ? "active" : ""}`}
          >
            <button
              className={`page-link ${currentPage === page ? "active" : ""}`}
              onClick={handlePageNumber}
              value={page}
            >
              {page}
            </button>
          </li>
        );
      } else {
        return null;
      }
    });
    let pageIncrementEllipses = null;
    if (pageItems.length > maxPageLimit) {
      pageIncrementEllipses = (
        <li className="page-item" onClick={handleNextButtonClick}>
          &hellip;
        </li>
      );
    }
    let pageDecremenEllipses = null;
    if (minPageLimit >= 1) {
      pageDecremenEllipses = (
        <li className="page-item" onClick={handleBackButtonClick}>
          &hellip;
        </li>
      );
    }
    return (
      <div className="card-footer d-flex align-items-center">
        <p className="m-0 text-muted">{paginationText}</p>
        <ul className="pagination m-0 ms-auto">
          <li className="page-item">
            <button
              className="page-link"
              onClick={handleBackButtonClick}
              disabled={previosDisabled}
              aria-disabled={previosDisabled}
              aria-label="previous page"
              pagerangedisplayed={10}
            >
              «
            </button>
          </li>
          {pageDecremenEllipses}
          {pageNumbers}
          {pageIncrementEllipses}
          <li className="page-item">
            <button
              className="page-link"
              onClick={handleNextButtonClick}
              disabled={nextDisabled}
              aria-disabled={nextDisabled}
              aria-label="next page"
            >
              »
            </button>
          </li>
        </ul>
      </div>
    );
  };
  /* END DATATABLE */
  const CustomLoader = () => (
    <div style={{ padding: "24px" }}>
      <DivLoader color="primary" />
    </div>
  );
  return (
    <>
      <Loading
        loading={fullLoading}
        background="rgba(0, 0, 0, 0.5)"
        loaderColor="#fff"
      />
      <HeaderSection
        title="Manage"
        heading="Birthday"
        addButton={() => handleShow("Add", 0)}
        addLink="#"
        addLinkName="Add Images"
        linktype="add"
      />
      <div className="page-body">
        <div className="container-xl">
          <div className="card p-4">
            <div className="row row-cols-5 g-3">
              {birthdayData.map((birthday, index) => {
                return (
                  <div className="col" key={birthday.id}>
                    <div
                      className={`${List2Style.img_box} img-responsive img-responsive-1x1 rounded border`}
                    >
                      <img
                        src={CONST.HOST + birthday.image_path}
                        alt={birthday.image}
                        srl_gallery_image="true"
                        className={
                          birthday.status == 0 ? `${List2Style.disabled}` : ""
                        }
                      />
                      <div className={List2Style.hover}>
                        {/* <SRLWrapper> */}
                          <a
                            href={CONST.HOST + birthday.image_path}
                            className={List2Style.thumbnail}
                          >
                            <img
                              src={CONST.HOST + birthday.image_path}
                              alt={birthday.image}
                              srl_gallery_image="true"
                              className={
                                birthday.status == 0
                                  ? `${List2Style.disabled}`
                                  : ""
                              }
                            />
                          </a>
                        {/* </SRLWrapper> */}
                        <ReactSwitch
                          onColor={SwitchProps.onColor}
                          onHandleColor={SwitchProps.onHandleColor}
                          handleDiameter={SwitchProps.handleDiameter}
                          uncheckedIcon={SwitchProps.uncheckedIcon}
                          checkedIcon={SwitchProps.checkedIcon}
                          boxShadow={SwitchProps.boxShadow}
                          activeBoxShadow={SwitchProps.activeBoxShadow}
                          height={SwitchProps.height}
                          width={SwitchProps.width}
                          className={SwitchProps.className}
                          checked={birthday.status == 1 ? true : false}
                          onChange={() => {
                            handleChangeStatus(
                              index,
                              birthday.status,
                              birthday.id
                            );
                          }}
                        />
                        <Link
                          onClick={() => deleteHandler(birthday.id)}
                          className="cursor"
                        >
                          <img src={deleteImg} alt="deleteImg" width={32} />
                        </Link>
                        {/* <Action row={row} /> */}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>

            <ul className="pagination justify-content-center mt-4 mb-0">
              <li className="page-item disabled">
                <a
                  className="page-link"
                  href="#"
                  tabindex="-1"
                  aria-disabled="true"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <polyline points="15 6 9 12 15 18" />
                  </svg>
                </a>
              </li>
              <li className="page-item active">
                <a className="page-link" href="#">
                  1
                </a>
              </li>
              <li className="page-item ">
                <a className="page-link" href="#">
                  2
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  3
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  4
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  5
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#">
                  6
                </a>
              </li>
              <li className="page-item">
                <a className="page-link" href="#" >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <polyline points="9 6 15 12 9 18" />
                  </svg>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{action} Birthday Images</Modal.Title>
        </Modal.Header>
        <form method="POST" onSubmit={handleSubmit} autoComplete={"off"}>
          <Modal.Body>
            <section className="custImgSection">
              <label>
                + Add Images
                <br />
                <span>up to 10 images</span>
                <input
                  type="file"
                  name="images"
                  onChange={onSelectFile}
                  multiple
                  accept="image/png , image/jpeg, image/jpg, image/gif"
                />
              </label>
              <br />
            </section>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal show={imgShow} onHide={handleImageClose}>
        <Modal.Header closeButton>
          <Modal.Title>{action} Birthday Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={imagePath} width="100%" alt="birthdayImage" />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default List2;
