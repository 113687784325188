import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import * as CONST from "../../../component/config/Constant";
import { AuthContext } from '../../../component/context/AuthContext';
import { toast } from "react-toastify";
import moment from 'moment';

function ViewEducation({ closeEducation, editEmpId, firstName, lastName }) {
    const [authUserData, setAuthUserData] = useContext(AuthContext);
    const [show, setShow] = useState('show');
    const [loading, setLoading] = useState(false);
    const [empId, setEmpId] = useState(editEmpId);
    const [educationFields, setEducationFields] = useState([{
        institute_name: '',
        degree: '',
        start_date: '', //moment().format('YYYY-MM-DD'),
        end_date: '', //moment().format('YYYY-MM-DD'),
    }]);

    let isToken = '';
    let user_id = '';
    if (authUserData) {
        if (authUserData[0]) {
            isToken = authUserData[0].token ? authUserData[0].token : "";
            user_id = authUserData[0].user_id ? authUserData[0].user_id : "";
        }
    }

    useEffect(() => {
        fetchGuardian(empId);
    }, [])

    const fetchGuardian = async (empId) => {
        setLoading(true);
        await axios
            .get(CONST.GET_EDUCATION_BY_USERID + empId,
                {
                    headers: {
                        'Authorization': `Bearer ${isToken}`
                    },

                })

            .then((response) => {

                const status = response.data.status;

                if (status === 'success') {
                    if (response.data.data != null) {
                        setEducationFields(response.data.data);
                    }
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch((error) => {
                console.log(error);
            });
        setLoading(false);
    }
    return (
        <>
            <Modal
                size="lg"
                show={show}
                onHide={() => closeEducation('hide')}
                backdrop="static"
                keyboard={false}
                centered
                fullscreen={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Education</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>
                        {(firstName && lastName)
                            ? <> {`${firstName && firstName} ${lastName && lastName}`}</>
                            : ''
                        }
                    </h5>
                    <hr />
                    <div className="row">
                        {

                            educationFields.map((data, i) => {
                                const { institute_name, degree, start_date, end_date, validate } = data;
                                return (
                                    <div className="row">

                                        <div className="col-md-6">
                                            <div className="form-group mb-3 ">
                                                <label>
                                                    <b>Institute:</b> {(institute_name) ? institute_name : '-'}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-3 ">
                                                <label >
                                                    <b> Degree: </b> {(degree) ? degree : '-'}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-3 ">
                                                <label>
                                                    <b>Start Date:</b> {(start_date) ? start_date : '-'}
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-3 ">
                                                <label >
                                                    <b>End Date:</b> {(end_date) ? end_date : '-'}
                                                </label>
                                            </div>
                                        </div>

                                        <hr />
                                    </div>


                                )
                            })
                        }
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default ViewEducation