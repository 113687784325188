import { React, useState, useEffect, useContext, useMemo } from "react";
import "../../../assets/css/table.css";
import Loading from "react-fullscreen-loading";
import DivLoader from "../../../component/utilities/Spinner";
import DataTable from "react-data-table-component";
import axios from "axios";
import { toast } from "react-toastify";
import * as CONST from "../../../component/config/Constant";
import { AuthContext } from "../../../component/context/AuthContext";
import { Link } from "react-router-dom";
import viewImg from "../../../assets/img/icon/index.png";
import uploadImg from "../../../assets/img/icon/upload.png";
import fileImg from "../../../assets/img/icon/file.png";
import Swal from "sweetalert2";
import DivSpinner from "../../../component/utilities/Spinner";
// import DatePicker from 'react-datetime';
// import moment from 'moment';
import ViewExemptions from "./ViewExemptions";
import { Modal, Button } from "react-bootstrap";

function ExemptionList() {
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  const [fullLoading, setFullLoading] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [srchExemptionName, setSrchEmployeeName] = useState("");
  const [srchEmployeeEmail, setSrchEmployeeEmail] = useState("");
  const [departmentList, setDepartmentList] = useState("");
  const [department, setDepartment] = useState("");

  /* START DATATABLE */
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [maxPageLimit, setMaxPageLimit] = useState(10);
  const [minPageLimit, setMinPageLimit] = useState(0);
  const [showModel, setShowModel] = useState({
    show: "hide",
  });
  const [viewModel, setViewModel] = useState({
    show: "hide",
  });
  const [viewData, setViewData] = useState("");
  const [activePage, setActivePage] = useState(1);
  const [dataSort, setDataSort] = useState({
    order: "desc",
    order_by: "id",
  });
  const currentYear = new Date().getFullYear();
  const [srchYear, setSrchYear] = useState(currentYear - 1 + "-" + currentYear);
  const [searchParam, setSearchParam] = useState({
    srchYear: srchYear,
  });
  const [username, setUserName] = useState("");
  /** For Modalpopup */
  const [show, setShow] = useState(false);
  const [formuserId, setFormUserId] = useState("");
  const [formtype, setFormType] = useState("A");
  const [assesmentyear, setAssesmentYear] = useState(srchYear);
  const [taxform, setTaxForm] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [pdfUrl, setpdfUrl] = useState("");
  const [doctype, setDocType] = useState("");

  let isToken = "";
  let user_id = "";
  if (authUserData) {
    if (authUserData[0]) {
      isToken = authUserData[0].token ? authUserData[0].token : "";
      user_id = authUserData[0].user_id ? authUserData[0].user_id : "";
    }
  }

  useEffect(() => {
    console.log("searchParam>>>>>>>>>", searchParam);
    fetchExemptionData(searchParam, dataSort, activePage, perPage);
    fetchDeptList();
  }, [searchParam, dataSort, activePage, perPage]);

  async function searchExemption() {
    setSearchParam({
      srchYear: srchYear,
    });
    setDataSort({
      order: "desc",
      order_by: "id",
    });
    setUserName(srchExemptionName);
    setActivePage("1");
    fetchExemptionData(searchParam, dataSort, activePage, perPage);
    //console.log('srch- '+searchParam)
  }

  async function fetchDeptList() {
    await axios
      .get(CONST.GET_DEPARTMENT, {
        headers: {
          'Authorization': `Basic ${isToken}`
        }
      })
      .then((response) => {
        let status = response.data.status;
        //console.log(response)
        if (status == 'success') {
          setDepartmentList(response.data.data);
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  }

  function clearSearch() {
    setSearchParam({
      srchYear: currentYear - 1 + "-" + currentYear,
    });
    setUserName("");
    setSrchYear(currentYear - 1 + "-" + currentYear);
    setSrchEmployeeName("");
    setDataSort({
      order: "desc",
      order_by: "id",
    });
    setActivePage("1");
    setDepartment("");
    setSrchEmployeeEmail("");
  }

  const handleViewExemption = async (data) => {
    setLoading(true);
    setViewModel({
      show: "view",
    });
    setViewData(data);
    setLoading(false);
  };

  const closeViewModel = async (type) => {
    setViewModel({
      show: "hide",
    });
    fetchExemptionData(searchParam, dataSort, activePage, perPage);
  };

  const Action = ({ row }) => (
    <>
      <Link onClick={() => handleViewExemption(row)}>
        <img
          src={viewImg}
          alt="editUser"
          width={32}
          className="img-action cursor"
        />
      </Link>
    </>
  );
  const uploadForm = (file, type) => {
    if (file) {
      setTaxForm(file);
      const previewDiv = document.getElementById("pdfPreview");
      previewDiv.innerHTML = "";
      if (file.type === "application/pdf") {
        const reader = new FileReader();

        reader.onload = function (e) {
          const pdfObject = document.createElement("object");
          pdfObject.type = "application/pdf";
          pdfObject.data = e.target.result;
          pdfObject.width = "100%";
          pdfObject.height = "500px";
          previewDiv.appendChild(pdfObject);
        };

        reader.readAsDataURL(file);
      } else {
        // Show an error message if the selected file is not a PDF
        previewDiv.innerHTML = "<p>Please select a PDF file.</p>";
      }
    }

  };
  const UploadDocument = ({ row }) => (
    <>
      <div className="col-md-6">
        <div className="form-group mb-3 ">
          <Link to={"#"} data-id={row.id} onClick={() => handleShow(row.id)}>
            <img
              src={uploadImg}
              alt="upload document"
              width={32}
              className="img-action cursor"
            />
          </Link>
        </div>
      </div>
    </>
  );
  const PreviewDocument = ({ row }) => (
    <>
      {row.etax_exemption_has_documents.length > 0
        ? row.etax_exemption_has_documents.map((doc, index) => (
          <div className="col-md-6" key={index}>
            <div className="form-group mb-3 ">
              <button
                className="mr-2 btn btn-primary"
                onClick={() => ShowPreview(doc.document_path, doc.form_type)}
              >
                Form16 {doc.form_type} &nbsp; &nbsp;
                <img
                  src={fileImg}
                  alt="upload document"
                  width={20}
                  className="img-action cursor"
                />
              </button>
            </div>
          </div>
        ))
        : "-"}
    </>
  );

  const SendEmail = ({ row }) => (
    <>
      {row.etax_exemption_has_documents.length == 2
        ?
        <div className="col-md-6" >
          <div className="form-group mb-3 ">
            <button
              className="mr-2 btn btn-primary"
              onClick={() => notifyUser(row)}
            >
              SendEmail &nbsp; &nbsp;

            </button>
          </div>
        </div>

        : "-"}
    </>
  );

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setpdfUrl("");
    setDocType("");
  };

  const ShowPreview = (url, type) => {
    // console.log('ggggg', url)
    setDocType(type);
    var d = CONST.FILE_BASE_URL + url;
    setpdfUrl(d);
    handleOpenPopup();
  };

  const notifyUser = async (data) => {
    console.log('send Email', data)
    setLoading(true);
    let srch_param = {
      user_id: data.id,
      assessment_year: srchYear,
    };
    await axios
      .post(CONST.SEND_DOCUMENT_EMAIL, srch_param, {
        headers: {
          Authorization: `Basic ${isToken}`,
        },
      })
      .then((response) => {
        setLoading(false);
        const status = response.data.status;
        if (status == "success") {
          toast.success(response.data.message);
        } else {
          toast.error(response.data.errors);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Something went wrong. Please try again." + error);
      });

    // let srch_param = {
    //   user_id:data.id,
    //   assessment_year:srchYear,
    // };
    // const response = await axios
    //   .post(CONST.ALL_EXEMPTIONSEND_DOCUMENT_EMAIL, srch_param, {
    //     headers: {
    //       Authorization: `Basic ${isToken}`,
    //     },
    //   })
    //   .then((response) => {
    //     const status = response.data.status;
    //     if (status == "success") {
    //       if (response.data.count > 0) {
    //         setTotalRows(response.data.count);
    //         setData(response.data.data);
    //         setTotalRecords(response.data.count);
    //       } else {
    //         setData([]);
    //         setTotalRows(0);
    //         setTotalRecords(0);
    //       }
    //     }
    //     setLoading(false);
    //   })
    //   .catch((error) => {
    //     setLoading(false);
    //     toast.error("There was an error.");
    //   });

  }

  function handleShow(userId) {
    setFormUserId(userId);
    setShow(true);
  }

  const handleClose = () => {
    setShow(false);
    setFormUserId("");
    setTaxForm("");
  };

  const columns = [
    {
      name: "Employee Name",
      selector: (row) => row.first_name + " " + row.last_name,
      cell: (row) => row.first_name + " " + row.last_name,
      sortable: true,
      sortField: "first_name",
    },
    {
      name: "Action",
      cell: (row) => <Action row={row} />,
      sortable: false,
      width: "70px",
    },
    {
      name: "Upload Form 16 A & B",
      cell: (row) => <UploadDocument row={row} />,
      sortable: false,
    },
    {
      name: "Preview",
      cell: (row) => <PreviewDocument row={row} />,
      sortable: false,
    },
    {
      name: "Notify",
      cell: (row) => <SendEmail row={row} />,
      sortable: false,
    },
  ];

  async function fetchExemptionData(
    paramSearch,
    paramSort,
    paramPage,
    paramLimit
  ) {
    setLoading(true);
    if (paramSearch !== undefined) {
      let srch_param = {
        limit: paramLimit,
        page_number: paramPage,
        search_input: paramSearch,
        sort: paramSort,
        username: username,
        srchdepartment: department,
        srchEmployeeEmail: srchEmployeeEmail
      };
      const response = await axios
        .post(CONST.ALL_EXEMPTION, srch_param, {
          headers: {
            Authorization: `Basic ${isToken}`,
          },
        })
        .then((response) => {
          const status = response.data.status;
          if (status == "success") {
            if (response.data.count > 0) {
              setTotalRows(response.data.count);
              setData(response.data.data);
              setTotalRecords(response.data.count);
            } else {
              setData([]);
              setTotalRows(0);
              setTotalRecords(0);
            }
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          toast.error("There was an error.");
        });
    }
  }
  const handlePerRowsChange = async (perPage, page) => {
    setLoading(true);

    const response = await axios.get(
      `https://reqres.in/api/users?page=${page}&per_page=${perPage}&delay=1`
    );

    setData(response.data.data);
    setPerPage(perPage);
    setLoading(false);
  };
  function toPages(pages) {
    const results = [];
    for (let i = 1; i <= pages; i++) {
      results.push(i);
    }
    return results;
  }
  function getNumberOfPages(rowCount, rowsPerPage) {
    return Math.ceil(rowCount / rowsPerPage);
  }
  function searchYear(e) {
    setSrchYear(e.target.value);
  }

  const handleSubmit = async (e) => {
    if (!taxform) {
      return false;
    }

    await axios({
      method: "post",
      url: CONST.UPLOAD_FORM_16,
      data: {
        user_id: formuserId,
        tax_form: taxform,
        form_type: formtype,
        assessment_year: assesmentyear,
      },
      headers: {
        Authorization: `Bearer ${isToken}`,
        "content-type": "multipart/form-data",
      },
    })
      .then((response) => {
        console.log("Personal info >>", response);
        handleClose();
        fetchExemptionData(searchParam, dataSort, activePage, perPage);
      })
      .catch((error) => {
        // setloaing(false);
        console.log(error);
        alert(error);
        toast.error("Something went wrong. Please try again.");
      });
  };
  const handleSort = async (column, sortDirection) => {
    setActivePage(1);
    setSearchParam({});

    setDataSort({
      order: sortDirection,
      order_by: column.sortField,
    });
    /*  setLoading(false); */
  };
  /*  PAGINATION FUNCTIONS  */
  const handlePageChange = async (page) => {
    console.log(page);
    setActivePage(page);
  };
  const changePageNumber = useMemo(() => {
    return fetchExemptionData();
  }, [activePage, searchParam, dataSort]);
  const BootyPagination = ({
    rowsPerPage,
    rowCount,
    onChangePage,
    onChangeRowsPerPage, // available but not used here
    currentPage,
  }) => {
    const handleBackButtonClick = async () => {
      if ((currentPage - 1) % perPage === 0) {
        setMaxPageLimit(maxPageLimit - perPage);
        setMinPageLimit(minPageLimit - perPage);
      }
      onChangePage(currentPage - 1);
    };

    const handleNextButtonClick = async () => {
      if (currentPage + 1 > maxPageLimit) {
        setMaxPageLimit(maxPageLimit + perPage);
        setMinPageLimit(minPageLimit + perPage);
      }

      onChangePage(currentPage + 1);
    };

    const handlePageNumber = (e) => {
      onChangePage(Number(e.target.value));
    };

    let paginationText = "Page:  " + currentPage + ", Showing ";

    paginationText =
      paginationText +
      ((currentPage - 1) * perPage + 1) +
      " to " +
      perPage * currentPage;
    paginationText = paginationText + " of " + rowCount + " entries;";

    const pages = getNumberOfPages(rowCount, rowsPerPage);

    const pageItems = toPages(pages);
    const nextDisabled = currentPage === pageItems.length;
    const previosDisabled = currentPage === 1;

    const pageNumbers = pageItems.map((page) => {
      if (page <= maxPageLimit && page > minPageLimit) {
        return (
          <li
            key={page}
            id={page}
            className={`page-item ${currentPage === page ? "active" : ""}`}
          >
            <button
              className={`page-link ${currentPage === page ? "active" : ""}`}
              onClick={handlePageNumber}
              value={page}
            >
              {page}
            </button>
          </li>
        );
      } else {
        return null;
      }
    });
    let pageIncrementEllipses = null;
    if (pageItems.length > maxPageLimit) {
      pageIncrementEllipses = (
        <li className="page-item" onClick={handleNextButtonClick}>
          &hellip;
        </li>
      );
    }
    let pageDecremenEllipses = null;
    if (minPageLimit >= 1) {
      pageDecremenEllipses = (
        <li className="page-item" onClick={handleBackButtonClick}>
          &hellip;
        </li>
      );
    }
    return (
      <div className="card-footer d-flex align-items-center">
        <p className="m-0 text-muted">{paginationText}</p>

        <ul className="pagination m-0 ms-auto">
          <li className="page-item">
            <button
              className="page-link"
              onClick={handleBackButtonClick}
              disabled={previosDisabled}
              aria-disabled={previosDisabled}
              aria-label="previous page"
            // pageRangeDisplayed={10}
            >
              «
            </button>
          </li>
          {pageDecremenEllipses}
          {pageNumbers}
          {pageIncrementEllipses}
          <li className="page-item">
            <button
              className="page-link"
              onClick={handleNextButtonClick}
              disabled={nextDisabled}
              aria-disabled={nextDisabled}
              aria-label="next page"
            >
              »
            </button>
          </li>
        </ul>
      </div>
    );
  };

  /* END DATATABLE */
  const CustomLoader = () => (
    <div style={{ padding: "24px" }}>
      <DivLoader color="primary" />
    </div>
  );
  return (
    <>
      <Loading
        loading={fullLoading}
        background="rgba(0, 0, 0, 0.5)"
        loaderColor="#fff"
      />
      <div className="page-header d-print-none">
        <div className="container-xl">
          <div className="row g-2 align-items-center">
            <div className="col">
              <div className="page-pretitle">Manage</div>
              <h2 className="page-title ">Exemption</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="page-body">
        <div className="container-xl">
          <div className="row row-cards">
            <div className="col-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Listing</h3>
                </div>
                <div className="card-body border-bottom py-3">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group mb-3 ">
                        <label className="form-label required">
                          Employee Name
                        </label>
                        <div>
                          <input
                            type="text"
                            name="srchExemptionName"
                            id="srchExemptionName"
                            className="form-control"
                            aria-describedby="emailHelp"
                            placeholder="Search Employee name"
                            onChange={(e) =>
                              setSrchEmployeeName(e.target.value)
                            }
                            value={srchExemptionName}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="form-group mb-3 ">
                        <label className="form-label required">
                          Email
                        </label>
                        <div>
                          <input
                            type="text"
                            name="searchEmail"
                            id="searchEmail"
                            className="form-control"
                            aria-describedby="emailHelp"
                            placeholder="Search Employee email"
                            onChange={(e) =>
                              setSrchEmployeeEmail(e.target.value)
                            }
                            value={srchEmployeeEmail}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-2">
                      <div className="mb-2">
                        <label className="form-label required">Department</label>
                        <select
                          className="form-select form-control"
                          value={department}
                          onChange={(e) => setDepartment(e.target.value)}
                        >
                          <option value="">Select Department</option>
                          {departmentList && departmentList.map((element) => (
                            <option key={element.id} value={element.id}>
                              {element.name}
                            </option>
                          ))

                          }
                        </select>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="mb-2">
                        <label className="form-label required">Year</label>
                        <select
                          className="form-select form-control"
                          value={srchYear}
                          onChange={(e) => searchYear(e)}
                        >
                          <option value="">Select Year</option>
                          {Array.from({ length: 8 }, (_, index) => {
                            const startYear =
                              new Date().getFullYear() - 5 + index;
                            const endYear = startYear + 1;
                            const formattedYear = `${startYear}-${endYear}`;

                            return (
                              <option key={formattedYear} value={formattedYear}>
                                {formattedYear}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="form-group mb-3 ">
                        <label className="form-label">&nbsp;</label>
                        <div>
                          <button
                            type="button"
                            name="btnSearch"
                            id="btnSearch"
                            className="btn btn-blue d-none d-sm-inline-block mr-1"
                            onClick={searchExemption}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon icon-tabler icon-tabler-search"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <circle cx="10" cy="10" r="7"></circle>
                              <line x1="21" y1="21" x2="15" y2="15"></line>
                            </svg>
                            Search
                          </button>
                          &nbsp;
                          <button
                            type="button"
                            name="btnSearchReset"
                            id="btnSearchReset"
                            className="btn btn-outline-primary "
                            onClick={clearSearch}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon icon-tabler icon-tabler-x"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              strokeWidth="2"
                              stroke="currentColor"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path
                                stroke="none"
                                d="M0 0h24v24H0z"
                                fill="none"
                              ></path>
                              <line x1="18" y1="6" x2="6" y2="18"></line>
                              <line x1="6" y1="6" x2="18" y2="18"></line>
                            </svg>
                            Clear
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="table-responsive">
                  <DataTable
                    columns={columns}
                    data={data}
                    progressPending={loading}
                    progressComponent={<CustomLoader />}
                    pagination="true"
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    paginationPerPage={perPage}
                    paginationComponent={BootyPagination}
                    /*  sortServer */
                    onSort={handleSort}
                  />
                </div>
                <div className="card-footer d-flex align-items-center">
                  <p className="m-0 text-muted">
                    Showing {/* <span>1</span> to <span>8</span> of{" "} */}
                    <span>{totalRecords}</span> entries
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show}
        onHide={handleClose}
        centered
        fullscreen={true}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload Form 16 A&B</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form method="POST" autoComplete={"off"}>
            <div className="row">
              <div className="col-md-12">
                {taxform ? (
                  <label className="form-label required">Preview</label>
                ) : (
                  ""
                )}

                <div id="pdfPreview"></div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <label className="form-label required">Select form</label>
                <div>
                  <input
                    type="file"
                    name="tax_form"
                    className={`form-control`}
                    accept="application/pdf"
                    onChange={(e) => uploadForm(e.target.files[0], "B")}
                  />
                </div>
              </div>
              {taxform ? (
                <div className={`invalid-feedback text-start`}>
                  Please Upload the document
                </div>
              ) : (
                ""
              )}

              <div className="col-md-6">
                <label className="form-label required">Form type</label>
                <div>
                  <select
                    className={` form-control`}
                    name="form_type"
                    id="formtype"
                    placeholder="Form Type"
                    onChange={(e) => setFormType(e.target.value)}
                    value={formtype}
                  >
                    <option value="">Select</option>
                    <option value="A">Form16 A</option>
                    <option value="B">Form16 B</option>
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <label className="form-label required">Assessment Year</label>

                <select
                  name="assessment_year"
                  value={assesmentyear}
                  className={`form-control`}
                >
                  <option value="">Select Year</option>
                  {Array.from({ length: 8 }, (_, index) => {
                    const startYear = new Date().getFullYear() - 5 + index;
                    const endYear = startYear + 1;
                    const formattedYear = `${startYear}-${endYear}`;

                    return (
                      <option key={formattedYear} value={formattedYear}>
                        {formattedYear}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <input
              type="hidden"
              name="user_id"
              id="formuserId"
              value={formuserId}
            />
          </form>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={isPopupOpen} onHide={handleClosePopup} centered
        fullscreen={true}
        size="lg">
        <Modal.Header closeButton>
          <Modal.Title> Form 16 {doctype} preivew</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              {/* <iframe src={pdfUrl} title="title"></iframe> */}
              <embed
                src={pdfUrl}
                type="application/pdf"
                style={{ width: "100%", height: "80vh" }}
              />
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>

        </Modal.Footer>
      </Modal>

      {/* <PDFPopup
        pdfUrl={pdfUrl} // Replace with the URL of the PDF you want to display
        isOpen={isPopupOpen}
        handleClose={handleClosePopup}
      />                      */}
      {viewModel.show === "view" && (
        <ViewExemptions
          closeViewExemptionForm={closeViewModel}
          data={viewData}
        />
      )}
    </>
  );
}

export default ExemptionList;
