import React from 'react'

const EditableRow = ({ editFormData , handleEditFormChange , handleCancelClick }) => {
  return (
          <tr key={`tr_${editFormData.id}`}>
            <td> <input
              type="text"
              name="relation"
              value={editFormData.relation}
              className={`form-control`}
              placeholder="Enter relation"
              onChange={handleEditFormChange}
              required="required" />
              <span className={`invalid-feedback text-start ${editFormData.editRelationError ? "d-block" : "d-none"}`}>{editFormData.editRelationError !== "" ? editFormData.editRelationError : ''}</span>
      </td>
      <td><input
          type="text"
          name="gName"
          id="gName"
          className={`form-control`}
          placeholder="Enter name"
          value={editFormData.gName}
          onChange={handleEditFormChange}
          required="required" />
              <span className={`invalid-feedback text-start ${editFormData.editGNameError ? "d-block" : "d-none"}`}>{editFormData.editGNameError !== "" ? editFormData.editGNameError : ''}
              </span>
          </td>
          <td>
              <input
                  type="text"
                  className={`form-control`}
                  id="email"
                  name="email"
                  value={editFormData.email}
                  placeholder="your@email.com"
                  onChange={handleEditFormChange} />
              <span className={`invalid-feedback text-start ${editFormData.editEmailError ? "d-block" : "d-none"}`}>{editFormData.editEmailError !== "" ? editFormData.editEmailError : ''}
              </span>
          </td>
          <td>
              <input
                  type="text"
                  name="gPhoneNumber"
                  id="gPhoneNumber"
                  className={`form-control`}
                  placeholder="Enter phone number"
                  value={editFormData.gPhoneNumber}
                  onChange={handleEditFormChange}
                  required="required" />
                    <span className={`invalid-feedback text-start ${editFormData.editGPhoneNumberError ? "d-block" : "d-none"}`}>{editFormData.editGPhoneNumberError !== "" ? editFormData.editGPhoneNumberError : ''}
              </span>
          </td>
          <td>
              <input
                  type="text"
                  name="gAddress"
                  id="gAddress"
                  className={`form-control`}
                  placeholder="Enter Address"
                  value={editFormData.gAddress}
                  onChange={handleEditFormChange}
                  required="required" />
                <span className={`invalid-feedback text-start ${editFormData.editGAddressError ? "d-block" : "d-none"}`}>{editFormData.editGAddressError !== "" ? editFormData.editGAddressError : ''}
              </span>
          </td>
          <td>
            <div className="inline-btn-grp">
              <button className="btn btn-primary" type="submit">Save</button>&nbsp;
              <button className="btn btn-danger" type="button" onClick={handleCancelClick}>Cancel</button>
            </div>
          </td>
        </tr>
  )
}

export default EditableRow