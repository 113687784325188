
console.log(window.location.hostname);
export const APP_LINK = (window.location.hostname === 'localhost') ? 'http://localhost:3000/' :
    (window.location.hostname === 'hrd.betaeserver.com') ? 'https://hrd.betaeserver.com/' :
        'http://localhost:3000/';
export const ADMIN_PRE = "/admin/";

/* export const API_LINK = (window.location.hostname === 'localhost') ? 'http://localhost:5000/api/eportal/v1/' :
    (window.location.hostname === 'hrd.eluminoustechnologies.net') ? 'http://143.244.134.89/api/eportal/v1/' :
        'http://143.244.134.89/api/eportal/v1/'; */


// export const API_LINK = 'http://143.244.134.89/api/eportal/v1/';
// export const HOST = 'http://143.244.134.89/';

export const API_LINK = 'https://kra.betaeserver.com/api/eportal/v1/';
export const HOST = 'https://kra.betaeserver.com/';

// export const API_LINK = 'http://localhost:3000/api/eportal/v1/';
// export const HOST = 'http://localhost:3000/';


export const LOGIN = API_LINK + "auth/login";
export const LOGOUT = API_LINK + "logout";
export const FORGOT_PASSWORD = API_LINK + "forgot-password";
export const RESET_PASSWORD = API_LINK + "reset-password";

/* DEPARTMENT */
export const ADD_DEPARTMENT = API_LINK + "department/create";
export const CNT_DEPARTMENT = API_LINK + "department/get_department_wise_user_count";
export const GET_DEPARTMENT_LIST = API_LINK + "department/findAll";
export const GET_DEPARTMENT = API_LINK + "department";
export const DELETE_DEPARTMENT = API_LINK + "department/delete";
export const GET_DEPARTMENT_BY_ID = API_LINK + "department/getById";
export const EDIT_DEPARTMENT = API_LINK + "department/update";
export const UPDATE_DEPT_STATUS = API_LINK + "department/changeStatus";
export const GET_DEPARTMENT_TEAM_LIST = API_LINK + "listing/department-team-list";

/* DESIGNATION */
export const GET_DESIG_BY_ID = API_LINK + "designation";
export const ADD_DESIGNATION = API_LINK + "designation/create";
export const EDIT_DESIGNATION = API_LINK + "designation/update";
export const GET_DESIGNATION_LIST = API_LINK + "designation/findAll";
export const GET_DESIGNATION_BY_ID = API_LINK + "designation/getById/";
export const DELETE_DESIGNATION = API_LINK + "designation/delete";
export const UPDATE_DESIGNATION_STATUS = API_LINK + "designation/changeStatus";
export const GET_DEPARTMENT_WISE_DESIGNATION = API_LINK + "roles/responsibility/get-department-wise-designation";


/* USER */
export const EMP_LIST = API_LINK + "user/list";
export const EMP_CREATE = API_LINK + "user/create";
export const CHANGE_EMP_STATUS = API_LINK + "user/change-status";
export const GET_EMP_BY_ID = API_LINK + "user/get-user-details";
export const UPDATE_EMP = API_LINK + "user/edit";
export const GET_GUARDIAN_BY_USERID = API_LINK + "user/getGuradianByUserId/";
export const GET_EXPERIENCE_BY_USERID = API_LINK + "user/getExperienceByUserId/";
export const GET_EDUCATION_BY_USERID = API_LINK + "user/getEducationByUserId/";
export const DEPT_HEAD_USER_LIST = API_LINK + "user/get-to-mail-employees";
export const DEPT_WISE_USER_LIST = API_LINK + "user/dept-list";
export const EMP_ALL_COMPOFF = API_LINK + "compoff/get-all-users-compoff";

/* PROFILE */
export const UPDATE_PERSONAL_INFO = API_LINK + "user/update";
export const UPDATE_RESET_PASSWORD = API_LINK + "update_reset_password";

/* EDUCATION */
export const ADD_EDUCATION = API_LINK + "user/education/add";
export const GET_EDUCATION_DATA = API_LINK + "user/education/";
export const DELETE_EDUCATION_DETAILS = API_LINK + "user/education/delete/";
export const EDIT_EDUCATION_DETAILS = API_LINK + "user/education/update";

/* EXPERIENCE */
export const ADD_EXPERIENCE = API_LINK + "user/experience/add";
export const GET_EXPERIENCE_DATA = API_LINK + "user/experience/";
export const DELETE_EXPERIENCE_DETAILS = API_LINK + "user/experience/delete/";
export const EDIT_EXPERIENCE_DETAILS = API_LINK + "user/experience/update";

/* SOCIAL LINKS */
export const ADD_SOCIAL = API_LINK + "user/sociallink/add";
export const GET_SOCIAL_DATA = API_LINK + "user/sociallink/";
export const DELETE_SOCIAL_DETAILS = API_LINK + "user/sociallink/delete/";
export const EDIT_SOCIAL_DETAILS = API_LINK + "user/sociallink/update";
/*USER PROFILE */
export const CHANGE_PASSWORD = API_LINK + "user/change-password";
/* GUARDIAN */
export const ADD_GUARDIAN_DETAILS = API_LINK + "user/guardian/add";
export const EDIT_GUARDIAN_DETAILS = API_LINK + "user/guardian/update";
export const GET_GUARDIAN_DETAILS = API_LINK + "user/guardian/";
export const DELETE_GUARDIAN_DETAILS = API_LINK + "user/guardian/delete/";
export const ADD_DOCUMENTS_DETAILS = API_LINK + "user/document/add";
export const GET_DOCUMENTS_DETAILS = API_LINK + "user/document/get-details/";
export const DELETE_DOCUMENTS_DETAILS = API_LINK + "user/document/delete/";
/* BIRTHDAY */
export const GET_BIRTHDAY_LIST = API_LINK + "birthday/list";
export const ADD_BIRTHDAY_IMG = API_LINK + "birthday/addImages";
export const DELETE_BIRTHDAY_IMG = API_LINK + "birthday/remove-image";
export const UPATE_BIRTHDAY_IMG_STATUS = API_LINK + "birthday/status";
/* POLICY */
export const GET_POLICY_LIST = API_LINK + "policy/findAll";
export const ADD_POLICY = API_LINK + "policy/create";
export const EDIT_POLICY = API_LINK + "policy/update";
export const DELETE_POLICY = API_LINK + "policy/delete";
export const GET_POLICY_BY_ID = API_LINK + "policy/";
export const UPDATE_POLICY_STATUS = API_LINK + "policy/changeStatus";
export const WEB_GET_POLICY_LIST = API_LINK + 'policy/get-policy-list';
export const WEB_GET_POLICY_DETAILS = API_LINK + 'policy/get-policy-details/';
/* POLICY TYPE*/
export const GET_POLICY_TYPE_LIST = API_LINK + "policy-type/findAll";
export const ADD_POLICY_TYPE = API_LINK + "policy-type/add";
export const EDIT_POLICY_TYPE = API_LINK + "policy-type/update";
export const GET_POLICY_TYPE_BY_ID = API_LINK + "policy-type/";
export const DELETE_POLICY_TYPE = API_LINK + "policy-type/delete";
export const UPDATE_POLICY_TYPE_STATUS = API_LINK + "policy-type/changeStatus";
/* OPENINGS*/
export const GET_OPENINGS_LIST = API_LINK + "openings/list";
export const UPDATE_OPENINGS_STATUS = API_LINK + "openings/change-status";
export const GET_APPLIED_USER = API_LINK + "openings/appliedUsers";
export const ADD_OPENINGS = API_LINK + "openings/create";
export const DELETE_OPENINGS = API_LINK + "openings/delete";
export const GET_OPENINGS_BY_ID = API_LINK + "openings/get-detail/";
export const EDIT_OPENINGS = API_LINK + "openings/update";
export const CHANGE_APPLICATION_STATUS = API_LINK + "openings/application/change-status";

/* Event */
export const GET_EVENT_CATEGORY_LIST = API_LINK + "event-category/list";
export const ADD_EVENT_CATEGORY = API_LINK + "event-category/create";

export const EVENT_LIST = API_LINK + "event/list";
export const UPDATE_EVENT_STATUS = API_LINK + "event/change-status";
export const DELETE_EVENT = API_LINK + "event/delete";
export const ADD_EVENT = API_LINK + "event/create";
export const WEB_GET_EVENTDATA = API_LINK + "event/get-event-list";
export const WEB_GET_EVENTCATDATA = API_LINK + "event/get-category-list";
export const DELETE_EVENT_IMAGE = API_LINK + "event/delete-event-images";
export const SET_FEATURED_IMAGE = API_LINK + "event/update-feature-image";

/* Library */
//admin
export const ADD_TECHNOLOGY = API_LINK + "technology/create";
export const GET_TECHNOLOGY_LIST = API_LINK + "technology/list";
export const DELETE_TECHNOLOGY_DETAILS = API_LINK + "technology/delete";
export const UPDATE_TECHNOLOGY_STATUS = API_LINK + "technology/change-status";
export const GET_TECHNOLOGY_BY_ID = API_LINK + "technology/get-details/";
export const EDIT_TECHNOLOGY = API_LINK + "technology/update";
export const GET_TECHNOLOGY_VERSIONS = API_LINK + "technology/version-list-by-techid/";
export const ADD_QUESTION = API_LINK + "question/create";
export const UPDATE_QUESTION = API_LINK + "question/update";
export const GET_QUESTIONS_LIST = API_LINK + "question/list";
export const UPDATE_QUESTION_STATUS = API_LINK + "question/status";
export const GET_QUESTION_BY_ID = API_LINK + "question/";
export const DELETE_QUESTION_DETAILS = API_LINK + "question/delete";

export const DELETE_TECH_VERSIONS = API_LINK + "technology/delete-version";
export const DELETE_TECH_QUESTION_VERSIONS = API_LINK + "question/delete-ques-version";

export const GET_QUESTION_COMMENT_LIST = API_LINK + "question/comment-list";
export const UPDATE_COMMENT_STATUS = API_LINK + "question/comment-status";
export const ADD_COMMENT_REPLY = API_LINK + "question/add-reply";
export const GET_COMMENTS_REPLY_LIST = API_LINK + "question/reply-list";
//Web
export const ADD_COMMENT_FOR_QUESTION = API_LINK + "question/add-comment";
export const GET_QUESTION_COMMENT_REPLY_DETAILS = API_LINK + "question/comment-reply-list";
export const GET_TECH_QUESTIONS = API_LINK + "question/question-by-tech-version";
export const GET_TECH_LIST = API_LINK + "technology/web-list";
export const GET_TECH_DETAILS_BY_ID = API_LINK + "technology/get-web-details/";


export const GET_EVENT_BY_ID = API_LINK + "event/get-details";
export const EDIT_EVENT = API_LINK + "event/update";
/* Testimonials */
export const ADMIN_TESTIMONIAL_LIST = API_LINK + "testimonial/get-all";
export const ADMIN_ADD_TESTIMONIAL = API_LINK + "testimonial/create";
export const ADMIN_TESTIMONIAL_STATUS = API_LINK + "testimonial/change-status";
export const ADMIN_TESTIMONIALS_UPDATE = API_LINK + "testimonial/update";
export const ADMIN_EDIT_TESTIMONIALS = API_LINK + "testimonial/get-details";
export const TESTIMONIAL_LIST = API_LINK + "testimonial/get-all-testimonials-web";
/* Permissions */

export const SAVE_PERMISSIONS = API_LINK + "permission/update";
export const GET_PERMISSIONS = API_LINK + "permission/get";

/* web openings */
export const GET_OPENINGS_WEB_LIST = API_LINK + "openings/getList";
export const GET_OPENINGS_WEB_DETAILS = API_LINK + "openings/detail/";
export const SAVE_OPENINGS_APPLY = API_LINK + "openings/apply";

/**Leaves */
export const GET_LEAVES_LIST = API_LINK + "leave/list";
export const UPDATE_LEAVE_STATUS = API_LINK + "leave/change-status";
export const GET_USER_LEAVES = API_LINK + "leave/getMyLeaves";
export const APPLY_LEAVE = API_LINK + "leave/apply";
export const MOTHWISE_LEAVES = API_LINK + "leave/month-wise-list";
export const LEAVE_TYPE_LIST = API_LINK + "leave-type/list";
export const LEAVE_CANCEL = API_LINK + "leave/cancle-leave";
export const LEAVE_CHANGE_STATUS = API_LINK + "leave/change-status";
export const LEAVES_BY_EMP_ID = API_LINK + "leave/get-leave-by-empId";
export const APPLY_LEAVE_FOR_EMPLOYEE = API_LINK + "leave/apply-for-employee";
export const APPLY_COMP_OFF_LEAVE = API_LINK + "leave/apply-for-comp-off";


/** Head Employee &  emails */
export const GET_HEAD_EMAILS = API_LINK + "user/get-to-mail-employees";
export const GET_HEAD_CC_EMAILS = API_LINK + "user/get-cc-mail-employee";
export const GET_HEAD_USER = API_LINK + "get-head-users-list";

/**Leave Types */
export const GET_LEAVE_TYPE_LIST = API_LINK + "leave-type/list";
export const CREATE_LEAVE_TYPE = API_LINK + "leave-type/create";
export const UPDATE_LEAVE_TYPE = API_LINK + "leave-type/update";
export const DELETE_LEAVE_TYPE = API_LINK + "leave-type/delete";
export const GET_APPLIED_LEAVES = API_LINK + "leave/get-tocc-leave-users";

/* Assets TYPE*/
export const GET_ASSETS_TYPE_LIST = API_LINK + "asset-type/findAll";
export const ADD_ASSETS_TYPE = API_LINK + "asset-type/add";
export const EDIT_ASSETS_TYPE = API_LINK + "asset-type/update";
export const GET_ASSETS_TYPE_BY_ID = API_LINK + "asset-type/";
export const DELETE_ASSETS_TYPE = API_LINK + "asset-type/delete";
export const UPDATE_ASSETS_TYPE_STATUS = API_LINK + "asset-type/changeStatus";

/* Assets Brand*/
export const GET_ASSETS_BRAND_LIST = API_LINK + "brands/list";
export const ADD_ASSETS_BRAND = API_LINK + "brands/add";
export const EDIT_ASSETS_BRAND = API_LINK + "brands/update";
export const DELETE_ASSETS_BRAND = API_LINK + "brands/delete";
export const UPDATE_ASSETS_BRAND_STATUS = API_LINK + "brands/status";

/* Assets Management*/
export const GET_ACTIVE_ASSET_TYPE_LIST = API_LINK + "asset-type";
export const GET_ACTIVE_BRAND_LIST = API_LINK + "brands/get-active-brands";
export const GET_ASSET_LIST = API_LINK + "assets/list";
export const ADD_ASSET = API_LINK + "assets/add";
export const EDIT_ASSETS = API_LINK + "assets/update";
export const UPDATE_ASSETS_STATUS = API_LINK + "assets/change-asset-status";
export const DELETE_ASSETS = API_LINK + "assets/delete-asset";

/*Employee Assets Management*/
export const GET_EMPLOYEE_ASSETS = API_LINK + "allocate-assets/get-employees";
export const GET_ASSETS_BY_BRAND_ID = API_LINK + "assets/get-by-brand-id";
export const ADD_ASSET_TO_EMPLOYEE = API_LINK + "allocate-assets/add-to-employee";
export const UPDATE_ASSET_TO_EMPLOYEE = API_LINK + "allocate-assets/update-to-employee";

/* ASSETS */
export const ASSETS_TYPE_LIST = API_LINK + "user/asset/get-asset-types";
export const GET_ASSETS_LIST = API_LINK + "user/asset/get-all-requests";
export const GET_ADMIN_ASSETS_LIST = API_LINK + "user/asset/get-admin-all-requests";
export const ADD_ASSETS = API_LINK + "user/asset/add";
export const GET_USER_REQUEST_DETAILS_ASSETS = API_LINK + "user/asset/get-user-requests-details";
export const GET_BRANDS_BY_ASSETS = API_LINK + "user/asset/get-brands-by-asset-id";
export const ADD_USER_ASSETS_ALLOCATED = API_LINK + "user/asset/create-user-assets-alocated";

/* Exemption */
export const CREATE_TAX_EXEMOTIONS = API_LINK + "taxexemption/create";
export const UPDATE_TAX_EXEMOTIONS = API_LINK + "taxexemption/update";
export const MY_EXEMPTION_LIST = API_LINK + "taxexemption/get-all";
export const DELETE_EXEMPTION = API_LINK + "taxexemption/delete";
export const ALL_EXEMPTION = API_LINK + "taxexemption/get-all-emp-tax";
export const UPLOAD_FORM_16 = API_LINK + "taxexemption/upload-tax-document";
export const FILE_BASE_URL = 'http://143.244.134.89';
export const CREATE_SUPPORT_TICKET = API_LINK + "ticket/create-support-ticket";
export const SEND_DOCUMENT_EMAIL = API_LINK + "taxexemption/send-document-email";

/* Manage Support */
export const GET_TICKET_LIST = API_LINK + "ticket/list";
export const SUPPORT_USER_LIST = API_LINK + "get-to-mail-tickets";
export const ADD_COMMENT = API_LINK + "ticket/add-comment";
export const GET_COMMENT = API_LINK + "ticket/get-all-user-comments";
export const UPDATE_STATUS_TICKET = API_LINK + "ticket/change-status";
export const GET_ACTIVE_DEPARTMENT_LIST = API_LINK + "ticket/get-all-active-department";

/** Dashboard */
export const DASHBOARD_DATA = API_LINK + "dashboard/dashboard";
export const USER_PROFILE_INFO = API_LINK + "dashboard/user-profile-percentage";
export const UPCOMING_INFO = API_LINK + "dashboard/upcoming";

/**Event Category */
export const GET_EVENT_CATEGORY_BY_ID = API_LINK + "event-category/category-by-id";
export const UPDATE_EVENT_CATEGORY = API_LINK + "event-category/update";
export const DELETE_EVENT_CATEGORY = API_LINK + "event-category/delete";
export const GET_SINGLE_EVENT = API_LINK + "event/get-event-detail";

/**Comp Off */
export const COMP_OFF_APPLY = API_LINK + "compoff/apply";
export const COMP_OFF_CHANGE_STATUS = API_LINK + "compoff/change-status";
export const COMP_OFF_CANCEL = API_LINK + "compoff/cancle";
export const COMP_OFF_GET_MY = API_LINK + "compoff/get-my-compoff";
export const COMP_OFF_GET_TOCC = API_LINK + "compoff/get-to-cc-users-compoff";
export const COMPOFF_BY_EMP_ID = API_LINK + "leave/get-compoff-by-empId";

/**Holidays */
export const GET_HOLIDAY_LIST = API_LINK + "holiday";
export const ADD_HOLIDAY = API_LINK + "holiday/create";
export const UPDATE_HOLIDAY = API_LINK + "holiday/update";
export const DELETE_HOLIDAY = API_LINK + "holiday/delete";
export const GET_PUBLIC_HOLIDAY_LIST = API_LINK + "holiday/get-public-holidays";
// export const COMP_OFF_APPLY = API_LINK + "compoff/apply";
// roles
export const CREATE_ROLES_RESPONSIBLITY = API_LINK + "roles/responsibility/create-responsibility";
export const GET_CREATED_RULES_RESPONSIBLITY = API_LINK + "roles/responsibility/get-responsibility";
export const GET_USER_RESPONSIBLITY = API_LINK + "roles/responsibility/get-user-responsibility";
export const UPDATE_USER_RESPONSIBLITY = API_LINK + "roles/responsibility/update-user-responsibility";


