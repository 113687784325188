import { React, useState, useEffect, useContext, useRef, useMemo } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom';
import Loading from "react-fullscreen-loading";
import { AuthContext } from '../../../component/context/AuthContext';
import axios from "axios";
import HeaderSection from "../banner/HeaderSection";
import { toast } from "react-toastify";
import * as  CONST from "../../../component/config/Constant"
import Form from "../../../component/utilities/Forms";


function ApproveLeave() {
    const [authUserData, setAuthUserData] = useContext(AuthContext);
    const [fullLoading, setFullLoading] = useState(false);
    const navigate = useNavigate();
    const  params  = useParams();
    const [loading, setLoading] = useState(false);
    const [comment, setComment] = useState('');
    const leaveID = (params.id) ?  params.id : 0;
    console.log('leave ID:'+params.id);

    let isToken = '';
    let userId = '';
   
    if (authUserData) {
        if (authUserData[0]) {
            isToken = authUserData[0].token ? authUserData[0].token : "";
            userId = authUserData[0].user_id ? authUserData[0].user_id : 0;           
        }
    }
    useEffect(() => {
       // update_leave_status()
    });

    function update_leave_status(status){
        setLoading(true);
        axios.post(CONST.UPDATE_LEAVE_STATUS,
            {
                'id':userId,
                'leave_id': leaveID,
                'comment': comment,
                'status': status,
            },
            {
                headers: {
                    'Authorization': `Basic ${isToken}`
                }
            }
        )
            .then((response) => {
                console.log(response);
                const status = response.data.status;
                if (status == 'success') {alert('suc');
                    toast.success(response.data.message);
                    
                    
                } else {alert('no suc');
                    toast.error(response.data.message);
                    
                }
                navigate('/admin/employee-leaves'); 
            })
            .catch((error) => {
                console.log(error);
                toast.error("There was an error.");
            });
        setLoading(false);
    }

    return (
        <>
            <Loading
                loading={fullLoading}
                background="rgba(0, 0, 0, 0.5)"
                loaderColor="#fff"
            />
            {/* <HeaderSection
                title="Manage"
                heading="Leaves"
                //addButton={handleShow}
                //addButton={() => update_leave_status(id)}
                addLink="#"
                addLinkName="Apply Leave"
                linktype="add"
            /> */}
            <div className="page-body">
                <div className="container-xl">
                    <div className="row row-cards">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Listing</h3>
                                </div>
                                <div className="card-body border-bottom py-3">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group mb-2 ">
                                                <label className="form-label required">Comment</label>
                                                <div>
                                                    <textarea 
                                                    className='form-control-textarea'
                                                    name='comment' 
                                                    id="comment"
                                                    onChange={(e) => setComment(e.target.value)}
                                                    >
                                                    </textarea>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <div className="col-md-12">
                                            <div className="form-group mb-2 ">
                                                <label className="form-label required">Comment</label>
                                                <div>
                                                <button
                                                    type="button"
                                                    className="mr-3 btn btn-primary"
                                                    onClick={() => update_leave_status(1)}
                                                >Approve</button>
                                                        
                                                &nbsp;&nbsp;

                                                <button
                                                    type="button"
                                                    className="mr-3 btn btn-danger"
                                                    onClick={() => update_leave_status(2)}
                                                >Reject</button>
                                                </div>
                                            </div>
                                        </div>
                                       
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ApproveLeave