import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import Loading from "react-fullscreen-loading";
import * as  CONST from "../../../component/config/Constant"
import axios from 'axios';
import Moment from "moment";
import { TwitterShareButton, TwitterIcon } from 'react-share';
import { FacebookShareButton, FacebookIcon } from 'react-share';
import { LinkedinShareButton } from "react-share";
import { Link } from 'react-router-dom';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

function Single() {
    const [loading, setloaing] = useState(false);
    const params = useParams();
    let slug = params.slug;
    const [event, setEvent] = useState([]);
    const shareLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.url)}`;

    const url = window.location.href;
    const [eventCatList, setEventCatList] = useState("");
    const [eventList, setEventList] = useState("");
    const [totalCount, setTotalCount] = useState(0);

    const [lightboxOpen, setLightboxOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);
  
    const openLightbox = (index) => {
        setPhotoIndex(index);
        setLightboxOpen(true);
    };
    
    const closeLightbox = () => {
        setLightboxOpen(false);
    };
    
    const images = event.e_event_images
    ? event.e_event_images
        .filter((ele) => ele.is_featured_image === 0)
        .map((ele) => `${CONST.HOST}${ele.image_path}`)
    : [];

    const fetchSingleEvent = async (slug) => {
        if (slug) {
            setloaing(true);
            //console.log(slug);
            await axios
            .get(CONST.GET_SINGLE_EVENT +'/'+slug

            )
            .then((response) => {
                setloaing(false);
                const status = response.data.status;
                if (status === 'success') {
                console.log(JSON.stringify(response.data.data));
                setEvent(response.data.data);
                }
            })
            .catch((error) => {
                console.log(error);
            });
            setloaing(false);
        }
    }

    const fetchEventCatList = async () => {
        await axios
          .get(CONST.WEB_GET_EVENTCATDATA)
          .then((response) => {           
            const status = response.data.status;
            if (status === 'success') {
                setEventCatList(response.data.data);
                
            }
          })
          .catch((error) => {
            console.log(error);
          });
    }

    const fetchEventData = async (cat_id) => {
        
        await axios
          .post(CONST.WEB_GET_EVENTDATA,
          {
            "sort": {
                "order": "asc",
                "order_by": "id"
            },
            //"page_number": currentPage,
            //"limit": postsPerPage,
            "category_id": cat_id
          })
        
          .then((response) => {console.log("event by cat: "+JSON.stringify(response.data))
            const status = response.data.status;
            if (status === 'success') {
                setEventList(response.data.data);
                setTotalCount(response.data.count)
                //alert(totalCount)
            }
          })
          .catch((error) => {
            console.log(error);
          });
    }

    useEffect(() => {
        fetchSingleEvent(slug);
        fetchEventCatList();
    }, [slug])

    const handleShareClick = () => {
        window.open(shareLink, '_blank', 'width=600,height=400');
      };
    return (
        <>
            <script async defer crossorigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v13.0" nonce="your-nonce"></script>
            <Loading
                loading={loading}
                background="rgba(0, 0, 0, 0.5)"
                loaderColor="#fff"
            />
            <section className='container'>
                <div className='row'>
                    <div className="col-12">
                        <div className="section-title text-center mb-60 mt-60">
                            <h3 className="mb-15">Events</h3>
                            <p>{event.title ? event.title : ''}</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="blog-section pt-100 single-event">
                <div className="container">
                    <div className="single-blog-wrapper">
                        <div className="row">

                            <div className="col-xl-8 col-lg-7">
                                <div className="left-wrapper">

                                    <div className="blog-content-wrapper">
                                      
                                        {
                                            event.e_event_images && event.e_event_images.map((ele, eKey) => {
                                            return (  
                                                (ele.is_featured_image == 1)
                                                ?
                                                <>     
                                                <div className="image event-banner">                            
                                                    <img src={CONST.HOST+ele.image_path} class="img-fluid" alt="Responsive image" />
                                                </div>
                                                </>
                                                :
                                                ''
                                            )
                                            })
                                        }
                                        <div className="content">
                                            <h4 className="">{event.title ? event.title : ''}</h4>
                                            <span className="event-dt">
                                                {event.event_date ? 
                                                
                                                Moment(event.event_date).format(
                                                    "DD, MMM. YYYY "
                                                )
                                                : ''}
                                            </span>
                                            <div className="quotation">
                                                {/* <i className="lni lni-quotation"></i> */}
                                                {event.description ?
                                                <div dangerouslySetInnerHTML={{ __html: event.description }} />
                                                : ""}
                                            </div>
                                            {/* <p></p> */}
                                        </div>
                                    </div>

                                    <div className='event-gallery-div'>
                                        <h3>Gallery</h3>
                                        
                                        <div className='event-gallery'>
                                        {images.map((imageUrl, index) => (
                                            <div className="image" key={index}>
                                            <img
                                                src={imageUrl}
                                                className="img-fluid"
                                                alt={`Image ${index + 1}`}
                                                onClick={() => openLightbox(index)}
                                                style={{ cursor: 'pointer', margin: '5px', maxWidth: '100px' }}
                                            />
                                            </div>
                                        ))}
                                        {lightboxOpen && (
                                            <Lightbox
                                            mainSrc={images[photoIndex]}
                                            nextSrc={images[(photoIndex + 1) % images.length]}
                                            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                                            onCloseRequest={() => setLightboxOpen(false)}
                                            onMovePrevRequest={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
                                            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
                                            />
                                        )}
                                        </div>
                                    </div>
                                                                

                                    <div className="comment-wrapper">
                                       
                                        <div className="title">
                                            {/* <h6>20 Comments</h6> */}
                                            <div className="share">
                                                
                                                <ul className="socials">
                                                    <li>Share</li>
                                                    <li>
                                                   
                                                        <div className="fb-share-button" data-href={window.location.href} data-layout="button_count" data-size="small">
                                                            <a target="_blank" rel="noopener noreferrer" href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`} className="fb-xfbml-parse-ignore">Share</a>
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <TwitterShareButton
                                                            url={window.location.href}
                                                            quote={event.title ? event.title : ''}
                                                            hashtag="#event"
                                                            >
                                                        <TwitterIcon size={32} round />
                                                        </TwitterShareButton>
                                                    </li>   
                                                    <li>
                                                        <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${url}`} target="_blank" title="Share to LinkedIn" > 
                                                            <i className="lni lni-linkedin-original"></i> 
                                                        </a> 
                                                
                                                    </li>
                                                </ul>
                                                       
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-4 col-lg-5">
                                <aside className="right-wrapper">
                                    <div className="sidebar-box category-box">
                                        <h4>Categories</h4>
                                        <ul>
                                        {
                                            eventCatList
                                            ?
                                            (
                                                eventCatList && eventCatList.map((element, key) => {
                                                    return (                                        
                                                        <li>
                                                        <Link to={`/events/`} onClick={() => fetchEventData(element.id)} >
                                                            <span>{element.name}</span> <span>({element.eventCount ? element.eventCount : ''})</span>
                                                        </Link>
                                                        </li>
                                                        

                                                    )
                                                })
                                            )
                                            :
                                            (
                                                <>
                                                {/* <DivSpinner size="sm" color="primary" /> */}
                                                </>
                                            )
                                        } 
                                        </ul>
                                    </div>

                                    {/* <div className="sidebar-box recent-blog-box">
                                        <h4>Recent Blog</h4>
                                        <ul>
                                            <li>
                                                <div className="image">
                                                    <a href="#0"> <img src="assets/img/blog/single-blog/recent-blog-1.jpg" alt="" data-pagespeed-url-hash="645779026" onload="pagespeed.CriticalImages.checkImageForCriticality(this);" /> </a>
                                                </div>
                                                <div className="content">
                                                    <h6><a href="#0">The Bank Marketing Strategies</a></h6>
                                                    <p>October 12, 2020</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="image">
                                                    <a href="#0"> <img src="assets/img/blog/single-blog/recent-blog-2.jpg" alt="" data-pagespeed-url-hash="940278947" onload="pagespeed.CriticalImages.checkImageForCriticality(this);" /> </a>
                                                </div>
                                                <div className="content">
                                                    <h6><a href="#0">The Bank Marketing Strategies</a></h6>
                                                    <p>October 12, 2020</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="image">
                                                    <a href="#0"> <img src="assets/img/blog/single-blog/recent-blog-3.jpg" alt="" data-pagespeed-url-hash="1234778868" onload="pagespeed.CriticalImages.checkImageForCriticality(this);" /> </a>
                                                </div>
                                                <div className="content">
                                                    <h6><a href="#0">The Bank Marketing Strategies</a></h6>
                                                    <p>October 12, 2020</p>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="image">
                                                    <a href="#0"> <img src="assets/img/blog/single-blog/recent-blog-4.jpg" alt="" data-pagespeed-url-hash="1529278789" onload="pagespeed.CriticalImages.checkImageForCriticality(this);" /> </a>
                                                </div>
                                                <div className="content">
                                                    <h6><a href="#0">The Bank Marketing Strategies</a></h6>
                                                    <p>October 12, 2020</p>
                                                </div>
                                            </li>
                                        </ul>
                                    </div> */}

                                    {/* <div className="sidebar-box tags-box">
                                        <h4>Popular Tag</h4>
                                        <ul>
                                            <li><a href="#0" className="button button-sm border-button radius-30">Web Design</a></li>
                                            <li><a href="#0" className="button button-sm border-button radius-30">Web Development</a></li>
                                            <li><a href="#0" className="button button-sm border-button radius-30">Mobile App</a></li>
                                            <li><a href="#0" className="button button-sm border-button radius-30">Business Analysis</a></li>
                                            <li><a href="#0" className="button button-sm border-button radius-30">Business Consulted</a></li>
                                            <li><a href="#0" className="button button-sm border-button radius-30">Seo</a></li>
                                            <li><a href="#0" className="button button-sm border-button radius-30">Landing page</a></li>
                                        </ul>
                                    </div> */}
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Single