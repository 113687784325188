import React, { useState } from "react";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
// import '../../../assets/css/react-Paginate.css'
import { Modal } from 'react-bootstrap';
import Loading from "react-fullscreen-loading";
import axios from "axios";
import { useContext } from 'react';
import { AuthContext } from '../../../component/context/AuthContext';
import { toast } from "react-toastify";
import * as  CONST from "../../../component/config/Constant";
import moment from 'moment';
import './ticket.css';
function RecentTickets({ onDataClick, data, onPageChange, totalPageCount }) {
  const [perPage] = useState(5); // Number of items per page
  const [currentPage, setCurrentPage] = useState(0);
  const [statusshow, setStatusShow] = useState(false);
  const [ticketupdate, setTicketUpdate] = useState(0);
  const [ticketid, setTicketId] = useState('');
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  let userid = '';
  let isToken = '';
  if (authUserData) {
    if (authUserData[0]) {
      isToken = authUserData[0].token ? authUserData[0].token : "";
      userid = authUserData[0].user_id ? authUserData[0].user_id : "";
    }
  }

  const handlePageChange = (selectedPage) => {
    console.log('selectedPage', selectedPage);
    setCurrentPage(selectedPage.selected);
    onPageChange(selectedPage.selected + 1);
  };

  const [ticketStatusList, setTicketStatus] = useState([
    {
      id: "0",
      name: "Open",
    },
    {
      id: "1",
      name: "In-Progress",
    },
    {
      id: "2",
      name: "Resolved",
    },
    {
      id: "3",
      name: "Rejected",
    },
    {
      id: "4",
      name: "Closed",
    },
  ]);

  const handleClick = (data) => {
    onDataClick(data);
  };

  const ticketStatus = (data) => {
    let status = ticketStatusList.find((tData) => {
      if (tData.id == data) {
        return tData;
      }
    });
    return status.name;
  };

  const statusClose = () => {
    setStatusShow(false);
  }

  const modelStatus = (data) => {
    console.log('jjjjjjjjj', data)
    setTicketUpdate(data.status)
    setTicketId(data.id)
    setStatusShow(true);
  }

  const updateStatus = async () => {
    setLoading(true);
    let data = {
      ticket_id: ticketid,
      status: ticketupdate
    }

    await axios.post(CONST.UPDATE_STATUS_TICKET, data, {
      headers: {
        Authorization: `Basic ${isToken}`,
      },
    }).then((response) => {
      const status = response.data.status;
      if (status == "success") {
        toast.success(response.data.message);
        setStatusShow(false);
        onPageChange(1);
      } else {
        toast.error(response.data.message);
        setStatusShow(false);
      }
      setLoading(false);
    }).catch((error) => {
      toast.error("Something went wrong. Please try again." + error);
      setLoading(false);
    })
  }

  // const offset = currentPage * perPage;
  const currentPageData = data;

  return (
    <>
      <Loading
				loading={loading}
				background="rgba(0, 0, 0, 0.5)"
				loaderColor="#fff"
			/>
      {currentPageData.length > 0 ? (
        currentPageData.map((ticketData, index) => (

          <div className="list-group list-group-flush" key={index} >
            <div className='row row-cards'>
              <div className='col-12'>
                <div className={`card ${ticketStatus(ticketData.status)}`} >
                  <div className="card-body card-body-scrollable card-body-scrollable-shadow">
                    <div className="divide-y">
                      <div>
                        <Link to="#" onClick={() => handleClick(ticketData)}>
                          <div class="row pb-2 ">
                            <div className="d-flex align-items-center">

                              {
                                (ticketData.createdByData.profile_photo_path)
                                  ?
                                  (<span className="avatar me-2 mr-2">
                                    <img src={CONST.HOST + ticketData.createdByData.profile_photo_path} alt="emp-img" width="40" className="rounded-circle" />
                                  </span>
                                  )
                                  :
                                  (
                                    <span className="avatar rounded-circle me-2 mr-2">
                                      {
                                        ticketData.createdByData && (
                                          <>
                                            {ticketData.createdByData && ticketData.createdByData.first_name.substring(0, 1)}
                                            {ticketData.createdByData && ticketData.createdByData.last_name.substring(0, 1)}
                                          </>
                                        )
                                      }

                                    </span>
                                  )
                              }
                              <div className="flex-fill pt-1">
                                <div className="text-muted "  >
                                  <h6>{
                                    ticketData.createdByData && (
                                      <>
                                        {ticketData.createdByData && ticketData.createdByData.first_name}&nbsp;{ticketData.createdByData && ticketData.createdByData.last_name}
                                      </>
                                    )
                                  }
                                  </h6>
                                </div>
                                {
                                  ticketData.createdByData.email && (
                                    <>
                                      <div className="text-muted ">
                                        <span className="text-muted" >
                                          <small>
                                            <Link to="mailto:{ticketData.createdByData.email}" > {ticketData.createdByData.email}</Link>
                                          </small>
                                        </span>
                                      </div>
                                    </>
                                  )
                                }
                                <div className="font-weight-medium pt-1"  >

                                  {
                                    ticketData.createdForData.id == userid || ticketData.createdByData.id == userid ?
                                      <Link to="#" onClick={() => modelStatus(ticketData)}>
                                        <span className="badge bg-blue ml-2 ">
                                          Update status
                                        </span>
                                      </Link>
                                      : ''
                                  }

                                </div>
                              </div>
                            </div>

                          </div> <div class="row pb-2">
                            <div className="col-md-9">
                              <div className="font-weight-medium text-left">
                                <div style={{ color: "#000" }}>
                                  <h6>{ticketData.title}</h6>
                                </div>
                                <div className="text-muted pt-1 " style={{ fontSize: "13px" }}>
                                  <svg xmlns="http://www.w3.org/2000/svg" class=" text-left mb-1  icon icon-tabler icon-tabler-calendar-plus  " width="16" height="16" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M12.5 21h-6.5a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v5"></path>
                                    <path d="M16 3v4"></path>
                                    <path d="M8 3v4"></path>
                                    <path d="M4 11h16"></path>
                                    <path d="M16 19h6"></path>
                                    <path d="M19 16v6"></path>
                                  </svg><small className="pl-1">{moment(ticketData.created_at).format("DD-MM-YYYY")}</small></div>
                              </div>
                            </div>
                            <div className="col-md-3">
                              <div className="text-muted text-right">

                                <span>#{ticketData.id}</span>
                              </div>
                              <div className="text-muted text-right">
                                {
                                  ticketData.status ? (
                                    (() => {
                                      const status = ticketStatus(ticketData.status);

                                      if (status === 'Open') {
                                        return <span className="badge bg-cyan">{status}</span>;
                                      } else if (status === 'Resolved') {
                                        return <span className="badge bg-green">{status}</span>;
                                      } else if (status === 'In-Progress') {
                                        return <span className="badge bg-orange">{status}</span>;
                                      } else if (status === 'Rejected') {
                                        return <span className="badge bg-red">{status}</span>;
                                      } else if (status === 'Closed') {
                                        return <span className="badge bg-dark">{status}</span>;
                                      } else {
                                        return <span className="badge bg-blue">Unknown Status</span>;
                                      }
                                    })()
                                  ) : ""
                                }
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>

                    </div>
                  </div>
                </div>
              </div >
            </div >
            {/*  <div className="list-group-item">

              <div className="row align-items-center">

                <div className="col text-truncate">
                  <Link to="#" onClick={() => handleClick(ticketData)}>
                    <div className="text-reset d-block">
                      <h6>{ticketData.title}</h6>
                    </div>
                    <div className="text-reset d-block">
                      {ticketData.createdByData.first_name +
                        " " +
                        ticketData.createdByData.last_name}
                    </div>
                    <div className="d-block text-muted text-truncate mt-n1">
                      <span className="badge bg-green mr-2">
                        {ticketStatus(ticketData.status)}
                      </span>{" "}
                      <span>{moment(ticketData.created_at).format("DD-MM-YYYY")}</span> (#
                      {ticketData.id})
                    </div>
                  </Link>
                  {ticketData.createdForData.id == userid ?
                    <Link to="#" onClick={() => modelStatus(ticketData)}>
                      <div className="d-block text-muted text-truncate mt-n1">
                        <span className="badge bg-blue mr-2">
                          Update status
                        </span>
                      </div>
                    </Link>
                    : ''
                  }
                </div>
              </div>
            </div> */}
          </div >
        ))
      ) : (
        <span>No data found</span>
      )
      }

      {/* <ReactPaginate
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        onPageChange={handlePageChange}
        containerClassName={"pagination"}
        activeClassName={"active"}
      /> */}

      <ReactPaginate
        pageCount={totalPageCount}
        breakLabel="..."
        nextLabel="next >"
        onPageChange={handlePageChange}
        pageRangeDisplayed={5}
        previousLabel="< previous"
        containerClassName={'container'}
        previousLinkClassName={'page'}
        breakClassName={'page'}
        nextLinkClassName={'page'}
        pageClassName={'page'}
        disabledClassNae={'disabled'}
        activeClassName={'active'}
        renderOnZeroPageCount={null}
      />
      <Modal size="lg" show={statusshow} onHide={statusClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Satus</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <div className="row">
            {/* <form
                method="POST"
                autoComplete={"off"}
              >  */}
            <div className="col-md-6">
              <div className="form-group mb-3 ">
                <label className="form-label required">Update Status</label>
                <div>
                  <select
                    className="form-control"
                    name="exemption_type"
                    aria-describedby="emailHelp"
                    value={ticketupdate}
                    placeholder="Enter Name"
                    onChange={(e) => setTicketUpdate(e.target.value)}
                  >
                    <option value="">Select</option>
                    {
                      ticketStatusList && ticketStatusList.map((element) => (
                        <option key={element.id} value={element.id}>{element.name}</option>
                      ))
                    }
                  </select>

                </div>
              </div>
            </div>


            {/* </form>          */}
            <div className="col-md-12">
              <div className="form-footer">
                <button
                  type="submit"
                  className="mr-3 btn btn-primary"
                  onClick={updateStatus}
                >
                  Update
                </button>
                &nbsp;&nbsp;
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default RecentTickets;