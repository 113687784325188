import React from 'react'
import { Link } from 'react-router-dom'
import * as CONST from "../../../component/config/Constant";

function DashboardHeader(props) {
    return (
        <>
            <div className="page-header d-print-none">
                <div className="container-xl">
                    <div className="row g-2 align-items-center">
                        <div className="col">
                            <div className="page-pretitle">Welcome to Dashboard</div>
                            <h2 className="page-title "> {props.userName},

                            </h2>
                        </div>
                        {/* <div className="col-12 col-md-auto ms-auto d-print-none">

                            <div className="btn-list">
                                <span className="d-none d-sm-inline">
                                    <Link
                                        to={CONST.ADMIN_PRE + 'testimonials'}
                                        className="btn btn-primary ">
                                        Testimonials
                                    </Link>
                                </span>
                                <Link
                                    to={CONST.ADMIN_PRE + 'employee'}
                                    className="btn btn-primary"
                                    onClick=""
                                >

                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="icon icon-tabler icon-tabler-eye"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        strokeWidth="2"
                                        stroke="currentColor"
                                        fill="none"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                        <circle cx="12" cy="12" r="2"></circle>
                                        <path d="M22 12c-2.667 4.667 -6 7 -10 7s-7.333 -2.333 -10 -7c2.667 -4.667 6 -7 10 -7s7.333 2.333 10 7"></path>
                                    </svg>

                                    &nbsp;Employee List
                                </Link>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div></>
    )
}

export default DashboardHeader