import React, { useEffect, useState } from 'react'
import Parser from 'html-react-parser';
import axios from 'axios';
import * as  CONST from "../../../component/config/Constant";
import { useContext } from 'react';
import { AuthContext } from '../../../component/context/AuthContext';
import '../../../assets/css/comment.css'
import DivSpinner from "../../../component/utilities/Spinner";
import moment from 'moment';

const TicketDetails = ({ data, defaultTab }) => {
  const [activeTab, setActiveTab] = useState(defaultTab);
  const [comment, setComment] = useState('');
  const [authUserData, setAuthUserData] = useContext(AuthContext);
  const [comments, setComments] = useState('');
  const [loading, setLoading] = useState(false);
  

  let user_id = '';
  let isToken = '';
  if (authUserData) {
    if (authUserData[0]) {
      isToken = authUserData[0].token ? authUserData[0].token : "";
      user_id = authUserData[0].user_id ? authUserData[0].user_id : "";
    }
  }

  useEffect(() => {
    setActiveTab(defaultTab);
  }, [defaultTab, data]);

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const handleSendComment = () => {
    if (comment) {
      axios({
        method: "post",
        url: CONST.ADD_COMMENT,
        data: {
          fk_ticket_id: data.id,
          comment_added_by: user_id,
          comment: comment
        },
        headers: {
          'Authorization': `Basic ${isToken}`,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => {
          console.log("responce >>", response);
          if (response.data.status == "success") {
            setLoading(true);
            handleCommnets();
            setComment('');
          }

        })
        .catch((error) => {
          // setloaing(false);
          console.log(error);
        });
    }

  };

  const handleTab = (tab) => {
    setActiveTab(tab);
    if (tab == 'comments') {
      setLoading(true);
      handleCommnets();
    }

  };

  const handleCommnets = async (e) => {
    await axios
      .post(CONST.GET_COMMENT, {
        fk_ticket_id: data.id,
      }, {
        headers: {
          'Authorization': `Bearer ${isToken}`
        }
      })
      .then((response) => {
        const status = response.data.status;
        if (status === 'success') {
          console.log('commetnts>>>>>>>>', response.data.data);
          setComments(response.data.data);
        } else {
          setComments('');
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const formatDate = (dateString) => {
    const dateOptions = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
      timeZone: 'Asia/Kolkata', 
    };
    const inputDate = new Date(dateString).toLocaleDateString("en-IN", dateOptions);
    const [datePart, timePart] = inputDate.split(', ');

    const [day, month, year] = datePart.split('/');

    const formattedDate = `${day}-${month}-${year}, ${timePart}`;
    return formattedDate;
  };
  return (
    <div>
      <div className="row">
        <div className="col-md-12 mb-3">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <button
                className={`nav-link ${activeTab === "details" ? "active" : ""
                  }`}
                onClick={() => handleTab("details")}
              >
                Details
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link ${activeTab === "comments" ? "active" : ""
                  }`}
                onClick={() => handleTab("comments")}
              >
                Comments
              </button>
            </li>
          </ul>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          {activeTab === "details" && (
            <div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group mb-3 ">
                    <label className="form-label required"><b>Assign To</b></label>
                    <div>
                      {data.createdForData.first_name +
                        " " +
                        data.createdForData.last_name +
                        " <" +
                        data.createdForData.email +
                        ">"}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group mb-3 ">
                    <label className="form-label required"><b>CC</b></label>
                    {data.e_support_cc_mails.map((ccData, index) => (
                      <div key={index}>
                        {ccData.user.first_name}{" "}
                        {ccData.user.last_name + " <" + ccData.user.email + ">"}
                      </div>
                    ))}
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group mb-3 ">
                    <label className="form-label required"><b>Subject</b></label>
                    <div>{data.title}</div>
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group mb-3 ">
                    <label className="form-label required"><b>Reason</b></label>
                    <div>{Parser(data.description)}</div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {activeTab === "comments" && (
            <>
              {loading ? (
                <DivSpinner size="sm" color="primary" />
              ) : (
                <div className="form-group mb-3">
                  <div>
                    {comments.length > 0 ? (
                      <div className="comments-container">
                        {Object.values(comments).map((commentdata) => (
                          <div
                            key={commentdata.id}
                            className={`comment ${commentdata.comment_added_by === user_id
                              ? "own-comment"
                              : "other-comment"
                              }`}
                          >
                            <div className="comment-header">
                              {commentdata.comment_added_by === user_id
                                ? "You"
                                : commentdata.user
                                  ? commentdata.user.first_name +
                                  " " +
                                  commentdata.user.last_name
                                  : ""} ({formatDate(commentdata.created_at)})
                            </div>
                            <div className="comment-content">
                              {commentdata.comment}
                            </div>
                          </div>
                        ))}
                      </div>
                    ) : null}
                  </div>

                  <div className="d-flex align-items-center mt-20">
                    <textarea
                      className="form-control flex-grow-1"
                      rows="4"
                      value={comment}
                      onChange={handleCommentChange}
                    />
                    <button
                      className="btn btn-primary ml-2"
                      onClick={handleSendComment}
                    >
                      Send
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TicketDetails;
