import { React, useState, useEffect, useContext, useMemo  } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import "../../../assets/css/table.css";
import Loading from "react-fullscreen-loading";
import DivLoader from "../../../component/utilities/Spinner";
import DataTable from "react-data-table-component";
import axios from "axios";
import { toast } from "react-toastify";
import * as  CONST from "../../../component/config/Constant"
import { AuthContext } from '../../../component/context/AuthContext';
import { Link } from 'react-router-dom';
import Moment from "moment";
import editImg from "../../../assets/img/icon/edit.svg";
import deleteImg from "../../../assets/img/icon/delete.svg";
import ReactSwitch from 'react-switch';
import * as SwitchProps from '../../../component/utilities/ReactSwitchReactSwitchProps';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
//import EventCategory from '../../FieldComponents/EventCategory';
import DivSpinner from '../../../component/utilities/Spinner';



function Details() {

      const [authUserData, setAuthUserData] = useContext(AuthContext);
      const [fullLoading, setFullLoading] = useState(false);
      const [validate, setValidate] = useState({});
      const [processing, setProcessing] = useState(false);
      const [data, setData] = useState([]);
      const [loading, setLoading] = useState(false);
      const [srchEventName, setSrchEventName] = useState("");
      const [srchStatus, setSrchStatus] = useState("");
      const [srchCatName, setSrchCatName] = useState("");
      const [webOpeningList, setwebOpeningList] = useState('');
      const [webOpeningDetails, setwebOpeningDetails] = useState('');
      const [isAddModalShow, setAddModalShow] = useState(false);
      const [isEditModalShow, setEditModalShow] = useState(false);

    let isToken = '';
    let user_id = '';
    if (authUserData) {
        if (authUserData[0]) {
          isToken = authUserData[0].token ? authUserData[0].token : "";
          user_id = authUserData[0].user_id ? authUserData[0].user_id : "";
        }
    }

    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        fetchWebOpeningsData();
        fetchWebOpeningsDetailsData(id);
    },[]);

    async function fetchWebOpeningsData() {
        setLoading(true);
        const response = await axios
          .post(CONST.GET_OPENINGS_WEB_LIST)
          .then((response) => {
                const status = response.data.status;
                setLoading(false);
                if (status === 'success') {
                    setwebOpeningList(response.data.data);
                }else{
                    toast.error(response.data.message);
                }
          })
          .catch((error) => {
            setLoading(false);
            toast.error("There was an error.");
          });
      }

    async function fetchWebOpeningsDetailsData(id) {
      setLoading(true);
      const response = await axios
        .get(CONST.GET_OPENINGS_WEB_DETAILS+id)
        .then((response) => {
              const status = response.data.status;
              setLoading(false);
              if (status === 'success') {
                  setwebOpeningDetails(response.data.data);
              }else{
                  toast.error(response.data.message);
              }
        })
        .catch((error) => {
          setLoading(false);
          toast.error("There was an error.");
        });
    }

    const capitalizeFirst = str => {
      return str?.charAt(0).toUpperCase() + str?.slice(1);
    };


    return (
        <>
            
            <Loading
              loading={loading}
              background="rgba(0, 0, 0, 0.5)"
              loaderColor="#fff"
            />
            
            <section className='container'>
                <div className='row'>
                    <div className="col-12">
                        <div className="section-title text-center mb-60 mt-60">
                            <h3 className="mb-15">{webOpeningDetails.title}</h3>
                            <p> <b>Exp Req : </b>
                              {(() => {
                                const test = '"'+webOpeningDetails.exp_required+'"';
                                if (test.includes('years')) { 
                                  return (
                                    webOpeningDetails.exp_required
                                  )
                                }else{
                                  return (
                                    webOpeningDetails.exp_required+' years'
                                  )  
                                }


                              })()}
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className='web-opening-section'>
                <div className='container'>
                    <div className="single-blog-wrapper">
                        <div className="row">
                            <div className="open_position_box">

                                <div className="card openings-card">

                                    <div className="card-body openings-card-body">
                                      <div className="open_wrapper_section">
                                        <div className="openings">Openings : <b>

                                        {webOpeningDetails.total_openings}

                                        </b></div>

                                        <div className="openings">Location : <b>{ capitalizeFirst(webOpeningDetails.location) }</b></div>
                                        <div className="recruiter">Email :
                                        <a href="mailto:sales@eluminoustechnologies.com">recruiter@eluminoustechnologies.com</a></div>
                                        
                                      </div>

                                      <div className="job_description text-left">
                                        <h4>Job Description</h4>
                                        <div dangerouslySetInnerHTML={{__html: capitalizeFirst  (webOpeningDetails.job_description)}} />
                                      </div>
                                      
                                      <div>

                                        <Link className="btn btn-primary blue_big_btn blue_big_btn_scrool1" to={"/openings-apply/"+webOpeningDetails.id}>Apply Now</Link>
                                        
                                        <Link onClick={() => navigate(-1)} className="ml-3 btn btn-outline-primary">Cancel</Link>
                                      </div>
                                      </div>
                                </div>

                            </div>
                        </div>
                    </div></div>
            </section>
        </>
    )
}

export default Details