import React from "react";
import moment from 'moment';
import 'react-datetime/css/react-datetime.css';
import { json } from "react-router-dom";

const ReadOnlyRow = ({data, handleEditEducation, deleteEducation, deleteImg, start_date, end_date}) => {
    
    const getDate = (date) => {
            let newD = date.toString();
            return newD;
    }

    return (
        <>
            {
                (data.id)
                ?
                (
                    <tr key={`tr_view_education`}>
                        <td>{data.institute_name ? data.institute_name : ''}</td>
                        <td className="text-muted">{data.degree ? data.degree : '' }</td>
                        
                        <td>{moment(data.start_date, 'DD-MM-YYYY').format("DD-MM-YYYY")}</td>
                        <td>{moment(data.end_date, 'DD-MM-YYYY').format("DD-MM-YYYY")}</td>
                        <td>
                        <img src="/static/media/edit.8b4759ee999d507ec6d9bc66c8538edc.svg" alt="editEducation" className="cursor" width="32" onClick={(event)=> handleEditEducation(event, data)} />
                        <img src={deleteImg} alt='deleteImg' width={32} className='cursor' onClick={() => deleteEducation(data.id) } />
                        </td>
                    </tr>
                )
                :
                (
                    <tr>
                        <td colSpan='6'>Education not found!</td>
                    </tr>
                )
            }
            
        </>
    )
}

export default ReadOnlyRow