import axios from 'axios';
import React from 'react'
import { useEffect } from 'react';
import * as  CONST from "../../../component/config/Constant"
import { useState } from 'react';
function WebTestimonials() {
  const [testimonials, setTestimonials] = useState("");
  useEffect(() => {
    fetchTestimonials();
  }, []);
  const fetchTestimonials = async () => {
    await axios
      .get(CONST.TESTIMONIAL_LIST,
        {
          page_number: 1,
          limit: 10
        })
      .then((response) => {
        const status = response.data.status;
        if (status === 'success') {
          console.log(response.data.data);
          setTestimonials(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
  return (
    <>
      <section className='container'>
        <div className='row'>
          <div className="col-12">
            <div className="section-title text-center mb-60 mt-60">
              <h3 className="mb-15">Testimonials</h3>
              <p>What our employee says about...</p>
            </div>
          </div>
        </div>
      </section>
      <section class="page-body">
        <div class="container-xl">
          <div class="row justify-content-center">

            {
              testimonials && testimonials.map((testimonial, index) => {
                let testimonials_desc = testimonial.description.replace(/(<([^>]+)>)/ig, '');
                testimonials_desc = testimonials_desc.replace('&nbsp;', ' ');
                return (
                  <div class="col-4  wow fadeInRight " data-wow-delay="0.5s">
                    <div class="card bg-primary text-primary-fg mb-4">

                      <div class="card-stamp">

                        <div class="card-stamp-icon bg-white text-primary">
                          <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"></path><path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"></path></svg>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-auto pb-2">
                            {
                              testimonial.user && (
                                (testimonial.user.profile_photo_path && testimonial.user.profile_photo_path)
                                  ? (
                                    <span className=" me-2" >
                                      <img src={CONST.HOST + testimonial.user.profile_photo_path} alt='' width={50} className="rounded-circle" />
                                    </span>
                                  ) : (
                                    <span className=" me-2">
                                      <span>

                                        {(testimonial.user.first_name && testimonial.user.last_name)
                                          ? <> {testimonial.user.first_name && testimonial.user.first_name.substring(0, 1)}
                                            {testimonial.user.last_name && testimonial.user.last_name.substring(0, 1)}</>
                                          : testimonial.user.name && testimonial.user.name.substring(0, 2)
                                        }
                                      </span>
                                    </span>
                                  )
                              )
                            }
                          </div>
                          <div class="col">
                            <div class="card-title text-white">
                              <strong>
                                {
                                  testimonial.user && (
                                    <>
                                      {testimonial.user.first_name} {testimonial.user.last_name}
                                    </>
                                  )
                                }

                              </strong>
                              <br />
                              <span className='card-subhead'>- {
                                testimonial.user && (testimonial.user.department.name)
                              }</span>
                            </div>

                          </div>
                          <p className='text-grey'>
                            {
                              testimonial.user && (testimonials_desc)
                            }
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }

            {/*  <div class="card">
                <div class="card-body">
                  <div class="divide-y">
                    <div>
                      {
                        testimonials && testimonials.map((testimonial, index) => {
                          let testimonials_desc = testimonial.description.replace(/(<([^>]+)>)/ig, '');
                          testimonials_desc = testimonials_desc.replace('&nbsp;', ' ');
                          return (
                            <div class="row">

                              <div class="col-auto">
                                {
                                  (testimonial.user.profile_photo_path && testimonial.user.profile_photo_path)
                                    ? (
                                      <span className="avatar me-2" >

                                        <img src={CONST.HOST + testimonial.user.profile_photo_path} alt='' width={40} className="rounded-circle" />
                                      </span>
                                    ) : (
                                      <span className="avatar me-2">
                                        <span>

                                          {(testimonial.user.first_name && testimonial.user.last_name)
                                            ? <> {testimonial.user.first_name && testimonial.user.first_name.substring(0, 1)}
                                              {testimonial.user.last_name && testimonial.user.last_name.substring(0, 1)}</>
                                            : testimonial.user.name && testimonial.user.name.substring(0, 2)
                                          }
                                        </span>
                                      </span>)

                                }
                              </div>
                              <div class="col">
                                <div class="text-truncate">
                                  <div>
                                    <strong>{testimonial.user.first_name} {testimonial.user.last_name}</strong> - <small>Development</small>

                                  </div>
                                  <div className='pt-3'>{testimonials_desc}</div>

                                </div>

                              </div>

                            </div>
                          )
                        })
                      }


                    </div>
                  </div>
                </div>
              </div> */}
          </div>
        </div>

      </section >
    </>
  )
}

export default WebTestimonials