import { React, useState, useContext, useEffect } from 'react'
import Loading from "react-fullscreen-loading";
import HeaderSection from "../banner/HeaderSection";
import * as  CONST from "../../../component/config/Constant"
import "../../../assets/css/form.css";
import DatePicker from 'react-datetime';
import moment from 'moment';
import { AuthContext } from '../../../component/context/AuthContext';
import axios from 'axios';
import { toast } from "react-toastify";
import { useNavigate  } from 'react-router-dom';


const AddExemptions = () => {
    const [authUserData, setAuthUserData] = useContext(AuthContext);
    let isToken = '';
    let userId = '';
	if (authUserData) {
		if (authUserData[0]) {
			isToken = authUserData[0].token ? authUserData[0].token : "";
            userId = authUserData[0].user_id ? authUserData[0].user_id : "";
		}
	}
    const [loading, setloading] = useState(false);
    const currentYear = new Date().getFullYear();
    const [srchYear, setSrchYear] = useState(currentYear - 1 + "-" + currentYear);
    const [inputFields, setInputFields] = useState([{
        emp_id:userId,
        exemption_type :'',
        name:'',
        total_amount:'',
        year: srchYear,
        description:'', 
        validate: {}
    } ]);
    const [typeLisiting, setTypeLisiting] = useState([
        {
          id: "1",
          name: "80C",
        },
        {
          id: "2",
          name: "80D",
        },
        {
          id: "3",
          name: "80E",
        },
        {
          id: "4",
          name: "80G",
        },
        {
          id: "5",
          name: "80TTA",
        },
      ]);
    const navigate = useNavigate();
   
    const valdations = async () =>{
        let valid = true;
        
        Object.entries(inputFields).map(([key, value]) => {
            let insError, total_amountError, sdateError, edateError,exemption_typeError  = ''
           
            if(value.exemption_type == ''){
                exemption_typeError = 'exemption_type field is required' 
              valid = false;
            }
            validateChange(key, 'exemption_type' ,exemption_typeError );

            if(value.name == ''){
                insError = 'Insttute name field is required' 
              valid = false;
            }
            validateChange(key, 'name' ,insError );

            if(value.total_amount == ''){
                total_amountError = 'total_amount field is required' 
                valid = false;
            }else{
                if (!value.total_amount.match(/^[0-9]+$/)) {
                    total_amountError = 'Invalid total_amount format' 
                  valid = false;
                }
            }
            validateChange(key, 'total_amount' ,total_amountError );

            if(value.year == ''){
                sdateError = 'year field is required' 
                valid = false;
            }
            validateChange(key, 'year' ,sdateError );

            if(value.description == ''){
                edateError = 'description field is required' 
                valid = false;
            }
            validateChange(key, 'description' ,edateError );
            
            // if(new Date(value.start_date) >= new Date(value.end_date)){
            //     edateError = 'End Date should be greater than Start Date' 
            //     valid = false;
            // }
            validateChange(key, 'description' ,edateError );
            
        });
        return valid ;
    }
    const validateChange = (index, fieldName ,error)=>{
        const list = [...inputFields];
        
        if(error != ''){
            list[index]['validate']['validate'] = 1;
            list[index]['validate'][fieldName] = error;    
        }
        
        
        setInputFields(list);
    }
    
   

    const handleSubmit = async (e) => {
       
        let isValid = await valdations();
        if(isValid === true){
            // alert('inputFields: '+JSON.stringify(inputFields) );
            setloading(true);
            axios
                .post(CONST.CREATE_TAX_EXEMOTIONS, 
                    {
                        "taxExemption": inputFields
                    }  ,
                    {
                    headers: {
                        'Authorization': `Bearer ${isToken}`,
                        'Content-Type': 'application/json'   
                    }
                })
                .then((response) => {
                    
                    if (response) {

                        if (response.data.status == 'error') {
                            toast.error(response.data.message);
                        } else {
                            toast.success(response.data.message);
                            setInputFields(
                                [{
                                    exemption_type :'',
                                    name:'',
                                    total_amount:'',
                                    year: '',
                                    description:'', 
                                    validate: {}
                                }]  
                            );
                            navigate('/admin/my-exemption');
                        }
                    }
                })
                .catch((error) => {
                    setloading(false);
                    console.log(error);
                    toast.error("Something went wrong. Please try again." + error);
                });
            setloading(false);
            
        }else{
            
        }
    }

    const addExperienceRow = () => {
        
        setInputFields([...inputFields, {
            emp_id:userId,
            exemption_type :'',
            name:'',
            total_amount:'',
            year: srchYear,
            description: '',  
            validate: {}
        } ])
    }

    const removeInputFields = (index)=>{
        const rows = [...inputFields];
        rows.splice(index, 1);
        setInputFields(rows);
        
    }

    const handleChange = (index, evnt)=>{
        // alert(index + ' '+ JSON.stringify(evnt));
        const { name, value } = evnt.target;
        const list = [...inputFields];
        list[index][name] = value;
        setInputFields(list);
    }

   
    return (
        <>
           <HeaderSection
				title=""
				heading="Add Information"
				addButton={addExperienceRow}
				addLink="#"
				addLinkName="Add More"
				linktype=""
			/>
             <div className="page-body">
                <div className="container-xl">
                    <div className="row row-cards">
                        <div className="col-12">
                            <div className="card">
                          
                                <div className="card-body">
                                {
                                     
                                inputFields.map((data, i)=>{
                                
                                // const {name, total_amount, year, description, exemption_type, validate}= data;
                                const {exemption_type, name, total_amount, year, description, validate}= data;
                                //alert(start_date);
                                return(
                        <div className="row" key={`row_${i}`}  >
                                 <div className="col-md-6">
                                        <div className="form-group mb-3 ">
                                            <label className="form-label required">
                                            Exemption Type
                                            </label>
                                        <div>

                                            <select
                                            name="exemption_type"
                                            id={ `exemption_type_${i}`}
                                            value={exemption_type}
                                            className={`form-control ${validate.validate && validate.exemption_type
                                                ? "is-invalid "
                                                : ""
                                                }`}
                                            aria-describedby="emailHelp"
                                            placeholder="Enter Name"
                                            onChange={(evnt)=>handleChange(i, evnt)}
                                            >
                                                <option value="">Select</option>
                                               {
                                                typeLisiting && typeLisiting.map((element)=>(
                                                    <option key={element.id} value={element.id}>{element.name}</option>
                                                ))
                                               }
                                            </select>
                                        </div>
                                        <div
                                            className={`invalid-feedback text-start ${validate.validate && validate.exemption_type
                                            ? "d-block"
                                            : "d-none"
                                            }`}
                                        >
                                           {validate.validate && validate.exemption_type
                                        ? validate.exemption_type
                                        : ""}
                                        </div>
                                    </div>
                                </div>    
                                <div className="col-md-6">
                                        <div className="form-group mb-3 ">
                                            <label className="form-label required">
                                                Name
                                            </label>
                                        <div>

                                            <input
                                            type="text"
                                            name="name"
                                            id={ `name_${i}`}
                                            value={name}
                                            className={`form-control ${validate.validate && validate.name
                                                ? "is-invalid "
                                                : ""
                                                }`}
                                            aria-describedby="emailHelp"
                                            placeholder="Enter Name"
                                            onChange={(evnt)=>handleChange(i, evnt)}
                                            />
                                        </div>
                                        <div
                                            className={`invalid-feedback text-start ${validate.validate && validate.name
                                            ? "d-block"
                                            : "d-none"
                                            }`}
                                        >
                                           {validate.validate && validate.name
                                        ? validate.name
                                        : ""}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mb-3 ">
                                        <label className="form-label required">
                                        Total amount
                                        </label>
                                        <div>
                                            <input
                                            type="text"
                                            name="total_amount"
                                            id={ `total_amount_${i}`}
                                            className={`form-control ${validate.validate && validate.total_amount
                                                ? "is-invalid "
                                                : ""
                                                }`}
                                            aria-describedby="emailHelp"
                                            placeholder="Enter Total amount"
                                            onChange={(evnt)=>handleChange(i, evnt)}
                                            value={total_amount}
                                            />
                                        </div>            
                                        <div
                                            className={`invalid-feedback text-start ${validate.validate && validate.total_amount
                                            ? "d-block"
                                            : "d-none"
                                            }`}
                                        >
                                            {validate.validate && validate.total_amount
                                            ? validate.total_amount
                                            : ""}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6">
                                    <div className="form-group mb-3 ">
                                        <label className="form-label required">year </label>
                                        <div>
                                        <select
                                        name="year"
                                        value={year}
                                        id={ `year_${i}`}
                                        className={`form-control ${validate.validate && validate.year
                                            ? "is-invalid "
                                            : ""
                                            }`}
                                        aria-describedby="emailHelp"
                                        onChange={(evnt)=>handleChange(i, evnt)}
                                        >
                                        <option value="">Select Year</option>
                                        {Array.from({ length: 8 }, (_, index) => {
                                            const startYear =
                                            new Date().getFullYear() - 5 + index;
                                            const endYear = startYear + 1;
                                            const formattedYear = `${startYear}-${endYear}`;

                                            return (
                                            <option key={formattedYear} value={formattedYear}>
                                                {formattedYear}
                                            </option>
                                            );
                                        })}
                                        </select>    
                                       
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mb-3 ">
                                        <label className="form-label required">
                                        Description
                                        </label>
                                        <div>
                                        <textarea id={ `description_${i}`}
                                        name="description"
                                        value={description}
                                        className={`form-control ${validate.validate && validate.description
                                            ? "is-invalid "
                                            : ""
                                            }`}
                                        placeholder="Enter description"
                                        onChange={(evnt)=>handleChange(i, evnt)} rows="4">

                                        </textarea>
                                                            
                                           
                                        </div>
                                        <div
                                        className={`invalid-feedback text-start 
                                            ${validate.validate && validate.description
                                            ? "d-block"
                                            : "d-none"
                                        }`}>
                                        {validate.validate && validate.description
                                        ? validate.description
                                        : ""}
                                        </div>
                                        <div
                                            className="invalid-feedback text-start" >
                                        </div>
                                    </div>
                                </div> 
                            
                            
                            <div className="col-md-6">
                            {(inputFields.length!==1 && i>0)? 
                            <button className="mr-3 btn btn-danger mt-4" onClick={() => removeInputFields(i)} style={{margin: "1px 10px 30px 1px"}}>Remove</button>:''
                            }
                            </div>
                            <hr></hr>
                        </div>
                        
                        )
                    })
                    }

                       

                        <div className="col-md-12">
                            <div className="form-footer">
                                <button
                                type="button"
                                className="mr-3 btn btn-primary"
                                onClick={handleSubmit}
                                >
                                Submit
                                </button>
                                    &nbsp;&nbsp;
                                    <button
                                    type="reset"
                                    className="btn btn-outline-primary"
                                    
                                    >
                                    Reset
                                </button>
                            </div>
                        </div>
                       
                     
                    </div>
                  </div>
                </div>
              </div>
                </div>
           </div >
        </>
    );
};

export default AddExemptions;